<div class="row mt-4"></div>
<div class="container-fluid">
    <!-- <div class="row mt-4 divcls" *ngFor="let item of notificationlist"> -->
    <div class="row mt-4 divcls">
        <div class="col-12 text-center">
            <span class="dayname text-center">{{notificationdetails.activeFrom}}</span>
        </div>

        <div class="col-12 BackRect mt-3">
            <div class="row">
                <div class="col-12 text-center mt-3">
                    <span class="header_text ">{{notificationdetails.notificationHeader}}</span>
                </div>
                </div>
                <div class="row">
                    <div class="col-12 text-center mt-3" *ngIf="notificationdetails.notificationImage != ''">
                        <img [src]="notificationImageUrl + notificationdetails.notificationImage"
                            class="img-fluid Rounded Corners imgrect" onerror="this.onerror=null;this.src='../assets/images/NoBlogImage.jpg'"/>
                    </div>
                </div>
                <div class="row">
                <div class="col-12 mt-3 text-center mb-3">
                    <span class="tmtext">{{notificationdetails.notificationText}}</span>
                </div>
            </div>

        </div>
    <!-- </div> -->
</div>
<br><br><br><br>
