import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { HeaderService } from 'src/app/service/header.service';
import { DatePipe } from '@angular/common';
import { FilterComponent } from '../filter/filter.component';
import { MatDialog } from '@angular/material/dialog';
import { InputPara } from 'src/app/models/kpi-models';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { DataService } from 'src/app/service/data.service';

@Component({
  selector: 'app-ulb-data',
  templateUrl: './ulb-data.component.html',
  styleUrls: ['./ulb-data.component.css']
})
export class UlbDataComponent implements OnInit {
  deviceType: any;
  device: any;
  FieldName: any;
  FieldValue: any;
  ParaName: any;
  UserID: any;
  public resList: any[] = [];
  myDate: any;
  curYear: any;
  curMonth: any;
  prevYear: string = '';
  prevYearTwoDigit: string = '';
  rdType: any = 0;
  selected: string = '';
  token: any;
  ULBType: any;
  billclass: any;
  totalclass: any;
  lat: any;
  lng: any;
  UserType: any;

  constructor(private activatedRoute: ActivatedRoute, private dialog: MatDialog,
    private router: Router, private headerService: HeaderService,
    private mds: MongoDataService, private datePipe: DatePipe, private notifyMeService: NotifyMeService, private ds: DataService) { }

  ngOnInit(): void {
    this.selected = this.activatedRoute.snapshot.queryParams["selected"];
    this.headerService.setActiveURl('/ulb');
    this.headerService.setTitle('Unique Lines Billed');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.token = localStorage.getItem("TokenNo");
    this.UserType = localStorage.getItem("UserType");
    this.myDate = Date.now();

    this.curMonth = this.datePipe.transform(this.myDate, 'MMM');
    this.prevYear = JSON.stringify(new Date().getFullYear() - 1);
    this.prevYearTwoDigit = this.prevYear.substring(2, 4);

    this.activatedRoute.paramMap.forEach(params => {
      this.FieldValue = params.get('FieldValue');
      this.FieldName = params.get('FieldName');
      this.UserID = params.get('UserID');
      this.rdType = params.get('ParaName');
      try { this.ULBType = params.get('ULBType'); } catch { this.ULBType = "Billed" }
      debugger
      this.GetGeoLocation();
      this.loadData();
    });
  }
  input: InputPara = new InputPara();

  GetGeoLocation() {
    debugger
    if (navigator) {
      navigator.geolocation.getCurrentPosition(pos => {
        this.lat = pos.coords.latitude;
        console.log("  this.lat", this.lat)
        this.lng = pos.coords.longitude;
        this.SetapplicationLogData();
      }, this.handleLocationError, { timeout: 30000 });
    }
    else {
      this.notifyMeService.showError('Location Could not be captured', 'Mera APP');
    }
  }

  handleLocationError(error: { code: any; }) {
    switch (error.code) {
      case 3:
        this.notifyMeService.showError('Timeout in Position capture', 'Mera APP V2');
        break;
      case 2:
        this.notifyMeService.showError('Device is not capable of Position capture', 'Mera APP V2');
        break;
      case 1:
        this.notifyMeService.showError('You denied for Position capture', 'Mera APP V2');
        break;
    }
  }


  SetapplicationLogData() {
    debugger
    let request = {
      "userid": this.UserID,
      "usertype": this.UserType,
      "formname": "ulbData",
      "actiondesc": "ulb Data" + " " + this.FieldName,
      "fieldname": this.FieldName,
      "fieldvalue": this.FieldValue,
      "latitude": this.lat,
      "longitude": this.lng
    }
    this.ds.SetapplicationLogData(request).subscribe(res => {
      console.log("res", res)
    })
  }
  loadData(): void {
    this.input.FieldName = this.FieldName;
    this.input.FieldValue = this.FieldValue;
    this.input.TagName = "1";
    this.input.Datatype = this.rdType;
    this.input.UserID = this.UserID;
    this.input.TYPE = ""
    if (this.ULBType == 'Billed') {
      this.billclass = 'active_rla col-6';
      this.totalclass = 'unactive_rla col-6';
      this.headerService.setTitle('Unique Lines Billed');
      this.input.TYPE = "Billed";


    }
    else {
      this.billclass = 'unactive_rla col-6';
      this.totalclass = 'active_rla col-6';
      this.headerService.setTitle('Unique Lines Total');
      this.input.TYPE = "Overall";
    }

    this.resList = [];
    this.mds.getOverallUlbDashboard(this.input, this.token, this.device, this.deviceType, 'Unique Lines Billed').subscribe(res => {
      console.log("getUlbDashboard", res);

      this.resList = res;
    });
  }
  OnClickRLA(type: any) {
    this.ULBType = type;

    this.loadData();
  }
  RowClick(paraname: any, paravalue: any, selected: any): void {
    debugger;
    if (paraname != "rdcode") {
      paraname = paraname.toUpperCase() == 'INDIA' ? 'BRANCH' : paraname.toUpperCase() == 'BRANCH' ? 'REGION' : paraname.toUpperCase() == 'REGION' ? 'ASM' : paraname.toUpperCase() == 'ASM' ? 'SOTERRITORY' : paraname.toUpperCase() == 'SOTERRITORY' ? 'RDCode' : 'RDCode';
      this.router.navigate(['/home/ulbData/', paraname, paravalue, selected, this.UserID, this.ULBType]);
    }
  }
  openFilter() {
    if (this.dialog.openDialogs.length == 0) {
      const dialogRef = this.dialog.open(FilterComponent, {
        closeOnNavigation: true,
        data: {
          "FieldName": localStorage.getItem("OriginalFieldName"),
          "FieldValue": localStorage.getItem("OriginalFieldValue"),
          "UserID": this.UserID,
          "MenuUpTo": "5",
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (result.FieldName == "RD") {
            this.FieldName = "RDCode";
          } else {
            this.FieldName = result.FieldName;
          }
          this.FieldValue = result.FieldValue;
          // this.FieldName="1";
          // this.FieldValue=result.FieldName;
          // this.ParaName=result.FieldValue;
          this.loadData();
        }
      });
    }
  }
}
