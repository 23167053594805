import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { OifApproval } from 'src/app/models/kpi-models';
import { DataService } from '../../service/data.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifyMeService } from 'src/app/service/notify-me.service';

@Component({
  selector: 'app-oif-approval',
  templateUrl: './oif-approval.component.html',
  styleUrls: ['./oif-approval.component.css']
})
export class OifApprovalComponent implements OnInit {
  FormName: any;

  constructor(private headerService: HeaderService, private activatedRoute: ActivatedRoute, private fb: FormBuilder, private router: Router, private mds: MongoDataService, private ds: DataService, private notifyMeService: NotifyMeService,) { }

  UserID: any;
  RequirementType: any;
  UserType: any;
  isChecked: boolean = false;
  ID: any;
  OIFApprovalList: any;
  private _searchText = '';
  TokenNo: any;
  ClientID: any;
  isLongPress: boolean = false;
  isSelected: boolean = false;
  userType: string | null = '';
  list: any;
  selected = -1;
  deviceType: any;
  device: any;

  isSelectedObj: any;
  expansionbtnclass: string | null = '';
  additionbtnclass: string | null = '';
  replacementbtnclass: string | null = '';
  upgradebtnclass: string | null = '';
  normalcss: string = 'btn bgwhite';
  Expansion: number = 0;
  Additional: number = 0;
  Replacement: number = 0;
  Upgrade: number = 0;
  filteredList: any;
  searchText = '';
  userTypelist: any;
  selectedApprovalCount: number = 0;
  activecss: string = 'width: 82px;  height: 19px;  font-family: Roboto;  font-size: 14px;  font-weight: 500;  font-stretch: normal;  font-style: normal;  line-height: 1.36;  letter-spacing: 1.25px;  text-align: center;  color: #724e8c;';
  public OIFlist: OifApproval = new OifApproval();
  token: any;
  lat:any;
  lng:any;
  FieldName:any;
  FieldValue:any;


  ngOnInit(): void {
    this.headerService.setActiveURl('/OifApproval');
    this.headerService.setTitle("OIF Creation");
    this.token = localStorage.getItem("TokenNo");
    this.FormName = 'OIF Approval';
    this.UserType = localStorage.getItem("UserType");
    this.UserID = localStorage.getItem("UserID");
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.FieldName = localStorage.getItem("FieldName");
    this.FieldValue = localStorage.getItem("FieldValue");
    this.RequirementType = "Expansion";
    this.ID = 0;
    this.TokenNo = localStorage.getItem("TokenNo");
    this.ClientID = localStorage.getItem("ClientID");
    this.GetGeoLocation();
    this.getUserType();
    this.GetRequirementtypeCount();

    this.GetApprovalList(this.UserID, "Expansion", 0, this.UserType);
    this.selectedApprovalCount = this.RequirementType == 'EXPANSION' ? this.Expansion : this.RequirementType == 'ADDITIONAL' ? this.Additional : this.RequirementType == 'REPLACEMENT' ? this.Replacement : this.Upgrade;
  }


  GetGeoLocation() {
    debugger
    if (navigator) {
      navigator.geolocation.getCurrentPosition(pos => {
        this.lat = pos.coords.latitude;
        console.log("  this.lat", this.lat)
        this.lng = pos.coords.longitude;
        this.SetapplicationLogData();
      }, this.handleLocationError, { timeout: 30000 });
    }
    else {
      this.notifyMeService.showError('Location Could not be captured', 'Mera APP');
    }
  }

  handleLocationError(error: { code: any; }) {
    switch (error.code) {
      case 3:
        this.notifyMeService.showError('Timeout in Position capture', 'Mera APP V2');
        break;
      case 2:
        this.notifyMeService.showError('Device is not capable of Position capture', 'Mera APP V2');
        break;
      case 1:
        this.notifyMeService.showError('You denied for Position capture', 'Mera APP V2');
        break;
    }
  }

  SetapplicationLogData() {
    debugger
    let request = {
      "userid": this.UserID,
      "usertype": this.UserType,
      "formname": "OifApproval",
      "actiondesc": "Oif Approval",
      "fieldname": this.FieldName,
      "fieldvalue": this.FieldValue,
      "latitude": this.lat,
      "longitude": this.lng
    }
    this.ds.SetapplicationLogData(request).subscribe(res => {
      console.log("res", res)
    })
  }

  getUserType() {
    this.mds.getUserDetails(this.UserID, this.token, this.device, this.deviceType, this.FormName).subscribe(res => {
      this.userTypelist = res;
      if (this.userTypelist[0].userType == "RCCM") { this.UserType = "RCCM"; }
      else { this.UserType = this.userTypelist[0].userType; }
    });
  }
  showDetails(type: string) {
    this.RequirementType = type;
    // localStorage.setItem('RequirementType', this.RequirementType);
    this.GetApprovalList(this.UserID, this.RequirementType, this.ID, this.UserType);


    //this.loadDataApprovalType();
    this.expansionbtnclass = this.additionbtnclass = this.replacementbtnclass = this.upgradebtnclass = this.normalcss;
    this.resetCSS();
    this.selectedApprovalCount = this.RequirementType == 'EXPANSION' ? this.Expansion : this.RequirementType == 'ADDITIONAL' ? this.Additional : this.RequirementType == 'REPLACEMENT' ? this.Replacement : this.Upgrade;
  }
  resetCSS() {
    if (this.RequirementType == 'EXPANSION') { this.expansionbtnclass = this.activecss; }
    else if (this.RequirementType == 'ADDITIONAL') { this.additionbtnclass = this.activecss; }
    else if (this.RequirementType == 'REPLACEMENT') { this.replacementbtnclass = this.activecss; }
    else { this.upgradebtnclass = this.activecss; }
  }




  GetApprovalList(UserID: string, RequirementType: string, ID: number, UserType: string) {
    this.OIFApprovalList = [];

    this.ds.GetEligibleOutletList(UserID, RequirementType, ID, UserType, this.TokenNo, this.ClientID, this.device, this.deviceType, this.FormName).subscribe(res => {
      localStorage.setItem('dataType', 'eligible');
      this.OIFApprovalList = res;
      this.filteredList = res;

      console.log("GetOIFApprovalList", this.OIFApprovalList)

    });

  }

  clickOnSelect(e: any, id: any) {
    if (e.target.checked) {
      this.isSelected = e.target.value;
    }
    this.isSelectedObj = this.list[id];
    console.log("isSelected", this.isSelected);
    console.log("isSelectedObj", this.isSelectedObj);
  }

  rowClick(ID: Number) {
    this.ID = ID;
    console.log("OIFRequest", this.OIFApprovalList);

    this.router.navigate(['/home/outletdetailoif/', this.UserID, this.RequirementType, this.ID, this.UserType]);

  }
  get searchTerm(): any {

    return this._searchText;
  }
  set searchTerm(value: any) {

    this._searchText = value;
    this.filteredList = this.filteredListMethod(value);
  }

  filteredListMethod(value: any): any {

    return this.OIFApprovalList.filter((ele: any) =>
      ele.outletCode.indexOf(value) !== -1);
  }

  GetRequirementtypeCount() {
    this.ds.GetRequirementTypeCount(this.UserID, this.UserType, this.TokenNo, this.ClientID, this.device, this.deviceType, this.FormName).subscribe(data => {
      if (data.length > 0) {
        this.Additional = data[0].rCount;
        this.Expansion = data[1].rCount;
        this.Replacement = data[2].rCount;
        this.Upgrade = data[3].rCount;
      }
      let tot = this.Additional + this.Expansion + this.Replacement + this.Upgrade;
      this.selectedApprovalCount = this.RequirementType == 'EXPANSION' ? this.Expansion : this.RequirementType == 'ADDITIONAL' ? this.Additional : this.RequirementType == 'REPLACEMENT' ? this.Replacement : this.Upgrade;

    }
    );
  }
  onOIFTabChanged(e: any) {
    this._searchText = '';
    if (e.index == "0") {
      this.GetApprovalList(this.UserID, "Expansion", 0, this.UserType); this.RequirementType = "Expansion";
    }
    if (e.index == "1") { this.GetApprovalList(this.UserID, "Additional", 0, this.UserType); this.RequirementType = "Additional"; }
    if (e.index == "2") { this.GetApprovalList(this.UserID, "Replacement", 0, this.UserType); this.RequirementType = "Replacement"; }
    if (e.index == "3") { this.GetApprovalList(this.UserID, "Upgrade", 0, this.UserType); this.RequirementType = "Upgrade"; }
  }

  searchOutlet(OutletUID: any) {
    if (OutletUID != '') {
      this.ds.searchOutletForOIFApproval(OutletUID, this.UserID, this.UserType, this.RequirementType, this.TokenNo, this.device, this.deviceType, 'OIF Approval').subscribe(res => {
        console.log("searchOutletForOIFApproval", res);
        if (res.length == 0) {
          this.notifyMeService.showWarning('Outlet Details not found for this Outlet Code', 'Sales Genie App');
        } else {
          this.filteredList = res;
        }
      });
    }
    else {
      this.notifyMeService.showWarning('Outlet code should not be blank', 'Sales Genie App');
    }
  }

}


