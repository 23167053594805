<div class="container-fluid">
    <div class="row mt-4">
        <div class="col-8">
          <span class="header_text">{{FieldValue=='0'? 'All India' : FieldValue=='ALLINDIA' ? 'All India': FieldValue |titlecase}}</span>
        </div>
        <div class="col-4 text-right">
          <img src="../../../assets/filter.png" class="img-fluid" (click)="openFilter()" /><sup><i
              class="fa fa-circle fa-sm" style="color: #eaab5e;font-size: 5px;"></i></sup>&nbsp;<span
            class="filter_text">Filter</span>
        </div>
      </div>
      <br>
    <div class="row mt-2">
        <div class="col-6">
            <span class="subhead_text">Full Month Data</span> 
        </div>
        <div class="col-6">
            <div class="row">
                <div class="col-6">
                    <i class="fa fa-circle fa-sm" style="color:#eaab5e; font-size:6px;">&nbsp;&nbsp;<span
                            class="Secondary_text">Secondary</span></i>
                </div>
                <div class="col-6">
                    <i class="fa fa-circle fa-sm" style="color: #f4d5ae; font-size: 6px;">&nbsp;&nbsp;<span
                            class="Secondary_text">Primary</span></i>
                </div>
            </div>
        </div>
    </div>
    <br>
    <div style="overflow: auto;">
        <div class="row mt-2" *ngFor="let item of seconderytrenddata ">
            <div class="col-4">
                <span class="val_text">{{item.period}}</span>
            </div>
            <div class="col-8">
                <div class="row">
                    <div class="col-12">
                        <div class="progress rounded-pill">
                            <div class="progress-bar bg_sec" role="progressbar"
                                [style.width.%]="item.secondarysale%100|number:'1.0-0'" aria-valuenow="25"
                                aria-valuemin="0" aria-valuemax="100"></div>
                            <div class="progress-bar bg_pri" role="progressbar"
                                [style.width.%]="item.primarysale%100|number:'1.0-0'" aria-valuenow="25"
                                aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-6 text-center">
                        <span class="val_text">{{item.secondarysale}}&nbsp;<span class="Unit_text">{{item.unit}}</span></span>
                    </div>
                    <div class="col-6 text-center">
                        <span class="val_text">{{item.primarysale}}&nbsp;<span class="Unit_text">{{item.unit}}</span></span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">&nbsp;</div>
                  </div>
            </div>
        </div>
    </div>
</div>
<br><br><br><br>