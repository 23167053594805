import { Component, OnInit } from '@angular/core';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.css']
})
export class QrCodeComponent implements OnInit {
  public output: any;
  title = 'QRCode';
  userType: string | null = '';
  userId: any;
  formname:any;
  actiondesc:any;
  FieldName:any;
  FieldValue:any;

  constructor(private cs:CommonUtilitiesService) { }

  ngOnInit(): void {
    this.userId = localStorage.getItem("UserID");
    this.userType = localStorage.getItem("UserType");
    this.FieldName=localStorage.getItem("FieldName");
    this.FieldValue=localStorage.getItem("FieldValue");
    this.formname = "pullOutReasonPulloutreq";
    this.actiondesc = "Pull Out Reason Pull Out Req";
    this.cs.GetGeoLocation(this.userId, this.userType, this.formname, this.actiondesc, this.FieldName, this.FieldValue);
  }

  public frameConfig: Object = {
    lineWidth: 4,
    strokeStyle: 'red'
  };
  public textConfig: Object = {
    font: 'bold 18px serif',
    fillStyle: 'red'
  };

  // @ViewChild('action', { static: true }) action: NgxScannerQrcodeComponent;
  // TODO something this.action

  public onError(e: any): void {
    alert(e);
  }

}
