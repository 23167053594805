<div class="container-fluid">
  <div class="row mt-4">
    <div class="col-6"></div>
    <div class="col-2  mt-1 text-right">
      <img src="../../../assets/download.png" class="img-fluid" (click)="captureScreen()" />
    </div>
    <div class="col-4 text-right">
      <mat-form-field appearance="outline" style="width: 100px">
        <!-- <mat-label>Period</mat-label> -->
        <mat-select id="period" name="period" (selectionChange)="PeriodChange($event)" [(value)]="selectedperiod">
          <mat-option value="" disabled>Select Month</mat-option>
          <mat-option *ngFor="let item of periodList" [value]="item">
            {{ item }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <br>
  <div id="content" #content>
    <div class="row bg_CMA" *ngFor="let item of filterItemsOfType(RdInformation);let i = index">
      <div class="col-12">
        <div class="row">
          <div class="col-12">&nbsp;</div>
        </div>
        <div class="row">
          <div class="col-12 text-center mt-3">
            <span class="rdheading">{{item.rdname|titlecase}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-center mt-3 ">
            <span class="rdcodecss">{{item.rdcode}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-center mt-3 ">
            <span *ngIf="item.ach >=item.tgt" class="rdstatusAchived"> <img src="../../../assets/target_achieved.png"
                class="img-fluid" />&nbsp;
              Great! Target Achieved</span>
            <span *ngIf="item.ach < item.tgt" class="rdstatusOnTrack"> <img src="../../../assets/targetachieved.png"
                class="img-fluid" />&nbsp;
              On Track</span>
          </div>
        </div>

        <div class="row">
          <div class="col-12 mt-3">
            <div class="progress rounded-pill">
              <div class="progress-bar bg_sec" role="progressbar" [style.width.%]="item.ach" aria-valuenow="25"
                aria-valuemin="0" aria-valuemax="100"></div>
              <div class="progress-bar bg_pri" role="progressbar" [style.width.%]="item.tgt" aria-valuenow="25"
                aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-6 text-center">
            <span class="val_text">{{item.ach}}<span class="unit_text"> {{item.unit}}</span></span>
          </div>
          <div class="col-6 text-center">
            <span class="val_text">{{item.tgt}}<span class="unit_text"> {{item.unit}}</span></span>
          </div>
        </div>
        <div class="row">
          <div class="col-6 text-center">
            <i class="fa fa-circle fa-sm" style="color: #eaab5e; font-size: 6px;"><span class="Secondary_text">
                Achievement</span></i>
          </div>
          <div class="col-6 text-center">
            <i class="fa fa-circle fa-sm" style="color: #f4d5ae; font-size: 6px;"><span class="Secondary_text">
                Target</span></i>
          </div>
        </div>
        <div class="row">
          <div class="col-6 mt-3 mb-3"><button class="btn btn_custom" (click)="clickOnYPrimary()">
              Primary Invoices</button></div>
          <div class="col-6 text-right mt-3 mb-3">
            <button class="btn btn_custom" (click)="clickOnYTDAch()">
              Secondary Trend</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-3 p-0 uv-100">
    <div class="col-12 p-0">
      <div id="content1" #content1>
        <mat-accordion>
          <mat-expansion-panel (opened)="panelOpenState = true" [expanded]="true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header class="acctitle">
              <mat-panel-title>
                Grow Performance
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row mt-2">
              <table class="table tbl_custom">
                <thead>
                  <tr>
                    <td class="td_cust">Parameter</td>
                    <td class="td_cust">Target</td>
                    <td class="td_cust">Achivement</td>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let data of filterItemsOfType(rdGrowData);let i = index">
                    <td class="Value_text">{{data.paraname}}</td>
                    <td class="Head_text">{{data.tgt}}</td>
                    <td class="Head_text">{{data.ach}}</td>
                  </tr>
                </tbody>
              </table>
            </div>

          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div id="content2" #content2>
        <mat-accordion>
          <mat-expansion-panel class="mt-3" (opened)="panelOpenState = true" [expanded]="true"
            (closed)="panelOpenState = false">
            <mat-expansion-panel-header class="acctitle">
              <mat-panel-title>
                Growth Over LYSM
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row mt-2">
              <table class="table tbl_custom">
                <thead>
                  <tr>
                    <td class="td_cust">Parameter</td>
                    <td class="td_cust">{{curMonth}} {{currYearTwoDigit}}({{GrowthOverLYSMUnit}})</td>
                    <td class="td_cust">{{curMonth}} {{prevYearTwoDigit}}({{GrowthOverLYSMUnit}})</td>
                    <td class="td_cust">Growth</td>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of GrowthOverLYSM">
                    <td class="Value_text">{{item.fieldvalue==RDCode?'Total':item.fieldvalue.toUpperCase()}}</td>
                    <td class="Head_text" style="text-align: right;">{{item.salescy}}</td>
                    <td class="Head_text" style="text-align: right;">{{item.salespy}}</td>
                    <td class="Head_text" style="text-align: right;">{{item.growth}}%</td>
                  </tr>
                </tbody>
              </table>
            </div>

          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div id="content3" #content3>
        <mat-accordion>
          <mat-expansion-panel class="mt-3" (opened)="panelOpenState = true" [expanded]="true"
            (closed)="panelOpenState = false">
            <mat-expansion-panel-header class="acctitle">
              <mat-panel-title>
                ULB
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row mt-3">
              <div class="col-4 td_cust">{{curMonth}} {{currYearTwoDigit}}</div>
              <div class="col-4 td_cust">{{curMonth}} {{prevYearTwoDigit}}</div>
              <div class="col-4 td_cust">Growth</div>
            </div>
            <div class="row mt-3" *ngFor="let item of UlbList">
              <div class="col-4"><span class="Value_text">{{item.cmulb}}</span></div>
              <div class="col-4"><span class="Value_text">{{item.lmulb}}</span></div>
              <div class="col-4"><span class="Value_text">{{item.ulbdiff}}</span></div>
            </div>

          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div id="content4" #content4>
        <mat-accordion>
          <mat-expansion-panel class="mt-3" (opened)="panelOpenState = true" [expanded]="true"
            (closed)="panelOpenState = false">
            <mat-expansion-panel-header class="acctitle">
              <mat-panel-title>
                Earning Trend
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="row mt-3" *ngFor="let item of EarningTrend">
              <div class="col-4"><span class="Value_text">{{item.fieldvalue}}</span></div>
              <div class="col-6 p-0" style="margin-top: .35rem;">
                <div class="progress rounded-pill">
                  <div class="progress-bar bg_sec" role="progressbar" [style.width.%]="item.totalincentive*20"
                    aria-valuenow="25" aria-valuemin="0" aria-valuemax="10"></div>
                </div>
              </div>
              <div class="col-2"><span class="Head_text">{{item.totalincentive}}%</span></div>
            </div>

          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </div>

  <br>
  <br>
  <br>
  <br>
  <br>
  <br>
  <br>
</div>