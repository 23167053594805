export class EncryptionClass {
  Word: string = '';
  WordKey: string = '';
}

export class UserLogin {
  UserType: string = "";
  RDCOde: string = "";
  Soterritory: string = "";
  ASMArea: string = "";
  branchName: string = "";
  BSM: string = "";
  implementer: string = "'";
  floatcode: string = "";
  UserName: string = "";
  NonMeranetUser: string = "";
  NonMeranetApproval: string = "";
  CMSAdmin: string = "";
  SST: string = "";
  Covidstatus:string="";
  ATSAccessType: string = "";
  RDTYPE: string = "";
  RDStatus: string = "";
  ATS3Plevel: string = "";
  UserLock: string = "";
  firstlogin: string = "";
  pwage: number = 0;
  Rval: string = "";
  UserID: string = "";
  TokenNo: string = "";
  SecreteKey: string = "";
  ClientID: number = 0;
  sessionNo:string ="";
  MobileNo:string="";
  EmailID:string ="";
  userstatus:string ="";
}

export class LoginDtls {
  UserPassword: string = '';
  UserID: string = '';
}

export class Enclass {
  buggerstring: string = '';
}

export class ResultClass {
  resultString: string = '';
}

export class ResTargetdataOld {
  _id: number = 0;
  FieldName: string = '';
  FieldValue: string = '';
  Period: string = '';
  RuralTgt: number = 0;
  RuralAch: number = 0;
  UrbanTgt: number = 0;
  UrbanAch: number = 0;
  TotalTgt: number = 0;
  TotalAch: number = 0;
}

export class ResTargetdata {
  _id: number = 0;
  fieldname: string = '';
  fieldvalue: string = '';
  period: string = '';
  valuetype: string = '';
  tgt: number = 0;
  ach: number = 0;
  primarysale: number = 0;
  percentage: number = 0;
  unit: string = '';
}
export class GetChannelPerformance {
  _id: string = '';
  fieldname: string = '';
  fieldvalue: string = '';
  paraname: string = '';
  salescy: number = 0;
  salespy: number = 0;
  growth: number = 0;
  unit: string = '';
}
