import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { DataService } from 'src/app/service/data.service';
import { elementAt } from 'rxjs/operators';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-pccreation-edit',
  templateUrl: './pccreation-edit.component.html',
  styleUrls: ['./pccreation-edit.component.css']
})
export class PccreationEditComponent implements OnInit {
  deviceType: any;
  device: any;
  userType: string | null = '';
  RDCode: any;
  list: any;
  msg: any;
  private _searchText = '';
  filteredList: any;
  selectedpc: any;
  selected = -1;
  UserID: any;
  SelectedPCID : any='';
  token: any;
  formname: any;
  actiondesc: any;
  fieldName: any;
  fieldValue: any;

  constructor(private activatedRoute: ActivatedRoute,private cs:CommonUtilitiesService, private router: Router, private headerService: HeaderService, private mds: MongoDataService, private notifyMeService:NotifyMeService, private ds: DataService,) { }

  ngOnInit(): void {
    this.headerService.setTitle('PC Creation Setting');
    this.headerService.setActiveURl('/PccreationEdit');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.token = localStorage.getItem("TokenNo");
    this.userType = localStorage.getItem("UserType");
    this.RDCode = localStorage.getItem("RDCode");
    this.UserID = localStorage.getItem("UserID");

    
    this.fieldName=localStorage.getItem("FieldName");
    this.fieldValue=localStorage.getItem("FieldValue");
    this.formname = "PccreationEdit";
    this.actiondesc = "Pc Creation Edit";
    this.cs.GetGeoLocation(this.UserID, this.userType, this.formname, this.actiondesc, this.fieldName, this.fieldValue);
    this.onLoad();
  }
  onLoad() {
    let id = 0;
    this.ds.getActivePCLIst(this.RDCode, this.token, this.device, this.deviceType,'PC Creation Edit').subscribe(res => {
      this.list = res;
      this.filteredList = this.list;
    });
  }
  btnClick()
  {
    if( this.SelectedPCID!='')
    {
      const dataToSend = {
        RDCode : this.RDCode,
        UserID : this.UserID,
        Additional : 'A',
        NoOfAdditional :  0,
        Replacement :'R',
        NoOfReplacement : 0,
        Edit :  'E',
        EditPCUID : this.SelectedPCID,
      };
      console.log(dataToSend);
          this.ds.setPCCreationSetting(dataToSend, this.token, this.device,this.deviceType,'PC Creation Setting').subscribe(res => {
          this.notifyMeService.showSuccess('Records Save Sucessfully', 'Sales Genie App');
          this.gotonextpage();
          });
      }
      else{
        this.notifyMeService.showInfo('Please Select Atleast One PC', 'Sales Genie App');
      }
  }

  get searchTerm(): any{
    return this._searchText;
  }
  set searchTerm(value: any){
     this._searchText = value;
     this.filteredList = this.filteredListMethod(value);
  }

  filteredListMethod(value: any): any {
    return this.list.filter((ele: any) =>(
      ele.pcName.toUpperCase().indexOf(value.toUpperCase()) !== -1 || ele.pcuid.toString().toUpperCase().indexOf(value.toUpperCase()) !== -1));
  }
  selected_pc(e: any) {
    if (e.target.checked) {
      this.selectedpc = e.target.value;
      this.SelectedPCID=this.SelectedPCID==''?this.selectedpc:this.SelectedPCID+','+this.selectedpc;
    }
    else{
      if(this.SelectedPCID.indexOf(',')>0){
        if(this.SelectedPCID.indexOf(e.target.value)+e.target.value.length==this.SelectedPCID.length)
        {
          this.SelectedPCID=this.SelectedPCID.substring(0,this.SelectedPCID.indexOf(e.target.value)-1)
        }
        else{
          this.SelectedPCID=this.SelectedPCID.replace(e.target.value+',','');
        }

      //this.SelectedPCID=this.SelectedPCID.substring(0,this.SelectedPCID.indexof(e.target.value))+this.SelectedPCID.substring(this.SelectedPCID.indexof(e.target.value)+e.target.value.length)
      }
      else{
        this.SelectedPCID='';
      }

    }
    // console.log(this.SelectedPCID);
  }
  gotonextpage(){
    this.router.navigate(['/home/PccrRdlist']);
  }

}
