<div class="container-fluid">
    <div class="row mt-4">
        <div class="col-9">{{FieldValue=='ALLINDIA' ? 'All India': FieldValue}}
        </div>
        <div class="col-3 text-left">
            <img src="../../../assets/filter.png" class="img-fluid" (click)="openFilter()" /><sup><i
                    class="fa fa-circle fa-sm" style="color: #eaab5e;font-size: 5px;"></i></sup>&nbsp;<span
                style="color: #724e8c;font-weight:400 ;font-size: 0.8125rem;">Filter</span>
        </div>
    </div>
    <br>
    <div class="row mt-2">
        <div class="col-4">
            <!-- <select class="form-control rounded-pill Period_Cust" id="period"  (change)="PeriodChange($event)"   >
                <option value="" disabled>Select Month</option>
                <option *ngFor="let item of periodList" [value]="item">
                {{ item }}
                </option>
            </select> -->
            <mat-form-field appearance="outline" style="width: 100px">
                <!-- <mat-label>Period</mat-label> -->
                <mat-select id="period" name="period" (selectionChange)="PeriodChange($event)" [(value)]="selectedperiod">
                    <mat-option value="" disabled>Select Month</mat-option>
                    <mat-option *ngFor="let item of periodList" [value]="item">
                        {{ item }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-8">
            <div class="row ">
                <div class="col-2">
                </div>
                <div class="col-4">
                    <i class="fa fa-circle fa-sm" style="color: #eaab5e; font-size: 6px;">&nbsp;&nbsp;<span
                            class="Secondary_text">Secondary</span></i>
                </div>
                <div class="col-3">
                    <i class="fa fa-circle fa-sm" style="color:#f4d5ae; font-size: 6px;">&nbsp;&nbsp;<span
                            class="Secondary_text">Primary</span></i>
                </div>
                <div class="col-3">
                    <i class="fa fa-circle fa-sm" style="color: #ebe6f1; font-size: 6px;">&nbsp;&nbsp;<span
                            class="Secondary_text">Target</span></i>
                </div>
            </div>
        </div>
    </div>
    <br>
    <div style="overflow: auto;">
        <div class="row mt-2" *ngFor="let item of data" (click)="RowClick(item.valuetype)">
            <div class="col-12">
                <div class="row">
                    <div class="col-12">
                        <span class="header_text1">{{item.valuetype}}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <div class="row mt-2">
                            <div class="col-12">
                                <span class="Value_text">{{item.percentage}}%</span>
                                <!-- <animated-digit [digit]="item.percentage" [duration]="1000"></animated-digit> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-9">
                        <div class="row mt-2">
                            <div class="col-12">
                                <div class="progress rounded-pill">
                                    <div class="progress-bar bg_sec" role="progressbar" [style.width.%]="item.ach"
                                        aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                    <div class="progress-bar bg_pri" role="progressbar"
                                        [style.width.%]="item.primarysale" aria-valuenow="25" aria-valuemin="0"
                                        aria-valuemax="100"></div>
                                    <div class="progress-bar bg_tgt" role="progressbar" [style.width.%]="item.tgt"
                                        aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-4">
                                <span class="text_14">{{item.ach}}<span class="text_12"> {{item.unit}}</span></span>
                            </div>
                            <div class="col-4">
                                <span class="text_14">{{item.primarysale}}<span class="text_12">
                                        {{item.unit}}</span></span>
                            </div>
                            <div class="col-4">
                                <span class="text_14">{{item.tgt}}<span class="text_12"> {{item.unit}}</span></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr class="hr_cust">
        </div>
    </div>
</div>
<br><br><br><br>