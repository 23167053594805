<div>
  <div class="row mt-4">
    <div class="col-9">
      <span class="header_text">{{FieldValue=='0'? 'All India' : FieldValue=='ALLINDIA' ? 'All India': FieldValue | titlecase}}</span>
    </div>
    <div class="col-3 text-right" (click)="openFilter()">
      <img src="../../../assets/filter.png" class="img-fluid" /><sup><i class="fa fa-circle fa-sm"
          style="color: #eaab5e;font-size: 5px;"></i></sup>&nbsp;<span
        style="color: #724e8c;font-family: Roboto-Medium;font-weight:400 ;font-size: 14px;">Filter</span>
    </div>
  </div>
  <div class="row mt-3" *ngIf="!isPC">
    <div class="col-6">
      <span class="date_Text">Date: {{myDate | date:'dd MMM, yyyy'}}</span>
    </div>
    <div class="col-6 text-right">
      <img class="img-fluid" src="assets/sec_dot.png" />&nbsp;
      <span class="Secondary_text">Absent</span>&nbsp;&nbsp;
      <img class="img-fluid" src="assets/pri_dot.png" />&nbsp;
      <span class="Secondary_text">Present</span>&nbsp;&nbsp;
      <img class="img-fluid" src="assets/tar_dot.png" />&nbsp;
      <span class="Secondary_text">Total</span>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-6">
    
    </div>
    <div class="col-6 text-right">
      <span class="date_Text">Date: {{myDate | date:'dd MMM, yyyy'}}</span>
    </div>
  </div>
  <br>
 
    <div class="row mt-2" *ngFor="let item of HealthStatus" >
      <div class="col-12">
        <div class="row">
          <div class="col-9">
            <span class="desc_Text">{{item.fieldvalue.toUpperCase()}}</span>
          </div>
          <div class="col-3 text-right">
            <span class="present_Status" *ngIf="item.status == 'Present'">{{item.status}}</span>
            <span class="absent_Status" *ngIf="item.status == 'Absent'">{{item.status}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <div class="row mt-2">
              <div class="col-12">
                <span class="Value_text">{{item.percentage | number: '1.0-0'}}%</span>
              </div>
            </div>
          </div>
          <div class="col-9">
            <div class="row mt-2">
              <div class="col-12">
                <div class="progress rounded-pill">
                  <div class="progress-bar bg_sec" role="progressbar" [style.width.%]="item.absent"
                    aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                  <div class="progress-bar bg_pri" role="progressbar" [style.width.%]="item.present" aria-valuenow="25"
                    aria-valuemin="0" aria-valuemax="100"></div>
                  <div class="progress-bar bg_tgt" role="progressbar" [style.width.%]="item.total" aria-valuenow="25"
                    aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-4 text-center">
                <span class="text_14">{{item.absent}}</span>
              </div>
              <div class="col-4 text-center">
                <span class="text_14">{{item.present}}</span>
              </div>
              <div class="col-4 text-center">
                <span class="text_14">{{item.total}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr class="hr_cust">
    </div>
 
  
  <br>
  <br>
  <br>
  <br>
  <br>
</div>
