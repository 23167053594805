<div class="row mt-4">
    <div class="col-12">
        <input id="txtSearch" class="SearchCss" autocomplete="off" type="text" name="" min-length="4"
            placeholder="Search PC Name Or UID" typeahead-editable="false">
        <a href="#" class="search-icon">
            <i class="fa fa-search" id="myimage"></i>
        </a>
    </div>
</div>

<div class="row mt-4 PCList">
    <span>PC List</span>
</div>
<div class="row mt-1 rowe" *ngFor="let item of list" >
    <!-- (click)="FacialRecDetails(item.id)"> -->
    <div class="col-12">
        <span class="pcname">Shrikant Nayak K</span>
        <span class="pccode">15990</span>
    </div>
    <div class="col-12">
        <span class="cmpname">Leroys Enterprises</span>
        <span class="cmpcode">7081572</span>
    </div>
    <div class="col-12">
        <span>
            <hr />
        </span>
    </div>
</div>