import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxImageCompressService } from 'ngx-image-compress';
import { ToastrService } from 'ngx-toastr';
import { Enclass, ResultClass } from 'src/app/models/encryption-class';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';
import { EncryptionService } from 'src/app/service/encryption.service';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { MatTooltip } from '@angular/material/tooltip';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { DataService } from 'src/app/service/data.service';

@Component({
  selector: 'app-new-ticket-log',
  templateUrl: './new-ticket-log.component.html',
  styleUrls: ['./new-ticket-log.component.css']
})
export class NewTicketLogComponent implements OnInit {
  private _searchText = '';
  filteredList: any;
  list: any;
  token: any;
  userType: any;
  formname: any;
  actiondesc: any;
  FieldName: any;
  FieldValue: any;
  userID: any = '';
  userName: string | null = '';
  device: any = ""
  deviceType: any = ""
  allowedPattern: any = /^[A-Za-z0-9@.\-, ]*$/
  ticketLogForm = this.fb.group({
    Subject: ['', [Validators.required, Validators.maxLength(50), Validators.pattern(this.allowedPattern)]],
    Module: ['', [Validators.required]],
    Category: ['', [Validators.required]],
    TicketFor: ['', [Validators.required]],
    PersonName: ['', []],
    mobNo: ['', [Validators.minLength(10), Validators.maxLength(10)]],
    Impact: ['', [Validators.required]],
    Urgency: ['', [Validators.required]],
    WatchList: ['', []],
    Description: ['', [Validators.required, Validators.maxLength(500)]],
  });

  public sendComment = false;
  public comment: any = "";
  public commentDate: any = "";
  selectedFile: any = "";
  public IsShowAttachmentList = false;
  ticketFor = ["Self Ticket", "Others Ticket"]
  watchListItems: any = []

  selectedAreaId: any
  @ViewChild('openDPNARGModal', { static: true }) elRefopenDataPrivacyAndAgreement: ElementRef | undefined;
  title: any = ""


  moduleList: any = []
  mainModuleList: any = []
  mainUrgencyList: any = []
  urgencyList: any = []
  selectedModule: any = ""
  selectedCategory: any = ""
  selectedUrgency: any = ""
  selectedImpact: any = ""
  categoyList: any = []
  impactList: any = []
  secretKey: any = ""
  clientId: any = ""
  isAttachementShowCompulsury: any = "N"
  mobileNo: any = ""
  emailID: any = ""
  priority: any = ""
  urgencyImpactDetails: any = []
  public enclass: Enclass = new Enclass();
  public resultClass: ResultClass = new ResultClass();


  httpOptions: HttpHeaders =
    new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      // 'Content-Type': 'multipart/form-data',
      'enctype': 'multipart/form-data',
      'charset': 'UTF-8',
      'FolderName': 'HelpDesk',
    })

  fileURL: any;
  fileName: string | undefined;
  file: any;
  localUrl: any;
  localCompressedURl: any;
  sizeOfOriginalImage: number = 0;
  sizeOFCompressedImage: number = 0;
  imgResultBeforeCompress: string = '';
  imgResultAfterCompress: string = '';
  isErrorMessageInToast: boolean = false
  photourlstr: string = ''
  fileList: any = [];
  itemControl = new FormControl();
  selectedWatchlistItems: any = []
  @ViewChild('tooltip', { static: true }) tooltip: MatTooltip | any;
  @ViewChild('impacttooltip', { static: true }) impacttooltip: MatTooltip | any;


  items: string[] = [];
  filteredItems: string[] = [];
  selectedItems = new FormControl([]);
  searchTerm: string = '';
  public searchCtrl: FormControl = new FormControl();

  // ****************
  public source: Array<{ text: string; value: number }> = [
    { text: 'Small', value: 1 },
    { text: 'Medium', value: 2 },
    { text: 'Large', value: 3 },
    { text: 'X-Large', value: 4 },
    { text: 'XX-Large', value: 5 },
  ];

  public data: Array<{ text: string; value: number }>;
  @ViewChild(CdkVirtualScrollViewport, { static: false }) cdkVirtualScrollViewport: CdkVirtualScrollViewport | any;

  uploadForm = this.fb.group({
    files: [null]
  });
  previewUrls: string[] = [];
  isWatchlisShow: boolean = true
  files: any;
  url: any = ""
  existingCommentImageName: any = ""
  branchName: any = ""
  Soterritory: any = ""
  @ViewChild('openExistingCommentImagesModal', { static: true }) elRefopenopenExistingCommentImagesModalModal: ElementRef | undefined;
  isImageUploaded: boolean = false
  constructor(private headerService: HeaderService, private modalService: NgbModal, private fb: FormBuilder,
    private cs: CommonUtilitiesService, private es: EncryptionService, private notifyMeService: NotifyMeService,
    private toastr: ToastrService, private http: HttpClient,
    private router: Router, public mds: MongoDataService, private imageCompress: NgxImageCompressService, private ds: DataService) {
    this.data = this.source.slice();
  }

  ngOnInit(): void {
    this.headerService.setTitle('New Ticket');
    this.headerService.setActiveURl('/newTicket');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.userID = localStorage.getItem("UserID");
    this.userName = localStorage.getItem("UserID");
    this.token = localStorage.getItem("TokenNo");
    this.userType = localStorage.getItem("UserType");
    this.FieldName = localStorage.getItem("FieldName");
    this.FieldValue = localStorage.getItem("FieldValue");
    this.secretKey = localStorage.getItem("SecreteKey")
    this.clientId = localStorage.getItem("ClientID");
    this.mobileNo = localStorage.getItem("MobileNo");
    this.emailID = localStorage.getItem("EmailID");
    this.branchName = localStorage.getItem("BranchName");
    this.Soterritory = localStorage.getItem("Soterritory");
    this.formname = "newTicket";
    this.actiondesc = "New Ticket"
    this.url = this.mds.url;
    // this.checkCameraPermissions()
    this.cs.GetGeoLocation(this.userID, this.userType, this.formname, this.actiondesc, this.FieldName, this.FieldValue);
    // this.onLoad()
    this.getModuleList()
    this.getMainListOfUrgencyAndImpact()
  }

  private async checkCameraPermissions(): Promise<void> {
    debugger
    try {
      // Check if permissions are granted
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      // If permissions are granted, stop the stream to release the camera
      stream.getTracks().forEach(track => track.stop());
    } catch (error) {
      // Show popup if permission is denied or any error occurs
      this.toastr.warning('Camera access is not allowed. Please enable camera permissions.');
    }
  }


  openTooltip() {
    this.tooltip.show();
    setTimeout(() => {
      this.tooltip.hide();
    }, 5000); // Hide after 3 seconds
  }

  openImpactTooltip() {
    this.impacttooltip.show();
    setTimeout(() => {
      this.impacttooltip.hide();
    }, 5000); // Hide after 3 seconds
  }

  onLoad() {
    this.mds.getUserDetails(this.userID, this.token, this.device, this.deviceType, 'newTicketLog').subscribe(res => {
      this.list = res;
      console.log("list", this.list)

      this.userType = this.list[0].userType.toUpperCase();
    }, (error: any) => {
      console.log(error)
    });
  }

  getModuleList() {
    let request = {
      "companyCode": "mdlz",
      "userId": this.userID,
    }
    this.es.Word = this.es.WordKey;
    this.es.encrypt(this.secretKey);
    this.es.Word = JSON.stringify(request);
    this.enclass.buggerstring = this.es.encrypt(this.secretKey)
    this.mds.HD_ModuleCategoryData_Bind(this.enclass, this.token, this.device, this.deviceType, this.formname, this.clientId).subscribe(res => {
      console.log("getSurveydetails", res)

      this.resultClass = res;
      this.es.Word = this.resultClass.resultString;
      let response = JSON.parse(this.es.decrypt(this.secretKey));

      console.log("response", response)
      this.mainModuleList = this.moduleList = []
      this.mainModuleList = response.Data
      this.moduleList = response.Data?.map((m: any) => m.module)
      console.log("this.moduleList", this.moduleList)

    }, (error: any) => {
      console.log("error", error)
    });

  }

  getMainListOfUrgencyAndImpact() {
    let request = {
      "companyCode": "mdlz",
      "userId": this.userID,
    }
    this.es.Word = this.es.WordKey;
    this.es.encrypt(this.secretKey);
    this.es.Word = JSON.stringify(request);
    this.enclass.buggerstring = this.es.encrypt(this.secretKey)
    this.mds.HD_urgencyImpactData_Bind(this.enclass, this.token, this.device, this.deviceType, this.formname, this.clientId).subscribe(res => {
      console.log("getSurveydetails", res)

      this.resultClass = res;
      this.es.Word = this.resultClass.resultString;
      let response = JSON.parse(this.es.decrypt(this.secretKey));

      console.log("response", response)
      this.mainUrgencyList = this.urgencyList = []
      this.mainUrgencyList = response.Data
      this.urgencyList = response.Data?.map((m: any) => m.urgency)
      console.log("this.urgencyList", this.urgencyList)

      this.HD_UserMasterData()
    }, (error: any) => {
      console.log("error", error)
    });
  }

  onChangeModule(event: any) {
    debugger
    this.selectedModule = event?.target?.value

    let list = this.mainModuleList?.filter((f: any) => f.module == this.selectedModule)
    console.log("list", list)
    this.categoyList = []
    this.categoyList = list[0]?.moduleDetails
    console.log("this.categoyList", this.categoyList)
  }

  onChangeCategory(event: any) {
    this.selectedCategory = event?.target?.value

    this.isAttachementShowCompulsury = this.categoyList?.filter((f: any) => f.category == this.selectedCategory)?.map((m: any) => m.document)
  }

  onChangeUrgency(event: any) {
    debugger
    this.selectedUrgency = event?.target?.value

    let list = this.mainUrgencyList?.filter((f: any) => f.urgency == this.selectedUrgency)
    console.log("list", list)
    this.impactList = []
    this.urgencyImpactDetails = list[0]?.urgencyImpactDetails
    console.log("urgencyImpactDetails", this.urgencyImpactDetails)
    this.impactList = list[0]?.urgencyImpactDetails?.map((m: any) => m.impact)
    console.log("this.impactList", this.impactList)
  }

  onChangeImpact(event: any) {
    this.selectedImpact = event?.target?.value

    for (let i = 0; i < this.urgencyImpactDetails.length; i++) {
      if (this.urgencyImpactDetails[i].impact == this.ticketLogForm.controls['Impact']?.value) {
        this.priority = this.urgencyImpactDetails[i].priority
      }
    }
  }


  HD_UserMasterData() {
    debugger
    let request = {
      "companyCode": "mdlz",
      "userId": this.userID,
      "userType": this.userType
    }
    this.es.Word = this.es.WordKey;
    this.es.encrypt(this.secretKey);
    this.es.Word = JSON.stringify(request);
    this.enclass.buggerstring = this.es.encrypt(this.secretKey)
    this.mds.HD_UserMasterData(this.enclass, this.token, this.device, this.deviceType, this.formname, this.clientId).subscribe(res => {
      console.log("getSurveydetails", res)

      this.resultClass = res;
      this.es.Word = this.resultClass.resultString;
      let response = JSON.parse(this.es.decrypt(this.secretKey));

      console.log("response", response)
      this.watchListItems = []
      this.watchListItems = response.Data

      for (let i = 0; i < this.watchListItems?.length; i++) {
        this.watchListItems[i].id = i
        this.watchListItems[i].isChecked = false
      }

      console.log("this.watchListItems", this.watchListItems)
    }, (error: any) => {
      console.log("error", error)
    });
  }

  commentSend(comment: any, isRequired: any = false) {
    debugger
    if ((comment.trim() == "" && this.selectedFile == "") && isRequired == true) {
      this.toastr.warning("Please enter comment or  attachment")
      return;
    }
    else if ((comment.trim() != "" || this.selectedFile != "")) {
      this.sendComment = true;
    }
    else {
      this.sendComment = false;
    }


    let currentDate = new Date();
    let month = currentDate.getMonth() + 1;
    var date = currentDate.getDate();
    var year = currentDate.getFullYear()
    this.commentDate = date + "-" + month + "-" + year;

    (<HTMLInputElement>document.getElementById("updateInputBox")).value = ""

    setTimeout(() => {
      (<HTMLInputElement>document.getElementById('test'))?.scrollIntoView();
    }, 2)
  }

  attachmentOption() {
    // this.IsShowAttachmentList=(! this.IsShowAttachmentList);
    this.openModal(this.elRefopenDataPrivacyAndAgreement);
  }


  receivedSelectedList(rows: any) {
    debugger
    console.log("rows", rows)

    this.isWatchlisShow = false
    setTimeout(() => {
      this.isWatchlisShow = true
      this.watchListItems = this.watchListItems.sort((a: any, b: any) => b.isChecked - a.isChecked);
    }, 1);

    this.selectedWatchlistItems = []
    for (let i = 0; i < rows.length; i++) {
      this.selectedWatchlistItems.push({
        userId: rows[i].userId,
        emailId: rows[i].emailId,
        userName: rows[i].userName
      })
    }

    console.log("selectedWatchlistItems", this.selectedWatchlistItems)
  }

  openModal(modal: ElementRef<any> | undefined) {
    this.modalService.open(modal,
      {
        size: 'lg',
        backdrop: 'static',
        windowClass: '',
        keyboard: false,
        centered: true,
      })
  }

  closeAttachment() {
    this.IsShowAttachmentList = false
  }

  modalClose() {
    this.modalService.dismissAll();
  }

  onFileSelected(event: any) {
    // this.attachments[event.target.name] = event.target.files[0].name;
    // this.selectedFile = event.target.files[0];
    // const formData = new FormData();
    // this.imgURL = event.target.files[0].name;
    // formData.append('file', this.selectedFile, this.marketAuditData[this.currentQueNo]?.auditid + "_" + this.attachments[event.target.name]);
    // document.getElementById("sendIcon")?.click();
    // this.UploadFileToS3Bucket(formData);
    // this.closeAttachment();
  }

  // numberOnly(event: { which: any; keyCode: any; }): boolean {
  //   const charCode = event.which ? event.which : event.keyCode;
  //   if (charCode > 31 && (charCode < 48 || charCode > 57)) {
  //     return false;
  //   }
  //   return true;
  // }

  numberOnly(event: KeyboardEvent) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }


  // subjectKeyPreessEventOnly(e: any) {  // Accept only alpha numerics, not special characters 
  //   debugger
  //   let allowSpecialChar = "-@,. "
  //   allowSpecialChar = allowSpecialChar.replace("-", "\-")
  //   let regex = new RegExp("^[A-Za-z0-9" + allowSpecialChar + "]+$")
  //   var str = e.target.value
  //   if (!regex.test(str)) {
  //     e.preventDefault();
  //   }
  // }


  subjectKeyPreessEventOnly(event: KeyboardEvent) {
    const inputChar = String.fromCharCode(event.charCode);
    if (!this.allowedPattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  subjectPreventPaste(event: ClipboardEvent) {
    let clipboardData: any
    clipboardData = (event.clipboardData || window.Clipboard);
    const pastedText = clipboardData.getData('text');
    if (!this.allowedPattern.test(pastedText)) {
      event.preventDefault();
    }
  }

  preventPaste(event: ClipboardEvent) {
    let clipboardData: any
    clipboardData = (event.clipboardData || window.Clipboard);
    const pastedText = clipboardData.getData('text');
    if (/\D/.test(pastedText)) {
      event.preventDefault();
    }
  }

  raisedTicketClick() {
    debugger
    console.log("form", this.ticketLogForm)

    // if(this.isErrorMessageInToast){
    //   document.getElementById("outlet_Name")?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    //   return
    // }
    if (this.isAttachementShowCompulsury[0] == "Y" && this.fileList?.length == 0) {
      this.toastr.warning("Please Upload Attachment")
      return;
    }

    if ((this.ticketLogForm.controls['TicketFor'].value == "Others Ticket") && this.ticketLogForm.controls['PersonName'].value == "") {
      this.toastr.warning("Please fill Contact Person Name")
      return
    }
    if ((this.ticketLogForm.controls['TicketFor'].value == "Others Ticket") && this.ticketLogForm.controls['mobNo'].value == "") {
      this.toastr.warning("Please fill Mobile No Details")
      return
    }
    if ((this.ticketLogForm.controls['TicketFor'].value == "Others Ticket") && this.ticketLogForm.controls['mobNo'].value.length != 10) {
      this.toastr.warning("Mobile number must be 10 digits")
      return
    }

    else {
      if (this.ticketLogForm?.valid) {
        let request = {
          "companyCode": "mdlz",
          "userType": this.userType,
          "userId": this.userID,
          "userName": this.userName,
          "mobileNo": this.mobileNo,
          "emailId": this.emailID,
          "ticketFor": this.ticketLogForm.controls['TicketFor'].value,
          "contactPersonName": this.ticketLogForm.controls['PersonName']?.value,
          "contactPersonMobile": this.ticketLogForm.controls['mobNo']?.value,
          "subject": this.ticketLogForm.controls['Subject']?.value,
          "fileAttachmentName": this.fileList.map((m: any) => m),
          "watchList": this.selectedWatchlistItems,
          "category": this.ticketLogForm.controls['Category']?.value,
          "module": this.ticketLogForm.controls['Module']?.value,
          "impact": this.ticketLogForm.controls['Impact']?.value,
          "urgency": this.ticketLogForm.controls['Urgency']?.value,

          "priority": this.priority,
          "agentUser": "",
          "ticketOwner": "",
          "ticketSource": "Salegenie",
          "ticketStatus": "New",
          "ticketOwnerType": "",
          "description": this.ticketLogForm.controls['Description']?.value,
          "branch": this.branchName,
          "soterritory": this.Soterritory
        }

        console.log("request", request)
        this.es.Word = this.es.WordKey;
        this.es.encrypt(this.secretKey);
        this.es.Word = JSON.stringify(request);
        this.enclass.buggerstring = this.es.encrypt(this.secretKey)
        this.mds.Insert_TicketDetails(this.enclass, this.token, this.device, this.deviceType, this.formname, this.clientId).subscribe(res => {
          console.log("getSurveydetails", res)

          this.resultClass = res;
          this.es.Word = this.resultClass.resultString;
          let response = JSON.parse(this.es.decrypt(this.secretKey));

          this.toastr.success(response.InsertRecords)
          this.router.navigate(['/home/ticketLogList'])

          this.getPushNotificationONInsertRecord(response.InsertRecords)
          this.getHD_NotificationSave(response.InsertRecords)
        }, (error: any) => {
          console.log("error", error)
        });
      } else {
        this.toastr.warning("Please fill all details")
        return
      }
    }

  }

  getPushNotificationONInsertRecord(response: any) {
    let request = {
      "title": "Ticket Creation",
      "Body": response,
      "Userid": this.userID

    }

    console.log("request", request)
    this.es.Word = this.es.WordKey;
    this.es.encrypt(this.secretKey);
    this.es.Word = JSON.stringify(request);
    this.enclass.buggerstring = this.es.encrypt(this.secretKey)
    this.mds.PushNotification(this.enclass, this.device, this.deviceType, this.formname, this.clientId).subscribe(res => {
      console.log("getSurveydetails", res)

      this.resultClass = res;
      this.es.Word = this.resultClass.resultString;



    }, (error: any) => {
      console.log("error", error)
    });
  }

  getHD_NotificationSave(response: any) {
    let request = {
      companyCode: "mdlz",
      userid: this.userID,
      createdBy: this.userID,
      notificationHeader: "ticket Creation",
      notificationText: response

    }

    console.log("request", request)
    this.es.Word = this.es.WordKey;
    this.es.encrypt(this.secretKey);
    this.es.Word = JSON.stringify(request);
    this.enclass.buggerstring = this.es.encrypt(this.secretKey)
    this.mds.HD_NotificationSave(this.enclass, this.device, this.deviceType, this.formname, this.clientId).subscribe(res => {
      console.log("getSurveydetails", res)

      this.resultClass = res;
      this.es.Word = this.resultClass.resultString;

    }, (error: any) => {
      console.log("error", error)
    });
  }

  removeAttachment(ind: any) {
    debugger
    this.fileList.splice(ind, 1);
    this.previewUrls.splice(ind, 1)
  }


  isValidFileType(fileName: string): boolean {
    const allowedExtensions = ['jpg', 'jpeg', "zip", "doc", "docx", "txt", "xlsx", "7z", "pdf", "jpg", "pptx", "msg", "png"];
    const fileExtension = fileName.split('.').pop()?.toLowerCase();
    return allowedExtensions.includes(fileExtension || '');
  }


  // fileProgress(event: any) {
  //   debugger
  //   const input = event.target as HTMLInputElement;
  //   this.fileURL = <File>event.target.files[0]
  //   if (input.files) {
  //     var today = new Date();
  //     var day = today.getDate() + "";
  //     var month = today.getMonth() + 1 + "";
  //     month = (month.length == 1 ? "0" : "") + month + ""
  //     var year = today.getFullYear() + "";
  //     var hour = today.getHours() + "";
  //     var minutes = today.getMinutes() + "";
  //     var seconds = today.getSeconds() + "";
  //     let todaysDate = year + month + day + hour + minutes + seconds
  //     const files = Array.from(input.files);

  //     const validFiles = files.filter((file: any) =>
  //       this.isValidFileType(file.name)

  //     );


  //     this.uploadForm.patchValue({
  //       files: files
  //     });
  //     console.log("this.uploadForm", this.uploadForm)
  //     files.forEach((file: any, index: any) => {
  //       const reader = new FileReader();
  //       reader.onload = (e: any) => {
  //         const formData = new FormData()
  //         this.previewUrls.push(e.target.result);
  //         var fileName: any;
  //         this.file = event.target.files[index];
  //         fileName = this.file['name'];
  //         this.localUrl = e.target.result;
  //         var orientation = -1;
  //         this.sizeOfOriginalImage = this.imageCompress.byteCount(this.localUrl) / (2500 * 2500);

  //         this.imageCompress.compressFile(this.localUrl, orientation, 75, 75).then(result => {
  //           this.imgResultAfterCompress = result;
  //           console.log("this.imgResultAfterCompress", this.imgResultAfterCompress)
  //           this.localCompressedURl = result;
  //           this.sizeOFCompressedImage = this.imageCompress.byteCount(result) / (2500 * 2500)
  //           console.log("this.sizeOFCompressedImage", this.sizeOFCompressedImage)

  //           const imageName = fileName + "_" + todaysDate;
  //           console.log("fileName", fileName)
  //           const imageBlob = this.dataURItoBlob(this.imgResultAfterCompress.split(',')[1]);
  //           const imageFile = new File([imageBlob], imageName, { type: 'image/jpg, image/jpeg' });

  //           var fileNameSplit = imageName.split(".")
  //           console.log("this.fileURL.name", fileNameSplit.slice(0, fileNameSplit.length - 1).join("") + "_" + todaysDate + "." + this.fileURL.name.split(".")[fileNameSplit.length - 1])
  //           formData.append('file', imageFile, fileNameSplit.slice(0, fileNameSplit.length - 1).join("") + "_" + todaysDate + "." + this.fileURL.name.split(".")[fileNameSplit.length - 1]);
  //           this.fileList.push(fileNameSplit.slice(0, fileNameSplit.length - 1).join("") + "_" + todaysDate + "." + this.fileURL.name.split(".")[fileNameSplit.length - 1]);
  //           console.log("fileList",this.fileList)
  //           this.http.post(this.mds.url + 'MeraNet/UploadFileToS3Bucket', formData, { reportProgress: true, observe: 'events', headers: this.httpOptions, responseType: 'blob' as 'json' }).subscribe(res => {
  //             console.log("ATOBUploadFile", res)
  //           })
  //         })
  //       }
  //       reader.readAsDataURL(file);
  //     });

  //   }
  // }

  fileProgress(event: any) {
    debugger
    const input = event.target as HTMLInputElement;
    this.fileURL = <File>event.target.files[0]
    if (input.files) {
      var today = new Date();
      var day = today.getDate() + "";
      var month = today.getMonth() + 1 + "";
      month = (month.length == 1 ? "0" : "") + month + ""
      var year = today.getFullYear() + "";
      var hour = today.getHours() + "";
      var minutes = today.getMinutes() + "";
      var seconds = today.getSeconds() + "";
      let todaysDate = year + month + day + hour + minutes + seconds
      this.files = Array.from(input.files);

      console.log("files", this.files)
      const validFiles = this.files.filter((file: any) =>
        this.isValidFileType(file.name)

      );


      this.uploadForm.patchValue({
        files: this.files
      });
      console.log("this.uploadForm", this.uploadForm)
      this.files.forEach((file: any, index: any) => {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          const formData = new FormData()
          this.previewUrls.push(e.target.result);
          console.log("previewUrls", this.previewUrls)
          var fileName: any;
          this.file = event.target.files[index];
          fileName = this.file['name'];
          this.localUrl = e.target.result;
          var orientation = -1;
          this.sizeOfOriginalImage = this.imageCompress.byteCount(this.localUrl) / (2500 * 2500);

          // this.imageCompress.compressFile(this.localUrl, orientation, 75, 75).then(result => {
          // this.imgResultAfterCompress = result;
          // console.log("this.imgResultAfterCompress", this.imgResultAfterCompress)
          // this.localCompressedURl = result;
          // this.sizeOFCompressedImage = this.imageCompress.byteCount(result) / (2500 * 2500)
          // console.log("this.sizeOFCompressedImage", this.sizeOFCompressedImage)

          const imageName = fileName + "_" + todaysDate;
          console.log("fileName", fileName)
          const imageBlob = this.dataURItoBlob(e.target.result.split(',')[1]);

          const acceptedTypes: any = [
            'application/zip',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'text/plain',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/x-7z-compressed',
            'application/pdf',
            'application/pptx',
            'image/jpeg',
            'image/jpg',
            'image/png',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation',
          ];
          const imageFile = new File([imageBlob], imageName, { type: acceptedTypes });

          var fileNameSplit = imageName.split(".")
          console.log("this.fileURL.name", fileNameSplit.slice(0, fileNameSplit.length - 1).join("") + "_" + todaysDate + "." + this.file.name.split(".")[fileNameSplit.length - 1])
          formData.append('file', imageFile, fileNameSplit.slice(0, fileNameSplit.length - 1).join("") + "_" + todaysDate + "." + this.file.name.split(".")[fileNameSplit.length - 1]);
          this.fileList.push(fileNameSplit.slice(0, fileNameSplit.length - 1).join("") + "_" + todaysDate + "." + this.file.name.split(".")[fileNameSplit.length - 1]);
          console.log("fileList", this.fileList)
          this.isImageUploaded = false

          this.http.post(this.mds.url + 'MeraNet/UploadFileToS3Bucket', formData, { reportProgress: true, observe: 'events', headers: this.httpOptions, responseType: 'blob' as 'json' }).subscribe((res: any) => {
            console.log("ATOBUploadFile", res)
            if (res?.status == 200) {
              this.isImageUploaded = true
            }
          })




          // })
        }
        reader.readAsDataURL(file);
      });

    }
  }


  downloadFile(imgName: any) {
    debugger
    if (imgName?.split('.')?.pop()?.toLowerCase() == "jpg" || imgName?.split('.')?.pop()?.toLowerCase() == "jpeg" || imgName?.split('.')?.pop()?.toLowerCase() == "png") {
      this.existingCommentImageName = imgName
      this.openModal(this.elRefopenopenExistingCommentImagesModalModal);
    } else {
      this.ds.getDownloadFileFromS3Bucket("HelpDesk", imgName).subscribe((res) => {
        let anchor = document.createElement("a");
        anchor.href = res.url;
        anchor.id = "xyz";
        (<HTMLBodyElement>document.getElementsByTagName("body")[0]).appendChild(anchor)
        document.getElementById("xyz")?.click();
        document.getElementsByTagName("body")[0].removeChild(anchor)

      }, (error) => { console.log("error", error) });
    }
  }

  dataURItoBlob(dataURI: string) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpg, image/jpeg, image/png,application/zip,application/pdf,application/txt,application/vnd.ms-outlook,application/kswps,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
    return blob;
  }



  upload(file: any) {
    debugger
  }
}
