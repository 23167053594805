import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { DatePipe } from '@angular/common';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { InputPara } from 'src/app/models/kpi-models';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';
@Component({
  selector: 'app-search-outlet-pulloutreq',
  templateUrl: './search-outlet-pulloutreq.component.html',
  styleUrls: ['./search-outlet-pulloutreq.component.css']
})
export class SearchOutletPulloutreqComponent implements OnInit {
  deviceType: any;
  device: any;
  isLongPress: boolean = false;
  isSelected: boolean = false;
  list: any;
  searchText = '';
  userType: any;
  userId: any;
  fieldName: any;
  fieldValue: any;
  input:InputPara=new InputPara();
  token: any;
  formname:any;
  actiondesc:any;

  constructor(private activatedRoute: ActivatedRoute,private cs:CommonUtilitiesService,  private router: Router, private headerService: HeaderService, private datePipe: DatePipe, private mds: MongoDataService, private notifyMeService:NotifyMeService,) { }

  ngOnInit(): void {
    this.headerService.setTitle('Search Outlet');
    this.headerService.setActiveURl('/searchOutletPulloutreq');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.userId = localStorage.getItem("UserID");
    this.userType = localStorage.getItem("UserType");
    this.fieldName=localStorage.getItem("OriginalFieldName");
    this.fieldValue=localStorage.getItem("OriginalFieldValue");
    this.token = localStorage.getItem("TokenNo");

    this.formname = "searchOutletPulloutreq"
    this.actiondesc = "Search Outlet Pull Out Req";
    this.cs.GetGeoLocation(this.userId, this.userType, this.formname, this.actiondesc, this.fieldName, this.fieldValue);
  }

  onLongPress() {
    this.isLongPress = true;
  }

  clickOnSelect() {
    this.isSelected = true;
  }

  rowClick(selected: any) {
    localStorage.setItem("outLetSelected", JSON.stringify(selected));
    this.router.navigate(['/home/outletDetailPulloutreq']);
  }

  searchOutlet(OutletUID: string, Status: string) {
    debugger;
    if (OutletUID != '') {
      this.input.FieldName=this.fieldName;
      this.input.FieldValue=this.fieldValue;
      this.input.TagName=OutletUID;
      this.input.Datatype= "Pullout";
      this.input.UserID=this.userId;
      this.mds.getOutletDetailByRDWise(this.input,this.token,this.device,this.deviceType,'searchOutletPulloutreq').subscribe(res => {
        console.log("getOutletDetailByRDWise", res);
        debugger;
        this.list = res;
        if(this.list.length==0){
          this.notifyMeService.showWarning('Outlet Details not found for this Outlet Code', 'Sales Genie App');
        }
      });
    }
    else {
      this.notifyMeService.showWarning('Outlet code should not be blank', 'Sales Genie App');
    }
  }

}
