<div class="mt-4">
    <!-- <div class="row">
        <div class="col-12">
            <span class="text_14 text_black">Name</span><br>
            <span class="text_14 text_black mt-1 mt-1" style="opacity: 40%;">{{outletCodeDetails.outletName}}</span>
        </div>
    </div>
    <hr> -->
    <div class="row">
        <div class="col-12">
            <span class="text_14 text_black">RD Name</span><br>
            <span class="text_14 text_black mt-1" style="opacity: 40%;">{{outletCodeDetails.rdName}}</span>
        </div>
    </div>

    <hr>
    <div class="row">
        <div class="col-12">
            <span class="text_14 text_black">Outlet Name</span><br>
            <span class="text_14 text_black mt-1" style="opacity: 40%;">{{outletCodeDetails.outletName}}</span>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-12">
            <span class="text_14 text_black">OIF NO</span><br>
            <span class="text_14 text_black mt-1" style="opacity: 40%;">{{outletCodeDetails.oifNo}}</span>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-12">
            <span class="text_14 text_black">Visicooler</span><br>
            <span class="text_14 text_black mt-1" style="opacity: 40%;">{{outletCodeDetails.visicooler}}</span>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-12">
            <span class="text_14 text_black">DIS Print User</span><br>
            <span class="text_14 text_black mt-1" style="opacity: 40%;">{{outletCodeDetails.disPrintUser}}</span>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-12">
            <span class="text_14 text_black">Deployment Date</span><br>
            <span class="text_14 text_black mt-1" style="opacity: 40%;">{{outletCodeDetails.deploymentDate}}</span>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-12">
            <span class="text_14 text_black">QR Code</span><br>
            <span class="text_14 text_black mt-1" style="opacity: 40%;">{{outletCodeDetails.qrCode}}</span>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-12">
            <span class="text_14 text_black">Location</span><br>
            <span class="text_14 text_black mt-1" style="opacity: 40%;">{{outletCodeDetails.location}}</span>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-12">
            <span class="text_14 text_black">VC Taggged Date</span><br>
            <span class="text_14 text_black mt-1" style="opacity: 40%;">{{outletCodeDetails.vcTagggedDate}}</span>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-12">
            <span class="text_14 text_black">VCC Tagged By</span><br>
            <span class="text_14 text_black mt-1" style="opacity: 40%;">{{outletCodeDetails.vccTaggedBy}}</span>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-12">
            <span class="text_14 text_black">DIS Cancel Date</span><br>
            <span class="text_14 text_black mt-1" style="opacity: 40%;">{{outletCodeDetails.disCancelDate}}</span>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-12">
            <span class="text_14 text_black">DIS Cancel userID</span><br>
            <span class="text_14 text_black mt-1" style="opacity: 40%;">{{outletCodeDetails.disCanceluserID}}</span>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-12">
            <span class="text_14 text_black">DIS Cancel reason</span><br>
            <span class="text_14 text_black mt-1" style="opacity: 40%;">{{outletCodeDetails.disCancelreason}}</span>
        </div>
    </div>
    <!-- <hr>

   <div class="row">
        <div class="col-12">
            <span class="text_14 text_black">Rd Conformation Date</span><br>
            <span class="text_14 text_black mt-1" style="opacity: 40%;">{{outletCodeDetails.rdConfirmationDate}}</span>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-12">
            <span class="text_14 text_black">Installation Date</span><br>
            <span class="text_14 text_black mt-1" style="opacity: 40%;">{{outletCodeDetails.installationDate}}</span>
        </div>
    </div> -->
    <hr>
    <div class="row mt-3">
        <div class="col-8">
            <span class="text_14 text_black">Cooler Photo</span>
        </div>
        <div class="col-4">
            <img [src]="url+'MeraNet/GetDownloadFileFromS3Bucket/Deploymentimages/' + outletCodeDetails.coolerPhoto"
                alt="Image" class="img-fluid float-end">
        </div>
    </div>
    <hr>

    <div class="row mt-4">
        <div class="col-8">
            <span class="text_14 text_black">Outlet Exterior Photo</span>
        </div>
        <div class="col-4">
            <img [src]="url+'MeraNet/GetDownloadFileFromS3Bucket/Deploymentimages/' + outletCodeDetails.outletPhotoWithCooler"
                alt="Image" class="img-fluid float-end">
        </div>
    </div>
    <hr>
    <div class="row mt-4">
        <div class="col-8">
            <span class="text_14 text_black">Owner ID Proof</span>
        </div>
        <div class="col-4">
            <img [src]="url + 'MeraNet/GetDownloadFileFromS3Bucket/Deploymentimages/' + outletCodeDetails.outletIDCard"
                alt="Image" class="img-fluid float-end">
        </div>
    </div>
    <hr>
    <div class="row mt-4">
        <div class="col-8">
            <span class="text_14 text_black">Agreement page1</span>
        </div>
        <div class="col-4">
            <img [src]="url + 'MeraNet/GetDownloadFileFromS3Bucket/Deploymentimages/' + outletCodeDetails.agreementpage1"
                alt="Image" class="img-fluid float-end">
        </div>
    </div>
    <hr>
    <div class="row mt-4">
        <div class="col-8">
            <span class="text_14 text_black">Agreement page2</span>
        </div>
        <div class="col-4">
            <img [src]="url + 'MeraNet/GetDownloadFileFromS3Bucket/Deploymentimages/' + outletCodeDetails.agreementpage2"
                alt="Image" class="img-fluid float-end">
        </div>
    </div>
    <hr>
    <div class="row mt-4">
        <div class="col-8">
            <span class="text_14 text_black">DIS page1</span>
        </div>
        <div class="col-4">
            <img [src]="url + 'MeraNet/GetDownloadFileFromS3Bucket/Deploymentimages/' + outletCodeDetails.diSpage1"
                alt="Image" class="img-fluid float-end">
        </div>
    </div>
    <hr>

    <div class="row">
        <div class="col-9 mt-3">
            <span class="text_14 text_black">E-Agreement</span>
        </div>
        <div class="col-3 text-center mt-3 word_break">
            <a class="img-fluid downloadDPNAgr" (click)="downloadDPNAgr()">Download E-Agreement</a>
        </div>
    </div>

    <hr>
    <div class="row">
        <div class="col-12">
            <span class="text_14 text_black"></span>
            <span class="text_14 text_black mt-1" style="opacity: 40%;"></span>
        </div>
    </div>
    <hr><br><br>
</div>