import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from 'src/app/service/header.service';
import { FilterComponent } from '../filter/filter.component';
import { MatDialog } from '@angular/material/dialog';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import {InputPara} from 'src/app/models/kpi-models';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { DataService } from 'src/app/service/data.service';
@Component({
  selector: 'app-green-outlet',
  templateUrl: './green-outlet.component.html',
  styleUrls: ['./green-outlet.component.css']
})
export class GreenOutletComponent implements OnInit {
  FieldName: any;
  FieldValue: any;
  DataType: any;
  UserID: any;
  GreenOutlets: any;
  UserType: any;
  deviceType: any;
  device: any;
  periodList: any[] = [];
  selectedperiod: any;
input:InputPara=new InputPara();
  token: any;
  lat:any;
  lng:any;

  constructor(private dialog: MatDialog,private activatedRoute: ActivatedRoute,
     private router: Router, private headerService: HeaderService,
     private mds: MongoDataService,private notifyMeService: NotifyMeService,private ds: DataService,) { }

  ngOnInit(): void {
    this.headerService.setActiveURl('/GreenOutlet');
    this.headerService.setTitle('Green Outlet');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.token = localStorage.getItem("TokenNo");
    this.selectedperiod=localStorage.getItem("selectedperiod");
    this.UserType = localStorage.getItem("UserType");
    this.activatedRoute.paramMap.forEach(params => {
      this.FieldValue = params.get('FieldValue');
      this.FieldName = params.get('FieldName');
      this.UserID = params.get('UserID');
      this.DataType = params.get('DataType');
      this.selectedperiod = params.get('selectedperiod');
      debugger
      this.GetGeoLocation();
      this.loadData();
    });
  }

  GetGeoLocation() {
    debugger
    if (navigator) {
      navigator.geolocation.getCurrentPosition(pos => {
        this.lat = pos.coords.latitude;
        console.log("  this.lat", this.lat)
        this.lng = pos.coords.longitude;
        this.SetapplicationLogData();
      }, this.handleLocationError, { timeout: 30000 });
    }
    else {
      this.notifyMeService.showError('Location Could not be captured', 'Mera APP');
    }
  }

  handleLocationError(error: { code: any; }) {
    switch (error.code) {
      case 3:
        this.notifyMeService.showError('Timeout in Position capture', 'Mera APP V2');
        break;
      case 2:
        this.notifyMeService.showError('Device is not capable of Position capture', 'Mera APP V2');
        break;
      case 1:
        this.notifyMeService.showError('You denied for Position capture', 'Mera APP V2');
        break;
    }
  }


  SetapplicationLogData() {
    debugger
    let request = {
      "userid": this.UserID,
      "usertype": this.UserType,
      "formname": "GreenOutlet",
      "actiondesc": "Green Outlet" + " " + this.FieldName,
      "fieldname": this.FieldName,
      "fieldvalue": this.FieldValue,
      "latitude": this.lat,
      "longitude": this.lng
    }
    this.ds.SetapplicationLogData(request).subscribe(res => {
      console.log("res", res)
    })
  }

  loadData(): void {
    this.input.FieldName=this.FieldName;
    this.input.FieldValue=this.FieldValue;
    this.input.TagName="0";
    this.input.Datatype= this.DataType;
    this.input.UserID=this.UserID;
      this.mds.getGreenOutlets(this.input, this.token, this.device, this.deviceType,'Green Outlet').subscribe(res => {
      this.GreenOutlets = res;
      this.periodList = [...new Set(res.map((bill: { period: any; }) => bill.period))].sort();
    });
  }
  RowClick(e:any): void{
    if(this.FieldName!="RD"){
     localStorage.setItem("selectedperiod",this.selectedperiod);
    this.FieldName=this.FieldName.toUpperCase()=='ALLINDIA'?'BRANCH':this.FieldName.toUpperCase()=='BRANCH'?'REGION': this.FieldName.toUpperCase()=='REGION'?'ASMAREA': this.FieldName.toUpperCase()=='ASMAREA'?'SOTERRITORY' : this.FieldName.toUpperCase()=='SOTERRITORY'?'RD' : 'RD';
    this.router.navigate(['/home/GreenOutlet/',this.FieldName, e, this.DataType,this.UserID,this.selectedperiod]);
    }
 }
 PeriodChange(e: any): void {
  this.selectedperiod = e.source._value;
}
filterItemsOfType(type: any) {
  return type != null ? type.filter((x: { [x: string]: any; }) => (x['period'] == this.selectedperiod)) : null;
}
  openFilter(){
    if (this.dialog.openDialogs.length == 0) {
      const dialogRef = this.dialog.open(FilterComponent, {
        closeOnNavigation: true,
        data: {
          "FieldName": localStorage.getItem("OriginalFieldName"),
          "FieldValue": localStorage.getItem("OriginalFieldValue"),
          "UserID": this.UserID,
          "MenuUpTo": "5",
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.FieldName=result.FieldName;
          this.FieldValue=result.FieldValue;
          this.loadData();
              }
      });
    }
  }
}
