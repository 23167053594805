<div>
    <div class="row mt-4">
        <div class="col-12">
            <input class="fullWidth" type="text" name="" id="" placeholder="Search Beat Name" [(ngModel)]="searchTerm">
        </div>
    </div>

    <!-- <div class="row mt-4">
        <div class="col-12">        
          <input class="fullWidth" type="text" placeholder="Search Beat Name" list="dynmicUserIds" (keyup)="getUserIdsFirstWay($event)" id="userIdFirstWay" (change)="SearchText($event)" autocomplete="off">
          <datalist id="dynmicUserIds">
            <option *ngFor="let item of userList1" [value]="item">{{item}}</option>
          </datalist>
        </div>
      </div> -->

    <div class="row mt-3 mb-3">
        <div class="col-4 purpleColor text_rdList">
            Beat List
        </div>
        <div class="col-8 text-end">
            <img class="img-fluid" src="assets/sec_dot.png" />
            <span class="achivement mr-2 purpleColor"> Achievement</span>
            <img class="img-fluid" src="assets/pri_dot.png" />
            <span class="target purpleColor ml-1">Beat Potential</span>
        </div>
    </div>

    <div class="bodyEle">
        <div class="" *ngFor="let item of filteredList;let i=index" (click)="clickOnBeatList(item)">
            <div class="row" *ngIf="item.ispjpbeat.toLowerCase()=='y'">
                <div class="col-8">
                    <span class="todaysBeat"> 
                        Today's Beat
                        <img class="" src="assets/done.png" />
                    </span>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-9">
                    <span class="Head_text ml-3">{{item.beatname}}</span>
                </div>
                <div class="col-3">
                    <div class="float-end">
                        <span class="Head_text">Outlet</span>
                        <span class="ml-1 Head_Val_txt ">{{getOutletCountData[i]}}</span>
                    </div>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-3">
                    <div class="row">
                        <div class="col-12 text-center">
                            <h1 class="m-0 text_Per purpleColor">{{item.beatachper}}%</h1>
                        </div>
                    </div>
                </div>
                <div class="col-9">
                    <div class="row mt-3">
                        <div class="col-12">
                            <div class="progress rounded-pill">
                                <div class="progress-bar bg_sec" role="progressbar" [style.width.%]="item.beatach"
                                    aria-valuenow="20" aria-valuemin="0" aria-valuemax="100"></div>
                                <div class="progress-bar bg_pri" role="progressbar" [style.width.%]="item.beattarget"
                                    aria-valuenow="40" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2 mb-4">
                        <div class="col-6 text-center">
                            <h2 class="m-0 vp_Bar_value">{{item.beatach}}<span class="text_10"> Lac</span></h2>
                        </div>
                        <div class="col-6 text-center">
                            <h2 class="m-0 vp_Bar_value">{{item.beattarget}}<span class="text_10"> Lac</span></h2>
                        </div>
                    </div>
                </div>
            </div>
            <hr style="margin: 0px;">
        </div>
    </div>

</div>