<div class="container-fluid">

    <div class="row mt-4">
        <div class="col-8 pull-left">
            <i class="fa fa-caret-left" style="color: #757575;">&nbsp;&nbsp;
            <span class="header_text">{{FieldValue=='0'? 'All India' : FieldValue=='ALLINDIA' ? 'All India': FieldValue |titlecase}}</span>
            </i>
        </div>
        <div class="col-4 text-right">
            <img src="../../../assets/filter.png" class="img-fluid" (click)="openFilter()" /><sup><i
                    class="fa fa-circle fa-sm" style="color: #eaab5e;font-size: 5px;"></i></sup>&nbsp;
            <span class="Filter">Filter</span>
            <!-- style="color: #724e8c;font-family: Roboto-Medium;font-weight:400 ;font-size: 14px;" -->
        </div>
    </div>
    <br>
    <div class="row mt-2">
        <div class="col-12 text-right">
            <span class="CurrentActive"><i class="fa fa-circle fa-sm" style="color: #d17b12">&nbsp;</i>Active since Morning</span>&nbsp;
            <span class="Last30minAct"><i class="fa fa-circle fa-sm" style="color: #eaab5e">&nbsp;</i>Last 30min Act</span>&nbsp;
            <span class="PhyActive"><i class="fa fa-circle fa-sm" style="color:bisque">&nbsp;</i>Not Active</span>&nbsp;
            <span class="Total"><i class="fa fa-circle fa-sm" style="color: #ebe6f1">&nbsp;</i>Total</span>
        </div>
    </div>
    <br>
    <div class="row mt-2" *ngFor="let item of tsiData">
        <div class="col-12">
            <div class="row mt-2" (click)="RowClick(item.fieldName,item.fieldvalue)">
                <span class="header" style=" margin-left: 20px;">{{item.desc|titlecase}} {{item.fieldvalue}} </span>
            </div>
            <div class="row mt-2" (click)="RowClick(item.fieldName,item.fieldvalue)" >
                <div class="col-12">
                    <div class="progress rounded-pill">

                        <div class="progress-bar bg_currActive" role="progressbar" aria-valuenow="25" aria-valuemin="0"
                            [style.width.%]="item.activePC"></div>

                        <div class="progress-bar bg_last30min" role="progressbar" aria-valuenow="25" aria-valuemin="0"
                            [style.width.%]="item.currActivePC"></div>

                        <div class="progress-bar bg_phyworking" role="progressbar" aria-valuenow="25" aria-valuemin="0"
                            [style.width.%]="item.inActivePC"></div>

                        <div class="progress-bar bg_totalNoOfPC" role="progressbar" aria-valuenow="25" aria-valuemin="0"
                            [style.width.%]="item.totalNoOfPC"></div>
                    </div>
                </div>
            </div>
            <div class="row mt-2 text-center">
                <div class="col-3 text-center"> <span class="text_14">{{item.activePC}}</span></div>
                <div class="col-3 text-center"> <span class="text_14">{{item.currActivePC}}</span></div>
                <div class="col-3 text-center"> <span class="text_14">{{item.inActivePC}}</span></div>
                <div class="col-3 text-center"> <span class="text_14">{{item.totalNoOfPC}}</span></div>
            </div>
        </div>
        <hr class="hr_cust">
    </div>

</div>
<br><br><Br><br><br>