<div class="row mt-2" *ngIf="outletListData.issurvey.toLowerCase()=='yes';else defaultPhoto">
    <div class="col-12 text-end container p-0">
        <img class="outletPhoto"
            [src]="url + 'MeraNet/GetDownloadFileFromS3Bucket/SurveyImages/' + outletCode + '_SURVEY.JPEG'">
        <button class="btn viewBtn mt-2" (click)="viewMap()">
            <span class="text-center"><img src="assets/marketKaSaathiLocation.png"
                    class="img-fluid mr-2 locationIcon" />View</span>
        </button>
    </div>
</div>

<ng-template #defaultPhoto>
    <div class="col-12 text-end container p-0">
        <img class="outletPhoto" src="assets/imageNotAvailable.png">
    </div>

    <!-- <span style="background: #000000 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 0.6;">
        <img src="../../../assets/icons/visited_green_icon.svg" class="img-fluid mr-2" />Last Visited 15 Jan
    </span> -->
</ng-template>


<span style="background: #000000 0% 0% no-repeat padding-box;
border-radius: 5px;
opacity: 0.6; color: white;" class="me-1 span-above-image"
    *ngIf="(outletListData.isvisited =='VG' || outletListData.isvisited =='VR') && outletListData.lastvisitdate!=''">
    <!-- <img src="../../../assets/icons/visited_green_icon.svg" class="img-fluid mr-2" />Last Visited -->
    <span class="ms-1 " style="color: #76DC67;">Last Visited {{outletListData.lastvisitdate}} </span>
</span>

<div class="purpleBgColor row">
    <!-- <div class=""> -->
    <div class="col-12 mt-2 text-center">
        <span class="heading">{{outletListData.outletname}}</span>
        <span class="ml-1 rdcodecss">{{outletListData.outletcode}}</span>
    </div>
    <!-- </div> -->


    <div class="col-12 mt-3  d-flex justify-content-evenly">
        <div class="col-auto " style="color: white;">
            <span class="main_text text-center">RE</span>
            <span class="text_value ml-2" style="white-space: nowrap;">{{outletListData.shortre}} | </span>
            <!-- <span class="ml-4">|</span> -->

        </div>
        <div class="col-auto" style="color: white;">
            <span class="main_text">Program</span>
            <span class="text_value ml-2">{{outletListData.pp}} |</span>
        </div>
        <div class="col-auto p-0" style="color: white;">
            <span class="main_text">Visicooler</span>
            <span class="text_value ml-2">{{outletListData.visicooler}}</span>
            <!--  <span class="ml-4"></span> -->
        </div>
    </div>

    <div class="row mt-3 mx-auto">
        <div class="col-12 text-center mt-3 ">
            <span class="rdstatusOnTrack"> <img src="../../../assets/onTrack.png" class="img-fluid" />&nbsp;
                {{outletListData.achstatus | titlecase}}
            </span>
            <span *ngIf="outletListData.isgreencall.toLowerCase()=='y'" class="ml-3" style="color: white;">GO Status
                <img src="../../../assets/surveyStatus.png" class="img-fluid" />&nbsp;</span>
            <span *ngIf="outletListData.isgreencall.toLowerCase()=='n'" class="ml-3" style="color: white;">GO Status
                <img src="../../../assets/surveyStatusCross.png" style="height: 4vh;" />&nbsp;</span>
        </div>
    </div>

    <div class="row mx-auto mt-3" *ngIf="showSpeedometer">
        <!-- <kendo-radialgauge [pointer]="{ value: value}">
                <kendo-radialgauge-scale [minorUnit]="2" [majorUnit]="20" [max]="180" [rangeSize]="rangeSize"
                    [majorTicks]="{color: ticksColor }" [minorTicks]="{ color: ticksColor }" [startAngle]="startAngle"
                    [endAngle]="endAngle">
                    <kendo-radialgauge-scale-labels [color]="'white'"></kendo-radialgauge-scale-labels>
                    <kendo-radialgauge-scale-ranges>
                        <kendo-radialgauge-scale-range *ngFor="let item of scaleRange" [from]="item.from" [to]="item.to"
                            [color]="item.color">
                        </kendo-radialgauge-scale-range>
                    </kendo-radialgauge-scale-ranges>
                </kendo-radialgauge-scale>

                <kendo-radialgauge-pointers>
                    <kendo-radialgauge-pointer *ngFor="let pointer of pointers" [value]="pointer.value"
                        [color]="pointer.color" [length]="pointer.length">
                    </kendo-radialgauge-pointer>
                </kendo-radialgauge-pointers>
            </kendo-radialgauge> -->

        <kendo-radialgauge [pointer]="{ value: value}">
            <kendo-radialgauge-scale [minorUnit]="minorValue" [majorUnit]="majorValue" [max]="maxValue"
                [rangeSize]="rangeSize" [majorTicks]="{color: ticksColor }" [minorTicks]="{ color: ticksColor }"
                [startAngle]="startAngle" [endAngle]="endAngle">
                <kendo-radialgauge-scale-labels [color]="'white'"></kendo-radialgauge-scale-labels>
                <kendo-radialgauge-scale-ranges>
                    <kendo-radialgauge-scale-range *ngFor="let item of scaleRange" [from]="item.from" [to]="item.to"
                        [color]="item.color">
                    </kendo-radialgauge-scale-range>
                </kendo-radialgauge-scale-ranges>
            </kendo-radialgauge-scale>

            <kendo-radialgauge-pointers>
                <kendo-radialgauge-pointer *ngFor="let pointer of pointers" [value]="pointer.value"
                    [color]="pointer.color" [length]="pointer.length">
                </kendo-radialgauge-pointer>
            </kendo-radialgauge-pointers>
        </kendo-radialgauge>
    </div>


    <div class="row mx-auto mt-2" style='width:26rem;height:4rem'>
        <div class="col-5">
            <span class="whiteColor p-2">{{outletListData.ach}}<span class="unit"> INR</span></span>
            <br>
            <span class="">
                <img class="mr-1 p-0" src="assets/sec_dot.png" />
                <span class="whiteColor p-0">Achievement</span>
            </span>
        </div>

        <div class="col-3 p-2 ">
            <span class="" style="color: white;font-size:20px ;">{{outletListData.achper}} %</span>
        </div>
        <div class="col-3 p-0">
            <span class="whiteColor float-end">{{outletListData.tgt}}<span class="unit"> INR</span></span>
            <br>
            <span class="float-end">
                <img class="img-fluid mr-1" src="assets/pri_dot.png" />
                <span class=" whiteColor">Target</span>
            </span>
        </div>
    </div>

    <div class="row mx-auto">
        <div class="col-12 text-center">
            <span class="whiteColor">{{LYSM}}</span><br>
            <span class="whiteColor">LYSM</span>
        </div>
    </div>
</div>

<div class="row mt-3">
    <span class="acctitle">KPI</span>
</div>
<div class="row mt-2">
    <table class="table tbl_custom">
        <thead>
            <tr>
                <td class="td_cust">Parameter</td>
                <td class="td_cust">LM1</td>
                <td class="td_cust">LM2</td>
                <td class="td_cust">LM3</td>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of getOutletKPIData">
                <td class="Value_text purpleColor">{{item.kpiname}}</td>
                <td class="Head_text" [ngStyle]="{'color':item.color1}"> {{item.kpiname == "M-Score" ? item.mscorelm1 :
                    item.lm1}}</td>
                <td class="Head_text" [ngStyle]="{'color':item.color2}"> {{item.kpiname == "M-Score" ? item.mscorelm2 :
                    item.lm2}}</td>
                <td class="Head_text" [ngStyle]="{'color':item.color3}"> {{item.kpiname == "M-Score" ? item.mscorelm3 :
                    item.lm3}}</td>
            </tr>
        </tbody>
    </table>
</div>

<!-- {{(item.lm1 == 0 ? 'No' : item.lm1 == 1 ? 'Yes' : item.lm1)}} -->
<!-- <td class="" [ngClass]="{
                        'is-green': item.lm1 == 1,
                        'is-red': item.lm1 == 0}" > {{item.lm1}}</td> -->


<div class="row mt-3">
    <span class="acctitle">Category Performance</span>
</div>

<div class="row mt-2">
    <table class="table tbl_custom">
        <thead>
            <tr>
                <td class="td_cust">Category</td>
                <td class="td_cust">MTD</td>
                <td class="td_cust">L3M%</td>
                <td class="td_cust">LYSM%</td>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of getOutletSalesParameter">
                <td class="Value_text purpleColor">{{item.category}}</td>
                <td class="Head_text">{{item.mtd}}</td>
                <td class="Head_text">{{item.l3m}}%</td>
                <td class="Head_text">{{item.lm}}%</td>
            </tr>
        </tbody>
    </table>
</div>

<div class="row mt-3">
    <span class="acctitle">Payout</span>
</div>

<div class="row mt-3">
    <div class="col-5">
        <span class="payout">UTR No</span>
    </div>
    <div class="col-4">
        <span class="payout">Type</span>
    </div>
    <div class="col-3 ">
        <span class="payout">Amt ( <img class="img-fluid mr-1" src="assets/rupee.png" />)</span>
    </div>
</div>
<hr style="border-style: solid;">

<div *ngFor="let item of getProgramtargetDetailsList; let i = index">
    <div class="row mt-3">
        <div class="col-5">
            <span class="utr_No">{{item.utrno.trim()=="0"? '' :item.utrno}}</span>
        </div>
        <div class="col-4">
            <span class="fieldValue">{{item.paymenttype}}</span>
        </div>
        <div class="col-3 ">
            <span class="fieldValue">{{item.amount}}</span>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-12">
            <span class="date_Value">{{item.utrno=="0"? '' :item.paymentdate}}</span>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <span class="bank_Name">{{item.utrno=="0"? '' : item.bankname | titlecase}}</span>
        </div>
    </div>
    <hr>
</div>
<br><br><br>