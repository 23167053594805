import { Component, EventEmitter, OnInit, Output, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { DatePipe } from '@angular/common';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AtoBtransfer } from '../../models/kpi-models';
import { HttpEventType, HttpClient, HttpHeaders } from '@angular/common/http';
import { NgxImageCompressService } from 'ngx-image-compress';
import { DataService } from 'src/app/service/data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { color } from 'html2canvas/dist/types/css/types/color';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-outlet-detail-two-atob',
  templateUrl: './outlet-detail-two-atob.component.html',
  styleUrls: ['./outlet-detail-two-atob.component.css']
})
export class OutletDetailTwoAtobComponent implements OnInit {
  @ViewChild('openModalThankYou', { static: true }) elRefOpenModalThankYou: ElementRef | undefined;
  @ViewChild('openConfirmationModal', { static: true }) elRefopenConfirmationModal: ElementRef | undefined;

  deviceType: any;
  device: any;
  searchText = '';
  isLongPress: boolean = false;
  isSelected: boolean = false;
  isValid: boolean = false;
  outletSelectedA: any;
  imgURL: any;
  photourlstr: string = '';
  public atobtransfer: AtoBtransfer = new AtoBtransfer();
  public progress: number = 0;
  public message: string = '';
  public mobileNo: string = "";
  @Output() public onUploadFinished = new EventEmitter();
  public photoUploaded:any
  public submitted = false;

  public photos: any = {
    CoolerPhoto: 0,
    OutletPhoto: 0,
    OutletIDPhoto: 0
  }



  visicColerForm = this.fb.group({
    // mobNo: ['', [Validators.required]],
    mobNo: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
    address1: [''],
    address2: [''],
    landmark: [''],
    pinCode: [''],
    EMailID: ['', [Validators.email]],
    AssetCOde: ['',],
    SerialNo: ['',],
  });

  get f() {
    return this.visicColerForm.controls;
  }

  coolerPhoto: any;
  outletPhoto: any;
  outletIDPhoto: any;
  page1Photo: any;
  page2Photo: any;
  outletSelectedB: any;
  selected: string = '';
  imgResultBeforeCompress: string = '';
  imgResultAfterCompress: string = '';
  file: any;
  localUrl: any;
  localCompressedURl: any;
  sizeOfOriginalImage: number = 0;
  sizeOFCompressedImage: number = 0;
  TokenNo: any;
  ClientID: any;
  httpOptions: HttpHeaders =
    new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      // 'Content-Type': 'multipart/form-data',
      'enctype': 'multipart/form-data',
      'charset': 'UTF-8',
      'FolderName': 'ATOB',
    });
    formname:any;
    actiondesc:any;
    UserType:any;
    fieldName:any;
    fieldValue:any;
    userId:any;
  constructor(private ds: DataService, private activatedRoute: ActivatedRoute, private router: Router, private headerService: HeaderService, private datePipe: DatePipe,
    private cs:CommonUtilitiesService,private mds: MongoDataService, private notifyMeService: NotifyMeService, private fb: FormBuilder, private http: HttpClient, private imageCompress: NgxImageCompressService, private modalService: NgbModal,) { }

  ngOnInit(): void {

    this.headerService.setTitle('Outlet Detail');
    this.headerService.setActiveURl('/outletDetailOneAtob');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.TokenNo = localStorage.getItem("TokenNo");
    this.ClientID = localStorage.getItem("ClientID");
    this.outletSelectedA = JSON.parse(localStorage.getItem("outletSelectedA") || '{}');
    this.outletSelectedB = JSON.parse(localStorage.getItem("outletSelectedB") || '{}');
    this.selected = this.activatedRoute.snapshot.queryParams["selected"];
    // debugger

    this.outletSelectedB.cellno = this.outletSelectedB.cellno.replace(/\D/g, '')
    this.visicColerForm.get('mobNo')?.setValue(this.outletSelectedB.cellno, { emitEvent: false });
    this.visicColerForm.get('address1')?.setValue(this.outletSelectedB.address1, { emitEvent: false });
    this.mobileNo = this.visicColerForm.get('mobNo')?.value

    this.userId = localStorage.getItem("UserID");
    this.UserType= localStorage.getItem('UserType');
    this.fieldName = localStorage.getItem("FieldName");
    this.fieldValue = localStorage.getItem("FieldValue");
    this.formname="outletDetailTwoAtob";
    this.actiondesc="Outlet Detail Two Atob";
    this.cs.GetGeoLocation(this.userId,this.UserType,this.formname,this.actiondesc,this.fieldName,this.fieldValue); 
  }

  onLongPress() {
    this.isLongPress = true;
  }

  clickOnSelect() {
    this.isSelected = true;
  }

  rowClick() {
    for (let prop in this.visicColerForm.controls) {
      this.visicColerForm.get(prop)?.markAsTouched();
    }
    debugger;
    if (this.visicColerForm.status === 'VALID') {
      localStorage.setItem("visicColerForm", JSON.stringify(this.visicColerForm.value));
      localStorage.setItem("atobtransferObj", JSON.stringify(this.atobtransfer))
      if (this.selected == 'VS') {
        this.router.navigate(['/home/atobTransferPreview'], { queryParams: { selected: this.selected } });
      } else {
        this.router.navigate(['/home/atobTransferPreview']);
      }
    }
  }

  fileProgress(fileInput: any, photoUrl: string) {
    this.photos[photoUrl] += 1;
    
    this.imgURL = <File>fileInput.target.files[0];
    // if (fileInput.length === 0) {
      //   return;
      // }
      if (fileInput.target.files.length === 0) {
        return;
      }
      

    let fileToUpload = <File>fileInput.target.files[0];
    this.photoUploaded=fileToUpload
    
    if ( fileToUpload.name.toLowerCase().lastIndexOf('.jpg') == -1 && fileToUpload.name.toLowerCase().lastIndexOf('.jpeg') == -1 ) {
      console.log("Other file format...");
      this.notifyMeService.showWarning("Please upload jpg or jpeg format only", 'Sales Genie');
      return;
    }

    this.imgURL = fileToUpload;
    const formData = new FormData();
    this.loaddatatoMemberVariable(fileToUpload.name, photoUrl);

    var fileName: any;
    this.file = fileInput.target.files[0];
    fileName = this.file['name'];
    if (fileInput.target.files && fileInput.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.localUrl = event.target.result;
        var orientation = -1;
        this.sizeOfOriginalImage = this.imageCompress.byteCount(this.localUrl) / (1024 * 1024);

        this.imageCompress.compressFile(this.localUrl, orientation, 50, 50).then(result => {
          this.imgResultAfterCompress = result;
          this.localCompressedURl = result;
          this.sizeOFCompressedImage = this.imageCompress.byteCount(result) / (1024 * 1024)
          // create file from byte
          const imageName = fileName;
          // call method that creates a blob from dataUri
          const imageBlob = this.dataURItoBlob(this.imgResultAfterCompress.split(',')[1]);
          //imageFile created below is the new compressed file which can be send to API in form data
          const imageFile = new File([imageBlob], imageName, { type: 'image/jpeg' });

          formData.append('file', imageFile, this.photourlstr);

          // this.http.post(this.mds.url + 'MeraNet/UploadFileToS3Bucket', formData, { reportProgress: true, observe: 'events', headers: this.httpOptions }).subscribe(event => {
          //     console.log("ATOBUploadFile",event)
          //     if (event.type === HttpEventType.UploadProgress){
          //       // this.progress = Math.round(100 * event.loaded / event.total);
          //     } else if (event.type === HttpEventType.Response) {
          //       this.message = 'Upload success.';
          //       this.onUploadFinished.emit(event.body);
          //       this.preview(photoUrl);
          //     }
          // }, 
          // error => { 
          //   console.log("error",error);
          // });
          this.http.post(this.mds.url + 'MeraNet/UploadFileToS3Bucket', formData, { reportProgress: true, observe: 'events', headers: this.httpOptions, responseType: 'blob' as 'json' }).subscribe(res => {
            console.log("ATOBUploadFile", res)
            this.preview(photoUrl);
          },
            error => { console.log("error", error) });

          // this.ds.uploadFileToS3Bucket(formData).subscribe(data => {
          //   console.log("UploadFileToS3Bucket",data)
          //   this.preview(photoUrl);            
          // },
          //   error => { 
          //     console.log("error",error);
          //     this.preview(photoUrl);            
          //  }
          // );
        });
      }
      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

  loaddatatoMemberVariable(filename: string, photoUrl: string) {
    debugger
    switch (photoUrl) {
      case 'CoolerPhoto':
        this.photourlstr = this.outletSelectedB?.outletcode.toString() + "_1" + filename.substring(filename.indexOf('.'));
        this.atobtransfer.CoolerPhoto = this.photourlstr;
        break;
      case 'OutletPhoto':
        this.photourlstr = this.outletSelectedB?.outletcode.toString() + "_2" + filename.substring(filename.indexOf('.'));
        this.atobtransfer.OutletPhoto = this.photourlstr;
        break;
      case 'OutletIDPhoto':
        this.photourlstr = this.outletSelectedB?.outletcode.toString() + "_3" + filename.substring(filename.indexOf('.'));
        this.atobtransfer.OutletIDPhoto = this.photourlstr;
        break;
      case 'Page1Photo':
        this.photourlstr = this.outletSelectedB?.outletcode.toString() + "_7" + filename.substring(filename.indexOf('.'));
        this.atobtransfer.Page1Photo = this.photourlstr;
        break;
      case 'Page2Photo':
        this.photourlstr = this.outletSelectedB?.outletcode.toString() + "_8" + filename.substring(filename.indexOf('.'));
        this.atobtransfer.Page2Photo = this.photourlstr;
        break;
    }

    if (this.atobtransfer.CoolerPhoto != '' && this.atobtransfer.OutletPhoto != '' && this.atobtransfer.OutletIDPhoto != ''
      && this.atobtransfer.Page1Photo != '' && this.atobtransfer.Page2Photo != '') {
      this.isValid = true;
    }
  }

  preview(photoUrl: string) {
    // Show preview
    debugger
    var mimeType = this.imgURL.type;
    if (mimeType?.match(/image\/*/) == null) {
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(this.imgURL);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
      switch (photoUrl) {
        case 'CoolerPhoto':
          this.coolerPhoto = this.imgURL;
          //  localStorage.setItem("CoolerPhoto",JSON.stringify(this.coolerPhoto))
          break;
        case 'OutletPhoto':
          this.outletPhoto = this.imgURL;
          ///   localStorage.setItem("OutletPhoto",JSON.stringify(this.outletPhoto))
          break;
        case 'OutletIDPhoto':
          this.outletIDPhoto = this.imgURL;
          //   localStorage.setItem("OutletIDPhoto",JSON.stringify(this.outletIDPhoto))
          break;
        case 'Page1Photo':
          this.page1Photo = this.imgURL;
          //   localStorage.setItem("PagePhoto1",JSON.stringify(this.page1Photo))
          break;
        case 'Page2Photo':
          this.page2Photo = this.imgURL;
          //     localStorage.setItem("PagePhoto2",JSON.stringify(this.page2Photo))
          break;
      }
    }
  }

  numberOnly(event: { which: any; keyCode: any; }): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  characterOnly(event: { which: any; keyCode: any; }): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if ((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123) || charCode == 32) {
      return true;
    }
    return false;
  }

  get mobNo() { return this.visicColerForm.get('mobNo'); }
  get address1() { return this.visicColerForm.get('address1'); }
  get address2() { return this.visicColerForm.get('address2'); }
  get landmark() { return this.visicColerForm.get('landmark'); }
  get pinCode() { return this.visicColerForm.get('pinCode'); }
  get EMailID() { return this.visicColerForm.get('EMailID'); }
  get AssetCOde() { return this.visicColerForm.get('AssetCOde'); }
  get SerialNo() { return this.visicColerForm.get('SerialNo'); }

  dataURItoBlob(dataURI: string) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }

  EAgreementOtp(targetEle: any) {
    console.log("targetEle", targetEle.value)

    debugger
    // if (Object.values(this.photos).includes(0)) {
    //   this.notifyMeService.showWarning("Above 3 images are required", 'Sales Genie App');
    //   return;
    // }

    if (this.photos['CoolerPhoto'] == 0 || (this.photoUploaded.name.toLowerCase().lastIndexOf(".jpg")==-1 && this.photoUploaded.name.toLowerCase().lastIndexOf('.jpeg') == -1)) {
      this.notifyMeService.showWarning("Please upload Cooler Photo", 'Sales Genie App');
      return;
    }

    if (this.photos['OutletPhoto'] == 0 || (this.photoUploaded.name.toLowerCase().lastIndexOf(".jpg")==-1 && this.photoUploaded.name.toLowerCase().lastIndexOf('.jpeg') == -1)) {
      this.notifyMeService.showWarning("Please upload Outlet Photo", 'Sales Genie App');
      return;
    }

    if (this.photos['OutletIDPhoto'] == 0 || (this.photoUploaded.name.toLowerCase().lastIndexOf(".jpg")==-1 && this.photoUploaded.name.toLowerCase().lastIndexOf('.jpeg') == -1)) {
      this.notifyMeService.showWarning("Please upload OutletID Photo", 'Sales Genie App');
      return;
    }

    this.mobileNo = this.visicColerForm.get('mobNo')?.value
    if (this.visicColerForm.invalid) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
    debugger


    if (this.visicColerForm.valid) {
      this.openModal(this.elRefopenConfirmationModal)
    }
    else {
      debugger
      this.onSubmit();
    }

  }

  A2BAgreeGetOTP() {
    
    if (this.visicColerForm.valid) {
      this.mobileNo = this.visicColerForm.get('mobNo')?.value
      localStorage.setItem("visicColerForm_MobNo", this.mobileNo)
      let obj = {
        "OutletCode": this.outletSelectedB.outletcode.toString(),
        "OutletCodeRD": this.outletSelectedB.outletcoderd.replace(/_+$/,""),
        "MobileNo": this.visicColerForm.get('mobNo')?.value,
        "RDCode": this.outletSelectedB.rdcode.toString(),
        "Visicooler": this.outletSelectedB.visicooler,
        "UserID": localStorage.getItem("UserID"),
        "ISResent": "N"
      }

      debugger

      this.ds.MA_A2BAgreeGetOTP(obj).subscribe(res => {
        debugger
        // console.log("res",res)
        if (res[0].result.toLowerCase() == 'ok' &&
          res[1].result.toLowerCase() == 'ok') {
          localStorage.setItem("keyID", res[0].keyID);
          this.ds.visicColerForm = this.visicColerForm;
          // document.getElementById('EAgreementOtpModalBtn')?.click();
          this.openModal(this.elRefOpenModalThankYou);
        }
        else {
          this.notifyMeService.showWarning(res[0].result, 'Sales Genie App');
        }
      },
        error => {
          console.log("error:Invalid API")
        },
      )
      this.closeModal();
    }


  }

  openAtoBTransfer() {
    for (let prop in this.visicColerForm.controls) {
      this.visicColerForm.get(prop)?.markAsTouched();
    }
    debugger;
    if (this.visicColerForm.status === 'VALID') {
      localStorage.setItem("visicColerForm", JSON.stringify(this.visicColerForm.value));
      localStorage.setItem("atobtransferObj", JSON.stringify(this.atobtransfer));
      localStorage.setItem("selected", this.selected);
      this.modalClose();
      this.router.navigate(['/home/a2bTransfer']);
    }
    this.router.navigate(['/home/a2bTransfer']);
  }

  modalClose() {
    this.modalService.dismissAll();
    this.router.navigate(['/home/a2bTransfer']);
  }

  openModal(modal: ElementRef<any> | undefined) {
    this.modalService.open(modal,
      {
        size: 'lg',
        backdrop: 'static',
        windowClass: '',
        keyboard: false,
        centered: true,
      })
  }

  onSubmit() {
    debugger
    this.submitted = true;
    if (this.visicColerForm.invalid) {
      return;
    }
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  cancleA2BAgreeGetOtp(target: any) {
    this.modalService.dismissAll();
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    let mobNo: any = document.getElementById('mobNo');

    mobNo.style.borderColor = 'yellow';

    setTimeout(() => {
      mobNo.style.borderColor = '';
    }, 2000);

  }
}

