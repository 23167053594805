<div *ngIf="!isSend">
  <form [formGroup]="praiseForm" autocomplete="off">
    <div class="row mt-3 vw-100">
      <div class="col-2 mt-1">
        <span class="to_text">To</span>
      </div>
      <div class="col-10">
        <!-- <input class="to_input" placeholder="  Type Mail Id" /> -->
        <ng-multiselect-dropdown [placeholder]="'Search User'" [settings]="dropdownSettings" [data]="dropdownList"
          formControlName="toEmail" [(ngModel)]="selectedItems" (onSelect)="onItemSelect($event)"
          (onSelectAll)="onSelectAll($event)" (onDeSelectAll)="UnSelectAll($event)"
          (onDeSelect)="onItemDeSelect($event)">
        </ng-multiselect-dropdown>
      </div>
    </div>
    <div [ngClass]="{
        'cus_div1_1':PraiseName === 'Courage',
        'cus_div1_2':PraiseName === 'Optimism',
        'cus_div1_3':PraiseName === 'Inclusive',
        'cus_div1_4':PraiseName === 'Awesome',
        'cus_div1_5':PraiseName === 'Thankyou',
        'cus_div1_6':PraiseName === 'Achiever',
        'cus_div1_7':PraiseName === 'ProblemSolver',
        'cus_div1_8':PraiseName === 'WorkWithAgility',
        'cus_div1_9':PraiseName === 'TeamPlayer',
        'cus_div1_10':PraiseName === 'Leadership',
        'cus_div1_11':PraiseName === 'Creative',
        'cus_div1_12':PraiseName === 'Coach'
      }" class="row vw-100 text-center">
      <div class="col-2 text-center"></div>
      <div class="col-8 text-center">
        <div [ngClass]="{
                'cus_div1':PraiseName === 'Courage',
                'cus_div2':PraiseName === 'Optimism',
                'cus_div3':PraiseName === 'Inclusive',
                'cus_div4':PraiseName === 'Awesome',
                'cus_div5':PraiseName === 'Thankyou',
                'cus_div6':PraiseName === 'Achiever',
                'cus_div7':PraiseName === 'ProblemSolver',
                'cus_div8':PraiseName === 'WorkWithAgility',
                'cus_div9':PraiseName === 'TeamPlayer',
                'cus_div10':PraiseName === 'Leadership',
                'cus_div11':PraiseName === 'Creative',
                'cus_div12':PraiseName === 'Coach'
              }" class="row">
        </div>
      </div>
      <div class="col-2 text-center"></div>
    </div>
    <div class="row mt-2 vw-100">
      <div class="col-12">
        <textarea rows="8" name="text" class="remark_text" placeholder="Add personalised note"
          formControlName="description" required></textarea>
      </div>

    </div>
  </form>

  <div class="row mt-3 vw-100 div_login">
    <div class="col-12 p-0">
      <button class="btn btn-block btn_login" type="button" *ngIf="praiseForm.valid" (click)="isSend = true">
        SEND</button>
    </div>
  </div>
</div>
<div *ngIf="isSend">
  <div class="row preview_Box" style="margin-top: 2rem!important;">
    <div class="col-12 text-center mt-4">
      <span class="user_Name">{{userName}}</span>
    </div>
    <div class="col-12 text-center mt-1">
      <span class="sent_Praise">Sent Appreciation to</span>
    </div>
    <div class="col-12 text-center mt-1" *ngFor="let item of toEmail">
      <span class="email_Ids">{{item}}</span>
    </div>
    <div class="col-12 text-center">
      <div style="padding: 1.375rem;">
        <div class="rectangle" [ngClass]="{
      'cus_div1_1':PraiseName === 'Courage',
      'cus_div1_2':PraiseName === 'Optimism',
      'cus_div1_3':PraiseName === 'Inclusive',
      'cus_div1_4':PraiseName === 'Awesome',
      'cus_div1_5':PraiseName === 'Thankyou',
      'cus_div1_6':PraiseName === 'Achiever',
      'cus_div1_7':PraiseName === 'ProblemSolver',
      'cus_div1_8':PraiseName === 'WorkWithAgility',
      'cus_div1_9':PraiseName === 'TeamPlayer',
      'cus_div1_10':PraiseName === 'Leadership',
      'cus_div1_11':PraiseName === 'Creative',
      'cus_div1_12':PraiseName === 'Coach'
    }">
          <div class="ellipse" [ngClass]="{
            'bg_courage':PraiseName === 'Courage',
            'bg_optimism':PraiseName === 'Optimism',
            'bg_inclusive':PraiseName === 'Inclusive',
            'bg_awesome':PraiseName === 'Awesome',
            'bg_thankyou':PraiseName === 'Thankyou',
            'bg_achiever':PraiseName === 'Achiever',
            'bg_problemSolver':PraiseName === 'ProblemSolver',
            'bg_WorkWithAgility':PraiseName === 'WorkWithAgility',
            'bg_teamPlayer':PraiseName === 'TeamPlayer',
            'bg_leadership':PraiseName === 'Leadership',
            'bg_creative':PraiseName === 'Creative',
            'bg_coach':PraiseName === 'Coach'
          }">
            <img class="img-fluid" src="assets/courage.png"  *ngIf="PraiseName === 'Courage'"/>
            <img class="img-fluid" src="assets/Pr_Optimism.svg"  *ngIf="PraiseName === 'Optimism'"/>
            <img class="img-fluid" src="assets/Pr_Inclusive.svg"  *ngIf="PraiseName === 'Inclusive'"/>
            <img class="img-fluid" src="assets/Pr_Awesome.svg"  *ngIf="PraiseName === 'Awesome'"/>
            <img class="img-fluid" src="assets/Pr_Thankyou.svg"  *ngIf="PraiseName === 'Thankyou'"/>
            <img class="img-fluid" src="assets/Pr_Achiever.svg"  *ngIf="PraiseName === 'Achiever'"/>
            <img class="img-fluid" src="assets/Pr_ProblemSolver.svg"  *ngIf="PraiseName === 'ProblemSolver'"/>
            <img class="img-fluid" src="assets/Pr_WorkWithAgility.svg"  *ngIf="PraiseName === 'WorkWithAgility'"/>
            <img class="img-fluid" src="assets/Pr_TeamPlayer.svg"  *ngIf="PraiseName === 'TeamPlayer'"/>
            <img class="img-fluid" src="assets/Pr_Leadership.svg"  *ngIf="PraiseName === 'Leadership'"/>
            <img class="img-fluid" src="assets/Pr_Creative.svg"  *ngIf="PraiseName === 'Creative'"/>
            <img class="img-fluid" src="assets/Pr_Coach.svg"  *ngIf="PraiseName === 'Coach'"/>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer">
    <div class="row">
      <div class="col-6 pr-0" (click)="cancel()">
        <button class="btn btn_Cancel">CANCEL</button>
      </div>
      <div class="col-6 pl-0" (click)="Ok()">
        <button class="btn btn_Ok">OK</button>
      </div>
    </div>
  </div>
</div>
