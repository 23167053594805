import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';
import { HeaderService } from 'src/app/service/header.service';

@Component({
  selector: 'app-outlet-name-changed-verified-otp-confirmation',
  templateUrl: './outlet-name-changed-verified-otp-confirmation.component.html',
  styleUrls: ['./outlet-name-changed-verified-otp-confirmation.component.css']
})
export class OutletNameChangedVerifiedOtpConfirmationComponent implements OnInit {
  deviceType: any;
  device: any;
  userType: string | null = '';
  userId: any;
  token: any;
  FormName: any;
  formname: any;
  actiondesc: any;
  fieldName: any;
  fieldValue: any;
  mobileNo:any=""
  constructor(private modalService: NgbModal,private headerService: HeaderService,private cs: CommonUtilitiesService,
    private router:Router ,private fb: FormBuilder, ) { }

  ngOnInit(): void {
    this.FormName = 'OutletNameChange'
    this.headerService.setTitle('Outlet Name Change');
    this.headerService.setActiveURl('/outletNameChangeRequestVerifyOtpConfirmation');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.userId = localStorage.getItem("UserID");
    this.token = localStorage.getItem("TokenNo");
    this.userType = localStorage.getItem("UserType");
    this.fieldName = localStorage.getItem("FieldName");
    this.fieldValue = localStorage.getItem("FieldValue");
    this.formname = "outletNameChange"
    this.actiondesc = "Outlet Name Change Request";
    this.cs.GetGeoLocation(this.userId, this.userType, this.formname, this.actiondesc, this.fieldName, this.fieldValue)
  }

  closeBtnClick(){
    this.router.navigate(['home/outletNameChangeRequest'])
  }
}
