<div>
  <div class="row mt-4">
    <div class="col-12">
      <button class="btn btn_Scan btn-block" type="button" (click)="action.toggleCamera()">Scan QR Code</button>

      <br /><br />
      <div class="qr-scan-area">
        <ngx-scanner-qrcode #action="scanner" [config]="config" (data)="qrCodeResult($event)" (error)="onError($event)">
        </ngx-scanner-qrcode>
        <div class="qr-area">
          <div class="area"></div>
        </div>
      </div>
      <hr />
    </div>
    <div class="col-12 mt-4 text-center" *ngIf="qrResult">
      <img class="img-fluid" src="assets/qr_Icon.svg" />
    </div>
    <!-- <div class="row mt-3 text-center" >
            <div class="col-8">
                <input type="text" class="outletCode" #QRCode placeholder="Enter QR Code"  [(ngModel)]="output"/>
            </div>
            <div class="col-4">
                <button class="btn btn_GO" (click)="GOBtn(output)">GO</button>
            </div>
        </div> -->

  </div>
  <br><br><br><br>
</div>


<ng-template #openModalQRcCodeAvailable let-modal>
  <div class="modal-header p-1">
    <button type="button" class="close" aria-label="Close" (click)="modalClose()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row mx-auto">
      <div class="col-12">
        <span class="model_PopUp_Text">QR code is not available in system.</span>
      </div>
    </div>
  </div>
  <div class="modal-footer p-1">
    <div class="row">
      <div class="col-12">
        <button type="button" class="btn btn_SUBMIT" (click)="clickOnYes()">OK</button>

      </div>
      <!-- <div class="col-6">
        <button type="button" class="btn btn_SUBMIT" (click)="clickOnNo()">No</button>

      </div> -->
    </div>
  </div>
</ng-template>