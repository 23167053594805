import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { FilterComponent } from '../filter/filter.component';
import { Resultdata,InputPara } from 'src/app/models/kpi-models';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { DataService } from 'src/app/service/data.service';
@Component({
  selector: 'app-ltm',
  templateUrl: './ltm.component.html',
  styleUrls: ['./ltm.component.css']
})
export class LtmComponent implements OnInit {
  deviceType: any;
  device: any;
  FieldName: any;
  FieldValue: any;
  ParaName: any;
  UserID: any;
  list: any;
  IsRDValue: boolean = false;
  IsPC: boolean = false;
  outletCode: any;
  permanentlyClosedList: any;
  permanentlyClosedCount: any;
  resultdata:Resultdata=new Resultdata();
  totalOutletsCount: any;
  TotalBilledOutletCount: any;
  FormName: any;
  input:InputPara=new InputPara();
  token: any;
  lat:any;
  lng:any;
  UserType:any;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, 
    private notifyMeService: NotifyMeService,private ds: DataService,private headerService: HeaderService, private datePipe: DatePipe, private mds: MongoDataService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.headerService.setActiveURl('/ltm');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.token = localStorage.getItem("TokenNo");
    this.UserType = localStorage.getItem("UserType");
    this.activatedRoute.paramMap.forEach(params => {
      this.FieldValue = params.get('FieldValue');
      this.outletCode = this.FieldValue;
      this.FieldName = params.get('FieldName');
      this.UserID = params.get('UserID');
      this.ParaName = params.get('ParaName');
      if(this.ParaName =="l3m"){
        this.headerService.setTitle('L3M Unbilled Outlets');
        this.FormName='L3M Unbilled Outlets';
      }else{
        this.headerService.setTitle('L6M Risk Outlet');
        this.FormName='L6M Risk Outlet';
      }
      this.GetGeoLocation();
      this.loadData();
    });
  }

  
  GetGeoLocation() {
    debugger
    if (navigator) {
      navigator.geolocation.getCurrentPosition(pos => {
        this.lat = pos.coords.latitude;
        console.log("  this.lat", this.lat)
        this.lng = pos.coords.longitude;
        this.SetapplicationLogData();
      }, this.handleLocationError, { timeout: 30000 });
    }
    else {
      this.notifyMeService.showError('Location Could not be captured', 'Mera APP');
    }
  }

  handleLocationError(error: { code: any; }) {
    switch (error.code) {
      case 3:
        this.notifyMeService.showError('Timeout in Position capture', 'Mera APP V2');
        break;
      case 2:
        this.notifyMeService.showError('Device is not capable of Position capture', 'Mera APP V2');
        break;
      case 1:
        this.notifyMeService.showError('You denied for Position capture', 'Mera APP V2');
        break;
    }
  }

  SetapplicationLogData() {
    debugger
    let request = {
      "userid": this.UserID,
      "usertype": this.UserType,
      "formname": "ltm",
      "actiondesc": "ltm" + " " + this.FieldName + " " + this.ParaName,
      "fieldname": this.FieldName,
      "fieldvalue": this.FieldValue,
      "latitude": this.lat,
      "longitude": this.lng
    }
    this.ds.SetapplicationLogData(request).subscribe(res => {
      console.log("res", res)
    })
  }


  loadData(): void {
    debugger;
    this.input.FieldName=this.FieldName;
    this.input.FieldValue=this.FieldValue;
    this.input.TagName=this.ParaName;
    this.input.Datatype= 'month';
    this.input.UserID=this.UserID;
    this.mds.getUnbilledOutlet(this.input,this.token,this.device, this.deviceType,this.FormName).subscribe(res => {
     debugger;
      this.list = res;
      if(this.list[0].fieldName == "rd") {
        this.IsRDValue = true;
      } else {
        this.IsRDValue = false;
      }
      if(this.list[0].fieldName == "pc") {
        this.IsPC = true;
        this.FieldValue = this.list[0].rdname;
        this.totalOutletsCount = this.list.length;
        this.permanentlyClosedList = this.list.filter((singleItem: { [x: string]: string; }) => singleItem['pcreason'] == "Permanently Closed");//pcreason=='Permanently Closed'
        this.permanentlyClosedCount = this.permanentlyClosedList.length;
        this.TotalBilledOutletCount = this.list.filter((t: { billedamount: number; })=>t.billedamount>0).length;
      } else {
        this.IsPC = false;
      }
    });
  }
  openFilter() {
    if (this.dialog.openDialogs.length == 0) {
      const dialogRef = this.dialog.open(FilterComponent, {
        closeOnNavigation: true,
        data: {
          "FieldName": localStorage.getItem("OriginalFieldName"),
          "FieldValue": localStorage.getItem("OriginalFieldValue"),
          "UserID": this.UserID,
          "MenuUpTo": "5",
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.resultdata = result as Resultdata;
          this.FieldName = this.resultdata.FieldName;
          this.FieldValue = this.resultdata.FieldValue;
          this.outletCode =  this.resultdata.FieldValue;
          this.loadData();
        }
      });
    }
  }
  RowClick(fieldName: any, fieldvalue: any): void {
    this.router.navigate(['/home/ltm/', fieldName, fieldvalue, this.ParaName, this.UserID]);
  }
}
