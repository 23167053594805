import { Component, ElementRef, OnInit,ViewChild } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Output_MTAFHAccount } from 'src/app/models/kpi-models';
import { EncryptionService } from '../../service/encryption.service';
import { DataService } from '../../service/data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';
@Component({
  selector: 'app-mtafhapprovaldetails',
  templateUrl: './mtafhapprovaldetails.component.html',
  styleUrls: ['./mtafhapprovaldetails.component.css']
})
export class MTAFHApprovaldetailsComponent implements OnInit {
  @ViewChild('openModalThankYou', { static: true }) elRefOpenModalThankYou: ElementRef | undefined;
  ID: any;
  status: string = '';
  Flag: any;
  ParaName: any;
  UserID: any;
  UserType: any;
  ParaValue: any;
  TokenNo: any ;
  ClientID: any;
  accountlist:any;
  device: any;
  deviceType: any;
  Resaccountlist:any;
  margindtls:any;
  userTypelist:any;
  FormName: any;
  dtTemplate: any[]=[];
  formname:any;
  actiondesc:any;
  fieldName:any;
  fieldValue:any;
  constructor(private activatedRoute: ActivatedRoute,private fb: FormBuilder,  private es: EncryptionService,private mds: MongoDataService,private router: Router,
    private headerService: HeaderService,private cs:CommonUtilitiesService,private ds:DataService,private modalService: NgbModal,) { }
 

  ngOnInit(): void {
    this.headerService.setTitle('MT AFH Approvals');
    this.ID = "0";
    this.Flag ="SalesGenie";
    this.FormName= 'MTAFH Approval';
    this.UserID =localStorage.getItem('UserID');
    this.TokenNo= localStorage.getItem("TokenNo");
    this.ClientID= localStorage.getItem("ClientID");
    this.device = localStorage.getItem('Device');
    this.deviceType = localStorage.getItem('DeviceType');
    this.UserType= localStorage.getItem('UserType');
    this.fieldName = localStorage.getItem("FieldName");
    this.fieldValue = localStorage.getItem("FieldValue");
    this.UserType=this.UserType.toUpperCase();
    this.getUserType();
 
     this.activatedRoute.paramMap.forEach(params => {
      this.ID = params.get('ID');      
       this.UserID = params.get('UserID');      
     this.loadData();
     
     this.formname="mtafhdetails";
     this.actiondesc="mtafh Details"+" "+this.ID;
     this.cs.GetGeoLocation(this.UserID,this.UserType,this.formname,this.actiondesc,this.fieldName,this.fieldValue);  
     });
    
  }

  getUserType(){
    this.mds.getUserDetails(this.UserID, this.TokenNo, this.device, this.deviceType,this.FormName).subscribe(res => {
      this.userTypelist = res;
      this.UserType=this.userTypelist[0].userType;
      localStorage.setItem("UserTypeDetails",this.UserType);
      console.log("UserTypeDetails",this.UserType);
    });}
  loadData(): void {
   
    this.ds.MTAFH_GetAccountForSalesGenie(this.ID, this.UserID, this.TokenNo,this.device,this.deviceType,'MT AFH Approval').subscribe(res => {
      console.log("MTAFH_GetAccountForSalesGenie",res)
    
      this.accountlist =res;
      this.margindtls=res.accountMarginDetails;
      localStorage.setItem("MTAFHDetails",JSON.stringify(res));
     
    });
  }

  approve(){
    let status = "Approved";
     let RejectedRemark = "";
     this.dtTemplate.push({Para1: "", Para2: "", Para3: "", Para4: "", Para5: "", Para6: "", Para7: "", Para8: "", Para9: "", Para10: "",});
     const dataToSend = {
      ID:this.ID,
      UserID:this.UserID,
      Status:status,
      UserType:this.UserType,
      RejectRemark:RejectedRemark,
      DTTemplate: this.dtTemplate,
      
    };
     this.ds.MTAFH_ApproveAccount(dataToSend, this.TokenNo, this.device, this.deviceType,'MT AFH Approval Details').subscribe(res => {
        console.log("MTAFH_ApproveAccount",res);
       
        this.openModal(this.elRefOpenModalThankYou);
    });
  }

  reject(){
   this.router.navigate(['/home/facialRecRejReasonApproval'], { queryParams: { selected: 'MTAFH' } });
  }
  okay(){
    this.modalService.dismissAll();
    this.router.navigate(['/home/mtafhapproval',0,this.UserType,this.UserID]);
  }

  openModal(modal: ElementRef<any> | undefined) {
    this.modalService.open(modal,
      {
        size: 'lg',
        backdrop: 'static',
        windowClass: '',
        keyboard: false,
        centered: true,
      })
  }

  modalClose() {
    this.modalService.dismissAll();
    this.router.navigate(['/home/mtafhapproval',0,this.UserType,this.UserID]);
  }
}
