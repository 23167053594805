<div>
  <div class="row mt-4">
    <div class="col-12">
      <input class="fullWidth" type="text" name="" id="" placeholder="Search Outlet by Outlet Code" [(ngModel)]="searchTerm">
    </div>
  </div>

  <div *ngFor="let item of filteredList; let i = index;">
    <div class="myDiv" [class.myClass]="item === isSelectedObj">
      <div class="container-fluid">
        <div long-press (onLongPress)="onLongPress()">
          <div class="row">
            <div class="col-10 mt-2">
              <span class="text_14 text_black">{{item.outletName | titlecase}}</span><span class="ml-1 text_14 text_black"
                style="opacity: 40%;">{{item.outletCode}}</span>
            </div>
            <div class="col-2 text-right mt-2" *ngIf="isLongPress">
              <input type="checkbox" id="Check{{i}}" (change)="selected = i; clickOnSelect($event, i)"
                [(ngModel)]="list[i].checked" [checked]="selected === i">
              <!-- <img class="img" src="assets/check.png" *ngIf="!isSelected" (click)="clickOnSelect()" />-->
              <!-- <img class="img" src="assets/check_1.png" *ngIf="isSelected" /> -->
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-12">
              <span style="opacity: 40%;" class="text_12 text_black">{{item.rdName | titlecase}}<span
                  class="ml-1 text_12 text_black">{{item.rdCode}}</span></span>
            </div>
          </div>
          <hr>
          <div class="row">
            <div class="col-6 text-left">
              <span style="opacity: 40%;" class="text_12 text_black">Pull Out Reson</span>
            </div>
            <div class="col-6 text-right">
              <span class="text_12 text_black">{{item.visicoolerName}}</span>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-12 mb-2">
              <span class="text_14 text_black">{{item.pulloutReason | titlecase}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br>
  <br>
  <br>
  <br>
  <br>
</div>
<div class="footer" *ngIf="isSelected && userType?.toUpperCase() =='ASM' ">
  <!-- <div class="footer" *ngIf="isSelected"> -->
  <div class="row text-center mt-3">
    <div class="col-12" (click)="rowClick()">
      APPROVE
    </div>
  </div>
</div>
<ng-template #openModalMsg let-modal>
  <div class="modal-header">
    <h4 class="modal-title text_color_head text_20" id="modal-basic-title">
      <!-- <img class="img-fluid" src="assets/thanks_icon.png" /> -->
      <span class="ml-3">Message</span>
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modalClose()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      <div class="row">
        <div class="col-12">
          <span class="text_16">{{msg}}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer" (click)="modalClose()">
    OK
  </div>
</ng-template>
