import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { DataService } from 'src/app/service/data.service';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Enclass, ResultClass } from 'src/app/models/encryption-class';
import { EncryptionService } from 'src/app/service/encryption.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-outlet-name-change-approval-list-details',
  templateUrl: './outlet-name-change-approval-list-details.component.html',
  styleUrls: ['./outlet-name-change-approval-list-details.component.css']
})
export class OutletNameChangeApprovalListDetailsComponent implements OnInit {

  deviceType: any;
  device: any;
  userType: string | null = '';
  userId: any;
  msg: any;
  token: any;
  formname: any;
  actiondesc: any;
  fieldName: any;
  fieldValue: any;
  outletNameChangedApprovalListDetails: any
  @ViewChild('openRejectedReasonBox', { static: true }) elRefopenRejectedReasonBox: ElementRef | undefined;
  secretKey: any;
  clientId: any;
  public enclass: Enclass = new Enclass();
  public resultClass: ResultClass = new ResultClass();
  url:any=""
  constructor(private activatedRoute: ActivatedRoute, private toastr: ToastrService,
    private es: EncryptionService,
    private router: Router, private cs: CommonUtilitiesService,
    private headerService: HeaderService, private modalService: NgbModal, private mds: MongoDataService, private notifyMeService: NotifyMeService, private ds: DataService,) { }

  ngOnInit(): void {
    this.headerService.setTitle('Outlet Details');
    this.headerService.setActiveURl('/OutletNameChangeApprovalListDetails');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.token = localStorage.getItem("TokenNo");
    this.userType = localStorage.getItem("UserType");
    this.userId = localStorage.getItem("UserID");
    this.fieldName = localStorage.getItem("FieldName");
    this.fieldValue = localStorage.getItem("FieldValue");

    this.formname = "OutletNameChangeApprovalListDetails";
    this.actiondesc = "Outlet Details"
    this.secretKey = localStorage.getItem("SecreteKey")
    this.clientId = localStorage.getItem("ClientID");
    this.url=this.mds.url
    this.outletNameChangedApprovalListDetails = JSON.parse(sessionStorage.getItem("outletNameChangedApprovalListDetails") || '{}')
console.log("outletNameChangedApprovalListDetails",this.outletNameChangedApprovalListDetails)
    this.cs.GetGeoLocation(this.userId, this.userType, this.formname, this.actiondesc, this.fieldName, this.fieldValue);
    this.onLoad()
  }


  onLoad() {
    this.mds.getUserDetails(this.userId, this.token, this.device, this.deviceType,'Outlet Name Change Approve List').subscribe(res => {
      this.userType=res[0].userType.toUpperCase();
    });
  }

  Approve() {

    let request = {
      "ID": this.outletNameChangedApprovalListDetails.Id,
      "OutletCode": this.outletNameChangedApprovalListDetails.OutletCode,
      "Status": "Approved",
      "UserID": this.userId,
      "RejectionReason": "",
      "UserType": this.userType,
      "ApprovedFrom": "salesgenie"
    }

    this.es.WordKey = this.secretKey
    this.es.Word = JSON.stringify(request);
    this.enclass.buggerstring = this.es.encrypt(this.secretKey)

    this.mds.MA_OutletNameChangeApproval(this.enclass, this.token, this.device, this.deviceType, this.formname, this.clientId).subscribe(res => {
      this.resultClass = res;
      this.es.Word = this.resultClass.resultString;
      let response = JSON.parse(this.es.decrypt(this.secretKey));

      console.log("response***", response)

      if (response[0]?.Result?.toLowerCase() == 'ok') {
        this.toastr.success("Approved Successfully.")
        this.router.navigate(['/home/OutletNameChangeApprovalList']);
      }else{
        this.toastr.warning("Something went wrong!")
        return;
      }
    }, (error) => {
      console.log("error", error)
    });
  }

  Reject() {
    this.openModal(this.elRefopenRejectedReasonBox)
  }

  submitRejectedReason(rejectedReason:any) {
    if(rejectedReason==""){
      this.toastr.warning("Please Enter Rejected Reason")
      return;
    }

    let request = {
      "ID": this.outletNameChangedApprovalListDetails.Id,
      "OutletCode": this.outletNameChangedApprovalListDetails.OutletCode,
      "Status": "Rejected",
      "UserID": this.userId,
      "RejectionReason": rejectedReason,
      "UserType": this.userType,
      "ApprovedFrom": "salesgenie"
    }

    this.es.WordKey = this.secretKey
    this.es.Word = JSON.stringify(request);
    this.enclass.buggerstring = this.es.encrypt(this.secretKey)

    this.mds.MA_OutletNameChangeApproval(this.enclass, this.token, this.device, this.deviceType, this.formname, this.clientId).subscribe(res => {
      this.resultClass = res;
      this.es.Word = this.resultClass.resultString;
      let response = JSON.parse(this.es.decrypt(this.secretKey));

      console.log("response***", response)

      if (response[0]?.Result?.toLowerCase() == 'ok') {
        this.modalClose()
        this.toastr.success("Rejected Successfully.")
        this.router.navigate(['/home/OutletNameChangeApprovalList']); 
      }else{
        this.toastr.warning("Something went wrong!")
      }
    }, (error) => {
      console.log("error", error)
    })
  }

  openModal(modal: ElementRef<any> | undefined) {
    this.modalService.open(modal,
      {
        size: 'lg',
        backdrop: 'static',
        windowClass: '',
        keyboard: false,
        centered: true,
      })
  }
  modalClose() {
    this.modalService.dismissAll();
  }
}
