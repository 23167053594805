import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ResNotificationList } from 'src/app/models/kpi-models';
import { EncryptionService } from '../../service/encryption.service';
import { DataService } from '../../service/data.service';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';
import { Enclass, ResultClass } from 'src/app/models/encryption-class';


@Component({
  selector: 'app-notification-details',
  templateUrl: './notification-details.component.html',
  styleUrls: ['./notification-details.component.css']
})
export class NotificationDetailsComponent implements OnInit {
  ID: any;
  status: any;
  Flag: any;
  ParaName: any;
  UserID: any;
  UserType: any;
  ParaValue: any;
  TokenNo: any;
  notificationlist: any;
  device: any;
  deviceType: any;
  notificationImageUrl: any;
  notificationdetails: any;
  formname: any;
  actiondesc: any;
  fieldName: any;
  fieldValue: any;

  secretKey: any;
  clientId: any;
  public enclass: Enclass = new Enclass();
  public resultClass: ResultClass = new ResultClass();
  dataFrom:any=""
  constructor(private activatedRoute: ActivatedRoute, private fb: FormBuilder, private es: EncryptionService, private router: Router,
    private headerService: HeaderService, private ds: DataService, private cs: CommonUtilitiesService,) { }

  ngOnInit(): void {
    this.headerService.setTitle('Notification Details');
    this.ID = "0";
    this.status = "";
    this.Flag = "Meraapp";
    this.UserID = localStorage.getItem('UserID');
    this.TokenNo = localStorage.getItem("TokenNo");
    this.secretKey = localStorage.getItem("SecreteKey")
    this.clientId = localStorage.getItem("ClientID");
    this.device = localStorage.getItem('Device');
    this.deviceType = localStorage.getItem('DeviceType');
    this.fieldName = localStorage.getItem("FieldName");
    this.fieldValue = localStorage.getItem("FieldValue");
    this.UserType = localStorage.getItem('UserType');
    this.notificationImageUrl = localStorage.getItem("baseappurl") + "MeraApp2/Notification/";
    this.activatedRoute.paramMap.forEach(params => {
      this.ID = params.get('ID');
      this.status = params.get('flag');
      this.dataFrom=params.get('dataFrom')
      // this.loadData();
    });
    if(this.dataFrom!=""){
      this.loadData()
    }else{
    this.notificationdetails = JSON.parse(localStorage.getItem("notificationdetails") || '{}');
    }
    
    this.formname = "notificationdetails";
    this.actiondesc = "Notification Details";
    this.cs.GetGeoLocation(this.UserID, this.UserType, this.formname, this.actiondesc, this.fieldName, this.fieldValue);
  }

  loadData(): void {
debugger
    let request = {
      "companyCode": "mdlz",
      "userId": this.UserID,
      "id": this.ID,
    }

    this.es.WordKey = this.secretKey
    this.es.encrypt(this.secretKey);
    this.es.Word = JSON.stringify(request);
    this.enclass.buggerstring = this.es.encrypt(this.secretKey)
    this.ds.input_HD_TicketNotificationByID(this.enclass,this.clientId).subscribe(res => {
      console.log("GetNotification Details Data", res)
      this.resultClass = res;
      this.es.Word = this.resultClass.resultString;
      let response = JSON.parse(this.es.decrypt(this.secretKey));
      this.notificationdetails = response.Data[0];
    });
  }
}
