import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { SaveFirReason } from 'src/app/models/kpi-models';
import { ToastrService } from 'ngx-toastr';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-missing-reason',
  templateUrl: './missing-reason.component.html',
  styleUrls: ['./missing-reason.component.css']
})
export class MissingReasonComponent implements OnInit {
  deviceType: any;
  device: any;
  userType: string | null = '';
  userId: any;
  list: any;
  selected = -1;
  selectedReasons: any;
  outletSelectedMV: any;
  token: any;

  lat: number = 0;
  lng: number = 0;
  FirStatus: string = 'VisiMissing';
  slectedIndex: number = 0;
  saveFirReason: SaveFirReason = new SaveFirReason();
  firList: any;
  formname:any;
  actiondesc:any;
  fieldName:any;
  fieldValue:any;
  constructor(private activatedRoute: ActivatedRoute,private cs:CommonUtilitiesService, private router: Router, private headerService: HeaderService,
    private mds: MongoDataService, private notifyMeService: NotifyMeService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.headerService.setTitle('Missing Reason');
    this.headerService.setActiveURl('/missingReason');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.userType = localStorage.getItem("UserType");
    this.userId = localStorage.getItem("UserID");
    this.token = localStorage.getItem("TokenNo");
    this.fieldName = localStorage.getItem("FieldName");
    this.fieldValue = localStorage.getItem("FieldValue");
    this.outletSelectedMV = JSON.parse(localStorage.getItem("outletSelectedMV") || '{}');
    console.log("outletSelectedMV",this.outletSelectedMV);
    this.formname="missingReason";
    this.actiondesc="Missing Reason";
    this.cs.GetGeoLocation(this.userId,this.userType,this.formname,this.actiondesc,this.fieldName,this.fieldValue);
    this.onLoad();
    this.GetGeoLocation();
  }

  onLoad() {
    this.mds.getReasons(this.token, this.device, this.deviceType, 'Missing Reason').subscribe(res => {
      this.list = res.filter((singleItem: { [x: string]: string; }) => singleItem['reasonType'].startsWith("Missing"));
      console.log("list", this.list)
    });
  }

  selected_Reason(e: any) {
    if (e.target.checked) {
      this.selectedReasons = e.target.value;
    }
  }

  save() {
    if (this.selectedReasons == "Shifted to RD Point(Pull Out)") {
      this.saveRecord();
      localStorage.setItem("outLetSelected", JSON.stringify(this.outletSelectedMV));
      this.router.navigate(['/home/outletDetailPulloutreq'], { queryParams: { selected: 'VS' } });
    } else if (this.selectedReasons == "Shifted to Other Outlet (A to B Transfer)") {
      debugger
      if (this.outletSelectedMV.visicoolertype == "NONE") {
        this.toastr.warning("visiscooler is already detagged for this outlet ,you cannot transfer this outlet", "Warning");
      }
      else {
        this.saveRecord();
        localStorage.setItem("outletSelectedA", JSON.stringify(this.outletSelectedMV));
        this.router.navigate(['/home/outletDetailAtob'], { queryParams: { selected: 'VS' } });
      }
    } else if (this.selectedReasons == "Available at same Outlet") {
      this.saveRecord();
      this.router.navigate(['/home/missingVisicooler']);
    }
  }

  saveRecord() {
    debugger
    this.saveFirReason.UserId = this.userId;
    this.saveFirReason.FIRID = this.outletSelectedMV.firid;
    this.saveFirReason.OutletUID = this.outletSelectedMV.outletcode;
    this.saveFirReason.FirStatus = this.FirStatus;
    this.saveFirReason.ReasonID = this.slectedIndex;
    this.saveFirReason.RDCode = this.outletSelectedMV.rdCode;
    this.saveFirReason.Visicooler = this.outletSelectedMV.visicoolertype;
    this.saveFirReason.latitude = this.lat;
    this.saveFirReason.longitude = this.lng;
    console.log("saveFirReason", this.saveFirReason);
    this.mds.saveFIRRequest(this.saveFirReason, this.token, this.device, this.deviceType, 'Missing Visicooler').subscribe(res => {
      console.log("saveFIRRequest", res);
      this.firList = res;
      if (res[0].msg == 'OK') {
        // this.notifyMeService.showSuccess('Records Updated Sucessfully', 'Mera APP');
      }
    });
  }

  GetGeoLocation() {
    debugger
    if (navigator) {
      navigator.geolocation.getCurrentPosition(pos => {
        debugger
        this.lat = pos.coords.latitude;
        console.log("  this.lat", this.lat)
        this.lng = pos.coords.longitude;
      }, this.handleLocationError, { timeout: 30000 });
    }
    else {
      this.notifyMeService.showError('Location Could not be captured', 'Mera APP');
    }
  }

  handleLocationError(error: { code: any; }) {
    switch (error.code) {
      case 3:
        this.notifyMeService.showError('Timeout in Position capture', 'Mera APP V2');
        break;
      case 2:
        this.notifyMeService.showError('Device is not capable of Position capture', 'Mera APP V2');
        break;
      case 1:
        this.notifyMeService.showError('You denied for Position capture', 'Mera APP V2');
        break;
    }
  }
}
