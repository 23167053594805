import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { resPCAttendance,resPCFRData,InputPara } from 'src/app/models/kpi-models';
import { EncryptionService } from '../../service/encryption.service';
import { DataService } from '../../service/data.service';
import { Enclass, ResultClass } from '../../models/encryption-class';
import { MatDialog } from '@angular/material/dialog';
import { FilterComponent } from '../filter/filter.component';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-complaints-list',
  templateUrl: './complaints-list.component.html',
  styleUrls: ['./complaints-list.component.css']
})
export class ComplaintsListComponent implements OnInit {
  paracode: any;
  deviceType: any;
  device: any;
  UserID: any;
  isLongPress: boolean = false;
  isSelected: boolean = false;
  currentDate = new Date();
input:InputPara=new InputPara();
  heading :string="";
  CurrentDate:any;

  complaintlist:any;
  FRheading :string="";
  FRFieldName:string|null="";
  FRFieldValue:string|null="";
  list:any;
  myBooks: any;
  listOrigin:any;
  selectedText: any;
  userList1: any;
  lastkeydown1: number = 0;
  fieldName: any;
  fieldValue: any;
  private _searchText = '';
  filteredList: any;
  token: any;
  formname:any;
  actiondesc:any;
  userType:any;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private cs:CommonUtilitiesService,
     private headerService: HeaderService,  private mds: MongoDataService, private dialog: MatDialog,private ds:DataService) { }

  ngOnInit(): void {
    this.headerService.setTitle('Complaint List');
    this.headerService.setActiveURl('/complaintlist');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.fieldName=localStorage.getItem("OriginalFieldName");
    this.fieldValue=localStorage.getItem("OriginalFieldValue");
    this.UserID=localStorage.getItem("UserID");
    this.token = localStorage.getItem("TokenNo");

    this.UserID=localStorage.getItem("UserID");
    this.userType = localStorage.getItem("UserType");  
    this.fieldName = localStorage.getItem("FieldName");
    this.fieldValue = localStorage.getItem("FieldValue");

    this.formname="complaintlist";
    this.actiondesc="Complaint List"
    this.cs.GetGeoLocation(this.UserID,this.userType,this.formname,this.actiondesc,this.fieldName,this.fieldValue);

    this.getComplaintList();

  }
  fillAutoCompleteSearchBox() {
    this.myBooks = [];
    let mybook1 :any = [...new Set(this.listOrigin.map((bill: { rdname: string; rdcode: number; }) => (bill.rdname + ' [' + bill.rdcode + '] ' )))];
    this.myBooks = [].concat.apply([], mybook1);

  }
  getUserIdsFirstWay($event: any) {
    let userId = (<HTMLInputElement>document.getElementById('userIdFirstWay')).value;
   this.userList1 = [];
    if (userId.length > 2) {
      if ($event.timeStamp - this.lastkeydown1 > 200) {
        this.userList1 = this.searchFromArray(this.myBooks, userId);
      }
    }
  }
  searchFromArray(arr: any, regex : any) {
    let matches = [], i, j = 0;
    for (i = 0; i < arr.length; i++) {
      if (arr[i].toLowerCase().match(regex.toLowerCase())) {
        matches.push(arr[i]);
        j++;
        i = j < 10 ? i : arr.length;
      }
    }
    return matches;
  };

  SearchText(e: any): void {
    this.selectedText = e.target.value;
    // let isfound: boolean = false;
     let selectingType = this.selectedText.substring(this.selectedText.indexOf('[')+1,this.selectedText.indexOf(']'));
    //  this.RowClick(selectingType);
    //  (<HTMLInputElement>document.getElementById('userIdFirstWay')).value = '';
  }

  TicketClick()
  {}
  ClickNewRequest(){
this.router.navigate(['/home/searchcomplaint/']);

  }
  onLongPress() {
    this.isLongPress = true;
  }

  clickOnSelect() {
    this.isSelected = true;
  }
  getComplaintList()
  {
    this.complaintlist=[];
    this.input.FieldName=this.fieldName;
    this.input.FieldValue=this.fieldValue;
    this.input.TagName="0";
    this.input.Datatype='0';
    this.input.UserID=this.UserID;
    this.mds.getcomplaint(this.input, this.token, this.device, this.deviceType,'Complaint List').subscribe(res => {
      console.log("getcomplaint",res)
      this.complaintlist=res;
      this.filteredList = this.complaintlist;
   });
  }

  get searchTerm(): any{
    return this._searchText;
  }
  set searchTerm(value: any){
     this._searchText = value;
     this.filteredList = this.filteredListMethod(value);
  }

  filteredListMethod(value: any): any {
    return this.complaintlist.filter((ele: any) =>
      ele.outletname.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }
}
