<div class="container-fluid footer">
  <div class="row text-center">
    <div class="col-12">
      <div class="row mt-2">
        <div class="col-3">
          <img class="img" src="assets/home.png" [class.myClass]="activeURl != '/home'" (click)="onClickHome()"/>
        </div>
        <div class="col-3">
          <img class="img" src="assets/approvals_icon.png" [class.myClass]="activeURl != '/Approvals'" (click)="onClickApprovals()"/>
        </div>
        <div class="col-3">
          <img class="img" src="assets/transactions_icon.png" [class.myClass]="activeURl != '/transactions'" (click)="onClickTransactions()"/>
        </div>
        <div class="col-3">
          <img class="img" src="../../../assets/icons/saral_icon.svg" [class.myClass]="activeURl != '/ticketLogList'" (click)="onClickHelp()"/>
        </div>
      </div>

      <div class="row mt-1">
        <div class="col-3" [class.myClass]="activeURl != '/home'" (click)="onClickHome()">
          Home
        </div>
        <div class="col-3" [class.myClass]="activeURl != '/Approvals'" (click)="onClickApprovals()">
          Approvals
        </div>
        <div class="col-3" [class.myClass]="activeURl != '/transactions'" (click)="onClickTransactions()">
          Transactions
        </div>
        <div class="col-3" [class.myClass]="activeURl != '/ticketLogList'" (click)="onClickHelp()">
          Saral
        </div>
      </div>
    </div>
  </div>
</div>

