import { Component } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { Spinkit } from 'ng-http-loader'; 

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'MeraApp2';
  public spinkit = Spinkit;

  constructor(private updates: SwUpdate) {
    // Check for updates when the application starts
    this.updates.available.subscribe((event:any) => {
      if (confirm('A new version of the app is available. Do you want to update?')) {
        window.location.reload();
      }
    });


    // Optionally, check for updates periodically
    // this.updates.checkForUpdate().then(() => console.log('Checked for update'));
  }

  ngOnInit(){
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('firebase-messaging-sw.js')
        .then((registration) => {
          if (registration) {
            registration.update();
          }
          console.log('Service Worker registered with scope:', registration.scope);
        }).catch((error) => {
          console.log('Service Worker registration failed:', error);
        });
    }
  }
}
