<div class="row mt-4">
  <div class="col-9">
    <i class="fa fa-caret-left" style="color: #757575;">&nbsp;&nbsp;
      <span class="header_text">{{FieldValue=='0'? 'All India' : FieldValue=='ALLINDIA' ? 'All India': FieldValue
        |titlecase}}</span></i>
  </div>
  <div class="col-3 text-right" (click)="openFilter()">
    <img src="../../../assets/filter.png" class="img-fluid" /><sup><i class="fa fa-circle fa-sm"
        style="color: #eaab5e;font-size: 5px;"></i></sup>&nbsp;<span
      style="color: #724e8c;font-family: Roboto-Medium;font-weight:400 ;font-size: 14px;">Filter</span>
  </div>
</div>
<br>
<div class="row mt-2">
  <div class="col-4">
    <mat-form-field appearance="outline" style="width: 100px">
      <mat-select id="period" name="period" (selectionChange)="PeriodChange($event)" [(value)]="selectedperiod">
        <mat-option value="" disabled>Select Month</mat-option>
        <mat-option *ngFor="let item of periodList" [value]="item">
          {{ item }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-8 text-right" style="margin-top: 0.75rem;" *ngIf="tagname == 'Sales'">
    <img class="img-fluid" src="assets/sec_dot.png" />
    <span class="ml-1 Secondary_text">Secondary</span>&nbsp;&nbsp;&nbsp;
    <img class="img-fluid" src="assets/pri_dot.png" />
    <span class="ml-1 Secondary_text">Primary</span>
    <img class="img-fluid" src="assets/pri_dot.png" />
    <span class="ml-1 Secondary_text">Tgt</span>
  </div>
  <div class="col-8 text-right" style="margin-top: 0.75rem;" *ngIf="tagname == 'EC'">
    <img class="img-fluid" src="assets/sec_dot.png" />
    <span class="ml-1 Secondary_text">Billed RLA</span>&nbsp;&nbsp;&nbsp;
    <img class="img-fluid" src="assets/pri_dot.png" />
    <span class="ml-1 Secondary_text">Total RLA</span>
  </div>
  <div class="col-8 text-right" style="margin-top: 0.75rem;" *ngIf="tagname == 'Lines'">
    <img class="img-fluid" src="assets/sec_dot.png" />
    <span class="ml-1 Secondary_text">Secondary ULB</span>&nbsp;&nbsp;&nbsp;
    <img class="img-fluid" src="assets/pri_dot.png" />
    <span class="ml-1 Secondary_text">Primary ULB</span>
    <img class="img-fluid" src="assets/pri_dot.png" />
    <span class="ml-1 Secondary_text">ULB Target</span>
  </div>
</div>
<br>
<div>
  <div class="row mt-2" *ngFor="let item of filterItemsOfType(effectiveCoveragedata)"
    (click)="RowClick(item.fieldname, item.fieldvalue)">
    <div class="col-12">
      <div class="row">
        <div class="col-12">
          <span class="header_text">{{item.desc + ' ' + item.fieldvalue|titlecase}}</span>
        </div>
      </div>
      <div class="row" >
        <div class="col-3" *ngIf="tagname != 'Lines'">
          <div class="row mt-2">
            <div class="col-12" *ngIf="tagname != 'Lines'">
              <span class="Value_text">{{item.percentage}}%</span>
            </div>
          </div>
        </div>
        <div [class]="tagname != 'Lines' ? 'col-9': 'col-12'">
          <div class="row mt-2">
            <div class="col-12">
              <div class="progress rounded-pill" *ngIf="tagname == 'Sales'">
                <div class="progress-bar bg_sec" role="progressbar" [style.width.%]="item.sale" aria-valuenow="25"
                  aria-valuemin="0" aria-valuemax="100"></div>
                <div class="progress-bar bg_pri" role="progressbar" [style.width.%]="item.primarysale" aria-valuenow="25"
                  aria-valuemin="0" aria-valuemax="100"></div>
                  <div class="progress-bar bg_pri" role="progressbar" [style.width.%]="item.tgt" aria-valuenow="25"
                  aria-valuemin="0" aria-valuemax="100"></div>
              </div>
              <div class="progress rounded-pill" *ngIf="tagname == 'EC'">
                <div class="progress-bar bg_sec" role="progressbar" [style.width.%]="item.billedrla" aria-valuenow="25"
                  aria-valuemin="0" aria-valuemax="100"></div>
                <div class="progress-bar bg_pri" role="progressbar" [style.width.%]="item.rla" aria-valuenow="25"
                  aria-valuemin="0" aria-valuemax="100"></div>
              </div>
              <div class="progress rounded-pill" *ngIf="tagname == 'Lines'">
                <div class="progress-bar bg_sec" role="progressbar" [style.width.%]="item.rla" aria-valuenow="25"
                  aria-valuemin="0" aria-valuemax="100"></div>
                <div class="progress-bar bg_pri" role="progressbar" [style.width.%]="item.linebilled" aria-valuenow="25"
                  aria-valuemin="0" aria-valuemax="100"></div>
                  <div class="progress-bar bg_pri" role="progressbar" [style.width.%]="item.tgt" aria-valuenow="25"
                  aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>
          </div>
          <div class="row mt-2" *ngIf="tagname == 'Sales'">
            <div class="col-4 text-center">
              <span class="text_14" >{{item.sale}} {{item.unit}}</span>
            </div>
            <div class="col-4 text-center">
              <span class="text_14" >{{item.primarysale}} {{item.unit}}</span>
            </div>
            <div class="col-4 text-center">
              <span class="text_14" >{{item.tgt}} {{item.unit}}</span>
            </div>
          </div>
          <div class="row mt-2" *ngIf="tagname == 'EC'">
            <div class="col-6 text-center">
             
              <span class="text_14" >{{item.billedrla}}</span>
            </div>
            <div class="col-6 text-center">
             
              <span class="text_14">{{item.rla}}</span>
            </div>
          </div>
          <div class="row mt-2" *ngIf="tagname == 'Lines'">
            <div class="col-4 text-center">
              <span class="text_14">{{item.rla}}</span>
            </div>
            <div class="col-4 text-center">
              <span class="text_14" >{{item.linebilled}}</span>
            </div>
            <div class="col-4 text-center">
              <span class="text_14" >{{item.tgt}}</span>
            </div>
          </div>

        </div>
      </div>
    </div>
    <hr class="hr_cust">
  </div>
</div>
<br>
<!-- <div *ngIf="IsRDValue">
  <div class="row bg_CMA" *ngFor="let item of rdData">
    <div class="col-12">
      <div class="row">
        <div class="col-12">&nbsp;</div>
      </div>
      <div class="row">
        <div class="col-12 text-center">
          <span class="rdheading">{{item.desc|titlecase}}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center mt-3 ">
          <span class="rdcodecss">{{item.fieldvalue}}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center mt-3 ">
          <span *ngIf="item.percentage >=100" class="rdstatusAchived"> <img src="../../../assets/target_achieved.png"
              class="img-fluid" />&nbsp;
            Great! Target Achieved</span>
          <span *ngIf="item.percentage < 100" class="rdstatusOnTrack"> <img src="../../../assets/targetachieved.png"
              class="img-fluid" />&nbsp;
            On Track</span>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-3">
          <div class="row mt-2">
            <div class="col-12">
              <span class="Value_text">{{item.percentage}}%</span>
            </div>
          </div>
        </div>
        <div class="col-9">
          <div class="row mt-2">
            <div class="col-12">
              <div class="progress rounded-pill" *ngIf="tagname == 'Sales'">
                <div class="progress-bar bg_sec" role="progressbar" [style.width.%]="item.sale" aria-valuenow="25"
                  aria-valuemin="0" aria-valuemax="100"></div>
                <div class="progress-bar bg_pri" role="progressbar" [style.width.%]="item.primarysale" aria-valuenow="25"
                  aria-valuemin="0" aria-valuemax="100"></div>
              </div>
              <div class="progress rounded-pill" *ngIf="tagname == 'EC'">
                <div class="progress-bar bg_sec" role="progressbar" [style.width.%]="item.billedrla" aria-valuenow="25"
                  aria-valuemin="0" aria-valuemax="100"></div>
                <div class="progress-bar bg_pri" role="progressbar" [style.width.%]="item.rla" aria-valuenow="25"
                  aria-valuemin="0" aria-valuemax="100"></div>
              </div>
              <div class="progress rounded-pill" *ngIf="tagname == 'Lines'">
                <div class="progress-bar bg_sec" role="progressbar" [style.width.%]="item.linebilled" aria-valuenow="25"
                  aria-valuemin="0" aria-valuemax="100"></div>
                <div class="progress-bar bg_pri" role="progressbar" [style.width.%]="item.rla" aria-valuenow="25"
                  aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-6 text-center">
              <span class="text_14" *ngIf="tagname == 'Sales'">{{item.sale}} {{item.unit}}</span>
              <span class="text_14" *ngIf="tagname == 'EC'">{{item.billedrla}}</span>
              <span class="text_14" *ngIf="tagname == 'Lines'">{{item.linebilled}}</span>
            </div>
            <div class="col-6 text-center">
              <span class="text_14" *ngIf="tagname == 'Sales'">{{item.primarysale}} {{item.unit}}</span>
              <span class="text_14" *ngIf="tagname == 'EC'">{{item.rla}}</span>
              <span class="text_14" *ngIf="tagname == 'Lines'">{{item.rla}}</span>
            </div>
          </div>
          <div class="row" *ngIf="tagname == 'Sales'">
            <div class="col-6 text-center">
              <img class="img-fluid" src="assets/sec_dot.png" />
              <span class="ml-1 Secondary_text">Secondary</span>
            </div>
            <div class="col-6 text-center">
              <img class="img-fluid" src="assets/pri_dot.png" />
              <span class="ml-1 Secondary_text">Primary</span>
            </div>
          </div>
          <div class="row" *ngIf="tagname == 'EC'">
            <div class="col-6 text-center">
              <img class="img-fluid" src="assets/sec_dot.png" />
              <span class="ml-1 Secondary_text">Billed RLA</span>
            </div>
            <div class="col-6 text-center">
              <img class="img-fluid" src="assets/pri_dot.png" />
              <span class="ml-1 Secondary_text">Total RLA</span>
            </div>
          </div>
          <div class="row" *ngIf="tagname == 'Lines'">
            <div class="col-6 text-center">
              <img class="img-fluid" src="assets/sec_dot.png" />
              <span class="ml-1 Secondary_text">Lined RLA</span>
            </div>
            <div class="col-6 text-center">
              <img class="img-fluid" src="assets/pri_dot.png" />
              <span class="ml-1 Secondary_text">Total RLA</span>
            </div>
          </div>
          <div class="row">
            <div class="col-12">&nbsp;</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br>
  <div class="row mt-2">
    <div class="col-12">
      <span class="header_text">Substk Details</span>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-4">
      <span class="text_12">Substk Details</span>
    </div>
    <div class="col-8 text-right" *ngIf="tagname == 'Sales'">
      <img class="img-fluid" src="assets/sec_dot.png" />
      <span class="ml-1 Secondary_text">Secondary</span>&nbsp;&nbsp;&nbsp;
      <img class="img-fluid" src="assets/pri_dot.png" />
      <span class="ml-1 Secondary_text">Primary</span>
    </div>
    <div class="col-8 text-right" *ngIf="tagname == 'EC'">
      <img class="img-fluid" src="assets/sec_dot.png" />
      <span class="ml-1 Secondary_text">Billed RLA</span>&nbsp;&nbsp;&nbsp;
      <img class="img-fluid" src="assets/pri_dot.png" />
      <span class="ml-1 Secondary_text">Total RLA</span>
    </div>
    <div class="col-8 text-right" *ngIf="tagname == 'Lines'">
      <img class="img-fluid" src="assets/sec_dot.png" />
      <span class="ml-1 Secondary_text">Lined RLA</span>&nbsp;&nbsp;&nbsp;
      <img class="img-fluid" src="assets/pri_dot.png" />
      <span class="ml-1 Secondary_text">Total RLA</span>
    </div>
  </div>
  <div class="row mt-2" *ngFor="let item of filterItemsOfType(effectiveCoveragedata)">
    <div class="col-12">
      <div class="row mt-2">
        <div class="col-8">
          <span class="tgt_text">{{item.desc | titlecase}}</span>
        </div>
        <div class="col-4 text-right">
          <img *ngIf="item.ach > item.tgt" src="../../../assets/ach.png" class="img-fluid" />&nbsp;
          <span class="ach_text">{{item.billedrla}}</span>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-8">
          <span class="Secondary_text">{{item.valuetype}}</span>
        </div>
        <div class="col-4 text-right">
          <span class="tgt_text">{{item.totrla}}</span>
        </div>
      </div>
    </div>
  </div>
</div> -->
<br>
<br>
<br>
