import { Component,ElementRef,OnInit, AfterViewInit,ViewChild,ViewChildren,QueryList} from "@angular/core";
import { MatDialog } from '@angular/material/dialog';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { ValuePerformancedata,Resultdata, InputPara } from 'src/app/models/kpi-models';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterComponent } from '../filter/filter.component';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';
import { NotifyMeService } from "src/app/service/notify-me.service";
import { DataService } from "src/app/service/data.service";

@Component({
  selector: 'app-value-performance',
  templateUrl: './value-performance.component.html',
  styleUrls: ['./value-performance.component.css']
})
export class ValuePerformanceComponent implements OnInit, AfterViewInit {
  FieldName: any;
  FieldValue: any;
  DataType: any;
  UserID: any;
  valueperformancedata: any;
  data: any;

  periodList: any[] = [];
  selectedperiod: any;
  UserType: any;
  resultdata:Resultdata=new Resultdata();
  @ViewChild("oneItem") oneItem: any;
  @ViewChildren("count") count: any;
  device: any;
  deviceType: any;
  input:InputPara=new InputPara();
  token: any ;
  lat:any;
  lng:any

  constructor(private elRef: ElementRef,private activatedRoute: ActivatedRoute,private cs:CommonUtilitiesService,private fb: FormBuilder, private router: Router, private headerService: HeaderService, private mds: MongoDataService, 
    private dialog: MatDialog,private notifyMeService: NotifyMeService,private ds: DataService,) { }

  ngOnInit(): void {
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");

  //  this.headerService.setActiveURl('/ValuePerformance');
  //  this.headerService.setTitle("value performance (Second Half)");
    this.headerService.setTitle("value performance");
    this.UserType = localStorage.getItem("UserType");
    this.UserID = localStorage.getItem("UserID");
    this.cs.setFieldNamevalue();
    this.FieldName=localStorage.getItem("FieldName");
    this.FieldValue=localStorage.getItem("FieldValue");
    this.token = localStorage.getItem("TokenNo");
    this.selectedperiod=localStorage.getItem("selectedperiod");
    this.GetGeoLocation();
    this.DataType = "0"
    this.valueperformancedata = [];
    this.activatedRoute.paramMap.forEach(params => {
      this.selectedperiod = params.get('selectedperiod');
      this.loadData();
    });
  }

  
  GetGeoLocation() {
    debugger
    if (navigator) {
      navigator.geolocation.getCurrentPosition(pos => {
        this.lat = pos.coords.latitude;
        console.log("  this.lat", this.lat)
        this.lng = pos.coords.longitude;
        this.SetapplicationLogData();
      }, this.handleLocationError, { timeout: 30000 });
    }
    else {
      this.notifyMeService.showError('Location Could not be captured', 'Mera APP');
    }
  }

  handleLocationError(error: { code: any; }) {
    switch (error.code) {
      case 3:
        this.notifyMeService.showError('Timeout in Position capture', 'Mera APP V2');
        break;
      case 2:
        this.notifyMeService.showError('Device is not capable of Position capture', 'Mera APP V2');
        break;
      case 1:
        this.notifyMeService.showError('You denied for Position capture', 'Mera APP V2');
        break;
    }
  }

  
  SetapplicationLogData() {
    debugger
    let request = {
      "userid": this.UserID,
      "usertype": this.UserType,
      "formname": "ValuePerformance",
      "actiondesc": "Value Performance Data",
      "fieldname": this.FieldName,
      "fieldvalue": this.FieldValue,
      "latitude": this.lat,
      "longitude": this.lng
    }
    this.ds.SetapplicationLogData(request).subscribe(res => {
      console.log("res", res)
    })
  }

loadData(): void {
  this.input.FieldName=this.FieldName;
    this.input.FieldValue=this.FieldValue;
    this.input.TagName=this.DataType;
    this.input.Datatype=this.DataType;
    this.input.UserID=this.UserID;
    this.mds.getTargetAchivementValue(this.input,this.token,this.device,this.deviceType,'Value Performance').subscribe(res => {
     this.valueperformancedata = res as ValuePerformancedata;
     console.log("this.valueperformancedata",this.valueperformancedata)
      this.periodList = [...new Set(res.map((bill: { period: any; }) => bill.period))].sort();
     // this.selectedperiod = this.periodList[0];
     this.data = this.valueperformancedata.filter((T: { [x: string]: any; }) => (T['period'] == this.selectedperiod));
    });
    localStorage.setItem("rdData", '');
  }
  PeriodChange(e: any): void {
    this.selectedperiod = e.source._value;
    this.data = this.valueperformancedata.filter((T: { [x: string]: any; }) => (T['period'] == this.selectedperiod));

  }
  filterItemsOfType(type: any) {
    return type != null ? type.filter((x: { [x: string]: any; }) => (x['period'] == this.selectedperiod)) : null;
  }
  RowClick(e: any): void {
    localStorage.setItem("DataType",e);
    localStorage.setItem("selectedperiod",this.selectedperiod);
    this.router.navigate(['/home/ValuePerformanceData/',this.FieldName,this.FieldValue,this.UserID,localStorage.getItem("DataType"),this.selectedperiod]);
  }
  onCountoEnd(): void {
    console.log('counto end');
}
ngAfterViewInit() {
  this.animateCount();
}
animateCount() {
  let _this = this;

  let single = this.oneItem.nativeElement.innerHTML;

  this.counterFunc(single, this.oneItem, 7000);

  this.count.forEach((item: { nativeElement: { innerHTML: number; }; }) => {
    _this.counterFunc(item.nativeElement.innerHTML, item, 2000);
  });
}
counterFunc(end: number, element: any, duration: number) {
  let range, current: number, step, timer:any;

  range = end - 0;
  current = 0;
  step = Math.abs(Math.floor(duration / range));

  timer = setInterval(() => {
    current += 1;
    element.nativeElement.textContent = current;
    if (current == end) {
      clearInterval(timer);
    }
  }, step);
}
  openFilter(){
    if (this.dialog.openDialogs.length == 0) {
      const dialogRef = this.dialog.open(FilterComponent, {
        closeOnNavigation: true,
        data: {
          "FieldName": localStorage.getItem("OriginalFieldName"),
          "FieldValue": localStorage.getItem("OriginalFieldValue"),
          "UserID": this.UserID,
          "MenuUpTo": "4",
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.FieldName=result.FieldName;
          this.FieldValue=result.FieldValue;
          this.loadData();
              }
      });
    }
  }
}
