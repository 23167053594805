import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { InputPara } from 'src/app/models/kpi-models';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterComponent } from '../filter/filter.component';
import { MatDialog } from '@angular/material/dialog';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-kpi-productivity-daywise',
  templateUrl: './kpi-productivity-daywise.component.html',
  styleUrls: ['./kpi-productivity-daywise.component.css']
})
export class KpiProductivityDaywiseComponent implements OnInit {
  FieldName: any;
  FieldValue: any;
  UserID: any;
  productivitydata: any;
  device: any;
  deviceType: any;
  input:InputPara=new InputPara();
  periodList: any[] = [];
  selectedperiod: any;
  token: any;
  UserType:any;
  formname:any;
  actiondesc:any;

  constructor(private activatedRoute: ActivatedRoute,private cs:CommonUtilitiesService, private dialog: MatDialog,private fb: FormBuilder,private router: Router, private headerService: HeaderService, private mds: MongoDataService) { }

  ngOnInit(): void {
    this.headerService.setActiveURl('/kpiProductivityDaywise');
    this.headerService.setTitle("Day Wise Productivity");
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.UserType = localStorage.getItem("UserType");
    // this.FieldName = localStorage.getItem("FieldName");
    // this.FieldValue = localStorage.getItem("FieldValue");
    this.UserID = localStorage.getItem("UserID");
    this.token = localStorage.getItem("TokenNo");
    this.selectedperiod = localStorage.getItem("currperiod");
    this.activatedRoute.paramMap.forEach(params => {
      this.FieldValue = params.get('FieldValue');
      this.FieldName = params.get('FieldName');
      this.formname="kpiProductivityDaywise";
      this.actiondesc="Kpi Productivity Day wise"+" "+this.FieldName;
      this.cs.GetGeoLocation(this.UserID,this.UserType,this.formname,this.actiondesc,this.FieldName,this.FieldValue);
      this.loadData();
    });
  }

  loadData(): void {
    this.input.FieldName=this.FieldName;
    this.input.FieldValue=this.FieldValue;
    this.input.TagName = '0';
    this.input.Datatype='DAYWISE';
    this.input.UserID=this.UserID;
    this.mds.getProductivity(this.input, this.token, this.device, this.deviceType,'kpiProductivityDaywise').subscribe(res => {
      this.productivitydata = res;
      this.periodList = [...new Set(res.map((bill: { period: any; }) => bill.period))].sort();
    });
  }

  PeriodChange(e: any): void {
    this.selectedperiod = e.source._value;
  }

  filterItemsOfTypeProductivity(type: any) {
    return type != null ? type.filter((x: { [x: string]: any; }) => (x['period'] == this.selectedperiod)) : null;
  }

}
