import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { MatDialog } from '@angular/material/dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterComponent } from '../filter/filter.component';
import { DataService } from '../../service/data.service';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-a2bpdf',
  templateUrl: './a2bpdf.component.html',
  styleUrls: ['./a2bpdf.component.css']
})
export class A2bpdfComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute,private fb: FormBuilder,private router: Router,
    private dialog: MatDialog,private ds:DataService,private headerService: HeaderService, 
    private modalService: NgbModal,private cs:CommonUtilitiesService) { }
    deviceType: any;
    device: any;
    userType: string | null = '';
    userId: any;
    token: any;
    filename:string|null='';
    KeyID: string|null='';
    type:string|null='';
    imgurl:string|null='';
    formname:any;
    actiondesc:any;
    FieldName:any;
    FieldValue:any;
   
  ngOnInit(): void {

    this.headerService.setTitle('Check Agreement');
    this.headerService.setActiveURl('/vc');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.token = localStorage.getItem("TokenNo");
    this.userId = localStorage.getItem("UserID");
    

    this.activatedRoute.paramMap.forEach(params => {
     
      this.KeyID = params.get('KeyID');
      this.type=params.get('type');
      if(this.type=='Agree'){this.type='Agreement'}
      this.filename=this.KeyID+"_"+this.type+".pdf";
      if (this.KeyID != null) {
        if (this.KeyID.length > 0 && this.KeyID.length > 0) {
      this.downloadpdf(this.KeyID,this.type);
   
        }
      }
      
    });

  
    this.userType = localStorage.getItem("UserType");
    this.FieldName = localStorage.getItem("FieldName");
    this.FieldValue = localStorage.getItem("FieldValue");
    this.formname="login/a2bpdf";
    this.actiondesc="a2bpdf"+" "+this.KeyID
    this.cs.GetGeoLocation(this.userId,this.userType,this.formname,this.actiondesc,this.FieldName,this.FieldValue);
  }

  downloadpdf(keyid:any,type:any): void{
    if(type=='Agree'){type='Agreement'}
   this.filename=keyid+"_"+type+".pdf";
   debugger;
       this.ds.getDownloadPDFFileFromS3Bucket("eAgreementMaster", this.filename);
  }


}
