import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Enclass } from '../models/encryption-class';
import { MarketData, VisicoolerMapping, OifApproval, inputOifapproval, ComplaintSave, InputPara, OutputGetVisiAgrOTP, OutputAgreeDetails } from '../models/kpi-models';
import { environment } from 'src/environments/environment';
import { MongoDataService } from './mongo-data.service';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  // public url = 'https://devsalespulseapi.cilsales.net/api/';
  // public url = 'https://localhost:44368/api/';
  //  public url = 'https://salespulseapi.cilpwa.net/api/';
  // public url = 'https://testapi1.cilsales.net/api/';
  // public url = 'https://devmeraapp.cilsales.net/#/';

  public url = environment.apiUrl;
  // url = ""
  constructor(private http: HttpClient,private mds:MongoDataService,private notification:NotificationService) {
  }

  public visicColerForm: any;


  // isSetOriginalUrl(res: boolean) {
  //   debugger
  //   if (res) {
  //     this.url = environment.apidevsalespulseapiUrl
  //   } else {
  //     this.url = environment.apiUrl
  //   }
  //   this.mds.url=this.url
  //   this.notification.url=this.url
  // }




  authentication(formData: Enclass, secretKey: string, device: any, deviceType: any, FormName: any): Observable<any> {
    debugger
    let httpOptionsWithSecretekey = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Secretekey': secretKey,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    console.log("httpOptionsWithSecretekey", httpOptionsWithSecretekey);
    return this.http.post<any>(this.url + "Authentication/MeraApp2Authjentication", formData, httpOptionsWithSecretekey);
  }


  UserLogin(formData: Enclass, secretKey: string, device: any, deviceType: any, FormName: any): Observable<any> {
    debugger
    let httpOptionsWithSecretekey = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'clientID':"11"
        // 'Secretekey': secretKey,
        // 'DeviceID': device,
        // 'DeviceType': deviceType,
        // 'FormName': FormName,
      })
    };
    console.log("httpOptionsWithSecretekey", httpOptionsWithSecretekey);
    return this.http.post<any>("https://fosrocapi.hspldms.com/api/" + "Fosroc/UserLogin", formData, httpOptionsWithSecretekey);
  }


  SetapplicationLogData(formData: any) {
    debugger
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        // 'Secretekey': secretKey,
        // 'DeviceID': device,
        // 'DeviceType': deviceType,
        // 'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + "MeraApp2Reporting/MA_SetapplicationLogData", formData, httpOptions);
  }

  getUserLoginData(formData: Enclass, token: string, clientID: string, device: any, deviceType: any, FormName: any): Observable<any> {
    console.log("formData", formData.buggerstring + " token", token + " clientID", clientID);

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        "ClientID": clientID,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    console.log("httpOptions", httpOptions);
    return this.http.post<any>(this.url + "MeraApp2/GetuserLoginData", formData, httpOptions);
  }
  /** POST DATA TO SERVER */
  getMenuData(formData: Enclass, token: string, clientID: string, device: any, deviceType: any, FormName: any): Observable<any> {
    console.log("formData", formData.buggerstring + " token", token + " clientID", clientID);

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        "ClientID": clientID,
      })
    };
    console.log("httpOptions", httpOptions);
    return this.http.post<any>(this.url + "MeraApp2/GetuserLoginData", formData, httpOptions);
  }

  getValuePerformance(input: InputPara, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + 'Mongo/GetTargetAchivementValue/', input, httpOptions);
  }
  GetValuePerformanceData(formData: MarketData, token: string, clientID: string, device: any, deviceType: any, FormName: any): Observable<any> {

    // console.log("formData", formData.buggerstring + " token", token + " clientID", clientID);

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        "ClientID": clientID,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    console.log("httpOptions", httpOptions);
    return this.http.post<any>(this.url + "MeraApp2/GetValuePerformance", formData, httpOptions);
  }
  GetOutletList(PCUID: any, RDCode: any, UserID: string, token: string, clientID: string, device: any, deviceType: any, FormName: any): Observable<any> {


    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        "ClientID": clientID,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    console.log("httpOptions", httpOptions);
    return this.http.get<any>(this.url + 'MeraApp2/MA_GetPCOutletList/' + RDCode + '/' + PCUID + '/' + UserID, httpOptions);
  }

  GetNotificationData(UserID: any, ID: any, Flag: any, Status: any, token: string, clientID: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        "ClientID": clientID,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.get<any>(this.url + 'MeraApp2Reporting/MA_GetNotificationData/' + UserID + '/' + ID + '/' + Flag + '/' + Status, httpOptions);
  }


  input_HD_TicketNotificationByID(input: any,clientId:any): Observable<any> {
    debugger
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'ClientID':clientId
      })
    };
    return this.http.post<any>(this.url + "HelpDesk/HD_TicketNotificationByID", input, httpOptions);
  }

  GetTSIMarketData(formData: MarketData, token: string, clientID: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        "ClientID": clientID,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    console.log("httpOptions", httpOptions);
    return this.http.post<any>(this.url + "MeraApp2/GetTSIMarketData ", formData, httpOptions);
  }

  MA_GetBrandDisplayData(SrNo: number, status: string, token: string, clientID: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        "ClientID": clientID,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    console.log("httpOptions", httpOptions);


    return this.http.get<any>(this.url + "MeraApp2Reporting/MA_GetBrandDisplayData/" + SrNo + '/' + status, httpOptions);
  }

  //GetMarketData(formData: Enclass,token: string, clientID: string): Observable<any> {
  GetMarketData(formData: MarketData, token: string, clientID: string, device: any, deviceType: any, FormName: any): Observable<any> {

    //console.log("formData", formData.buggerstring + " token", token + " clientID", clientID);

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        "ClientID": clientID,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    console.log("httpOptions", httpOptions);
    return this.http.post<any>(this.url + "MeraApp2/GetMarketData ", formData, httpOptions);
  }


  GetEligibleOutletList(UserID: string, RequirementType: string, ID: number, UserType: string, token: string, clientID: string, device: any, deviceType: any, FormName: any): Observable<any> {


    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        "ClientID": clientID,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    console.log("httpOptions", httpOptions);

    return this.http.get<any>(this.url + 'MaATS/GetEligibleOutletList/' + UserID + '/' + RequirementType + '/' + ID + '/' + UserType, httpOptions);
  }


  GetNonEligibleOutletList(UserID: string, RequirementType: string, ID: number, UserType: string, token: string, clientID: string, device: any, deviceType: any, FormName: any): Observable<any> {


    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        "ClientID": clientID,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    console.log("httpOptions", httpOptions);

    return this.http.get<any>(this.url + 'MaATS/GetNonEligibleOutletList/' + UserID + '/' + RequirementType + '/' + ID + '/' + UserType, httpOptions);
  }

  CheckVisicoolerMapping(visicoolerMapping: VisicoolerMapping, token: string, clientID: string, device: any, deviceType: any, FormName: any): Observable<any> {

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        "ClientID": clientID,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    console.log("httpOptions", httpOptions);
    return this.http.post<any>(this.url + "MaATS/CheckVisicoolerMapping", visicoolerMapping, httpOptions);
  }


  SaveOIFRequest(oifapproval: inputOifapproval, token: string, clientID: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        "ClientID": clientID,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    console.log("httpOptions", httpOptions);
    return this.http.post<any>(this.url + 'MaATS/SaveOIFRequest/', oifapproval, httpOptions);
  }


  GetRequirementTypeCount(UserID: string, UserType: string, token: string, clientID: string, device: any, deviceType: any, FormName: any): Observable<any> {
    //return this.http.get(this.url + 'ATS/GetRequirementTypeCount/' + UserID + '/' + UserType, this.httpOptions);
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        "ClientID": clientID,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    console.log("httpOptions", httpOptions);

    return this.http.get(this.url + 'MaATS/GetRequirementTypeCount/' + UserID + '/' + UserType, httpOptions);
  }
  public GetMeraAppVersion(): Observable<any> {
    debugger
    let httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',

      })
    }
    return this.http.get<any>(this.url + 'MeraApp2Reporting/GetMeraAppVersion/', httpOptions1);
  }

  getGetFRData(input: InputPara, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + 'MeraApp2/MA_GetFRData/', input, httpOptions);
  }


  GetFRRDList(userId: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.get<any>(this.url + 'MeraApp2/MA_GetFRList/' + userId, httpOptions);
  }

  //MA_ForgotpasswordNew/{UserID
  forgotpasswordNew(userId: any, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.get<any>(this.url + 'MeraApp2/MA_ForgotpasswordNew/' + userId, httpOptions);
  }
  //MA_GateMeetingData/{UserID}/{paracode}/{paraname}/{paravalue}/{rdtype}
  gateMeetingData(input: InputPara, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + 'MeraApp2/MA_GateMeetingData/', input, httpOptions);
  }



  //https://localhost:44319/api/MeraApp2/MA_GetPCFTDUnbilledOut/0/0/0/0/0
  getPCFTDUnbilledOut(input: InputPara, DataType: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + "MeraApp2/MA_GetPCFTDUnbilledOut/", input, httpOptions);

  }
  getVMSComplaintList(userId: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.get<any>(this.url + 'MeraApp2/MA_GetVMSComplaintList/' + userId, httpOptions);
  }

  // GetVMSCallDetails(OutletCode :any,userId: any): Observable<any> {

  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'Access-Control-Allow-Origin': '*',
  //       // 'DeviceID': device,
  //       // 'DeviceType': deviceType,
  //     })
  //   };
  //   return this.http.get<any>(this.url + 'MaATS/ATS_GetVMSCallDetails/'+OutletCode+'/'+userId, httpOptions);
  // }
  public SaveVMSComplaint(objComp: ComplaintSave, token: any, clientID: any, device: any, deviceType: any, FormName: any): Observable<any> {

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        "ClientID": clientID,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    console.log("httpOptions", httpOptions);
    return this.http.post<any>(this.url + 'MaATS/SaveVMSComplaint/', objComp, httpOptions);
  }

  updateUsersMobileNo(userId: any, mobNo: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.get<any>(this.url + 'MeraApp2/MA_UpdateUsersMobileNo/' + userId + '/' + mobNo, httpOptions);
  }

  profileImageDownload(userId: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      }),
    };
    return this.http.post<any>(this.url + 'MeraApp2/ProfileImageDownload/', userId, { observe: 'response', responseType: 'blob' as 'json', reportProgress: true, });
  }

  AToBImagesDownload(filename: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      }),
    };
    return this.http.post<any>(this.url + 'MeraApp2/AToBImagesDownload/', filename, { observe: 'response', responseType: 'blob' as 'json', reportProgress: true, });
  }
  savePraise(formData: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + 'MeraApp2Reporting/MA_SetPraise/', formData, httpOptions);
  }

  FIRMenuCount(UserID: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.get<any>(this.url + 'MaATS/FIRMenucount/' + UserID, httpOptions);
  }

  getVisiMake(dataToSend: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + 'MaATS/ATS_GetVisiMake', dataToSend, httpOptions);
  }

  getRegisterEntry(UserID: any, PCUID: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.get<any>(this.url + 'MeraApp2/MA_FRGetRegisterEntry/' + UserID + '/' + PCUID, httpOptions);
  }

  //MA_SalesmanMasterForApproval_List/{UserID}/{rdcode}/{Pccode}
  salesmanMasterForApproval_List(UserID: any, rdcode: any, Pccode: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.get<any>(this.url + 'MeraApp2/MA_SalesmanMasterForApproval_List/' + UserID + '/' + rdcode + '/' + Pccode, httpOptions);
  }


  FRSetPCApprovalData(UserID: any, Status: any, PCUID: any, RejectedRemark: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.get<any>(this.url + 'MeraApp2/MA_FRSetPCApprovalData/' + UserID + '/' + Status + '/' + PCUID + '/' + RejectedRemark, httpOptions);
  }

  salesmanApprove(rdcode: any, Pccode: any, UserID: any, Status: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.get<any>(this.url + 'MeraApp2/MA_SalesmanApprove/' + rdcode + '/' + Pccode + '/' + UserID + '/' + Status, httpOptions);
  }

  //MA_GetMeetingDetails/{UserID}/{MeetingID}
  getMeetingDetails(UserID: any, MeetingID: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.get<any>(this.url + 'MeraApp2/MA_GetMeetingDetails/' + UserID + '/' + MeetingID, httpOptions);
  }
  //MA_SetStartMeetingDetails/{UserID}/{StartLat}/{StartLong}
  setStartMeetingDetails(UserID: any, StartLat: any, StartLong: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.get<any>(this.url + 'MeraApp2/MA_SetStartMeetingDetails/' + UserID + '/' + StartLat + '/' + StartLong, httpOptions);
  }
  //[HttpPost("MA_SetFinishMeetingDetails")]
  setFinishMeetingDetails(dataToSend: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
      })
    };

    return this.http.post<any>(this.url + 'MeraApp2/MA_SetFinishMeetingDetails', dataToSend, httpOptions);
  }
  public Users_GetSessionID(UserID: string): any {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      })
    };
    return this.http.get(this.url + 'MeraApp2/Users_GetSessionID/' + UserID, httpOptions);
  }


  getSTKInOutApprovalData(userID: any, userType: any, token: string, device: any, deviceType: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
      })
    };
    return this.http.get<any>(this.url + 'MeraApp2/MA_GetSTKInOutApprovalData/' + userType + '/' + userID, httpOptions);
  };

  setSTKInOutApprovalData(dataToSend: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + 'MeraApp2/MA_SetSTKInOutApprovalData', dataToSend, httpOptions);
  }

  searchOutletForOIFApproval(OutletUID: any, UserID: any, UserType: any, RequirementType: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.get<any>(this.url + 'MAATS/MA_SearchOutletForOIFApproval/' + OutletUID + '/' + UserID + '/' + UserType + '/' + RequirementType, httpOptions);
  }

  //MA_GetPCCreationSetting/{RDCode}
  getPCCreationSetting(RDCode: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.get<any>(this.url + 'MeraApp2/MA_GetPCCreationSetting/' + RDCode, httpOptions);
  }
  //[HttpPost("MA_SetPCCreationSetting")]
  setPCCreationSetting(dataToSend: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + 'MeraApp2/MA_SetPCCreationSetting', dataToSend, httpOptions);
  }
  //MA_GetActivePCLIst
  getActivePCLIst(RDCode: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.get<any>(this.url + 'MeraApp2/MA_GetActivePCLIst/' + RDCode, httpOptions);
  }

  pcDeviceTagging(rdCode: any, pcuid: any, deviceuid: any, token: string, device: any, deviceType: any, formName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': formName,
      })
    };
    return this.http.get<any>(this.url + 'MeraApp2/PCdevicetagging/' + rdCode + '/' + pcuid + '/' + deviceuid, httpOptions);
  }
  //
  getFSSAIComplianceDetail(dataToSend: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + 'MeraApp2/GetFSSAIComplianceDetail', dataToSend, httpOptions);
  }
  fSSAIApproval(dataToSend: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + 'MeraApp2/MA_FSSAIApproval', dataToSend, httpOptions);
  }
  getStkInOutDetails(trnNo: any, rdCode: any, token: string, device: any, deviceType: any, formName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': formName,
      })
    };
    return this.http.get<any>(this.url + 'MeraApp2/getstkinoutdetails/' + trnNo + '/' + rdCode, httpOptions);
  }

  soAuthorisation(userId: any, token: string, device: any, deviceType: any, formName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': formName,
      })
    };
    return this.http.get<any>(this.url + 'MeraApp2Reporting/MA_SOAuthorisation/' + userId, httpOptions);
  }

  asmAuthorisation(userId: any, token: string, device: any, deviceType: any, formName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': formName,
      })
    };
    return this.http.get<any>(this.url + 'MeraApp2Reporting/MA_ASMAuthorisation/' + userId, httpOptions);
  }

  bsmAuthorisation(userId: any, token: string, device: any, deviceType: any, formName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': formName,
      })
    };
    return this.http.get<any>(this.url + 'MeraApp2Reporting/MA_BSMauthorisation/' + userId, httpOptions);
  }

  getAutoCloserOutletForASM(yearNo: any, userId: any, token: string, device: any, deviceType: any, formName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': formName,
      })
    };
    return this.http.get<any>(this.url + 'MeraApp2/GetAutoCloserOutlet/' + yearNo + '/' + userId, httpOptions);
  }

  autoClosureReportApprovalForASM(dataToSend: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + 'MeraApp2/MA_RLASetAutoClosureReportApproval', dataToSend, httpOptions);
  }

  autoClosureReportClassForSO(dataToSend: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + 'MeraApp2/RLA_SetSOAutoClosureReport', dataToSend, httpOptions);
  }

  // gateMeetingDownload(filename: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'Access-Control-Allow-Origin': '*',
  //       "Authorization": "Bearer " + token,
  //       'DeviceID': device,
  //       'DeviceType': deviceType,
  //       'FormName': FormName,
  //     }),
  //   };
  //   return this.http.post<any>(this.url + 'MeraApp2/GateMeetingDownload/', filename, { observe: 'response', responseType: 'blob' as 'json', reportProgress: true, });
  // }

  getSOAuthorisationByID(yearNo: any, monthNo: any, rdCode: any, claimTypeID: any, token: string, device: any, deviceType: any, formName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': formName,
      })
    };
    return this.http.get<any>(this.url + 'MeraApp2Reporting/MA_GetSOAuthorisationByID/' + yearNo + '/' + monthNo + '/' + rdCode + '/' + claimTypeID, httpOptions);
  }
  getASMAuthorisationByID(yearNo: any, monthNo: any, rdCode: any, claimTypeID: any, token: string, device: any, deviceType: any, formName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': formName,
      })
    };
    return this.http.get<any>(this.url + 'MeraApp2Reporting/MA_GetASMAuthorisationByID/' + yearNo + '/' + monthNo + '/' + rdCode + '/' + claimTypeID, httpOptions);
  }
  getBSMAuthorisationByID(yearNo: any, monthNo: any, rdCode: any, claimTypeID: any, token: string, device: any, deviceType: any, formName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': formName,
      })
    };
    return this.http.get<any>(this.url + 'MeraApp2Reporting/MA_GetBSMAuthorisationByID/' + yearNo + '/' + monthNo + '/' + rdCode + '/' + claimTypeID, httpOptions);
  }

  rdCLAIMNew_SOUpdate(dataToSend: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + 'MeraApp2Reporting/RDCLAIMNew_SOUpdate', dataToSend, httpOptions);
  }

  rdCLAIMNew_ASMUpdate(dataToSend: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + 'MeraApp2Reporting/RDCLAIMNew_ASMUpdate', dataToSend, httpOptions);
  }

  rdCLAIMNew_BSMUpdate(dataToSend: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + 'MeraApp2Reporting/RDCLAIMNew_BSMUpdate', dataToSend, httpOptions);
  }
  PrimaryUploadStatus(dataToSend: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + 'MeraApp2/PrimaryUploadStatus', dataToSend, httpOptions);
  }
  getColdchainApprovalDetails(userID: any, userType: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.get<any>(this.url + 'MeraApp2/MA_GetColdchainApprovalDetails/' + userID + '/' + userType, httpOptions);
  }
  getCCAQuestionMasterDetails(dataToSend: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + 'MeraApp2/MA_GetCCAQuestionMasterDetails', dataToSend, httpOptions);
  }

  setApprovalDetailsForCCA(dataToSend: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + 'MeraApp2/CCA_SetApprovalDetails', dataToSend, httpOptions);
  }

  getMediaData(token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    }
    return this.http.get<any>(this.url + 'MeraApp2Reporting/MA_GetMediaData', httpOptions1);
  }

  getSubRDData(userID: any, userType: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.get<any>(this.url + 'MeraApp2/ma_Substockist_GetConfirmation/' + userID + '/' + userType, httpOptions);
  }

  rla_SetSubstockistApproval(dataToSend: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + 'MeraApp2/RLA_SetSubstockistApproval', dataToSend, httpOptions);
  }

  ma_RLA_GetOutletsForSUB(rdCode: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.get<any>(this.url + 'MeraApp2/ma_RLA_GetOutletsForSUB/' + rdCode, httpOptions);
  }

  setSubStkForApproval(dataToSend: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + 'MeraApp2/RLA_SetSubStkForApproval', dataToSend, httpOptions);
  }

  setSubStkForApprovalRejection(dataToSend: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + 'MeraApp2/RLA_SetSubStkForApprovalRejection', dataToSend, httpOptions);
  }

  getsubmarket(subid: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.get<any>(this.url + 'MeraApp2/ma_getsubmarket/' + subid, httpOptions);
  }

  MA_GetOutletsForApproval(subid: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.get<any>(this.url + 'MeraApp2/MA_GetOutletsForApproval/' + subid, httpOptions);
  }

  setOutletsForApproval(dataToSend: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + 'MeraApp2/RLA_SetOutletsForApproval', dataToSend, httpOptions);
  }

  MA_ERODS_GetClaimSummary(userID: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.get<any>(this.url + 'MeraApp2Reporting/MA_ERODS_GetClaimSummary/' + userID, httpOptions);
  };

  MA_ERODS_GetVoucherDetails(userID: any, rdCode: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.get<any>(this.url + 'MeraApp2Reporting/MA_ERODS_GetVoucherDetails/' + userID + '/' + rdCode, httpOptions);
  };

  ERODS_GetClaimSummaryDetails(ERODSNo: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.get<any>(this.url + 'MeraApp2Reporting/ERODS_GetClaimSummaryDetails/' + ERODSNo, httpOptions);
  };

  MA_ERODS_SetApproval(dataToSend: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + 'MeraApp2Reporting/MA_ERODS_SetApproval', dataToSend, httpOptions);
  }

  ATSAsset_GetDataForApproval(dataToSend: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + 'MeraApp2Reporting/ATSAsset_GetDataForApproval', dataToSend, httpOptions);
  }

  ATSAsset_SetApproval(dataToSend: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + 'MeraApp2Reporting/ATSAsset_SetApproval', dataToSend, httpOptions);
  }

  MA_GetOtherApplicationData(dataToSend: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + 'MeraApp2Reporting/MA_GetOtherApplicationData', dataToSend, httpOptions);
  }

  getDownloadFileFromS3Bucket(foldername: any, filename: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      }),
    };
    return this.http.get<any>(this.url + 'MeraNet/GetDownloadFileFromS3Bucket/' + foldername + '/' + filename, { observe: 'response', responseType: 'blob' as 'json', reportProgress: true, });
  }

  deleteFileFromS3Bucket(dataToSend: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + 'MeraNet/DeleteFileFromS3Bucket', dataToSend, httpOptions);
  }

  ATS_VisiEAgreeCheckLink(dataToSend: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    debugger;

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<OutputGetVisiAgrOTP>(this.url + 'AMMS/ATS_VisiEAgreeCheckLink', dataToSend, httpOptions);
  };

  ATS_VisiEAgreeGetAgreementdetails(token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    // debugger;

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.get<OutputAgreeDetails>(this.url + 'AMMS/ATS_VisiEAgreeGetAgreementdetails/', httpOptions);

  };



  ATS_VisiEAgreeSetOtp(dataToSend: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    debugger;

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + 'AMMS/ATS_VisiEAgreeSetOtp', dataToSend, httpOptions);
  };

  getDownloadPDFFileFromS3Bucket(foldername: any, filename: any): any {

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/pdf',
        'responseType': 'Blob'
      }),
    };

    window.open((this.url + 'MeraNet/GetDownloadFileFromS3Bucket/' + foldername + '/' + filename));// { observe: 'response', responseType: 'blob' as 'json'  , reportProgress: true, }););
  }

  ////MT AFH approval

  MTAFH_GetAccount(ID: any, Flag: string, UserID: string, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.get<any>(this.url + 'MeraApp2/MTAFH_GetAccount/' + ID + '/' + Flag + '/' + UserID, httpOptions);
  }

  MTAFH_ApproveAccount(dataToSend: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + 'MeraApp2/MTAFH_ApproveAccount/', dataToSend, httpOptions);
  }

  MTAFH_GetAccountForSalesGenie(ID: any, UserID: string, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.get<any>(this.url + 'MeraApp2/MTAFH_GetAccountForSalesGenie/' + ID + '/' + '/' + UserID, httpOptions);
  }




  MA_A2BAgreeGetOTP(obj: any): Observable<any> {
    return this.http.post<any>(this.url + 'amms/MA_A2BAgreeGetOTP', obj);
  }

  MA_A2BEAgreeCheckLink(dataToSend: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    debugger;

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<OutputGetVisiAgrOTP>(this.url + 'AMMS/MA_A2BEAgreeCheckLink', dataToSend, httpOptions);
  };


  MA_A2BEAgreeSetOtp(dataToSend: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    debugger;

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + 'AMMS/MA_A2BEAgreeSetOtp', dataToSend, httpOptions);
  };

  MA_A2BEAgreeGetAgreementdetails(token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    // debugger;

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.get<OutputAgreeDetails>(this.url + 'AMMS/MA_A2BEAgreeGetAgreementdetails/', httpOptions);

  };

  ma_atobcheckoutletcode(fromOutletCode: any, toOutletCode: any): Observable<any> {
    return this.http.get<OutputAgreeDetails>(this.url + 'maats/ma_atobcheckoutletcode/' + fromOutletCode + '/' + toOutletCode);
  }

  MA_GetSurveyDetailsForApproval(UserID: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.get<any>(this.url + 'meraapp2/MA_GetSurveyDetailsForApproval/' + UserID + '/', httpOptions);
  }

  MA_SetSurveyApproval(request: any, token: string, device: any, deviceType: any, FormName: any): Observable<any> {
    debugger
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
        'DeviceID': device,
        'DeviceType': deviceType,
        'FormName': FormName,
      })
    };
    return this.http.post<any>(this.url + 'meraapp2/MA_SetSurveyApproval', request, httpOptions);
  };

  ATS_AddEligibleOutlet(request: any, token: string): Observable<any> {
    debugger
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
      })
    };
    return this.http.post<any>(this.url + 'maats/ATS_AddEligibleOutlet', request, httpOptions);
  };

  ATS_AmmsSaveQRHistroryData(request: any, token: string): Observable<any> {
    debugger
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
      })
    };
    return this.http.post<any>(this.url + 'ATS/ATS_AmmsSaveQRHistroryData', request, httpOptions);
  };


  public GetVisicoolerList(ReqType: any): Observable<any> {
    debugger
    let httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',

      })
    }
    return this.http.get<any>(this.url + 'maats/GetVisicoolerList/' + ReqType, httpOptions1);
  }

  public ATS_GetOutletwiseDeploymentDetails(ReqType: any): Observable<any> {
    let httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',

      })
    }
    return this.http.get<any>(this.url + 'maats/ATS_GetOutletwiseDeploymentDetails/' + ReqType, httpOptions1);
  }

  public ATS_QRCodeHistory(ReqType: any, token: string): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
      })
    }
    return this.http.post<any>(this.url + 'maats/ATS_QRCodeHistory/', ReqType, httpOptions);
  }

  public ATS_ScanQRCodeDataSave(ReqType: any, token: string): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
      })
    }
    return this.http.post<any>(this.url + 'maats/ATS_ScanQRCodeDataSave/', ReqType, httpOptions);
  }

  public ATS_CheckEligibleOutletList(ReqType: any, token: string): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer " + token,
      })
    }
    return this.http.post<any>(this.url + 'maats/ATS_CheckEligibleOutletList', ReqType, httpOptions);
  }

}
