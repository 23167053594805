import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { DatePipe } from '@angular/common';
import { DataService } from '../../service/data.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InputPara } from 'src/app/models/kpi-models';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';
@Component({
  selector: 'app-search-outlet-complaint',
  templateUrl: './search-outlet-complaint.component.html',
  styleUrls: ['./search-outlet-complaint.component.css']
})
export class SearchOutletComplaintComponent implements OnInit {
  @ViewChild('openModalNonMeraNet', { static: true }) elRefopenModalNonMeraNet: ElementRef | undefined;
  input:InputPara=new InputPara();
  deviceType: any;
  device: any;
  isLongPress: boolean = false;
  isSelected: boolean = false;
  list: any;
  searchText = '';
  userType: any;
  userId: any;
  fieldName: any;
  fieldValue: any;
  token: any;

  formname:any;
  actiondesc:any;
  constructor(private activatedRoute: ActivatedRoute, private cs:CommonUtilitiesService,private router: Router, private headerService: HeaderService, private datePipe: DatePipe, private mds: MongoDataService,private ds:DataService, private modalService: NgbModal,) { }

  ngOnInit(): void {
    this.headerService.setTitle('Search Outlet');
    this.headerService.setActiveURl('/searchcomplaint');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.userId = localStorage.getItem("UserID");
    this.userType = localStorage.getItem("UserType");
    this.fieldName=localStorage.getItem("OriginalFieldName");
    this.fieldValue=localStorage.getItem("OriginalFieldValue");
    this.token = localStorage.getItem("TokenNo");

    this.formname = "searchcomplaint"
    this.actiondesc = "Search Outlet Complaint";
    this.cs.GetGeoLocation(this.userId, this.userType, this.formname, this.actiondesc, this.fieldName, this.fieldValue);
  }

  onLongPress() {
    this.isLongPress = true;
  }

  clickOnSelect() {
    this.isSelected = true;
  }

  rowClick(selected: any) {
    localStorage.setItem("outLetSelected", JSON.stringify(selected));
    this.router.navigate(['/home/outletdetailcomplaint']);
  }

  searchOutlet(OutletUID: string) {
    this.input.FieldName=this.fieldName;
    this.input.FieldValue=this.fieldValue;
    this.input.TagName=OutletUID;
    this.input.Datatype= "COMPLAINTS";
    this.input.UserID=this.userId;
    this.mds.getOutletDetailByRDWise(this.input, this.token, this.device, this.deviceType,'Search Outlet').subscribe(res => {
      this.list = res;
      debugger;
      if(this.list.length == 0){
        this.modalService.open(this.elRefopenModalNonMeraNet,
          {
            size: 'lg',
            backdrop: 'static',
            windowClass: '',
            keyboard: false,
            centered: true,
          });
      }
    }, error => { debugger;  this.modalService.open(this.elRefopenModalNonMeraNet,
      {
        size: 'lg',
        backdrop: 'static',
        windowClass: '',
        keyboard: false,
        centered: true,
      }); }
    );
  }

  modalClose() {
    this.modalService.dismissAll();
  }

  yes(){
    localStorage.setItem("outletSearchInCOMPLAINTS", JSON.stringify(this.searchText));
    this.modalService.dismissAll();
    this.router.navigate(['/home/nonMeranetOutletdetail']);
  }
}
