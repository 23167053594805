<div>

    <div class="row mt-4">
        <div class="col-12">
            <span class="text_14 text_black">Scan QR Code</span>
        </div>
    </div>

    <div class="row mt-2">
        <div class="col-12">
            <span class="text_12 text_black">{{qrCodeName}}</span>
        </div>
    </div>

    <hr>

    <div class="row">
        <div class="col-12">
            <span class="text_14 text_black">Outlet UID</span>
        </div>
    </div>

    <div class="row mt-2 mb-2">
        <div class="col-12">
            <input type="text" class="UID" [(ngModel)]="OutletUID" #UID placeholder="Enter Outlet UID"
                (keypress)="numberOnly($event)" minlength="7" maxlength="7" (ngModelChange)="changeOutletUId($event)" />
        </div>
    </div>

    <hr *ngIf="filteredList.length>0">

    <div class="row" *ngIf="filteredList.length>0">
        <div class="col-12 ">
            <span class="text_14 text_black">Outlet Name</span>
        </div>
    </div>

    <div class="row mt-2" *ngIf="filteredList.length>0">
        <div class="col-12 ">
            <span class="text_12 text_black">{{filteredList[0]?.Outletname}}</span>
        </div>
    </div>
    <hr>

    <div class="row" *ngIf="filteredList.length>0">
        <div class="col-12">
            <div class="form-group">
                <span class="text_14 text_black">Select visicooler Available</span>
                <select class=" form-select mt-1" [(ngModel)]="selectedVisicooler">
                    <option value="{{list?.itemName}}" *ngFor="let list of visicoolerList" class="custom-dropdown">{{list?.itemName}}</option>
                </select>
            </div>
        </div>
    </div>

    <hr *ngIf="filteredList.length>0">

    <div class="row mt-4" *ngIf="filteredList.length>0">
        <div class="col-3">
            <img [src]="coolerPhoto" alt="Image" *ngIf="coolerPhoto" class="img-fluid">
            <div class="Rectangle" *ngIf="!coolerPhoto">
                <span class="Image">Image</span>
            </div>
        </div>
        <div class="col-7 mt-3">
            <span class="text_14 text_black">Visicooler Photo</span>
        </div>
        <div class="col-2 mt-3">
            <img class="img-fluid" src="assets/camera.png" style="margin-top: -4px;" (click)="CoolerPhoto.click()" />
            <input #CoolerPhoto id="CoolerPhoto" type="file" accept="image/*" capture="environment"
                (change)="fileProgress($event,'CoolerPhoto')" style="display: none" />
        </div>
    </div>
    <hr *ngIf="filteredList.length>0">
    <div class="row mt-4" *ngIf="filteredList.length>0">
        <div class="col-3">
            <img [src]="outletPhoto" alt="Image" *ngIf="outletPhoto" class="img-fluid">
            <div class="Rectangle" *ngIf="!outletPhoto">
                <span class="Image">Image</span>
            </div>
        </div>
        <div class="col-7 mt-3">
            <span class="text_14 text_black">Outlet Exterior Photo</span>
        </div>
        <div class="col-2 mt-3">
            <img class="img-fluid" src="assets/camera.png" style="margin-top: -4px;" (click)="OutletPhoto.click()" />
            <input #OutletPhoto id="OutletPhoto" type="file" accept="image/*" capture="environment"
                (change)="fileProgress($event,'OutletPhoto')" style="display: none" />
        </div>
    </div>


    <br><br><br><br><br> <br><br><br>

    <div class="footer">
        <div class="row text-center mt-3">
            <div class="col-12" (click)="clickOnSubmit()">
                SUBMIT
            </div>
        </div>
    </div>
</div>