<div>
  <div class="row mt-4" *ngFor="let item of list; let i = index;">
    <div class="col-12 mt-3">
      <div class="form-check">
        <input type="checkbox" class="form-check-input" value={{item.rdcode}} id="Check{{i}}" [(ngModel)]="list[i].checked" (change)="selected = i; selected_Reason($event)" [checked]="selected === i">
        <label class="form-check-label" style="margin-top: .15rem;" for="Check{{i}}">
          <span class="rd_Name">{{item.rdname | titlecase}}</span>
          <span class="ml-1 rd_Code">{{item.rdcode }}</span>
        </label>
      </div>
    </div>
  <div class="col-12  hr_line">&nbsp;</div>
  </div>
</div>
<div class="footer">
  <div class="row text-center mt-3">
    <div class="col-12" (click)="rowClick()">
      APPROVE
    </div>
  </div>
</div>
<ng-template #openModalThankYou let-modal>
  <div class="modal-header">
    <h4 class="modal-title text_color_head text_20" id="modal-basic-title">
      <img class="img-fluid" src="assets/thanks_icon.png" />
      <span class="ml-3">Thank You</span>
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modalClose()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <span class="model_PopUp_Text">RD to RD Stock Transfer Approved</span>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <span class="model_PopUp_Text">Successfully</span>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12">
        <span class="model_PopUp_From_Text">From</span>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12">
        <span class="from_Rd">{{stockInoutDetails.rdName | titlecase}}</span>
        <span class="ml-1 from_RD_Code">{{stockInoutDetails.rdCode}}</span>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12">
        <span class="model_PopUp_From_Text">To</span>
      </div>
    </div>
    <div class="row mt-2 mb-2">
      <div class="col-12">
        <span class="from_Rd">{{toList.rdname | titlecase}}</span>
        <span class="ml-1 from_RD_Code">{{toList.rdcode}}</span>
      </div>
    </div>
  </div>
  <div class="modal-footer" (click)="okay()">
    OKAY
  </div>
</ng-template>
