<div class="customContainer">
    <div class="row mt-4">
        <div class="col-12 text-center">
            <button type="button" class="btn btn_ViewDPN_Agreement" (click)="openDPNAgreement()">VIEW DPN /
                AGREEMENT</button>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-12 text-center">
            <span class="text_FailOtp text_displayContent">
                Didn't receive an OTP?
                <span class="text_ResendOtp" (click)="resendOtp()">Resend OTP</span>
            </span>
        </div>
    </div>
    <hr>
    <div class="row mt-4">
        <div class="col-8">
            <span class="text_eAgreementStatus">DPN / E-AGREEMENT STATUS</span>
        </div>
        <div class="col-4 text_alignEnd">
            <img *ngIf="isAgreementStatus==false;else signedSuccess" class="refreshBtn float-right"
                src="assets\icons\refreshBtn.svg">
            <ng-template #signedSuccess>
                <span class="text_Signed text_displayContent">SIGNED
                    <img src="assets\icons\doubleTick.svg">
                </span>
            </ng-template>
        </div>
    </div>

    <div class="row mt-3">
        <div class="col-8" *ngIf="argDate==false;else argDateSuccess">
            <span class="text_viewStatus">Click on refresh button to view updated status</span>
        </div>
        <ng-template #argDateSuccess>
            <span class="col-8 text_viewStatus">Signed on dated: {{agrsubmiton}}</span>
        </ng-template>
    </div>

    <!-- <div class="justify-content-end bottomright">
            <button type="button" class="btn btn_cancle_Agreement float-right">Cancle</button>
            <button type="button" class="btn btn_save_Agreement float-right" (click)="saveDpnArgData()">Save</button>
    </div> -->
    <div class="footer row">
        <div class="col-6 p-0">
            <button type="button" class="btn btn_cancle_Agreement float-right w-100"
                (click)="backToOutletDetail()" [disabled]="isSubmitted">Cancel</button>
        </div>

        <div class="col-6 p-0">
            <button type="button" class="btn btn_save_Agreement float-right w-100"
                (click)="saveDpnArgData()" [disabled]="isSubmitted">Save</button>
        </div>
    </div>
</div>

<ng-template #openDPNARGModal let-modal>
    <div class="modal-header text-center">
        <h4 class="modal-title text_color_head text_20 text-center" id="modal-basic-title">

            <span class="ml-3 text-center Value_text">View DPN/Agreement</span>
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="modalClose()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body my-3">
        <div id="accordion">
            <div class="card">
                <div class="card-header" id="headingOne">
                    <h5 class="mb-0 collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
                        aria-controls="collapseOne">
                        <button class="btn collapsed" data-toggle="collapse" data-target="#collapseOne"
                            aria-expanded="true" aria-controls="collapseOne">
                            <span class="Value_headertext text-center"><img class="img-fluid mr-1" />Data Privacy
                                Note</span>
                        </button>
                        <i class="fa fa-sort-desc float-right" aria-hidden="true"></i>
                    </h5>
                </div>

                <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                    <div class="card-body">
                        <span class="data_Privacy_Text" innerHtml="{{DPNText}}"></span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 text-center mt-3">
                        <!-- <span class="Value_headertext text-center submissionDate">Signed on dated : {{dpnsubmiton }}</span>  -->
                    </div>
                </div>
                <!-- add -->
            </div>
            <div class="card">
                <div class="card-header" id="headingTwo">
                    <h5 class="mb-0 collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false"
                        aria-controls="collapseTwo">
                        <button class="btn collapsed" data-toggle="collapse" data-target="#collapseTwo"
                            aria-expanded="false" aria-controls="collapseTwo">
                            <span class="Value_headertext text-center">Agreement</span>
                        </button>
                        <i class="fa fa-sort-desc float-right" aria-hidden="true"></i>
                    </h5>
                </div>
                <div id="collapseTwo" class="collapse show" aria-labelledby="headingTwo" data-parent="#accordion">
                    <div class="card-body">
                        <span class="data_Privacy_Text" innerHtml="{{AgrText}}">
                        </span>
                    </div>
                </div>

                <div class="row my-3">
                    <div class="col-12 text-center">
                        <span class="Value_headertext text-center submissionDate">Signed on dated : {{agrsubmiton
                            }}</span>
                    </div>
                </div>
                <!-- add -->
            </div>
        </div>
    </div>
</ng-template>


<ng-template #openDPNModal let-modal>
    <div class="modal-header text-center">
        <h4 class="modal-title text_color_head text_20 text-center" id="modal-basic-title">

            <span class="ml-3 text-center Value_text">Data Privacy Note</span>
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="modalClose()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body ">
        <div id="accordion">
            <div class="card">
                <div class="card-header" id="headingOne">
                    <h5 class="mb-0 collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
                        aria-controls="collapseOne">
                        <button class="btn collapsed" data-toggle="collapse" data-target="#collapseOne"
                            aria-expanded="true" aria-controls="collapseOne">
                            <span class="Value_headertext text-center"><img class="img-fluid mr-1" />Data Privacy
                                Note</span>
                        </button>
                        <i class="fa fa-sort-desc float-right" aria-hidden="true"></i>
                    </h5>
                </div>

                <div id="collapseOne" class="collapse show cardBody" aria-labelledby="headingOne"
                    data-parent="#accordion">
                    <div class="card-body">
                        <span class="data_Privacy_Text" innerHtml="{{DPNText}}"></span>
                    </div>
                </div>
            </div>
        </div>
        <form [formGroup]="loginForm" autocomplete="off">

            <div class="row">
                <div class="col-12 text-center mt-3">
                    <label>
                        <input type="checkbox" name="options" autocomplete="off" class="Chkbtn" #checkbox
                            (change)="selected_Reason($event)" />
                        &nbsp;<span>I Accept the Data Privacy Note</span>
                    </label>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-left">
                    <label>
                        <span class="txtspan">Enter Verification Code</span>
                    </label>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <input id="otp" class="form-control" #OTP type="text" placeholder="Enter verification Code"
                            name="OTP" formControlName="otp" value="">
                    </div>
                </div>

            </div>
            <div class="row">
                <div class="col-12"><button class="btn btn-block btn_login" type="submit"
                        (click)="submitOTP(OTP.value,checkbox.checked)">
                        SUBMIT</button></div>
            </div>
        </form>
        <div class="row">
            <div class="col-12 text-center">
                <h6 [style]="IsError? 'color: red' : 'color:green'">{{errormsg}}</h6>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #openAGRModal let-modal>
    <div class="modal-header text-center">
        <h4 class="modal-title text_color_head text_20 text-center" id="modal-basic-title">

            <span class="ml-3 text-center Value_text">View Agreement</span>
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="modalClose()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div id="accordion">
            <div class="card">
                <div class="card-header" id="headingTwo">
                    <h5 class="mb-0 collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false"
                        aria-controls="collapseTwo">
                        <button class="btn collapsed" data-toggle="collapse" data-target="#collapseTwo"
                            aria-expanded="false" aria-controls="collapseTwo">
                            <span class="Value_headertext text-center">Agreement</span>
                        </button>
                        <i class="fa fa-sort-desc float-right" aria-hidden="true"></i>
                    </h5>
                </div>
                <div id="collapseTwo" class="collapse show cardBody" aria-labelledby="headingTwo"
                    data-parent="#accordion">
                    <div class="card-body">
                        <span class="data_Privacy_Text" innerHtml="{{AgrText}}">
                        </span>
                    </div>
                </div>
            </div>


            <form [formGroup]="loginFormagr" autocomplete="off">
                <div class="row">
                    <div class="col-12 text-center mt-3">
                        <label>
                            <input type="checkbox" name="options" autocomplete="off" class="Chkbtn" #agrcheckbox
                                (change)="selected_agrcheckbox($event)" />
                            &nbsp;<span>I Accept the Agreement</span>
                        </label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 text-left">
                        <label>
                            <span class="txtspan">Enter Verification Code</span>
                        </label>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <input id="agrOTP" class="form-control" #agrOTP type="text"
                                placeholder="Enter verification Code" name="agrOTP" formControlName="agrOTP" value="">

                        </div>
                    </div>

                </div>
                <div class="row">
                    <div class="col-12"><button class="btn btn-block btn_login" type="submit"
                            [disabled]="!loginFormagr.valid" (click)="submitagrOTP(agrOTP.value,agrcheckbox.checked)">
                            SUBMIT</button></div>
                </div>
            </form>
            <div class="row">
                <div class="col-12 text-center">
                    <h6 [style]="IsError? 'color: red' : 'color:green'">{{errormsg}}</h6>

                </div>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #openModalDPNSignedin let-modal id="test">
    <div class="modal-header justify-content-center">
        <h4 class="modal-title text_color_head text_20" id="modal-basic-title">
            <img class="img-fluid img_thumb text-center" src="assets\icons\thumb.svg" />
        </h4>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12 text-center">
                <span class="model_PopUp_Text text-center">Data privacy note has been signed in successfully</span>
            </div>
        </div>
    </div>
    <div class="modal-footer text-center justify-content-center" (click)="okay()">
        <button type="button" class="btn okBtn">OK</button>
    </div>
</ng-template>

<ng-template #openModalAGRSignedin let-modal>
    <div class="modal-header justify-content-center">
        <h4 class="modal-title text_color_head text_20" id="modal-basic-title">
            <img class="img-fluid img_thumb text-center" src="assets\icons\thumb.svg" />
        </h4>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12 text-center">
                <span class="model_PopUp_Text text-center">Agreement has been signed in succesfully</span>
            </div>
        </div>
    </div>
    <div class="modal-footer text-center justify-content-center" (click)="okay()">
        <button type="button" class="btn okBtn">OK</button>
    </div>
</ng-template>