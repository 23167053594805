import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { FormBuilder, Validators } from '@angular/forms';
import { DataService } from 'src/app/service/data.service';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-praise-details',
  templateUrl: './praise-details.component.html',
  styleUrls: ['./praise-details.component.css'],
})
export class PraiseDetailsComponent implements OnInit {
  PraiseName: any;
  deviceType: any;
  device: any;
  userType: string | null = '';
  userId: any;
  list: any;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  toEmail: any[] = [];
  toUserID: any[] = [];
  emailID: string | null = '';
  praiseForm = this.fb.group({
    toEmail: ['', [Validators.required]],
    description: ['', [Validators.required]],
  });
  isSend: boolean = false;
  userName: string | null = '';
  token: any;
  formname: any;
  actiondesc: any;
  fieldName: any;
  fieldValue: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private headerService: HeaderService,
    private mds: MongoDataService,
    private fb: FormBuilder,
    private ds: DataService,
    private cs:CommonUtilitiesService, 
  ) {}

  ngOnInit(): void {
    this.PraiseName = localStorage.getItem('PraiseName');
    this.headerService.setActiveURl('/PraiseDetails');
    this.headerService.setTitle('Appreciation');
    this.device = localStorage.getItem('Device');
    this.deviceType = localStorage.getItem('DeviceType');
    this.userType = localStorage.getItem('UserType');
    this.userName = localStorage.getItem('UserName');
    this.userId = localStorage.getItem('UserID');
    this.emailID = localStorage.getItem('EmailID');
    this.token = localStorage.getItem("TokenNo");

   
    this.fieldName=localStorage.getItem("FieldName");
    this.fieldValue=localStorage.getItem("FieldValue");
    this.formname = "PraiseDetails";
    this.actiondesc = "Praise Details";
    this.cs.GetGeoLocation(this.userId, this.userType, this.formname, this.actiondesc, this.fieldName, this.fieldValue);

    this.loadData();
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'userid',
      textField: 'emailid',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };
  }

  loadData(): void {
    this.mds.getUsers(this.userId,this.token,this.device, this.deviceType,'PraiseDetails').subscribe((res) => {
      this.list = res;
      this.dropdownList = this.list;
    });
  }

  onItemSelect(item: any) {
    this.toEmail.push(item.emailid);
    this.toUserID.push(item.userid);
  }
  onSelectAll(items: any) {
    items.forEach((element: { emailid: any }) => {
      this.toEmail.push(element.emailid);
    });
    items.forEach((element: { userid: any }) => {
      this.toUserID.push(element.userid);
    });
  }
  UnSelectAll(items: any) {
    this.toEmail.splice(0, this.toEmail.length);
    this.toUserID.splice(0, this.toUserID.length);
  }
  onItemDeSelect(item: any) {
    this.toEmail.forEach((element, index) => {
      if (element == item.emailid) {
        this.toEmail.splice(index, 1);
      }
    });
    this.toUserID.forEach((element, index) => {
      if (element == item.userid) {
        this.toUserID.splice(index, 1);
      }
    });
  }

  send() {
    this.isSend = true;
  }

  cancel(){
    this.isSend = false;
  }

  Ok(){
    const praiseFormData = this.praiseForm.value;
    const dataToSend = {
      PraisedBy: this.userName,
      PFromEmail: this.emailID,
      PToEmail: this.toEmail.toString(),
      PToUserID: this.toUserID.toString(),
      PDescription: praiseFormData.description,
      ImgName: 'Pr_'+ this.PraiseName + '.png'
    };

    console.log("dataToSend",dataToSend);
    this.ds.savePraise(dataToSend, this.token, this.device, this.deviceType,'PraiseDetails').subscribe(res => {
      console.log("savePraise", res);
      debugger;
      this.router.navigate(['/home/']);
    });
  }
}
