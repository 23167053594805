import { Component, OnInit, ViewChild,ElementRef } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ComplaintSave } from 'src/app/models/kpi-models';
import { EncryptionService } from '../../service/encryption.service';
import { DataService } from '../../service/data.service';
import { Enclass, ResultClass } from '../../models/encryption-class';
import { MatDialog } from '@angular/material/dialog';
import { FilterComponent } from '../filter/filter.component';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';
import { NullTemplateVisitor } from '@angular/compiler';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-add-complaint',
  templateUrl: './add-complaint.component.html',
  styleUrls: ['./add-complaint.component.css']
})
export class AddComplaintComponent implements OnInit {
  @ViewChild('openModalThankYou', { static: true }) elRefOpenModalThankYou: ElementRef | undefined;

  reason: any;
  deviceType: any;
  device: any;
  TotalPC: number=0;
  TotalVisitor: string="";
  LastUpdated: any;
  Title: string = "";
  msg: string = "";
  animal: string= "";
  TokenNo: any ;
  ClientID: any;
  slectedIndex: number = 0;
 // OutletList: ComplaintDetails;
  OutletCode: string = "";
  OutletCodeRD: string= "";
  OutletName: string= "";
  RDCode: string= "";
  ContactPerson: string= "";
  SerialNo: string= "";
  Address1: string= "";
  Address2: string= "";
  Address3: string= "";
  PinCode: string= "";
  PhoneNo: string= "";
  MobileNo: string= "";
  Visicooler: string= "";
  VisiMake: string= "";
  CurCondition: string= "";
  selectedReasons:string="";
  IssueDetails: string= "";
  LandMark: string= "";
  PrevCallNo: number= 0;
  State: string= "";
  Town: string= "";
  Visicooler2: string= "";
  OutletType: string= "";
  serviceID: number= 0;
  IssueReportedby: string|null = "";
  Reportingpersonname: string= "";
  ReportingcontactNo: string= "";
  QRCode: string= "";
  UserID: string|null = "";
  AssetCode: string= "";
  IsSaved: boolean=false;
  selected: any;
  CompSave: ComplaintSave = new ComplaintSave();
  //CompSave: ComplaintSave = new ComplaintSave();
  IsVisicoolerFoundClick: boolean = false;
  outLetSelected: any;
  ticketno :number=0;
  outletSearchInCOMPLAINTS: any;
  nonMeranetForm: any;
  token: any;
  formname:any;
  actiondesc:any;
  FieldName:any;
  FieldValue:any;
  userType: string | null = '';

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private headerService: HeaderService, 
     private mds: MongoDataService, private dialog: MatDialog,private ds:DataService,
      private modalService: NgbModal,private cs:CommonUtilitiesService) { }

  ngOnInit(): void {
    this.headerService.setTitle('Add Complaint');
    this.headerService.setActiveURl('/addcomplaint');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.TokenNo= localStorage.getItem("TokenNo");
    this.ClientID= localStorage.getItem("ClientID");
    this.UserID=localStorage.getItem("UserID");
    this.token = localStorage.getItem("TokenNo");
    this.selected = this.activatedRoute.snapshot.queryParams["selected"];
    this.userType = localStorage.getItem("UserType");
    this.FieldName = localStorage.getItem("FieldName");
    this.FieldValue = localStorage.getItem("FieldValue");
    this.formname="addcomplaint";
    this.actiondesc="Add Complaint"
    this.cs.GetGeoLocation(this.UserID,this.userType,this.formname,this.actiondesc,this.FieldName,this.FieldValue);
    if(this.selected == "fromNonMeranet"){
      this.outletSearchInCOMPLAINTS = JSON.parse(localStorage.getItem("outletSearchInCOMPLAINTS") || '{}');
      this.nonMeranetForm = JSON.parse(localStorage.getItem("nonMeranetForm") || '{}');
      console.log("outletSearchInCOMPLAINTS",this.outletSearchInCOMPLAINTS);
      console.log("nonMeranetForm",this.nonMeranetForm);
    }
    this.outLetSelected = JSON.parse(localStorage.getItem("outLetSelected") || '{}');
    this.mds.getReasons(this.token, this.device, this.deviceType,'Add Complaint').subscribe(res => {
      this.reason = res.filter((singleItem: { [x: string]: string; }) => singleItem['reasonType'].startsWith("Complaint"));
    });
  }
  selected_Reason(e: any) {
    if (e.target.checked) {
      this.selectedReasons = e.target.value;
    }
  }
  ClickSave(){
    debugger;
    if(this.selected == "fromNonMeranet"){
      this.CompSave.OutletCode = this.outletSearchInCOMPLAINTS;
    this.CompSave.OutletCodeRD = this.outletSearchInCOMPLAINTS;
    this.CompSave.OutletName = this.nonMeranetForm.outletDetails;
    this.CompSave.RDCode = "9999999";
    this.CompSave.ContactPerson = this.nonMeranetForm .contactPerson;
    this.CompSave.SerialNo = "0";
    this.CompSave.Address1 = this.nonMeranetForm .address;
    this.CompSave.Address2 = "";
    this.CompSave.Address3 = "";
    this.CompSave.PinCode = "0";
    this.CompSave.PhoneNo = this.nonMeranetForm.mobNo;
    this.CompSave.MobileNo = this.nonMeranetForm.mobNo;
    this.CompSave.Visicooler = this.nonMeranetForm.visicooler;
    this.CompSave.VisiMake = this.nonMeranetForm.vcMake;
    this.CompSave.CurCondition =this.CurCondition;
    this.CompSave.UserID = this.UserID;
    this.CompSave.IssueDetails = this.IssueDetails;
    this.CompSave.LandMark = "";
    this.CompSave.PrevCallNo = 0;
    this.CompSave.State = this.nonMeranetForm.state;
    this.CompSave.Town = this.nonMeranetForm.town;
    this.CompSave.Visicooler2 = "0";
    this.CompSave.OutletType = "0";
    this.CompSave.serviceID = 0;
    this.CompSave.IssueReportedby =this.UserID;
    this.CompSave.Reportingpersonname = this.Reportingpersonname;
    this.CompSave.ReportingcontactNo = this.ReportingcontactNo;
    this.CompSave.AssetCode = "";
    }else{
    this.CompSave.OutletCode = this.outLetSelected.outletcode.toString();
    this.CompSave.OutletCodeRD = this.outLetSelected.outletcoderd.substring(0, this.outLetSelected.outletcoderd.length-1);
    this.CompSave.OutletName = this.outLetSelected.outletname;
    this.CompSave.RDCode = this.outLetSelected.rdcode.toString();
    this.CompSave.ContactPerson = this.outLetSelected.contactperson==null?'':this.outLetSelected.contactperson;
    this.CompSave.SerialNo = "0";
    this.CompSave.Address1 = this.outLetSelected.address1;
    this.CompSave.Address2 = this.outLetSelected.address2==null?'': this.outLetSelected.address2;
    this.CompSave.Address3 = this.outLetSelected.address3 ==null?'':this.outLetSelected.address3 ;
    this.CompSave.PinCode = "0";
    this.CompSave.PhoneNo = this.outLetSelected.cellno;
    this.CompSave.MobileNo = this.outLetSelected.cellno;
    this.CompSave.Visicooler = this.outLetSelected.visicooler;
    this.CompSave.VisiMake = this.outLetSelected.visimake;
    this.CompSave.CurCondition =this.CurCondition;
    this.CompSave.UserID = this.UserID;
    this.CompSave.IssueDetails = this.IssueDetails;
    this.CompSave.LandMark = this.outLetSelected.landMark==null?'':this.outLetSelected.landMark;
    this.CompSave.PrevCallNo = this.outLetSelected.prevTicketNo==null?0:this.outLetSelected.prevTicketNo;
    this.CompSave.State = this.outLetSelected.stateName==null?'':this.outLetSelected.stateName;
    this.CompSave.Town = this.outLetSelected.town==null?'':this.outLetSelected.town;
    this.CompSave.Visicooler2 = "0";
    this.CompSave.OutletType = "0";
    this.CompSave.serviceID = 0;
    this.CompSave.IssueReportedby =this.UserID;
    this.CompSave.Reportingpersonname = this.Reportingpersonname;
    this.CompSave.ReportingcontactNo = this.ReportingcontactNo;
    this.CompSave.AssetCode = this.outLetSelected.assetCode?this.outLetSelected.assetCode:"";
  }

    console.log("CompSave",this.CompSave)

    this.ds.SaveVMSComplaint(this.CompSave,this.TokenNo,this.ClientID,this.device,this.deviceType,'Add Complaint').subscribe(data => {
      console.log("SaveVMSComplaint",data);
      this.msg = data[0].msg;//'Records Updated Sucessfully';
      this.IsSaved = true;
      //this.ticketno=data.msg;
        this.modalService.open(this.elRefOpenModalThankYou,
          {
            size: 'lg',
            backdrop: 'static',
            windowClass: '',
            keyboard: false,
            centered: true,
          });
    });
  }

  openModal(modal: ElementRef<any> | undefined) {
    // console.log('OpenModal called');
    this.modalService.open(modal,
      {
        size: 'lg',
        backdrop: 'static',
        windowClass: '',
        keyboard: false,
        centered: true,
      })
  }

  modalClose() {
    this.modalService.dismissAll();
    this.router.navigate(['/home/transactions']);
  }
  ChgMobileNo(mobileNo:any) { this.MobileNo = mobileNo.target.value; }
  ChgcontactPerSon(ContactPerson:any) { this.ContactPerson = ContactPerson.target.value; }
  Chgaddress1(add1:any) { this.Address1 = add1.target.value; }
  Chgaddress2(add2:any) { this.Address2 = add2.target.value; }
  Chgaddress3(add3:any) { this.Address3 = add3.target.value; }
  changecomplaint(complaint:any) { this.CurCondition = complaint.target.value; }
  changevisicooler(visi:any) { this.Visicooler = visi.target.value; }
  ChangeIssue(IDtls:any) { this.IssueDetails = IDtls.target.value; }
  ChglandMark(lmark:any) { this.LandMark = lmark.target.value; }
  ChgReportingPerson(Rperson:any) { this.Reportingpersonname = Rperson.target.value; }
  ChgReportingContact(Rcontact:any) { this.ReportingcontactNo = Rcontact.target.value; }

  cancel(){
    this.router.navigate(['/home/complaintlist']);
  }

  numberOnly(event: { which: any; keyCode: any; }): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

}

