<div>
    <div class="">
        <div class="row mt-4">
            <div class="col-12">
                <input class="fullWidth" type="text" name="" id="" placeholder="Search RD Name or RD Code"
                    [(ngModel)]="searchTerm">
            </div>
        </div>

        <!-- <div class="row mt-4">
            <div class="col-12">        
              <input class="fullWidth" type="text" placeholder="Search RD Name or RD Code" list="dynmicUserIds" (keyup)="getUserIdsFirstWay($event)" id="userIdFirstWay" (change)="SearchText($event)" autocomplete="off">
              <datalist id="dynmicUserIds">
                <option *ngFor="let item of userList1" [value]="item">{{item}}</option>
              </datalist>
            </div>
        </div> -->

        <div class="row mt-3">
            <div class="col-5 purpleColor text_rdList">
                RD List
            </div>
            <div class="col-7 text-right">
                <img class="img-fluid" src="assets/sec_dot.png" />
                <span class="achivement mr-2 purpleColor"> Achievement</span>
                <img class="img-fluid" src="assets/pri_dot.png" />
                <span class="target purpleColor"> Target</span>
            </div>
        </div>
    </div>
    <div class="bodyEle">
        <div class="" *ngFor="let item of filteredList" (click)="clickOnRdList(item)">
            <div class="row mt-4">
                <div class="col-12">
                    <span class="Head_text">{{item.rdname}}</span>
                    <span class="ml-1 Head_Val_txt">{{item.rdcode}}</span>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-3">
                    <div class="row">
                        <div class="col-12 text-center">
                            <h1 class="m-0 text_rdList_percentage purpleColor">{{item.percentage}}%</h1>
                        </div>
                    </div>
                </div>
                <div class="col-9">
                    <div class="row mt-2" >
                        <div class="col-12" >
                            <div class="progress rounded-pill">
                                <div class="progress-bar bg_sec" role="progressbar" [style.width.%]="item.ach"
                                    aria-valuenow="20" aria-valuemin="0" aria-valuemax="100"></div>
                                <div class="progress-bar bg_pri" role="progressbar" [style.width.%]="item.tgt"
                                    aria-valuenow="40" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-6 text-center">
                            <h2 class="m-0 vp_Bar_value">{{item.ach}}<span class="text_10 ml-1">{{item.unit}}</span></h2>
                        </div>
                        <div class="col-6 text-center">
                            <h2 class="m-0 vp_Bar_value">{{item.tgt}}<span class="text_10 ml-1">{{item.unit}}</span></h2>
                        </div>
                    </div>

                </div>
            </div>
            <hr>
        </div>
    </div>
</div>