import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { GetGrowKPI } from 'src/app/models/kpi-models';
import { ActivatedRoute, Router } from '@angular/router';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { DatePipe } from '@angular/common';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-approvals',
  templateUrl: './approvals.component.html',
  styleUrls: ['./approvals.component.css']
})
export class ApprovalsComponent implements OnInit {
  deviceType: any;
  device: any;
  userType: string | null = '';
  userId: any;
  list: any;
  myDate: any;
  curDate: any;


  IsATOBApproval: boolean = false;
  IsPulloutApproval: boolean = false;
  IsStkInOutApproval: boolean = false;
  ISRLAApproval: boolean = false;
  IsFRApproval: boolean = false;
  ISClaimApproval: boolean = false;
  ISFSSAIApproval: boolean = false;
  ISColdChainApproval: boolean = false;
  ISErodsApproval: boolean = false;
  ISPCTSIApproval: boolean = false;
  ISSubRD: boolean = false;
  ISMTAFHApproval: boolean = false;
  token: any;
  fieldName: any;
  fieldValue: any;
  mobileNo: any;
  Soterritory: any = "";
  ASMArea: any = "";
  BSM: any = "";
  userArea: any = "";
  formname:any;
  actiondesc:any;

  constructor(private activatedRoute: ActivatedRoute, private headerService: HeaderService, 
    private cs:CommonUtilitiesService,private router: Router, private mds: MongoDataService, private notifyMeService: NotifyMeService, private datePipe: DatePipe,) { }


  ngOnInit(): void {
    this.headerService.setTitle('Approvals');
    this.headerService.setActiveURl('/Approvals');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.userType = localStorage.getItem("UserType");
    this.userId = localStorage.getItem("UserID");
    this.token = localStorage.getItem("TokenNo");
    this.fieldName = localStorage.getItem("FieldName");
    this.fieldValue = localStorage.getItem("FieldValue");
    debugger
    this.mobileNo = localStorage.getItem("MobileNo");
    this.Soterritory = localStorage.getItem("Soterritory");
    this.ASMArea = localStorage.getItem("ASMArea");
    this.BSM = localStorage.getItem("RegionName");
    this.myDate = Date.now();
    this.curDate = this.datePipe.transform(this.myDate, 'dd');
    console.log("curDate", this.curDate)

    this.userType = localStorage.getItem("UserType");
    this.formname="Approvals";
    this.actiondesc="Approvals"
    this.cs.GetGeoLocation(this.userId,this.userType,this.formname,this.actiondesc,this.fieldName,this.fieldValue);
    this.onLoad();
  }

  onLoad() {
    this.mds.getUserDetails(this.userId, this.token, this.device, this.deviceType, 'Transactions').subscribe(res => {
      this.list = res;
      console.log("list", this.list)

      this.userType = this.list[0].userType.toUpperCase();
    },(error:any)=>{
      console.log(error)
    });
  }

  clickOnOIF(): void {
    if (this.list[0].userType == "RCCM" || this.list[0].userType == "ASM" || this.list[0].userType == "BSM") {
      this.router.navigate(['/home/OifApproval']);
    } else {
      this.notifyMeService.showWarning("You are not authorized for this transaction", 'Sales Genie');
    }
  }

  clickOnPullOutApproval() {
    if (this.list[0].userType == "ASM" || this.list[0].userType == "BSM") {
      this.router.navigate(['/home/pullOutApproval']);
    } else {
      this.notifyMeService.showWarning("You are not authorized for this transaction", 'Sales Genie');
    }
  }

  clickOnA2B() {
    //  
    if (this.list[0].userType == "RCCM" || this.list[0].userType == "SO") {
      this.router.navigate(['/home/a2b']);
    } else {
      this.notifyMeService.showWarning("You are not authorized for this transaction", 'Sales Genie');
    }
  }

  clickOnFacRec() {
    this.router.navigate(['/home/facialRecognitionApproval']);
  }

  clickOnstockInOut() {
    if (this.list[0].userType == "ASM" || this.list[0].userType == "BSM") {
      this.router.navigate(['/home/stockInoutApproval']);
    } else {
      this.notifyMeService.showWarning("You are not authorized for this transaction", 'Sales Genie');
    }
  }

  clickOnRLAAutoClosure() {
    if (this.curDate <= "24") {
      if (this.list[0].userType == "SO" || this.list[0].userType == "ASM") {
        this.router.navigate(['/home/rlaAutoClosureApproval']);
      } else {
        this.notifyMeService.showWarning("You are not authorized for this transaction", 'Sales Genie');
      }
    } else {
      this.notifyMeService.showWarning("Window for RLA closure process is closed", 'Sales Genie');
    }
  }

  clickOnEClaim() {
    if (this.list[0].userType == "SO" || this.list[0].userType == "ASM" || this.list[0].userType == "BSM") {
      this.router.navigate(['/home/claimApproval']);
    } else {
      this.notifyMeService.showWarning("You are not authorized for this transaction", 'Sales Genie');
    }
  }

  clickOnErods() {
    if (this.list[0].userType == "SO" || this.list[0].userType == "ASM" || this.list[0].userType == "BSM") {
      this.router.navigate(['/home/erodsApproval']);
    } else {
      this.notifyMeService.showWarning("You are not authorized for this transaction", 'Sales Genie');
    }
  }

  clickOnFSSAI() {
    if (this.list[0].userType == "SO" || this.list[0].userType == "ASM" || this.list[0].userType == "BCM") {
      this.router.navigate(['/home/fssaiApproval']);
    } else {
      this.notifyMeService.showWarning("You are not authorized for this transaction", 'Sales Genie');
    }
  }

  clickOnPCTSI() {
    if (this.list[0].userType == "ASM" || this.list[0].userType == "BSM") {
      this.router.navigate(['/home/pc/tsi']);
    } else {
      this.notifyMeService.showWarning("You are not authorized for this transaction", 'Sales Genie');
    }
  }

  clickOnColdChainAudit() {
    if (this.list[0].userType == "SO" || this.list[0].userType == "ASM" || this.list[0].userType == "RSOM" || this.list[0].userType == "RH") {
      this.router.navigate(['/home/coldChainAudit']);
    } else {
      this.notifyMeService.showWarning("You are not authorized for this transaction", 'Sales Genie');
    }
  }

  clickOnSubRD() {
    if (this.list[0].userType == "SO" || this.list[0].userType == "ASM" || this.list[0].userType == "RSOM" || this.list[0].userType == "RH") {
      this.router.navigate(['/home/subRdApproval']);
    } else {
      this.notifyMeService.showWarning("You are not authorized for this transaction", 'Sales Genie');
    }
  }

  clickOnAssetApproval() {
    if (this.list[0].userType == "SO" || this.list[0].userType == "ASM" || this.list[0].userType == "BSM") {
      this.router.navigate(['/home/assetApproval', '0', '0', '0', this.userId]);
    } else {
      this.notifyMeService.showWarning("You are not authorized for this transaction", 'Sales Genie');
    }
  }
  clickOnMTAFH() {
    if (this.list[0].userType == "ASM" || this.list[0].userType == "FIRSTAPPROVAL" || this.list[0].userType == "SECONDAPPROVAL" || this.list[0].userType == "THIRDAPPROVAL") {
      this.router.navigate(['/home/mtafhapproval', '0', this.list[0].userType, this.userId]);
    } else {
      this.notifyMeService.showWarning("You are not authorized for this transaction", 'Sales Genie');
    }

  }

  clickOnRDAudit() {
    debugger
    if (this.list[0].userType.toLowerCase() == "so") {
      this.userArea = this.Soterritory;
    }

    if (this.list[0].userType.toLowerCase() == "asm") {
      this.userArea = this.ASMArea;
    }
    if (this.list[0].userType.toLowerCase() == "bsm") {
      this.userArea = this.BSM;
    };

    debugger
    if (this.list[0].userType.toLowerCase() == "so" || this.list[0].userType.toLowerCase() == "asm" || this.list[0].userType.toLowerCase() == "bsm"){
      if ((this.mds.url == "https://salespulseapi.dev.cilsales.net/api/") || (this.mds.url =="https://test.cilsales.net/api/'")) {
        window.open(
          `https://devrdaudit.cilsales.net/ACM-dashboard/acm-home/${this.userId.split("%")[0]}`,
          '_blank'
        );
        //  window.open(
        //   ` http://localhost:56939/ACM-dashboard/acm-home/${this.userId.split("%")[0]}`,
        //   '_blank'
        // );
      } 
      else {
        window.open(
          `https://rdaudit.cilsales.net/ACM-dashboard/acm-home/${this.userId.split("%")[0]}`,
          '_blank'
        );
      }
    }else{
      this.notifyMeService.showWarning("You are not authorized for this transaction", 'Sales Genie');
    }
  }

  clickOnUniverseMapping() {
      this.router.navigate(['/home/surveyApproval']);
  }

  clickOnOutletNameChangeApproval(){
debugger
    if (this.list[0].userType == "RCCM" || this.list[0].userType == "BCM" || this.list[0].userType == "RSOM" || this.list[0].userType == "RCMM"  || this.list[0].userType == "ACM") {
      this.router.navigate(['/home/OutletNameChangeApprovalList'])
    } else {
      this.notifyMeService.showWarning("You are not authorized for this transaction", 'Sales Genie');
    }

  }

  MenuUserControl() {
    this.IsPulloutApproval = this.IsATOBApproval = this.ISClaimApproval = this.ISErodsApproval = this.IsFRApproval = this.ISPCTSIApproval = this.ISFSSAIApproval = this.ISColdChainApproval = this.ISRLAApproval = this.IsStkInOutApproval = this.ISSubRD = this.ISMTAFHApproval = false;

    if (this.userType == 'ASM') {
      this.ISPCTSIApproval = this.ISClaimApproval = this.ISFSSAIApproval = this.ISRLAApproval = this.IsFRApproval = this.ISColdChainApproval = this.ISErodsApproval = this.ISSubRD = this.ISMTAFHApproval = true;

    }
    else if (this.userType == 'BSM') {
      this.ISPCTSIApproval = this.ISClaimApproval = this.ISFSSAIApproval = this.ISRLAApproval = this.IsFRApproval = this.ISColdChainApproval = this.ISErodsApproval = this.ISFSSAIApproval = true;
    }
    else if (this.userType == 'RCCM') {
      this.IsATOBApproval = true;
    }
    else if (this.userType == 'BCM') {
      this.ISClaimApproval = this.ISFSSAIApproval = this.ISErodsApproval = true;
    }

    else if (this.userType == 'RSOM') {
      this.ISColdChainApproval = this.ISSubRD = true;
    }
    else if (this.userType == 'SO') {
      this.ISColdChainApproval = this.ISSubRD = true;
    }
    else if ((this.userType == 'FIRSTAPPROVAL') || (this.userType == 'SECONDAPPROVAL') || (this.userType == 'THIRDAPPROVAL')) {
      this.ISMTAFHApproval = true;
    }
  }

}
