
<div class="row mt-1">
  <div class="col-12 bgcustom" >

    <mat-tab-group mat-stretch-tabs  [disablePagination]="false" (selectedTabChange)="onOIFTabChanged($event);">

  <mat-tab label="Expansion" class="tablabel">
        <div>
          <div class="row mt-3 ml-2">
            <div class="col-12">
            <span class="Requirementtype">Expansion ({{Expansion}})</span>
            </div>
          
          </div>

          <div class="row mt-4">
            <!-- <div class="col-9">
              <input class="fullWidth" type="text" name="" id="" #OutletUID placeholder="Search Outlet" [(ngModel)]="searchText" >

            </div>
            <div class="col-3 pl-0">
              <button class="btn btn_Search" (click)="searchOutlet(OutletUID.value,Expansion)" >Search</button>

            </div> -->
            <div class="col-9">
              <input class="textBox" type="text" name="" id="" #OutletUID placeholder="Search Outlet by Outlet Code" [(ngModel)]="searchTerm">
            </div>
            <div class="col-3 pl-0">
              <button class="btn btn_Search" (click)="searchOutlet(OutletUID.value)">Search</button>
            </div>
          </div>
          <div class="row mt-4" *ngFor="let item of filteredList; let i = index;">
            <div class="myDiv" [class.myClass]="isSelected" (click)="rowClick(item.id)">
               <div class="container-fluid" >
                <div>
                  <div class="row">
                    <div class="col-12 mt-2">
                      <span class="text_14 text_black">{{item.outletName | titlecase}}</span>
                      <span class="ml-1 text_14 text_black" style="opacity: 40%;">{{item.outletCode}}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <span style="opacity: 40%;" class="text_12 text_black">{{item.rdName | titlecase}}<span
                          class="ml-1 text_12 text_black">{{item.rdCode}}</span></span>
                    </div>
                  </div>
                  <hr>
                  <div class="row">
                    <div class="col-6 text-left">
                      <span style="opacity: 40%;" class="text_12 text_black">Suggested Visicooler</span>
                    </div>
                    <div class="col-6 text-right">
                      <span class="text_12 text_black">{{item.suggVisicooler}}</span>
                    </div>

                  </div>
                  <div class="row mt-1">
                    <div class="col-6 text-left">
                      <span class="text_14 text_black">Avg L3M Choc Sale</span>
                    </div>
                    <div class="col-6 text-right">
                      <span class="text_14 text_black">{{item.ltma}}</span>
                    </div>


                  </div>
                  <div class="row mt-1">
                    <div class="col-6 text-left">
                      <span class="text_14 text_black">Priority Level</span>
                    </div>
                    <div class="col-6 text-right">
                      <span class="text_14 text_black">{{item.priorityLevel}}</span>
                    </div>

                  </div>
                  <div *ngIf="item.remark.length>0">
                    <div class="row">
                      <div class="col-12 textdiv text-danger">
                        <span class="text_14"> {{item.remark}} </span>
                      </div>
                    </div>
                  </div>
                  <div class="row" *ngIf="item.soApprovalStatus!='PENDING'|| item.asmApprovalStatus!='PENDING'">
                    <div class="col-4 p-0" >
                      <div class="soapprovaldiv" [style.visibility]="item.soApprovalStatus=='PENDING' ? 'hidden' : 'visible'">

                        <img src="../../../assets/icons/ATSIcons/soapproval.png" width="12%" height="60%" style="padding-left: 5px;"/>&nbsp;<span class="textgreen textdiv">SO Approved</span>
                      </div>
                    </div>
                    <div class="col-4 p-0" *ngIf="item.asmApprovalStatus!='PENDING' ">
                      <div class="asmapprovaldiv" [style.visibility]="item.asmApprovalStatus=='PENDING' ? 'hidden' : 'visible'">


                        <img src="../../../assets/icons/ATSIcons/asmapproval.png" width="12%" height="60%" />&nbsp;<span class="text-info textdiv">ASM Approved</span>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="footer" *ngIf="isSelected && userType?.toUpperCase() =='SO' ">
            <!-- <div class="footer" *ngIf="isSelected"> -->
            <div class="row text-center mt-3">
              <div class="col-12" >
                APPROVE
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Additional"  class="tablabel">
        <div>
          <div class="row mt-3 ml-2">
            <span class="Requirementtype">Additional ({{Additional}})</span>
          </div>
          <div class="row" style="margin-top: 2rem;">
            <!-- <div class="col-9">
              <input class="fullWidth" type="text" name="" id="" #OutletUID placeholder="Search Outlet"  [(ngModel)]="searchText" >

            </div>
            <div class="col-3 pl-0">
              <button class="btn btn_Search"(click)="searchOutlet(OutletUID.value)" >Search</button>

            </div> -->
            <div class="col-9">
              <input class="textBox" type="text" name="" id="" #OutletUID placeholder="Search Outlet by Outlet Code" [(ngModel)]="searchTerm">
            </div>
            <div class="col-3 pl-0">
              <button class="btn btn_Search" (click)="searchOutlet(OutletUID.value)">Search</button>
            </div>
          </div>
          <div class="row mt-4" *ngFor="let item of filteredList; let i = index;">
            <div class="myDiv" [class.myClass]="isSelected" (click)="rowClick(item.id)">
               <div class="container-fluid" >
                <div>
                  <div class="row">
                    <div class="col-12 mt-2">
                      <span class="text_14 text_black">{{item.outletName | titlecase}}</span><span class="ml-1 text_14 text_black"
                        style="opacity: 40%;">{{item.outletCode}}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <span style="opacity: 40%;" class="text_12 text_black">{{item.rdName | titlecase}}<span
                          class="ml-1 text_12 text_black">{{item.rdCode}}</span></span>
                    </div>
                  </div>
                  <hr>
                  <div class="row">
                    <div class="col-6 text-left">
                      <span style="opacity: 40%;" class="text_12 text_black">Suggested Visicooler</span>
                    </div>
                    <div class="col-6 text-right">
                      <span class="text_12 text_black">{{item.suggVisicooler}}</span>
                    </div>

                  </div>
                  <div class="row mt-1">
                    <div class="col-6 text-left">
                      <span class="text_14 text_black">Avg L3M Choc Sale</span>
                    </div>
                    <div class="col-6 text-right">
                      <span class="text_14 text_black">{{item.ltma}}</span>
                    </div>


                  </div>
                  <div *ngIf="item.remark.length>0">
                    <div class="row">
                      <div class="col-12 textdiv text-danger">
                        <span class="text_14"> {{item.remark}} </span>
                      </div>
                    </div>
                  </div>
                  <div class="row" *ngIf="item.soApprovalStatus!='PENDING'|| item.asmApprovalStatus!='PENDING'">
                    <div class="col-4 p-0" >
                      <div class="soapprovaldiv" [style.visibility]="item.soApprovalStatus=='PENDING' ? 'hidden' : 'visible'">

                        <img src="../../../assets/icons/ATSIcons/soapproval.png" width="12%" height="60%" style="padding-left: 5px;"/>&nbsp;<span class="textgreen textdiv">SO Approved</span>
                      </div>
                    </div>
                    <div class="col-4 p-0" *ngIf="item.asmApprovalStatus!='PENDING' ">
                      <div class="asmapprovaldiv" [style.visibility]="item.asmApprovalStatus=='PENDING' ? 'hidden' : 'visible'">


                        <img src="../../../assets/icons/ATSIcons/asmapproval.png" width="12%" height="60%" />&nbsp;<span class="text-info textdiv">ASM Approved</span>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="footer" *ngIf="isSelected && userType?.toUpperCase() =='SO' ">
            <!-- <div class="footer" *ngIf="isSelected"> -->
            <div class="row text-center mt-3">
              <div class="col-12" >
                APPROVE
              </div>
            </div>
          </div>
        </div>

      </mat-tab>
      <mat-tab label="Replacement"  class="tablabel">
        <div>
          <div class="row mt-3 ml-2">
            <span class="Requirementtype">Replacement ({{Replacement}})</span>
          </div>
          <div class="row" style="margin-top: 2rem;">
            <!-- <div class="col-9">
              <input class="fullWidth" type="text" name="" id="" #OutletUID placeholder="Search Outlet" [(ngModel)]="searchText">

            </div>
            <div class="col-3 pl-0">
              <button class="btn btn_Search" (click)="searchOutlet(OutletUID.value)" >Search</button>

            </div> -->
            <div class="col-9">
              <input class="textBox" type="text" name="" id="" #OutletUID placeholder="Search Outlet by Outlet Code" [(ngModel)]="searchTerm">
            </div>
            <div class="col-3 pl-0">
              <button class="btn btn_Search" (click)="searchOutlet(OutletUID.value)">Search</button>
            </div>
          </div>
          <div class="row mt-4" *ngFor="let item of filteredList; let i = index;" >
            <div class="myDiv" [class.myClass]="isSelected" (click)="rowClick(item.id)">
               <div class="container-fluid" >
                <div>
                  <div class="row">
                    <div class="col-12 mt-2">
                      <span class="text_14 text_black">{{item.outletName | titlecase}}</span><span class="ml-1 text_14 text_black"
                        style="opacity: 40%;">{{item.outletCode}}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <span style="opacity: 40%;" class="text_12 text_black">{{item.rdName | titlecase}}<span
                          class="ml-1 text_12 text_black">{{item.rdCode}}</span></span>
                    </div>
                  </div>
                  <hr>
                  <div class="row">
                    <div class="col-6 text-left">
                      <span style="opacity: 40%;" class="text_12 text_black">Suggested Visicooler</span>
                    </div>
                    <div class="col-6 text-right">
                      <span class="text_12 text_black">{{item.suggVisicooler}}</span>
                    </div>

                  </div>
                  <div class="row mt-1">
                    <div class="col-6 text-left">
                      <span class="text_14 text_black">Avg L3M Choc Sale</span>
                    </div>
                    <div class="col-6 text-right">
                      <span class="text_14 text_black">{{item.ltma}}</span>
                    </div>


                  </div>
                  <div *ngIf="item.remark.length>0">
                    <div class="row">
                      <div class="col-12 textdiv text-danger">
                        <span class="text_14"> {{item.remark}} </span>
                      </div>
                    </div>
                  </div>
                  <div class="row" *ngIf="item.soApprovalStatus!='PENDING'|| item.asmApprovalStatus!='PENDING'">
                    <div class="col-4 p-0" >
                      <div class="soapprovaldiv" [style.visibility]="item.soApprovalStatus=='PENDING' ? 'hidden' : 'visible'">

                        <img src="../../../assets/icons/ATSIcons/soapproval.png" width="12%" height="60%" style="padding-left: 5px;"/>&nbsp;<span class="textgreen textdiv">SO Approved</span>
                      </div>
                    </div>
                    <div class="col-4 p-0" *ngIf="item.asmApprovalStatus!='PENDING' ">
                      <div class="asmapprovaldiv" [style.visibility]="item.asmApprovalStatus=='PENDING' ? 'hidden' : 'visible'">


                        <img src="../../../assets/icons/ATSIcons/asmapproval.png" width="12%" height="60%" />&nbsp;<span class="text-info textdiv">ASM Approved</span>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="footer" *ngIf="isSelected && userType?.toUpperCase() =='SO' ">
            <!-- <div class="footer" *ngIf="isSelected"> -->
            <div class="row text-center mt-3">
              <div class="col-12" >
                APPROVE
              </div>
            </div>
          </div>
        </div>

      </mat-tab>
      <mat-tab label="Upgrade"  class="tablabel">
        <div>
          <div class="row mt-3 ml-2">
            <span class="Requirementtype">Upgrade ({{Upgrade}})</span>
          </div>
          <div class="row" style="margin-top: 2rem;">
            <!-- <div class="col-9">
              <input class="fullWidth" type="text" name="" id="" #OutletUID placeholder="Search Outlet" [(ngModel)]="searchText" >

            </div>
            <div class="col-3 pl-0">
              <button class="btn btn_Search" (click)="searchOutlet(OutletUID.value)">Search</button>

            </div> -->
            <div class="col-9">
              <input class="textBox" type="text" name="" id="" #OutletUID placeholder="Search Outlet by Outlet Code" [(ngModel)]="searchTerm">
            </div>
            <div class="col-3 pl-0">
              <button class="btn btn_Search" (click)="searchOutlet(OutletUID.value)">Search</button>
            </div>
          </div>
          <div class="row mt-4" *ngFor="let item of filteredList; let i = index;">
            <div class="myDiv" [class.myClass]="isSelected" (click)="rowClick(item.id)">
               <div class="container-fluid" >
                <div>
                  <div class="row">
                    <div class="col-12 mt-2">
                      <span class="text_14 text_black">{{item.outletName | titlecase}}</span><span class="ml-1 text_14 text_black"
                        style="opacity: 40%;">{{item.outletCode}}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <span style="opacity: 40%;" class="text_12 text_black">{{item.rdName | titlecase}}<span
                          class="ml-1 text_12 text_black">{{item.rdCode}}</span></span>
                    </div>
                  </div>
                  <hr>
                  <div class="row">
                    <div class="col-6 text-left">
                      <span style="opacity: 40%;" class="text_12 text_black">Suggested Visicooler</span>
                    </div>
                    <div class="col-6 text-right">
                      <span class="text_12 text_black">{{item.suggVisicooler}}</span>
                    </div>

                  </div>
                  <div class="row mt-1">
                    <div class="col-6 text-left">
                      <span class="text_14 text_black">Avg L3M Choc Sale</span>
                    </div>
                    <div class="col-6 text-right">
                      <span class="text_14 text_black">{{item.ltma}}</span>
                    </div>


                  </div>
                  <div *ngIf="item.remark.length>0">
                    <div class="row">
                      <div class="col-12 textdiv text-danger">
                        <span class="text_14"> {{item.remark}} </span>
                      </div>
                    </div>
                  </div>
                  <div class="row" *ngIf="item.soApprovalStatus!='PENDING'|| item.asmApprovalStatus!='PENDING'">
                    <div class="col-4 p-0" >
                      <div class="soapprovaldiv" [style.visibility]="item.soApprovalStatus=='PENDING' ? 'hidden' : 'visible'">

                        <img src="../../../assets/icons/ATSIcons/soapproval.png" width="12%" height="60%" style="padding-left: 5px;"/>&nbsp;<span class="textgreen textdiv">SO Approved</span>
                      </div>
                    </div>
                    <div class="col-4 p-0" *ngIf="item.asmApprovalStatus!='PENDING' ">
                      <div class="asmapprovaldiv" [style.visibility]="item.asmApprovalStatus=='PENDING' ? 'hidden' : 'visible'">


                        <img src="../../../assets/icons/ATSIcons/asmapproval.png" width="12%" height="60%" />&nbsp;<span class="text-info textdiv">ASM Approved</span>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="footer" *ngIf="isSelected && userType?.toUpperCase() =='SO' ">
            <!-- <div class="footer" *ngIf="isSelected"> -->
            <div class="row text-center mt-3">
              <div class="col-12" >
                APPROVE
              </div>
            </div>
          </div>
        </div>

      </mat-tab>
    </mat-tab-group>
  </div>

</div>
 <!-- <div class="scrollmenu">
  <div class="horizontal-scroll-wrapper squares">
    <ul class="list-inline Box">
      <li class="list-inline-item">
        &nbsp;<label>
          <input type="checkbox" name="options" style="display:none;" autocomplete="off" class="Type" />
          <button  (click)="showDetails('EXPANSION')">EXPANSION ({{Expansion}})</button>

        </label>
      </li>
      <li class="list-inline-item">
        <label>
          <input type="checkbox" name="options" style="display:none;" autocomplete="off"  class="Type" >
          <button  (click)="showDetails('ADDITIONAL')">ADDITIONAL ({{Additional}})</button>

        </label>
      </li>
      <li class="list-inline-item">
        <label>
          <input type="checkbox" name="options" style="display:none;" autocomplete="off"  class="Type" >
          <button (click)="showDetails('REPLACEMENT')">REPLACEMENT ({{Replacement}})</button>

        </label>
      </li>
      <li class="list-inline-item">
        <label>
          <input type="checkbox" name="options" style="display:none;" autocomplete="off"  class="Type" >
          <button (click)="showDetails('UPGRADE')">UPGRADE ({{Upgrade}})</button>

        </label>
      </li>
    </ul>
  </div>
  <div style="height: 50px;  padding-top: 3px;  margin-left: 1rem;">
    &nbsp;<span class="tablabel">{{RequirementType}} ({{selectedApprovalCount}})</span>

  </div>
</div>  -->

<!-- <div class="row mt-4">
  <div class="col-12">
    <input class="fullWidth" type="text" name="" id="" placeholder="  Search Outlet">
  </div>
</div> -->
<!-- <div class="row mt-4" *ngFor="let item of OIFApprovalList; let i = index;">
  <div class="myDiv" [class.myClass]="isSelected" (click)="rowClick(item.id)">
     <div class="container-fluid" >
      <div long-press (onLongPress)="onLongPress()">
        <div class="row">
          <div class="col-10 mt-2">
            <span class="text_14 text_black">{{item.outletName}}</span><span class="ml-1 text_14 text_black"
              style="opacity: 40%;">{{item.outletCode}}</span>
          </div>
          <div class="col-2 text-right mt-2" *ngIf="isLongPress">
            <input type="checkbox" id="Check{{i}}" [(ngModel)]="list[i].checked" [checked]="selected === i"
              (change)="selected = i; clickOnSelect($event, i)">
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <span style="opacity: 40%;" class="text_12 text_black">{{item.rdName}}<span
                class="ml-1 text_12 text_black">{{item.rdCode}}</span></span>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-6 text-left">
            <span style="opacity: 40%;" class="text_12 text_black">Suggested Visicooler</span>
          </div>
          <div class="col-6 text-right">
            <span class="text_12 text_black">{{item.suggVisicooler}}</span>
          </div>

        </div>
        <div class="row mt-1">
          <div class="col-6 text-left">
            <span class="text_14 text_black">L3MA</span>
          </div>
          <div class="col-6 text-right">
            <span class="text_14 text_black">{{item.ltma}}</span>
          </div>


        </div>
        <div *ngIf="item.remark.length>0">
          <div class="row">
            <div class="col-12 textdiv text-danger">
              <span class="text_14"> {{item.remark}} </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-4 p-0">
            <div class="soapprovaldiv" [style.visibility]="item.soApprovalStatus=='PENDING' ? 'hidden' : 'visible'">
              <img src="../../../assets/icons/ATSIcons/soapproval.png" width="12%" height="60%" />&nbsp;<span class="textgreen textdiv">SO Approved</span>
            </div>
          </div>
          <div class="col-4 p-0">
            <div class="asmapprovaldiv" [style.visibility]="item.asmApprovalStatus=='PENDING' ? 'hidden' : 'visible'">

              <img src="../../../assets/icons/ATSIcons/asmapproval.png" width="12%" height="60%" />&nbsp;<span class="text-info textdiv">ASM Approved</span>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
<div class="footer" *ngIf="isSelected && userType?.toUpperCase() =='SO' ">
  <!-- <div class="footer" *ngIf="isSelected">
  <div class="row text-center mt-3">
    <div class="col-12" >
      APPROVE
    </div>
  </div>
</div> -->
