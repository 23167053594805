import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { DataService } from 'src/app/service/data.service';
import { InputPara } from 'src/app/models/kpi-models';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-rla-auto-closure-approval',
  templateUrl: './rla-auto-closure-approval.component.html',
  styleUrls: ['./rla-auto-closure-approval.component.css']
})
export class RlaAutoClosureApprovalComponent implements OnInit {
  deviceType: any;
  device: any;
  userType: string | null = '';
  userId: any;
  list: any;
  private _searchText = '';
  filteredList: any;
  fieldName: any;
  fieldValue: any;
  input:InputPara=new InputPara();
  currYearNo: any;
  currmonthno: any;
  token: any;
  formname:any;
  actiondesc:any;

  constructor(private activatedRoute: ActivatedRoute,private cs:CommonUtilitiesService, private router: Router, private headerService: HeaderService, private mds: MongoDataService, private notifyMeService:NotifyMeService, private ds: DataService,) { }

  ngOnInit(): void {
    this.headerService.setTitle('RLA Auto Closure');
    this.headerService.setActiveURl('/rlaAutoClosureApproval');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.token = localStorage.getItem("TokenNo");
    this.userType = localStorage.getItem("UserType");
    this.userId = localStorage.getItem("UserID");
    this.fieldName = localStorage.getItem("FieldName");
    this.fieldValue = localStorage.getItem("FieldValue");
    this.currYearNo = localStorage.getItem("curryearno");
    this.currmonthno = localStorage.getItem("currmonthno");

    this.formname = "rlaAutoClosureApproval"
    this.actiondesc = "Rla Auto Closure Approval";
    this.cs.GetGeoLocation(this.userId, this.userType, this.formname, this.actiondesc, this.fieldName, this.fieldValue);
    this.onLoad();
  }

  onLoad() {
    if(this.userType == "so") {
      this.input.FieldName=this.fieldName;
      this.input.FieldValue=this.fieldValue;
      this.input.TagName=this.currYearNo;
      this.input.Datatype=this.currmonthno;
      this.input.UserID=this.userId;
      this.mds.getAutoCloserOutletForSO(this.input, this.token, this.device, this.deviceType, 'RLA Auto Closure').subscribe(res => {
        this.list = res;
        this.filteredList = this.list;
      });
    } else if(this.userType == "asm") {
      this.ds.getAutoCloserOutletForASM(this.currYearNo, this.userId, this.token, this.device, this.deviceType, 'RLA Auto Closure').subscribe(res => {
        debugger;
        this.list = res;
        this.filteredList = this.list;
      });
    }
  }

  rowClick(data: any){
    localStorage.setItem("rlaAutoClosureDetails",JSON.stringify(data))
    this.router.navigate(['/home/rlaAutoClosureDetailsApproval']);
  }

  get searchTerm(): any{
    return this._searchText;
  }
  set searchTerm(value: any){
     this._searchText = value;
     this.filteredList = this.filteredListMethod(value);
  }

  filteredListMethod(value: any): any {
    if(this.userType == "so") {
      return this.list.filter((ele: any) =>
      ele.desc.toUpperCase().indexOf(value.toUpperCase()) !== -1);
    } else if(this.userType == "asm") {
      return this.list.filter((ele: any) =>
      ele.rdname.toUpperCase().indexOf(value.toUpperCase()) !== -1);
    }
  }
}
