import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { MatDialog } from '@angular/material/dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterComponent } from '../filter/filter.component';
import { DataService } from '../../service/data.service';
import { OutputGetVisiAgrOTP,OutputAgreeDetails } from 'src/app/models/kpi-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';
import { Enclass, ResultClass } from 'src/app/models/encryption-class';
import { EncryptionService } from 'src/app/service/encryption.service';
import { ToastrService } from 'ngx-toastr';
import { MongoDataService } from 'src/app/service/mongo-data.service';

@Component({
  selector: 'app-outlet-name-change',
  templateUrl: './outlet-name-change.component.html',
  styleUrls: ['./outlet-name-change.component.css']
})
export class OutletNameChangeComponent implements OnInit {

  @ViewChild('openEnterVerificationCodeModal', { static: true }) elRefEnterVerificationCodeOpenModal: ElementRef | undefined;
  @ViewChild('openShowDataAndPrivacyGModal', { static: true }) elRefopenDataPrivacyAndAgreement: ElementRef | undefined;


  constructor(private activatedRoute: ActivatedRoute,private cs:CommonUtilitiesService,private fb: FormBuilder,private router: Router,
     private dialog: MatDialog,private ds:DataService, private es: EncryptionService, private toaster: ToastrService,
     private headerService: HeaderService,  private mds: MongoDataService,private modalService: NgbModal,private deviceService: DeviceDetectorService) 
  { }
  loginForm = this.fb.group({
    otp: ['', [Validators.required]]
  });
  loginFormagr = this.fb.group({   
    agrOTP: ['', [Validators.required]]
  });
  deviceType: any;
  device: any;
  userType: string | null = '';
  userId: any;
  token: any;
  DPNText: string | null = '';
  AgrText: string | null = '';
  submiton:string|null='';
  dpnsubmiton:string|null='';
  agrsubmiton:string|null='';
  IsOTP: boolean = false;
  IsChecked :boolean=false;
  statusobj: OutputGetVisiAgrOTP = new OutputGetVisiAgrOTP();
  agrdetails:OutputAgreeDetails=new OutputAgreeDetails();
  ID:any;
  errormsg: any='';
  IsError: boolean=false;
  KeyID: string|null='';
  OTP:string|null='';
  agrtype:string|null='';
  deviceInfo: any;
  formname:any;
  actiondesc:any;
  fieldName: any;
  fieldValue: any;
  UserType:any;
  VerifiedOtpText = "Dear Sir, We refer to placement of our visi-cooler at your Retail Outlet set out above (“Retail Outlet”) for which you have agreed to certain terms and conditions with Mondelez India Foods Private Limited (hereinafter referred to as “MIFPL” or, “Mondelez” or the “Company”) vide TERMS OF USE OF VISI COOLER (“Terms”). You wish to amend name reflected in your Retailer Outlet set out above to new name. In order to make this change on your request, this name change amendment (“Amendment Letter”) is being initiated by Mondelez and shall be made effective subject to verification of documents submitted by you for the name change request. You undertake that all information you have provided to Mondelez in relation to your name change is accurate and none of such information is false or incomplete to the best of your knowledge and information. If any information is incorrect, then please reach out to the Mondelez Sales Team to rectify or update the information as required. Except as provided above, all other terms and conditions of your agreement with the Company shall be governed as per the Terms."
  secretKey: any;
  clientId: any;
  public enclass: Enclass = new Enclass();
  public resultClass: ResultClass = new ResultClass();
  OutletNameChangeVerifyOTP:any;
  isSubmitted:boolean=false
  Id:any;
  ngOnInit(): void {
    debugger;
    
    this.headerService.setTitle('Check Agreement');
    this.headerService.setActiveURl('/onc');
    this.deviceInfo = this.deviceService.getDeviceInfo();
      this.deviceType= this.deviceInfo.deviceType;
      this.device= this.deviceInfo.device;
    this.token = localStorage.getItem("TokenNo");
   this.userId = localStorage.getItem("UserID");
    
   this.UserType = localStorage.getItem("UserType");
   this.fieldName = localStorage.getItem("FieldName");
   this.fieldValue = localStorage.getItem("FieldValue");

   this.secretKey = localStorage.getItem("SecreteKey")
   this.clientId = localStorage.getItem("ClientID");

   this.formname = "onc"
     this.activatedRoute.paramMap.forEach(params => {
       this.KeyID = params.get('KeyID');
       this.actiondesc ="onc"+" "+this.KeyID;
       this.cs.GetGeoLocation(this.userId, this.UserType, this.formname, this.actiondesc, this.fieldName, this.fieldValue);
       if (this.KeyID != null) {
         if (this.KeyID.length > 0 && this.KeyID.length > 0) {
           this.checkuserlink(this.KeyID);
         }
       }
      
    });
  }

  checkuserlink(keyId: any) {
    // if(!this.isSubmitted){
    // this.openModal(this.elRefEnterVerificationCodeOpenModal);
    // }else{
    // this.openModal(this.elRefopenDataPrivacyAndAgreement);
    // }
debugger
    let request = {
      // "KeyID": keyId.split(":")[1],
      "KeyID": keyId,

    }
  
    console.log("request", request)  

    this.mds.MA_CheckOTPISSubmit(request, this.token, this.device, this.deviceType, this.formname, this.clientId).subscribe(res => {
      console.log("getSurveydetails", res)
      let response=res
      console.log("response**************", response)
      this.OutletNameChangeVerifyOTP = response
      this.isSubmitted = false
      if (response[0].result?.toLowerCase() == "ok") {
        this.isSubmitted = true
        // this.openModal(this.elRefopenDataPrivacyAndAgreement);
        this.openModal(this.elRefEnterVerificationCodeOpenModal)

      } else {
        // this.openModal(this.elRefEnterVerificationCodeOpenModal)
          this.openModal(this.elRefopenDataPrivacyAndAgreement);

        return;
      }
    }, (error) => {
      this.isSubmitted = false
      console.log("error", error)
    });
  }

  selected_Reason(e: any) {
    if (e.target.checked) {
      this.IsChecked = e.target.value;        
  }}


verifyOTP(OTP: string, IsChecked: boolean) {

  if (!IsChecked) {
    this.toaster.warning("Please Check Terms and Condition")
    return;
  }

  if (OTP == "") {
    this.toaster.warning("Please Enter Valid Verification Code")
    return;
  }


  let request = {
    "Id": "0",
    "KeyID": this.KeyID,
    "OTP": OTP
  }

  console.log("request", request)

  this.mds.MA_OutletNameChangeVerifyOTP(request, this.token, this.device, this.deviceType, this.formname, this.clientId).subscribe(res => {
    console.log("getSurveydetails", res)
    let response=res
    console.log("response**************", response)
    this.OutletNameChangeVerifyOTP = response
    this.isSubmitted = false
    if (response[0].result?.toLowerCase() == "ok") {
      this.isSubmitted = true
      this.toaster.success("OTP Submit Successfully.Outlet Name Change request with Mondelez has been submitted.")
      this.modalService.dismissAll();
      // this.router.navigate(['home/outletNameChangeRequestVerifyOtpConfirmation'])
    } else {
      this.toaster.warning(response[0].result)
      return;
    }
  }, (error) => {
    this.isSubmitted = false
    console.log("error", error)
  });
}

  okay(){
    this.modalService.dismissAll();
    this.checkuserlink(this.KeyID);
     }

  modalClose() {
    this.modalService.dismissAll();  
  }

  openModal(modal: ElementRef<any> | undefined) {
    this.modalService.open(modal,
      {
        size: 'lg',
        backdrop: 'static',
        windowClass: '',
        keyboard: false,
        centered: true,
      })
  }
  


}
