import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { InputPara } from 'src/app/models/kpi-models';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { DatePipe } from '@angular/common';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';


@Component({
  selector: 'app-market-working-pc-list',
  templateUrl: './market-working-pc-list.component.html',
  styleUrls: ['./market-working-pc-list.component.css']
})
export class MarketWorkingPcListComponent implements OnInit {
  deviceType: any;
  device: any;
  userType: string | null = '';
  userId: any;
  list: any;
  filteredList: any;
  firMenuCountList: any;
  primarynos: any;
  secondarynos: any;
  token: any;
  _searchText = '';
  // public pcList: any = [
  //   {
  //     rdname: "abc",
  //     rdcode: "7474",
  //     totalachievment: "50",
  //     achievment: "50",
  //     target: "50"
  //   },
  //   {
  //     rdname: "jhgjgjhg",
  //     rdcode: "7474",
  //     totalachievment: "50",
  //     achievment: "50",
  //     target: "50"
  //   },
  //   {
  //     rdname: "resszzx",
  //     rdcode: "7474",
  //     totalachievment: "50",
  //     achievment: "50",
  //     target: "50"
  //   },
  //   {
  //     rdname: "jhgjgjhg",
  //     rdcode: "7474",
  //     totalachievment: "50",
  //     achievment: "50",
  //     target: "50"
  //   },
  //   {
  //     rdname: "resszzx",
  //     rdcode: "7474",
  //     totalachievment: "50",
  //     achievment: "50",
  //     target: "50"
  //   },
  //   {
  //     rdname: "jhgjgjhg",
  //     rdcode: "7474",
  //     totalachievment: "50",
  //     achievment: "50",
  //     target: "50"
  //   },
  //   {
  //     rdname: "resszzx",
  //     rdcode: "7474",
  //     totalachievment: "50",
  //     achievment: "50",
  //     target: "50"
  //   },
  // ];

  input: InputPara = new InputPara();
  FieldName: any = "rd";
  ParaName: any = "rdincentive";
  rdListData: any;
  pcList: any = [];
  myBooks: any;
  userList1: any;
  lastkeydown1: number = 0;
  listOrigin: any = [];
  selectedText: any;
   public day=1; //live
  //  public day=40; //dev
  formname:any;
  actiondesc:any;
  constructor(private router: Router,private cs:CommonUtilitiesService, private headerService: HeaderService, private mds: MongoDataService,private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.headerService.setTitle('Market Ka Saathi');
    this.headerService.setActiveURl('/marketWorkingPcList');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.userType = localStorage.getItem("UserType");
    this.userId = localStorage.getItem("UserID");
    this.token = localStorage.getItem("TokenNo");
    let rdListData: any = localStorage.getItem("marketWorkingRdListData");
    this.rdListData = JSON.parse(rdListData);
    this.formname="MarketWorkingPcList";
    this.actiondesc="Market Working PC List"+" "+this.rdListData.rdcode;
    this.cs.GetGeoLocation(this.userId,this.userType,this.formname,this.actiondesc,this.FieldName,this.rdListData.rdcode);
    this.loadData();
  }

  loadData() {
    this.input.FieldName = this.FieldName;
    this.input.FieldValue = this.rdListData.rdcode.toString();
    this.input.TagName = "PCTarget";
    this.input.Datatype = "AllIndia";
    this.input.UserID = this.userId

    this.mds.getTargetAchivementValue(this.input, this.token, this.device, this.deviceType, 'Market Ka Sathi PC List').subscribe(res => {
      this.list=res; 
      this.filteredList = this.list;
      console.log("pc list",this.filteredList )
      let todaysDate = new Date();

      if (todaysDate.getDate() <= this.day) {
        todaysDate.setDate(todaysDate.getDate() - this.day);
        let previousPeriod =this.datePipe.transform(todaysDate, 'MMM')+ ' ' + todaysDate.getFullYear();
        this.list = res.filter((singleItem: { [x: string]: string; }) => (singleItem['rdtype'].toLowerCase() == "urban") && (singleItem['period'] == previousPeriod));
        this.filteredList = res.filter((singleItem: { [x: string]: string; }) => (singleItem['rdtype'].toLowerCase() == "urban") && (singleItem['period'] == previousPeriod));
      }
      else {
        let currentPeriod =this.datePipe.transform(todaysDate, 'MMM')+ ' ' + todaysDate.getFullYear();
        this.list = res.filter((singleItem: { [x: string]: string; }) => (singleItem['rdtype'].toLowerCase() == "urban") && (singleItem['period'] == currentPeriod));
        this.filteredList = res.filter((singleItem: { [x: string]: string; }) => (singleItem['rdtype'].toLowerCase() == "urban") && (singleItem['period'] == currentPeriod));
      }
      this.listOrigin = this.list = this.filteredList
      // this.fillAutoCompleteSearchBox()
      console.log("this.pcList",this.filteredList)
    });
  }

  get searchTerm(): any {
    return this._searchText;
  }
  set searchTerm(value: any) {
    this._searchText = value;
    this.filteredList = this.filteredListMethod(value);
  }

  filteredListMethod(value: any): any {
    return this.list.filter((ele: any) =>
    ele.desc.toUpperCase().indexOf(value.toUpperCase()) !== -1 || ele.valuetype.indexOf(value) !== -1);
  }

  clickOnPcList(item: any) {
    localStorage.setItem("marketWorkingPCListData", JSON.stringify(item))
    this.router.navigate(['/home/MarketWorkingPcListData']);
  }

  
  // fillAutoCompleteSearchBox() {
  //   this.myBooks = [];
  //   let mybook1: any = [...new Set(this.listOrigin.map((bill: { desc: string; valuetype: number; }) => (bill.desc + ' [' + bill.valuetype + '] ')))];
  //   this.myBooks = [].concat.apply([], mybook1);

  // }
  // getUserIdsFirstWay($event: any) {
  //   let userId = (<HTMLInputElement>document.getElementById('userIdFirstWay')).value;
  //   this.userList1 = [];
  //   if (userId.length > 2) {
  //     if ($event.timeStamp - this.lastkeydown1 > 200) {
  //       this.userList1 = this.searchFromArray(this.myBooks, userId);
  //     }
  //   }
  // }
  // searchFromArray(arr: any, regex: any) {
  //   let matches = [], i, j = 0;
  //   for (i = 0; i < arr.length; i++) {
  //     if (arr[i].toLowerCase().match(regex.toLowerCase())) {
  //       matches.push(arr[i]);
  //       j++;
  //       i = j < 10 ? i : arr.length;
  //     }
  //   }
  //   return matches;
  // };
  // SearchText(e: any): void {
  //   debugger
  //   this.selectedText = e.target.value;
  //   let selectingType = this.selectedText.substring(this.selectedText.indexOf('[') + 1, this.selectedText.indexOf(']'));
  //   let item = this.listOrigin.filter((ele: any) => { return ele.valuetype == selectingType })[0];

  //   this.clickOnPcList(item);
  //   (<HTMLInputElement>document.getElementById('userIdFirstWay')).value = '';
  // }

}
