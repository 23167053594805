<div>
  <div class="row mt-4">
    <div class="col-12">
      <input class="fullWidth" type="text" name="" id="" placeholder="Search Outlet" [(ngModel)]="searchTerm">
    </div>
  </div>
  <div *ngFor="let item of filteredList; let i = index;">
    <div class="myDiv" [class.myClass]="item === isSelectedObj" (click)="rowClick(item)">
      <div class="container-fluid">
        <!-- <div long-press (onLongPress)="onLongPress()"> -->
        <div>
          <div class="row">
            <div class="col-12 mt-3">
              <span class="text_16 text_black" style="opacity: 40%;">Request No:</span><span class="ml-1 text_14 text_black" style="opacity: 40%;">{{item.id}}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-12 mt-2">
              <span class="text_14 text_black">From Outlet: {{item.fromOutletName | titlecase}}</span><span class="ml-1 text_14 text_black"
                style="opacity: 40%;">{{item.fromOutletCode}}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-8">
              <span style="opacity: 40%;" class="text_12 text_black">{{item.fromRDName | titlecase}}<span
                  class="ml-1 text_12 text_black">{{item.fromRDCode}}</span></span>
            </div>
            <div class="col-4 text-right">
              <span class="text_12 text_black">{{item.fromVisiName}}</span>
            </div>
          </div>
          <hr>
          <div class="row">
            <div class="col-12">
              <span class="text_14 text_black">To Outlet: {{item.toOutletName | titlecase}}</span><span class="ml-1 text_14 text_black"
                style="opacity: 40%;">{{item.toOUtletCode}}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-12 mb-3">
              <span style="opacity: 40%;" class="text_12 text_black">{{item.toRDName | titlecase}}<span
                  class="ml-1 text_12 text_black">{{item.toRDCode}}</span></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br>
  <br>
  <br>
  <br>
  <br>
</div>

