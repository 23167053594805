<div class="container lock_screen">
  <div class="row vw-100 login_box">
    <div class="col-12">
      <div class="row mt-4">
        <div class="col-12 text-center">
          <span class="ver_text">VERSION {{versionNo}}</span>
        </div>
      </div>
      <br>
      <form [formGroup]="loginForm" autocomplete="off">
        <div class="row mt-4">
          <div class="col-12">
            <div class="form-group">
              <input id="userName" class="form-control" #userName type="text" placeholder="User Name" name="userName"
                formControlName="userName">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <!-- <div class="input-group">
            <input
              type="password"
              class="form-control"
              formControlName="password"
            />
            <div class="input-group-append">
              <span class="input-group-text">
                <i class="fa fa-eye-slash"></i>
              </span>
           </div>
        </div> -->
              <div class="input-group">
                <input id="password" class="form-control" #passwd placeholder="Password"
                  [type]="showPassword ? 'text' : 'password'" name="password" formControlName="password">

                <span>
                  <!-- <i class="far fa-eye" id="togglePassword" style="margin-left: -30px; cursor: pointer;"></i> -->
                  <mat-icon aria-hidden="true" matSuffix (click)="showPassword = !showPassword">{{showPassword ?
                    'visibility_off' : 'visibility'}}</mat-icon>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-1"></div>
          <div class="col-10 text-center">
            <a class="link" (click)="open(ForgotPassword)">Forgot Password?</a>
          </div>
          <div class="col-1"></div>
        </div>
        <!-- <div class="row mt-4">
      <div class="col-1"></div>
      <div class="col-10 text-center">
        <a class="link" (click)="OnHealthStatus()">Health Status</a>
      </div>
      <div class="col-1"></div>
    </div> -->
        <div class="row">
          <div class="col-12 text-center">
            <h6 [style]="IsError? 'color: red' : 'color:green'">{{errormsg}}</h6>

          </div>

        </div>
      </form>
    </div>
  </div>
  <div class="row mt-3 vw-100 div_login">
    <div class="col-12 p-0">
      <button class="btn btn-block btn_login" type="submit" [disabled]="!loginForm.valid"
        (click)="CheckLogin(userName.value,passwd.value)">
        LOGIN</button>
    </div>
  </div>
</div>



<ng-template #ForgotPassword let-modal>
  <div class="modal-header text-center">
    <h4 class="modal-title text_color_head text_20" id="modal-basic-title">Forgot Password </h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- Kindly Entry User Name
    <br> -->
    <div class="form-group">
      <input id="fuserName" class="form-control" #fuserName placeholder="User Name" type="text" name="UserID"
        (change)="ChgUserName($event)">
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn_SUBMIT" [disabled]="!ValidUserID" (click)="submit()">SUBMIT</button>
  </div>
</ng-template>

<ng-template #openModalForPushNotification let-modal>
  <div class="modal-header">
    <h4 class="modal-title text_color_head text_20" id="modal-basic-title">
      <img class="img-fluid" src="assets/login_notification.png" />
      <span class="ml-3">Notification</span>
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modalClose()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      <div class="row">
        <div class="col-12">
          <span class="text_16">
            Sales Genie wants to sent you<br>notifications
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6" (click)="modalClose()">
        <button class="btn btn_DENY">DENY</button>
      </div>
      <div class="col-6" (click)="allow()">
        <button class="btn btn_ALLOW">ALLOW</button>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #opennotificationModal let-modal>
  <div class="modal-header text-center">
    <h4 class="modal-title text_color_head text_20 text-center" id="modal-basic-title">
      <span class="ml-3 text-center Value_text">Allow Notification</span>
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modalClose()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div id="accordion">
      <h3 class="mb-0 collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
        aria-controls="collapseOne">
        Please Allow Notification Permission To Get Latest Update
      </h3>
    </div>
  </div>

</ng-template>


<ng-template #openFeedbackModal let-modal>
  <div class="modal-content">
    <div class="modal-header text-center mt-2" style="font-size: 20px;
    color: #724E8C;
    font-weight: 500;
    letter-spacing: 0.19px;">
      <!-- <h4 class="modal-title text_color_head text_20 text-center" id="modal-basic-title"> -->
      <img class="img-fluid" src="../../../assets/icons/feedback_icon.svg" />
      <span class="ml-3 text-center feedbackLebel mt-1">Ticket Feedback</span>
      <!-- </h4> -->
      <button type="button" class="close" aria-label="Close" (click)="modalFeedbackClose()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="position: sticky;
    overflow-y: auto;
    max-height: 66vh;
    min-height: 0px;">

      <div class="row mt-2" style="background: #EFEBF5;">
        <div class="col-12">
          <h2 class="rateYourExpLabel mt-2">Rate Your Experience with <br><span class=""  style="color: #42A133;font-size: 14px;">{{feedbackUserName}}</span></h2>
          
        </div>
      </div>

      <div class="row mt-2" >
        <div class="col-12">
          <span class="" style="font-size: 14px;font-weight: 400;
    word-wrap: break-word !important;color: #707070;">{{ticketSubject}}</span>
          <span class="ms-2" style="font-weight: 200;">{{ticketNumber}}</span>
        </div>
      </div>



      <!-- <div class="row">
        <div class="col-12">
          <span style="opacity: 0.5;">Are you satisfied with the service</span>
        </div>
      </div> -->

      <div class="row mt-3">
        <div class="col-12 p-0">
          <ul class="list-inline rating-list" *ngFor="let star of stars" style="display: inline-block">
            <li (click)="countStar(star)" [ngClass]="{'selected': (star<= selectedValue)}">
              <i class="fa fa-star" style="font-size: xxx-large;"></i>
            </li>
          </ul>
        </div>
      </div>

      <hr>

      <div class="row mt-4" *ngIf="selectedValue<5">
        <div class="col-12">
          <span style="font-size: 16px;font-weight: 500;">Which of the issues below was the biggest problem during
            your
            experience with the ticketing tool service?</span>
        </div>
      </div>

      <div class="row mt-2" *ngIf="selectedValue<5">
        <span *ngFor="let item of feedBackOption;let i=index" class="mt-2">
          <div class="col-12 ms-2">
            <input id="checkBox" class="form-check-input" type="checkbox" (click)="checkValue($event, item.id)"
              value="item.option">
            <span class="ms-2" style="font-size: 14px;">
              {{ item.option }}
            </span>
          </div>
        </span>
      </div>


      <br><br><br>

    </div>


    <div class="row p-0">
      <div class="col-12">
        <button class="btn btn_APPROVE" (click)="submitFeedback()">SUBMIT</button>
      </div>
    </div>
  </div>
</ng-template>