import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { DataService } from 'src/app/service/data.service';
import { GetNewRLADashboard, InputPara } from 'src/app/models/kpi-models';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterComponent } from '../filter/filter.component';
import { MatDialog } from '@angular/material/dialog';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifyMeService } from 'src/app/service/notify-me.service';

@Component({
  selector: 'app-gate-meetingdata',
  templateUrl: './gate-meetingdata.component.html',
  styleUrls: ['./gate-meetingdata.component.css']
})
export class GateMeetingdataComponent implements OnInit {
  FieldName: any;
  FieldValue: any;
  DataType: any;
  UserID: any;
  gateMeeting: any;
  IsRDValue: boolean = false;
  device: any;
  deviceType: any;
  input: InputPara = new InputPara();
  isASMArea: boolean = false;
  photo: any;
  ParaName: any;
  token: any;
  lat: any;
  lng: any;
  UserType: any;

  constructor(private activatedRoute: ActivatedRoute, private modalService: NgbModal, private notifyMeService: NotifyMeService,
    private dialog: MatDialog, private fb: FormBuilder, private router: Router, private headerService: HeaderService, private ds: DataService) { }

  ngOnInit(): void {
    this.headerService.setActiveURl('/GateMeetingdata');
    this.headerService.setTitle("Gate Meeting");
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.token = localStorage.getItem("TokenNo");
    this.UserType = localStorage.getItem("UserType");
    this.activatedRoute.paramMap.forEach(params => {
      this.FieldValue = params.get('FieldValue');
      this.FieldName = params.get('FieldName');
      this.UserID = params.get('UserID');
      this.DataType = params.get('DataType');
      this.ParaName = params.get('ParaName');
      this.gateMeeting = [];
      if (this.FieldName == "ASMAREA") {
        this.IsRDValue = true;
      }
      else { this.IsRDValue = false; }
      this.GetGeoLocation();
      this.loadData();
    });
  }

  GetGeoLocation() {
    debugger
    if (navigator) {
      navigator.geolocation.getCurrentPosition(pos => {
        this.lat = pos.coords.latitude;
        console.log("  this.lat", this.lat)
        this.lng = pos.coords.longitude;
        this.SetapplicationLogData();
      }, this.handleLocationError, { timeout: 30000 });
    }
    else {
      this.notifyMeService.showError('Location Could not be captured', 'Mera APP');
    }
  }

  handleLocationError(error: { code: any; }) {
    switch (error.code) {
      case 3:
        this.notifyMeService.showError('Timeout in Position capture', 'Mera APP V2');
        break;
      case 2:
        this.notifyMeService.showError('Device is not capable of Position capture', 'Mera APP V2');
        break;
      case 1:
        this.notifyMeService.showError('You denied for Position capture', 'Mera APP V2');
        break;
    }
  }

  SetapplicationLogData() {
    debugger
    let request = {
      "userid": this.UserID,
      "usertype": this.UserType,
      "formname": "GateMeetingdata",
      "actiondesc": "Gate Meeting Data" + " " + this.FieldName,
      "fieldname": this.FieldName,
      "fieldvalue": this.FieldValue,
      "latitude": this.lat,
      "longitude": this.lng
    }
    this.ds.SetapplicationLogData(request).subscribe(res => {
      console.log("res", res)
    })
  }

  loadData(): void {
    this.input.FieldName = this.FieldName;
    this.input.FieldValue = this.FieldValue;
    this.input.TagName = this.ParaName;
    this.input.Datatype = this.DataType;
    this.input.UserID = this.UserID;
    console.log("input", this.input)
    this.ds.gateMeetingData(this.input, this.token, this.device, this.deviceType, 'Gate Meeting Data').subscribe(res => {
      console.log("gateMeetingData", res)
      this.gateMeeting = res;
      if (this.gateMeeting.length > 0 && (this.FieldName.toUpperCase() == "ASMAREA" || this.FieldName.toUpperCase() == "SOTERRITORY")) {
        this.isASMArea = true;
      } else {
        this.isASMArea = false;
      }
    });
  }

  RowClick(paraValue: any, paraName: any): void {
    this.router.navigate(['/home/GateMeetingdata/', this.UserID, "1", paraName, paraValue, this.DataType]);
  }

  open(content: any, filename: any) {
    this.photo = '';
    if (filename != '') {
      this.imagesDownload(filename)
    }
    this.modalService.open(content,
      {
        size: 'lg',
        backdrop: 'static',
        windowClass: '',
        keyboard: false,
        centered: true,
      })
  }

  openFilter() {
    if (this.dialog.openDialogs.length == 0) {
      const dialogRef = this.dialog.open(FilterComponent, {
        closeOnNavigation: true,
        data: {
          "FieldName": localStorage.getItem("OriginalFieldName"),
          "FieldValue": localStorage.getItem("OriginalFieldValue"),
          "UserID": this.UserID,
          "MenuUpTo": "3",
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.FieldName = result.FieldName;
          this.FieldValue = result.FieldValue;
          this.loadData();
        }
      });
    }
  }

  imagesDownload(filename: any) {
    // const dataToSend = {
    //   filename: filename,
    // };
    // this.ds.gateMeetingDownload(dataToSend, this.token, this.device, this.deviceType, 'Gate Meeting data').subscribe((res) => {
    //   this.createImageFromBlob(res.body)
    // }, (err) => {
    // });

 
  this.ds.getDownloadFileFromS3Bucket("GateMeeting", filename).subscribe((res) => {
    this.createImageFromBlob(res.body)
  }, (error) => { console.log("error", error) });
  }

  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      this.photo = reader.result;
    }, false);
    if (image) {
      reader.readAsDataURL(image);
    }
  }
}
