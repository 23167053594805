import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { InputPara } from 'src/app/models/kpi-models';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { DatePipe } from '@angular/common';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-market-working-pc-list-data',
  templateUrl: './market-working-pc-list-data.component.html',
  styleUrls: ['./market-working-pc-list-data.component.css']
})
export class MarketWorkingPcListDataComponent implements OnInit {
  deviceType: any;
  device: any;
  userType: string | null = '';
  userId: any;
  list: any;
  filteredList: any;
  firMenuCountList: any;
  primarynos: any;
  secondarynos: any;
  token: any;
  pcListData: any;
  panelOpenState: boolean = true;
  value: any = 0;
  scaleRange: any = [
    {
      from: 0,
      to: 100,
      color: "#DCABFF",
    }
  ];
  pointers: any = [
    {
      value: 0,
      color: "#FFFFFF",
      length: 0.75,
    },
  ];
  public rangeSize = 15;
  public ticksColor = 'rgba(252, 252, 255, 0.98)';
  public startAngle = -40;
  public endAngle = 220;
  input: InputPara = new InputPara();
  pcTsiEarnings: any = [];
  FieldName = "PC";
  public maxValue = 0;
  public majorValue = 0;
  public minorValue = 0;
  public showSpeedometer: any = false;
  public getOutletSalesParameter: any = [];
  curYear: any;
  curMonth: any;
  myDate: any;
  currentMonthData: any = [];
  GetPCIncentiveData: any = [];
  PCProductivity: any = 0;
  NoOfWorkingDays: any = 0;
  formname:any;
  actiondesc:any;
  constructor(private router: Router,private cs:CommonUtilitiesService, private headerService: HeaderService, private mds: MongoDataService, private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.panelOpenState = true;
    this.headerService.setTitle('Market Ka Saathi');
    this.headerService.setActiveURl('/marketWorkingPCListData');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.userType = localStorage.getItem("UserType");
    this.userId = localStorage.getItem("UserID");
    this.token = localStorage.getItem("TokenNo");
    this.showSpeedometer = false;
    let pcListData: any = localStorage.getItem("marketWorkingPCListData");
    this.pcListData = JSON.parse(pcListData);
    this.myDate = Date.now();
    this.curMonth = this.datePipe.transform(this.myDate, 'MMM');
    this.curYear = JSON.stringify(new Date().getFullYear());

    setTimeout(() => {
      // this.scaleRange = [
      //   {
      //     from: this.pcListData.tgt > this.pcListData.ach ? this.pcListData.tgt : this.pcListData.ach,
      //     to: 100,
      //     color: "#CDBED8",
      //   },
      //   {
      //     from: 0,
      //     to: this.pcListData.tgt,
      //     color: "#e6c4ff",
      //   },
      //   {
      //     from: 0,
      //     to: this.pcListData.ach,
      //     color: "#EAAB5E",
      //   }
      // ]

      // console.log("this.scaleRange", this.scaleRange)

      // this.pointers = [
      //   {
      //     value: this.pcListData.ach,
      //     color: "#e6c4ff",
      //     length: 0.75,
      //   },
      // ];

      this.scaleRange = [
        {
          from: this.pcListData.tgt > this.pcListData.ach ? this.pcListData.tgt : this.pcListData.ach,
          to: this.pcListData.tgt > this.pcListData.ach ? (Math.ceil(this.pcListData.tgt) + 10) : (Math.ceil(this.pcListData.ach) + 10),
          color: "#DCABFF",
        },
        {
          from: 0,
          to: this.pcListData.tgt,
          color: "#e6c4ff",
        },
        {
          from: 0,
          to: this.pcListData.ach,
          color: "#EAAB5E",
        }
      ]
      console.log("this.scaleRange", this.scaleRange);
      debugger
      this.maxValue = this.pcListData.tgt > this.pcListData.ach ? (Math.ceil(this.pcListData.tgt) + 10) : (Math.ceil(this.pcListData.ach) + 10);
      this.majorValue = (this.maxValue % 10);
      if (this.majorValue > 0) {
        this.maxValue = this.maxValue - this.majorValue;
      }
      this.majorValue = this.maxValue / 10;
      this.minorValue = this.majorValue / 10;
      this.minorValue = this.majorValue / 10;
      this.pointers = [
        {
          value: this.pcListData.ach,
          color: "#FFFFFF",
          length: 0.75,
        },
      ];
      this.showSpeedometer = true;
    }, 1000);
    debugger
    this.formname="MarketWorkingPcListData";
    this.actiondesc="Market Working PC List Data"+" "+this.pcListData.valuetype;
    this.cs.GetGeoLocation(this.userId,this.userType,this.formname,this.actiondesc,'pc',this.pcListData.valuetype);
    this.MarketWorking_GetPCIncentiveData();
    this.MarketWorking_GetOutletSalesParameter();
    this.loadData();
  }

  MarketWorking_GetPCIncentiveData() {
    debugger
    let request =
    {
      "FieldName": "pc",
      "FieldValue": this.pcListData.valuetype,
      "Datatype": "",
      "TagName": "",
      "UserID": this.userId
    }
    this.mds.MarketWorking_GetPCIncentiveData(request, this.token, this.device, this.deviceType, 'Market Ka Sathi Get PC Incentive Data Of Selected PC').subscribe(res => {
      console.log("res", res)
      this.GetPCIncentiveData = res;
      console.log("GetPCIncentiveData", this.GetPCIncentiveData)
      for (let item of this.GetPCIncentiveData) {
        this.PCProductivity = item.productivity;
        this.NoOfWorkingDays = item.noofworkingday;
      }
    });
  }

  MarketWorking_GetOutletSalesParameter() {
    let request =
    {
      "FieldName": "PC",
      "FieldValue": this.pcListData.valuetype.toString(),
      "Datatype": "",
      "TagName": "",
      "UserID": this.userId
    }
    this.mds.MarketWorking_GetOutletSalesParameter(request, this.token, this.device, this.deviceType, 'Market Ka Sathi Get Sales Parameter Of Selected PC').subscribe(res => {
      console.log("res", res)
      this.getOutletSalesParameter = res;
      console.log("getOutletSalesParameter", this.getOutletSalesParameter)
    });
  }


  loadData(): void {
    this.input.FieldName = this.FieldName;
    this.input.FieldValue = this.pcListData.valuetype;
    this.input.TagName = "PC";
    this.input.Datatype = '0';
    this.input.UserID = this.userId;
    debugger
    this.mds.getPCtsiincentive(this.input, this.token, this.device, this.deviceType, 'Market Ka Sathi Get PC Incentive Of Selected PC').subscribe(res => {

      this.pcTsiEarnings = [];
      let todaysDate = new Date();
      for (let i = 1; i <= 6; i++) {
        todaysDate.setMonth(todaysDate.getMonth() - 1);
        let previous6MonthData = this.datePipe.transform(todaysDate, 'MMM') + ' ' + todaysDate.getFullYear();
        res.forEach((item: any) => {
          if (item['paraname'] == previous6MonthData) {
            this.pcTsiEarnings.push(item)
          }
        });
      }
      let monthYear = this.curMonth + " " + this.curYear;
      this.currentMonthData = res.filter((singleItem: { [x: string]: string; }) => (singleItem['paraname'] == monthYear));
      console.log("pcTsiEarnings", this.pcTsiEarnings)
    });
  }

  clickOnViewBeat() {
    this.router.navigate(['/home/MarketWorkingBeatList']);
  }

}
