<div class="row">
    <div class="col-12 text-center">
      <h6 [style]="IsError? 'color: red' : 'color:green'">{{errormsg}}</h6>
      
    </div>
    
  </div>
  <ng-template #openModalThankYou let-modal>
      <div class="modal-header text-center">
        <h4 class="modal-title text_color_head text_20 text-center" id="modal-basic-title">
          
          <span class="ml-3 text-center Value_text">View DPN/Agreement</span>
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="modalClose()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
                <div id="accordion">
              <div class="card">
                <div class="card-header" id="headingOne" >
                  <h5 class="mb-0 collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    <button class="btn collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      <span class="Value_headertext text-center"><img class="img-fluid mr-1"/>Data Privacy Note</span>
                    </button>
                  <i class="fa fa-sort-desc float-right" aria-hidden="true"></i>
                  </h5>
                </div>
            
                <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                  <div class="card-body">
                      <span class="data_Privacy_Text" innerHtml="{{DPNText}}"></span>
                  </div>
                </div>
                <!-- <hr class="hr_cust">  -->
                <div class="row">
                  <div class="col-12 text-center mt-3">
                      <span class="Value_headertext text-center">Signed on dated : {{dpnsubmiton }}</span> 
                      <!-- | date: 'dd/MM/yyyy' -->
                </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingTwo">
                  <h5 class="mb-0 collapsed"  data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    <button class="btn collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      <span class="Value_headertext text-center">Agreement</span>
                    </button>
                    <i class="fa fa-sort-desc float-right"  aria-hidden="true"></i>
                  </h5>
                </div>
                <div id="collapseTwo" class="collapse show" aria-labelledby="headingTwo" data-parent="#accordion">
                  <div class="card-body">
                      <span class="data_Privacy_Text" innerHtml="{{AgrText}}">
                                             </span>
                  </div>
                </div>
                <!-- <hr class="hr_cust">  -->
                <div class="row">
                  <div class="col-12 text-center mt-3">
                      <span class="Value_headertext text-center">Signed on dated : {{agrsubmiton }}</span> 
                </div>
                </div>
              </div>
                     
             
          </div>
      </div>
        
         
  </ng-template>
  
  <ng-template #openDPNModalThankYou let-modal>
      <div class="modal-header text-center">
        <h4 class="modal-title text_color_head text_20 text-center" id="modal-basic-title">
          
          <span class="ml-3 text-center Value_text">Data Privacy Note</span>
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="modalClose()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
          <div id="accordion">
              <div class="card">
                <div class="card-header" id="headingOne" >
                  <h5 class="mb-0 collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    <button class="btn collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      <span class="Value_headertext text-center"><img class="img-fluid mr-1"/>Data Privacy Note</span>
                    </button>
                  <i class="fa fa-sort-desc float-right" aria-hidden="true"></i>
                  </h5>
                </div>
            
                <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                  <div class="card-body">
                      <span class="data_Privacy_Text" innerHtml="{{DPNText}}"></span>
                  </div>
                </div>
              </div>
             
              </div>
                     
              <form [formGroup]="loginForm" autocomplete="off">
              <div class="row">
                <div class="col-12 text-center mt-3">
                  <label>
                      <input type="checkbox" name="options"  autocomplete="off" class="Chkbtn" #checkbox
                      (change)="selected_Reason($event)"/>
                      &nbsp;<span>I Accept the Data Privacy Note</span>
                    </label>
              </div>
              </div>
              <div class="row">
                <div class="col-12 text-left">
                  <label>
                     <span class="txtspan">Enter Verification Code</span>
                    </label>
              </div>
              </div> 
             
              <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <input id="otp" class="form-control" #OTP  type="text" placeholder="Enter verification Code" name="OTP" formControlName="otp" value="">
                </div>
              </div>
  
            </div>
            <div class="row">
              <div class="col-12"><button class="btn btn-block btn_login" type="submit"  [disabled]="!loginForm.valid" (click)="submitOTP(OTP.value,checkbox.checked)">
                SUBMIT</button></div>
            </div>
          </form>
          <div class="row">
            <div class="col-12 text-center">
              <h6 [style]="IsError? 'color: red' : 'color:green'">{{errormsg}}</h6>
              
            </div>
            
          </div>
            
          
  
      </div>
      
         
  </ng-template>
  
  <ng-template #openAGRModalThankYou let-modal>
      <div class="modal-header text-center">
        <h4 class="modal-title text_color_head text_20 text-center" id="modal-basic-title">
          
          <span class="ml-3 text-center Value_text">View Agreement</span>
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="modalClose()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
          <div id="accordion">
              <div class="card">
                <div class="card-header" id="headingTwo">
                  <h5 class="mb-0 collapsed"  data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    <button class="btn collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      <span class="Value_headertext text-center">Agreement</span>
                    </button>
                    <i class="fa fa-sort-desc float-right"  aria-hidden="true"></i>
                  </h5>
                </div>
                <div id="collapseTwo" class="collapse show" aria-labelledby="headingTwo" data-parent="#accordion">
                  <div class="card-body">
                      <span class="data_Privacy_Text" innerHtml="{{AgrText}}">
                                             </span>
                  </div>
                </div>
              </div>
                     
                       
              <form [formGroup]="loginFormagr" autocomplete="off">
                <div class="row">
                  <div class="col-12 text-center mt-3">
                    <label>
                        <input type="checkbox" name="options"  autocomplete="off" class="Chkbtn" #agrcheckbox
                        (change)="selected_agrcheckbox($event)"/>
                        &nbsp;<span>I Accept the Agreement</span>
                      </label>
                </div>
                </div>
                <div class="row">
                  <div class="col-12 text-left">
                    <label>
                       <span class="txtspan">Enter Verification Code</span>
                      </label>
                </div>
                </div> 
               
                <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <input id="agrOTP" class="form-control" #agrOTP  type="text" placeholder="Enter verification Code" name="agrOTP" formControlName="agrOTP" value="">
                  
                  </div>
                </div>
    
              </div>
              <div class="row">
                <div class="col-12"><button class="btn btn-block btn_login" type="submit"  [disabled]="!loginFormagr.valid" (click)="submitagrOTP(agrOTP.value,agrcheckbox.checked)">
                  SUBMIT</button></div>
              </div>
            </form>
            <div class="row">
              <div class="col-12 text-center">
                <h6 [style]="IsError? 'color: red' : 'color:green'">{{errormsg}}</h6>
                
              </div>
              
            </div>
              
          </div>
      </div>
         
  </ng-template>
    
  <ng-template #openModalDPNSignedin let-modal>
    <div class="modal-header">
      <h4 class="modal-title text_color_head text_20" id="modal-basic-title">
        <img class="img-fluid" src="assets/thanks_icon.png" />
        <span class="ml-3">Thank You</span>
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="modalClose()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-12">
          <span class="model_PopUp_Text">Data Privacy Note has been signed in succesfully</span>
        </div>
      </div>
    </div>
    <div class="modal-footer" (click)="okay()">
      OKAY
    </div>
  </ng-template>
  
  <ng-template #openModalAGRSignedin let-modal>
    <div class="modal-header">
      <h4 class="modal-title text_color_head text_20" id="modal-basic-title">
        <img class="img-fluid" src="assets/thanks_icon.png" />
        <span class="ml-3">Thank You</span>
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="modalClose()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-12">
          <span class="model_PopUp_Text">Agreement has been signed in succesfully</span>
        </div>
      </div>
    </div>
    <div class="modal-footer" (click)="okay()">
      OKAY
    </div>
  </ng-template>
    