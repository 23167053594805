import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { DataService } from 'src/app/service/data.service';
import { InputPara } from 'src/app/models/kpi-models';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-rla-auto-closure-details-approval',
  templateUrl: './rla-auto-closure-details-approval.component.html',
  styleUrls: ['./rla-auto-closure-details-approval.component.css']
})
export class RlaAutoClosureDetailsApprovalComponent implements OnInit {
  deviceType: any;
  device: any;
  userType: string | null = '';
  userId: any;
  rlaAutoClosureDetails: any;
  list: any;
  currYearNo: any;
  currmonthno: any;
  token: any;
  formname:any;
  actiondesc:any;
  fieldName: any;
  fieldValue: any;

  constructor(private activatedRoute: ActivatedRoute,private cs:CommonUtilitiesService, private router: Router, private headerService: HeaderService, private mds: MongoDataService, private ds: DataService, private notifyMeService:NotifyMeService, ) { }

  ngOnInit(): void {
    this.headerService.setTitle('RLA Auto Closure');
    this.headerService.setActiveURl('/rlaAutoClosureDetailsApproval');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.token = localStorage.getItem("TokenNo");
    this.userType = localStorage.getItem("UserType");
    this.userId = localStorage.getItem("UserID");
    this.currYearNo = localStorage.getItem("curryearno");
    this.currmonthno = localStorage.getItem("currmonthno");4
    this.fieldName = localStorage.getItem("FieldName");
    this.fieldValue = localStorage.getItem("FieldValue");
    this.rlaAutoClosureDetails = JSON.parse(localStorage.getItem("rlaAutoClosureDetails") || '{}');
    this.formname = "rlaAutoClosureDetailsApproval"
    this.actiondesc = "Rla Auto Closure Details Approval";
    this.cs.GetGeoLocation(this.userId, this.userType, this.formname, this.actiondesc, this.fieldName, this.fieldValue);
    this.onLoad();
  }
  input:InputPara=new InputPara();
  onLoad() {
    if(this.userType == "so") {
      this.input.FieldName=this.rlaAutoClosureDetails.fieldName;
      this.input.FieldValue=this.rlaAutoClosureDetails.fieldvalue;
      this.input.TagName=this.currYearNo ;
      this.input.Datatype=this.currmonthno;
      this.input.UserID=this.userId;
      this.mds.getAutoCloserOutletForSO(this.input, this.token, this.device, this.deviceType, 'RLA Auto Closure').subscribe(res => {
        this.list = res;
      });
    }
  }

  rowClick(data: any){
    localStorage.setItem("rlaAutoClosureOutletDetails",JSON.stringify(data))
    this.router.navigate(['/home/rlaAutoClosureSelectReasonApproval']);
  }

  approve() {
    const dataToSend = {
      UserID : this.userId,
      UserType  : this.userType,
      Status : 'APPROVED',
      RejectRemark :'0',
      Rdcode : this.rlaAutoClosureDetails.rdcode,
      Yearno : Number(this.currYearNo),
      // Yearno : 2020,
    };
    this.ds.autoClosureReportApprovalForASM(dataToSend, this.token, this.device, this.deviceType,'Reject Reason Approval').subscribe(res => {
      this.notifyMeService.showSuccess('Record APPROVED Sucessfully', 'Sales Genie App');
      this.router.navigate(['/home/rlaAutoClosureApproval']);
    });
  }

  reject() {
    this.router.navigate(['/home/facialRecRejReasonApproval'], { queryParams: { selected: 'RLAAutoClosure' } });
  }

}
