import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { HeaderService } from 'src/app/service/header.service';
import { DatePipe } from '@angular/common';
import { FilterComponent } from '../filter/filter.component';
import { MatDialog } from '@angular/material/dialog';
import { InputPara } from 'src/app/models/kpi-models';
import { DataService } from 'src/app/service/data.service';
import { NotifyMeService } from 'src/app/service/notify-me.service';

@Component({
  selector: 'app-urjaa-ulb',
  templateUrl: './urjaa-ulb.component.html',
  styleUrls: ['./urjaa-ulb.component.css']
})
export class UrjaaUlbComponent implements OnInit {
  deviceType: any;
  device: any;
  FieldName: any;
  FieldValue: any;
  ParaName: any;
  UserID: any;
  public resList: any[] = [];
  myDate: any;
  curYear: any;
  curMonth: any;
  prevYear: string = '';
  prevYearTwoDigit: string = '';
  rdType: any = 0;
  selected: string = '';
  token: any ;
  lat:any;
  lng:any;
  UserType:any;

  constructor(private activatedRoute: ActivatedRoute, private dialog: MatDialog,  private notifyMeService: NotifyMeService,
    private ds: DataService,
    private router: Router, private headerService: HeaderService, private mds: MongoDataService, private datePipe: DatePipe,) { }

  ngOnInit(): void {
    this.selected = this.activatedRoute.snapshot.queryParams["selected"];
    this.headerService.setActiveURl('/ulb');
    this.headerService.setTitle('Unique Lines Billed');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.token = localStorage.getItem("TokenNo");
    this.UserType = localStorage.getItem("UserType");
    this.myDate = Date.now();

    this.curMonth = this.datePipe.transform(this.myDate, 'MMM');
    this.prevYear = JSON.stringify(new Date().getFullYear() - 1);
    this.prevYearTwoDigit = this.prevYear.substring(2, 4);

    this.activatedRoute.paramMap.forEach(params => {
      this.FieldValue = params.get('FieldValue');
      this.FieldName = params.get('FieldName');
      this.UserID = params.get('UserID');
      this.rdType = params.get('ParaName');
      this.GetGeoLocation();
      this.loadData();
    });
  }

  
  GetGeoLocation() {
    debugger
    if (navigator) {
      navigator.geolocation.getCurrentPosition(pos => {
        this.lat = pos.coords.latitude;
        console.log("  this.lat", this.lat)
        this.lng = pos.coords.longitude;
        this.SetapplicationLogData();
      }, this.handleLocationError, { timeout: 30000 });
    }
    else {
      this.notifyMeService.showError('Location Could not be captured', 'Mera APP');
    }
  }

  handleLocationError(error: { code: any; }) {
    switch (error.code) {
      case 3:
        this.notifyMeService.showError('Timeout in Position capture', 'Mera APP V2');
        break;
      case 2:
        this.notifyMeService.showError('Device is not capable of Position capture', 'Mera APP V2');
        break;
      case 1:
        this.notifyMeService.showError('You denied for Position capture', 'Mera APP V2');
        break;
    }
  }

  SetapplicationLogData() {
    debugger
    let request = {
      "userid": this.UserID,
      "usertype": this.UserType,
      "formname": "urjaaUlb",
      "actiondesc": "Urjaa Ulb" + " " + this.FieldName,
      "fieldname": this.FieldName,
      "fieldvalue": this.FieldValue,
      "latitude": this.lat,
      "longitude": this.lng
    }
    this.ds.SetapplicationLogData(request).subscribe(res => {
      console.log("res", res)
    })
  }

  input:InputPara=new InputPara();
  loadData(): void {
    this.input.FieldName=this.FieldName;
    this.input.FieldValue=this.FieldValue;
    this.input.TagName="1";
    this.input.Datatype=this.rdType;
    this.input.UserID=this.UserID;
    this.mds.getUlbDashboard(this.input,this.token,this.device, this.deviceType,'Unique Lines Billed').subscribe(res => {
      this.resList = res;
    });
  }



  RowClick(paraname: any,paravalue: any, selected: any): void {
   if(paraname!="rdcode"){
    paraname=paraname.toUpperCase()=='INDIA'?'BRANCH':paraname.toUpperCase()=='BRANCH'?'REGION': paraname.toUpperCase()=='REGION'?'ASM': paraname.toUpperCase()=='ASM'?'SOTERRITORY' : paraname.toUpperCase()=='SOTERRITORY'?'RDCode' : 'RDCode';
    this.router.navigate(['/home/urjaaUlb/', paraname, paravalue, selected, this.UserID]);
   }
  }
  openFilter(){
    if (this.dialog.openDialogs.length == 0) {
      const dialogRef = this.dialog.open(FilterComponent, {
        closeOnNavigation: true,
        data: {
          "FieldName": localStorage.getItem("OriginalFieldName"),
          "FieldValue": localStorage.getItem("OriginalFieldValue"),
          "UserID": this.UserID,
          "MenuUpTo": "5",
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if(result.FieldName == "RD"){
            this.FieldName="RDCode";
          }else{
            this.FieldName=result.FieldName;
          }
          this.FieldValue=result.FieldValue;
          this.loadData();
        }
      });
    }
  }

}
