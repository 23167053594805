<div class="row mt-4">
  <div class="col-12 mediaTab">
    <mat-tab-group dynamicHeight mat-align-tabs="center" (selectedTabChange)="onTabChanged($event)">

      <mat-tab label="All" class="tablabel">
        <div *ngFor="let item of list; let i = index;">
          <div class="row mt-3">
            <div class="col-12" *ngIf="item.brandType == 'Image'">
              <img class="img-fluid" [src]='defaultImageUrl + item.imgName' alt="">
            </div>
            <div class="col-12" *ngIf="item.brandType == 'Video'">
              <video class="img-fluid" controls>
                <source [src]='defaultImageUrl + item.imgName' type="video/mp4">
              </video>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-12">
              <span class="heading_Text">{{item.description}}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <span class="date_Text">{{item.activeFrom}}</span>
            </div>
          </div>
        </div>
        <br><br><br><br><br>
      </mat-tab>

      <mat-tab label="VIDEO" class="tablabel">
        <div *ngFor="let item of videoList; let i = index;">
          <div class="row mt-3">
            <div class="col-12">
              <video class="img-fluid" controls>
                <source [src]='defaultImageUrl + item.imgName' type="video/mp4">
              </video>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-12">
              <span class="heading_Text">{{item.description}}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <span class="date_Text">{{item.activeFrom}}</span>
            </div>
          </div>
        </div>
        <br><br><br><br><br>
      </mat-tab>

      <mat-tab label="IMAGES" class="tablabel">
        <div *ngFor="let item of imageList; let i = index;">
          <div class="row mt-3">
            <div class="col-12">
              <img class="img-fluid" [src]='defaultImageUrl + item.imgName' alt="">
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-12">
              <span class="heading_Text">{{item.description}}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <span class="date_Text">{{item.activeFrom}}</span>
            </div>
          </div>
        </div>
        <br><br><br><br><br>
      </mat-tab>

    </mat-tab-group>
  </div>
</div>
