<div>
  <div class="row mt-4">
    <div class="col-12">
      <input class="fullWidth" type="text" name="" id="" placeholder="Search RD Name"
        [(ngModel)]="searchTerm">
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-6">
      <span class="RD_List">RD List</span>
    </div>
    <div class="col-6 text-right">
      <span class="status_Text">Status</span>
    </div>
  </div>
  <div *ngFor="let item of filteredList; let i = index;" (click)="rowClick(item)">
    <div class="row mt-3">
      <div class="col-8">
        <span class="rd_Name">{{item.rdName | titlecase}}</span>
        <span class="ml-1 rd_Code">{{item.rdCode}}</span>
      </div>
      <div class="col-4 text-right">
        <span class="status_Value">{{item.status}}</span>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12">
        <span class="state_Name">{{item.state}}</span>
      </div>
    </div>
    <hr>
  </div>
  <br>
  <br>
  <br>
  <br>
  <br>
</div>
