<div>
  <div class="row mt-4">
    <div class="col-9">
      <input class="fullWidth" type="text" name="" id="" #OutletUID placeholder="Search Outlet" [(ngModel)]="searchText">
    </div>
    <div class="col-3 pl-0">
      <button class="btn btn_Search" (click)="searchOutlet(OutletUID.value,'pullout')">Search</button>
    </div>
  </div>
  <!-- <div class="row mt-4">
    <div class="col-12">
      <ul>
        <li *ngFor="let c of items | filter : searchText">
          {{c.title}}
        </li>
      </ul>
    </div>
  </div> -->
  <div *ngFor="let item of list">
    <div class="myDiv" [class.myClass]="isSelected">
      <div class="container-fluid">
        <div long-press (onLongPress)="onLongPress()" (click)="rowClick(item)">
          <div class="row">
            <div class="col-12 mt-2">
              <span class="text_16 text_black">{{item.outletname | titlecase}}</span>
              <span class="ml-1 text_14 text_black" style="opacity: 40%;">{{item.outletcode}}</span>
            </div>
            <!-- <div class="col-2 text-right mt-2" *ngIf="isLongPress">
              <img class="img" src="assets/check.png" *ngIf="!isSelected" (click)="clickOnSelect()" />
              <img class="img" src="assets/check_1.png" *ngIf="isSelected" />
            </div> -->
          </div>
          <div class="row">
            <div class="col-12 mb-2">
              <span style="opacity: 40%;" class="text_12 text_black">{{item.rdname | titlecase}}
                <span class="ml-1 text_12 text_black">{{item.rdcode}}</span></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br>
  <br>
</div>
