<div>
  <div class="row mt-1">
    <div class="col-12">
      <!-- <input class="fullWidth" type="text" placeholder=" Search Outlet code" list="dynmicUserIds"
        (keyup)="getUserIdsFirstWay($event)" id="userIdFirstWay" (change)="SearchText($event)" autocomplete="off"> -->
      <input class="fullWidth" type="text" name="" id="" placeholder="Search Outlet by Outlet Name" [(ngModel)]="searchTerm">
      <!-- <datalist id="dynmicUserIds">
        <option *ngFor="let item of userList1" [value]="item">{{item}}</option>
      </datalist> -->
    </div>
  </div>
  <div *ngFor="let cmp of filteredList">
    <div class="myDiv">
      <div class="container-fluid">
        <div long-press (onLongPress)="onLongPress()">
          <div class="row">
            <div class="col-12 mt-2">
              <span class="outlet_Name">{{cmp.outletname | titlecase}}</span>
              <span class="outlet_Code ml-2">{{cmp.outletcode}}</span>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-7">
              <span class="outlet_Name">Ticket Number: <span class="outlet_Code">{{cmp.ticketnumber}}</span> </span>
            </div>
            <div class="col-5 pr-0">
              <span class="outlet_Name">Date: <span class="outlet_Code">{{cmp.vdate}}</span> </span>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-12">
              <span class="actionremark">Action: {{cmp.actionremark}} </span>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-6 text-left" style="margin-bottom: 1rem;">
              <span class="actionremark">Problem: {{cmp.currentcondition}}</span>
            </div>
            <div class="col-6 text-right" *ngIf="cmp.callstatus == 'Close'">
              <button class="btn btn_custom">
                <img class="btn_img" src="assets/done.png" /> Close
              </button>
            </div>
            <div class="col-6 text-right" *ngIf="cmp.callstatus == 'Open'">
              <button class="btn btn_Pending">
                <img class="btn_img" src="assets/pending.png" /> Open
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
  <br>
  <br>
  <br>
  <br>
  <div class="footer">
    <div class="row text-center mt-3">
      <div class="col-12" (click)="ClickNewRequest()">
        ADD NEW COMPLAINT
      </div>
    </div>
  </div>
