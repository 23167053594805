<div class="row mt-2 bg_Color">
  <div class="col-12 mt-4">
    <span class="rd_Name_Head">{{subRdApprovalDetails.outletName | titlecase}}</span>
  </div>
  <div class="col-7 mt-2 mb-4">
    <span class="amt_Text">SST Code:</span>
    <span class="ml-1 amt_Value">{{subRdApprovalDetails.rdCode}}</span>
  </div>
  <div class="col-5 mt-1 mb-4 text-right">
    <button class="btn btn_custom" (click)="clickOnMoreDetails()">More Details</button>
  </div>
</div>
<div class="row mt-4">
  <div class="col-12">
    <span class="Transaction_No_Text">SST Beat</span>
  </div>
  <div class="col-12 mt-1" *ngIf="userType == 'SO'">
    <select class="form-control" id="beat" (change)="onChangeBeat($event)" [(ngModel)]="selectedBeat">
      <option value="" selected disabled>Select</option>
      <option *ngFor="let item of beatnameList" [value]="item">{{item}}</option>
    </select>
  </div>
  <div class="col-12 mt-1" *ngIf="userType != 'SO'">
    <span class="Transaction_No_Value">{{selectedBeat}}</span>
  </div>
</div>
<hr *ngIf="userType != 'SO'">
<div class="row mt-4">
  <div class="col-12">
    <span class="Transaction_No_Text">Sub RD Type</span>
  </div>
  <div class="col-12 mt-1" *ngIf="userType == 'SO'">
    <select class="form-control" id="beat" (change)="onChangeSubRDType($event)">
      <option value="" selected disabled>Select</option>
      <option value="new">New</option>
      <option value="replacement">Replacement</option>
      <option value="bifurcate">Bifurcate</option>
    </select>
  </div>
  <div class="col-12 mt-1" *ngIf="userType != 'SO'">
    <span class="Transaction_No_Value">{{subRdApprovalDetails.substokistStatus | titlecase}}</span>
  </div>
</div>
<hr *ngIf="userType != 'SO'">
<div class="row mt-4" *ngIf="isNew">
  <div class="col-12">
    <span class="Transaction_No_Text">Old Sub</span>
  </div>
  <div class="col-12 mt-1">
    <select class="form-control" id="beat" (change)="onChangeOldSub($event)">
      <option value="" selected disabled>Select</option>
      <option *ngFor="let item of oldSubList" [value]="item">{{item}}</option>
    </select>
  </div>
</div>
<div class="row mt-4" *ngIf="userType != 'SO' && subRdApprovalDetails.oldSubStkName != ''">
  <div class="col-12">
    <span class="Transaction_No_Text">Old Sub</span>
  </div>
  <div class="col-12 mt-1" *ngIf="userType != 'SO'">
    <span class="Transaction_No_Value">{{subRdApprovalDetails.oldSubStkName + ' (' + subRdApprovalDetails.oldSubStkUID +')' | titlecase}}</span>
  </div>
</div>
<hr *ngIf="userType != 'SO' && subRdApprovalDetails.oldSubStkName != ''">
<div class="row mt-4" *ngIf="userType != 'SO'">
  <div class="col-12">
    <span class="Transaction_No_Text">Markets</span>
  </div>
</div>
<div *ngIf="userType != 'SO'">
  <div class="row mt-1" *ngFor="let item of marketNameList; let i = index;">
    <div class="col-12" >
      <span class="Transaction_No_Value">{{item.marketName | titlecase}}</span>&nbsp;
      (<span class="Transaction_No_Value4">{{item.marketuid}}</span>)
    </div>
  </div>
</div>
<hr *ngIf="userType != 'SO'">
<div class="row mt-4" (click)="clickOnMarkets(openModalForMarket)" *ngIf="isNew">
  <div class="col-10">
    <span class="Transaction_No_Text">Markets</span>
  </div>
  <div class="col-2 text-right">
    <img class="img-fluid" src="assets/edit_profile.png"/>
  </div>
</div>
<div *ngIf="isBufircate">
  <div class="row mt-1" *ngFor="let item of marketNameList; let i = index;">
    <div class="col-12" >
      <span class="Transaction_No_Value">{{item.marketName | titlecase}}</span>&nbsp;
      (<span class="Transaction_No_Value4">{{item.marketUID}}</span>)
    </div>
  </div>
</div>
<hr *ngIf="isNew">
<div *ngIf="userType == 'RH'">
  <div *ngFor="let item of rhList; let i = index;">
    <div class="row mt-3">
      <div class="col-12">
        <span class="Selfie_facing_text">Bank Details</span>
      </div>
      <div class="col-12 mt-3">
        <img [src]="photopath + item.bankPhoto"  class="img-fluid" (click) = "show(photopath + item.bankPhoto)"/>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <span class="Selfie_facing_text">Pan Details</span>
      </div>
      <div class="col-12 mt-3">
        <img [src]="photopath + item.panPhoto"  class="img-fluid" (click) = "show(photopath + item.panPhoto)"/>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <span class="Selfie_facing_text">Sub Details</span>
      </div>
      <div class="col-12 mt-3">
        <img [src]="photopath + item.subPhoto"  class="img-fluid" (click) = "show(photopath + item.subPhoto)"/>
      </div>
    </div>
  </div>
</div>

<div [style.display]="showModal ? 'block' : 'none'" class="modal" id="imagemodal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel">Image preview</h4>
      </div>
      <div class="modal-body">
        <img [src]="selectedimagepath" id="imagepreview" style="width: 100%; height: 425px;" >
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal" (click) = "hide()">Close</button>
      </div>
    </div>
  </div>
</div>

<br><br><br><br>
<div class="footer" *ngIf="userType == 'SO'">
  <div class="row">
    <div class="col-12" (click)="approve()">
      <button class="btn btn_APPROVE">APPROVE</button>
    </div>
  </div>
</div>
<div class="footer" *ngIf="userType != 'SO'">
  <div class="row">
    <div class="col-6 pr-0" (click)="reject()">
      <button class="btn btn_REJECT">REJECT</button>
    </div>
    <div class="col-6 pl-0" (click)="approveForOther()">
      <button class="btn btn_APPROVE">APPROVE</button>
    </div>
  </div>
</div>
<ng-template #openModalForMarket let-modal>
  <div class="modal-header">
    <h4 class="modal-title text_color_head text_20" id="modal-basic-title">
      <span class="">Select Market</span>
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modalCloseForMarket()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="display: block; height: 450px; overflow-y: scroll;">
    <div>
      <div class="row" style="margin-top: 2rem;">
        <div class="col-6">
          <span class="head_Text">Market</span>
        </div>
        <div class="col-3">
          <span class="head_Text">UID</span>
        </div>
        <div class="col-3">
          <span class="head_Text">Code</span>
        </div>
      </div>
      <div class="row mt-4" *ngFor="let item of marketNameList; let i = index;">
        <div class="col-6">
          <div class="form-check">
            <input type="checkbox" class="form-check-input" value={{item.marketName}} id="Check{{i}}"
              [(ngModel)]="marketNameList[i].checked" (change)="selected = i; selected_Reason($event)" [checked]="i">

            <label class="form-check-label" for="Check{{i}}">
              <span class="rd_Name">{{item.marketName }}</span>
            </label>
          </div>
        </div>
        <div class="col-3">
          <span class="uid_value">{{item.marketUID}}</span>
        </div>
        <div class="col-3">
          <span class="uid_value">{{item.marketCode}}</span>
        </div>
      <div class="col-12  hr_line">&nbsp;</div>
      </div>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-6 pr-0" (click)="modalCloseForMarket()">
      <button class="btn btn_REJECT">Cancel</button>
    </div>
    <div class="col-6 pl-0" (click)="save()">
      <button class="btn btn_APPROVE">Save</button>
    </div>
  </div>

  <!-- <div class="modal-footer">
    <div class="row">
      <div class="col-6" (click)="modalCloseForMarket()">
        <button class="btn btn_Cancel">Cancel</button>
      </div>
      <div class="col-6" (click)="save()">
        <button class="btn btn_Save">Save</button>
      </div>
    </div>
  </div> -->
</ng-template>


<ng-template #openModalThankYou let-modal>
  <div class="modal-header">
    <h4 class="modal-title text_color_head text_20" id="modal-basic-title">
      <img class="img-fluid" src="assets/thanks_icon.png" />
      <span class="ml-3">{{message!='' ? 'Warning' : 'Thank You'}}</span>
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modalClose()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row" *ngIf="message == ''">
      <div class="col-12">
        <span class="model_PopUp_Text" *ngIf="!isReject">Sub RD  {{subRdApprovalDetails.outletName | titlecase}} Approved Successfully</span>
        <span class="model_PopUp_Text" *ngIf="isReject">Sub RD {{subRdApprovalDetails.outletName | titlecase}} Rejected Successfully </span>
      </div>
    </div>
    <div class="row" *ngIf="message != ''">
      <div class="col-12">
        <span class="model_PopUp_Text">{{message}}</span>
      </div>
    </div>
    <!-- <div class="row mt-4 mb-2" *ngIf="message == ''">
      <div class="col-12">
        <span class="pc_Name_PopUp">{{subRdApprovalDetails.outletName | titlecase}}</span>
        <span class="ml-2 pc_Code_PopUp">{{subRdApprovalDetails.rdCode}}</span>
      </div>
    </div> -->
  </div>
  <div class="modal-footer" (click)="okay()">
    OKAY
  </div>
</ng-template>
