import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { DataService } from '../../service/data.service';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-missing-visicooler',
  templateUrl: './missing-visicooler.component.html',
  styleUrls: ['./missing-visicooler.component.css']
})
export class MissingVisicoolerComponent implements OnInit {
  deviceType: any;
  device: any;
  userType: string | null = '';
  userId: any;
  private _searchText = '';
  list: any;
  filteredList: any;
  firMenuCountList: any;
  primarynos: any;
  secondarynos: any;
  token: any;
  formname:any;
  actiondesc:any;
  fieldName:any;
  fieldValue:any;

  constructor(private activatedRoute: ActivatedRoute, private cs:CommonUtilitiesService,private router: Router, private headerService: HeaderService, private mds: MongoDataService, private notifyMeService:NotifyMeService, private ds:DataService) { }


  ngOnInit(): void {
    this.headerService.setTitle('Missing Visicooler');
    this.headerService.setActiveURl('/missingVisicooler');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.userType = localStorage.getItem("UserType");
    this.userId = localStorage.getItem("UserID");
    this.token = localStorage.getItem("TokenNo");
    this.fieldName = localStorage.getItem("FieldName");
    this.fieldValue = localStorage.getItem("FieldValue");
    this.formname="missingVisicooler";
    this.actiondesc="Missing Visicooler";
    this.cs.GetGeoLocation(this.userId,this.userType,this.formname,this.actiondesc,this.fieldName,this.fieldValue);
    this.loadData();
  }

  loadData(): void {

    this.mds.firlist(this.userId, '1', '0', this.token, this.device, this.deviceType,'Missing Visicooler').subscribe(res => {
      console.log("firlist",res);
      this.list = res;
      this.filteredList = this.list;
      debugger
    });
    this.ds.FIRMenuCount(this.userId, this.token, this.device, this.deviceType,'Missing Visicooler').subscribe(res => {
      console.log("FIRMenuCount",res);
      this.firMenuCountList = res;
      this.firMenuCountList.forEach((ele: any) => {
        if(ele.code == 'Primarynos'){
          this.primarynos = ele.primarynos;
        }
        if(ele.code == 'Secondarynos'){
          this.secondarynos = ele.primarynos;
        }
      });
    });
  }


  rowClick(selected: any) {
    if(this.userType == "so" || this.userType?.toLowerCase()=="asm"){
      localStorage.setItem("outletSelectedMV", JSON.stringify(selected));
      this.router.navigate(['/home/missingVisicoolerOutletDetail']);
    }else{
      this.notifyMeService.showWarning("You are not authorized for this transaction", 'MeraApp 2');
    }
  }

  get searchTerm(): any{
    return this._searchText;
  }
  set searchTerm(value: any){
     this._searchText = value;
     this.filteredList = this.filteredListMethod(value);
  }

  filteredListMethod(value: any): any {
    return this.list.filter((ele: any) =>
      ele.outletcode.indexOf(value) !== -1);
  }

}
