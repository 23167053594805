import { Component, OnInit ,Input} from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { MatDialog } from '@angular/material/dialog';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { ValuePerformancedata ,Resultdata, InputPara} from 'src/app/models/kpi-models';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterComponent } from '../filter/filter.component';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { DataService } from 'src/app/service/data.service';

@Component({
  selector: 'app-value-performence-data',
  templateUrl: './value-performence-data.component.html',
  styleUrls: ['./value-performence-data.component.css']
})
export class ValuePerformenceDataComponent implements OnInit {
  FieldName: any;
  FieldValue: any;
  DataType: any;
  UserID: any;
  valueperformancedata: any;
  periodList: any[] = [];
  selectedperiod: any;
  UserType: any;
  IsRDValue: boolean=false;
  rdData: any=null;
  resultdata:Resultdata=new Resultdata();
  device: any;
  deviceType: any;
  input:InputPara=new InputPara();
  isPc:boolean=false;
  token: any ;
  lat:any;
  lng:any;

  constructor(private activatedRoute: ActivatedRoute,private fb: FormBuilder,private router: Router, private headerService: HeaderService, private mds: MongoDataService, 
    private dialog: MatDialog,private notifyMeService: NotifyMeService,private ds: DataService) { }

  ngOnInit(): void {
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.headerService.setActiveURl('/ValuePerformanceData');
  //  this.headerService.setTitle("Value performance (Second Half)");
    this.headerService.setTitle("Value performance");
    this.UserType = localStorage.getItem("UserType");
    this.activatedRoute.paramMap.forEach(params => {
      this.FieldValue = params.get('FieldValue');
      this.FieldName = params.get('FieldName');
      this.UserID = params.get('UserID');
      this.DataType = params.get('DataType');
      this.token = localStorage.getItem("TokenNo");
      this.valueperformancedata = [];
      this.selectedperiod = params.get('selectedperiod');
      this.isPc=this.DataType.toUpperCase()=='URBAN' ?  true:false;
      if(this.FieldName=="RD")
      {
        this.selectedperiod =localStorage.getItem("selectedperiod");
        let obj=localStorage.getItem("rdData");
        this.rdData = JSON.parse(obj || '{}') as ValuePerformancedata;
        this.IsRDValue=true;
      }
      else{this.IsRDValue=false;}
      debugger
      this.GetGeoLocation();
      this.loadData();
    });
  }

  
  GetGeoLocation() {
    debugger
    if (navigator) {
      navigator.geolocation.getCurrentPosition(pos => {
        this.lat = pos.coords.latitude;
        console.log("  this.lat", this.lat)
        this.lng = pos.coords.longitude;
        this.SetapplicationLogData();
      }, this.handleLocationError, { timeout: 30000 });
    }
    else {
      this.notifyMeService.showError('Location Could not be captured', 'Mera APP');
    }
  }

  handleLocationError(error: { code: any; }) {
    switch (error.code) {
      case 3:
        this.notifyMeService.showError('Timeout in Position capture', 'Mera APP V2');
        break;
      case 2:
        this.notifyMeService.showError('Device is not capable of Position capture', 'Mera APP V2');
        break;
      case 1:
        this.notifyMeService.showError('You denied for Position capture', 'Mera APP V2');
        break;
    }
  }

  
  SetapplicationLogData() {
    debugger
    let request = {
      "userid": this.UserID,
      "usertype": this.UserType,
      "formname": "ValuePerformance",
      "actiondesc": "Value Performance Data" +" "+ this.FieldName,
      "fieldname": this.FieldName,
      "fieldvalue": this.FieldValue,
      "latitude": this.lat,
      "longitude": this.lng
    }
    this.ds.SetapplicationLogData(request).subscribe(res => {
      console.log("res", res)
    })
  }

  loadData(): void {
    this.input.FieldName=this.FieldName;
    this.input.FieldValue=this.FieldValue;
    this.input.TagName=this.DataType;
    this.input.Datatype=this.DataType;
    this.input.UserID=this.UserID;
    debugger;
    this.mds.getTargetAchivementValue(this.input,this.token,this.device,this.deviceType,'Value Performance').subscribe(res => {
    this.valueperformancedata = res as ValuePerformancedata;
    debugger;
    this.periodList = [...new Set(res.map((bill: { period: any; }) => bill.period))].sort();
    this.isPc= this.FieldName.toUpperCase() =='RD' ? res[0].rdtype.toUpperCase()=='URBAN'?  true : false :  false;
     // this.selectedperiod = this.periodList[0];
    });
  }
  PeriodChange(e: any): void {
    this.selectedperiod =e.source._value;
  }
  filterItemsOfType(type: any) {

    return type != null ? type.filter((x: { [x: string]: any; }) => (x['period'] == this.selectedperiod)) : null;
  }
  RowClick(e:any): void{

    this.FieldName=this.FieldName.toUpperCase()=='ALLINDIA'?'BRANCH':this.FieldName.toUpperCase()=='BRANCH'?'REGION': this.FieldName.toUpperCase()=='REGION'?'ASMAREA': this.FieldName.toUpperCase()=='ASMAREA'?'SOTERRITORY' : this.FieldName.toUpperCase()=='SOTERRITORY'?'RD' : 'RD';
    if(this.FieldName=="RD")
    {
      let rdData = this.valueperformancedata.filter((singleItem: { [x: string]: string; }) => (singleItem['valuetype']==e && singleItem['period']==this.selectedperiod));
      localStorage.setItem("rdData", JSON.stringify(rdData));
      localStorage.setItem("selectedperiod",this.selectedperiod);
    }
    else {
      localStorage.setItem("rdData", '');
    }
    this.router.navigate(['/home/ValuePerformanceData/',this.FieldName,e,this.UserID,localStorage.getItem("DataType"),this.selectedperiod]);
  }

  openFilter(){
    if (this.dialog.openDialogs.length == 0) {
      const dialogRef = this.dialog.open(FilterComponent, {
        closeOnNavigation: true,
        data: {
          "FieldName": localStorage.getItem("OriginalFieldName"),
          "FieldValue": localStorage.getItem("OriginalFieldValue"),
          "UserID": this.UserID,
          "MenuUpTo": "5",
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.FieldName=result.FieldName;
          this.FieldValue=result.FieldValue;
          this.loadData();
              }
      });
    }
  }
}
