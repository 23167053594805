import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { DataService } from 'src/app/service/data.service';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-stock-inout-approval',
  templateUrl: './stock-inout-approval.component.html',
  styleUrls: ['./stock-inout-approval.component.css']
})
export class StockInoutApprovalComponent implements OnInit {
  deviceType: any;
  device: any;
  userType: string | null = '';
  userId: any;
  list: any;
  msg: any;
  private _searchText = '';
  filteredList: any;
  token: any;
  formname:any;
  actiondesc:any;
  fieldName: any;
  fieldValue: any;
  constructor(private activatedRoute: ActivatedRoute,private cs:CommonUtilitiesService, private router: Router, private headerService: HeaderService, private mds: MongoDataService, private notifyMeService:NotifyMeService, private ds: DataService,) { }

  ngOnInit(): void {
    this.headerService.setTitle('Stock In/Out');
    this.headerService.setActiveURl('/stockInoutApproval');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.userType = localStorage.getItem("UserType");
    this.userId = localStorage.getItem("UserID");
    this.token = localStorage.getItem("TokenNo");

    
    this.fieldName = localStorage.getItem("FieldName");
    this.fieldValue = localStorage.getItem("FieldValue");
    this.formname = "stockInoutApproval"
    this.actiondesc = "Stock In Out Approval";
    this.cs.GetGeoLocation(this.userId, this.userType, this.formname, this.actiondesc, this.fieldName, this.fieldValue);
    this.onLoad();
  }

  onLoad() {
    this.ds.getSTKInOutApprovalData(this.userId, this.userType, this.token, this.device, this.deviceType).subscribe(res => {
      this.list = res;
      this.filteredList = this.list;
    });
  }

  rowClick(data: any){
    localStorage.setItem("stockInoutDetails",JSON.stringify(data))
    this.router.navigate(['/home/stockInoutDetailsApproval']);
  }

  get searchTerm(): any{
    return this._searchText;
  }
  set searchTerm(value: any){
     this._searchText = value;
     this.filteredList = this.filteredListMethod(value);
  }

  filteredListMethod(value: any): any {
    return this.list.filter((ele: any) =>
      ele.rdName.toUpperCase().indexOf(value.toUpperCase()) !== -1);
  }
}
