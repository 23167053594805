import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/service/data.service';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { NotifyMeService } from 'src/app/service/notify-me.service';

@Component({
  selector: 'app-eligible-outlet-serach',
  templateUrl: './eligible-outlet-serach.component.html',
  styleUrls: ['./eligible-outlet-serach.component.css']
})
export class EligibleOutletSerachComponent implements OnInit {
  public GoBtnClicked = "false";
  deviceType: any;
  device: any;
  userType: string | null = '';
  userId: any;
  list: any;
  msg: any;
  private _searchText = '';
  searchText: any;
  token: any;
  FormName: any;
  public eligibleOutlet: any = [];
  public UIDData: any = "";
  lat:any;
  lng:any;
  FieldName:any;
  FieldValue:any;

  constructor(private headerService: HeaderService, private router: Router, 
    private notifyMeService: NotifyMeService,public ds: DataService, private toastr: ToastrService, public mds: MongoDataService) { }

  ngOnInit(): void {
    this.FormName = 'EligibleOutletSearch'
    this.headerService.setTitle('Eligible Outlet Search');
    this.headerService.setActiveURl('/EligibleOutletSearch');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.userId = localStorage.getItem("UserID");
    this.token = localStorage.getItem("TokenNo");
    this.FieldName = localStorage.getItem("FieldName");
    this.FieldValue = localStorage.getItem("FieldValue");
    this.GetGeoLocation();

  }

  
  GetGeoLocation() {
    debugger
    if (navigator) {
      navigator.geolocation.getCurrentPosition(pos => {
        this.lat = pos.coords.latitude;
        console.log("  this.lat", this.lat)
        this.lng = pos.coords.longitude;
        this.SetapplicationLogData();
      }, this.handleLocationError, { timeout: 30000 });
    }
    else {
      this.notifyMeService.showError('Location Could not be captured', 'Mera APP');
    }
  }

  handleLocationError(error: { code: any; }) {
    switch (error.code) {
      case 3:
        this.notifyMeService.showError('Timeout in Position capture', 'Mera APP V2');
        break;
      case 2:
        this.notifyMeService.showError('Device is not capable of Position capture', 'Mera APP V2');
        break;
      case 1:
        this.notifyMeService.showError('You denied for Position capture', 'Mera APP V2');
        break;
    }
  }

  SetapplicationLogData() {
    debugger
    let request = {
      "userid": this.userId,
      "usertype": this.userType,
      "formname": "EligibleOutletSerach",
      "actiondesc": "Eligible Outlet Search",
      "fieldname": this.FieldName,
      "fieldvalue": this.FieldValue,
      "latitude": this.lat,
      "longitude": this.lng
    }
    this.ds.SetapplicationLogData(request).subscribe(res => {
      console.log("res", res)
    })
  }


  GOBtn(UID: any) {
    if (UID.value == "") {
      this.toastr.warning("Please enter Outlet UID");
      return;
    }
    this.GoBtnClicked = "true";

    let request = {
      "outletcode": UID.value + ",",
      "userid": this.userId
    }

    this.ds.ATS_CheckEligibleOutletList(request, this.token).subscribe(res => {
      console.log("getEligibleOutletdetails", res)
      this.eligibleOutlet = res;
      console.log("eligibleOutlet", this.eligibleOutlet)

      if (this.eligibleOutlet[0].outletname == null) {
        this.toastr.warning("Outlet UID not found")
      }
    }, error => {
      this.toastr.warning("Outlet UID not found");
      // UID.value = "";
      this.eligibleOutlet = [];
    });
  }

  numberOnly(event: { which: any; keyCode: any; }): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

}
