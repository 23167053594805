import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { HeaderService } from 'src/app/service/header.service';
import { Location } from '@angular/common';
import { DataService } from 'src/app/service/data.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  public openQrCode = false;
  title = '';
  mobileQuery: MediaQueryList;
  IsShowNotification: boolean = false;
  private _mobileQueryListener: () => void;
  UserName: any;
  fillerNav = Array.from({ length: 50 }, (_, i) => `Nav Item ${i + 1}`);
  fillerOtherNav = [
    { id: "1", name: "Media", path: "assets/media.png", link: "/home/Media" },
    { id: "2", name: "Feedback", path: "assets/feedback.png", link: "/home/Feedback" },
    { id: "3", name: "FAQ", path: "assets/faq.png", link: "/home/faqlist" },
    { id: "5", name: "Certificate", path: "assets/faq.png", link: "/home/certificate" },
    // { id: "6", name: "Other Applications", path: "assets/other_App_Icon.png", link: "/home/otherApplications" },
    { id: "7", name: "Logout", path: "assets/logout_new.png", link: "/login" },
  ];
  pageName: any;
  activeURl: string = '';
  userType: string | null = '';
  isImage: boolean = false;
  image: any;
  userId: any;
  profileImage: any;
  UserID: any;
  device: any;
  deviceType: any;
  FieldName: any;
  FieldValue: any;
  getPermissionList: any;
  token: any;
  clientID: any;

  constructor(private headerService: HeaderService, changeDetectorRef: ChangeDetectorRef, media: MediaMatcher, private activatedRoute: ActivatedRoute, private router: Router, private location: Location, private ds: DataService, private mds: MongoDataService,) {
    this.mobileQuery = media.matchMedia('(max-width: 2600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }
  GetNotofication(): void {
    this.ds.GetNotificationData(this.UserID, '0', "Meraapp", 0, this.token, this.clientID, this.device, this.deviceType, 'Header').subscribe(res => {
      if (res.length > 0) {
        this.IsShowNotification = true;
      }
      else {
        this.IsShowNotification = false;
      }


    });
  }
  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  ngOnInit(): void {
    this.device = localStorage.getItem('Device');
    this.deviceType = localStorage.getItem('DeviceType');
    this.UserID = localStorage.getItem("UserID");
    this.userType = localStorage.getItem("UserType");
    this.userId = localStorage.getItem("UserID");
    this.UserName = localStorage.getItem("UserName");
    this.FieldName = localStorage.getItem("FieldName");
    this.FieldValue = localStorage.getItem("FieldValue");
    this.token = localStorage.getItem("TokenNo");
    this.clientID = localStorage.getItem("ClientID");

    this.GetNotofication();
    if (this.userType == 'gm') {
      this.userType = 'ho';
    }

    this.headerService.title.subscribe(title => {
      this.title = title;
    });

    this.headerService.activeURl.subscribe((activeURl: string) => {
      this.activeURl = activeURl;
    });
    debugger
    this.headerService.profileImage.subscribe((profileImage: string) => {
      this.image = profileImage;
      if (this.image != "") {
        this.isImage = true;
      } else {
        this.isImage = false;
      }
    });
    this.profileImageDownload();
    this.getPermissionData();
  }
  notificationform() {
    this.router.navigate(['/home/Notoficationlist']);

  }
  goBack() {
    if (this.activeURl == '/HealthstauslistPC') {
      this.router.navigate(['/Healthstatus']);
    } 
    else if(this.activeURl == '/outletNameChangeRequest'){
      this.router.navigate(['/home']);
    } 
    else if(this.activeURl == '/ticketLogList'){
      this.router.navigate(['/home']);
    }else {
      this.location.back();
    }
  }
  Gonotification() {
    this.router.navigate(['/home/notifications']);

  }
  Gopraise() {
    this.router.navigate(['/home/Praise']);
  }


  sideNavClick(name: string) {
    if (name == 'Logout') {
      localStorage.clear();
      sessionStorage.clear()
      this.router.navigate(['login']);

    } else if (name == 'Status Report') {
      this.router.navigate(['/home/HealthstatusRpt/', this.FieldName, this.FieldValue, "ALLINDIA", this.UserID]);
    }
  }

  profileImageDownload() {
    // const dataToSend = {
    //   UserID: this.userId,
    // };
    // this.ds.profileImageDownload(dataToSend, this.token, this.device, this.deviceType, 'Header').subscribe((res) => {
    //   this.createImageFromBlob(res.body)
    //   // this.selectedFile = res.body;
    //   // this.preview();
    // }, (err) => {
    //   this.isImage = false;
    // });

    let extension = ".jpg"
    let imgURL = this.userId + extension;

    this.ds.getDownloadFileFromS3Bucket("Profile", imgURL).subscribe((res) => {
      this.createImageFromBlob(res.body)
    }, (error) => {
      console.log("error", error);
      this.isImage = false;
    });
  }

  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      this.image = reader.result;
      if (this.image != '') {
        this.isImage = true;
      } else {
        this.isImage = false;
      }
    }, false);
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  getPermissionData() {
    this.mds.getpermission(this.userId, 'health report', this.token, this.device, this.deviceType, 'Header').subscribe(res => {
      this.getPermissionList = res;
      if (this.getPermissionList.length > 0) {
        this.fillerOtherNav.splice(1, 0, { id: "4", name: "Status Report", path: "assets/status_report.svg", link: "" });
      }
    });
  }

}
