import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';

@Component({
  selector: 'app-market-working-beat-list',
  templateUrl: './market-working-beat-list.component.html',
  styleUrls: ['./market-working-beat-list.component.css']
})
export class MarketWorkingBeatListComponent implements OnInit {
  deviceType: any;
  device: any;
  userType: string | null = '';
  userId: any;
  list: any;
  filteredList: any = [];
  firMenuCountList: any;
  primarynos: any;
  secondarynos: any;
  token: any;
  _searchText = '';
  pcListData: any;
  public beatuid: any;
  getOutletCountData: any = {};
  curYear: any;
  curMonth: any;
  myDate: any;
  todaysDate:any;
  myBooks: any;
  userList1: any;
  lastkeydown1: number = 0;
  listOrigin: any = [];
  selectedText: any;
  formname:any;
  actiondesc:any;
  constructor(private router: Router, private cs:CommonUtilitiesService,private headerService: HeaderService, private mds: MongoDataService,private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.headerService.setTitle('Market Ka Saathi');
    this.headerService.setActiveURl('/marketWorkingBeatList');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.userType = localStorage.getItem("UserType");
    this.userId = localStorage.getItem("UserID");
    this.token = localStorage.getItem("TokenNo");
    let pcListData: any = localStorage.getItem("marketWorkingPCListData");
    this.pcListData = JSON.parse(pcListData);
    console.log("this.pcListData", this.pcListData);
    this.myDate = Date.now();
    let date = new Date();
    this.todaysDate = date.getDate();
    this.curMonth = this.datePipe.transform(this.myDate, 'MMM');
    this.curYear= JSON.stringify(new Date().getFullYear());
    this.formname="MarketWorkingBeatList";
    this.actiondesc="Market Working Beat List";
    this.cs.GetGeoLocation(this.userId,this.userType,this.formname,this.actiondesc,"PC",this.pcListData.valuetype);
    this.loadData();
    setTimeout(() => {
      this.MarketWorking_GetOutletData();
    }, 1000)
  }

  loadData() {
    let request =
    {
      "FieldName": "PC",
      "FieldValue": this.pcListData.valuetype,
      "Datatype": "",
      "TagName": "",
      "UserID": this.userId
    }

    this.mds.MarketWorking_GetBeatTargetData(request, this.token, this.device, this.deviceType, 'Market Ka Sathi Beat List').subscribe(res => {
      this.list = res;
      this.filteredList = this.list;
      this.listOrigin = this.list = this.filteredList
      // this.fillAutoCompleteSearchBox()
      console.log("this.beatList", this.filteredList)
    });

  }

  MarketWorking_GetOutletData() {
    console.log("this.filteredList", this.filteredList)
    for (let ele in this.list) {
      let request:any =
      {
        "FieldName": "beat",
        "FieldValue": this.list[ele].beatuid.toString(),
        "Datatype": "",
        "TagName": "",
        "UserID": this.userId
      }
      this.mds.MarketWorking_GetOutletData(request, this.token, this.device, this.deviceType, 'Market Ka Sathi Get Outlet Data Of Selected Beat').subscribe(res => {
        this.getOutletCountData[ele]=res.outletcount;
        console.log("getOutletCountData", this.getOutletCountData)
      });
    }
  }

  get searchTerm(): any {
    return this._searchText;
  }
  set searchTerm(value: any) {
    this._searchText = value;
    this.filteredList = this.filteredListMethod(value);
  }

  filteredListMethod(value: any): any {
    return this.list.filter((ele: any) =>
      ele.beatname.toUpperCase().indexOf(value.toUpperCase()) !== -1);
  }

  clickOnBeatList(item: any) {
    localStorage.setItem("marketWorkingBeatListData", JSON.stringify(item))
    this.router.navigate(['/home/MarketWorkingBeatListData']);
  }

   
  // fillAutoCompleteSearchBox() {
  //   this.myBooks = [];
  //   let mybook1: any = [...new Set(this.listOrigin.map((bill: { beatname: string; beatuid: number; }) => (bill.beatname + ' [' + bill.beatuid + '] ')))];
  //   this.myBooks = [].concat.apply([], mybook1);

  // }
  // getUserIdsFirstWay($event: any) {
  //   let userId = (<HTMLInputElement>document.getElementById('userIdFirstWay')).value;
  //   this.userList1 = [];
  //   if (userId.length > 2) {
  //     if ($event.timeStamp - this.lastkeydown1 > 200) {
  //       this.userList1 = this.searchFromArray(this.myBooks, userId);
  //     }
  //   }
  // }
  // searchFromArray(arr: any, regex: any) {
  //   let matches = [], i, j = 0;
  //   for (i = 0; i < arr.length; i++) {
  //     if (arr[i].toLowerCase().match(regex.toLowerCase())) {
  //       matches.push(arr[i]);
  //       j++;
  //       i = j < 10 ? i : arr.length;
  //     }
  //   }
  //   return matches;
  // };
  // SearchText(e: any): void {
  //   this.selectedText = e.target.value;
  //   let selectingType = this.selectedText.substring(this.selectedText.indexOf('[') + 1, this.selectedText.indexOf(']'));
  //   let item = this.listOrigin.filter((ele: any) => { return ele.beatuid == selectingType })[0];

  //   this.clickOnBeatList(item);
  //   (<HTMLInputElement>document.getElementById('userIdFirstWay')).value = '';
  // }


}
