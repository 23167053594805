import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { DataService } from '../../service/data.service';
import { HttpEventType, HttpClient, HttpHeaders } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  userName: string | null = '';
  isProfile: boolean = true;
  isGallery: boolean = false;
  emailID: string | null = '';
  mobileNo: string | null = '';
  editPhoneNo = this.fb.group({
    phoneNo: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
  });
  userId: any;
  selectedFile: any;
  public message: string = '';
  photourl: string = '';
  image: any;
  imgURL: any;
  isImage: boolean = false;
  device: any;
  deviceType: any;
  token: any;
  UserType: any;
  formname: any;
  actiondesc: any;
  FieldName: any;
  FieldValue: any;
  httpOptions: HttpHeaders =
    new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      // 'Content-Type': 'multipart/form-data',
      'enctype': 'multipart/form-data',
      'charset': 'UTF-8',
      'FolderName': 'Profile',
    })

  constructor(private activatedRoute: ActivatedRoute, private cs: CommonUtilitiesService, private router: Router, private headerService: HeaderService, private mds: MongoDataService, private modalService: NgbModal, private fb: FormBuilder, private ds: DataService, private http: HttpClient, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.headerService.setActiveURl('/profile');
    this.headerService.setTitle('Profile');
    this.userName = localStorage.getItem("UserName");
    this.userId = localStorage.getItem("UserID");
    this.emailID = localStorage.getItem("EmailID");
    this.mobileNo = localStorage.getItem("MobileNo");
    this.device = localStorage.getItem('Device');
    this.deviceType = localStorage.getItem('DeviceType');
    this.token = localStorage.getItem("TokenNo");
    this.UserType = localStorage.getItem("UserType");
    this.FieldName = localStorage.getItem("FieldName");
    this.FieldValue = localStorage.getItem("FieldValue");
    this.formname = "profile";
    this.actiondesc = "Profile";
    this.cs.GetGeoLocation(this.userId, this.UserType, this.formname, this.actiondesc, this.FieldName, this.FieldValue);
    this.profileImageDownload();
  }

  onClickImage(model: any) {
    // this.isProfile = false;
    // this.headerService.setTitle('Profile Pic');
    this.modalService.open(model,
      {
        size: 'lg',
        backdrop: 'static',
        windowClass: '',
        keyboard: false,
        centered: true,
      });
  }

  onClickEdit(model: any) {
    this.editPhoneNo.patchValue({
      phoneNo: this.mobileNo,
    });
    this.modalService.open(model,
      {
        size: 'lg',
        backdrop: 'static',
        windowClass: '',
        keyboard: false,
        centered: true,
      });
  }

  modalClose() {
    this.modalService.dismissAll();
  }

  save() {
    this.ds.updateUsersMobileNo(this.userId, this.editPhoneNo.value.phoneNo, this.token, this.device, this.deviceType, 'Profile').subscribe(res => {
      if (res[0].msg == "OK") {
        localStorage.setItem("MobileNo", this.editPhoneNo.value.phoneNo);
        this.mobileNo = localStorage.getItem("MobileNo");
        this.modalService.dismissAll();
      }
    });
  }

  onFileSelected(event: any) {
    debugger
    this.selectedFile = event.target.files[0];
    localStorage.setItem("selectedFile", JSON.stringify(this.selectedFile.name))
    const formData = new FormData();
    let extension = this.selectedFile.name.substring(this.selectedFile.name.indexOf('.'));
    if (extension != '.jpg') {
      extension = ".jpg";
    }
    this.imgURL = this.userId + extension;
    // formData.append('file', this.selectedFile, this.imgURL);

    // this.http.post(this.mds.url + 'MeraApp2/ProfileImageUpload/', formData, { reportProgress: true, observe: 'events' })
    //   .subscribe(event => {
    //     if (event.type === HttpEventType.UploadProgress) {
    //       // this.progress = Math.round(100 * event.loaded / event.total);
    //     } else if (event.type === HttpEventType.Response) {
    //       this.message = 'Upload success.';
    //       this.profileImageDownload();
    //       // this.preview();
    //     }
    //   });
    formData.append('file', this.selectedFile, this.imgURL);
    this.http.post(this.mds.url + 'MeraNet/UploadFileToS3Bucket', formData, { reportProgress: true, observe: 'events', headers: this.httpOptions, responseType: 'blob' as 'json' }).subscribe(res => {
      console.log("ATOBUploadFile", res)
      this.profileImageDownload()
    });
  }
  // }




  profileImageDownload() {
    // const dataToSend = {
    //   UserID: this.userId,
    // };
    // this.ds.profileImageDownload(dataToSend, this.token, this.device, this.deviceType, 'Profile').subscribe((res) => {
    //   this.createImageFromBlob(res.body)
    // }, (err) => {
    //   this.isImage = false;
    // });
    debugger

      let extension=".jpg"
      this.imgURL = this.userId + extension;
    this.ds.getDownloadFileFromS3Bucket("Profile", this.imgURL).subscribe((res) => {
      this.createImageFromBlob(res.body)
    }, (error) => { console.log("error", error) });
  }

  createImageFromBlob(image: Blob) {
    debugger
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      this.image = reader.result;
      this.headerService.setProfileImage(this.image);
      if (this.image != '') {
        this.isImage = true;
      } else {
        this.isImage = false;
      }
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }

  preview() {
    // Show preview
    var mimeType = this.selectedFile.type;
    console.log("mimeType", mimeType);

    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(this.selectedFile);
    reader.onload = (_event) => {
      this.image = reader.result;
      console.log("preview image", this.image);
    }
  }

}
