<app-header></app-header>
<div class="container-fluid">
<div class="row mt-5 note">
    <div class="col-12">&nbsp;
    </div>
    <div class="col-12">
            <span class="note_text">You have to update your team Field status to
                continue with app.</span>
    </div>
    <div class="col-12">&nbsp;
    </div>
</div>
<div class="row mt-3">
    <div class="col-12">
        <span class="heading">My Field Status</span>
    </div>
</div>
<div class="row mt-3">
    <div class="col-12">
        <mat-radio-group aria-label="Select an option">
            <mat-radio-button value="0" [checked]="selfupdated=='Y' && selfstatus==0 ? true :selfupdated=='Y' && selfstatus!=0 ? false : defaultchk=='first' ? true:false" (change)="StatusChange(statusobj[0],0)" >{{firstchk}}</mat-radio-button>&nbsp;&nbsp;&nbsp;&nbsp;
            <mat-radio-button value="1" [checked]="selfupdated=='Y' && selfstatus==1 ? true :selfupdated=='Y' && selfstatus!=1 ? false : defaultchk=='first' ? false:true"  (change)="StatusChange(statusobj[0],1)">{{secondchk}}</mat-radio-button>
        </mat-radio-group>
    </div>
</div>
<div class="row mt-5" *ngIf="FieldName.toUpperCase()=='ASMAREA'">
    <div class="col-6">
        <span class="heading">SE Status</span>
    </div>
    <div class="col-6 text-right">
        <a [class]="seupdated=='Y'? 'vlink':'link'" (click)="OnClickLink('SE')">{{seupdated=='Y'? 'Updated' :'Pending'}}</a>
    </div>
</div>
<div class="row mt-5" *ngIf="FieldName.toUpperCase()!='ASMAREA'">
    <div class="col-6">
        <span class="heading">RD Status</span>
    </div>
    <div class="col-6 text-right">
        <a [class]="rdupdated=='Y'? 'vlink':'link'" (click)="OnClickLink('RD')">{{rdupdated=='Y'? 'Updated' :'Pending'}}</a>
    </div>
</div>
<div class="row mt-5" *ngIf="FieldName.toUpperCase()!='ASMAREA'">
    <div class="col-6">
        <span class="heading">PC Status</span>
    </div>
    <div class="col-6 text-right">
        <a [class]="pcupdated=='Y'? 'vlink':'link'" (click)="OnClickLink('PC')">{{pcupdated=='Y'? 'Updated' :'Pending'}}</a>
    </div>
</div>
</div>
<div class="footer" *ngIf="(UserType == 'asm' && seupdated=='Y') || (UserType == 'so' && rdupdated=='Y' && pcupdated=='Y')">
    <div class="row text-center mt-3">
      <div class="col-12" (click)="btnClick()">
        CONTINUE
      </div>
    </div>
  </div>
  <!-- <app-footer></app-footer> -->
