<div class="container" style="padding: 16em 1em;text-align: center;">
    <div class="row">
        <div class="col-12">
            <img class="img-fluid img_thumb text-center" src="assets\icons\thumb.svg" />
        </div>
    </div>

    <div class="row mt-3">
        <div class="col-12">
            <span style="font-weight: 500;font-size: 16px;">Name Change Request submitted via Link</span>
        </div>
    </div>

    <div class="row mt-3">
        <div class="col-12">
            <span style="font-size: 16px;">Outlet Name Change request with Mondelez has been submitted.</span>
        </div>
    </div>
<!-- </div> -->

<div class="footer">
    <div class="row">
        <div class="col-12">
            <button type="button" class="btn_Cancel" (click)="closeBtnClick()">CLOSE</button>
        </div>
    </div>
</div>