import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { DataService } from 'src/app/service/data.service';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';
import { Enclass, ResultClass } from 'src/app/models/encryption-class';
import { EncryptionService } from 'src/app/service/encryption.service';
import { ToastrIconClasses, ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-outlet-name-change-approval-list',
  templateUrl: './outlet-name-change-approval-list.component.html',
  styleUrls: ['./outlet-name-change-approval-list.component.css']
})
export class OutletNameChangeApprovalListComponent implements OnInit {
  deviceType: any;
  device: any;
  userType: string | null = '';
  userId: any;
  list: any;
  msg: any;
  private _searchText = '';
  filteredList: any;
  token: any;
  formname:any;
  actiondesc:any;
  fieldName: any;
  fieldValue: any;
  secretKey: any;
  clientId: any;
  public enclass: Enclass = new Enclass();
  public resultClass: ResultClass = new ResultClass();
  constructor(private activatedRoute: ActivatedRoute, private router: Router,private cs:CommonUtilitiesService,
     private headerService: HeaderService,private toastr:ToastrService,
     private es: EncryptionService, private mds: MongoDataService, private notifyMeService:NotifyMeService, private ds: DataService,) { }

  ngOnInit(): void {
    this.headerService.setTitle('Outlet Name Change Approval');
    this.headerService.setActiveURl('/OutletNameChangeApprovalList');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.token = localStorage.getItem("TokenNo");
    this.userType = localStorage.getItem("UserType");
    this.userId = localStorage.getItem("UserID"); 
    this.fieldName = localStorage.getItem("FieldName");
    this.fieldValue = localStorage.getItem("FieldValue");

    this.formname="OutletNameChangeApprovalList";
    this.actiondesc="Outlet Name Change Approval"

    this.secretKey = localStorage.getItem("SecreteKey")
    this.clientId = localStorage.getItem("ClientID");
    this.cs.GetGeoLocation(this.userId,this.userType,this.formname,this.actiondesc,this.fieldName,this.fieldValue);

    this.onLoad();
  }

  onLoad() {
    this.mds.getUserDetails(this.userId, this.token, this.device, this.deviceType,'Outlet Name Change Approve List').subscribe(res => {
      this.userType=res[0].userType.toUpperCase();
      this.afterLoad()
    });
  }

  afterLoad() {
   debugger
    let request = {
      "UserID":this.userId,
      "UserType":this.userType
    }

    // this.es.WordKey = this.secretKey
    // this.es.Word = JSON.stringify(request);
    // this.enclass.buggerstring = this.es.encrypt(this.secretKey)

    this.es.Word = this.es.WordKey;
    this.es.encrypt(this.secretKey);
    this.es.Word = JSON.stringify(request);
    this.enclass.buggerstring = this.es.encrypt(this.secretKey)

    this.mds.MA_GetDataForApproval(this.enclass, this.token, this.device, this.deviceType, this.formname, this.clientId).subscribe(res => {
      this.resultClass = res;
      this.es.Word = this.resultClass.resultString;
      let response = JSON.parse(this.es.decrypt(this.secretKey));

      console.log("response***", response)

      this.list = this.filteredList = response;
      console.log("filteredList", this.filteredList)

      if (this.filteredList?.length==0) {
        this.toastr.warning("Data not available")
      }
    }, (error) => {
      console.log("error", error)
      
      // this.filteredList=[{
      //   "OldOutletName":"OldOutletName",
      //   "OutletCode":"OutletCode",
      //   "RDName":"RDName",
      //   "RDCode":"RDCode"
      // }]
    });

  }


  rowClick(data:any){
    sessionStorage.setItem("outletNameChangedApprovalListDetails",JSON.stringify(data))
    this.router.navigate(['/home/OutletNameChangeApprovalListDetails']);
  }

  get searchTerm(): any{
    return this._searchText;
  }
  set searchTerm(value: any){
     this._searchText = value;
     this.filteredList = this.filteredListMethod(value);
  }

  filteredListMethod(value: any): any {
    return this.list.filter((ele: any) =>(
      ele.OldOutletName?.toUpperCase().indexOf(value?.toUpperCase()) !== -1 || ele.OutletCode?.toUpperCase().indexOf(value?.toUpperCase()) !== -1));
  }


}
