import { Component, OnInit } from '@angular/core';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';
import { HeaderService } from 'src/app/service/header.service';

@Component({
  selector: 'app-c',
  templateUrl: './category-performance.component.html',
  styleUrls: ['./category-performance.component.css']
})
export class CategoryPerformanceComponent implements OnInit {
  formname:any;
  actiondesc:any;
  FieldName:any;
  FieldValue:any;
  userType: any;
  userId: any;
  constructor(private headerService: HeaderService,private cs:CommonUtilitiesService,) { }

  ngOnInit(): void {
    this.headerService.setTitle('Category');
    this.userId = localStorage.getItem("UserID");
    this.userType = localStorage.getItem("UserType");
    this.FieldName = localStorage.getItem("FieldName");
    this.FieldValue = localStorage.getItem("FieldValue");
    this.formname="category";
    this.actiondesc="category"
    this.cs.GetGeoLocation(this.userId,this.userType,this.formname,this.actiondesc,this.FieldName,this.FieldValue);
  }

}
