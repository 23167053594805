<div>
  <div class="row mt-4" *ngFor="let item of list; let i = index;">
    <div class="col-12 mt-3">
      <div class="form-check">
        <input type="checkbox" class="form-check-input" value={{item.reasonName}} id="Check{{i}}" [(ngModel)]="list[i].checked" (change)="selected = i; selected_Reason($event)" [checked]="selected === i">
        <label class="form-check-label text_16 text_black" style="margin-top: .15rem;" for="Check{{i}}">{{item.reasonName }}</label>
      </div>
    </div>
  <div class="col-12  hr_line">&nbsp;</div>
  </div>
</div>
<div class="footer">
  <div class="row text-center mt-3">
    <div class="col-12" (click)="rowClick()">
      CONTINUE
    </div>
  </div>
</div>
<ng-template #openModalMsg let-modal>
  <div class="modal-header">
    <h4 class="modal-title text_color_head text_20" id="modal-basic-title">
      <!-- <img class="img-fluid" src="assets/thanks_icon.png" /> -->
      <span class="ml-3">Warning!</span>
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modalClose()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      <div class="row">
        <div class="col-12">
          <span class="text_16">{{msg}}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer" (click)="modalClose()">
    OK
  </div>
</ng-template>
