<div>
  <div class="row mt-4">
    <div class="col-8">
      <span class="text_20 text_black">Visicooler</span>
    </div>
    <div class="col-4 text-right mt-1">
      <span class="text_14 text_black text_bold">{{outletSelectedA.visiCooler}}</span>
    </div>
  </div>
  <form [formGroup]="visicColerForm" autocomplete="off" (ngSubmit)="onSubmit()">
    <div class="row mt-3">
      <div class="col-12">
        <input class="fullWidth" type="text" name="mobNo" id="mobNo" placeholder="Mobile No." maxlength="10"
          formControlName="mobNo" required (keypress)="numberOnly($event)" pattern="^[6-9]\d+$"
          [ngClass]="{ 'is-invalid': submitted  && f.mobNo?.errors }" #target>
        <div *ngIf="submitted && f.mobNo?.errors " class="invalid-feedback">
          <div *ngIf="f.mobNo.errors?.required">
            Mobile No is required.
          </div>
          <div *ngIf="f.mobNo.errors?.pattern">
            Mobile number should be starts from 6,7,8 or 9   
          </div>
          <div *ngIf="f.mobNo.errors?.minlength">
            Mobile No must be at least 10 digit.
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <input class="fullWidth" type="text" name="address1" id="add1" placeholder="Address 01"
          formControlName="address1" [ngClass]="{ 'is-invalid': f.address1.touched  && f.address1?.errors }">
        <div *ngIf="f.address1.touched  && f.address1?.errors" class="invalid-feedback">
          <div *ngIf="f.address1.errors?.required">
            Address1 No is required.
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <input class="fullWidth" type="text" name="address2" id="add2" placeholder="Address 02"
          formControlName="address2" [ngClass]="{ 'is-invalid': f.address2?.touched  && f.address2?.errors }">
        <div *ngIf="f.address2?.touched  && f.address2?.errors" class="invalid-feedback">
          <div *ngIf="f.address2.errors?.required">
            Address2 No is required.
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <input class="fullWidth" type="text" name="landmark" id="landMark" placeholder="Landmark"
          formControlName="landmark" [ngClass]="{ 'is-invalid': f.landmark.touched  && f.landmark?.errors }">
        <div *ngIf="f.landmark.touched  && f.landmark?.errors" class="invalid-feedback">
          <div *ngIf="f.landmark.errors?.required">
            Landmark No is required.
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <input class="fullWidth" type="text" name="pinCode" id="pinCode" placeholder="Pincode" formControlName="pinCode"
          (keypress)="numberOnly($event)" maxlength="6"
          [ngClass]="{ 'is-invalid': f.pinCode?.touched  && f.pinCode?.errors }">
        <div *ngIf="f.pinCode?.touched  && f.pinCode?.errors" class="invalid-feedback">
          <div *ngIf="f.pinCode.errors?.required">
            Pin Code No is required.
          </div>
          <div *ngIf="f.pinCode.errors?.minlength">
            Pin Code No must be at least 6 digit.
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-12">
        <input class="fullWidth" type="text" name="EMailID" id="EMailID" placeholder="Email ID"
          formControlName="EMailID" [ngClass]="{ 'is-invalid': submitted  && f.EMailID?.errors }">
        <div *ngIf="submitted  && f.EMailID?.errors" class="invalid-feedback">
          <!-- <div *ngIf="f.EMailID.errors?.required">
            Email Address is required.
          </div> -->
          <div *ngIf="f.EMailID.errors?.email">
            Email address should be valid.
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-12">
        <input class="fullWidth" type="text" name="AssetCOde" id="AssetCode" placeholder="Asset Code"
          formControlName="AssetCOde" [ngClass]="{ 'is-invalid': f.AssetCOde?.touched  && f.AssetCOde?.errors }">
        <div *ngIf="f.AssetCOde?.touched  && f.AssetCOde?.errors" class="invalid-feedback">
          <div *ngIf="f.AssetCOde.errors?.required">
            Asset Code is required.
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-12">
        <input class="fullWidth" type="text" name=" SerialNo" id="SerialNo" placeholder=" Serial No"
          formControlName="SerialNo" [ngClass]="{ 'is-invalid': f.SerialNo?.touched  && f.SerialNo?.errors }">
        <div *ngIf="f.SerialNo?.touched  && f.SerialNo?.errors" class="invalid-feedback">
          <div *ngIf="f.SerialNo.errors?.required">
            Serial No is required.
          </div>
        </div>
      </div>
    </div>
    <!-- </form> -->
    <div class="row mt-4">
      <div class="col-3">
        <img [src]="coolerPhoto" alt="Image" *ngIf="coolerPhoto" class="img-fluid">
        <div class="Rectangle" *ngIf="!coolerPhoto">
          <span class="Image">Image</span>
        </div>
      </div>
      <div class="col-7 mt-3">
        <span class="text_14 text_black">Cooler Photo</span>
      </div>
      <div class="col-2 mt-3">
        <img class="img-fluid" src="assets/camera.png" style="margin-top: -4px;" (click)="CoolerPhoto.click()" />
        <input #CoolerPhoto id="CoolerPhoto" type="file" accept="image/*" (change)="fileProgress($event,'CoolerPhoto')"
         style="display: none" />
      </div>
    </div>
    <hr>
    <div class="row mt-4">
      <div class="col-3">
        <img [src]="outletPhoto" alt="Image" *ngIf="outletPhoto" class="img-fluid">
        <div class="Rectangle" *ngIf="!outletPhoto">
          <span class="Image">Image</span>
        </div>
      </div>
      <div class="col-7 mt-3">
        <span class="text_14 text_black">Outlet Exterior Photo</span>
      </div>
      <div class="col-2 mt-3">
        <img class="img-fluid" src="assets/camera.png" style="margin-top: -4px;" (click)="OutletPhoto.click()" />
        <input #OutletPhoto id="OutletPhoto" type="file" accept="image/*" (change)="fileProgress($event,'OutletPhoto')"
           style="display: none" />
      </div>
    </div>

    <hr>
    <div class="row mt-4">
      <div class="col-3">
        <img [src]="outletIDPhoto" alt="Image" *ngIf="outletIDPhoto" class="img-fluid">
        <div class="Retangle" *ngIf="!outletIDPhoto">
          <span class="Image">Image</span>
        </div>
      </div>
      <div class="col-7 mt-3">
        <span class="text_14 text_black">Owner ID Proof</span>
      </div>
      <div class="col-2 mt-3">
        <img class="img-fluid" src="assets/camera.png" style="margin-top: -4px;" (click)="OutletIDPhoto.click()" />
        <input #OutletIDPhoto id="OutletIDPhoto" type="file" accept="image/*"
          (change)="fileProgress($event,'OutletIDPhoto')" style="display: none" />
      </div>
    </div>
    <hr>
    <div class="row mt-4">
      <!-- <div class="col-3">
      <img [src]="page1Photo" alt="Image" *ngIf="page1Photo" class="img-fluid">
      <div class="Rectangle" *ngIf="!page1Photo">
        <span class="Image">Image</span>
      </div>
    </div> -->
      <div class="col-10 mt-3">
        <span class="text_14 text_black">DPN/E-Agreement</span>
      </div>
      <div class="col-2 mt-3">
        <img class="img-fluid" src="assets\icons\eAgreement.svg"  (click)="EAgreementOtp(target)" style="margin-top: -4px;"/>
        <input style="display: none" />
      </div>
    </div>

    <hr>
    <br>
    <br>
    <br>
    <br>
    <br>
  </form>
</div>
<div class="footer" *ngIf="isValid && visicColerForm.valid">
  <div class="row text-center mt-3">
    <div class="col-12" (click)="rowClick()">
      SAVE
    </div>
  </div>
</div>

<ng-template #openModalThankYou let-modal>
  <div class="modal-header justify-content-center row">
    <div class="col-12 text-center">
      <h4 class="modal-title text_color_head text_20 text-center" id="modal-basic-title">
        <img class="img-fluid check_circle" src="assets\icons\check_circle.svg" style="margin-top: -4px;" />
      </h4>
    </div>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12 text-center text_16">
        OTP & Link for sign-in DPN/Agreement has been sent successfully to Mobile No.
        <div>
          {{mobileNo}}
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer justify-content-center ">
    <button type="button" class="btn openAtoB" (click)="openAtoBTransfer()">OK</button>
  </div>


</ng-template>


<ng-template #openConfirmationModal let-modal>
  <div class="modal-header justify-content-center row">
    <div class="col-12 text-center">
      <h4 class="modal-title text_color_head text_20 text-center" id="modal-basic-title">
        <img class="img-fluid check_circle" src="assets\icons\check_circle.svg" style="margin-top: -4px;" />
      </h4>
    </div>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12 text-center text_16">
        You will receive DPN/agreement OTP on Mobile no ( {{mobileNo}} ) .Click on cancel button if you want to change the Mobile no.
        <!-- Do you want to continue with ( {{mobileNo}} ) this mobile number? -->
        <div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer justify-content-center">
    <button type="" class="btn  okBtn"  (click)="A2BAgreeGetOTP()">OK</button>
    <button type="" class="btn cancleBtn" (click)="cancleA2BAgreeGetOtp(target)">Cancel</button>
  </div>
</ng-template>