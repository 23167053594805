<div class="container-fluid p-0">
    <div class="row mt-5">
        <div class="col-12" style=" color: #757575;">
            <i class="fa fa-caret-left">&nbsp;&nbsp;
                <span class="header_text">{{FieldValue=='0'? 'All India' : FieldValue=='ALLINDIA' ? 'All India':
                    FieldValue |titlecase}}</span></i>
        </div>
        <!-- <div class="col-4 text-right">
            <img src="../../../assets/filter.png" class="img-fluid" (click)="openFilter()" /><sup><i
                    class="fa fa-circle fa-sm" style="color: #eaab5e;font-size: 5px;"></i></sup>&nbsp;<span
                style="color: #724e8c;font-weight:400 ;font-size: 0.8125rem;">Filter</span>
        </div> -->
    </div>
    <br>

    <div class="row mt-2">
        <div class="col-4 p-0" style="text-align: center;">
            <img class="mb-1" src="../../../assets/rightVisitedIcon.svg">Right Visited
        </div>
        <div class="col-4 p-0">
            <img class="mb-1" src="../../../assets/wrongVisitedIcon.svg">Wrong Visited
        </div>
        <div class="col-4 p-0">
            <img class="mb-1" src="../../../assets/notVisitedIcon.svg">Not Visited
        </div>
    </div>

    <div class="row mt-4" *ngFor="let item of VData" (click)="RowClick(item.fieldName,item.fieldvalue)">
        <div class="col-12">
            <div class="row" >
                <div class="col-12" >
                    <span class="Head_text">{{item?.description}}</span>
                    <span   class="Head_text ms-2" [ngStyle]="{ 'opacity': item.description ? '0.4' : 'normal' }">{{item?.fieldvalue}}</span>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-3">
                    <div class="row">
                        <div class="col-12 text-center">
                            <h1 class="m-0 text_rdList_Percentage purpleColor">{{item?.Visitpercent}}%</h1>
                        </div>
                    </div>
                </div>
                <div class="col-9">
                    <div class="row mt-2">
                        <div class="col-12">
                            <div class="progress rounded-pill">
                                <div class="progress-bar bg_rightvisit" role="progressbar" [style.width.%]="item?.rightvisit"
                                    [aria-valuenow]="item?.rightvisit" aria-valuemin="0" aria-valuemax="100"></div>
                                <div class="progress-bar bg_wrongvisit" role="progressbar" [style.width.%]="item?.wrongvisit"
                                    [aria-valuenow]="item?.wrongvisit" aria-valuemin="0" aria-valuemax="100"></div>
                                <div class="progress-bar bg_tgt" role="progressbar" [style.width.%]="item?.notvisited"
                                    [aria-valuenow]="item?.notvisited" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-4 text-center p-0">
                            <img class="mb-1" src="../../../assets/rightVisitedIcon.svg">{{item?.rightvisit}}
                        </div>
                        <div class="col-4 text-center p-0">
                            <img class="mb-1" src="../../../assets/wrongVisitedIcon.svg">{{item?.wrongvisit}}
                        </div>
                        <div class="col-4 text-center p-0">
                            <img class="mb-1" src="../../../assets/notVisitedIcon.svg">{{item?.notvisited}}
                        </div>
                    </div>
                </div>
            </div>
            <hr class="hr_cust">
        </div>
    </div>
    <br><br><br><br><br><br><br>
</div>