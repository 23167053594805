<div class="row mt-2 bg_Color">
  <div class="col-12 mt-4">
    <span class="pc_Name">{{coldChainAuditDetails.name}}</span>
    <span class="ml-1 pc_Code">{{coldChainAuditDetails.code}}</span>
  </div>
  <div class="col-12 mt-2 mb-4">
    <span class="rd_Name">Audit Date</span>
    <span class="ml-1 rd_Code">{{coldChainAuditDetails.auditDate}}</span>
  </div>
</div>
<div *ngFor="let item of list; let i = index;">
  <div class="myDiv">
    <div class="container-fluid">
      <div class="row mt-3">
        <div class="col-12">
          <img
            [src]="photopath + coldChainAuditDetails.type + '_' + coldChainAuditDetails.code + '_' + item.questionID + '_' + coldChainAuditDetails.auditdate1 + '.jpg'"
            alt="Image" class="img_fluid user_Profile" (click) = "show(photopath + coldChainAuditDetails.type + '_' + coldChainAuditDetails.code + '_' + item.questionID + '_' + coldChainAuditDetails.auditdate1 + '.jpg')">
<!-- 
            <img [src]="url+'MeraNet/GetDownloadFileFromS3Bucket/Deploymentimages/' + outletCodeDetails.coolerPhoto"
                alt="Image" class="img-fluid float-end"> -->
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <span class="description">{{item.description}}</span>
        </div>
      </div>
      <div class="row mt-3 mb-3">
        <div class="col-4">
          <button class="btn btn_Approve" [class.myClassReject]="item.isReject" (click)="item.isReject = true;item.isApprove = false">Reject</button>
        </div>
        <div class="col-4">
          <button class="btn btn_Approve" [class.myClassApprove]="item.isApprove" (click)="item.isApprove = true;item.isReject = false">Approve</button>
        </div>
      </div>
    </div>
  </div>
</div>
<br>
<br>
<br>
<br>
<br>
<div class="footer">
  <div class="row">
    <div class="col-12" (click)="submit()">
      <button class="btn btn_Submit">SUBMIT</button>
    </div>
  </div>
</div>
<ng-template #openModalThankYou let-modal>
  <div class="modal-header">
    <h4 class="modal-title text_color_head text_20" id="modal-basic-title">
      <img class="img-fluid" src="assets/thanks_icon.png" />
      <span class="ml-3">Thank You</span>
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modalClose()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <span class="model_PopUp_Text">Cold Chain Audie Approved</span>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <span class="model_PopUp_Text">successfully for</span>
      </div>
    </div>
    <div class="row mt-4 mb-2">
      <div class="col-12">
        <span class="pc_Name_PopUp">{{coldChainAuditDetails.name}}</span>
        <span class="ml-2 pc_Code_PopUp">{{coldChainAuditDetails.code}}</span>
      </div>
    </div>
  </div>
  <div class="modal-footer" (click)="okay()">
    OKAY
  </div>
</ng-template>
<div [style.display]="showModal ? 'block' : 'none'" class="modal" id="imagemodal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel">Image preview</h4>
      </div>
      <div class="modal-body">
        <img [src]="selectedimagepath" id="imagepreview" style="width: 100%; height: 425px;" >
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal" (click) = "hide()">Close</button>
      </div>
    </div>
  </div>
  <br> <br> <br><br> <br>
</div>
