import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { DataService } from 'src/app/service/data.service';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';
@Component({
  selector: 'app-erods-voucher-details-approval',
  templateUrl: './erods-voucher-details-approval.component.html',
  styleUrls: ['./erods-voucher-details-approval.component.css']
})
export class ErodsVoucherDetailsApprovalComponent implements OnInit {
  deviceType: any;
  device: any;
  userType: string | null = '';
  userId: any;
  list: any = [1,2,3,4,5];
  fieldName: any;
  fieldValue: any;
  erodsApproval: any;
  erodsVoucherApproval: any;
  formname:any;
  actiondesc:any;

  constructor(private activatedRoute: ActivatedRoute,private cs:CommonUtilitiesService, private router: Router, private headerService: HeaderService, private mds: MongoDataService, private notifyMeService:NotifyMeService, private ds: DataService,) { }

  ngOnInit(): void {
    this.headerService.setTitle('Voucher Details');
    this.headerService.setActiveURl('/erodsVoucherDetailsApproval');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.userType = localStorage.getItem("UserType");
    this.userId = localStorage.getItem("UserID");
    this.erodsVoucherApproval = JSON.parse(localStorage.getItem("erodsVoucherApproval") || '{}');
    this.fieldName = localStorage.getItem("FieldName");
    this.fieldValue = localStorage.getItem("FieldValue");
    this.formname="erodsVoucherDetailsApproval";
    this.actiondesc="Erods Voucher Details Approval"
    this.cs.GetGeoLocation(this.userId,this.userType,this.formname,this.actiondesc,this.fieldName,this.fieldValue);
  }

  back() {
    this.router.navigate(['/home/erodsVoucherApproval']);
  }
}
