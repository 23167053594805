<div class="mt-4">
    <mat-tab-group dynamicHeight mat-align-tabs="center">
        <mat-tab label="Professional" class="ProfessionalTab">
            <!-- content1 -->
            <div class="row mt-4" *ngFor="let item of list" >
                <div class="col-12">
                    <div class="row">
                        <div class=" col-12">
                            <span class="PC-Name">PC Name</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class=" col-8">
                            <span class="PersonName">{{item.pcname}}</span>
                        </div>
                        <div class=" col-4 text-right">
                            <span class="PersonCode">{{item.pcCode}}</span>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <hr class="Divider" />
                </div>
                <div class="col-12 mt-3">
                    <div class="row">
                        <div class=" col-12">
                            <span class="PC-Name">RD Name</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class=" col-8">
                            <span class="PersonName">{{item.rdName}}</span>
                        </div>
                        <div class=" col-4 text-right">
                            <span class="PersonCode">{{item.rdcode}}</span>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <hr class="Divider" />
                </div>
                <div class="col-12  mt-3">
                    <div class="row">
                        <div class=" col-12">
                            <span class="PC-Name">PC Type</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class=" col-8">
                            <span class="PersonName">{{item.pctYpe}}</span>
                        </div>
                        <div class=" col-4">
                            <!-- <span class="PersonCode">846513</span> -->
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <hr class="Divider" />
                </div>
                <div class="col-12  mt-3">
                    <div class="row">
                        <div class=" col-12">
                            <span class="PC-Name">Reference</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class=" col-8">
                            <span class="PersonName">{{item.reference}}</span>
                        </div>
                        <div class="col-4 text-right">
                            <span class="PersonCode">{{item.referencePCUID}}</span>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <hr class="Divider" />
                </div>
                <div class="col-12  mt-3">
                    <div class="row">
                        <div class=" col-12">
                            <span class="PC-Name">Monthly Sallary</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class=" col-8">
                            <span class="PersonName">{{item.salary}}</span>
                        </div>
                        <div class=" col-4">
                            <!-- <span class="PersonCode">548123</span> -->
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <hr class="Divider" />
                </div>
                <div class="col-12  mt-3">
                    <div class="row">
                        <div class=" col-12">
                            <span class="PC-Name">Joining Date</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class=" col-8">
                            <span class="PersonName">{{item.jdate| date}}</span>
                        </div>
                        <div class=" col-4">
                            <!-- <span class="PersonCode">548123</span> -->
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <hr class="Divider" />
                </div>
                <div class="col-12  mt-3">
                    <div class="row">
                        <div class=" col-12">
                            <span class="PC-Name">Eligible for Incentive</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class=" col-8">
                            <span class="PersonName">{{item.eligibleForIncentive}}</span>
                        </div>
                        <div class=" col-4">
                            <!-- <span class="PersonCode">548123</span> -->
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <hr class="Divider" />
                </div>
                <div class="col-12  mt-3">
                    <div class="row">
                        <div class=" col-12">
                            <span class="PC-Name">Seller Type</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class=" col-8">
                            <span class="PersonName">{{item.sellerType}}</span>
                        </div>
                        <div class=" col-4">
                            <!-- <span class="PersonCode">548123</span> -->
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <hr class="Divider" />
                </div>
                <div class="col-12  mt-3">
                    <div class="row">
                        <div class=" col-12">
                            <span class="PC-Name">SKU Division</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class=" col-8">
                            <span class="PersonName">{{item.skudivision}}</span>
                        </div>
                        <div class=" col-4">
                            <!-- <span class="PersonCode">548123</span> -->
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <hr class="Divider" />
                </div>
                <div class="col-12  mt-3">
                    <div class="row">
                        <div class=" col-12">
                            <span class="PC-Name">Replacement PC Code</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class=" col-8">
                            <span class="PersonName">{{item.replacemetPCcode}}</span>
                        </div>
                        <div class=" col-4">
                            <!-- <span class="PersonCode">548123</span> -->
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <hr class="Divider" />
                </div>
                <div class="col-12  mt-3">
                    <div class="row">
                        <div class=" col-12">
                            <span class="PC-Name">Replacement PC UID</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class=" col-8">
                            <span class="PersonName">{{item.replacemetPCUID}}</span>
                        </div>
                        <div class=" col-4">
                            <!-- <span class="PersonCode">548123</span> -->
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <hr class="Divider" />
                </div>
            </div>
            <br> <br> <br> <br>
        </mat-tab>

        <mat-tab label="Personal">
            <!-- content2 -->

            <div class="row mt-3" *ngFor="let item of list" >
                <div class="col-6">
                    <!-- <img class="img-fluid user_Profile"  src="assets/profile_icon.png"/> -->
                    <img class="img-fluid user_Profile" [src]="photopathpc + item.photoPath " (click) = "show(photopathpc + item.photoPath)"/>
                </div>
                <div class="col-12 mt-3">
                    <div class="row">
                        <div class=" col-12">
                            <span class="PC-Name">Address</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class=" col-12">
                            <span class="PersonName">{{item.address}}</span>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <hr class="Divider" />
                </div>
                <div class="col-12 mt-3">
                    <div class="row">
                        <div class=" col-12">
                            <span class="PC-Name">Mobile No.</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class=" col-12">
                            <span class="PersonName">{{item.cellNo}}</span>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <hr class="Divider" />
                </div>
                <div class="col-12 mt-3">
                    <div class="row">
                        <div class=" col-12">
                            <span class="PC-Name">Date of Birth</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class=" col-12">
                            <span class="PersonName">{{item.dob| date}}</span>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <hr class="Divider" />
                </div>
                <div class="col-12 mt-3">
                    <div class="row">
                        <div class=" col-12">
                            <span class="PC-Name">Age</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class=" col-12">
                            <span class="PersonName">{{item.age}} Years</span>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <hr class="Divider" />
                </div>
                <div class="col-12 mt-3">
                    <div class="row">
                        <div class=" col-12">
                            <span class="PC-Name">Languages Known</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class=" col-12">
                            <span class="PersonName">{{item.lang}}</span>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <hr class="Divider" />
                </div>
                <div class="col-12 mt-3">
                    <div class="row">
                        <div class=" col-12">
                            <span class="PC-Name">Qualification</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class=" col-12">
                            <span class="PersonName">{{item.qualification}}</span>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <hr class="Divider" />
                </div>
                <div class="col-12 mt-3">
                    <div class="row">
                        <div class=" col-12">
                            <span class="PC-Name">Previous Company</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class=" col-12">
                            <span class="PersonName">{{item.prevComp}}</span>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <hr class="Divider" />
                </div>
                <div class="col-12 mt-3">
                    <div class="row">
                        <div class="col-6 mt-3 text-left">
                            <span class="PC-Name">ID Card</span>
                        </div>
                        <div class="col-6 text-right">
                            <!-- <img class="img-fluid ID_Card"  src="assets/LoginBackground.jpg"/> -->
                            <img class="img-fluid ID_Card" [src]="photopathcard + item.photoIDPath "/>
                        </div>
                    </div>

                </div>
                <div class="col-12">
                    <hr class="Divider" />
                </div>
                <br> <br> <br> <br>
            </div>
        </mat-tab>
    </mat-tab-group>
    <div  class="footer">
        <div class="row">
          <div class="col-6 pr-0" (click)="cancel()">
            <button class="btn btn_Cancel">REJECT</button>
          </div>
          <div class="col-6 pl-0" (click)="Ok()">
            <button class="btn btn_Ok">APPROVE</button>
          </div>
        </div>
      </div>
</div>
<br><br><br><br>
<div [style.display]="showModal ? 'block' : 'none'" class="modal" id="imagemodal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel">Image preview</h4>
      </div>
      <div class="modal-body">
        <img [src]="selectedimagepath" id="imagepreview" style="width: 100%; height: 425px;" >
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal" (click) = "hide()">Close</button>
      </div>
    </div>
  </div>
  <br> <br> <br><br> <br>

  <ng-template #openModalThankYou let-modal>
    <div class="modal-header">
      <h4 class="modal-title text_color_head text_20" id="modal-basic-title">
        <img class="img-fluid" src="assets/thanks_icon.png" />
        <span class="ml-3">Thank You</span>
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="modalClose()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row" *ngIf="!isReject">
        <div class="col-12">
          <span class="model_PopUp_Text">PC/TSI Details Approved Successfully</span>
        </div>
      </div>
      <div class="row" *ngIf="isReject">
        <div class="col-12">
          <span class="model_PopUp_Text">PC/TSI Details Rejected Successfully</span>
        </div>
      </div>
      <div class="row mt-4 mb-2">
        <!-- <div class="col-12">
          <span class="rd_Name_PopUp">{{stockInoutDetails.rdName}}</span>
          <span class="ml-2 rd_Code_PopUp">{{stockInoutDetails.rdCode}}</span>
        </div> -->
      </div>
    </div>
    <div class="modal-footer" (click)="okay()">
      OKAY
    </div>
  </ng-template>
</div>

