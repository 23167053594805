<div class="row mt-4">
  <div class="col-7" style="margin-top: 0.75rem;">
    <i class="fa fa-caret-left" style="color: #757575;">&nbsp;&nbsp;
      <span class="header_text">{{FieldValue=='0'? 'All India' : FieldValue=='ALLINDIA' ? 'All India': FieldValue
        |titlecase}}</span></i>
  </div>
  <div class="col-5 text-right">
    <mat-form-field appearance="outline" style="width: 90px">
      <mat-select id="period" name="period" (selectionChange)="PeriodChange($event)" [(value)]="selectedperiod">
        <mat-option value="" disabled>Select Month</mat-option>
        <mat-option *ngFor="let item of periodList" [value]="item">
          {{ item }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
<div class="row mt-3">
  <div class="col-6">
      <span class="year_Text">Year 2021</span>
  </div>
  <div class="col-6 text-right">
      <span class="year_Text">Productivity</span>
  </div>
</div>
<div class="row mt-4" *ngFor="let item of filterItemsOfTypeProductivity(productivitydata)">
  <div class="col-3">
      <span class="month_Text">{{item.fieldvalue}}</span>
  </div>
  <div class="col-7" >
    <div class="row mt-2">
      <div class="col-12">
        <div class="progress rounded-pill">
          <div class="progress-bar bg_sec" role="progressbar" [style.width.%]="item.prodper"
            aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-2 text-right">
      <span class="percent_Text">{{item.prodper}}</span>
  </div>
</div>
