import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { DataService } from 'src/app/service/data.service';
import { GetNewRLADashboard,InputPara } from 'src/app/models/kpi-models';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterComponent } from '../filter/filter.component';
import { MatDialog } from '@angular/material/dialog';
import { NotifyMeService } from 'src/app/service/notify-me.service';


@Component({
  selector: 'app-gate-meeting',
  templateUrl: './gate-meeting.component.html',
  styleUrls: ['./gate-meeting.component.css']
})
export class GateMeetingComponent implements OnInit {
  FieldName: any;
  FieldValue: any;
  DataType: any;
  UserID: any;
  gateMeeting: any;
  UserType: any;
  device: any;
  deviceType: any;
  input:InputPara=new InputPara();
  token: any;
  lat:any;
  lng:any;

  constructor(private activatedRoute: ActivatedRoute, private dialog: MatDialog,
    private fb: FormBuilder,private router: Router,
     private headerService: HeaderService, private ds: DataService,private notifyMeService: NotifyMeService) { }

  ngOnInit(): void {
    this.headerService.setActiveURl('/GateMeeting');
    this.headerService.setTitle("Gate Meeting");
    this.UserType = localStorage.getItem("UserType");
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.FieldValue=localStorage.getItem("FieldValue");
    this.FieldName=localStorage.getItem("FieldName");
    this.UserID = localStorage.getItem("UserID");
    this.token = localStorage.getItem("TokenNo");
    this.gateMeeting = [];
    this.DataType="0";
    this.GetGeoLocation();
    this.loadData();
  }

  GetGeoLocation() {
    debugger
    if (navigator) {
      navigator.geolocation.getCurrentPosition(pos => {
        this.lat = pos.coords.latitude;
        console.log("  this.lat", this.lat)
        this.lng = pos.coords.longitude;
        this.SetapplicationLogData();
      }, this.handleLocationError, { timeout: 30000 });
    }
    else {
      this.notifyMeService.showError('Location Could not be captured', 'Mera APP');
    }
  }

  handleLocationError(error: { code: any; }) {
    switch (error.code) {
      case 3:
        this.notifyMeService.showError('Timeout in Position capture', 'Mera APP V2');
        break;
      case 2:
        this.notifyMeService.showError('Device is not capable of Position capture', 'Mera APP V2');
        break;
      case 1:
        this.notifyMeService.showError('You denied for Position capture', 'Mera APP V2');
        break;
    }
  }

  SetapplicationLogData() {
    debugger
    let request = {
      "userid": this.UserID,
      "usertype": this.UserType,
      "formname": "GateMeeting",
      "actiondesc": "Gate Meeting",
      "fieldname": this.FieldName,
      "fieldvalue": this.FieldValue,
      "latitude": this.lat,
      "longitude": this.lng
    }
    this.ds.SetapplicationLogData(request).subscribe(res => {
      console.log("res", res)
    })
  }

  loadData(): void {
    this.input.FieldName='0';
    this.input.FieldValue='0';
    this.input.TagName="0";
    this.input.Datatype= this.DataType;
    this.input.UserID=this.UserID;
    console.log("input",this.input)
   this.ds.gateMeetingData(this.input,this.token,this.device, this.deviceType,'Gate Meeting').subscribe(res => {
    console.log("gateMeetingData",res)
      this.gateMeeting = res;
    });
  }

  RowClick(e:any): void{
    this.router.navigate(['/home/GateMeetingdata/',this.UserID,"1",this.FieldName=="ALLINDIA"? "INDIA":this.FieldName, this.FieldValue=="ALLINDIA"? "INDIA":this.FieldValue,e]);
  }

  openFilter(){
    if (this.dialog.openDialogs.length == 0) {
      const dialogRef = this.dialog.open(FilterComponent, {
        closeOnNavigation: true,
        data: {
          "FieldName": localStorage.getItem("OriginalFieldName"),
          "FieldValue": localStorage.getItem("OriginalFieldValue"),
          "UserID": this.UserID,
          "MenuUpTo": "3",
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.FieldName=result.FieldName;
          this.FieldValue=result.FieldValue;
          this.loadData();
              }
      });
    }
  }

}
