<div class="row mt-3">
    <div class="col-12">
        <span class="pc_Name">{{surveyApprovalDetails.outletname | titlecase}}
            ({{surveyApprovalDetails.outletcodeuid}})</span>
    </div>
</div>

<div class="row mt-3">
    <div class="col-12">
        <span class="sst_Code">Survey Date:</span>
        <span class="ml-1 pc_Code">{{surveyApprovalDetails.surveydate}}</span>
    </div>
</div>

<div class="row mt-2 ">
    <div class="col-12">Outlet Photo</div>
</div>
<div class="row mt-1">
    <div class="col-12">
        <img class="img_fluid mt-3 surveyImg"
            [src]="url+'MeraNet/GetDownloadFileFromS3Bucket/SurveyImages/' + surveyApprovalDetails.imagename"
            > {{surveyApprovalDetails.imagename}}
    </div>
</div>

<div class="footer">
    <div class="row">
        <div class="col-6 pr-0">
            <button class="btn btn_Cancel" data-bs-toggle="modal" data-bs-target="#rejectedReasonModal">Reject</button>
        </div>
        <div class="col-6 pl-0" (click)="saveRecords()">
            <button class="btn btn_APPROVE">APPROVE</button>
        </div>
    </div>
</div>

<div class="modal" id="rejectedReasonModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <span class="modal-title" id="exampleModalLabel">Reason</span>
            </div>
            <div class="modal-body">
                <div class="">
                    <input type="text" class="form-control" placeholder="Enter Rejected Reason"
                        [(ngModel)]="rejectedReason">
                </div>
                <div class="modal-footer">
                    <div class="row w-100">
                        <div class="col-6">
                            <button type="button" class="btn cancleBtn float-end"
                                data-bs-dismiss="modal">Cancel</button>
                        </div>

                        <div class="col-6">
                            <button type="button " class="btn applyBtn" data-bs-dismiss="modal"
                                (click)="Reject()">Save</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>