import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/service/data.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { OutputGetVisiAgrOTP, OutputAgreeDetails } from 'src/app/models/kpi-models';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';



@Component({
  selector: 'app-a2b-transfer',
  templateUrl: './a2b-transfer.component.html',
  styleUrls: ['./a2b-transfer.component.css']
})
export class A2bTransferComponent implements OnInit {
  @ViewChild('openDPNARGModal', { static: true }) elRefopenDataPrivacyAndAgreement: ElementRef | undefined;
  @ViewChild('openDPNModal', { static: true }) elRefDPNOpenModal: ElementRef | undefined;
  @ViewChild('openAGRModal', { static: true }) elRefARGOpenModal: ElementRef | undefined;
  @ViewChild('openModalAGRSignedin', { static: true }) elRefopenModalAGRSignedin: ElementRef | undefined;
  @ViewChild('openModalDPNSignedin', { static: true }) elRefopenModalDPNSignedin: ElementRef | undefined;
  constructor(private headerService: HeaderService, private modalService: NgbModal, private ds: DataService,
    private fb: FormBuilder, private activatedRoute: ActivatedRoute, private router: Router,private cs:CommonUtilitiesService,) { }

  loginForm = this.fb.group({
    otp: ['', [Validators.required]]
  });

  loginFormagr = this.fb.group({
    agrOTP: ['', [Validators.required]]
  });
  deviceType: any;
  device: any;
  TokenNo: any;
  ClientID: any;
  IsChecked: boolean = false;
  errormsg: any = '';
  IsOTP: boolean = false;
  KeyID: string | null = '';
  ID: any;
  token: any;
  public isAgreementStatus = false;
  public argDate = false;
  agrdetails: OutputAgreeDetails = new OutputAgreeDetails();
  DPNText: string | null = '';
  AgrText: string | null = '';
  statusobj: OutputGetVisiAgrOTP = new OutputGetVisiAgrOTP();
  IsError: boolean = false;
  dpnsubmiton: string | null | any = '';
  agrsubmiton: string | null | any = '';
  agrtype: string | null = '';
  outletSelectedB: any;
  outletSelectedA: any;
  public isSubmitted = true;
  userType:any;
  formname:any;
  actiondesc:any;
  FieldName:any;
  FieldValue:any;
  userID:any;
  ngOnInit(): void {
    this.headerService.setTitle('A to B Transfer');
    this.headerService.setActiveURl('/a2bTransfer');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.TokenNo = localStorage.getItem("TokenNo");
    this.ClientID = localStorage.getItem("ClientID");
    this.outletSelectedB = JSON.parse(localStorage.getItem("outletSelectedB") || '{}');
    this.outletSelectedA = JSON.parse(localStorage.getItem("outletSelectedA") || '{}');

    // this.KeyID=localStorage.getItem("keyID")

    debugger
    // this.activatedRoute.paramMap.forEach(params => {
    this.KeyID = localStorage.getItem("keyID");
    // if (this.KeyID != null) {
    //   if (this.KeyID.length > 0 && this.KeyID.length > 0) {
    //     debugger
    //     this.checkuserlink(this.KeyID);
    //   }
    // }

    // });
    this.userID = localStorage.getItem("UserID");
    this.userType = localStorage.getItem("UserType");
    this.FieldName = localStorage.getItem("FieldName");
    this.FieldValue = localStorage.getItem("FieldValue");
    this.formname="a2bTransfer";
    this.actiondesc="a2b Transfer"
    this.cs.GetGeoLocation(this.userID,this.userType,this.formname,this.actiondesc,this.FieldName,this.FieldValue);
    this.getAllAgreementDetails();
  }

  checkuserlink(e: any): void {
    debugger
    const dataToSend = {
      KeyID: e,
    };
    debugger
    this.ds.MA_A2BEAgreeCheckLink(dataToSend, this.token, this.device, this.deviceType, 'checkDPNAgreementLink').subscribe((res: any) => {
      debugger
      // console.log("MA_A2BEAgreeCheckLink res)
      this.statusobj = res;
      this.isSubmitted = true;
      //check for submiton date for DPN and Agreement
      if ((res[0].submitOn != "") && (res[1].submitOn != "")) {
        this.isAgreementStatus = true;
        this.isSubmitted = false;
        this.argDate = true;
        this.IsError = false;
        this.errormsg = res[0].result;
        this.dpnsubmiton = res[0].submitOn.split(" ")[0];
        this.agrsubmiton = res[1].submitOn;
        localStorage.setItem("AgrSubmitDate", this.dpnsubmiton);
        localStorage.setItem("AgrSubmitDate", this.agrsubmiton);
        this.openModal(this.elRefopenDataPrivacyAndAgreement);
      }
      //check submiton for DPN 
      if ((res[0].submitOn == "") && (res[0].agreementType == "DPN")) {
        this.errormsg = "";
        if (res[0].result == "OK") {
          this.IsError = false;
          this.ID = res[0].id;
          this.agrtype = 'DPN';
          this.openModal(this.elRefDPNOpenModal);
        }
        else {
          this.IsError = true;
          this.errormsg = res[0].result;
        }

      }
      //check submiton for Agreement
      else if ((res[1].submitOn == "") && (res[1].agreementType == "Agreement")) {
        this.errormsg = "";
        if ((res[1].result == "OK")) {
          this.IsError = false;
          this.ID = res[1].id;
          this.agrtype = 'Agreement';
          this.openModal(this.elRefARGOpenModal);
        }
        else {
          this.IsError = true;
          this.errormsg = res[1].result;
        }
      }
    });
  }

  openModal(modal: ElementRef<any> | undefined) {
    this.modalService.open(modal,
      {
        size: 'lg',
        backdrop: 'static',
        windowClass: '',
        keyboard: false,
        centered: true,
      })
  }
  modalClose() {
    this.modalService.dismissAll();
  }

  openDPNAgreement() {
    // this.openModal(this.elRefopenDataPrivacyAndAgreement);
    this.KeyID = localStorage.getItem("keyID");
    if (this.KeyID != null) {
      if (this.KeyID.length > 0 && this.KeyID.length > 0) {
        debugger
        this.checkuserlink(this.KeyID);
      }
    }
  }

  selected_Reason(e: any) {
    if (e.target.checked) {
      this.IsChecked = e.target.value;
    }
  }

  submitOTP(OTP: string, IsChecked: boolean) {
    debugger
    this.errormsg = '';
    if ((OTP == "") || (!IsChecked)) { this.IsOTP = false; IsChecked = false; }
    else {
      this.IsOTP = true;
      IsChecked = true;
      const dataToSend = {
        KeyID: this.KeyID,
        ID: this.ID,
        OTp: OTP,
        CallFrom: "API"
      };
      this.ds.MA_A2BEAgreeSetOtp(dataToSend, this.token, this.device, this.deviceType, 'MA_A2BEAgreeSetOtp').subscribe((res: any) => {
        this.statusobj = res;
        if (res[0].result == 'OK') {
          this.IsError = false;

          this.openModal(this.elRefopenModalDPNSignedin);

        }
        else {
          this.errormsg = res[0].result; this.IsError = true;
        }
      });
    }
  }

  submitagrOTP(OTP: string, IsChecked: boolean) {
    this.errormsg = '';
    if ((OTP == "") || (!IsChecked)) { this.IsOTP = false; IsChecked = false; }
    else {
      this.IsOTP = true; IsChecked = true;
      const dataToSend = {
        KeyID: this.KeyID,
        ID: this.ID,
        OTp: OTP,
        CallFrom: "API"
      };
      this.ds.MA_A2BEAgreeSetOtp(dataToSend, this.token, this.device, this.deviceType, 'MA_A2BEAgreeSetOtp').subscribe((res: any) => {
        console.log("MA_A2BEAgreeSetOtp", res)
        this.statusobj = res;
        if (res[0].result == 'OK') {
          this.IsError = false;

          this.openModal(this.elRefopenModalAGRSignedin);
        }
        else {
          this.errormsg = res[0].result; this.IsError = true;
        }
      });
    }
  }


  selected_agrcheckbox(e: any) {
    if (e.target.checked) {
      this.IsChecked = e.target.value;
    }
  }

  getAllAgreementDetails() {
    this.ds.MA_A2BEAgreeGetAgreementdetails(this.token, this.device, this.deviceType, 'MA_A2BEAgreeGetAgreementdetails').subscribe((agrdtls: any) => {
      console.log("MA_A2BEAgreeGetAgreementdetails", agrdtls)
      debugger
      this.agrdetails = agrdtls;
      this.AgrText = agrdtls[0].details;
      this.DPNText = agrdtls[1].details;
    });

  }

  okay() {
    this.modalService.dismissAll();
    this.checkuserlink(this.KeyID);
  }

  saveDpnArgData() {
    debugger

    // let outletSelectedA:any = localStorage.getItem("outletSelectedA");
    // let outletSelectedB:any = localStorage.getItem("outletSelectedB");

    // let obj = {
    //   "UserID": localStorage.getItem("UserID"),
    //   "FromOutletCode": JSON.parse(outletSelectedA).outletcode,
    //   "ToOutletCode": JSON.parse(outletSelectedB).outletcode,
    //   "FromRDCode": JSON.parse(outletSelectedA).rdcode,
    //   "ToRDCode": JSON.parse(outletSelectedB).rdcode,
    //   "FromVisiCooler1": JSON.parse(outletSelectedA).visicooler,
    //   "ToVisiCooler1": JSON.parse(outletSelectedB).visicooler,
    //   "Flag": "Save",
    //   "ID": 0,
    //   "MobileNo": localStorage.getItem("MobileNo"),
    //   "Add1": this.ds.visicColerForm?.value["address1"],
    //   "Add2": this.ds.visicColerForm?.value["address2"],
    //   "Landmark":  this.ds.visicColerForm?.value["landmark"],
    //   "PinCode": this.ds.visicColerForm?.value["pinCode"],
    //   "Visiid": 0,
    //   "FIRID": 0,
    //   "EmailID": this.ds.visicColerForm?.value["EMailID"],
    //   "AssetCode":  this.ds.visicColerForm?.value["AssetCOde"],
    //   "SerialNo":  this.ds.visicColerForm?.value["SerialNo"],
    //   "AgreementSubmitDate": this.agrsubmiton
    // }
    // this.ds.ATS_SaveAtoBtransfer(obj).subscribe(res => {
    //   debugger
    //   console.log("res**********",res)
    //   if(res[0].msg.toLowerCase()=="ok"){
    this.router.navigate(['/home/atobTransferPreview']);
    // }
    // })
  }

  backToOutletDetail() {
    this.router.navigate(['/home/outletDetailTwoAtob']);
  }

  resendOtp() {
    let obj = {
      "OutletCode": this.outletSelectedB.outletcode.toString(),
      "OutletCodeRD": this.outletSelectedB.outletcoderd,
      "MobileNo": localStorage.getItem("visicColerForm_MobNo"),
      "RDCode": this.outletSelectedB.rdcode.toString(),
      "Visicooler": this.outletSelectedB.visicooler,
      "UserID": localStorage.getItem("UserID"),
      "ISResent": "Y"
    }

    this.ds.MA_A2BAgreeGetOTP(obj).subscribe(res => {
      debugger
      // console.log("res",res)
      if (res[0].result.toLowerCase() == 'ok' &&
        res[1].result.toLowerCase() == 'ok') {
        localStorage.setItem("keyID", res[0].keyID);
        // this.ds.visicColerForm = this.visicColerForm;
        // document.getElementById('EAgreementOtpModalBtn')?.click();
        // this.openModal(this.elRefOpenModalThankYou);
      }
      // else {
      //   this.notifyMeService.showWarning(res[0].result, 'Sales Genie App');
      // }
    },
      error => {
        console.log("error:Invalid API")
      },
    )
  }
}
