import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { DataService } from 'src/app/service/data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-stock-inout-voucher-details-approval',
  templateUrl: './stock-inout-voucher-details-approval.component.html',
  styleUrls: ['./stock-inout-voucher-details-approval.component.css']
})
export class StockInoutVoucherDetailsApprovalComponent implements OnInit {
  deviceType: any;
  device: any;
  userType: string | null = '';
  userId: any;
  stockInoutDetails: any;
  list: any;
  totalAmt: any = 0;
  token: any;
  formname:any;
  actiondesc:any;
  fieldName: any;
  fieldValue: any;

  constructor(private activatedRoute: ActivatedRoute,private cs:CommonUtilitiesService, private router: Router, private headerService: HeaderService, private mds: MongoDataService, private ds: DataService, private modalService: NgbModal, private datePipe: DatePipe,) { }

  ngOnInit(): void {
    this.headerService.setTitle('In/Out Voucher Details');
    this.headerService.setActiveURl('/stockInoutVoucherDetailsApproval');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.token = localStorage.getItem("TokenNo");
    this.userType = localStorage.getItem("UserType");
    this.userId = localStorage.getItem("UserID");
    this.stockInoutDetails = JSON.parse(localStorage.getItem("stockInoutDetails") || '{}');

    this.fieldName = localStorage.getItem("FieldName");
    this.fieldValue = localStorage.getItem("FieldValue");
    this.formname = "stockInoutVoucherDetailsApproval"
    this.actiondesc = "Stock In Out Voucher Details Approval";
    this.cs.GetGeoLocation(this.userId, this.userType, this.formname, this.actiondesc, this.fieldName, this.fieldValue);
    this.onLoad();
  }

  onLoad() {
    this.ds.getStkInOutDetails(this.stockInoutDetails.trnNo, this.stockInoutDetails.rdCode, this.token, this.device, this.deviceType, 'stockInoutVoucherDetailsApproval').subscribe(res => {
      this.list = res;
      if (this.list.length > 0) {
        this.list.forEach((ele: { amt: any; }) => {
          this.totalAmt += ele.amt;
        });
      }
    });
  }
}
