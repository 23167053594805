  <div class="row mt-4">
      <div class="col-9">
          <i class="fa fa-caret-left" style="color: #757575;">&nbsp;&nbsp;
              <span class="header_text">{{FieldValue=='0'? 'All India' : FieldValue=='ALLINDIA' ? 'All India': FieldValue |titlecase}}</span></i>
      </div>
      <div class="col-3 text-right" (click)="openFilter()">
          <img src="../../../assets/filter.png" class="img-fluid"  /><sup><i
                  class="fa fa-circle fa-sm" style="color: #eaab5e;font-size: 5px;"></i></sup>&nbsp;<span
              style="color: #724e8c;font-family: Roboto-Medium;font-weight:400 ;font-size: 14px;">Filter</span>
      </div>
  </div>
  <br>
  <div class="row mt-2">
      <div class="col-3">
          <mat-form-field appearance="outline" style="width: 90px">
              <mat-select id="period" name="period" (selectionChange)="PeriodChange($event)" [(value)]="selectedperiod">
                  <mat-option value="" disabled>Select Month</mat-option>
                  <mat-option *ngFor="let item of periodList" [value]="item">
                      {{ item }}
                  </mat-option>
              </mat-select>
          </mat-form-field>
      </div>
      <div class="col-3">
      <mat-form-field appearance="outline" style="width: 90px">
        <mat-select id="month" name="month" (selectionChange)="outletrangeChange($event)" [(value)]="selectedOutletRange">
            <mat-option value="" disabled>Select</mat-option>
            <mat-option *ngFor="let item of outletrangeListForVP" [value]="item">
              {{ item }}
            </mat-option>
          </mat-select>
      </mat-form-field>
    </div>
      <div class="col-3">
        <mat-form-field appearance="outline" style="width: 90px">
            <mat-select id="month" name="month" (selectionChange)="billslabChange($event)" [(value)]="selectedbillRange">
                <mat-option value="" disabled>Select</mat-option>
                <mat-option *ngFor="let item of billslabListForVP" [value]="item">
                  {{ item }}
                </mat-option>
              </mat-select>
        </mat-form-field>
      </div>
      <div class="col-3">
        <mat-form-field appearance="outline" style="width: 90px">
          <mat-select id="month" name="month" (selectionChange)="popslabChange($event)" [(value)]="selectedpopslab">
            <mat-option value="" disabled>Select</mat-option>
            <mat-option *ngFor="let item of popslabListForVP" [value]="item">
              {{ item }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      </div>
      
      <div class="row mt-2">
      <div class="col-12 text-right">
          <i class="fa fa-circle fa-sm" style="color: #eaab5e; font-size: 6px;">&nbsp;&nbsp;<span
                  class="Secondary_text">Billed Villages</span></i>&nbsp;&nbsp;&nbsp;
          <i class="fa fa-circle fa-sm" style="color: #f4d5ae; font-size: 6px;">&nbsp;&nbsp;<span
                  class="Secondary_text">Total Villages</span></i>
      </div>
  </div>
  <br>
  <div>
      <div class="row mt-2" *ngFor="let item of filterItemsOfTypeUrjaaVP(vpdata)" (click)="RowClick(item.fieldname, item.fieldvalue, item)">
          <div class="col-12">
              <div class="row">
                  <div class="col-12">
                      <span class="header_text">{{item.desc + ' ' + item.valuetype | titlecase}}</span>
                  </div>
              </div>
              <div class="row">
                  <div class="col-3">
                      <div class="row mt-2">
                          <div class="col-12">
                              <span class="Value_text">{{item.percentage}}%</span>
                          </div>
                      </div>
                  </div>
                  <div class="col-9">
                      <div class="row mt-2">
                          <div class="col-12">
                              <div class="progress rounded-pill">
                                  <div class="progress-bar bg_sec" role="progressbar" [style.width.%]="item.billedvillages"
                                      aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                  <div class="progress-bar bg_pri" role="progressbar" [style.width.%]="item.totalvillages"
                                      aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                          </div>
                      </div>
                      <div class="row mt-2">
                          <div class="col-6 text-center">
                              <span class="text_14">{{item.billedvillages}}</span>
                          </div>
                          <div class="col-6 text-center">
                              <span class="text_14">{{item.totalvillages}}</span>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <hr class="hr_cust">
      </div>
  </div>
  <!-- <div *ngIf="IsRDValue">
    <div class="row bg_CMA">
        <div class="col-12">
            <div class="row">
                <div class="col-12">&nbsp;</div>
            </div>
            <div class="row">
                <div class="col-12 text-center">
                    <span class="rdheading">{{rdData.desc|titlecase}}</span>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-center mt-3 ">
                    <span class="rdcodecss">{{rdData.valuetype}}</span>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-center mt-3 ">
                    <span *ngIf="rdData.percentage >=100" class="rdstatusAchived"> <img
                            src="../../../assets/target_achieved.png" class="img-fluid" />&nbsp;
                        Great! Target Achieved</span>
                    <span *ngIf="rdData.percentage < 100" class="rdstatusOnTrack"> <img
                            src="../../../assets/targetachieved.png" class="img-fluid" />&nbsp;
                        On Track</span>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-3">
                    <div class="row mt-2">
                        <div class="col-12">
                            <span class="Value_text">{{rdData.percentage}}%</span>
                        </div>
                    </div>
                </div>
                <div class="col-9">
                    <div class="row mt-2">
                        <div class="col-12">
                            <div class="progress rounded-pill">
                                <div class="progress-bar bg_sec" role="progressbar" [style.width.%]="rdData.billedvillages"
                                    aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                <div class="progress-bar bg_pri" role="progressbar" [style.width.%]="rdData.totalvillages"
                                    aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-6 text-center">
                            <span class="text_14">{{rdData.billedvillages}}</span>
                        </div>
                        <div class="col-6 text-center">
                            <span class="text_14">{{rdData.totalvillages}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 text-center">
                            <i class="fa fa-circle fa-sm" style="color: #eaab5e; font-size: 6px;">&nbsp;&nbsp;<span
                                    class="Secondary_text">Billed Villages</span></i>
                        </div>
                        <div class="col-6 text-center">
                            <i class="fa fa-circle fa-sm" style="color:#f4d5ae; font-size: 6px;">&nbsp;&nbsp;<span
                                    class="Secondary_text">Total Villages</span></i>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-12">&nbsp;</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br>
    <div class="row mt-2">
      <div class="col-12">
        <span class="header_text">Substk Details</span>
      </div>
    </div>
    <div class="row mt-2">
        <div class="col-4">
            <span class="text_12">Substk Details</span>
        </div>
        <div class="col-8 text-right">
            <i class="fa fa-circle fa-sm" style="color: #eaab5e; font-size: 6px;">&nbsp;&nbsp;<span
                    class="Secondary_text">Billed Villages</span></i>&nbsp;&nbsp;
            <i class="fa fa-circle fa-sm" style="color:#000000; font-size: 6px;">&nbsp;&nbsp;<span
                    class="Secondary_text">Total Villages</span></i>
        </div>
    </div>
    <div class="row mt-2" *ngFor="let item of filterItemsOfTypeUrjaaVP(vpdata)">
        <div class="col-12">
            <div class="row mt-2">
                <div class="col-8">
                    <span class="tgt_text">{{item.desc}}</span>
                </div>
                <div class="col-4 text-right">
                    <span class="ach_text">{{item.billedvillages}}</span>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-8">
                    <span class="Secondary_text">{{item.valuetype}}</span>
                </div>
                <div class="col-4 text-right">
                    <span class="tgt_text">{{item.totalvillages}}</span>
                </div>
            </div>
        </div>
    </div>
</div> -->
  <br>
  <br>
  <br>
  <br>
