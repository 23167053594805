import { Component, OnInit } from '@angular/core';
import { Route, Router } from '@angular/router';
import { min } from 'rxjs/operators';
import { Enclass, ResultClass } from 'src/app/models/encryption-class';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';
import { EncryptionService } from 'src/app/service/encryption.service';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';

@Component({
  selector: 'app-ticket-log-list',
  templateUrl: './ticket-log-list.component.html',
  styleUrls: ['./ticket-log-list.component.css']
})
export class TicketLogListComponent implements OnInit {
  private _searchText = '';
  filteredList: any;
  list: any;
  token: any;
  userType: any;
  formname: any;
  actiondesc: any;
  FieldName: any;
  FieldValue: any;
  userID: string | null = '';
  userName: string | null = '';
  device: any = ""
  deviceType: any = ""
  mainList: any = []
  secretKey: any;
  clientId: any;
  public enclass: Enclass = new Enclass();
  public resultClass: ResultClass = new ResultClass();
  pendingTicket: any = 0
  resolvedTicket: any = 0
  isREShow: boolean = false
  constructor(private headerService: HeaderService, private router: Router,
    private cs: CommonUtilitiesService, private es: EncryptionService, public mds: MongoDataService) { }

  ngOnInit(): void {
    this.headerService.setTitle('Ticket Log List');
    this.headerService.setActiveURl('/ticketLogList');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.userID = localStorage.getItem("UserID");
    this.userName = localStorage.getItem("UserID");
    this.token = localStorage.getItem("TokenNo");
    this.userType = localStorage.getItem("UserType");
    this.FieldName = localStorage.getItem("FieldName");
    this.FieldValue = localStorage.getItem("FieldValue");
    this.secretKey = localStorage.getItem("SecreteKey")
    this.clientId = localStorage.getItem("ClientID");
    this.formname = "ticketLogList";
    this.actiondesc = "Ticket LogList"
    this.cs.GetGeoLocation(this.userID, this.userType, this.formname, this.actiondesc, this.FieldName, this.FieldValue);
    this.loadData();
    this.list = this.filteredList = this.mainList
  }

  loadData() {
    let request = {
      "companyCode": "mdlz",
      "userId": this.userID,
      "userType": this.userType
    }
    this.es.Word = this.es.WordKey;
    this.es.encrypt(this.secretKey);
    this.es.Word = JSON.stringify(request);
    this.enclass.buggerstring = this.es.encrypt(this.secretKey)
    this.mds.HD_GetAllTicketDetails(this.enclass, this.token, this.device, this.deviceType, this.formname, this.clientId).subscribe(res => {
      console.log("getSurveydetails", res)

      this.resultClass = res;
      this.es.Word = this.resultClass.resultString;
      let response = JSON.parse(this.es.decrypt(this.secretKey));

      console.log("response", response)
      this.mainList = []
      // response.Data.sort((a:any, b:any) => b.ticketNo - a.ticketNo);

      this.list = this.filteredList = this.mainList = response.Data

      var replyCountList = []
      for (let i = 0; i < this.filteredList.length; i++) {

        // if (this.filteredList[i].ticketOwnerType == 'L1' || this.filteredList[i].ticketOwnerType == 'L2' || this.filteredList[i].ticketOwnerType == 'L3') {
        //   this.filteredList[i].isTicketWithL1L2L3 = true
        // }
        let splitTicketTime = this.filteredList[i].ticketTime?.split(".")
        this.filteredList[i].ticketTimeInHours =splitTicketTime[0]+":"+splitTicketTime[1]

          replyCountList = []
        for (let j = 0; j < this.filteredList[i].transactionDetails.length; j++) {
          if (this.filteredList[i].transactionDetails[j].transactionType?.toLowerCase() == "reply" || this.filteredList[i].transactionDetails[j].transactionType?.toLowerCase() == "transfer" || this.filteredList[i].transactionDetails[j].transactionType?.toLowerCase() == "changestatus") {
            replyCountList.push(this.filteredList[i].transactionDetails[j])
          }

          if (this.filteredList[i].transactionDetails[j].transactionType?.toLowerCase() == "reopen") {
            this.filteredList[i].isREShow = true
          }

          this.filteredList[i].replyCount = replyCountList.length
        }
      }

      for (let i = 0; i < this.filteredList.length; i++) {
        var date = new Date(this.filteredList[i].statusDate)
        this.filteredList[i].statusDateFormatChange = date.toLocaleDateString()

        const time = new Date(this.filteredList[i].date);
        console.log("time", time)
        const hours = time.getHours().toString().padStart(2, '0');
        let minutes: any = time.getMinutes().toString().padStart(2, '0');
        // minutes=minutes.toString().length == 1 ? 0 + minutes : minutes
        const seconds = time.getSeconds();

        // console.log(`Hours: ${hours}, Minutes: ${minutes}, Seconds: ${seconds}`);

        // this.filteredList[i].statusTimeFormat = (`${hours}:${minutes}`)
        this.filteredList[i].statusTimeFormat = time

        if (this.filteredList[i].ticketStatus?.toLowerCase() == "inprogress" || this.filteredList[i].ticketStatus?.toLowerCase() == "new" || this.filteredList[i].ticketStatus?.toLowerCase() == "on hold" || this.filteredList[i].ticketStatus?.toLowerCase() == "reopen") {
          this.pendingTicket = this.pendingTicket + 1
        }
        if (this.filteredList[i].ticketStatus?.toLowerCase() == "resolved") {
          this.resolvedTicket = this.resolvedTicket + 1
        }
      }


      console.log("filteredList", this.filteredList)
      // this.replyCountList=this.filteredList.map((m:any)=>m.transactionDetails).filter((f:any)=>(f.transactionType=="reply"))
    }, (error: any) => {
      console.log("error", error)
    });

  }

  get searchTerm(): any {
    return this._searchText;
  }
  set searchTerm(value: any) {
    this._searchText = value;
    this.filteredList = this.filteredListMethod(value);
  }
  filteredListMethod(value: any): any {
    return this.list.filter((ele: any) =>
      ele.subject?.toLowerCase().indexOf(value?.toLowerCase()) !== -1)
  }

  clickedOnNewTicket() {
    this.router.navigate(['/home/newTicket'])
  }

  rowClick(item: any) {
    localStorage.setItem("ticketDetails", JSON.stringify(item))
    this.router.navigate(['/home/UpdateTicketDetails'])
  }

}
