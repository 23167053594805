import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { resPCAttendance,resPCFRData } from 'src/app/models/kpi-models';
import { EncryptionService } from '../../service/encryption.service';
import { DataService } from '../../service/data.service';
import { Enclass, ResultClass } from '../../models/encryption-class';
import { MatDialog } from '@angular/material/dialog';
import { FilterComponent } from '../filter/filter.component';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-complaints',
  templateUrl: './complaints.component.html',
  styleUrls: ['./complaints.component.css']
})
export class ComplaintsComponent implements OnInit {
  paracode: any;
  
  UserID: any;
  isLongPress: boolean = false;
  isSelected: boolean = false;
  currentDate = new Date();
 
  heading :string="";
  CurrentDate:any;
 
  complaintlist:any;
  FRheading :string="";
  FRFieldName:string|null="";
  FRFieldValue:string|null="";
  list:any;
  myBooks: any;
  listOrigin:any;
  selectedText: any;
  userList1: any;
  lastkeydown1: number = 0;
  formname:any;
  actiondesc:any;
  fieldName: any;
  fieldValue: any;
  userType:any;
  constructor(private activatedRoute: ActivatedRoute, private cs:CommonUtilitiesService,
    private router: Router, private headerService: HeaderService,  private mds: MongoDataService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.headerService.setTitle('Add Complaint');
    this.UserID=localStorage.getItem("UserID");
    this.userType = localStorage.getItem("UserType");  
    this.fieldName = localStorage.getItem("FieldName");
    this.fieldValue = localStorage.getItem("FieldValue");

    this.formname="complaints";
    this.actiondesc="complaints"
    this.cs.GetGeoLocation(this.UserID,this.userType,this.formname,this.actiondesc,this.fieldName,this.fieldValue);
  }
  ClickAddComplaint(){

    this.router.navigate(['/home/addcomplaint/']);
  }
}
