import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { DataService } from 'src/app/service/data.service';
import { InputPara } from 'src/app/models/kpi-models';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';
@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.css']
})
export class MediaComponent implements OnInit {
  deviceType: any;
  device: any;
  userType: string | null = '';
  userId: any;
  list: any;
  videoList: any;
  imageList: any;
  fieldName: any;
  fieldValue: any;
   defaultImageUrl: string = "https://cilsales.net/MeraApp2/BrandDisplay/";
 // defaultImageUrl: string = "https://dev.cilsales.net/MeraApp2/BrandDisplay/";
  token: any;
  formname:any;
  actiondesc:any;

  constructor(private activatedRoute: ActivatedRoute, private cs:CommonUtilitiesService,private router: Router, private headerService: HeaderService, private mds: MongoDataService, private notifyMeService:NotifyMeService, private ds: DataService,) { }

  ngOnInit(): void {
    this.headerService.setTitle('Media');
    this.headerService.setActiveURl('/Media');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.token = localStorage.getItem("TokenNo");
    this.userType = localStorage.getItem("UserType");
    this.userId = localStorage.getItem("UserID");
    this.fieldName = localStorage.getItem("FieldName");
    this.fieldValue = localStorage.getItem("FieldValue");
    this.defaultImageUrl =localStorage.getItem("baseappurl") + "MeraApp2/BrandDisplay/";

    this.formname="Media";
    this.actiondesc="Media";
    this.cs.GetGeoLocation(this.userId,this.userType,this.formname,this.actiondesc,this.fieldName,this.fieldValue);
    this.loadData();
  }

  loadData(): void {
    this.ds.getMediaData(this.token,this.device, this.deviceType, 'Media').subscribe(res => {
      this.list = res
    });
  }

  onTabChanged(e:any){
    if(e.index == 0){

    }else if(e.index == 1){
      this.videoList = this.list.filter((singleItem: { [x: string]: string; }) => (singleItem['brandType']== "Video"));
    }else if(e.index == 2){
      this.imageList = this.list.filter((singleItem: { [x: string]: string; }) => (singleItem['brandType']== "Image"));
    }
  }
}
