import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { DatePipe } from '@angular/common';
import { PullRequest } from 'src/app/models/kpi-models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-pull-out-approval',
  templateUrl: './pull-out-approval.component.html',
  styleUrls: ['./pull-out-approval.component.css']
})
export class PullOutApprovalComponent implements OnInit {
  @ViewChild('openModalMsg', { static: true }) elRefopenModalMsg: ElementRef | undefined;
  deviceType: any;
  device: any;
  isLongPress: boolean = false;
  isSelected: boolean = false;
  userType: string | null = '';
  userId: any;
  list: any;
  selected = -1;
  isSelectedObj: any;
  public pullRequest: PullRequest = new PullRequest();
  msg: any;
  private _searchText = '';
  filteredList: any;
  token: any;
  formname: any;
  actiondesc: any;
  FieldName: any;
  FieldValue: any;

  constructor(private activatedRoute: ActivatedRoute,private cs:CommonUtilitiesService, private router: Router, private headerService: HeaderService, private datePipe: DatePipe, private mds: MongoDataService, private modalService: NgbModal,) { }


  ngOnInit(): void {
    this.headerService.setTitle('Pull Out Approval');
    this.headerService.setActiveURl('/pullOutApproval');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.userType = localStorage.getItem("UserType");
    this.userId = localStorage.getItem("UserID");
    this.token = localStorage.getItem("TokenNo");
    var myDate = new Date();
    var previousMonth = new Date(myDate);
    previousMonth.setMonth(myDate.getMonth() - 1);
    let previousMonths = this.datePipe.transform(previousMonth, 'MMM');
    this.isLongPress = false;

    this.FieldName=localStorage.getItem("FieldName");
    this.FieldValue=localStorage.getItem("FieldValue");
    this.formname = "pullOutApproval";
    this.actiondesc = "Pull Out Approval";
    this.cs.GetGeoLocation(this.userId, this.userType, this.formname, this.actiondesc, this.FieldName, this.FieldValue);
    this.onLoad();
  }

  onLoad() {
    let id = 0;
    this.mds.getPendingPulloutList(this.userId,this.token, this.userType, id, this.device, this.deviceType,'Pull Out Approval').subscribe(res => {
      this.list = res;
      this.filteredList = this.list;
    });
  }

  onLongPress() {
    this.isLongPress = true;
  }

  clickOnSelect(e: any, id: any) {
    if (e.target.checked) {
      this.isSelected = e.target.value;
      this.isSelectedObj = this.list[id];
    }else{
      this.isSelected = false;
      this.isSelectedObj = null;
    }
  }

  rowClick() {
    this.pullRequest.outletCode = this.isSelectedObj.outletCode;
    this.pullRequest.rdCode = this.isSelectedObj.rdCode;
    this.pullRequest.PulloutNo = this.isSelectedObj.pulloutNo;
    this.pullRequest.VisicoolerID = this.isSelectedObj.visicoolerID;
    this.pullRequest.Reason = this.isSelectedObj.pulloutReason;
    this.pullRequest.UserID = this.userId;
    this.pullRequest.UserType = this.userType;
    this.mds.approvePullRequest(this.pullRequest, this.token, this.device, this.deviceType,'Pull Out Approval').subscribe(res => {
      if (res[0].msg == "OK") {
        this.msg = "Pull Out number "+ this.isSelectedObj.pulloutNo +" Approved Successfully";
        this.isSelected = false;
        this.isLongPress = false;
        this.onLoad();
      } else {
        this.msg = res[0].msg;
      }

      this.modalService.open(this.elRefopenModalMsg,
        {
          size: 'lg',
          backdrop: 'static',
          windowClass: '',
          keyboard: false,
          centered: true,
        })
    });

  }

  modalClose() {
    this.modalService.dismissAll();
  }

  get searchTerm(): any{
    return this._searchText;
  }
  set searchTerm(value: any){
     this._searchText = value;
     this.filteredList = this.filteredListMethod(value);
  }

  filteredListMethod(value: any): any {
    return this.list.filter((ele: any) =>
      ele.outletCode.indexOf(value) !== -1);
  }

}
