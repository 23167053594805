<div>
    <div class="row mt-4">
        <div class="col-12">
            <span class="Name">Existing Outlet Name</span><br>
            <span class="Code">{{outletNameChangedApprovalListDetails.OldOutletName}}</span>
            <span class="Code ms-2">{{outletNameChangedApprovalListDetails.OutletCode}}</span>
        </div>
    </div>
    <hr>
    <div class="row mt-4">
        <div class="col-12">
            <span class="Name">New Outlet Name</span><br>
            <span class="Code">{{outletNameChangedApprovalListDetails.NewOutletName}}</span>
            <span class="Code ms-2">{{outletNameChangedApprovalListDetails.OutletCode}}</span>
        </div>
    </div>
    <hr>
    <div class="row mt-4">
        <div class="col-12">
            <span class="Name">Program</span><br>
            <span class="Code">{{outletNameChangedApprovalListDetails.ProgramType}}</span>
        </div>
    </div>
    <hr>
    <div class="row mt-4">
        <div class="col-12">
            <span class="Name">Visicooler</span><br>
            <span class="Code">{{outletNameChangedApprovalListDetails.VisiCooler}}</span>
        </div>
    </div>
    <hr>
    <div class="row mt-4">
        <div class="col-12">
            <span class="Name">SOTerritory</span><br>
            <span class="Code">{{outletNameChangedApprovalListDetails.SOTerritory}}</span>
        </div>
    </div>
    <hr>
    <div class="row mt-4">
        <div class="col-12">
            <span class="Name">ASMArea</span><br>
            <span class="Code">{{outletNameChangedApprovalListDetails.ASMArea}}</span>
        </div>
    </div>
    <hr>
    <div class="row mt-4">
        <div class="col-6">
            <span class="Name">Supporting Document</span><br>
            <span class="Code">{{outletNameChangedApprovalListDetails.DocumentType1}}</span>
        </div>
        <div class="col-6">
            <img [src]="url+'MeraNet/GetDownloadFileFromS3Bucket/OutletNameChange/' + outletNameChangedApprovalListDetails.DocumentName1"
                alt="Image" class="img-fluid float-end">
        </div>
    </div>
    <hr>
</div>

<br><br><br><br><br><br>

<div class="footer">
    <div class="row">
        <div class="col-6 pr-0" (click)="Reject()">
            <button class="btn btn_REJECT">Reject</button>
        </div>

        <div class="col-6 pl-0" (click)="Approve()">
            <button class="btn btn_APPROVE">Approve</button>
        </div>
    </div>
</div>


<ng-template #openRejectedReasonBox let-modal id="test">
    <div class="modal-header justify-content-center">
        <span class="modelHeader">Reject Reason</span>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modalClose()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12">
                <span class="">Reason</span>
                <br><br>
                <input class="form-control" #enterRejectedReason type="text" name="" id="" placeholder="Enter Reason">
            </div>
        </div>
    </div>
    <div class="modal-footer text-center justify-content-center" (click)="submitRejectedReason(enterRejectedReason.value)">
        <button type="button" class="okBtn">SUBMIT</button>
    </div>
</ng-template>