import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { DatePipe } from '@angular/common';
import { ApproveAtoBTransfer } from 'src/app/models/kpi-models';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { DataService } from 'src/app/service/data.service';
import { HttpEventType, HttpClient, HttpHeaders } from '@angular/common/http';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-a2-bdata',
  templateUrl: './a2-bdata.component.html',
  styleUrls: ['./a2-bdata.component.css']
})
export class A2BDataComponent implements OnInit {
  deviceType: any;
  device: any;
  userType: string | null = '';
  userId: any;
  selectedAtoB: any;
  public approveAtoBTransfer: ApproveAtoBTransfer = new ApproveAtoBTransfer();
  token: any;
  coolerPhoto: any;
  outletPhoto: any;
  outletIDPhoto: any;
  pagePhoto1: any;
  pagePhoto2: any;
  oifNo: any;
  photourlstr: string = '';
  httpOptions: HttpHeaders =
    new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      // 'Content-Type': 'multipart/form-data',
      'enctype': 'multipart/form-data',
      'charset': 'UTF-8',
      'FolderName': 'Deploymentimages',
    })

  filename: string | null = '';
  KeyID: string | null = '';
  type: string | null = '';
  formname:any;
  actiondesc:any;
  FieldName:any;
  FieldValue:any;
  constructor(private activatedRoute: ActivatedRoute, private router: Router, 
    private headerService: HeaderService, private datePipe: DatePipe,
     private mds: MongoDataService, private notifyMeService: NotifyMeService, 
     private ds: DataService, private http: HttpClient,private cs:CommonUtilitiesService) { }

  ngOnInit(): void {
    this.headerService.setTitle('A to B Transfer Approval');
    this.headerService.setActiveURl('/a2b');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.token = localStorage.getItem("TokenNo");
    this.userType = localStorage.getItem("UserType");
    this.userId = localStorage.getItem("UserID");
    this.selectedAtoB = JSON.parse(localStorage.getItem("SelectedAtoB") || '{}');
    this.FieldName = localStorage.getItem("FieldName");
    this.FieldValue = localStorage.getItem("FieldValue");
    this.formname="a2bData";
    this.actiondesc="a2b Data"
    this.cs.GetGeoLocation(this.userId,this.userType,this.formname,this.actiondesc,this.FieldName,this.FieldValue);
    this.AToBImagesDownload(this.selectedAtoB.toOUtletCode + '_1.jpg');
    this.AToBImagesDownload(this.selectedAtoB.toOUtletCode + '_2.jpg');
    this.AToBImagesDownload(this.selectedAtoB.toOUtletCode + '_3.jpg');
    this.AToBImagesDownload(this.selectedAtoB.toOUtletCode + '_1.JPEG');
    this.AToBImagesDownload(this.selectedAtoB.toOUtletCode + '_2.JPEG');
    this.AToBImagesDownload(this.selectedAtoB.toOUtletCode + '_3.JPEG');
    // this.AToBImagesDownload(this.selectedAtoB.toOUtletCode + '_7.jpg');
    // this.AToBImagesDownload(this.selectedAtoB.toOUtletCode + '_8.jpg');
  }

  saveRecords() {
    debugger;
    this.approveAtoBTransfer.ID = this.selectedAtoB.id;
    this.approveAtoBTransfer.Status = 'APPROVED';
    this.approveAtoBTransfer.RejectRemark = '';
    this.approveAtoBTransfer.UserID = this.userId;
    
    // localStorage.setItem("approveAtoBTransfer",JSON.stringify(this.approveAtoBTransfer))

    this.mds.approveAtoBtransfer(this.approveAtoBTransfer, this.token, this.device, this.deviceType, 'A to B Transfer Approval').subscribe((res: any) => {
      console.log("approveAtoBtransfer", res)
      // this.oifNo = res.oifno[0].oifNo;
      this.oifNo = res[0].oifNo;

      console.log("oifNo", this.oifNo)
      debugger;
      if (this.oifNo != null) {
        this.uploadImage(this.coolerPhoto, "coolerPhoto")
        this.uploadImage(this.outletPhoto, "outletPhoto")
        this.uploadImage(this.outletIDPhoto, "outletIDPhoto")
        // this.uploadImage(this.pagePhoto1, "pagePhoto1")
        // this.uploadImage(this.pagePhoto2, "pagePhoto2")
        this.deleteImage(this.selectedAtoB.toOUtletCode + '_1.jpg')
        this.deleteImage(this.selectedAtoB.toOUtletCode + '_2.jpg')
        this.deleteImage(this.selectedAtoB.toOUtletCode + '_3.jpg')
        this.deleteImage(this.selectedAtoB.toOUtletCode + '_1.JPEG')
        this.deleteImage(this.selectedAtoB.toOUtletCode + '_2.JPEG')
        this.deleteImage(this.selectedAtoB.toOUtletCode + '_3.JPEG')
        // this.deleteImage(this.selectedAtoB.toOUtletCode + '_7.jpg')
        // this.deleteImage(this.selectedAtoB.toOUtletCode + '_8.jpg')
      }
      let msg = "A to B Transfer Request No: " + this.selectedAtoB.id + " Approved Successfully"
      this.notifyMeService.showSuccess(msg, 'Sales Genie');
      this.router.navigate(['/home/a2b']);
    });
  }

  // cancel() {
  //   this.router.navigate(['/home/a2b']);
  // }

  Reject(){
    this.approveAtoBTransfer.ID = this.selectedAtoB.id;
    this.approveAtoBTransfer.Status = 'REJECTED';
    this.approveAtoBTransfer.RejectRemark = '';
    this.approveAtoBTransfer.UserID = this.userId;
    
    localStorage.setItem("approveAtoBTransfer",JSON.stringify(this.approveAtoBTransfer))
    this.router.navigate(['/home/facialRecRejReasonApproval'], { queryParams: { selected: 'AtoB' } });
  }

  AToBImagesDownload(filename: any) {
    this.ds.getDownloadFileFromS3Bucket("ATOB", filename).subscribe((res) => {
      this.createImageFromBlob(res.body, filename)
    }, (error) => { console.log("error", error) });
  }

  createImageFromBlob(image: Blob, filename: any) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {

      // filename.substring(filename.length - 6)
      switch (filename.split("_").at(-1)) {
        case '1.jpg':
          this.coolerPhoto = reader.result;
          break;
        case '2.jpg':
          this.outletPhoto = reader.result;
          break;
        case '3.jpg':
          this.outletIDPhoto = reader.result;
          break;
        case '7.jpg':
          this.pagePhoto1 = reader.result;
          break;
        case '8.jpg':
          this.pagePhoto2 = reader.result;
          break;
        case '1.JPEG':
          this.coolerPhoto = reader.result;
          break;
        case '2.JPEG':
          this.outletPhoto = reader.result;
          break;
        case '3.JPEG':
          this.outletIDPhoto = reader.result;
          break;
        case '7.JPEG':
          this.pagePhoto1 = reader.result;
          break;
        case '8.JPEG':
          this.pagePhoto2 = reader.result;
          break;
      }
    }, false);
    if (image) {
      reader.readAsDataURL(image);
    }
  }


  uploadImage(image: any, name: any) {
    debugger;
    switch (name) {
      case 'coolerPhoto':
        this.photourlstr = this.oifNo + '_1.jpg';
        break;
      case 'outletPhoto':
        this.photourlstr = this.oifNo + '_2.jpg';
        break;
      case 'outletIDPhoto':
        this.photourlstr = this.oifNo + '_3.jpg';
        break;
      case 'pagePhoto1':
        this.photourlstr = this.oifNo + '_7.jpg';
        break;
      case 'pagePhoto2':
        this.photourlstr = this.oifNo + '_8.jpg';
        break;

      case 'coolerPhoto':
        this.photourlstr = this.oifNo + '_1.JPEG';
        break;
      case 'outletPhoto':
        this.photourlstr = this.oifNo + '_2.JPEG';
        break;
      case 'outletIDPhoto':
        this.photourlstr = this.oifNo + '_3.JPEG';
        break;
      case 'pagePhoto1':
        this.photourlstr = this.oifNo + '_7.JPEG';
        break;
      case 'pagePhoto2':
        this.photourlstr = this.oifNo + '_8.JPEG';
        break;
    }
    const formData = new FormData();

    const imageName = "";

    const imageBlob = this.dataURItoBlob(image?.split(',')[1]);
    //imageFile created below is the new compressed file which can be send to API in form data
    const imageFile = new File([imageBlob], imageName, { type: 'image/jpeg' });
    console.log("photourlstr", this.photourlstr)
    formData.append('file', imageFile, this.photourlstr);
    this.http.post(this.mds.url + 'MeraNet/UploadFileToS3Bucket', formData, { reportProgress: true, observe: 'events', headers: this.httpOptions, responseType: 'blob' as 'json' }).subscribe(res => {
      console.log("ATOBUploadFile", res)
    },
      error => { console.log("error", error) });

  }

  dataURItoBlob(dataURI: string) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }


  deleteImage(filename: any) {
    const dataToSend = {
      filename: filename,
      folderName: "ATOB",
    };
    this.ds.deleteFileFromS3Bucket(dataToSend, this.token, this.device, this.deviceType, 'A to B Transfer Approval').subscribe((res: any) => {
      console.log("deleteFileFromS3Bucket", res);
    });
  }

  downloadDPNAgr() {
    debugger;
    let SelectedAtoB: any = localStorage.getItem("SelectedAtoB");
    this.KeyID = JSON.parse(SelectedAtoB).keyID;
    this.type = "Agreement";
    if (this.type == 'Agreement') {
      this.filename = this.KeyID + "_" + this.type + ".pdf";
      if (this.KeyID != null) {
        if (this.KeyID.length > 0 && this.KeyID.length > 0) {
          debugger
          this.downloadpdf(this.KeyID, this.type);
        }
      }
    }

    // this.type="DPN";
    // if(this.type=="DPN"){
    //   this.filename = this.KeyID + "_" + this.type + ".pdf";
    //   if (this.KeyID != null) {
    //     if (this.KeyID.length > 0 && this.KeyID.length > 0) {
    //       debugger
    //       this.downloadpdf(this.KeyID, this.type);
    //     }
    // }
    //                       z                                             }
  }

  downloadpdf(keyid: any, type: any): void {
    debugger;
    this.filename = keyid + "_" + type + ".pdf";
    this.ds.getDownloadPDFFileFromS3Bucket("eAgreementMaster", this.filename);
  }

}
