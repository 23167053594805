import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { resPCAttendance,resPCFRData, InputPara } from 'src/app/models/kpi-models';
import { EncryptionService } from '../../service/encryption.service';
import { DataService } from '../../service/data.service';
import { Enclass, ResultClass } from '../../models/encryption-class';
import { MatDialog } from '@angular/material/dialog';
import { FilterComponent } from '../filter/filter.component';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-fr-pclist',
  templateUrl: './fr-pclist.component.html',
  styleUrls: ['./fr-pclist.component.css']
})
export class FrPClistComponent implements OnInit {
  input:InputPara=new InputPara();
  paracode: any;

  UserID: any;

  currentDate = new Date();

  heading :string="";
  CurrentDate:any;

  pcFrdata:any;
  FRheading :any="";
  FRFieldName:any="";
  FRFieldValue:any="";
  list:any;
  myBooks: any;
  listOrigin:any;
  selectedText: any;
  userList1: any;
  lastkeydown1: number = 0;
  device: any;
  deviceType: any;
  token: any;
  formname:any;
  actiondesc:any;
  fieldName: any;
  fieldValue: any;
  userType:any;

  constructor(private cs:CommonUtilitiesService,private activatedRoute: ActivatedRoute,private fb: FormBuilder,  private es: EncryptionService,private router: Router,
    private headerService: HeaderService, private mds: MongoDataService,private ds:DataService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.headerService.setTitle('FR PC List');
debugger;
    this.activatedRoute.paramMap.forEach(params => {
      this.FRFieldValue = params.get('ParaValue');
      this.FRFieldName = params.get('ParaName');
      this.UserID = localStorage.getItem("UserID");
      this.device = localStorage.getItem('Device');
      this.deviceType = localStorage.getItem('DeviceType');
      this.token = localStorage.getItem("TokenNo");
      this.paracode="1";
      this.userType = localStorage.getItem("UserType");
      this.fieldName = localStorage.getItem("FieldName");
      this.fieldValue = localStorage.getItem("FieldValue");
      this.formname="frpclist";
      this.actiondesc="frpclist"+" "+this.paracode
      this.cs.GetGeoLocation(this.UserID,this.userType,this.formname,this.actiondesc,this.fieldName,this.fieldValue);
      this.GetPCFRData();
    });


  }
  GetPCFRData(){
    this.input.FieldName=this.FRFieldName;
    this.input.FieldValue=this.FRFieldValue;
    this.input.TagName=this.paracode;
    this.input.Datatype='0';
    this.input.UserID=this.UserID;
    this.ds.getGetFRData(this.input,this.token,this.device, this.deviceType,'FR PC List').subscribe(res => {
    debugger;
    console.log("getGetFRData", res);
    this.pcFrdata= res as resPCFRData;
     this.listOrigin= this.list = res;
     this.fillAutoCompleteSearchBox();
  }
    );
  }
  fillAutoCompleteSearchBox() {
    this.myBooks = [];
    let mybook1 :any = [...new Set(this.listOrigin.map((bill: { rdname: string; rdcode: number; }) => (bill.rdname + ' [' + bill.rdcode + '] ' )))];
    this.myBooks = [].concat.apply([], mybook1);

  }
  getUserIdsFirstWay($event: any) {
    let userId = (<HTMLInputElement>document.getElementById('userIdFirstWay')).value;
   this.userList1 = [];
    if (userId.length > 2) {
      if ($event.timeStamp - this.lastkeydown1 > 200) {
        this.userList1 = this.searchFromArray(this.myBooks, userId);
      }
    }
  }
  searchFromArray(arr: any, regex : any) {
    let matches = [], i, j = 0;
    for (i = 0; i < arr.length; i++) {
      if (arr[i].toLowerCase().match(regex.toLowerCase())) {
        matches.push(arr[i]);
        j++;
        i = j < 10 ? i : arr.length;
      }
    }
    return matches;
  };
  SearchText(e: any): void {
    this.selectedText = e.target.value;
     let selectingType = this.selectedText.substring(this.selectedText.indexOf('[')+1,this.selectedText.indexOf(']'));
  }
}
