<div [style.padding.px]="20">
    <h1> ngx-scanner-qrcode </h1>
  
    <!-- [frameConfig]="frameConfig"  -->
    <!-- [textConfig]="textConfig"  -->
    <!-- ngx-scanner-qrcode -->
    <ngx-scanner-qrcode 
      #action="scanner" 
      (data)="output = $event" 
      (error)="onError($event)">
    </ngx-scanner-qrcode>
  
    <!-- data  -->
    <!-- <p class="data">{{ output }}</p> -->
    <div class="col-12 mt-3 text-center" *ngIf="output">
      <span class="qr_Code_Success">QR Code - {{output}} <br>
        scanned successfully</span>
    </div>
  
    <!-- Loading -->
    <p *ngIf="action.isLoading">⌛ Loading video...</p>
  
    <!-- toggleCamera -->
    <button (click)="action.toggleCamera()" class="btn" [class.btn-info]="!action.isStart" [class.btn-warning]="action.isStart" [disabled]="action.isLoading">
      <img *ngIf="!action.isStart" src="https://img.icons8.com/ios/50/000000/no-camera--v2.png" width="30px"/>
      <img *ngIf="action.isStart" src="https://img.icons8.com/ios/50/000000/no-camera--v1.png" width="30px"/>
    </button>
  
    <!-- <h5>Author: DaiDH</h5> -->
  </div>