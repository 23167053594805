<div>
    <div class="row mt-4 mb-3">
        <div class="col-8">
            <input type="text" class="outletCode" #outletCode placeholder="Enter Outlet Code" maxlength="20"
                (keypress)="numberOnly($event)" />
        </div>
        <div class="col-4">
            <button class="btn btn_GO" (click)="GOBtn(outletCode)">GO</button>
        </div>
    </div>

    <div *ngIf="GoBtnClicked=='true'" class="mt-5">
        <div *ngFor="let item of outletDetails" (click)="rowClick(item)">
            <!-- <div > -->
            <div class=" row">
                <div class="col-12">
                    <span class="text_14 text_black">{{item.outletName}}</span>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <span class="text_14 text_black">RD Name <span class="text_14 text_black ml-1"
                            style="opacity: 40%;">{{item.rdName}}</span></span>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <span class="text_14 text_black">OIF NO <span class="text_14 text_black ml-1" 
                        style="opacity: 40%;">
                            {{item.oifNo}}
                        </span></span>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <span class="text_14 text_black">Visicooler <span class="text_14 text_black ml-1"
                            style="opacity: 40%;">
                            {{item.visicooler}}
                        </span></span>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <span class="text_14 text_black">Deployment <span class="text_14 text_black ml-1"
                            style="opacity: 40%;">
                            {{item.deploymentDate}}
                        </span></span>
                </div>
            </div>
            <hr>
            <!-- </div> -->
        </div>
    </div>
</div>