import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Output_MTAFHAccount } from 'src/app/models/kpi-models';
import { EncryptionService } from '../../service/encryption.service';
import { DataService } from '../../service/data.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-mtafhapproval',
  templateUrl: './mtafhapproval.component.html',
  styleUrls: ['./mtafhapproval.component.css']
})
export class MTAFHApprovalComponent implements OnInit {
  ID: any;
  status: string = '';
  Flag: any;
  userTypelist:any;
  ParaName: any;
  UserID: any;
  UserType: any;
  ParaValue: any;
  TokenNo: any ;
  ClientID: any;
  accountlist:any;
  device: any;
  deviceType: any;
  Resaccountlist:any;
  FormName: any;
  formname:any;
  actiondesc:any;
  fieldName:any;
  fieldValue:any;
  constructor(private activatedRoute: ActivatedRoute,private cs:CommonUtilitiesService,private fb: FormBuilder,  private es: EncryptionService,private router: Router,
    private headerService: HeaderService,private ds:DataService,private mds: MongoDataService) { }

  ngOnInit(): void {
    this.headerService.setTitle('MT AFH Approvals');
    this.ID = "0";
    this.Flag ="Display";
    this.UserID =localStorage.getItem('UserID');
    this.TokenNo= localStorage.getItem("TokenNo");
    this.ClientID= localStorage.getItem("ClientID");
    this.device = localStorage.getItem('Device');
    this.deviceType = localStorage.getItem('DeviceType');
    this.fieldName = localStorage.getItem("FieldName");
    this.fieldValue = localStorage.getItem("FieldValue");
  
 this.Flag=this.UserType;
    this.activatedRoute.paramMap.forEach(params => {
      this.ID = params.get('ID');
      this.Flag = params.get('Flag');
      this.UserID = params.get('UserID'); 

      this.formname="mtafhapproval";
      this.actiondesc="mtafh Approval"+" "+this.ID;
      this.cs.GetGeoLocation(this.UserID,this.UserType,this.formname,this.actiondesc,this.fieldName,this.fieldValue);   
      this.loadData();
    });
       
  }
  
  loadData(): void {
   debugger;
    this.ds.MTAFH_GetAccount(this.ID,this.Flag, this.UserID, this.TokenNo,this.device,this.deviceType,'MT AFH Approval').subscribe(res => {
      console.log("MTAFH_GetAccount",res)
      debugger;
      this.accountlist =res;
     this. Resaccountlist=this.accountlist;
       
    });
  }
  rowClick(ID:any){
     this.router.navigate(['/home/mtafhdetails/',ID,this.UserID]);
  }
}
