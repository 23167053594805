<div class="row mt-2 bg_Color">
  <div class="col-12 mt-4">
    <span class="rd_Name_Head">Voucher No {{erodsVoucherApproval.vouNo}}</span>
  </div>
  <div class="col-7 mt-2 mb-4 pr-0">
    <span class="amt_Text">Amt</span>
    <span class="ml-1 amt_Value">{{erodsVoucherApproval.voucherAmt}}</span>
    <span class="ml-3 amt_Text">Date</span>
    <span class="ml-1 amt_Value">{{erodsVoucherApproval.vouDate}}</span>
  </div>
  <div class="col-5 mt-1 mb-4 text-right">
    <button class="btn btn_custom" (click)="clickOnVoucherDetails()">Voucher Details</button>
  </div>
</div>
<div class="mt-4">
  <div *ngFor="let item of list; let i = index;">
    <div class="row">
      <div class="col-12">
        <span class="name_Text">{{item.skuName | titlecase}}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-3 mt-2">
        <span class="list_Text">List</span>
        <span class="ml-1 list_Value">{{item.listNo}}</span>
      </div>
      <div class="col-3 mt-2">
        <span class="list_Text">Mrp</span>
        <span class="ml-1 list_Value">{{item.mrp}}</span>
      </div>
      <div class="col-3 mt-2">
        <span class="list_Text">Qty</span>
        <span class="ml-1 list_Value">{{item.qty}}</span>
      </div>
      <div class="col-3 mt-2 pl-0">
        <span class="list_Text">Amt</span>
        <span class="ml-1 list_Value">{{item.lineAmt}}</span>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12">
        <span class="reason_Text">Reason</span>
        <mat-form-field class="ml-2" appearance="outline" style="width: 100px">
          <mat-select id="reason" name="reason" (selectionChange)="reasonChange($event)" [(value)]="item.reason">
            <mat-option value="" disabled>Select Reason</mat-option>
            <mat-option *ngFor="let items of reasonList" [value]="items.reason">
              {{ items.reason }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <hr>
  </div>
</div>
<br>
<br>
<br>
<br>
<br>
<div class="footer">
  <div class="row text-center mt-3">
    <div class="col-12" (click)="submit()">
      APPROVE
    </div>
  </div>
</div>

<ng-template #openModalThankYou let-modal>
  <div class="modal-header">
    <h4 class="modal-title text_color_head text_20" id="modal-basic-title">
      <img class="img-fluid" src="assets/thanks_icon.png" />
      <span class="ml-3">Thank You</span>
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modalClose()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <span class="model_PopUp_Text">Erods Approved Successfully</span>
      </div>
    </div>
    <div class="row mt-4 mb-2">
      <div class="col-12">
        <span class="pc_Name_PopUp">{{erodsVoucherApproval.rdName | titlecase}}</span>
        <span class="ml-2 pc_Code_PopUp">{{erodsVoucherApproval.rdCode}}</span>
      </div>
    </div>
  </div>
  <div class="modal-footer" (click)="okay()">
    OKAY
  </div>
</ng-template>
