import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { GetVPOORVISIScan,InputPara,Resultdata } from 'src/app/models/kpi-models';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterComponent } from '../filter/filter.component';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { DataService } from 'src/app/service/data.service';

@Component({
  selector: 'app-vpo',
  templateUrl: './vpo.component.html',
  styleUrls: ['./vpo.component.css']
})
export class VpoComponent implements OnInit, AfterViewInit {
  FieldName: any;
  FieldValue: any;
  DataType: any;
  UserID: any;
  vpo: any;
  periodList: any[] = [];
  selectedperiod: any;
  UserType: any;
  TagName: any;
  PrimaryText: any;
  SecondaryText: any;
  resultdata:Resultdata=new Resultdata();
  FormName:any;
  device:any;
  deviceType: any;
  input:InputPara=new InputPara();

  name = 'countUp Demo';
  num: number = 0;
  newNum: number=0;
  option = {
    startVal: this.num,
    useEasing: true,
    duration: 2,
    decimalPlaces: 0,
  };
  interval: any;
  currentView: any;
  token: any ;
  lat:any;
  lng:any;

  constructor(private activatedRoute: ActivatedRoute,private fb: FormBuilder,
     private router: Router, private headerService: HeaderService,
      private mds: MongoDataService, private dialog: MatDialog,private notifyMeService: NotifyMeService,private ds: DataService,) { }

  ngAfterViewInit() {
   // this.changeView('view1');
  }

  ngOnInit(): void {
    this.headerService.setActiveURl('/Vpo');
    this.UserType = localStorage.getItem("UserType");
    this.UserID = localStorage.getItem("UserID");
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.token = localStorage.getItem("TokenNo");
    this.DataType = "0"
    this.selectedperiod=localStorage.getItem("selectedperiod");
    this.vpo = [];
    this.activatedRoute.paramMap.forEach(params => {
      this.TagName = params.get('TagName');
      this.FieldName = params.get('FieldName');
      this.FieldValue = params.get('FieldValue');
      this.selectedperiod = params.get('selectedperiod');
      if(this.TagName=="VPO")
      {
        this.FormName='VPO';
        this.headerService.setTitle("VPO");
        this.PrimaryText="VPO Achieved";
        this.SecondaryText="Total VPO";
      }
      else
      {
        this.FormName='VC Scan';
        this.headerService.setTitle("VC Scan");
        this.PrimaryText="VC Scan";
        this.SecondaryText="Total VC";
      }
      debugger
      this.GetGeoLocation();
    this.loadData();
    localStorage.setItem("rdData", '');
  });
  }

  
  GetGeoLocation() {
    debugger
    if (navigator) {
      navigator.geolocation.getCurrentPosition(pos => {
        this.lat = pos.coords.latitude;
        console.log("  this.lat", this.lat)
        this.lng = pos.coords.longitude;
        this.SetapplicationLogData();
      }, this.handleLocationError, { timeout: 30000 });
    }
    else {
      this.notifyMeService.showError('Location Could not be captured', 'Mera APP');
    }
  }

  handleLocationError(error: { code: any; }) {
    switch (error.code) {
      case 3:
        this.notifyMeService.showError('Timeout in Position capture', 'Mera APP V2');
        break;
      case 2:
        this.notifyMeService.showError('Device is not capable of Position capture', 'Mera APP V2');
        break;
      case 1:
        this.notifyMeService.showError('You denied for Position capture', 'Mera APP V2');
        break;
    }
  }


  SetapplicationLogData() {
    debugger
    let request = {
      "userid": this.UserID,
      "usertype": this.UserType,
      "formname": "Vpo",
      "actiondesc": "Vpo" + " " + this.FieldName,
      "fieldname": this.FieldName,
      "fieldvalue": this.FieldValue,
      "latitude": this.lat,
      "longitude": this.lng
    }
    this.ds.SetapplicationLogData(request).subscribe(res => {
      console.log("res", res)
    })
  }

  loadData(): void {
    this.input.FieldName=this.FieldName;
    this.input.FieldValue=this.FieldValue;
    this.input.TagName=this.TagName;
    this.input.Datatype=this.DataType;
    this.input.UserID=this.UserID;
    debugger;
    this.mds.getVPOORVISIScan(this.input,this.token,this.device,this.deviceType,this.FormName).subscribe(res => {
       this.vpo = res as GetVPOORVISIScan;
      this.periodList = [...new Set(res.map((bill: { period: any; }) => bill.period))].sort();
    //  this.selectedperiod = this.periodList[0];
    });
  }
  PeriodChange(e: any): void {
    this.selectedperiod = e.source._value;

  }
  filterItemsOfType(type: any) {
    return type != null ? type.filter((x: { [x: string]: any; }) => (x['period'] == this.selectedperiod)) : null;
  }
  RowClick(e: any): void {
    localStorage.setItem("DataType",e);
    this.router.navigate(['/home/VpoData/',this.FieldName,this.FieldValue,this.TagName,localStorage.getItem("DataType"),this.UserID,this.selectedperiod]);
  }
  openFilter(){
    if (this.dialog.openDialogs.length == 0) {
      const dialogRef = this.dialog.open(FilterComponent, {
        closeOnNavigation: true,
        data: {
          "FieldName": localStorage.getItem("OriginalFieldName"),
          "FieldValue": localStorage.getItem("OriginalFieldValue"),
          "UserID": this.UserID,
          "MenuUpTo": "5",
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          debugger;
          this.FieldName=result.FieldName;
          this.FieldValue=result.FieldValue;
          this.loadData();
              }
      });
    }
  }
  counternumber() {
      if (this.interval)
      clearInterval(this.interval);
        this.newNum = 1000;
        this.num = this.newNum;
        const that = this;
        this.interval = setInterval(() =>{
          that.newNum += 10;
          that.option = {
            startVal: this.num,
            useEasing: false,
            duration: 30,
            decimalPlaces: 2,
          };
          that.num = that.newNum;
        }, 30000)
  }

}
