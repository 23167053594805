<div class="container-fluid">

    <div class="row mt-4">
      <div class="col-9">
        <i class="fa fa-caret-left" style="color: #757575;" >&nbsp;&nbsp;
            <span class="header_text">{{FieldValue=='0'? 'All India' : FieldValue=='ALLINDIA' ? 'All India': FieldValue |titlecase}}</span></i>
    </div>     
        <div class="col-3 text-right">
            <!-- <img src="../../../assets/filter.png" class="img-fluid" (click)="openFilter()" /><sup><i
                class="fa fa-circle fa-sm" style="color: #eaab5e;font-size: 5px;"></i></sup>&nbsp;<span
              style="color: #724e8c;font-family: Roboto-Medium;font-weight:400 ;font-size: 14px;">Filter</span> -->
          </div>
    </div>
    <div class="row mt-4">
<div class="col-8"><span class="header">Monthly Earning(Rs)</span></div>
<div class="col-4 text-right"><span class="header">Month</span></div>
    </div>
    <div class="row mt-2"  *ngFor ="let item of SeEarningData" >
        <div class="col-12">
            
            <div class="row"style="margin-top: 1rem;">
                <div class="col-2">
                    <span class="Value_text"> {{item.totalincentive}}</span>
                </div>
                <div class="col-6" style="margin-top: 0.5rem;">
            <div class="progress rounded-pill">
               
                <div class="progress-bar bg_sec" role="progressbar"aria-valuenow="25"
                    aria-valuemin="0"  [style.width.%]="item.totalincentive" aria-valuemax="100"></div>
                       </div>
            </div>
        
            <div class="col-2 text-right"><span class="Value_text"> {{item.fieldvalue}}</span></div>
            </div>
             
</div>
<hr class="hr_cust">   
    </div>
</div>
<br><br><br><br>
