<div style="margin-top: 0.75rem;">
  <div *ngFor="let item of list; let i = index;">
    <div class="row bg_Color" (click)="rowClick(item)">
      <div class="col-8 mt-3 mb-3">
        <span class="name_Text">Voucher No {{item.vouNo}}</span>
      </div>
      <div class="col-4 text-right mt-3 mb-3">
        <span class="date_Value mr-2">{{item.vouDate}}</span>
        <img class="img-fluid" src="assets/erods_next_icon.svg" />
      </div>
    </div>
    <div class="row">
      <div class="col-4 mt-1">
        <span class="amt_Text">Erods Amt</span>
      </div>
      <div class="col-4 mt-1">
        <span class="amt_Text">Sales</span>
      </div>
      <div class="col-4 mt-1">
        <span class="amt_Text">Percentage</span>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-4">
        <span class="amt_Value"><img class="img-fluid" src="assets/rupee.png" /> {{item.voucherAmt}}</span>
      </div>
      <div class="col-4">
        <span class="amt_Value"><img class="img-fluid" src="assets/rupee.png" /> {{item.salesAmt}}</span>
      </div>
      <div class="col-4">
        <span class="amt_Value">{{item.percentage}}%</span>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-4 pr-0">
        <span class="amt_Text">L12M Erods Amt</span>
      </div>
      <div class="col-4">
        <span class="amt_Text">L12M Sales</span>
      </div>
      <div class="col-4">
        <span class="amt_Text">Percentage</span>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-4">
        <span class="amt_Value"><img class="img-fluid" src="assets/rupee.png" /> {{item.l12MRODAmt}}</span>
      </div>
      <div class="col-4">
        <span class="amt_Value"><img class="img-fluid" src="assets/rupee.png" /> {{item.l12MSaleAmt}}</span>
      </div>
      <div class="col-4">
        <span class="amt_Value">{{item.l12RODSPer}}%</span>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-4">
        <span class="amt_Text">YTD Erods Amt</span>
      </div>
      <div class="col-4">
        <span class="amt_Text">YTD Sales</span>
      </div>
      <div class="col-4">
        <span class="amt_Text">Percentage</span>
      </div>
    </div>
    <div class="row mt-1 mb-3">
      <div class="col-4">
        <span class="amt_Value"><img class="img-fluid" src="assets/rupee.png" /> {{item.ytdrodAmt}}</span>
      </div>
      <div class="col-4">
        <span class="amt_Value"><img class="img-fluid" src="assets/rupee.png" /> {{item.ytdSaleAmt}}</span>
      </div>
      <div class="col-4">
        <span class="amt_Value">{{item.ytdrodsPer}}%</span>
      </div>
    </div>
  </div>
</div>
<br>
<br>
<br>
<br>
<br>
