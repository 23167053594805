<div>
  <div class="row bg_color">
    <div class="col-12 mt-3">
      <span class="text_16 text_black">From Outlet</span>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-12">
      <span class="text_14 text_black">Outlet</span>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <span class="text_12 text_black" *ngIf="selected != 'VS'">{{outletSelectedA.outletname | titlecase}}</span>
      <span class="text_12 text_black" *ngIf="selected == 'VS'">{{outletSelectedA.outletname | titlecase}}</span>
    </div>
  </div>
  <hr>
  <div class="row mt-3">
    <div class="col-12">
      <span class="text_14 text_black">UID</span>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <span class="text_12 text_black" *ngIf="selected != 'VS'">{{outletSelectedA.outletcode}}</span>
      <span class="text_12 text_black" *ngIf="selected == 'VS'">{{outletSelectedA.outletcode}}</span>
    </div>
  </div>
  <hr>
  <div class="row mt-3">
    <div class="col-12">
      <span class="text_14 text_black">RD</span>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <span class="text_12 text_black">{{outletSelectedA.rdName | titlecase}} {{outletSelectedA.rdcode}}</span>
    </div>
  </div>
  <hr>
  <div class="row mt-3">
    <div class="col-12">
      <span class="text_14 text_black">Visicooler</span>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <span class="text_12 text_black" *ngIf="selected != 'VS'">{{outletSelectedA.visicooler}}</span>
      <span class="text_12 text_black" *ngIf="selected == 'VS'">{{outletSelectedA.visicoolertype}}</span>
    </div>
  </div>
  <div class="row mt-3 bg_color">
    <div class="col-12" style="margin-top: .75rem!important;">
      <span class="text_16 text_black">To Outlet</span>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-12">
      <span class="text_14 text_black">Outlet</span>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <span class="text_12 text_black">{{outletSelectedB.outletname | titlecase}}</span>
    </div>
  </div>
  <hr>
  <div class="row mt-3">
    <div class="col-12">
      <span class="text_14 text_black">UID</span>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <span class="text_12 text_black">{{outletSelectedB.outletcode}}</span>
    </div>
  </div>
  <hr>
  <div class="row mt-3">
    <div class="col-12">
      <span class="text_14 text_black">RD</span>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <span class="text_12 text_black">{{outletSelectedB.rdname | titlecase}} {{outletSelectedB.rdcode}}</span>
    </div>
  </div>
  <hr>
  <div class="row mt-3">
    <div class="col-12">
      <span class="text_14 text_black">Visicooler</span>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <span class="text_12 text_black">{{outletSelectedB.visicooler}}</span>
    </div>
  </div>
  <div class="row mt-3 bg_color">
    <div class="col-12" style="margin-top: .75rem!important;">
      <span class="text_16 text_black">Documents</span>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-8" style="margin-top: 2rem;">
      <span class="text_14 text_black">Cooler Photo</span>
    </div>
    <div class="col-4">
      <img [src]="coolerPhoto" alt="Image" class="img-fluid">
    </div>
  </div>
  <hr>
  <div class="row">
    <div class="col-8" style="margin-top: 2rem;">
      <span class="text_14 text_black">Outlet Exterior Photo</span>
    </div>
    <div class="col-4">
      <img [src]="outletPhoto" alt="Image" class="img-fluid">
    </div>
  </div>
  <hr>
  <div class="row">
    <div class="col-8" style="margin-top: 2rem;">
      <span class="text_14 text_black">Owner ID Proof</span>
    </div>
    <div class="col-4">
      <img [src]="outletIDPhoto" alt="Image" class="img-fluid">
    </div>
  </div>
  <!-- <hr> -->
 <!-- <div class="row">
    <div class="col-8" style="margin-top: 2rem;">
      <span class="text_14 text_black">DPN/E-Agreement</span>
    </div>
    <div class="col-4">
      <img [src]="pagePhoto1" alt="Image" class="img-fluid">
    </div>
  </div>-->
  <!-- <hr>
  <div class="row">
    <div class="col-8" style="margin-top: 2rem;">
      <span class="text_14 text_black">Agreement Page 2</span>
    </div>
    <div class="col-4">
      <img [src]="pagePhoto2" alt="Image" class="img-fluid">
    </div>
  </div> -->
  <hr>
  <br>
  <br>
  <br>
  <br>
  <br>
</div>
<div class="footer">
  <div class="row text-center mt-3">
    <div class="col-12" (click)="rowClick()">
      CONTINUE
    </div>
  </div>
</div>
