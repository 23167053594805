import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
// import { debug } from 'console';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FirebasePushNotificationService {
  currentMessage = new BehaviorSubject(null)
  constructor(private angularFireMessaging: AngularFireMessaging) {
debugger
    this.angularFireMessaging.messages.subscribe(
      (messagingContext:any) => {
        // messagingContext.onMessage = messagingContext.onMessage.bind(messagingContext);
        // messagingContext.onTokenRefresh = messagingContext.onTokenRefresh.bind(messagingContext);

        messagingContext.onMessage = messagingContext?.onMessage?.bind(messagingContext);
        messagingContext.onTokenRefresh = messagingContext?.onTokenRefresh?.bind(messagingContext);

        const NotificationOptions = {
          body: messagingContext.notification.body,
          title: messagingContext.notification.title,
          data: messagingContext.data,
        }
        navigator.serviceWorker.getRegistration('/firebase-cloud-messaging-push-scope').then((registration: any) => {
          registration.showNotification(messagingContext.notification.title, NotificationOptions);
        });
// new code
        
        //end
        this.currentMessage.next(messagingContext)
      }
    );
  }
  requestPermission = () => this.angularFireMessaging.requestToken;
  removeToken = (token: any) => this.angularFireMessaging.deleteToken(token);

  // receiveMessage = () => this.angularFireMessaging.messages;

  receiveMessage() {
      debugger
    console.log('Receive push notification message when application is in foreground');
    this.angularFireMessaging.messages.subscribe((payload: any) => {
      console.log('new message received. ', payload);

      const NotificationOptions = {
        body: payload.notification.body,
        title: payload.notification.title,
        data: payload.data,
      }
      navigator.serviceWorker.getRegistration('/firebase-cloud-messaging-push-scope').then((registration: any) => {
        // alert("inside receive")
        registration.showNotification(payload.notification.title, NotificationOptions);
      });
      this.currentMessage.next(payload)
    }
    );
  }
}
