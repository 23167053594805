import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';
import { DataService } from 'src/app/service/data.service';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';

@Component({
  selector: 'app-outlet-details',
  templateUrl: './outlet-details.component.html',
  styleUrls: ['./outlet-details.component.css']
})
export class OutletDetailsComponent implements OnInit {
  deviceType: any;
  device: any;
  userType: string | null = '';
  userId: any;
  list: any;
  private _searchText = '';
  filteredList: any;
  fieldName: any;
  fieldValue: any;
  token: any;
  outletCodeDetails:any;
  public coolerPhoto:any;
public url:any;

formname:any;
actiondesc:any;
UserType:any;
  constructor(private headerService: HeaderService,private cs:CommonUtilitiesService, private toastr: ToastrService,private router: Router,private mds: MongoDataService,private ds:DataService) { }

  ngOnInit(): void {
    this.headerService.setTitle('Outlet Details');
    this.headerService.setActiveURl('/OutletDetails');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.token = localStorage.getItem("TokenNo");
    this.userType = localStorage.getItem("UserType");
    this.userId = localStorage.getItem("UserID");
    this.fieldName = localStorage.getItem("FieldName");
    this.fieldValue = localStorage.getItem("FieldValue");
    debugger
    this.url=this.mds.url;
    let outletdetail:any=localStorage.getItem("outletCodeDetails")
    this.outletCodeDetails =JSON.parse(outletdetail);
    this.coolerPhoto=this.outletCodeDetails.coolerPhoto;

    this.UserType= localStorage.getItem('UserType');
    this.formname="OutletDetails";
    this.actiondesc="Outlet Details";
    this.cs.GetGeoLocation(this.userId,this.UserType,this.formname,this.actiondesc,this.fieldName,this.fieldValue); 
  }

  downloadDPNAgr() {
    let folderName="eAgreementMaster";
    let fileName=this.outletCodeDetails.vcAgreement;
    this.ds.getDownloadPDFFileFromS3Bucket(folderName,fileName);
  }


}
