import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { DataService } from 'src/app/service/data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-cold-chain-audit-details',
  templateUrl: './cold-chain-audit-details.component.html',
  styleUrls: ['./cold-chain-audit-details.component.css']
})
export class ColdChainAuditDetailsComponent implements OnInit {
  @ViewChild('openModalThankYou', { static: true }) elRefOpenModalThankYou: ElementRef | undefined;
  deviceType: any;
  device: any;
  userType: string | null = '';
  userId: any;
  coldChainAuditDetails: any;
  list: any;
  isReject = false;
  isApprove = false;
  approvedList: any[] = [];
  rejectList: any[] = [];
  isApproveCount: number = 0;
  isRejectCount: number = 0;
  status: string = '';
  selectedimagepath: any;
  showModal: boolean=false;
  photopath:any="https://cilsales.net/SCCAuditImages/";
  token: any;
  formname:any;
  actiondesc:any;
  fieldName: any;
  fieldValue: any;
  constructor(private activatedRoute: ActivatedRoute,private cs:CommonUtilitiesService,
    private router: Router, private headerService: HeaderService, private mds: MongoDataService, private notifyMeService:NotifyMeService, private ds: DataService, private modalService: NgbModal,) { }

  ngOnInit(): void {
    this.headerService.setTitle('Audit Details');
    this.headerService.setActiveURl('/coldChainAuditDetails');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.photopath=localStorage.getItem("baseappurl") + "SCCAuditImages/";
    this.userId = localStorage.getItem("UserID");
    this.token = localStorage.getItem("TokenNo");
    this.coldChainAuditDetails = JSON.parse(localStorage.getItem("coldChainAuditDetails") || '{}');
    this.mds.getUserDetails(this.userId, this.token, this.device, this.deviceType,'Cold Chain Audit').subscribe(res => {
      this.userType=res[0].userType.toUpperCase();
    });

    this.userType = localStorage.getItem("UserType");  
    this.fieldName = localStorage.getItem("FieldName");
    this.fieldValue = localStorage.getItem("FieldValue");

    this.formname="coldChainAuditDetails";
    this.actiondesc="Cold Chain Audit Details"
    this.cs.GetGeoLocation(this.userId,this.userType,this.formname,this.actiondesc,this.fieldName,this.fieldValue);
    this.onLoad();
  }

  onLoad() {
    const dataToSend = {
      ID: this.coldChainAuditDetails.id,
      AuditDate: this.coldChainAuditDetails.auditdate1,
    };
    this.ds.getCCAQuestionMasterDetails(dataToSend, this.token, this.device, this.deviceType, 'Audit Details').subscribe(res => {
      this.list = res;
      this.list.forEach((item: any) => {
          item["isReject"] = this.isReject;
          item["isApprove"] = this.isApprove;
      });
    });
  }

  submit() {
    this.list.forEach((element: any) => {
      if(element.isApprove == true) {
        this.isApproveCount += 1;
        this.approvedList.push(element.questionID);
      }
      if(element.isReject == true) {
        this.isRejectCount += 1;
        this.rejectList.push(element.questionID);
      }
    });
    this.approvedList.push('');
    this.rejectList.push('');

    if(this.isApproveCount > this.isRejectCount){
      this.status = "Approved";
    } else {
      this.status = "Rejected";
    }
    const dataToSend = {
      USERID: this.userId,
      USERTYPE: this.userType,
      STATUS: this.status,
      ID: this.coldChainAuditDetails.id,
      ApprovedQuesID: this.approvedList.toString(),
      RejectedQuesID: this.rejectList.toString(),
    };
    console.log("dataToSend",dataToSend)
    this.ds.setApprovalDetailsForCCA(dataToSend, this.token, this.device, this.deviceType, 'Audit Details').subscribe(res => {
      this.openModal(this.elRefOpenModalThankYou);
    });
  }

  openModal(modal: ElementRef<any> | undefined) {
    this.modalService.open(modal,
      {
        size: 'lg',
        backdrop: 'static',
        windowClass: '',
        keyboard: false,
        centered: true,
      })
  }

  okay(){
    this.modalService.dismissAll();
    this.router.navigate(['/home/coldChainAudit']);
  }

  modalClose() {
    this.modalService.dismissAll();
    this.router.navigate(['/home/coldChainAudit']);
  }

  show(imagepath:any)
  {
    this.selectedimagepath=imagepath;
    this.showModal = true; // Show-Hide Modal Check

  }
  //Bootstrap Modal Close event
  hide()
  {
    this.showModal = false;
  }

}
