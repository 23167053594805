import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { DataService } from 'src/app/service/data.service';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-sub-rd-approval-select-market',
  templateUrl: './sub-rd-approval-select-market.component.html',
  styleUrls: ['./sub-rd-approval-select-market.component.css']
})
export class SubRdApprovalSelectMarketComponent implements OnInit {
  deviceType: any;
  device: any;
  userType: string | null = '';
  userId: any;
  list: any;
  selectedReasons: any;
  marketNameList: any;
  selectedMarketNameList: any[] = [];
  selected = -1;
  formname:any;
  actiondesc:any;
  fieldName: any;
  fieldValue: any;

  constructor(private activatedRoute: ActivatedRoute,private cs:CommonUtilitiesService,  private router: Router, private headerService: HeaderService, private mds: MongoDataService, private ds: DataService,) { }

  ngOnInit(): void {
    this.headerService.setTitle('Select Market');
    this.headerService.setActiveURl('/subRdApprovalSelectMarket');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.userType = localStorage.getItem("UserType");
    this.userId = localStorage.getItem("UserID");
    this.marketNameList = JSON.parse(localStorage.getItem("marketNameList") || '{}');
    console.log("marketNameList",this.marketNameList)

        
    this.fieldName = localStorage.getItem("FieldName");
    this.fieldValue = localStorage.getItem("FieldValue");
    this.formname = "subRdApprovalSelectMarket"
    this.actiondesc = "Sub Rd Approval Select Market";
    this.cs.GetGeoLocation(this.userId, this.userType, this.formname, this.actiondesc, this.fieldName, this.fieldValue);
  }

  selected_Reason(e: any) {
    if (e.target.checked) {
      this.selectedReasons = e.target.value;
      this.marketNameList.forEach((element: any) => {
        if(this.selectedReasons == element.marketName) {
          this.selectedMarketNameList.push(element);
        }
      });
    } else {
      this.selectedMarketNameList = this.selectedMarketNameList.filter((singleItem: { [x: string]: string; }) => singleItem['marketName'] != e.target.value);
    }
    console.log("selectedMarketNameList",this.selectedMarketNameList)
  }

  rowClick() {
    this.router.navigate(['/home/subRdApprovalDetails']);
  }

}
