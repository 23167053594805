<div>
  <div class="row mt-4">
    <div class="col-12">
      <input class="fullWidth" type="text" name="" id="" placeholder="Search Outlet by from Outlet Code" [(ngModel)]="searchTerm">
    </div>
  </div>
  <!-- <div class="row mt-4">
    <div class="col-12">
      <ul>
        <li *ngFor="let c of items | filter : searchText">
          {{c.title}}
        </li>
      </ul>
    </div>
  </div> -->
  <div *ngFor="let item of filteredList">
    <div class="myDiv" [class.myClass]="isSelected">
      <div class="container-fluid">
        <div long-press (onLongPress)="onLongPress()">
          <div class="row">
            <div class="col-12 mt-1">
              <span class="text_16 text_black" style="opacity: 40%;">Request No:</span><span class="ml-1 text_14 text_black" style="opacity: 40%;">{{item.id}}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-12 mt-1">
              <span class="text_16 text_black">From Outlet: {{item.fromOutletName | titlecase}}</span>
              <span class="ml-1 text_14 text_black" style="opacity: 40%;">{{item.fromOutletCode}}</span>
            </div>
            <!-- <div class="col-2 text-right mt-2" *ngIf="isLongPress">
              <img class="img" src="assets/check.png" *ngIf="!isSelected" (click)="clickOnSelect()" />
              <img class="img" src="assets/check_1.png" *ngIf="isSelected" />
            </div> -->
          </div>
          <div class="row mt-1">
            <div class="col-6">
              <span style="opacity: 40%;" class="text_12 text_black">{{item.fromRDName | titlecase}}
                <span class="ml-1 text_12 text_black">{{item.fromRDCode}}</span></span>
            </div>
            <div class="col-4 text-right p-0">
              <span class="text_12 text_black">{{item.fromVisiName}}</span>
            </div>
            <div class="col-2"></div>
          </div>
          <div class="row">
            <div class="col-10">
              <hr>
            </div>
            <div class="col-2 mt-1">
              <img class="img" src="assets/down-arrow_white.png" />
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <span class="text_16 text_black">To Outlet: {{item.toOutletName | titlecase}}</span><span class="ml-1 text_14 text_black"
                style="opacity: 40%;">{{item.toOUtletCode}}</span>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-6 mb-2">
              <span style="opacity: 40%;" class="text_12 text_black">{{item.toRDName | titlecase}}<span
                  class="ml-1 text_12 text_black">{{item.toRDCode}}</span></span>
            </div>
            <div class="col-6 mb-2 text-right">
              <button class="btn btn_custom">
                <img class="btn_img" src="assets/done.png" /> Done
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br>
  <br>
  <br>
  <br>
  <br>
</div>
<div class="footer">
  <div class="row text-center mt-3">
    <div class="col-12" (click)="rowClick()">
      ADD NEW REQUEST
    </div>
  </div>
</div>
