<div class="row" style="margin-top: 2rem;">
  <div class="col-12">
    <span class="rd_Name">{{deviceTaggingSelectPcDetails.pcname | titlecase}}</span>
    <span class="ml-1 rd_Code">{{deviceTaggingSelectPcDetails.pcuid}}</span>
  </div>
</div>
<div class="row" style="margin-top: 2.5rem;">
  <div class="col-12">
    <span class="tag_Device_ID">Tag Device ID</span>
  </div>
</div>
<div class="row mt-3">
  <div class="col-12">
    <input class="fullWidth" type="text" name="" id="" placeholder="Enter Device ID" [(ngModel)]="deviceId">
  </div>
</div>
<div class="footer" *ngIf="deviceId != ''">
  <div class="row">
    <div class="col-6 pr-0" (click)="cancel()">
      <button class="btn btn_CANCEL">CANCEL</button>
    </div>
    <div class="col-6 pl-0" (click)="update()">
      <button class="btn btn_UPDATE">UPDATE</button>
    </div>
  </div>
</div>

<ng-template #openModalThankYou let-modal>
  <div class="modal-header">
    <h4 class="modal-title text_color_head text_20" id="modal-basic-title">
      <img class="img-fluid" src="assets/thanks_icon.png" />
      <span class="ml-3">Thank You</span>
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modalClose()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <span class="model_PopUp_Text">Device ID Updated Successfully for</span>
      </div>
    </div>
    <div class="row mt-4 mb-2">
      <div class="col-12">
        <span class="pc_Name_PopUp">{{deviceTaggingSelectPcDetails.pcname | titlecase}}</span>
        <span class="ml-2 pc_Code_PopUp">{{deviceTaggingSelectPcDetails.pcuid}}</span>
      </div>
    </div>
  </div>
  <div class="modal-footer" (click)="okay()">
    OKAY
  </div>
</ng-template>
