<br><br>

<button mat-raised-button [disabled]="isRegisteredForPush()"
    (click)='registerForPush()'>{{!isRegisteredForPush() ? 'Register for push notification' :'Already Registered'}}</button>
  <button  mat-raised-button  (click)='removeSubscription()' *ngIf='isRegisteredForPush()'>Remove Subscription</button>



  <!-- <mat-form-field>
    <mat-label>Title</mat-label>
    <input matInput #title>
     </mat-form-field>

     <mat-form-field>
      <mat-label>Body</mat-label>
      <input matInput #body>
       </mat-form-field>
  
  <button mat-raised-button (click)="sendNotification(title.value,body.value)">Send Notification</button><br>
  <button mat-raised-button (click)="sendNotificationWithDelay(title.value,body.value)">Send Notification and open new tab</button>
</p>
<pre class='col-lg-6'>
    <code *ngFor='let message of messages'>
      {{message | json}}
    </code>
</pre>
<pre class='col-lg-6'>
  <code>
    {{error | json}}
  </code>
</pre> -->
