import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';

@Component({
  selector: 'app-target-ach',
  templateUrl: './target-ach.component.html',
  styleUrls: ['./target-ach.component.css']
})
export class TargetAchComponent implements OnInit {

  constructor(private headerService: HeaderService) { }

  ngOnInit(): void {
    this.headerService.setTitle('Target Vs Achievement');
  }

}
