import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { MatDialog } from '@angular/material/dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterComponent } from '../filter/filter.component';
import { DataService } from '../../service/data.service';
import { OutputGetVisiAgrOTP,OutputAgreeDetails } from 'src/app/models/kpi-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';
@Component({
  selector: 'app-vc',
  templateUrl: './vc.component.html',
  styleUrls: ['./vc.component.css']
})
export class VcComponent implements OnInit {
  @ViewChild('openModalThankYou', { static: true }) elRefOpenModalThankYou: ElementRef | undefined;
  @ViewChild('openDPNModalThankYou', { static: true }) elRefDPNOpenModalThankYou: ElementRef | undefined;
  @ViewChild('openAGRModalThankYou', { static: true }) elRefAGROpenModalThankYou: ElementRef | undefined;
  @ViewChild('openBothModalThankYou', { static: true }) elRefBothOpenModalThankYou: ElementRef | undefined;
  @ViewChild('openModalAGRSignedin', { static: true }) elRefopenModalAGRSignedin: ElementRef | undefined;
  @ViewChild('openModalDPNSignedin', { static: true }) elRefopenModalDPNSignedin: ElementRef | undefined;


  constructor(private activatedRoute: ActivatedRoute,private cs:CommonUtilitiesService,private fb: FormBuilder,private router: Router,
     private dialog: MatDialog,private ds:DataService,private headerService: HeaderService, private modalService: NgbModal,private deviceService: DeviceDetectorService) 
  { }
  loginForm = this.fb.group({
    otp: ['', [Validators.required]]
  });
  loginFormagr = this.fb.group({   
    agrOTP: ['', [Validators.required]]
  });
  deviceType: any;
  device: any;
  userType: string | null = '';
  userId: any;
  token: any;
  DPNText: string | null = '';
  AgrText: string | null = '';
  submiton:string|null='';
  dpnsubmiton:string|null='';
  agrsubmiton:string|null='';
  IsOTP: boolean = false;
  IsChecked :boolean=false;
  statusobj: OutputGetVisiAgrOTP = new OutputGetVisiAgrOTP();
  agrdetails:OutputAgreeDetails=new OutputAgreeDetails();
  ID:any;
  errormsg: any='';
  IsError: boolean=false;
  KeyID: string|null='';
  OTP:string|null='';
  agrtype:string|null='';
  deviceInfo: any;
  formname:any;
  actiondesc:any;
  fieldName: any;
  fieldValue: any;
  UserType:any;
  ngOnInit(): void {
    debugger;
    
    this.headerService.setTitle('Check Agreement');
    this.headerService.setActiveURl('/vc');
    this.deviceInfo = this.deviceService.getDeviceInfo();
      this.deviceType= this.deviceInfo.deviceType;
      this.device= this.deviceInfo.device;
    this.token = localStorage.getItem("TokenNo");
   this.userId = localStorage.getItem("UserID");
    
   this.UserType = localStorage.getItem("UserType");
   this.fieldName = localStorage.getItem("FieldName");
   this.fieldValue = localStorage.getItem("FieldValue");
   this.formname = "vc"
     this.activatedRoute.paramMap.forEach(params => {
       this.KeyID = params.get('KeyID');
       this.actiondesc ="vc"+" "+this.KeyID;
       this.cs.GetGeoLocation(this.userId, this.UserType, this.formname, this.actiondesc, this.fieldName, this.fieldValue);
       if (this.KeyID != null) {
         if (this.KeyID.length > 0 && this.KeyID.length > 0) {
           this.checkuserlink(this.KeyID);
         }
       }
      
    });
    this.getAgreementDetails();
  }

  checkuserlink(e:any): void{
    const dataToSend = {
      KeyID:e,
    };
    debugger;
     this.ds.ATS_VisiEAgreeCheckLink(dataToSend,this.token,this.device,this.deviceType,'checkDPNAgreementLink').subscribe((res: any) => {
         debugger;
      console.log("ATS_VisiEAgreeCheckLink",res)
      debugger;
      this.statusobj =res; 
      debugger;
      //check for submiton date for DPN and Agreement
      if((res[0].submitOn!="")&&(res[1].submitOn!=""))
      {
        // if((res[0].result=="OK")&&(res[1].result=="OK"))
        // {
          this.IsError=false;
          this.errormsg=res[0].result;
          this.dpnsubmiton =res[0].submitOn; 
          this.agrsubmiton =res[1].submitOn; 
          this.openModal(this.elRefOpenModalThankYou);  
        // }
        // else{this.IsError=true;
        // this.errormsg=res[0].result;
        // }
      }
      //check submiton for DPN 
      if((res[0].submitOn=="")&&(res[0].agreementType=="DPN")){
        this.errormsg="";
       if (res[0].result=="OK")
       {
        this.IsError=false;
         this.ID=res[0].id;
         this.agrtype='DPN';
        this.openModal(this.elRefDPNOpenModalThankYou);  
       }
       else
       {this.IsError=true;
        this.errormsg=res[0].result;}

      }
      //check submiton for Agreement
      else if ((res[1].submitOn=="")&&(res[1].agreementType=="Agreement")){
        this.errormsg="";
if((res[1].result=="OK"))
{
  this.IsError=false;
   this.ID=res[1].id; 
  this.agrtype='Agreement'; 
  this.openModal(this.elRefAGROpenModalThankYou);
}
else{this.IsError=true;
  this.errormsg=res[1].result;}
      }
    //  if((res[0].submitOn!="")&&(res[1].submitOn!=""))
    //  {
    //  this.IsError=false;
    //  this.dpnsubmiton =res[0].submitOn; 
    //  this.agrsubmiton =res[1].submitOn; 
    //  this.openModal(this.elRefOpenModalThankYou);   
    //  }
    // else if((res[0].result=="OK")&&(res[0].submitOn=="")&&(res[0].agreementType=="DPN")){
    //   this.IsError=false;
    //     this.ID=res[0].id;
    //     this.agrtype='DPN';
    //     this.openModal(this.elRefDPNOpenModalThankYou);      
    //       //this.checkuserlink(this.KeyID);
    //   }
    //   else if ((res[1].result=="OK")&&(res[1].submitOn=="")&&(res[1].agreementType=="Agreement")){
    //     this.IsError=false;
    //       this.ID=res[1].id;
    //       this.agrtype='Agreement';
    //       this.openModal(this.elRefAGROpenModalThankYou);
    //      // this.checkuserlink(this.KeyID);
    //     }
         
    });
    //, (error)=>{ this.IsError=true;  this.errormsg="Invalid data"; });
  }

  selected_Reason(e: any) {
    if (e.target.checked) {
      this.IsChecked = e.target.value;        
  }}

  selected_agrcheckbox(e: any){  if (e.target.checked) {
    this.IsChecked = e.target.value;        
}}

  submitOTP(OTP: string,IsChecked:boolean) {
    debugger;
    this.errormsg='';
    if ((OTP == "")||(!IsChecked)) { this.IsOTP = false;IsChecked=false;  } 
    else {this.IsOTP=true;IsChecked=true;
      const dataToSend = {
        KeyID:this.KeyID,
        ID:this.ID,
        OTp:OTP
      };
      this.ds.ATS_VisiEAgreeSetOtp(dataToSend,this.token,this.device,this.deviceType,'ATS_VisiEAgreeSetOtp').subscribe((res: any) => {
        debugger;
     console.log("ATS_VisiEAgreeSetOtp",res)
     debugger;
     this.statusobj =res; 
     if(res[0].result=='OK')
     {this.IsError=false;
    
       this.openModal(this.elRefopenModalDPNSignedin);
    
    }
     else{
     this.errormsg=res[0].result;this.IsError=true;}
    });
    }
  }

  submitagrOTP(OTP: string,IsChecked:boolean) {
    debugger;
    this.errormsg='';
    if ((OTP == "")||(!IsChecked)) { this.IsOTP = false;IsChecked=false;  } 
    else {this.IsOTP=true;IsChecked=true;
      const dataToSend = {
        KeyID:this.KeyID,
        ID:this.ID,
        OTp:OTP
      };
      this.ds.ATS_VisiEAgreeSetOtp(dataToSend,this.token,this.device,this.deviceType,'ATS_VisiEAgreeSetOtp').subscribe((res: any) => {
        debugger;
     console.log("ATS_VisiEAgreeSetOtp",res)
     debugger;
     this.statusobj =res; 
     if(res[0].result=='OK')
     {this.IsError=false;
    
     this.openModal(this.elRefopenModalAGRSignedin);
     }
     else{
     this.errormsg=res[0].result;this.IsError=true;}
    });
      }
  }

  okay(){
    this.modalService.dismissAll();
    this.checkuserlink(this.KeyID);
     }

  modalClose() {
    this.modalService.dismissAll();
    
  }
  openModal(modal: ElementRef<any> | undefined) {
    this.modalService.open(modal,
      {
        size: 'lg',
        backdrop: 'static',
        windowClass: '',
        keyboard: false,
        centered: true,
      })
  }
  

  getAgreementDetails()
  {
    this.ds.ATS_VisiEAgreeGetAgreementdetails(this.token,this.device,this.deviceType,'getAgreementDetails').subscribe((agrdtls: any) => {
    debugger;
 console.log("ATS_VisiEAgreeGetAgreementdetails",agrdtls)
 debugger;
 this.agrdetails=agrdtls;
 this.AgrText=agrdtls[0].details;
this.DPNText=agrdtls[1].details;
debugger;
  });

  }
}