<div class="container-fluid">
  <div class="row mt-4">
    <div class="col-8">
      <i class="fa fa-caret-left" style="color: #757575;">&nbsp;&nbsp;
        <span class="text_16">{{FieldValue=='ALLINDIA'? "All India" : FieldValue | titlecase}}</span>
      </i>
    </div>
    <div class="col-4 text-right" (click)="openFilter()">
      <img src="../../../assets/filter.png" class="img-fluid" />
      <sup><i class="fa fa-circle fa-sm" style="color: #eaab5e;font-size: 5px;"></i></sup>&nbsp;
      <span style="color: #724e8c;font-family: Roboto;font-weight:500 ;font-size: 0.875rem;">Filter</span>
    </div>
  </div>
  <br>
  <div *ngIf="ParaName=='l3m' && !IsPC" class="row mt-2">
    <div class="col-12 text-right">
      <i class="fa fa-circle fa-sm" style="color: #eaab5e; font-size: 6px;">&nbsp;&nbsp;<span
          class="Secondary_text">Billed TM</span></i>&nbsp;&nbsp;
      <i class="fa fa-circle fa-sm" style="color:#f4d5ae; font-size: 6px;">&nbsp;&nbsp;<span
          class="Secondary_text">PC Confirmed for Closure</span></i>&nbsp;&nbsp;
      <i class="fa fa-circle fa-sm" style="color: #ebe6f1; font-size: 6px;">&nbsp;&nbsp;<span
          class="Secondary_text">Unbilled in L3M</span></i>
    </div>
  </div>
  <br>
  <div *ngIf="ParaName=='l3m' && !IsPC">
    <div class="row mt-2" *ngFor="let item of list;let i = index" (click)="RowClick(item.fieldName ,item.fieldvalue)">
     <div class="col-12">
        <div class="row mt-2">
          <div class="col-12">
            <span class="area_name">{{item.desc +' '+ item.fieldvalue | titlecase}}</span>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12">
            <div class="progress rounded-pill">
              <div class="progress-bar bg_sec" role="progressbar" [style.width.%]="item.totalBilledoutlets"
                aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
              <div class="progress-bar bg_pri" role="progressbar" [style.width.%]="item.totalclosedoutlets"
                aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
              <div class="progress-bar bg_tgt" role="progressbar" [style.width.%]="item.totaloutlets" aria-valuenow="25"
                aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
        </div>
        <div class="row mt-2 ">
          <div class="col-4 text-center">
            <span class="text_14 ">{{item.totalBilledoutlets}}</span>
          </div>
          <div class="col-4 text-center">
            <span class="text_14 ">{{item.totalclosedoutlets}}</span>
          </div>
          <div class="col-4 text-center">
            <span class="text_14 ">{{item.totaloutlets}}</span>
          </div>
        </div>
      </div>
      <hr class="hr_cust">
    </div>
  </div>
  <div *ngIf="ParaName=='l6m' && !IsPC">
    <div class="row mt-2" *ngFor="let item of list;let i = index" (click)="RowClick(item.fieldName ,item.fieldvalue)">
     <div class="col-12">
        <div class="row mt-2">
          <div class="col-12">
            <span class="area_name">{{item.desc +' '+ item.fieldvalue | titlecase}}</span>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-9">
            <div class="progress rounded-pill">
              <div class="progress-bar bg_sec" role="progressbar" [style.width.%]="item.totaloutlets"
                aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
          <div class="col-3">
            <span class="text_14">{{item.totaloutlets}}</span>
          </div>
        </div>
      </div>
      <hr class="hr_cust">
    </div>
  </div>
  <div *ngIf="IsPC">
    <div class="row mydiv mt-2">
      <div class="col-12 text-center mt-4">
        <span class="rd_Name_Head">{{FieldValue | titlecase}}</span>
      </div>
      <div class="col-12 text-center">
        <span class="rd_No_Head">{{outletCode}}</span>
      </div>
      <div class="col-4 text-center mt-3">
        <span class="unbilled_value">{{totalOutletsCount}}</span>
      </div>
      <div class="col-4 text-center mt-3">
        <span class="unbilled_value">{{permanentlyClosedCount}}</span>
      </div>
      <div class="col-4 text-center mt-3">
        <span class="unbilled_value">{{TotalBilledOutletCount}}</span>
      </div>
      <div class="col-4 text-center p-0 mb-1" style="margin-top: -10px;">
        <span class="unbilled_text">Unbilled Outlets</span>
      </div>
      <div class="col-4 text-center" style="margin-top: -10px;">
        <span class="unbilled_text">Outlet Closer</span>
      </div>
      <div class="col-4 text-center" style="margin-top: -10px;">
        <span class="unbilled_text">Billed Outlet</span>
      </div>
    </div> 
    <div class="row mt-4">
      <div class="col-12">
        <span class="outlet_Details">Outlet Details</span>
      </div>
    </div>
    <div style="margin-top: 1.25rem;" *ngFor="let item of list;let i = index">
      <div class="row">
        <div class="col-12">
          <span class="outlet_Name">{{item.outletname | titlecase}}</span>
          <span class="ml-1 outlet_No">{{item.outletcode}}</span>
        </div>
      </div>
      <div class="row mt-2" *ngIf="this.ParaName =='l3m'">
        <div class="col-12">
          <span class="beat_Name">Beat Name :</span>
          <span class="ml-1 beat_Value">{{item.beatname | titlecase}}</span>
        </div>
        <!-- <div class="col-8 text-right">
          <span class="ml-1 beat_Value">{{item.beatname | titlecase}}</span>
        </div> -->
      </div>
      <div class="row mt-2" *ngIf="this.ParaName =='l3m'">
        <div class="col-12">
          <span class="beat_Name">SE Reason :</span>
          <span class="ml-1 beat_Value">{{item.sereason}}</span>
        </div>
        <!-- <div class="col-8 text-right">
          <span class="ml-1 beat_Value">{{item.sereason}}</span>
        </div> -->
      </div>
      <div class="row mt-2" *ngIf="this.ParaName =='l3m'">
        <div class="col-12">
          <span class="beat_Name">PC Reason :</span>
          <span class="ml-1 beat_Value">{{item.pcreason}}</span>
        </div>
        <!-- <div class="col-8 text-right">
          <span class="ml-1 beat_Value">{{item.pcreason}}</span>
        </div> -->
      </div>
      <div class="row mt-2" *ngIf="this.ParaName =='l3m'">
        <div class="col-12 text-right">
          <span class="ml-1 beat_Value">{{item.billedamount>0 ? "Bill Outlet" : item.pcreason=='Permanently Closed' ? "PC Cofirmed Closed Outlets" :''}}</span>
        </div>
        <!-- <div class="col-8 text-right">
          <span class="ml-1 beat_Value">{{item.pcreason}}</span>
        </div> -->
      </div>
      <hr class="hr_line">
    </div>
  </div>
  <br>
  <br>
  <br>
  <br>
  <br>
</div>