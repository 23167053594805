import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PCMarketData, MarketData, GetPCOutletList, ResPCOutletList, InputPara } from 'src/app/models/kpi-models';
import { EncryptionService } from '../../service/encryption.service';
import { DataService } from '../../service/data.service';
import { Enclass, ResultClass } from 'src/app/models/encryption-class';
import { AgmCoreModule } from '@agm/core';
import { MatDialog } from '@angular/material/dialog';
import { FilterComponent } from '../filter/filter.component';
import { NotifyMeService } from 'src/app/service/notify-me.service';

@Component({
  selector: 'app-pclocation-details',
  templateUrl: './pclocation-details.component.html',
  styleUrls: ['./pclocation-details.component.css']
})
export class PclocationDetailsComponent implements OnInit {
  input: InputPara = new InputPara();
  FieldName: any;
  FieldValue: any;
  DataType: any;
  UserID: any;
  UserType: any;
  pcData: any;
  outletlist: any;
  RdRoom: any;
  TempRooms: any;
  TempBaseRooms: any;
  BottomShowRooms: any;
  ShowNav: boolean = true;

  ShowSelection: boolean = false;
  SelectedHead: string = '';
  NearMe: string = 'Y';
  iconURL = '../assets/icons/MyLocation.png';
  rdIconURL = '../assets/icons/UrbanIcons/PCLocation.png';
  SelectedrdIconURL = '../assets/icons/UrbanIcons/PCLocation.png';
  outleticonURL = '../assets/icons/UrbanIcons/outlet.png';
  public style: object = {};
  IsFitBounds: boolean = false;

  showSSTCircle: boolean = false;
  showSUBCircle: boolean = false;
  IsShowOutletList: boolean = false;
  circleFillColorArray: string[] = ['CYAN', 'BROWN', 'RED', 'YELLOW', 'PINK', 'WHITE', '#F4F6F0', '#62DOBD'];

  openedWindow: string = '';
  previous: any;
  myBooks: string[] = [];
  userList1: string[] = [];
  lastkeydown1: number = 0;
  showModal: boolean = false;
  origin: any;
  destination: any;
  IsIOs: boolean = false;
  Rdcode: string = '0';
  userData: any;
  stateList: any
  isPC: boolean = true;
  RdCodeCircle: any;
  PcCircle: any;
  OutletRoomsCircle: any;

  reName: any;
  rdName: any;
  PCCircle: any;
  PCiconURL: string = '../assets/icons/UrbanIcons/PC.png';
  OutletIconURL: string = '../assets/icons/UrbanIcons/outlet.png';
  public pcMarketData: PCMarketData = new PCMarketData();
  public marketData: MarketData = new MarketData();
  public enclass: Enclass = new Enclass();
  public resultclass: ResultClass = new ResultClass();
  TokenNo: any;
  ClientID: any;
  SecreteKey: any;
  IsRDValue: boolean = false;
  rdData: any = null;
  lat = 10.0135214;//51.678418;
  lng = 77.4450509;//7.809007;
  zoom = 11;
  lat1 = 10.0135214;//51.678418;
  lng1 = 77.4450509;//7.809007;
  RDCode: string = "";
  RCode: string = "";
  circleFillColorArrayIndex: number = 0;
  IsOIFApproval: boolean = false;
  IsPullout: boolean = false;
  IsATOBTransfer: boolean = false;
  IsFIR: boolean = false;
  ISComplaints: boolean = false;
  IsShowInfoWindow: boolean = false;
  IsShowOutletInfoWindow: boolean = false;
  device: any;
  deviceType: any;
  FormName: any;
  token: any;
  isMarketWorkingViewClicked: any;

  constructor(private activatedRoute: ActivatedRoute, private notifyMeService: NotifyMeService, private fb: FormBuilder, private es: EncryptionService, private router: Router,
    private headerService: HeaderService, private mds: MongoDataService, private ds: DataService, private dialog: MatDialog) { }

  ngOnInit(): void {
    debugger
    this.device = localStorage.getItem('Device');
    this.deviceType = localStorage.getItem('DeviceType');
    this.token = localStorage.getItem("TokenNo");
    this.headerService.setTitle('Active PC Location');
    this.UserType = localStorage.getItem("UserType");
    this.FormName = 'Active PC Location';
    this.DataType = "0"
    this.pcData = [];
    this.outletlist = [];
    this.TokenNo = localStorage.getItem("TokenNo");
    this.ClientID = localStorage.getItem("ClientID");
    this.SecreteKey = localStorage.getItem("SecreteKey");
    this.UserID = localStorage.getItem('UserID');
    this.RCode = "";
    debugger
    this.isMarketWorkingViewClicked = localStorage.getItem("isMarketWorkingViewClicked")
    this.activatedRoute.paramMap.forEach(params => {
      this.FieldName = params.get('FieldName');
      this.FieldValue = params.get('FieldValue');
      this.DataType = params.get('DataType');
      this.rdName = params.get('RName');
      this.lat = 19.016439;
      this.lng = 72.829422;
      this.zoom = 11;
      this.GetGeoLocation();
      this.mapsSelector();
      this.loadData();
    });
  }

  mapsSelector() {
    if ((navigator.platform.indexOf("iPhone") != -1) || (navigator.platform.indexOf("iPad") != -1) || (navigator.platform.indexOf("iPod") != -1)) {
      this.IsIOs = true;
    }
    else {
      this.IsIOs = false;
    }
  }
  GetGeoLocation() {
    if (navigator) {
      this.zoom = 11;
      navigator.geolocation.getCurrentPosition(pos => {

        this.IsFitBounds = false;
        this.lat = pos.coords.latitude;
        this.lng = pos.coords.longitude;

        this.TempRooms = null;
        this.SetapplicationLogData();
      }, this.handleLocationError, { timeout: 30000 });

    }
    else {

      alert("Location Could not be captured");
    }
  }

  handleLocationError(error: any) {
    switch (error.code) {
      case 3:
        alert('Timeout in Position capture');
        // ...deal with timeout
        break;
      case 2:
        alert('Device is not capable of Position capture');
        // ...device can't get data
        break;
      case 1:
        alert('You denied for Position capture');
      // ...user said no ☹️
    }
  }
  SetapplicationLogData() {
    debugger
    let request = {
      "userid": this.UserID,
      "usertype": this.UserType,
      "formname": "pclocation",
      "actiondesc": "pc location" + " " + this.FieldName,
      "fieldname": this.FieldName,
      "fieldvalue": this.FieldValue,
      "latitude": this.lat,
      "longitude": this.lng
    }
    this.ds.SetapplicationLogData(request).subscribe(res => {
      console.log("res", res)
    })
  }
  loadData(): void {
    debugger
    this.input.FieldName = this.FieldName;
    this.input.FieldValue = this.FieldValue;
    this.input.TagName = '0';
    this.input.Datatype = 'details';
    this.input.UserID = this.UserID;
    this.mds.GetMarketData(this.input, this.token, this.device, this.deviceType, this.FormName).subscribe(res => {
      debugger;
      this.isPC = true;
      this.pcData = res;
      this.resultclass = res;
      this.pcData = res;
      console.log("this.pcData", this.pcData)
      this.pcData.map((T: { [x: string]: any; }) => T['iconurl'] = (T['currActivePC'] == 1 ? '../assets/icons/greenpc.png' : T['phyworking'] == 1 ? '../assets/icons/bluepc.png' : '../assets/icons/redepc.png'));
      if (res.length < 1) {
        this.notifyMeService.showInfo("No Lat longs given", "Sales Genie App");
      }
      else if (res.length > 1) {
        this.IsFitBounds = true;
      }
      else {
        this.IsFitBounds = false;
        this.lat = res[0].tlatitude;
        this.lng = res[0].tlongitude;
      }


    });
  }
  ViewOutlet(SelectedID: number, RDCode: string) {
    debugger
    this.mds.GetPcOutletList(Number(RDCode), SelectedID, this.UserID, this.token, this.device, this.deviceType, this.FormName).subscribe(res => {

      this.outletlist = res;
      this.outletlist.map((T: { [x: string]: any; }) => T['iconurl'] = (T['orderValue'] > 0 ? '../assets/icons/greenoutlet.png' : '../assets/icons/UrbanIcons/outlet.png'));

      this.isPC = false;
    });
  }

  PCMarkerClick(slickModal: any, i: number) {
    this.IsShowInfoWindow = true;
    this.IsShowOutletInfoWindow = false;
    slickModal.slickGoTo(i);

  }
  PCOutletMarkerClick(slickModal: any, i: number) {
    this.IsShowOutletInfoWindow = true;
    this.IsShowInfoWindow = false;
    slickModal.slickGoTo(i);

  }

  CloseClick() { this.IsShowInfoWindow = false; }
  CloseOutletClick() { this.IsShowOutletInfoWindow = false; }

  PCClick(SelectedID: number, RDCode: string, slickModal: any) {

    this.mds.GetPcOutletList(Number(RDCode), SelectedID, this.UserID, this.token, this.device, this.deviceType, this.FormName).subscribe(res => {
      this.outletlist = res;
      this.outletlist.map((T: { [x: string]: any; }) => T['iconurl'] = (T['orderValue'] > 0 ? '../assets/icons/greenoutlet.png' : '../assets/icons/UrbanIcons/outlet.png'));
      this.isPC = false;

      slickModal.slickGoTo(2);
    });
  }
  OutletClick(SelectedID: string, lat: number, lng: number) {
    this.ResetIsShowInfoWindow();
  }
  degreeToRadians(degrees: any): number {
    return degrees * Math.PI / 180;
  }
  getDistanceBetweenPoints(lat1: number, lng1: number, lat2: number, lng2: number) {
    let R = 6378137;
    let dLat = this.degreeToRadians(lat2 - lat1);
    let dlng = this.degreeToRadians(lng2 - lng1);
    let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(this.degreeToRadians(lat1)) * Math.cos(this.degreeToRadians(lat1)) * Math.sin(dlng / 2) * Math.sin(dlng / 2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let distance = R * c;
    return distance;
  }
  ClearAllCircle() {
    // if (this.dialog.openDialogs.length == 0) {
    //   const dialogRef = this.dialog.open(ConfirmDailogComponent, {
    //     closeOnNavigation: true,
    //     data: {
    //       "message": "Are you sure to refresh data?",
    //       "title": this.title
    //     }
    //   });
    //   dialogRef.afterClosed().subscribe(result => {
    //     if (result) {
    //       this.clearAll();
    //       for (let obj in this.TempRooms) {
    //         try { this.TempRooms[obj].IsCircleShow = false; } catch { }
    //       }
    //       this.RdRoom.map(singleItem => singleItem['IsCircleShow'] = false);
    //       this.RdRoom.map(singleItem => singleItem['IsShowInfoWindow'] = false);

    //       this.TempRooms.map(singleItem => singleItem['IsCircleShow'] = false);
    //       this.TempRooms.map(singleItem => singleItem['IsShowInfoWindow'] = false);
    //       this.TempRooms.map(singleItem => singleItem['ShowMarkers'] = false);
    //       this.RdCodeCircle = [];
    //       this.PCCircle = [];
    //       this.showDetails('RdCode');
    //       this.IsFitBounds = true;
    //     }
    //   });
    // }
  }
  RemoveCircleAndMarkersOfPC(SelectionType: string, SelectedID: string) {
    let listPC = this.TempRooms.filter((singleItem: { [x: string]: string; }) => singleItem['parameter'] == SelectionType);
    for (let obj in listPC) {
      this.RemoveCircleAndMarkersOfOutlet('Outlet', listPC[obj].paraCode);
    }
    this.TempRooms.map((singleItem: { [x: string]: any; }) => { singleItem['IsCircleShow'] = (singleItem['parameter'] == SelectionType && singleItem['paraCode'] == SelectedID) ? false : singleItem['IsCircleShow'] });
    this.TempRooms.map((singleItem: { [x: string]: any; }) => { singleItem['ShowMarkers'] = (singleItem['parameter'] == SelectionType && singleItem['parentCode'] == SelectedID) ? false : singleItem['ShowMarkers'] });
  }
  RemoveCircleAndMarkersOfOutlet(SelectionType: string, SelectedID: string) {
    this.TempRooms.map((singleItem: { [x: string]: any; }) => { singleItem['IsCircleShow'] = (singleItem['parameter'] == SelectionType && singleItem['paraCode'] == SelectedID) ? false : singleItem['IsCircleShow'] });
    this.TempRooms.map((singleItem: { [x: string]: any; }) => { singleItem['ShowMarkers'] = (singleItem['parameter'] == SelectionType && singleItem['parentCode'] == SelectedID) ? false : singleItem['ShowMarkers'] });
  }
  clearAll() {
    this.circleFillColorArrayIndex = 0;
  }
  ResetTempData(type: string) {

    this.TempRooms.map((singleItem: { [x: string]: boolean; }) => singleItem['ShowWindow'] = false);
    this.TempRooms.map((singleItem: { [x: string]: boolean; }) => singleItem['ShowDetails'] = false);
    this.TempRooms.map((singleItem: { [x: string]: boolean; }) => singleItem['ClickedDetails'] = false);
    this.TempRooms.map((singleItem: { [x: string]: boolean; }) => singleItem['IsShowInfoWindow'] = false);
    this.TempRooms.map((singleItem: { [x: string]: boolean; }) => singleItem['ShowSearch'] = false);
    this.TempRooms.map((singleItem: { [x: string]: boolean; }) => singleItem['ShowMarkers'] = false);
    this.TempRooms.map((singleItem: { [x: string]: boolean; }) => singleItem['IsCircleShow'] = false);
    this.TempRooms.map((singleItem: { [x: string]: number; }) => singleItem['circleDistance'] = 0);
    this.TempRooms.map((singleItem: { [x: string]: string; }) => singleItem['FillColor'] = '');

  }
  ResetIsShowInfoWindow() {
    this.TempRooms.map((singleItem: { [x: string]: boolean; }) => singleItem['IsShowInfoWindow'] = false);
  }
  filterItemsOfType(type: any) {
    return type != null ? type.filter((x: { [x: string]: number; }) => (x['tlatitude'] > 0)) : null;
  }
  filterBootomData(type: any, selectionType: string, SelectionID: string) {
    if (selectionType == 'RD') {
      return type != null ? type.filter((x: { [x: string]: string; }) => (x['parameter'] == selectionType)) : null;
    }
    //  return type != null ? type.filter((x: { [x: any]: boolean; }) => ((x['ClickedDetails'] == true) && (x['parameter'] == selectionType) && (x['parentCode'].startsWith(SelectionID)))) : null;
  }
  showHelp() {
    this.showModal = true;
  }
  hide() {
    this.showModal = false;
  }
  ViewList() {

    this.IsShowOutletList = this.IsShowOutletList == true ? false : true;
  }
  openFilter() {
    if (this.dialog.openDialogs.length == 0) {
      const dialogRef = this.dialog.open(FilterComponent, {
        closeOnNavigation: true,
        data: {
          "FieldName": localStorage.getItem("OriginalFieldName"),
          "FieldValue": localStorage.getItem("OriginalFieldValue"),
          "UserID": this.UserID,
          "MenuUpTo": "4",
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.FieldName = result.FieldName;
          this.FieldValue = result.FieldValue;
          this.loadData();
        }
      });
    }
  }
  slides = [342, 453, 846, 855, 234, 564, 744, 243];

  slideConfig = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "nextArrow": "<div class='nav-btn next-slide'></div>",
    "prevArrow": "<div class='nav-btn prev-slide'></div>",
    "dots": true,
    "infinite": true
  };
  addSlide() {
    this.slides.push(488)
  }
  removeSlide() {
    this.slides.length = this.slides.length - 1;
  }
  slickInit(e: any) {
    console.log('slick initialized');
  }
  breakpoint(e: any) {
    console.log('breakpoint');
  }
  afterChange(e: any) {
    console.log('afterChange');
  }
  beforeChange(e: any) {
    console.log('beforeChange');
  }
  slideclick(id: number, cindex: number): void {
    // if (id == 0 && cindex == 0 && this.IsOIFApproval==true) {
    //   this.gotoOLFApp();
    // }
    // else if (id == 0 && cindex == 1 && this.IsPullout == true) {
    //   this.gotoPullOutRequest();
    // }
    // else if (id == 0 && cindex == 2 && this.IsATOBTransfer == true) {
    //   this.gotoTransfer();
    // }
    // else if (id == 0 && cindex == 3 && this.ISComplaints == true) {
    //   this.gotoComplaint();

    // }
    // else if (id == 0 && cindex == 4 && this.IsFIR == true ) {
    //   this.gotoFIR();
    // }
    // else if (id == 0 && cindex == 5 && this.IsATOBApproval == true) {
    //   this.gotoTransferApproval();
    // }
    // else if (id == 1 && cindex == 0 && this.IsFIR == true) {
    //  // this.gotoVISIScan();
    //   if (this.dialog.openDialogs.length == 0) {
    //     const dialogRef = this.dialog.open(ConfirmDailogComponent, {
    //       closeOnNavigation: true,
    //       data: {
    //         "message": "Comming Soon",
    //         "title": this.Title
    //       }
    //     });
    //     dialogRef.afterClosed().subscribe(result => {
    //       if (result) {
    //       //  this._location.back();
    //       }
    //     });
    //   }
    // }
  }

}
