import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import {InputPara} from 'src/app/models/kpi-models';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';
@Component({
  selector: 'app-npd',
  templateUrl: './npd.component.html',
  styleUrls: ['./npd.component.css']
})
export class NpdComponent implements OnInit {

  deviceType: any;
  device: any;
  fieldName: any ='';
  fieldValue: any ='';
  userID: any ='';
  userName: any ='';
  list: any;
input:InputPara=new InputPara();
  token: any;
  formname:any;
  actiondesc:any;
  UserType:any;

  constructor(private activatedRoute: ActivatedRoute,private cs:CommonUtilitiesService, private router: Router, private headerService: HeaderService, private mds: MongoDataService, ) { }

  ngOnInit(): void {
    this.headerService.setActiveURl('/npd');
    this.headerService.setTitle('Select SKU');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.userID = localStorage.getItem("UserID");
    this.userName = localStorage.getItem("UserName");
    this.token = localStorage.getItem("TokenNo");
    this.fieldName = localStorage.getItem("FieldName");
    console.log("fieldName",this.fieldName)
    this.fieldValue = localStorage.getItem("FieldValue");
    this.UserType= localStorage.getItem('UserType');
    console.log("fieldValue",this.fieldValue)

    this.formname="npd";
    this.actiondesc="npd";
    this.cs.GetGeoLocation(this.userID,this.UserType,this.formname,this.actiondesc,this.fieldName,this.fieldValue);  
    this.loadData();

  }

  loadData() {
    this.input.FieldName=this.fieldName;
    this.input.FieldValue=this.fieldValue;
    this.input.TagName='0';
    this.input.Datatype= '0';
    this.input.UserID=this.userID;
    debugger;
    this.mds.getNpd(this.input, this.token, this.device, this.deviceType,'NPD').subscribe(res => {
      debugger;
      console.log("getNpd",res);
      debugger;
      this.list = res;
    });
  }

  RowClick(fieldName: any, fieldvalue: any, listno: any): void {
    this.router.navigate(['/home/npdData/', fieldName, fieldvalue, listno, this.userID]);
  }
}
