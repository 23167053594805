<div>
  <div class="row mt-4">
    <div class="col-12">
      <span class="text_16 text_black">Outlet</span>
    </div>
    <div class="col-12 mt-1" *ngIf="selected != 'VS'">
      <span class="text_14 text_black">{{outLetSelected.outletname | titlecase}}</span>
      <span class="ml-1 text_14 text_black text_bold">{{outLetSelected.outletcode}}</span>
    </div>
    <div class="col-12 mt-1" *ngIf="selected == 'VS'">
      <span class="text_14 text_black">{{outLetSelected.outletname | titlecase}}</span>
      <span class="ml-1 text_14 text_black text_bold">{{outLetSelected.outletcode}}</span>
    </div>
  </div>
  <hr>
  <div class="row">
    <div class="col-12">
      <span class="text_16 text_black">RD</span>
    </div>
    <div class="col-12 mt-1">
      <span class="text_14 text_black" >{{selected != 'VS' ? outLetSelected.rdname : outLetSelected.rdName | titlecase}}</span>
      <span class="ml-1 text_14 text_black text_bold" *ngIf="selected != 'VS'">{{outLetSelected.rdcode}}</span>
      <span class="ml-1 text_14 text_black text_bold"  *ngIf="selected == 'VS'">{{outLetSelected.rdCode}}</span>
    </div>
  </div>
  <hr>
  <div class="row">
    <div class="col-12">
      <span class="text_16 text_black">Visicooler</span>
    </div>
    <div class="col-12 mt-1">
      <span class="text_14 text_black">{{selected != 'VS'? outLetSelected.visicooler : outLetSelected.visicoolertype}}</span>
    </div>
  </div>
  <hr>
  <div class="row">
    <div class="col-12">
      <span class="text_16 text_black">Visicooler 2</span>
    </div>
    <div class="col-12 mt-1">
      <span class="text_14 text_black">{{selected != 'VS'? outLetSelected.visicooler2 : ""}}</span>
    </div>
  </div>
  <hr *ngIf="selected != 'VS'">
  <div class="row" *ngIf="selected != 'VS'">
    <div class="col-12">
      <span class="text_16 text_black">Contact Person</span>
    </div>
    <div class="col-12 mt-1">
      <span class="text_14 text_black">{{outLetSelected.contactperson}}</span>
    </div>
  </div>
  <hr>
  <div class="row">
    <div class="col-12">
      <span class="text_16 text_black">Address 01</span>
    </div>
    <div class="col-12 mt-1">
      <span class="text_14 text_black">{{outLetSelected.address1 | titlecase}}</span>
    </div>
  </div>
  <hr>
  <div class="row" *ngIf="selected != 'VS'">
    <div class="col-12">
      <span class="text_16 text_black">Sate Name</span>
    </div>
    <div class="col-12 mt-1">
      <span class="text_14 text_black">{{outLetSelected.statename | titlecase}}</span>
    </div>
  </div>
  <hr *ngIf="selected != 'VS'">
  <div class="row" *ngIf="selected != 'VS'">
    <div class="col-12">
      <span class="text_16 text_black">Pin Code</span>
    </div>
    <div class="col-12 mt-1">
      <span class="text_14 text_black">{{outLetSelected.pinCode}}</span>
    </div>
  </div>
  <hr *ngIf="selected != 'VS'">
  <div class="row" *ngIf="selected != 'VS'">
    <div class="col-12">
      <span class="text_16 text_black">Manufacture</span>
    </div>
    <div class="col-12 mt-1">
      <span class="text_14 text_black">{{outLetSelected.visimake | titlecase}}</span>
    </div>
  </div>
  <hr *ngIf="selected != 'VS'">
  <div class="row" *ngIf="selected == 'VS'">
    <div class="col-12">
      <span class="text_16 text_black">FIR Date</span>
    </div>
    <div class="col-12 mt-1">
      <span class="text_14 text_black">{{outLetSelected.firdate | date:'dd/MM/yyyy'}}</span>
    </div>
  </div>
  <hr *ngIf="selected == 'VS'">
  <div class="row" *ngIf="selected == 'VS'">
    <div class="col-12">
      <span class="text_16 text_black">FIR Reason</span>
    </div>
    <div class="col-12 mt-1">
      <span class="text_14 text_black">{{outLetSelected.reason | titlecase}}</span>
    </div>
  </div>
  <hr *ngIf="selected == 'VS'">
  <br>
  <br>
  <br>
  <br>
  <br>
</div>
<div class="footer">
  <div class="row text-center mt-3">
    <div class="col-12" (click)="rowClick()">
      PULL OUT VISICOOLER
    </div>
  </div>
</div>
