import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { DatePipe } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { faLessThanEqual } from '@fortawesome/free-solid-svg-icons';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.css']
})
export class TransactionsComponent implements OnInit {
  @ViewChild('openModalThankYou', { static: true }) elRefOpenModalThankYou: ElementRef | undefined;

  deviceType: any;
  device: any;
  from: any;
  isPullOut: boolean = false;
  isAtoB: boolean = false;
  outLetSelected: any;
  outletSelectedB: any;
  outletSelectedA: any;
  userId: any;
  list: any;
  UserType: any;
  IsOIFApproval: boolean = false;
  IsATOBTransfer: boolean = false;
  IsATOBApproval: boolean = false;
  IsPullout: boolean = false;
  IsFIR: boolean = false;
  ISComplaints: boolean = false;
  IsGateMeeting: boolean = false;
  ISPCCreationSetting: boolean = false;
  IsDeviceTagging: boolean = false;
  token: any;
  formname: any;
  actiondesc: any;
  fieldName: any;
  fieldValue: any;

  constructor(private activatedRoute: ActivatedRoute, private cs: CommonUtilitiesService, private router: Router, private headerService: HeaderService, private datePipe: DatePipe, private mds: MongoDataService, private modalService: NgbModal, private notifyMeService: NotifyMeService,) { }

  ngOnInit(): void {
    this.from = this.activatedRoute.snapshot.queryParams["from"];
    this.headerService.setTitle('Transactions');
    this.headerService.setActiveURl('/transactions');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.userId = localStorage.getItem("UserID");
    this.UserType = localStorage.getItem("UserType");
    this.token = localStorage.getItem("TokenNo");

    this.fieldName = localStorage.getItem("FieldName");
    this.fieldValue = localStorage.getItem("FieldValue");
    this.formname = "transactions"
    this.actiondesc = "Transactions";
    this.cs.GetGeoLocation(this.userId, this.UserType, this.formname, this.actiondesc, this.fieldName, this.fieldValue);
    this.onLoad();

    if (this.from) {
      this.openModal(this.elRefOpenModalThankYou);

    }
    if (this.from == 'pullout') {
      this.isPullOut = true;
      this.outLetSelected = JSON.parse(localStorage.getItem("outLetSelected") || '{}');
    } else {
      this.isAtoB = true;
      this.outletSelectedB = JSON.parse(localStorage.getItem("outletSelectedB") || '{}');
      this.outletSelectedA = JSON.parse(localStorage.getItem("outletSelectedA") || '{}');
    }
  }

  onLoad() {
    this.mds.getUserDetails(this.userId, this.token, this.device, this.deviceType, 'Transactions').subscribe(res => {
      this.list = res;
      this.UserType = this.list[0].userType.toUpperCase();
      this.MenuUserControl();
    });
  }

  clickOnAddEligibleOutlet(): void {
    if (this.list[0].userType == "SO") {
      this.router.navigate(['/home/addEligibleOutlet']);
    } else {
      this.notifyMeService.showWarning("You are not authorized for this transaction", 'Sales Genie App');
    }
  }

  clickOnEligibleOutletSearch() {
    this.router.navigate(['/home/EligibleOutletSerach']);
  }

  clickOnOIF(): void {
    if (this.list[0].userType == "SO") {
      this.router.navigate(['/home/OifApproval']);
    } else {
      this.notifyMeService.showWarning("You are not authorized for this transaction", 'Sales Genie App');
    }
  }
  clickOnPullOutRequest() {
    if (this.list[0].userType == "SO" || this.list[0].userType == "ASM") {
      this.router.navigate(['/home/pulloutTransactions']);
    } else {
      this.notifyMeService.showWarning("You are not authorized for this transaction", 'Sales Genie App');
    }
  }

  clickOnAtoBTransfer() {
    if (this.list[0].userType == "SO" || this.list[0].userType == "CCE" || this.list[0].userType == "ASM") {
      this.router.navigate(['/home/a2bTransactions']);
    } else {
      this.notifyMeService.showWarning("You are not authorized for this transaction", 'Sales Genie App');
    }
  }
  clickOnGateMeeting() {
    // if (this.list[0].userType == "SO") {
    this.router.navigate(['/home/GateMeetinglist']);
    // } else {
    //   this.notifyMeService.showWarning("You are not authorized for this transaction", 'Sales Genie App');
    // }
  }
  clickOncomplaints() {
    this.router.navigate(['/home/complaintlist']);
  }

  clickOnMissingVisicoolerFIR() {
    this.router.navigate(['/home/missingVisicooler']);
  }
  clickOnPCCreationSetting() {
    if (this.list[0].userType == "RSOM") {
      this.router.navigate(['/home/PccrRdlist']);
    } else {
      this.notifyMeService.showWarning("You are not authorized for this transaction", 'Sales Genie App');
    }
  }
  clickOnPCDeviceTagging() {
    if (this.list[0].userType == "ASM" || this.list[0].userType == "SO") {
      this.router.navigate(['/home/pcDeviceTagging']);
    } else {
      this.notifyMeService.showWarning("You are not authorized for this transaction", 'Sales Genie App');
    }
  }

  clickOnQRCodeHistory() {
    // if (this.list[0].userType == "ASM" || this.list[0].userType == "SO") {
    this.router.navigate(['/home/QRCodeHistory']);
    // } 
    // else {
    //   this.notifyMeService.showWarning("You are not authorized for this transaction", 'Sales Genie App');
    // }
  }

  clickOnVCDeploymentStatus() {
    // if (this.list[0].userType == "ASM" || this.list[0].userType == "SO") {
    this.router.navigate(['/home/VCDeploymentStatus']);
    // } else {
    //   this.notifyMeService.showWarning("You are not authorized for this transaction", 'Sales Genie App');
    // }
  }

  clickOnUniverseMapping() {
    this.router.navigate(['/home/surveyApproval']);
  }

  clickOnOutletNameChangeRequest() {
    if (this.list[0].userType?.toLowerCase() == "so" || this.list[0].userType?.toLowerCase() == "asm") {
      this.router.navigate(['/home/outletNameChangeRequest']);
    } else {
      this.notifyMeService.showWarning("You are not authorized for this transaction", 'Sales Genie App');
    }
  }

  clickOnScanQRCode(){
    // if (this.list[0].userType?.toLowerCase() == "so") {
      this.router.navigate(['/home/ScanQrCodeInTransaction']);
    // } else {
    //   this.notifyMeService.showWarning("You are not authorized for this transaction", 'Sales Genie App');
    // }
  }

  openModal(modal: ElementRef<any> | undefined) {
    // console.log('OpenModal called');
    this.modalService.open(modal,
      {
        size: 'lg',
        backdrop: 'static',
        windowClass: '',
        keyboard: false,
        centered: true,
      })
  }

  modalClose() {
    this.modalService.dismissAll();
    this.router.navigate(['/home/transactions']);
  }

  MenuUserControl() {
    this.IsOIFApproval = this.IsATOBTransfer = this.IsATOBApproval = this.IsPullout = this.IsFIR = this.ISComplaints = this.IsGateMeeting = this.ISPCCreationSetting = this.IsDeviceTagging = false;
    if (this.UserType == 'SO') {
      this.IsOIFApproval = this.IsATOBTransfer = this.IsPullout = this.IsGateMeeting = this.IsDeviceTagging = true;
    }
    else if (this.UserType == 'ASM') {
      this.IsPullout = this.IsDeviceTagging = this.IsATOBTransfer = true;
    }
    else if (this.UserType == 'RCCM') {
      this.IsATOBApproval = true;
    }
    else if (this.UserType == 'BCM') {
      this.IsATOBApproval = true;
    }
    else if (this.UserType == 'CCE') {
      this.IsATOBTransfer = true;
    }
    else if (this.UserType == 'RSOM') {
      this.ISPCCreationSetting = true;
    }
    else if (this.UserType == 'BSM') {
      //  this.IsOIFApproval=true;
    }
  }
}
