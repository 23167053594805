import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FilterComponent } from '../filter/filter.component';
import { Resultdata,InputPara } from 'src/app/models/kpi-models';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from 'src/app/service/header.service';
import { DatePipe } from '@angular/common';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-npd-data',
  templateUrl: './npd-data.component.html',
  styleUrls: ['./npd-data.component.css']
})
export class NpdDataComponent implements OnInit {
  input:InputPara=new InputPara();
  deviceType: any;
  device: any;
  FieldName: any;
  FieldValue: any;
  paraName: any;
  userID: any;
  list: any;
  IsRDValue: boolean = false;
  IsPC: boolean = false;
  outletCode: any;
  permanentlyClosedList: any;
  permanentlyClosedCount: any;
  resultdata: Resultdata = new Resultdata();
  totalOutletsCount: any;
  token: any;
  formname:any;
  actiondesc:any;
  UserType:any;

  constructor(private activatedRoute: ActivatedRoute,private cs:CommonUtilitiesService, private router: Router, private headerService: HeaderService, private datePipe: DatePipe, private mds: MongoDataService, private dialog: MatDialog) { }


  ngOnInit(): void {
    this.headerService.setActiveURl('/npdData');
    this.headerService.setTitle('NPD');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.token = localStorage.getItem("TokenNo");
    this.UserType= localStorage.getItem('UserType');
    this.activatedRoute.paramMap.forEach((params: { get: (arg0: string) => any; }) => {
      this.FieldValue = params.get('FieldValue');
      this.FieldName = params.get('FieldName');
      this.userID = params.get('UserID');
      this.paraName = params.get('ParaName');
      this.formname="npdData";
      this.actiondesc="npdData"+" "+this.FieldName;
      this.cs.GetGeoLocation(this.userID,this.UserType,this.formname,this.actiondesc,this.FieldName,this.FieldValue);  
      this.loadData();
    });
  }

  loadData(): void {
    this.input.FieldName=this.FieldName;
    this.input.FieldValue=this.FieldValue;
    this.input.TagName=this.paraName;
    this.input.Datatype=this.paraName;
    this.input.UserID=this.userID;
    debugger;
    this.mds.getNpd(this.input, this.token, this.device, this.deviceType,'NPD Data').subscribe(res => {
      debugger;
      console.log("getNpd", res);
      this.list = res;
      if (this.list[0].fieldname == "rd") {
        this.IsRDValue = true;
      } else {
        this.IsRDValue = false;
      }
      console.log("IsRDValue", this.IsRDValue);
    });
  }

  openFilter() {
    if (this.dialog.openDialogs.length == 0) {
      const dialogRef = this.dialog.open(FilterComponent, {
        closeOnNavigation: true,
        data: {
          "FieldName": localStorage.getItem("OriginalFieldName"),
          "FieldValue": localStorage.getItem("OriginalFieldValue"),
          "UserID": this.userID,
          "MenuUpTo": "5",
        }

      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.resultdata = result as Resultdata;
          this.FieldName = this.resultdata.FieldName;
          this.FieldValue = this.resultdata.FieldValue;

              this.loadData();
        }
      });
    }
  }

  RowClick(fieldName: any, fieldvalue: any): void {
    this.router.navigate(['/home/npdData/', fieldName, fieldvalue, this.paraName, this.userID]);
  }

}
