<div class="row mt-4 mb-3">
    <div class="col-8">
        <input type="text" class="UID" #UID placeholder="Search Outlet UID" (keypress)="numberOnly($event)" />
    </div>
    <div class="col-4">
        <button class="btn btn_GO" (click)="GOBtn(UID)">GO</button>
    </div>
</div>

<div class="" *ngIf="filteredList[0]?.Outletname!=null">
    <div class="row mt-5">
        <div class="col-6">
            <span class="outlet_Code">Rd Code</span>
        </div>
        <div class="col-6 text-end">
            <span class="outlet_Name ml-1">{{filteredList[0]?.RdCode}}</span>
        </div>
    </div>
    <hr>

    <div class="row mt-2">
        <div class="col-6">
            <span class="outlet_Code">Rd Name</span>
        </div>
        <div class="col-6 text-end">
            <span class="outlet_Name ml-1">{{filteredList[0]?.RdName}}</span>
        </div>
    </div>
    <hr>
    <div class="row mt-2">
        <div class="col-6">
            <span class="outlet_Code">Outlet Code</span>
        </div>
        <div class="col-6 text-end">
            <span class="outlet_Name ml-1">{{filteredList[0]?.Outletcode}}</span>
        </div>
    </div>
    <hr>

    <div class="row mt-2">
        <div class="col-6">
            <span class="outlet_Code">Outlet Name</span>
        </div>
        <div class="col-6 text-end">
            <span class="outlet_Name ml-1"> {{filteredList[0]?.Outletname}}</span>
        </div>
    </div>
    <hr>


    <div class="row mt-2 ">
        <div class="col-6">
            <span class="outlet_Code">Mobile No</span>
        </div>
        <div class="col-6 text-end">
            <span class="outlet_Name ml-1 " style="word-wrap: break-word;">
                {{filteredList[0]?.MobileNo?.split("T")[1]}}
            </span>
        </div>
    </div>
    <hr>

    <div class="row mt-2">
        <div class="col-6">
            <span class="outlet_Code">Visicooler</span>
        </div>
        <div class="col-6 text-end">
            <span class="outlet_Name ml-1">{{filteredList[0]?.Visicooler}}</span>
        </div>
    </div>
    <hr>

    <div class="row mt-2">
        <div class="col-6">
            <span class="outlet_Code">RE</span>
        </div>
        <div class="col-6 text-end">
            <span class="outlet_Name ml-1">{{filteredList[0]?.Re}}</span>
        </div>
    </div>
    <hr>


    <div class="row mt-2">
        <div class="col-6">
            <span class="outlet_Code">Program</span>
        </div>
        <div class="col-6 text-end">
            <span class="outlet_Name ml-1">{{filteredList[0]?.PP}}</span>
        </div>
    </div>
    <hr>
</div>
<br>


<div class="footer">
    <div class="row">
        <div class="col-12" (click)="changeOuteletName()">
            <button class="btn btn_APPROVE" >CHANGE OUTLET NAME</button>
        </div>
    </div>
</div>