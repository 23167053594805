import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { DatePipe } from '@angular/common';
import { DataService } from 'src/app/service/data.service';
import { ToastrService } from 'ngx-toastr';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-outlet-detail-one-atob',
  templateUrl: './outlet-detail-one-atob.component.html',
  styleUrls: ['./outlet-detail-one-atob.component.css']
})
export class OutletDetailOneAtobComponent implements OnInit {

  deviceType: any;
  device: any;
  searchText = '';
  isLongPress: boolean = false;
  isSelected: boolean = false;
  outletSelectedB: any;
  outletSelectedA: any;
  selected: string = '';
  formname: any;
  actiondesc: any;
  UserType: any;
  fieldName: any;
  fieldValue: any;
  userId: any;
  @ViewChild('openModalVerifiedOutlet', { static: true }) elRefopenModalVerifiedOutlet: ElementRef | undefined;

  constructor(private activatedRoute: ActivatedRoute, private router: Router,
    private headerService: HeaderService, private datePipe: DatePipe, private cs: CommonUtilitiesService,
    private mds: MongoDataService, private ds: DataService, private toastr: ToastrService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.headerService.setTitle('Outlet Detail');
    this.headerService.setActiveURl('/outletDetailOneAtob');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.userId = localStorage.getItem("UserID");
    this.UserType = localStorage.getItem('UserType');
    this.fieldName = localStorage.getItem("FieldName");
    this.fieldValue = localStorage.getItem("FieldValue");
    this.outletSelectedB = JSON.parse(localStorage.getItem("outletSelectedB") || '{}');
    this.outletSelectedA = JSON.parse(localStorage.getItem("outletSelectedA") || '{}');
    this.selected = this.activatedRoute.snapshot.queryParams["selected"];
    this.outletSelectedB.cellno = this.outletSelectedB.cellno.replace(/\D/g, '')
    console.log("selected", this.selected);

    this.formname = "outletDetailOneAtob";
    this.actiondesc = "Outlet Detail One Atob";
    this.cs.GetGeoLocation(this.userId, this.UserType, this.formname, this.actiondesc, this.fieldName, this.fieldValue);
  }

  onLongPress() {
    this.isLongPress = true;
  }

  clickOnSelect() {
    this.isSelected = true;
  }

  openModal(modal: ElementRef<any> | undefined) {
    this.modalService.open(modal,
      {
        size: 'lg',
        backdrop: 'static',
        windowClass: '',
        keyboard: false,
        centered: true,
      })
  }

  okay() {
    this.modalService.dismissAll();
    this.router.navigate(['/home/toOutletAtob'])
  }


  rowClick() {
    // if (!this.outletSelectedB?.verified) {
    //   this.openModal(this.elRefopenModalVerifiedOutlet);
    //   return
    // }
    let fromOutletCode = this.outletSelectedA.outletcode;
    let toOutletCode = this.outletSelectedB.outletcode;

    this.ds.ma_atobcheckoutletcode(fromOutletCode, toOutletCode).subscribe((res) => {
      if (res[0].msg == "OK") {
        if (this.selected == 'VS') {
          this.router.navigate(['/home/outletDetailTwoAtob'], { queryParams: { selected: this.selected } });
        } else {
          this.router.navigate(['/home/outletDetailTwoAtob']);
        }
      }
      else {
        this.toastr.warning(res[0].msg, "Warning");
      }
    })
  }

}
