import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { DatePipe } from '@angular/common';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-pullout-transactions',
  templateUrl: './pullout-transactions.component.html',
  styleUrls: ['./pullout-transactions.component.css']
})
export class PulloutTransactionsComponent implements OnInit {

  deviceType: any;
  device: any;
  isLongPress: boolean = false;
  isSelected: boolean = false;
  list:any;
  userType: string | null = '';
  userId: any;
  private _searchText = '';
  filteredList: any;
  token: any;
  formname:any;
  actiondesc:any;
  FieldName:any;
  FieldValue:any;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private headerService: HeaderService, private datePipe: DatePipe,
     private mds: MongoDataService,private cs:CommonUtilitiesService) { }

  ngOnInit(): void {
    this.headerService.setTitle('Pull Out Request');
    this.headerService.setActiveURl('/pulloutTransactions');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.userId = localStorage.getItem("UserID");
    this.userType = localStorage.getItem("UserType");
    this.FieldName = localStorage.getItem("FieldName");
    this.FieldValue = localStorage.getItem("FieldValue");
    this.token = localStorage.getItem("TokenNo");
    debugger
    this.formname="pulloutTransactions";
    this.actiondesc="Pull Out Transactions"
    this.cs.GetGeoLocation(this.userId,this.userType,this.formname,this.actiondesc,this.FieldName,this.FieldValue);
    this.onLoad();
  }

  onLoad() {
    let id =0;
    this.mds.getPendingPulloutList(this.userId,this.token, this.userType, id, this.device, this.deviceType,'Pull Out Request').subscribe(res => {
      console.log("atsGetPendingPulloutList",res);
      this.list = res;
      this.filteredList = this.list;
    });
  }

  onLongPress() {
    this.isLongPress = true;
  }

  clickOnSelect() {
    this.isSelected = true;
  }

  rowClick() {
    this.router.navigate(['/home/searchOutletPulloutreq']);
  }

  get searchTerm(): any{
    return this._searchText;
  }
  set searchTerm(value: any){
     this._searchText = value;
     this.filteredList = this.filteredListMethod(value);
  }

  filteredListMethod(value: any): any {
    return this.list.filter((ele: any) =>
      ele.outletCode.indexOf(value) !== -1);
  }
}
