import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';

@Component({
  selector: 'app-market-working-outlet-list',
  templateUrl: './market-working-outlet-list.component.html',
  styleUrls: ['./market-working-outlet-list.component.css']
})
export class MarketWorkingOutletListComponent implements OnInit {
  deviceType: any;
  device: any;
  userType: string | null = '';
  userId: any;
  list: any;
  filteredList: any;
  firMenuCountList: any;
  primarynos: any;
  secondarynos: any;
  token: any;
  _searchText = '';
  public marketWorkingList: any = [
    {
      rdname: "BOBBY STORE",
      rdcode: "1006971",
      totalachievment: "50",
      achievment: "50",
      target: "50"
    },

    {
      rdname: "VIG STORE",
      rdcode: "1006973",
      totalachievment: "50",
      achievment: "50",
      target: "50"
    },
    {
      rdname: "CHHABRA STORE 0109",
      rdcode: "1006976",
      totalachievment: "50",
      achievment: "50",
      target: "50"
    },
 
  ];
  myBooks: any;
  userList1: any;
  lastkeydown1: number = 0;
  listOrigin: any = [];
  selectedText: any;
  public beatListData:any;
  formname:any;
  actiondesc:any;
  constructor(private router: Router,private cs:CommonUtilitiesService, private headerService: HeaderService, private mds: MongoDataService,) { }

  ngOnInit(): void {
    this.headerService.setTitle('Market Ka Saathi');
    this.headerService.setActiveURl('/marketWorkingOutletList');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.userType = localStorage.getItem("UserType");
    this.userId = localStorage.getItem("UserID");
    this.token = localStorage.getItem("TokenNo");
    let beatListData: any = localStorage.getItem("marketWorkingBeatListData");
    this.beatListData = JSON.parse(beatListData);
    debugger
    this.formname="MarketWorkingOutletListData";
    this.actiondesc="Market Working Outlet List"+" "+this.beatListData.beatuid;
    this.cs.GetGeoLocation(this.userId,this.userType,this.formname,this.actiondesc,'beat',this.beatListData.beatuid);
    this.loadData();
  }

  
  loadData() {
    // this.list = this.marketWorkingList;
    // this.filteredList = this.list;
debugger
    let request =
    {
      "FieldName": "beat",
      "FieldValue": this.beatListData.beatuid.toString(),
      "Datatype": "",
      "TagName": "",
      "UserID": this.userId
    }

    this.mds.MarketWorking_GetOutletListData(request, this.token, this.device, this.deviceType, 'Market Ka Sathi Outlet List').subscribe(res => {
      this.list = res;
      this.listOrigin= this.filteredList = this.list;
      // this.fillAutoCompleteSearchBox()
      console.log("getOutletdata", this.filteredList)
      ;
    });
}

  get searchTerm(): any {
    return this._searchText;
  }
  set searchTerm(value: any) {
    this._searchText = value;
    this.filteredList = this.filteredListMethod(value);
  }

  filteredListMethod(value: any): any {
    return this.list.filter((ele: any) =>
      ele.outletname.toUpperCase().indexOf(value.toUpperCase()) !== -1 || ele.outletcode.toString().toUpperCase().indexOf(value.toUpperCase()) !== -1);
  }

  clickOnOutletList(item:any){
    localStorage.setItem("marketWorkingOutletListData", JSON.stringify(item))
    this.router.navigate(['/home/MarketWorkingOutletListData']); 
   }

  //  fillAutoCompleteSearchBox() {
  //   this.myBooks = [];
  //   let mybook1: any = [...new Set(this.listOrigin.map((bill: { outletname: string; outletcode: number; }) => (bill.outletname + ' [' + bill.outletcode + '] ')))];
  //   this.myBooks = [].concat.apply([], mybook1);

  // }
  // getUserIdsFirstWay($event: any) {
  //   let userId = (<HTMLInputElement>document.getElementById('userIdFirstWay')).value;
  //   this.userList1 = [];
  //   if (userId.length > 2) {
  //     if ($event.timeStamp - this.lastkeydown1 > 200) {
  //       this.userList1 = this.searchFromArray(this.myBooks, userId);
  //     }
  //   }
  // }
  // searchFromArray(arr: any, regex: any) {
  //   let matches = [], i, j = 0;
  //   for (i = 0; i < arr.length; i++) {
  //     if (arr[i].toLowerCase().match(regex.toLowerCase())) {
  //       matches.push(arr[i]);
  //       j++;
  //       i = j < 10 ? i : arr.length;
  //     }
  //   }
  //   return matches;
  // };
  // SearchText(e: any): void {
  //   debugger
  //   this.selectedText = e.target.value;
  //   let selectingType = this.selectedText.substring(this.selectedText.indexOf('[') + 1, this.selectedText.indexOf(']'));
  //   let item = this.listOrigin.filter((ele: any) => { return ele.outletcode == selectingType })[0];

  //   this.clickOnOutletList(item);
  //   (<HTMLInputElement>document.getElementById('userIdFirstWay')).value = '';
  // }

}
