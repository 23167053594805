<!-- <div id="container-fluid"> -->
  <div class="agmHead">
    <div class="row mt-2">
      <div class="col-9"> &nbsp;&nbsp;
          <i class="fa fa-caret-left" style="color: #757575;">&nbsp;
              <span class="header_text">{{FieldValue=='0'? 'All India' : FieldValue=='ALLINDIA' ? 'All India': FieldValue |titlecase}}</span></i>
      </div>        
      <div class="col-3 text-left">
          <img src="../../../assets/filter.png" class="img-fluid" (click)="openFilter()" /><sup><i
              class="fa fa-circle fa-sm" style="color: #eaab5e;font-size: 5px;"></i></sup>&nbsp;<span
            style="color: #724e8c;font-weight:400 ;font-size: 12px;">Filter</span>
        </div>
  </div>
  <div class="row mt-2">
    <div class="col-12 text-center">
      <span class="menu1">All</span>
      <span class="menu2"><i class="fa fa-circle fa-sm" style="color:#0fc755dc; font-size: 7px;"></i><span class="menutextmap">{{isTsi ? '30min Act' : 'FTD Billed Outlet'}}</span></span>
      <span class="menu2" *ngIf="isTsi"><i class="fa fa-circle fa-sm" style="color:blue; font-size: 7px;" *ngIf="isTsi"></i><span class="menutextmap">Active since Morning</span></span>
      <span class="menu2"><i class="fa fa-circle fa-sm" style="color: #f03434; font-size: 7px;" ></i><span class="menutextmap">{{isTsi ? 'Not Active' : 'UnBilled Outlet'}}</span></span>
    </div>
  </div>
  </div>
  <span class="header_text">{{FieldName}}:{{FieldValue}}</span>  
  <div class="map p-0" #map>
    <agm-map style="width:100vw; height:100vh;" [latitude]="lat" [longitude]="lng" [style.height.px]="map.offsetHeight" [style.width.px]="map.offsetWidth"  [fitBounds]="IsFitBounds ">
      <div *ngIf="isTsi">
        <agm-marker  *ngFor="let obj of tsiData;let i = index" [longitude]="obj.tlongitude >0 ? obj.tlongitude : null "  [latitude]="obj.tlatitude"  [iconUrl]="obj.iconurl" [agmFitBounds]="true"  (markerClick)="TSIMarkerClick(slickModal,i)">
        </agm-marker> 
      </div>
        <div *ngIf="!isTsi">
         <agm-marker *ngFor="let data of outletlist;let j = index" [latitude]="data.latitude > 1.2 ? data.latitude : null  " [longitude]="data.longitude"  [agmFitBounds]="true"  [iconUrl]="data.iconurl" (markerClick)="TSIOutletMarkerClick(slickModaloutlet,j)" >
        </agm-marker>
      
      </div>
      </agm-map>
    
</div>
 
<div class="divMenu"  [style.visibility]="IsShowInfoWindow ? 'visible' : 'hidden'">
       <ngx-slick-carousel class="carousel"  #slickModal="slick-carousel" [config]="slideConfig" (init)="slickInit($event)"
                          (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)" (beforeChange)="beforeChange($event)">
        <div ngxSlickItem *ngFor="let slide of tsiData" class="slide">
         <div class="row">
           <div class="col-12 text-center">
         <div class="divinfowindow">
          <div class="row mt-2">
            <div class="col-8">
              <span [class]="slide.currActivePC==1 ? 'l3m' : slide.activePC==1 ? 'pworking' :'active' ">{{slide.currActivePC==1 ? 'Last 30 min Active' : slide.activePC==1?'Active since Morning':'Not Active' }}</span> 
        
        </div>
        <div class="col-4 text-right" style="margin-left: -13px;">
        <i class="fa fa-window-close" aria-hidden="true" (click)="CloseClick()"></i></div>
    
        </div>
          <div class="row mt-2">
            <div class="col-12 text-center">
         <span class="pcName"> {{slide.desc|titlecase}}</span> <span class="value_text"> ({{slide.fieldvalue}})</span>
        </div>
          </div>
          <div class="row mt-2">
            <div class="col-1"></div>
            <div class="col-5">
          <Span  class="text text-left">Order: {{slide.sales}}</Span> </div>
          <div class="col-5">
          <Span  class="text text-right">No OfOrder: {{slide.noOfOrder}}</Span> 
           </div>
           <div class="col-1"></div>
          </div>
        <div class="row mt-2">
          <div class="col-1"></div>
          <div class="col-5 text-left"  *ngIf="slide.tlatitude>0">
           <a [href]="IsIOs ? 'http://maps.apple.com/?saddr=' + lat + ',' + lng + '&daddr=' + slide.Tlatitude + ',' + slide.TLongitude + '&dirflg=r' : 'https://www.google.com/maps/dir/' + lat + ',' + lng + '/' + slide.Tlatitude + ',' + slide.TLongitude + '/@' + slide.Tlatitude + ',' + slide.TLongitude" target="_blank">
         <button type="button" class="btn button ">Take me there</button>
            </a>
          </div>
          <div class="col-5 text-left"  *ngIf="slide.tlatitude ==0"> &nbsp;
           </div>
 
           <div class="col-5 text-right">
              <button type="button" class="btn button" (click)="ViewOutlet(slide.fieldvalue,FieldValue)">View Outlets</button>
            </div>
            <div class="col-1"></div>
      </div>
         </div>
        </div>
        </div>
        </div>
      </ngx-slick-carousel> 
    </div>



     <div class="divOutletMenu"  [style.visibility]="IsShowOutletInfoWindow ? 'visible' : 'hidden'">
      <ngx-slick-carousel class="carousel"  #slickModaloutlet="slick-carousel" [config]="slideConfig" (init)="slickInit($event)"
                          (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)" (beforeChange)="beforeChange($event)">
        <div ngxSlickItem *ngFor="let slide of outletlist" class="slide">
         <div class="row">
           <div class="col-12 text-center">
         <div class="divinfowindow">
          <div class="row mt-2">
            <div class="col-8">
              <span [class]="slide.orderValue>0 ? 'pworking' :'active' ">{{slide.orderValue>0 ? 'FTD Billed Outlets':'Unbill outlet' }}</span> 

        </div>
        <div class="col-4 text-right" style="margin-left: -13px;">
        <i class="fa fa-window-close" aria-hidden="true" (click)="CloseOutletClick()"></i></div>
        </div>
          <div class="row">
            <div class="col-12">
         <span class="pcName"> {{slide.marketName |titlecase}}</span>  <span class="value_text">  ({{slide.marketUID}})</span>
        </div>
          </div>
          <div class="row mt-2">
            <div class="col-1"></div>
            <div class="col-10 text-left p-0">
          <Span  class="text">Outlet : {{slide.outletName}}-({{slide.outletcode}})</Span></div>
          
          </div>
          <div class="row mt-2">
            <div class="col-1"></div>
            <div class="col-5 text-left p-0">
          <Span  class="text">Order Value: {{slide.orderValue}}</Span></div>
          <div class="col-5">
          <!-- <Span  class="text text-right">TSI UID: {{slide.Outletcode}}</Span>  -->
           </div>
          </div>
        <div class="row mt-2">
          <div class="col-1"></div>
          <div class="col-10 text-center"  *ngIf="slide.latitude >0">
           <a [href]="IsIOs ? 'http://maps.apple.com/?saddr=' + lat + ',' + lng + '&daddr=' + slide.latitude + ',' + slide.longitude + '&dirflg=r' : 'https://www.google.com/maps/dir/' + lat + ',' + lng + '/' + slide.latitude + ',' + slide.longitude + '/@' + slide.latitude + ',' + slide.longitude" target="_blank">
         <button type="button" class="btn button ">Take me there</button>
            </a>
          </div>
            <div class="col-1"></div>
      </div>
         </div>
        </div>
        </div>
        </div>
      </ngx-slick-carousel>
    </div> 
    <div class="viewlist"></div>

    <img [src]="IsShowOutletList ? '../../../assets/viewmap.svg' : '../../../assets/viewlist.svg'"  [class]="IsShowInfoWindow || IsShowOutletInfoWindow ? 'viewlistafteriw' : 'viewlist' " (click)="ViewList()" >
    <div class="row">
    <div class="col-12 outletlist_box border" *ngIf="IsShowOutletList">
      <div class="row">
        <div class="col-12 text-center">
          <img src="../../../assets/line45.svg"  class="Lineinoutletlist">
        </div>
      </div>
      <div  *ngIf="isTsi"> 
         <div class="row">
        <div class="col-6 text-left">
          <span class="Outlet-List">TSI List</span>
        </div>
        <div class="col-6 text-right">
          <span class="FTD-Value">Status</span>
        </div>
      </div> 
      <div class="pcblock">
      <div class="row mt-4" *ngFor="let slide of tsiData" >
       
        <div class="col-9 text-left">
          <span class="PCDetails">{{slide.desc |titlecase}}</span>
          <span class="pcuidcss">&nbsp;{{slide.fieldvalue}}</span>
        </div>
        <div class="col-3 text-right">
          <span > <i [class]="slide.currActivePC==1 ? 'fa fa-circle fa-sm greendot' : slide.activePC==1 ? 'fa fa-circle fa-sm bluedot' :'fa fa-circle fa-sm reddot'"></i></span>
        </div>
    <div class="Divider"></div>
      </div> 
      <br><br><br><br>  
    </div>
    </div>
    <div  *ngIf="!isTsi"> 
      <div class="row">
     <div class="col-6 text-left">
       <span class="Outlet-List">Outlet List</span>
     </div>
     <div class="col-6 text-right">
       <span class="FTD-Value">Order Value (&#8360;)</span>
     </div>
    </div> 
    <div class="pcblock">
      <div class="row mt-4" *ngFor="let slide of outletlist" >
       
        <div class="col-9 text-left">
          <span class="PCDetails">{{slide.outletName |titlecase}}</span>
          <span class="pcuidcss">&nbsp;{{slide.outletcode}}</span>
        </div>
        <div class="col-3 text-right">
          <span>{{slide.orderValue}}</span> 
        </div>
    <div class="Divider"></div>
      </div> 
      <br><br><br><br>  
    </div>
    
  </div>
 
</div>
    </div> 
    