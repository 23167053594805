import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { DataService } from 'src/app/service/data.service';
import { InputPara } from 'src/app/models/kpi-models';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-pc-device-tagging',
  templateUrl: './pc-device-tagging.component.html',
  styleUrls: ['./pc-device-tagging.component.css']
})
export class PcDeviceTaggingComponent implements OnInit {
  deviceType: any;
  device: any;
  userType: string | null = '';
  userId: any;
  fieldName: any;
  fieldValue: any;
  list: any;
  msg: any;
  private _searchText = '';
  filteredList: any;
  input:InputPara=new InputPara();
  count: any = 0;
  token: any;
  formname: any;
  actiondesc: any;
  constructor(private activatedRoute: ActivatedRoute,private cs:CommonUtilitiesService,  private router: Router, private headerService: HeaderService, private mds: MongoDataService, private notifyMeService:NotifyMeService, private ds: DataService,) { }

  ngOnInit(): void {
    this.headerService.setTitle('PC/TSI Device Tagging');
    this.headerService.setActiveURl('/pcDeviceTagging');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.token = localStorage.getItem("TokenNo");
    this.userType = localStorage.getItem("UserType");
    this.userId = localStorage.getItem("UserID");
    this.fieldName=localStorage.getItem("FieldName");
    this.fieldValue=localStorage.getItem("FieldValue");

  
 
    this.formname = "pcDeviceTagging";
    this.actiondesc = "Pc Device Tagging";
    this.cs.GetGeoLocation(this.userId, this.userType, this.formname, this.actiondesc, this.fieldName, this.fieldValue);
    this.onLoad();
  }

  onLoad() {
    this.input.FieldName=this.fieldName;
    this.input.FieldValue=this.fieldValue;
    this.input.TagName='0';
    this.input.Datatype='0';
    this.input.UserID=this.userId;
    this.mds.getRdList(this.input, this.token, this.device, this.deviceType, 'pcDeviceTagging').subscribe(res => {
      this.list = res;
      this.filteredList = this.list;
      if(this.list.length > 0) {
        this.count = this.list.length;
      }
    });
  }

  rowClick(data: any){
    localStorage.setItem("DeviceTaggingDetails",JSON.stringify(data))
    this.router.navigate(['/home/pcDeviceTaggingSelectPc']);
  }

  get searchTerm(): any{
    return this._searchText;
  }
  set searchTerm(value: any){
     this._searchText = value;
     this.filteredList = this.filteredListMethod(value);
  }

  filteredListMethod(value: any): any {
    return this.list.filter((ele: any) =>
      ele.rdname.toUpperCase().indexOf(value.toUpperCase()) !== -1);
  }
}
