<div>
  <div class="row mt-4">
    <div class="col-12">
      <!-- <input class="fullWidth" type="text" name="" id="" placeholder="Search Outlet by Outlet Code" [(ngModel)]="searchTerm"> -->
    </div>
  </div>
  <div *ngFor="let item of accountlist; let i = index;" (click)="rowClick(item.id)">
    <div class="row mt-3">
      <div class="col-9">
        <span class="rd_Name">{{item.accountName | titlecase}}</span>

      </div>
      <div class="col-3 text-right">
        <span class="state_Name">{{item.city}}</span>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-9">
        <span class="subhead_text">{{item.address | titlecase}}</span>
      </div>
      <!-- <div class="col-3 text-right">
        <img
          [src]="
            item.finalStatus=='Pending' && item.finalStatus=='Pending' ? (item.finalStatus=='Approved'? 'assets/fssai_approved.svg' :  item.finalStatus=='Rejected'?'assets/fssai_rejected.svg' : 'assets/fssai_pending.svg'):
            item.finalStatus=='Pending' && item.finalStatus!='Pending' ? (item.finalStatus=='Approved'? 'assets/fssai_approved.svg' :  item.finalStatus=='Rejected'?'assets/fssai_rejected.svg' : 'assets/fssai_pending.svg'):
            (item.finalStatus=='Approved'? 'assets/fssai_approved.svg' :  item.finalStatus=='Rejected'?'assets/fssai_rejected.svg' : 'assets/fssai_pending.svg')" />
      </div> -->
    </div>

    <div class="row mt-2">
      <div class="col-6">
        <span class="customer_Name subhead_text">{{item.customerName | titlecase}}</span>
      </div>
      <div class="col-6 ">
        <span class="customer_MobNo subhead_text float-end">{{item.customerMobileNo}}</span>

      </div>
    </div>

    <div class="row mt-1">
      <div class="col-9">
        <span class="customer_Email subhead_text">{{item.customerEmailID}}</span>
      </div>
      <div class="col-3 text-right">
        <img
          [src]="
            item.finalStatus=='Pending' && item.finalStatus=='Pending' ? (item.finalStatus=='Approved'? 'assets/fssai_approved.svg' :  item.finalStatus=='Rejected'?'assets/fssai_rejected.svg' : 'assets/fssai_pending.svg'):
            item.finalStatus=='Pending' && item.finalStatus!='Pending' ? (item.finalStatus=='Approved'? 'assets/fssai_approved.svg' :  item.finalStatus=='Rejected'?'assets/fssai_rejected.svg' : 'assets/fssai_pending.svg'):
            (item.finalStatus=='Approved'? 'assets/fssai_approved.svg' :  item.finalStatus=='Rejected'?'assets/fssai_rejected.svg' : 'assets/fssai_pending.svg')" />
      
          </div>
    </div>
  </div>

  <hr>
<br>
<br>
<br>
<br>
<br>
</div>
