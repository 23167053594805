import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import html2canvas from 'html2canvas';
import * as jsPDF from 'jspdf';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-certificate',
  templateUrl: './certificate.component.html',
  styleUrls: ['./certificate.component.css']
})
export class CertificateComponent implements OnInit {
  formname:any;
  actiondesc:any;
  FieldName:any;
  FieldValue:any;
  userType: any;
  userId: any;
  constructor(private headerService: HeaderService,private cs:CommonUtilitiesService) { }

  ngOnInit(): void {
    this.headerService.setTitle('Certificate');
    this.headerService.setActiveURl('/certificate');
    this.userId = localStorage.getItem("UserID");
    this.userType = localStorage.getItem("UserType");
    this.FieldName = localStorage.getItem("FieldName");
    this.FieldValue = localStorage.getItem("FieldValue");
    this.formname="certificate";
    this.actiondesc="certificate"
    this.cs.GetGeoLocation(this.userId,this.userType,this.formname,this.actiondesc,this.FieldName,this.FieldValue);
  }

  public captureScreen()
  {
    let data:any = document.getElementById('content');

    html2canvas(data).then((canvas: { height: number; width: number; toDataURL: (arg0: string) => any; }) => {
      var imgWidth = 200;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 5, position, imgWidth, imgHeight)
      pdf.save('certificate.pdf');
    });
  }

}
