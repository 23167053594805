<div>
    <div class="row mt-4">
        <div class="col-12">
            <input class="fullWidth" type="text" name="" id="" placeholder="Search Outlet Name Or ID"
                [(ngModel)]="searchTerm">
        </div>
    </div>

    <!-- <div class="row mt-4">
        <div class="col-12">        
          <input class="fullWidth" type="text" placeholder="Search Outlet Name Or ID" list="dynmicUserIds" (keyup)="getUserIdsFirstWay($event)" id="userIdFirstWay" (change)="SearchText($event)" autocomplete="off">
          <datalist id="dynmicUserIds">
            <option *ngFor="let item of userList1" [value]="item">{{item}}</option>
          </datalist>
        </div>
      </div> -->

    <div class="row mt-3">
        <div class="col-5 purpleColor text_rdList">
            Outlet List
        </div>
        <div class="col-7 text-right">
            <img class="img-fluid" src="assets/sec_dot.png" />
            <span class="achivement mr-2 purpleColor"> Achievement</span>
            <img class="img-fluid" src="assets/pri_dot.png" />
            <span class="target purpleColor"> Target</span>
        </div>
    </div>

    <div class="bodyEle">
        <div *ngFor="let item of filteredList" (click)="clickOnOutletList(item)">
            <div class="row mt-4">
                <div class="col-12">
                    <span class="Head_text">{{item.outletname}}</span>
                    <span class="ml-1 Head_Val_txt">{{item.outletcode}}</span>
                </div>
            </div>
            <div class="row mt-1 mx-auto">
                <ng-container *ngIf="item.isp7=='Y';then p7Yes else p7X;">
                </ng-container>
                <ng-template #p7Yes>
                    <div class="col-4 p-0">
                        <img class="img-fluid" src="../../../assets/greenCrown.svg" />
                        <span class="ms-1">Program</span>
                        <span class="ms-1">|</span>
                    </div>
                </ng-template>
               
                <ng-template #p7X >
                    <ng-container *ngIf="item.isp7=='N';then p7NO ">
                    </ng-container>
                </ng-template>

                <ng-template #p7NO>
                    <div class="col-4 p-0">
                        <img class="img-fluid" src="../../../assets/redCrown.svg" />
                        <span class="ms-1">Program</span>
                        <span class="ms-1">|</span>
                    </div>
                </ng-template>

                <ng-container *ngIf="item.isvisi=='Y';then visiYes else visiX ; ">
                </ng-container>
                <ng-template #visiYes>
                    <div class="col-4 p-0">
                        <img class="img-fluid" src="../../../assets/greenVisicooler.svg" />
                        <span class="ms-1">Visicooler</span>
                       
                        <span class="ms-1" *ngIf="item.isgrowth !='X'">|</span>
                    </div>
                </ng-template>
                <ng-template #visiX >
                    <ng-container *ngIf="item.isvisi=='N';then visiNo ">
                    </ng-container>
                </ng-template>
                <ng-template #visiNo>
                    <div class="col-4 p-0">
                        <img class="img-fluid" src="../../../assets/redVisicooler.svg" />
                        <span class="ms-1">Visicooler</span>
                        <span class="ms-1" *ngIf="item.isgrowth !='X'">|</span>
                    </div>
                </ng-template>
                
                <ng-template *ngIf="item.isgrowth=='Y';then growthYes else growthX;">
                </ng-template>
                <ng-template #growthYes>
                    <div class="col-4 p-0">
                        <img class="img-fluid" src="../../../assets/greenGrowth.svg" />
                        <span class="ms-1">Growth</span>
                        <span class="ms-2" *ngIf="item.isvisited =='VG' || item.isvisited =='VR'">|</span>
                    </div>
                </ng-template>

                <ng-template #growthX >
                    <ng-container *ngIf="item.isgrowth=='N';then growthNO ">
                    </ng-container>
                </ng-template>
                <ng-template #growthNO>
                    <div class="col-4 p-0">
                        <img class="img-fluid" src="../../../assets/redGrowth.svg" />
                        <span class="ms-1">Growth</span>
                        <span class="ms-2" *ngIf="item.isvisited =='VG' || item.isvisited =='VR'">|</span>
                    </div>
                </ng-template>


                <ng-container *ngIf="item.isvisited?.toLowerCase()=='vg';then VisitedYes else visitedNo;">
                </ng-container>
                <ng-template #VisitedYes>
                    <div class="col-4 p-0">
                        <img class="img-fluid mb-1" src="../../../assets/icons/visited_green_icon.svg" />
                        <span class="ms-1">Visited</span>
                    </div>
                </ng-template>
               
                <ng-template #visitedNo >
                    <div class="col-4 p-0" *ngIf="item.isvisited=='VR'">
                        <img class="img-fluid mb-1" src="../../../assets/icons/visited_red_icon.svg" />
                        <span class="ms-1">Visited</span>
                    </div>
                </ng-template>
            </div>

            <div class="row mt-2">
                <div class="col-3">
                    <div class="row">
                        <div class="col-12 text-center">
                            <h1 class="m-0 text_rdList_Percentage purpleColor">{{item.achper}}%</h1>
                        </div>
                    </div>
                </div>
                <div class="col-9">
                    <div class="row mt-2">
                        <div class="col-12">
                            <div class="progress rounded-pill">
                                <div class="progress-bar bg_sec" role="progressbar" [style.width.%]="item.ach"
                                    aria-valuenow="20" aria-valuemin="0" aria-valuemax="100"></div>
                                <div class="progress-bar bg_pri" role="progressbar" [style.width.%]="item.tgt"
                                    aria-valuenow="40" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-6 text-center">
                            <h2 class="m-0 vp_Bar_value">{{item.ach}}<span class="text_10"> INR</span></h2>
                        </div>
                        <div class="col-6 text-center">
                            <h2 class="m-0 vp_Bar_value">{{item.tgt}}<span class="text_10"> INR</span></h2>
                        </div>
                    </div>

                </div>
            </div>
            <hr>
        </div>
    </div>
</div>