import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxImageCompressService } from 'ngx-image-compress';
import { ToastrService } from 'ngx-toastr';
import { Enclass, ResultClass } from 'src/app/models/encryption-class';
import { EncryptionService } from 'src/app/service/encryption.service';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { MatTooltip } from '@angular/material/tooltip';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { FormBuilder } from '@angular/forms';
import { DataService } from 'src/app/service/data.service';


@Component({
  selector: 'app-update-ticket-details',
  templateUrl: './update-ticket-details.component.html',
  styleUrls: ['./update-ticket-details.component.css']
})
export class UpdateTicketDetailsComponent implements OnInit {
  ticketDetails: any = []
  FieldName: any;
  FieldValue: any;
  UserID: any;
  periodList: any[] = [];
  selectedperiod: any;
  UserType: any;
  FormName: any;
  device: any;
  deviceType: any;
  token: any;
  selectedAreaId: any
  watchListItems: any = []
  selectedWishlistItem: any = []
  public sendComment = false;
  public comment: any = "";
  public commentDate: any = "";
  selectedFile: any = "";
  sendCommentList: any = []
  @ViewChild('openWatchListModal', { static: true }) elRefopenWatchListModal: ElementRef | undefined;
  @ViewChild('openSelectMediaModal', { static: true }) elRefopenSelectMediaModal: ElementRef | undefined;
  @ViewChild('openReOpenModal', { static: true }) elRefopenReOpenModal: ElementRef | undefined;
  @ViewChild('openExistingCommentImagesModal', { static: true }) elRefopenopenExistingCommentImagesModalModal: ElementRef | undefined;

  httpOptions: HttpHeaders =
    new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'enctype': 'multipart/form-data',
      'charset': 'UTF-8',
      'FolderName': 'HelpDesk',
    })

  // fileURL: any;
  fileName: string | undefined;
  file: any;
  localUrl: any;
  localCompressedURl: any;
  sizeOfOriginalImage: number = 0;
  sizeOFCompressedImage: number = 0;
  imgResultBeforeCompress: string = '';
  imgResultAfterCompress: string = '';
  isErrorMessageInToast: boolean = false
  photourlstr: string = ''
  uploadedMultipleFile: any = []
  isSendBtnClicked: boolean = false
  existingComments: any = []
  secretKey: any = ""
  clientId: any = ""
  public enclass: Enclass = new Enclass();
  public resultClass: ResultClass = new ResultClass();
  @ViewChild('tooltip', { static: true }) tooltip: MatTooltip | any;
  @ViewChild('impacttooltip', { static: true }) impacttooltip: MatTooltip | any;

  uploadForm = this.fb.group({
    files: [null]
  });
  previewUrls: string[] = [];
  isL1L2L3: boolean = false
  url: any = ""
  existingCommentImageName: any;
  isWatchlisShow: boolean = true
  reopenReason: any = ""
  constructor(
    private router: Router, private fb: FormBuilder, private headerService: HeaderService, private imageCompress: NgxImageCompressService,
    private toastr: ToastrService, private http: HttpClient, public mds: MongoDataService,
    private modalService: NgbModal, private es: EncryptionService, private notifyMeService: NotifyMeService, private ds: DataService) { }

  ngOnInit(): void {
    this.headerService.setTitle('Ticket Details');
    this.headerService.setActiveURl('/UpdateTicketDetails');
    this.UserType = localStorage.getItem("UserType");
    this.UserID = localStorage.getItem("UserID");
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.token = localStorage.getItem("TokenNo");
    this.secretKey = localStorage.getItem("SecreteKey")
    this.clientId = localStorage.getItem("ClientID");
    this.ticketDetails = []
    this.ticketDetails = JSON.parse(localStorage.getItem("ticketDetails") || '{}')
    this.url = this.mds.url;
    console.log("ticketDetails", this.ticketDetails)
    this.existingComments = []
    debugger
// this.checkCameraPermissions()
    if (this.ticketDetails.ticketOwnerType == "L1" || this.ticketDetails.ticketOwnerType == "L2" || this.ticketDetails.ticketOwnerType == "L3") {
      this.isL1L2L3 = true
    }

    if (this.ticketDetails.transactionDetails?.length > 0) {
      for (let i = 0; i < this.ticketDetails.transactionDetails?.length; i++) {
        if (this.ticketDetails.transactionDetails[i].transactionType?.toLowerCase() == "reply" || this.ticketDetails.transactionDetails[i].transactionType?.toLowerCase() == "transfer" || this.ticketDetails.transactionDetails[i].transactionType?.toLowerCase() == "changestatus") {
          this.existingComments.push(this.ticketDetails.transactionDetails[i])
        }
      }
    }

    for (let i = 0; i < this.existingComments.length; i++) {
      var date = new Date(this.existingComments[i].transaction_date)
      this.existingComments[i].commentDate = date.toLocaleDateString()
    }


    this.selectedWishlistItem = this.ticketDetails?.watchList
    this.HD_UserMasterData();
    console.log("this.existingComments", this.existingComments)
  }


  private async checkCameraPermissions(): Promise<void> {
    debugger
    try {
      // Check if permissions are granted
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      // If permissions are granted, stop the stream to release the camera
      stream.getTracks().forEach(track => track.stop());
    } catch (error) {
      // Show popup if permission is denied or any error occurs
      this.toastr.warning('Camera access is not allowed. Please enable camera permissions.');
    }
  }

  HD_UserMasterData() {
    debugger
    let request = {
      "companyCode": "mdlz",
      "userId": this.UserID,
      "userType": this.UserType
    }
    this.es.Word = this.es.WordKey;
    this.es.encrypt(this.secretKey);
    this.es.Word = JSON.stringify(request);
    this.enclass.buggerstring = this.es.encrypt(this.secretKey)
    this.mds.HD_UserMasterData(this.enclass, this.token, this.device, this.deviceType, this.FormName, this.clientId).subscribe(res => {
      console.log("getSurveydetails", res)

      this.resultClass = res;
      this.es.Word = this.resultClass.resultString;
      let response = JSON.parse(this.es.decrypt(this.secretKey));

      console.log("response", response)
      this.watchListItems = []
      this.watchListItems = response.Data

      for (let i = 0; i < this.watchListItems?.length; i++) {
        this.watchListItems[i].id = i
        this.watchListItems[i].isChecked = false
      }

      for (let i = 0; i < this.watchListItems?.length; i++) {
        this.watchListItems[i].id = i
        for (let j = 0; j < this.ticketDetails.watchList?.length; j++) {
          if (this.watchListItems[i].emailId == this.ticketDetails.watchList[j].emailId && this.watchListItems[i].userId == this.ticketDetails.watchList[j].userId) {
            this.watchListItems[i].isChecked = true
          }
        }
      }

      this.watchListItems = this.watchListItems.sort((a: any, b: any) => b.isChecked - a.isChecked);
      console.log("this.watchListItems", this.watchListItems)
    }, (error: any) => {
      console.log("error", error)
    });
  }

  openTooltip() {
    this.tooltip.show();
    setTimeout(() => {
      this.tooltip.hide();
    }, 5000); // Hide after 3 seconds
  }

  openImpactTooltip() {
    this.impacttooltip.show();
    setTimeout(() => {
      this.impacttooltip.hide();
    }, 5000); // Hide after 3 seconds
  }

  addWatchlist() {
    this.openModal(this.elRefopenWatchListModal);
  }

  openModal(modal: ElementRef<any> | undefined) {
    this.modalService.open(modal,
      {
        size: 'lg',
        backdrop: 'static',
        windowClass: '',
        keyboard: false,
        centered: true,
      })
  }
  modalClose() {
    this.reopenReason=""
    this.modalService.dismissAll();
  }

  receivedSelectedList(item: any) {
    debugger
    console.log("item", item)
    this.isWatchlisShow = false
    setTimeout(() => {
      this.isWatchlisShow = true
      this.watchListItems = this.watchListItems.sort((a: any, b: any) => b.isChecked - a.isChecked);
    }, 1);

    this.selectedWishlistItem = []
    for (let i = 0; i < item.length; i++) {
      this.selectedWishlistItem.push({
        userId: item[i].userId,
        emailId: item[i].emailId,
        userName: item[i].userName
      })
    }

    let request = {
      "companyCode": "mdlz",
      "userType": this.UserType,
      "userId": this.UserID,
      "ticketNo": this.ticketDetails.ticketNo,
      "transactionType": "",
      "transferTo": this.ticketDetails.ticketOwner,
      "transactionstatus": "",
      "description": this.ticketDetails.description,
      "fileAttachmentName": [],
      "watchList": this.selectedWishlistItem,
      "flag": "watchlist",
    }

    console.log("request", request)
    this.es.Word = this.es.WordKey;
    this.es.encrypt(this.secretKey);
    this.es.Word = JSON.stringify(request);
    this.enclass.buggerstring = this.es.encrypt(this.secretKey)
    this.mds.HD_UpdateTicketDetailsAddComment(this.enclass, this.token, this.device, this.deviceType, this.FormName, this.clientId).subscribe(res => {
      console.log("getSurveydetails", res)

      this.resultClass = res;
      this.es.Word = this.resultClass.resultString;
      let response = JSON.parse(this.es.decrypt(this.secretKey));

      if (response.Data == null) {
        this.toastr.warning("Your watchlist is not updated yet.")
      } else {
        this.toastr.success("Your watchlist update successfully.")
      }
      console.log("response", response)

    }, (error: any) => {
      console.log("error", error)
    });
    console.log("selectedWishlistItem", this.selectedWishlistItem)
  }

  attachmentOption() {
    this.openModal(this.elRefopenSelectMediaModal);
  }

  removeAttachment(ind: any) {
    debugger
    this.uploadedMultipleFile.splice(ind, 1);
    this.file.value = ""
  }

  commentSend(comment: any, isRequired: any = false) {
    debugger
    this.sendComment = false
    if ((comment.trim() == "" && this.selectedFile == "") && isRequired == true) {
      this.sendComment = false;
      this.toastr.warning("Please enter comment or  attachment")
      return;
    }
    else if ((comment.trim() != "" || this.selectedFile != "")) {
      this.sendComment = true;
      let currentDate = new Date();
      let month = currentDate.getMonth() + 1;
      var date = currentDate.getDate();
      var year = currentDate.getFullYear()
      this.commentDate = month + "/" + date + "/" + year;
      this.sendCommentList.push({
        "commentUserName": this.UserID,
        "commentDate": this.commentDate,
        "comment": comment,
        "attachments": this.uploadedMultipleFile
      })

      console.log("sendCommentList", this.sendCommentList)
      let request = {
        "companyCode": "mdlz",
        "userType": this.UserType,
        "userId": this.UserID,
        "ticketNo": this.ticketDetails.ticketNo,
        "transactionType": "reply",
        "transferTo": "",
        "transactionstatus": this.ticketDetails.ticketStatus,
        "description": comment,
        "fileAttachmentName": this.uploadedMultipleFile.map((m: any) => m.fileName),
        "watchList": this.ticketDetails.watchList,
        "flag": "reply",
      }

      console.log("request", request)
      this.es.Word = this.es.WordKey;
      this.es.encrypt(this.secretKey);
      this.es.Word = JSON.stringify(request);
      this.enclass.buggerstring = this.es.encrypt(this.secretKey)
      this.mds.HD_UpdateTicketDetailsAddComment(this.enclass, this.token, this.device, this.deviceType, this.FormName, this.clientId).subscribe(res => {
        console.log("getSurveydetails", res)

        this.resultClass = res;
        this.es.Word = this.resultClass.resultString;
        let response = JSON.parse(this.es.decrypt(this.secretKey));

        if (response.Data == null) {
          this.toastr.warning("Comment not updated.")
        } else {
          this.toastr.success("Comment updated successfully.")
        }
        console.log("response", response)

      }, (error: any) => {
        console.log("error", error)
      });

      this.uploadedMultipleFile = []
    }
    else {
      this.sendComment = false;
    }
    (<HTMLInputElement>document.getElementById("updateInputBox")).value = ""

    setTimeout(() => {
      (<HTMLInputElement>document.getElementById('test'))?.scrollIntoView();
    }, 2)

  }

  clickOnExistingCommentImages(imgName: any) {
    debugger
    console.log("imgName", imgName)
    if (imgName?.split('.')?.pop()?.toLowerCase() == "jpg" || imgName?.split('.')?.pop()?.toLowerCase() == "jpeg" || imgName?.split('.')?.pop()?.toLowerCase() == "png") {
      this.existingCommentImageName = imgName
      this.openModal(this.elRefopenopenExistingCommentImagesModalModal);
    } else {
      this.ds.getDownloadFileFromS3Bucket("HelpDesk", imgName).subscribe((res) => {
        let anchor = document.createElement("a");
        anchor.href = res.url;
        anchor.id = "xyz";
        (<HTMLBodyElement>document.getElementsByTagName("body")[0]).appendChild(anchor)
        document.getElementById("xyz")?.click();
        document.getElementsByTagName("body")[0].removeChild(anchor)

      }, (error) => { console.log("error", error) });
    }

  }

  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      let downloadedImage = reader.result;
      console.log("downloadedImage", downloadedImage)
    }, false);
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  reOpenClicked() {
    this.openModal(this.elRefopenReOpenModal);

  }

  clickedOnYes() {
    debugger
    if (this.reopenReason=="") {
      this.toastr.warning("Please Enter Reason");
      return;
    }

    let request = {
      "companyCode": "mdlz",
      "userType": this.UserType,
      "userId": this.UserID,
      "ticketNo": this.ticketDetails.ticketNo,
      "transactionType": "reopen",
      "transferTo": this.ticketDetails.ticketOwner,
      "transactionstatus": "inprogress",
      "description": this.reopenReason,
      "fileAttachmentName": [],
      "watchList": this.selectedWishlistItem,
      "flag": "reopen",
    }

    console.log("request", request)
    this.es.Word = this.es.WordKey;
    this.es.encrypt(this.secretKey);
    this.es.Word = JSON.stringify(request);
    this.enclass.buggerstring = this.es.encrypt(this.secretKey)
    this.mds.HD_UpdateTicketDetailsAddComment(this.enclass, this.token, this.device, this.deviceType, this.FormName, this.clientId).subscribe(res => {
      console.log("getSurveydetails", res)

      this.resultClass = res;
      this.es.Word = this.resultClass.resultString;
      let response = JSON.parse(this.es.decrypt(this.secretKey));

      if (response.Data == null) {
        this.toastr.warning("Your record is not updated yet.")
        this.modalClose()
      } else {
        this.toastr.success("Your record update successfully.")
        this.modalClose()
        this.router.navigate(['/home/ticketLogList'])
      }
      console.log("response", response)

    }, (error: any) => {
      console.log("error", error)
    });
  }

  clickedOnNo() {
    this.reopenReason=""
    this.modalClose()
  }

  clickOnBackBtn() {
    this.router.navigate(['/home/ticketLogList'])
  }

  clickedOnFeedbackBtn() {
    this.router.navigate(['/home/TicketDetailsFeedback'])
  }

  // upload(fileInput: any) {
  //   debugger
  //       const input:any = fileInput.target as HTMLInputElement;
  //       var today = new Date();
  //       var day = today.getDate() + "";
  //       var month =today.getMonth() + 1 + "";
  //       month=(month.length==1?"0":"") + month + ""
  //       var year = today.getFullYear() + "";
  //       var hour = today.getHours() + "";
  //       var minutes = today.getMinutes() + "";
  //       var seconds = today.getSeconds() + "";
  //       let todaysDate=year  + month  + day  + hour + minutes  + seconds


  //       var fileNameSplit=fileInput.target.files[0]?.name.split(".")
  //       this.selectedFile = fileNameSplit.slice(0,fileNameSplit.length-1).join("") +"_"+todaysDate+"_"+this.ticketDetails.ticketNo+"."+fileInput.target.files[0]?.name.split(".")[fileNameSplit.length-1]
  //   console.log("this.fileURL.name", fileNameSplit.slice(0,fileNameSplit.length-1).join("") +"_"+todaysDate+"_"+fileInput.target.files[0]?.name.split(".")[fileNameSplit.length-1])

  //   let fileToUpload = <File>fileInput.target.files[0];

  //   if (fileToUpload.name.toLowerCase().lastIndexOf('.jpeg') == -1 && fileToUpload.name.toLowerCase().lastIndexOf('.jpg') == -1) {
  //     this.notifyMeService.showError('Image Should Be In JEPG And JPG', 'Mera APP');
  //     return
  //   }
  //   this.uploadedMultipleFile.push(this.selectedFile)


  //   this.fileURL = <File>fileInput.target.files[0];
  //   if (fileInput.length === 0) {
  //     return;
  //   }

  //   this.fileURL = fileToUpload;
  //   const formData = new FormData();
  //   debugger


  //   var fileName: any;
  //   this.file = fileInput.target.files[0];
  //   fileName = this.file['name'];
  //   if (fileInput.target.files && fileInput.target.files[0]) {
  //     var reader = new FileReader();
  //     reader.onload = (event: any) => {
  //       this.localUrl = event.target.result;
  //       var orientation = -1;
  //       this.sizeOfOriginalImage = this.imageCompress.byteCount(this.localUrl) / (2500 * 2500);

  //       this.imageCompress.compressFile(this.localUrl, orientation, 75, 75).then(result => {
  //         this.imgResultAfterCompress = result;
  //         console.log("this.imgResultAfterCompress",this.imgResultAfterCompress)
  //         this.localCompressedURl = result;
  //         this.sizeOFCompressedImage = this.imageCompress.byteCount(result) / (2500 * 2500)
  //         console.log("this.sizeOFCompressedImage",this.sizeOFCompressedImage)
  //         // create file from byte

  //         const imageName = fileName + "_" + todaysDate+"_"+this.ticketDetails.ticketNo;
  //         console.log("fileName", fileName)
  //         // call method that creates a blob from dataUri
  //         const imageBlob = this.dataURItoBlob(this.imgResultAfterCompress.split(',')[1]);
  //         //imageFile created below is the new compressed file which can be send to API in form data
  //         const imageFile = new File([imageBlob], imageName, { type: 'image/jpg, image/jpeg' });

  //         // formData.append('file', fileToUpload, this.fileURL.name);
  //         var fileNameSplit=input.files[0].name.split(".")
  //         formData.append('file', imageFile, fileNameSplit.slice(0,fileNameSplit.length-1).join("") +"_"+todaysDate+"_"+this.ticketDetails.ticketNo+"."+this.fileURL.name.split(".")[fileNameSplit.length-1]);
  //         this.http.post(this.mds.url + 'MeraNet/UploadFileToS3Bucket', formData, { reportProgress: true, observe: 'events', headers: this.httpOptions, responseType: 'blob' as 'json' }).subscribe(res => {
  //           console.log("ATOBUploadFile", res)
  //         },
  //           (error:any) => { console.log("error", error) });
  //       });
  //     }
  //     reader.readAsDataURL(fileInput.target.files[0]);
  //   }
  // }

  isValidFileType(fileName: string): boolean {
    const allowedExtensions = ['jpg', 'jpeg', "zip", "doc", "docx", "txt", "xlsx", "7z", "pdf", "jpg", "pptx", "msg", "png"];
    const fileExtension = fileName.split('.').pop()?.toLowerCase();
    return allowedExtensions.includes(fileExtension || '');
  }
  upload(event: any) {
    debugger
    const input = event.target as HTMLInputElement;
    // this.fileURL = <File>event.target.files[0]
    var fileToUpload = <File>event.target.files;
    const fileURLList: any = <File>event.target.files
    console.log("fileURLList", fileURLList)
    var today = new Date();
    var day = today.getDate() + "";
    var month = today.getMonth() + 1 + "";
    month = (month.length == 1 ? "0" : "") + month + ""
    var year = today.getFullYear() + "";
    var hour = today.getHours() + "";
    var minutes = today.getMinutes() + "";
    var seconds = today.getSeconds() + "";
    let todaysDate = year + month + day + hour + minutes + seconds
    if (input.files) {

      const files = Array.from(input.files);
      const validFiles: any = files.filter((file) =>
        this.isValidFileType(file.name)
      );


      // this.uploadedMultipleFile = this.uploadedMultipleFile.concat(validFiles);

      for (let i = 0; i < validFiles.length; i++) {
        let fileNameSplit = validFiles[i].name.split(".")
        validFiles[i].fileName = fileNameSplit.slice(0, fileNameSplit.length - 1).join("") + "_" + todaysDate + "_" + this.ticketDetails.ticketNo + "." + fileNameSplit[fileNameSplit.length - 1]

      }

      this.uploadedMultipleFile = this.uploadedMultipleFile.concat(validFiles);


      console.log("this.uploadedMultipleFile", this.uploadedMultipleFile)

      this.uploadForm.patchValue({
        files: files
      });
      console.log("this.uploadForm", this.uploadForm)
      this.previewUrls = [];
      files.forEach((file: any, index: any) => {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          const formData = new FormData()
          this.previewUrls.push(e.target.result);
          console.log("fileURLList", fileURLList)
          var fileName: any;
          fileName = files[index].name;
          //  fileName = this.file['name'];
          this.localUrl = e.target.result;
          var orientation = -1;
          this.sizeOfOriginalImage = this.imageCompress.byteCount(this.localUrl) / (2500 * 2500);

          //  this.imageCompress.compressFile(this.localUrl, orientation, 75, 75).then(result => {
          //    this.imgResultAfterCompress = result;
          //    console.log("this.imgResultAfterCompress", this.imgResultAfterCompress)
          //    this.localCompressedURl = result;
          //    this.sizeOFCompressedImage = this.imageCompress.byteCount(result) / (2500 * 2500)

          const imageName = fileName;
          const imageBlob = this.dataURItoBlob(e.target.result.split(',')[1]);

          const acceptedTypes: any = [
            'application/zip',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'text/plain',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/x-7z-compressed',
            'application/pdf',
            'application/pptx',
            'image/jpeg',
            'image/jpg',
            'image/png',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation',
          ];

          const imageFile = new File([imageBlob], imageName, { type: acceptedTypes });


          var fileNameSplit = imageName.split(".")

          console.log("uploaded file name", fileNameSplit.slice(0, fileNameSplit.length - 1).join("") + "_" + todaysDate + "_" + this.ticketDetails.ticketNo + "." + fileNameSplit[fileNameSplit.length - 1])

          formData.append('file', imageFile, fileNameSplit.slice(0, fileNameSplit.length - 1).join("") + "_" + todaysDate + "_" + this.ticketDetails.ticketNo + "." + fileNameSplit[fileNameSplit.length - 1]);

          this.http.post(this.mds.url + 'MeraNet/UploadFileToS3Bucket', formData, { reportProgress: true, observe: 'events', headers: this.httpOptions, responseType: 'blob' as 'json' }).subscribe(res => {
            console.log("ATOBUploadFile", res)
          })
          // })
        }
        reader.readAsDataURL(file);
      });
      input.value = '';
    }
  }

  dataURItoBlob(dataURI: string) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpg, image/jpeg, image/png,application/zip,application/pdf,application/txt,application/vnd.ms-outlook,application/kswps,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
    return blob;
  }
}
