import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { MatDialog } from '@angular/material/dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterComponent } from '../filter/filter.component';
import { DataService } from '../../service/data.service';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-pdf',
  templateUrl: './pdf.component.html',
  styleUrls: ['./pdf.component.css']
})
export class PdfComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute, private cs:CommonUtilitiesService, private fb: FormBuilder, private router: Router,
    private dialog: MatDialog, private ds: DataService, private headerService: HeaderService, private modalService: NgbModal,) { }
  deviceType: any;
  device: any;
  userType: string | null = '';
  userId: any;
  token: any;
  filename: string | null = '';
  KeyID: string | null = '';
  type: string | null = '';
  imgurl: string | null = '';
  formname: any;
  actiondesc: any;
  fieldName: any;
  fieldValue: any;


  ngOnInit(): void {

    this.headerService.setTitle('Check Agreement');
    this.headerService.setActiveURl('/vc');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.token = localStorage.getItem("TokenNo");
    this.userId = localStorage.getItem("UserID");


    this.userType = localStorage.getItem("UserType");
    this.fieldName = localStorage.getItem("FieldName");
    this.fieldValue = localStorage.getItem("FieldValue");
  
    this.activatedRoute.paramMap.forEach(params => {

      this.KeyID = params.get('KeyID');
      this.formname = "login/pdf";
      this.actiondesc = "pdf"+" "+this.KeyID;
      this.cs.GetGeoLocation(this.userId, this.userType, this.formname, this.actiondesc, this.fieldName, this.fieldValue);
      this.type = params.get('type');
      if (this.type == 'Agree') { this.type = 'Agreement' }
      this.filename = this.KeyID + "_" + this.type + ".pdf";
      if (this.KeyID != null) {
        if (this.KeyID.length > 0 && this.KeyID.length > 0) {
          this.downloadpdf(this.KeyID, this.type);

        }
      }

    });
  }

  downloadpdf(keyid: any, type: any): void {
    if (type == 'Agree') { type = 'Agreement' }
    this.filename = keyid + "_" + type + ".pdf";
    debugger;
    this.ds.getDownloadPDFFileFromS3Bucket("eAgreementMaster", this.filename);
  }

}
