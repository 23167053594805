<div class="container-fluid">
    <div class="row text-center">
        <span class="header_text">All India Performance</span>
        <div class="col-12">
            <div class="row mt-2">
                <div class="col-4">
                    <button class="btn btn_custom rounded-pill">Month</button>
                </div>
                <div class="col-4">
                    <button class="btn btn_custom rounded-pill">Year</button>
                </div>
                <div class="col-4">
                    <button class="btn btn_custom rounded-pill">Date</button>
                </div>
            </div>
        </div>
    </div>
    <br>
    <div class="row">
        <table class="table tbl_custom">
            <thead>
              <tr>
                <td class="td_cust">Category</td>
                <td class="td_cust">Jan 2021</td>
                <td class="td_cust">Jan 2020</td>
                <td class="td_cust">Growth</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td >Category 01</td>
                <td>50<sub>CR</sub></td>
                <td>40<sub>CR</sub></td>
                <td>10%</td>
                
              </tr>
              <tr>
                <td>Category 02</td>
                <td>45<sub>CR</sub></td>
                <td>30<sub>CR</sub></td>
                <td>15%</td>
              </tr>
              <tr>
                <td>Category 03</td>
                <td>60<sub>CR</sub></td>
                <td>40<sub>CR</sub></td>
                <td>20%</td>
              </tr>
              <tr>
                <th>Total</th>
                <th>160<sub>CR</sub></th>
                <th>140<sub>CR</sub></th>
                <th>10%</th>
              </tr>
            </tbody>
          </table>
    </div>
</div>