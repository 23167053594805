<div>
    <div class="purpleColor row p-0">
        <div class="row mx-auto">
            <div class="col-12 mt-3 text-center">
                <span class="rdheading">{{pcListData.desc}}</span>
                <span class="ml-1 rdcodecss">{{pcListData.valuetype}}</span>
            </div>
        </div>

        <div class="row mx-auto">
            <div class="col-12 text-center mt-3 ">
                <span class="rdstatusOnTrack"> <img src="../../../assets/onTrack.png" class="img-fluid" />&nbsp;
                    {{pcListData.achstatus |titlecase}}</span>
            </div>
        </div>

        <div class="row mx-auto" *ngIf="showSpeedometer">
            <kendo-radialgauge [pointer]="{ value: value}">
                <kendo-radialgauge-scale [minorUnit]="minorValue" [majorUnit]="majorValue" [max]="maxValue"
                    [rangeSize]="rangeSize" [majorTicks]="{color: ticksColor }" [minorTicks]="{ color: ticksColor }"
                    [startAngle]="startAngle" [endAngle]="endAngle">
                    <kendo-radialgauge-scale-labels [color]="'white'"></kendo-radialgauge-scale-labels>
                    <kendo-radialgauge-scale-ranges>
                        <kendo-radialgauge-scale-range *ngFor="let item of scaleRange" [from]="item.from" [to]="item.to"
                            [color]="item.color">
                        </kendo-radialgauge-scale-range>
                    </kendo-radialgauge-scale-ranges>
                </kendo-radialgauge-scale>

                <kendo-radialgauge-pointers>
                    <kendo-radialgauge-pointer *ngFor="let pointer of pointers" [value]="pointer.value"
                        [color]="pointer.color" [length]="pointer.length">
                    </kendo-radialgauge-pointer>
                </kendo-radialgauge-pointers>
            </kendo-radialgauge>
        </div>


        <div class="row mx-auto mt-2" style='width:26rem;height:4rem'>
            <div class="col-5">
                <!-- <span class="whiteColor p-2">{{pcListData.ach}} {{pcListData.unit}}</span> -->
                <span class="whiteColor p-2">{{pcListData.ach}}<span class="unit ml-1">{{pcListData.unit}}</span></span>

                <br>
                <span class="">
                    <img class="mr-1 p-0" src="assets/sec_dot.png" />
                    <span class="whiteColor p-0">Achievement</span>
                </span>
            </div>

            <div class="col-3 p-2 ">
                <span class="" style="color: white;font-size:20px ;">{{pcListData.percentage}} %</span>
            </div>
            <div class="col-3 p-0">
                <!-- <span class=" float-end whiteColor">{{pcListData.tgt}} {{pcListData.unit}}</span> -->
                <span class="whiteColor float-end">{{pcListData.tgt}}<span
                        class="unit ml-1">{{pcListData.unit}}</span></span>

                <br>
                <span class="float-end">
                    <img class="img-fluid mr-1" src="assets/pri_dot.png" />
                    <span class=" whiteColor">Target</span>
                </span>
            </div>
        </div>

        <div class="row mb-4 mx-auto">
            <div class="col-12 text-center" (click)="clickOnViewBeat()">
                <button class="btn btn_viewPC ">View Beats</button>
            </div>
        </div>
    </div>

    <div class="row mt-2">
        <div class="col-12">
            <span class="acctitle">PC Performance</span>
        </div>
    </div>

    <div class="row mt-3">
        <div class="col-12">
            <span class="labelUpdatePc">PC Productivity(Overall)</span>
            <span class="float-end">{{PCProductivity}}%</span>
        </div>
    </div>
    <hr>

    <div class="row mt-3">
        <div class="col-12">
            <span class="labelUpdatePc">No Of Working Days</span>
            <span class="float-end">{{NoOfWorkingDays}}</span>
        </div>
    </div>
    <hr>


    <div class="row mt-3 p-0 uv-100">
        <span class="acctitle">Incentive Update Of That PC</span>
    </div>
    <div class="row mt-3 p-0 uv-100">
        <table class="table tbl_custom">
            <thead>
                <tr>
                    <td class="td_cust">Parameter</td>
                    <td class="td_cust">Target</td>
                    <td class="td_cust">Achievement</td>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of GetPCIncentiveData">
                    <td class="Value_text">{{item.parameter}}</td>
                    <td class="Head_text">{{item.tgt}}</td>
                    <td class="Head_text">{{item.ach}}</td>
                </tr>
            </tbody>
        </table>
    </div>


    
    <div class="row mt-3 p-0 uv-100">
        <span class="acctitle">Sales Parameter</span>
    </div>
    <div class="row mt-3 p-0 uv-100">
        <table class="table tbl_custom">
            <thead>
                <tr>
                    <td class="td_cust">Category</td>
                    <td class="td_cust">MTD
                    </td>
                    <td class="td_cust">L3M%
                    </td>
                    <td class="td_cust">LYSM%</td>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of getOutletSalesParameter">
                    <td class="Value_text">{{item.category}}</td>
                    <td class="Head_text">{{item.mtd}}</td>
                    <td class="Head_text">{{item.l3m}}%</td>
                    <td class="Head_text">{{item.lm}}%</td>
                </tr>
            </tbody>
        </table>
    </div>


    <div class="row mt-3 p-0 uv-100">
        <span class="acctitle">Earnings</span>
    </div>
    <div class="row mt-3 p-0 uv-100">
        <table class="table tbl_custom">
            <thead>
                <tr>
                    <td class="text_12">Month</td>
                    <td class="text_12 text-end ">Monthly Earnings</td>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of pcTsiEarnings">
                    <td class="value_text monthName">{{item.paraname}}</td>
                    <td class="value_text text-end monthlyEraning">{{item.avgEarning}}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <br><br><br><br>

</div>