<div class="row" style="margin-top: 2rem;">
  <div class="col-12">
    <span class="text_Class">RD</span>
  </div>
  <div class="col-12">
    <span class="value_Class">{{erodsVoucherApproval.rdName}}</span>
    <span class="ml-1 value_Class">{{erodsVoucherApproval.rdCode}}</span>
  </div>
</div>
<hr>
<div class="row">
  <div class="col-12">
    <span class="text_Class">Voucher No</span>
  </div>
  <div class="col-12">
    <span class="value_Class">{{erodsVoucherApproval.vouNo}}</span>
  </div>
</div>
<hr>
<div class="row">
  <div class="col-12">
    <span class="text_Class">Voucher Date</span>
  </div>
  <div class="col-12">
    <span class="value_Class">{{erodsVoucherApproval.vouDate}}</span>
  </div>
</div>
<hr>
<div class="row">
  <div class="col-12">
    <span class="text_Class">Period</span>
  </div>
  <div class="col-12">
    <span class="value_Class">{{erodsVoucherApproval.fromDate}} To {{erodsVoucherApproval.toDate}}</span>
  </div>
</div>
<hr>
<div class="row">
  <div class="col-12">
    <span class="text_Class">Erods Amt</span>
  </div>
  <div class="col-12">
    <span class="value_Class">{{erodsVoucherApproval.voucherAmt}}</span>
  </div>
</div>
<hr>
<div class="row">
  <div class="col-12">
    <span class="text_Class">Sales Amt</span>
  </div>
  <div class="col-12">
    <span class="value_Class">{{erodsVoucherApproval.salesAmt}}</span>
  </div>
</div>
<hr>
<br>
<br>
<br>
<br>
<br>
<div class="footer">
  <div class="row text-center mt-3">
    <div class="col-12" (click)="back()">
      BACK
    </div>
  </div>
</div>


