import { Injectable } from '@angular/core';
import { NotifyMeService } from './notify-me.service';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class CommonUtilitiesService {
  public lat:any;
  public lng:any;
  public userId:any;
  public userType:any;
  public formname:any;
  public actiondesc:any;
  public FieldName:any;
  public FieldValue:any;
  constructor(private notifyMeService: NotifyMeService,private ds: DataService,) { }

  public setFieldNamevalue() {
    let FieldName: any;
    let FieldValue: any;
    let UserType: any = localStorage.getItem("UserType");
    switch (UserType.toUpperCase()) {
      case 'GM':
        FieldName = "ALLINDIA";
        FieldValue = "ALLINDIA";
        break;
      case 'BM':
        FieldName = "BRANCH";
        FieldValue = localStorage.getItem("BranchName")?.trim();
        break;
      case 'BSM':
        FieldName = "REGION";
        FieldValue = localStorage.getItem("RegionName")?.trim();
        break;
      case 'ASM':
        FieldName = "ASMAREA";
        FieldValue = localStorage.getItem("ASMArea")?.trim();
        break;
      case 'SO':
        FieldName = "SOTERRITORY";
        FieldValue = localStorage.getItem("SO")?.trim();
    }
    localStorage.setItem("FieldName", FieldName);
    localStorage.setItem("FieldValue", FieldValue);
    localStorage.setItem("OriginalFieldName", FieldName);
    localStorage.setItem("OriginalFieldValue", FieldValue);
  }

  public GetGeoLocation(userId:any,userType:any,formname:any,actiondesc:any,FieldName:any,FieldValue:any) {
    debugger
    this.userId=userId;
    this.userType=userType;
    this.formname=formname;
    this.actiondesc=actiondesc;
    this.FieldName=FieldName;
    this.FieldValue=FieldValue;

    if (navigator) {
      navigator.geolocation.getCurrentPosition(pos => {
        this.lat = pos.coords.latitude;
        console.log("  this.lat", this.lat)
       this.lng = pos.coords.longitude;
        this.SetapplicationLogData();
      }, this.handleLocationError, { timeout: 30000 });
    }
    else {
      this.notifyMeService.showError('Location Could not be captured', 'Mera APP');
    }
  }
  
  handleLocationError(error: { code: any; }) {
    switch (error.code) {
      case 3:
        this.notifyMeService.showError('Timeout in Position capture', 'Mera APP V2');
        break;
      case 2:
        this.notifyMeService.showError('Device is not capable of Position capture', 'Mera APP V2');
        break;
      case 1:
        this.notifyMeService.showError('You denied for Position capture', 'Mera APP V2');
        break;
    }
  }
  
  SetapplicationLogData() {
    debugger
    let request = {
      "userid": this.userId,
      "usertype": this.userType,
      "formname": this.formname,
      "actiondesc": this.actiondesc,
      "fieldname": this.FieldName,
      "fieldvalue": this.FieldValue,
      "latitude": this.lat,
      "longitude": this.lng
    }
    this.ds.SetapplicationLogData(request).subscribe(res => {
      console.log("res", res)
    })
  }
}


