export class KpiModels {
}
export class Seconderytrenddata {
  _id: number = 0;
  FieldName: string = '';
  FieldValue: string = '';
  Period: string = '';
  YearNo: number = 0;
  MonthNo: number = 0;
  PrimarySale: number = 0;
  SecondarySale: number = 0;
}
export class ResMasterdata {
  _id: string = '';
  branch: string = '';
  region: string = '';
  asmarea: string = '';
  soterritory: string = '';
  rdcode: number = 0;
  rdname: string = '';
}

export class ValuePerformancedata {
  _id: number = 0;
  FieldName: string = '';
  FieldValue: string = '';
  Period: string = '';
  valuetype: string = '';
  Tgt: number = 0;
  Ach: number = 0;
  PrimarySale: number = 0;
  Percentage: number = 0;
  unit: string = '';
  desc:string='';
}

export class PcTsiEarnings {
  _id: number = 0;
  FieldName: string = '';
  FieldValue: string = '';
  ParaName: string = '';
  totalPC: number = 0;
  Ach: number = 0;
  totalincentive: number = 0;
  avgEarning: number = 0;
  unit: string = '';
}



export class GetGrowKPIDashboard {
  _id: number = 0;
  fieldname: string = '';
  fieldvalue: string = '';
  period: string = '';
  valuetype: string = '';
  totrla: number = 0;
  billedrla: number = 0;
  percentage: number = 0;
  unit: string = '';
  desc: string = '';
}
export class GetVPOORVISIScanDashboard {
  _id: number = 0;
  fieldname: string = '';
  fieldvalue: string = '';
  period: string = '';
  valuetype: string = '';
  totalAch: number = 0;
  totalOutlet: number = 0;
  percentage: number = 0;
  desc: string = '';
}
export class GetProgramtargetDetailsDashboard {
  _id: number = 0;
  fieldname: string = '';
  fieldvalue: string = '';
  desc: string = '';
  type: string = '';
  tgt: number = 0;
  ach: number = 0;
  percentage: number = 0;
  amount: number = 0;
  monthno: number = 0;
  yearno: number = 0;
  bankname: string = '';
  paymentdate: string = '';
  paymenttype: string = '';
  uploaddate: string = '';
  utrno: string = '';
}
export class UnbilledOutlet {
  _id: number = 0;
  FieldName: string = '';
  FieldValue: string = '';
  Period: string = '';
  valuetype: string = '';
  Tgt: number = 0;
  Ach: number = 0;
  PrimarySale: number = 0;
  Percentage: number = 0;
  unit: string = '';
}
export class MarketData {
  UserID: string | null = '';
  paracode: string = '';
  paraname: string = '';
  paravalue: string = '';

}

export class PCMarketData {
  Code: string = '';
  TotalNoOfPC: number = 0;
  ActivePC: number = 0;
  CurrActivePC: number = 0;
  PCBefore30Min: number = 0;
  PCBefore1Hr: number = 0;
  InActivePC: number = 0;
  phyworking: number = 0;
  Paracode: number = 0;
  ParaName: string = '';
  BranchName: string = '';
  RegionName: string = '';
  ASMArea: string = '';
  Soterritory: string = '';
  RDCODE: string = '';
  Tlatitude: number = 0;
  TLongitude: number = 0;
  Sales: number = 0;
  NoOfOrder: number = 0;
}
export class GetGrowKPI {
  _id: number = 0;
  fieldname: string = '';
  fieldvalue: string = '';
  period: string = '';
  valuetype: string = '';
  totrla: number = 0;
  billedrla: number = 0;
  percentage: number = 0;
  unit: string = '';
  desc: string = '';
}
export class GetVPOORVISIScan {
  _id: number = 0;
  fieldname: string = '';
  fieldvalue: string = '';
  period: string = '';
  valuetype: string = '';
  totalAch: number = 0;
  totalOutlet: number = 0;
  percentage: number = 0;
  desc: string = '';
}

export class ResValuePerformance {

  paravalue: string = "";
  paracode: string = "";
  paraname: string = "";
  Sales: number = 0;

}

export class GetPCOutletList {
  RDCode: string = '';
  pcuid: number = 0;
  UserID: string = '';
}
export class ResPCOutletList {
  Parameter: string = '';
  ParaCode: string = '';
  ParaName: string = '';
  PCUID: number = 0;
  ParentCode: string = '';
  ParentName: string = '';
  BeatCode: string = '';
  BeatUID: number = 0;
  BeatName: string = '';
  SKUDivision: string = '';
  Outletcode: string = '';
  OutletCodeRD: string = '';
  OutletName: string = '';
  VisiCooler: string = '';
  RE: string = '';
  PP: string = '';
  OutletTown: string = '';
  OutletAddress: string = '';
  Latitude: string = '';
  Longitude: number = 0;
  Sales: number = 0;
  NoOfOrder: number = 0;
  PJP: number = 0;
  StartTime: string = '';
  IsOrder: string = '';
  NoOrderReason: string = '';
  OrderNo: number = 0;
  OrderValue: number = 0;
  NoOfLines: number = 0;
  LastBillDate: string = '';
  LastBillValue: number = 0;
  MTDSale: number = 0;
  TimeDiff: number = 0;
  StartTime1: string = '';
  mycolour: string = '';
}
export class ResNotificationList {

  id: string = '';
  notificationHeader: string = '';
  notificationText: string = '';
  activeFrom: string = '';
  activeTo: string = '';
  notificationImage: string = '';
  activeStatus: string = '';
}
export class GetNewRLADashboard {
  _id: number = 0;
  fieldname: string = '';
  fieldvalue: string = '';
  tagname: string = '';
  valuetype: string = '';
  newrla: number = 0;
  wsrla: number = 0;
  desc: string = '';
}

export class FilterData {
  public FieldName: string = "";
  public FieldValue: string = "";
  public UserID: string = "";
  public MenuUpTo: string = "";
}
export class Resultdata {
  public FieldName: string = "";
  public FieldValue: string = "";
}
export class BrandImage {
  srNo: number = 0;
  imgName: string = "";
  description: string = "";
  activeFrom: any;
  activeTo: any;
  activeStatus: string = "";
  brandType: string = "";
}
export class Reason {
  reasonID: number = 0;
  reasonName: string = '';
  reasonType: string = '';
}
export class PulloutEntry {
  RDCOde: string = '';
  OutletCode: string = '';
  OutletCoderd: string = '';
  Visicooler1: string = '';
  ContactPerson: string = '';
  Address1: string = '';
  Address2: string = '';
  Address3: string = '';
  LandMark: string = '';
  Town: string = '';
  State: string = '';
  PinCode: string = '';
  PhoneNo: string = '';
  MobileNo: string = '';
  STDCode: string = '';
  UserID: string = '';
  PulloutReason: string = '';
  SerialNo: string = '';
  RDName: string = '';
  OutletName: string = '';
  Status: string = '';
  FIRID: number = 0;
}

export class PullRequest {
  outletCode: string = '';
  rdCode: string = '';
  PulloutNo: number = 0;
  VisicoolerID: number = 0;
  Reason: string = '';
  UserID: string = '';
  UserType: string |null= '';
}

export class OifApproval{
        ID :number =0;
        OutletCode: string|null= '';
        OutletName: string|null = '';
        RDCode : string |null= '';
        RDName: string|null = '';
        SubStkCode: string|null = '';
          SubStkName: string|null = '';
          SuggVisicooler : string|null = '';
          LTMA  :number =0;
          Remark: string |null= '';
          town : string|null = '';
          ReasonID :number =0;
          ReasonName: string|null = '';
          RCCMStatus : string|null = '';
          SOApprovalStatus : string|null = '';
          ASMApprovalStatus: string|null = '';
          RCCMApprovalStatus : string|null = '';
          SOApprovalDate: string|null = '';
          ASMApprovalDate : string|null = '';
          RCCMApprovalDate : string|null = '';
          NewVisiCooler  :number =0;
}


export class VisicoolerMapping {
  Visicooler: string|null= '';
  FinalVisi: number=0;
}
export class inputOifapproval {
  ID: number=0;
  UserID: string|null= '';
  Status: string|null= '';
  ReasonID: number=0;
  VisiFinal: number=0;
  UserType: string|null= '';
  RCCMStatus: string|null= '';
  SubStkUID: string|null= '';
  visicooler: string|null= '';
  RequirementType: string|null= '';
}

export class  resPCAttendance{
  tagname: string|null= '';
  fieldName: string|null= '';
  fieldvalue: string|null= '';
  attendance: number=0;
  rdname: string|null= '';
  desc: string|null= '';
}

export class  resPCFRData{
  code: string|null= '';
  totalNoOfPC:number=0
  currActivePC:number=0
 paracode:number=0
  paraName: string|null= '';
  branchName: string|null= '';
  regionName: string|null= '';
  asmArea: string|null= '';
  soterritory: string|null= '';
  rdcode: string|null= '';
  createDate: any;
  frPer:number=0}
export class getRdList {
  rdcode: number=0;
  rdname: string|null= '';
  townname: string|null= '';
}
export class AtoBtransfer {
  UserID: string= '';
  FromOutletCode: string= '';
  ToOutletCode: string= '';
  FromRDCode: string= '';
  ToRDCode: string= '';
  FromVisiCooler1: string= '';
  ToVisiCooler1: string= '';
  Flag: string= '';
  ID: number=0;
  MobileNo: string= '';
  Add1: string= '';
  Add2: string= '';
  Landmark: string= '';
  PinCode: string= '';
  Visiid: string= '';
  CoolerPhoto: string= '';
  OutletPhoto: string= '';
  OutletIDPhoto: string= '';
  Page1Photo: string= '';
  Page2Photo: string= '';
  FIRID: number=0;
  EmailID: string= '';
  AssetCode:string='';
  SerialNo:string='';
  keyid:string|null='';
  Outletname:string|null='';  
}

export class AtoBtransfer_V1 {
  UserID: string= '';
  FromOutletCode: string= '';
  ToOutletCode: string= '';
  FromRDCode: string= '';
  ToRDCode: string= '';
  FromVisiCooler1: string= '';
  ToVisiCooler1: string= '';
  Flag: string= '';
  ID: number=0;
  MobileNo: string= '';
  Add1: string= '';
  Add2: string= '';
  Landmark: string= '';
  PinCode: string= '';
  Visiid: string= '';
  CoolerPhoto: string= '';
  OutletPhoto: string= '';
  OutletIDPhoto: string= '';
  Page1Photo: string= '';
  Page2Photo: string= '';
  FIRID: number=0;
  EmailID: string= '';
  AssetCode:string='';
  SerialNo:string='';
  keyid:string|null='';
  Outletname:string|null='';  
  RequestRaiseBy:string|null=''; 
  Lat:string|null=''; 
  Long:string|null='';
}

export class ComplaintSave {
  OutletCodeRD: string="";
  OutletCode: string="";
  OutletName: string="";
  RDCode: string="";
  ContactPerson: string="";
  SerialNo: string="";
  AssetCode: string="";
  Address1: string="";
  Address2: string="";
  Address3: string="";
  PinCode: string="";
  PhoneNo: string="";
  MobileNo: string="";
  Visicooler: string="";
  VisiMake: string="";
  CurCondition: string="";
  UserID: string|null="";
  IssueDetails: string="";
  LandMark: string="";
  PrevCallNo: number=0;
  State: string="";
  Town: string="";
  Visicooler2: string="";
  OutletType: string="";
  serviceID: number=0;
  IssueReportedby: string|null="";
  Reportingpersonname: string="";
  ReportingcontactNo: string="";
  QRCode: string="";
  // assetCode: string="";
}
export class ApproveAtoBTransfer {
  UserID: string= '';
  Status: string= '';
  ID: number= 0;
  RejectRemark: string= '';
}
export class QRCodeData {
  VisiCooler: string= '';
  IsWorking: string= '';
  Latitude: number= 0;
  Longitude: number= 0;
  OutletCoderd: string= '';
  QRCode: string= '';
  OutletCode: string= '';
  UserID: string= '';
  RDCOde: string= '';
  coolerphoto:string= '';
  exteriorphoto:string= '';
}
export class SaveFirReason {
  UserId: string= '';
  FIRID: number= 0;
  OutletUID: string= '';
  FirStatus: string= '';
  ReasonID: number= 0;
  RDCode: string= '';
  Visicooler: string= '';
  latitude: number= 0;
  longitude: number= 0;
}
export class InputPara
{
    FieldName: string= '';
   FieldValue: string= '';
    Datatype: string= '';
    TagName: string= '';
    UserID : string= '';
    TYPE:string='';
}
export class statusreport
{
    tagname:string='';
    fieldname :string='';
    fieldvalue :string='';
    branch :string='';
    region:string='';
    asmarea:string='';
    soterritory :string='';
    rdcode :number=0;
    rdname :string='';
    rdtype :string='';
    pcuid :number=0;
    pccode :string='';
    pcname :string='';
    code :string='';
    codename:string='';
    status:number=0;
}

export class OutputGetVisiAgrOTP
{
    Result :string='';    
    OutletCode:string='';
    OIFNo :number=0;
     ID :number=0;
     KeyID: string='';
    AgreementType :string='';
    SubmitOn :string='';


}

export class OutputAgreeDetails
{
  
      AgreementID:number=0;
      Description:string='';
      Details :string='';
      PDFFileName :string='';

}

export class Output_MTAFHAccount
{
  ID :number=0;
  AccountName :string='';
  City :string='';
  Address :string='';
  RE :string='';
 SubRE :string='';
Tag :string='';
 BusinessValue :number=0;
 ChocBelow20 :number=0;
 ChocAbove20:number=0;
AD :number=0;
Tang :number=0;
 Biscuits :number=0;
Remark :string='';
 CreatedBy:string='';
 CreatedOn :string='';
 RequiredThirdapproval :string='';
 ASMApprovalBy:string='';
 ASMApprovalStatus :string='';
 ASMApprovalDate :string='';
 FirstApprovalBy:string='';
 FirstApprovalStatus :string='';
 FirstApprovalDate:string='';
 SecondApprovalBy:string='';
 SecondApprovalStatus :string='';
 SecondApprovalDate:string='';
 ThirdApprovalBy :string='';
 ThirdApprovalStatus :string='';
 ThirdApprovalDate:string='';
 FinalStatus :string='';
}