<div class="row mt-4 mb-3">
    <div class="col-12">
        <span class="outlet_Name">Existing Outlet Name</span>
    </div>
</div>
<div class="row mt-2">
    <div class="col-12">
        <span class="outlet_Code">{{OutletDetails[0]?.Outletname}}</span><span class="outlet_Code ms-3">{{OutletDetails[0]?.Outletcode}}</span>
    </div>
</div>
<hr>

<div class="">
    <div class="row mt-2">
        <div class="col-12">
            <label class="outlet_Name">New Outlet Name</label>
            <input type="text" class="form-control" placeholder="Enter New Outlet Name"
                [(ngModel)]="newOutletName" (keyup)="alphaNumberOnly($event)" />

            <div class="error" *ngIf="isErrorMessageInToast">
                <small class="text-danger" >Outlet Name should contain Alpha, Numbers and .,-& characters<br></small>
            </div>

            <div class="error" *ngIf="charachetrValidation">
                <small class="text-danger" >Outlet Name should not exceed 40 characters<br></small>
            </div>
        </div>
    </div>

    <div class="row mt-3">
        <div class="col-12">
            <div class="form-group">
                <span class="outlet_Name">Supporting Document</span><br>
                <select class="form-control-plaintext mt-1" [(ngModel)]="selectedSupportingDocument1">
                    <option value="" disabled>Select Supporting Document</option>
                    <option value="GST certificate">GST certificate</option>
                    <option value="FSSAI licenses">FSSAI licenses</option>
                    <!-- <option value="Bank account passbook">Bank account passbook</option> -->
                    <option value="Bank account passbook">Bank Passbook/Cheque Leaf</option>
                   <option value="Trade Certificate">Trade Certificate</option>
                </select>
            </div>
        </div>
    </div>

    <!-- <div class="row mt-2">
        <div class="col-12">
            <div class="form-group">
                <span class="valueLabel">Supporting Document 2</span><br>
                <select class="form-control form-select mt-1" [(ngModel)]="selectedSupportingDocument2">
                    <option value="" disabled>Select Supporting Document</option>
                    <option value="Bank account passbook">Bank account passbook</option>
                </select>
            </div>
        </div>
    </div> -->

    <div class="row mt-2">
        <div class="col-12">
            <label class="outlet_Name">Attach Supporting Document</label>
            <input type="file" accept=" image/jpg, image/jpeg, image/png" id="attachSupportingDocument1" class="form-control" placeholder="Attach Supporting Document1"
               #attachSupportingDocument1 (change)="fileProgress($event,'attachSupportingDocument1')"/>
               <!-- <input #MeetingPhoto id="MeetingPhoto" type="file" accept="image/jpeg" capture="environment" (change)="fileProgress($event)" style="display: none" /> -->
        </div>
    </div>

    <!-- <div class="row mt-3">
        <div class="col-12">
            <label class="valueLabel">Attach Supporting Document 2</label>
            <input type="file" id="attachSupportingDocument2" class="form-control" placeholder="Attach Supporting Document2"
               #attachSupportingDocument2 (change)="fileProgress($event,'attachSupportingDocument2')"/>

        </div>
    </div> -->

    <div class="row mt-3">
        <div class="col-12">
            <label class="outlet_Name">Registered Mobile No</label>
            <input type="text" class="form-control" placeholder="Enter Registered Mobile No" maxlength="10"
                pattern="^[6-9]\d+$" required (keypress)="numberOnly($event)" (keyup)="validateMobileNo($event)"
                 [(ngModel)]="mobileNo" />
        </div>
    </div>
</div>
<br>
<br><br>
<br>

<div class="footer">
    <div class="row">
        <div class="col-12" (click)="sendOtpAndLink()">
            <button class="btn btn_APPROVE">SEND VERIFICATION CODE & LINK</button>
        </div>
    </div>
</div>

<ng-template #openSendOtpConfirmationBox let-modal id="test">
    <div class="modal-header justify-content-center">
        <span class="modelHeader">SEND VERIFICATION CODE & LINK</span>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12 text-center">
                <span class="modelBody text-center">The verification code & the Link for acceptance of the Outlet Name Change
                    request with Mondelez
                    will be sent to the retailer's registered mobile no. {{mobileNo}}</span>
                <br><br>
                <span class="modelBody">Are you sure you want to send the verification code & link?</span>
            </div>
        </div>
    </div>
    <div class="modal-footer text-center justify-content-center">
        <button type="button" class="cancleBtn" (click)="modalClose()">CANCEL</button>
        <button type="button" class="okBtn" (click)="sendOtp()">SEND</button>
    </div>
</ng-template>