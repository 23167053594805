import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { DatePipe } from '@angular/common';
import { PulloutEntry } from 'src/app/models/kpi-models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-pull-out-reason-pulloutreq',
  templateUrl: './pull-out-reason-pulloutreq.component.html',
  styleUrls: ['./pull-out-reason-pulloutreq.component.css']
})
export class PullOutReasonPulloutreqComponent implements OnInit {
  @ViewChild('openModalMsg', { static: true }) elRefopenModalMsg: ElementRef | undefined;

  deviceType: any;
  device: any;
  searchText = '';
  isLongPress: boolean = false;
  isSelected: boolean = false;
  list: any;
  public pulloutEntry: PulloutEntry = new PulloutEntry();
  selectedReasons: any;
  userType: any;
  userId: any;
  outLetSelected: any;
  msg: any;
  selected = -1;
  selectedByURL: string = '';
  outLetDetails: any;
  token: any;
  formname: any;
  actiondesc: any;
  FieldName: any;
  FieldValue: any;

  constructor(private activatedRoute: ActivatedRoute,private cs:CommonUtilitiesService, private router: Router, private headerService: HeaderService, private datePipe: DatePipe, private mds: MongoDataService, private modalService: NgbModal,) { }

  ngOnInit(): void {
    this.headerService.setTitle('Pull Out Reason');
    this.headerService.setActiveURl('/pullOutReasonPulloutreq');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.userId = localStorage.getItem("UserID");
    this.userType = localStorage.getItem("UserType");
    this.token = localStorage.getItem("TokenNo");
    this.outLetSelected = JSON.parse(localStorage.getItem("outLetSelected") || '{}');
    console.log("outLetSelected",this.outLetSelected);
    this.outLetDetails = JSON.parse(localStorage.getItem("outLetDetails") || '{}');
    console.log("outLetSelected",this.outLetDetails);
    this.selectedByURL = this.activatedRoute.snapshot.queryParams["selected"];
    console.log("selectedByURL",this.selectedByURL);

    
    this.FieldName=localStorage.getItem("FieldName");
    this.FieldValue=localStorage.getItem("FieldValue");
    this.formname = "pullOutReasonPulloutreq";
    this.actiondesc = "Pull Out Reason Pull Out Req";
    this.cs.GetGeoLocation(this.userId, this.userType, this.formname, this.actiondesc, this.FieldName, this.FieldValue);

    this.onLoad();
  }

  onLoad() {
    this.mds.getReasons(this.token,this.device, this.deviceType,'Pull Out Reason').subscribe(res => {
      this.list = res.filter((singleItem: { [x: string]: string; }) => singleItem['reasonType'].startsWith("Pullout"));
    });
  }

  onLongPress() {
    this.isLongPress = true;
  }

  clickOnSelect() {
    this.isSelected = true;
  }

  rowClick() {
    debugger;
    if(this.selectedByURL != 'VS'){
      this.pulloutEntry.RDCOde = this.outLetSelected.rdcode.toString();
      this.pulloutEntry.OutletCode = this.outLetSelected.outletcode.toString();
      this.pulloutEntry.OutletCoderd =  this.outLetSelected.outletcoderd.substring(0, this.outLetSelected.outletcoderd.length-1);
      this.pulloutEntry.Visicooler1 = this.outLetSelected.visicooler;
      this.pulloutEntry.ContactPerson = this.outLetSelected.contactperson==null?'':this.outLetSelected.contactperson;
      this.pulloutEntry.Address1 = this.outLetSelected.address1;
      this.pulloutEntry.Address2 = this.outLetSelected.address2==null?'':this.outLetSelected.address2;
      this.pulloutEntry.Address3 = this.outLetSelected.address3==null?'':this.outLetSelected.address3;
      this.pulloutEntry.LandMark = this.outLetSelected.landMark==null?'':this.outLetSelected.landMark;
      this.pulloutEntry.Town = this.outLetSelected.town==null?'':this.outLetSelected.town;
      this.pulloutEntry.State = this.outLetSelected.state==null?'':this.outLetSelected.state;
      this.pulloutEntry.PinCode = this.outLetSelected.pincode==null?"0":this.outLetSelected.state;
      this.pulloutEntry.PhoneNo = this.outLetSelected.cellno?.split("T")[1]==null?"0":this.outLetSelected.cellno?.split("T")[1];
      this.pulloutEntry.MobileNo = this.outLetSelected.cellno?.split("T")[1]==null?"0":this.outLetSelected.cellno?.split("T")[1];
      this.pulloutEntry.STDCode = this.outLetSelected.stdcode==null?"0":this.outLetSelected.stdcode;
      this.pulloutEntry.PulloutReason = this.selectedReasons;
      this.pulloutEntry.SerialNo = this.outLetSelected.serialno==null?'':this.outLetSelected.serialno;
      this.pulloutEntry.RDName = this.outLetSelected.rdname==null?'':this.outLetSelected.rdname;
      this.pulloutEntry.OutletName = this.outLetSelected.outletname==null?'':this.outLetSelected.outletname;
      this.pulloutEntry.UserID = this.userId;
      this.pulloutEntry.FIRID = this.outLetSelected.firid==null?0:this.outLetSelected.firid;
      this.pulloutEntry.Status = 'pullout';
    }else {
      this.pulloutEntry.RDCOde = this.outLetSelected.rdCode.toString();
      this.pulloutEntry.OutletCode = this.outLetSelected.outletcode.toString();
      this.pulloutEntry.OutletCoderd =  this.outLetSelected.outletCodeRD==null?'':this.outLetSelected.outletCodeRD;
      this.pulloutEntry.Visicooler1 = this.outLetSelected.visicoolertype;
      this.pulloutEntry.ContactPerson = this.outLetDetails[0].contactperson==null?'':this.outLetDetails[0].contactperson;
      this.pulloutEntry.Address1 = this.outLetDetails[0].address1;
      this.pulloutEntry.Address2 = this.outLetDetails[0].address2==null?'':this.outLetDetails[0].address2;
      this.pulloutEntry.Address3 = this.outLetDetails[0].address3==null?'':this.outLetDetails[0].address3;
      this.pulloutEntry.LandMark = this.outLetDetails[0].landMark==null?'':this.outLetDetails[0].landMark;
      this.pulloutEntry.Town = this.outLetDetails[0].town==null?'':this.outLetDetails[0].town;
      this.pulloutEntry.State = this.outLetDetails[0].state==null?'':this.outLetDetails[0].state;
      this.pulloutEntry.PinCode = this.outLetDetails[0].pincode==null?0:this.outLetDetails[0].pincode;
      this.pulloutEntry.PhoneNo = this.outLetDetails[0].cellno?.split("T")[1]==null?'':this.outLetDetails[0].cellno?.split("T")[1];
      this.pulloutEntry.MobileNo = this.outLetDetails[0].cellno?.split("T")[1]==null?'':this.outLetDetails[0].cellno?.split("T")[1];
      this.pulloutEntry.STDCode = this.outLetDetails[0].stdcode==null?0:this.outLetDetails[0].stdcode;
      this.pulloutEntry.SerialNo = this.outLetDetails[0].serialno==null?'':this.outLetDetails[0].serialno;
      this.pulloutEntry.PulloutReason = this.selectedReasons;
      this.pulloutEntry.RDName = this.outLetSelected.rdName==null?'':this.outLetSelected.rdName;
      this.pulloutEntry.OutletName = this.outLetSelected.outletname==null?'':this.outLetSelected.outletname;
      this.pulloutEntry.UserID = this.userId;
      this.pulloutEntry.FIRID = this.outLetSelected.firid==null?0:this.outLetSelected.firid;
      this.pulloutEntry.Status = 'fir';
    }
    console.log("pulloutEntry",this.pulloutEntry);


    this.mds.savePullRequest(this.pulloutEntry, this.token, this.device, this.deviceType,'Pull Out Reason').subscribe(res => {
      if (res[0].msg == "OK") {
        this.router.navigate(['/home/transactions'], { queryParams: { from: 'pullout' } });
      } else {
        this.msg = res[0].msg;
        this.modalService.open(this.elRefopenModalMsg,
          {
            size: 'lg',
            backdrop: 'static',
            windowClass: '',
            keyboard: false,
            centered: true,
          })
      }
    });
  }

  selected_Reason(e: any) {
    if (e.target.checked) {
      this.selectedReasons = e.target.value;
    }
  }

  modalClose() {
    this.modalService.dismissAll();
    this.router.navigate(['/home/pulloutTransactions']);
  }
}
