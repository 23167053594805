<div>
    <!-- <div class="row mt-4">
      <div class="col-12">
        <input class="fullWidth" type="text" name="" id="" #OutletUID placeholder="Search Outlet" [(ngModel)]="searchText" (change)="searchOutlet(OutletUID.value,'pullout')">
      </div>
    </div> -->
    <div class="row mt-4">
      <div class="col-9">
        <input class="fullWidth" type="text" name="" id="" #OutletUID placeholder="Search Outlet" [(ngModel)]="searchText">
      </div>
      <div class="col-3 pl-0">
        <button class="btn btn_Search"  (click)="searchOutlet(OutletUID.value)">Search</button>
      </div>
    </div>
    <!-- <div class="row mt-4">
      <div class="col-12">
        <ul>
          <li *ngFor="let c of items | filter : searchText">
            {{c.title}}
          </li>
        </ul>
      </div>
    </div> -->
    <div *ngFor="let item of list">
      <div class="myDiv" [class.myClass]="isSelected">
        <div class="container-fluid">
          <div long-press (onLongPress)="onLongPress()" (click)="rowClick(item)">
            <div class="row">
              <div class="col-12 mt-2">
                <span class="text_16 text_black">{{item.outletname | titlecase}}</span>
                <span class="ml-1 text_14 text_black" style="opacity: 40%;">{{item.outletcode}}</span>
              </div>
              <!-- <div class="col-2 text-right mt-2" *ngIf="isLongPress">
                <img class="img" src="assets/check.png" *ngIf="!isSelected" (click)="clickOnSelect()" />
                <img class="img" src="assets/check_1.png" *ngIf="isSelected" />
              </div> -->
            </div>
            <div class="row">
              <div class="col-12 mb-2">
                <span style="opacity: 40%;" class="text_12 text_black">{{item.rdname | titlecase}}
                  <span class="ml-1 text_12 text_black">{{item.rdcode}}</span></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br>
    <br>
    <br>
    <br>
    <br>
  </div>

  <ng-template #openModalNonMeraNet let-modal>
    <div class="modal-header">
      <h4 class="modal-title text_color_head text_20" id="modal-basic-title">
        <img class="img-fluid" src="assets/login_notification.png" />
        <span class="ml-3">Notification</span>
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="modalClose()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div>
        <div class="row">
          <div class="col-12">
            <span class="text_16">
              Outlet Not available on Meranet.
              Do you want to add Non-Meranet complaint?
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="row">
        <div class="col-6" (click)="modalClose()">
          <button class="btn btn_DENY">No</button>
        </div>
        <div class="col-6" (click)="yes()">
          <button class="btn btn_ALLOW">Yes</button>
        </div>
      </div>
    </div>
  </ng-template>
