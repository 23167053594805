import { Component, OnInit,ViewChild, ElementRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Resultdata,InputPara } from 'src/app/models/kpi-models';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { FilterComponent } from '../filter/filter.component';
import html2canvas from 'html2canvas';
import * as jsPDF from 'jspdf';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { DataService } from 'src/app/service/data.service';

@Component({
  selector: 'app-rd-performance',
  templateUrl: './rd-performance.component.html',
  styleUrls: ['./rd-performance.component.css']
})
export class RdPerformanceComponent implements OnInit {
  periodList: any[] = [];
  selectedperiod: any;
  UserID: any;
  RDCode: any;
  ParaName:any="rdincentive";
  FieldName:any="rd";
  RdInformation: any;
  EarningTrend: any;
  GrowthOverLYSM: any;
  GrowthOverLYSMUnit: any;
  deviceType: any;
  device: any;
  myDate: any;
  curYear: any;
  curMonth: any;
  prevYearTwoDigit:any;
  currYearTwoDigit:any;
  prevYear: string = '';
  resultdata: Resultdata = new Resultdata();
  panelOpenState: boolean = true;
  list = [1, 2, 3, 4, 5];
  rdGrowData:any;
  period1:any;
  period2:any;
  input:InputPara=new InputPara();
  UlbList: any;
  token: any ;
  lat:any;
  lng:any;
  UserType:any;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, 
    private headerService: HeaderService, private datePipe: DatePipe,
     private mds: MongoDataService, private dialog: MatDialog,private notifyMeService: NotifyMeService,private ds: DataService,) { }

  ngOnInit(): void {
    this.panelOpenState = true;
    this.headerService.setActiveURl('/RdPerformance');
    this.headerService.setTitle("RD Performance");
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.token = localStorage.getItem("TokenNo");
    this.UserID = localStorage.getItem("UserID");
    this.UserType=localStorage.getItem("UserType");
    this.RDCode = localStorage.getItem("RDCode");
    this.myDate = Date.now();
    this.curMonth = this.datePipe.transform(this.myDate, 'MMM');
    this.prevYear = JSON.stringify(new Date().getFullYear() - 1);
    this.prevYearTwoDigit = this.prevYear.substring(2, 4);
    this.currYearTwoDigit=JSON.stringify(new Date().getFullYear()).substring(2,4);
    debugger
    this.GetGeoLocation();
    this.loadData();
    this.loadULB();
  }

  GetGeoLocation() {
    debugger
    if (navigator) {
      navigator.geolocation.getCurrentPosition(pos => {
        this.lat = pos.coords.latitude;
        console.log("  this.lat", this.lat)
        this.lng = pos.coords.longitude;
        this.SetapplicationLogData();
      }, this.handleLocationError, { timeout: 30000 });
    }
    else {
      this.notifyMeService.showError('Location Could not be captured', 'Mera APP');
    }
  }

  handleLocationError(error: { code: any; }) {
    switch (error.code) {
      case 3:
        this.notifyMeService.showError('Timeout in Position capture', 'Mera APP V2');
        break;
      case 2:
        this.notifyMeService.showError('Device is not capable of Position capture', 'Mera APP V2');
        break;
      case 1:
        this.notifyMeService.showError('You denied for Position capture', 'Mera APP V2');
        break;
    }
  }


  SetapplicationLogData() {
    debugger
    let request = {
      "userid": this.UserID,
      "usertype": this.UserType,
      "formname": "RdPerformance",
      "actiondesc": "Rd Performance Data",
      "fieldname": this.FieldName,
      "fieldvalue": this.RDCode,
      "latitude": this.lat,
      "longitude": this.lng
    }
    this.ds.SetapplicationLogData(request).subscribe(res => {
      console.log("res", res)
    })
  }


  loadData(): void {

debugger;
      this.mds.getRdInformation(this.RDCode,this.token, this.UserID,this.device,this.deviceType,'RD Performance').subscribe(res => {
      this.RdInformation = res;
      this.periodList = [...new Set(res.map((bill: { period: any; }) => bill.period))].sort();
      this.selectedperiod = this.periodList[0];
    });
    this.input.FieldName=this.FieldName;
    this.input.FieldValue=this.RDCode;
    this.input.TagName=this.ParaName;
    this.input.Datatype=this.ParaName;
    this.input.UserID=this.UserID
    this.mds.getEarningTrend(this.input,this.token,this.device,this.deviceType,'RD Performance').subscribe(res => {
      this.EarningTrend = res;
     });
     this.input.FieldName=this.FieldName;
    this.input.FieldValue=this.RDCode;
    this.input.TagName="0";
    this.input.Datatype="0";
    this.input.UserID=this.UserID;
    debugger;
     this.mds.getGrowthOverLYSM(this.input,this.token,this.device,this.deviceType,'RD Performance').subscribe(res => {
     this.GrowthOverLYSM = res;
     this.GrowthOverLYSMUnit=res[0].unit;
     });
     this.mds.GetRDGrow( this.RDCode,this.UserID,this.token,this.device,this.deviceType,'RD Performance').subscribe(res => {
      this.rdGrowData = res;
     });
  }
  loadULB(): void {
    this.input.FieldName="RD";
    this.input.FieldValue=this.RDCode;
    this.input.TagName="0";
    this.input.Datatype="0";
    this.input.UserID=this.UserID;
    this.mds.getUlbDashboard(this.input,this.token,this.device, this.deviceType,'Unique Lines Billed').subscribe(res => {

      this.UlbList = res;
      debugger;
    });
  }
  PeriodChange(e: any): void {
    this.selectedperiod =e.source._value;
  }
  filterItemsOfType(type: any) {
    return type != null ? type.filter((x: { [x: string]: any; }) => (x['period'] == this.selectedperiod)) : null;
  }
  openFilter() {
    if (this.dialog.openDialogs.length == 0) {
      const dialogRef = this.dialog.open(FilterComponent, {
        closeOnNavigation: true,
        data: {
          "FieldName": localStorage.getItem("OriginalFieldName"),
          "FieldValue": localStorage.getItem("OriginalFieldValue"),
          "UserID": this.UserID,
          "MenuUpTo": "0",
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.resultdata = result as Resultdata;
        }
      });
    }
  }
  // @ViewChild('content') content: ElementRef   ;
//   captureScreen() {
//     debugger;
//     let data:any = document.getElementById('content');
//       html2canvas(data)
//      .then((canvas) => {
//         const doc = new jsPDF("p", "px", "a4");
//         doc.addHTML(document.getElementById('content'), 10, 10, {pagesplit: true, margin: {top: 10, right: 10, bottom: 10, left: 10, useFor: 'page'}}, function () {doc.save("test.pdf")})
//         });
// }

  //   // var data: any = document.getElementById('content');
  //   // html2canvas(data).then(canvas => {
  //   //   var imgWidth = 208;
  //   //   var pageHeight = 295;
  //   //   var imgHeight = canvas.height * imgWidth / canvas.width;
  //   //   var heightLeft = imgHeight;
  //   //   const contentDataURL = canvas.toDataURL('image/png')
  //   //   let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
  //   //   var position = 0;
  //   //   pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
  //   //   pdf.save('MYPdf.pdf'); // Generated PDF
  //   // });
  // }
   clickOnYTDAch() {
    this.router.navigate(['/home/Secondarytrend/', "rd", this.RDCode, this.UserID]);
  }

  public captureScreen()
  {
    let data:any = document.getElementById('content');
    let data1:any = document.getElementById('content1');
    let data2:any = document.getElementById('content2');
    let data3:any = document.getElementById('content3');
    let data4:any = document.getElementById('content4');
    html2canvas(data).then((canvas: { height: number; width: number; toDataURL: (arg0: string) => any; }) => {
      var imgWidth = 208;
      var pageHeight = 1295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight+500;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)

      html2canvas(data1).then((canvas1: { height: number; width: number; toDataURL: (arg0: string) => any; }) => {
      debugger;
        const contentDataURL1 = canvas1.toDataURL('image/png')
        pdf.addPage();
      pdf.addImage(contentDataURL1, 'PNG', 0, position, imgWidth, imgHeight)
    });
    html2canvas(data2).then((canvas2: { height: number; width: number; toDataURL: (arg0: string) => any; }) => {
      debugger;
      pdf.addPage();
      const contentDataURL2 = canvas2.toDataURL('image/png')
      pdf.addImage(contentDataURL2, 'PNG', 0,position, imgWidth, imgHeight)
    });
    html2canvas(data3).then((canvas3: { height: number; width: number; toDataURL: (arg0: string) => any; }) => {
      debugger;
      pdf.addPage();
      const contentDataURL3 = canvas3.toDataURL('image/png')
      pdf.addImage(contentDataURL3, 'PNG', 0,position, imgWidth, imgHeight)
      pdf.save('MYPdf.pdf');
    });
    html2canvas(data4).then((canvas4: { height: number; width: number; toDataURL: (arg0: string) => any; }) => {
      debugger;
      pdf.addPage();
      const contentDataURL4 = canvas4.toDataURL('image/png')
      pdf.addImage(contentDataURL4, 'PNG', 0,position, imgWidth, imgHeight)
      pdf.save('MYPdf.pdf');
    });
     // Generated PDF
    });
  }
  clickOnYPrimary(){
    this.router.navigate(['/home/PrimaryInvoices/', "rd", this.RDCode, this.UserID]);
  }
}
