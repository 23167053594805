<div>
  <div class="row mt-4" *ngFor="let item of list; let i = index;">
    <div class="col-12 mt-3">
      <div class="form-check">
        <input type="checkbox" class="form-check-input" value={{item.reason}} id="Check{{i}}" [(ngModel)]="list[i].checked" (change)="selected = i; selected_Reason($event)" [checked]="selected === i">
        <label class="form-check-label" style="margin-top: .15rem;" for="Check{{i}}">
          <span class="rd_Name">{{item.reason }}</span>
        </label>
      </div>
    </div>
  <div class="col-12  hr_line">&nbsp;</div>
  </div>
</div>
<div class="footer">
  <div class="row text-center mt-3">
    <div class="col-12" (click)="rowClick()">
      APPROVE
    </div>
  </div>
</div>
