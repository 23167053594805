import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PCMarketData,MarketData, InputPara } from 'src/app/models/kpi-models';
import { EncryptionService } from '../../service/encryption.service';
import { DataService } from '../../service/data.service';
import { Enclass, ResultClass } from '../../models/encryption-class';
import { MatDialog } from '@angular/material/dialog';
import { FilterComponent } from '../filter/filter.component';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-tsidetails-main',
  templateUrl: './tsidetails-main.component.html',
  styleUrls: ['./tsidetails-main.component.css']
})
export class TsidetailsMainComponent implements OnInit {

  FieldName: any;
  FieldValue: any;
  DataType: any;
  UserID: any;
    periodList: any[] = [];
  selectedperiod: any;
  UserType: any;
  currentDate = new Date();
  tsiData:any;
  heading :string="";

  public pcMarketData: PCMarketData = new PCMarketData();
  public marketData: MarketData = new MarketData();
  public enclass: Enclass = new Enclass();
  public resultclass: ResultClass=new ResultClass();
  TokenNo: any ;
  ClientID: any;
  SecreteKey: any;
  IsRDValue: boolean=false;
  rdData: any=null;
  device: any;
  deviceType: any;
  input:InputPara=new InputPara();
  token: any;
  formname:any;
  actiondesc:any;
  fieldName: any;
  fieldValue: any;

  constructor(private activatedRoute: ActivatedRoute,private fb: FormBuilder, private cs:CommonUtilitiesService, private es: EncryptionService,private router: Router,
     private headerService: HeaderService, private mds: MongoDataService,private ds:DataService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.headerService.setActiveURl('/tsidetailsmain');
    this.headerService.setTitle('Active TSI');
    this.UserType = localStorage.getItem("UserType");
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.token = localStorage.getItem("TokenNo");

    this.DataType = "0"
    this.tsiData = [];
    this.TokenNo= localStorage.getItem("TokenNo");
    this.ClientID= localStorage.getItem("ClientID");
   this.SecreteKey=   localStorage.getItem("SecreteKey");
   this.UserID = localStorage.getItem('UserID');

     this.currentDate=new Date();

      this.activatedRoute.paramMap.forEach(params => {
      this.FieldName = params.get('FieldName');
      this.FieldValue = params.get('FieldValue');
      this.DataType = params.get('DataType');
      this.heading=this.FieldName;

       if(this.FieldName=="RD")
      {
         this.heading=this.FieldName;

        let obj=localStorage.getItem("rdData");
         this.rdData = JSON.parse(obj || '{}') as PCMarketData;
         this.IsRDValue=true;

       }
       else{this.IsRDValue=false;  this.heading=this.FieldName;}

           
    this.fieldName = localStorage.getItem("FieldName");
    this.fieldValue = localStorage.getItem("FieldValue");
    this.formname = "tsidetailsmain"
    this.actiondesc = "Tsi Details Main";
    this.cs.GetGeoLocation(this.UserID, this.UserType, this.formname, this.actiondesc, this.fieldName, this.fieldValue);
       this.loadData();
     });
  }
  loadData(): void {
    this.input.FieldName=this.FieldName;
    this.input.FieldValue=this.FieldValue;
    this.input.TagName="details";
    this.input.Datatype="details";
    this.input.UserID=this.UserID;

    this.mds.GetTSIMarketData(this.input,this.token,this.device,this.deviceType,'Tsi Attendence').subscribe(res => {

      this.tsiData= res;
              });
            }

  RowClick(FieldName: any,fieldValue:any): void {

    if(FieldName=="RD" || FieldName=="rd")
    {

      this.heading=FieldName;
       this.IsRDValue=true;
       this.router.navigate(['/home/tsilocation/',FieldName,fieldValue,this.DataType,this.UserID]);
     }
     else
     {
     this.heading=FieldName;
     this.IsRDValue=false;
   this.router.navigate(['/home/tsidetailsmain/',FieldName,fieldValue,this.DataType,this.UserID]);
  }
}

  openFilter(){
    if (this.dialog.openDialogs.length == 0) {
      const dialogRef = this.dialog.open(FilterComponent, {
        closeOnNavigation: true,
        data: {
          "FieldName": localStorage.getItem("OriginalFieldName"),
          "FieldValue": localStorage.getItem("OriginalFieldValue"),
          "UserID": this.UserID,
          "MenuUpTo": "4",
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.FieldName=result.FieldName;
          this.FieldValue=result.FieldValue;
          this.loadData();
              }
      });
    }
  }

}
