<div>
  <div class="row mt-4">
    <div class="col-12">
      <input class="fullWidth" type="text" name="" id="" placeholder="Search SUB/SST Name Or UID"
        [(ngModel)]="searchTerm">
    </div>
  </div>
  <div *ngFor="let item of filteredList; let i = index;" (click)="rowClick(item)">
    <div class="row mt-3">
      <div class="col-9">
        <span class="pc_Name">{{item.name | titlecase}}</span>
        <span class="ml-1 pc_Code">{{item.code}}</span>
      </div>
      <div class="col-3 text-right">
        <span class="pc_Name">{{item.type}}</span>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12">
        <span class="audit_Date">{{item.auditDate}}</span>
      </div>
    </div>
    <hr>
  </div>
  <br>
  <br>
  <br>
  <br>
  <br>
</div>
