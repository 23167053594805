<div>
  <div class="row mt-4" *ngFor="let item of list; let i = index;">
    <div class="col-12 mt-3">
      <div class="form-check">
        <input type="checkbox" class="form-check-input" value={{item.reasonName}} id="Check{{i}}" [(ngModel)]="list[i].checked" (change)="selected = i; selected_Reason($event)" [checked]="selected === i">
        <label class="form-check-label text_16 text_black" style="margin-top: .15rem;" for="Check{{i}}">{{item.reasonName | titlecase}}</label>
      </div>
    </div>
  <div class="col-12  hr_line">&nbsp;</div>
  </div>
</div>
<div class="footer">
  <div class="row">
    <div class="col-12 text-center" (click)="save()">
      <button class="btn btn_Action">CONTINUE FOR SECONDARY ACTION</button>
    </div>
    <!-- <div class="col-6 pl-0" (click)="action()">
      <button class="btn btn_Action">ACTION</button>
    </div> -->
  </div>
</div>
