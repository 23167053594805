import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { DataService } from 'src/app/service/data.service';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-facial-recognition-approval',
  templateUrl: './facial-recognition-approval.component.html',
  styleUrls: ['./facial-recognition-approval.component.css']
})

export class FacialRecognitionApprovalComponent implements OnInit {
  deviceType: any;
  device: any;
  userType: string | null = '';
  userId: any;
  list: any;
  msg: any;
  private _searchText = '';
  filteredList: any;
  token: any;
  formname:any;
  actiondesc:any;
  fieldName: any;
  fieldValue: any;

  constructor(private activatedRoute: ActivatedRoute,private cs:CommonUtilitiesService, private router: Router, private headerService: HeaderService, private mds: MongoDataService, private notifyMeService:NotifyMeService, private ds: DataService,) { }

  ngOnInit(): void {
    this.headerService.setTitle('Facial Recognition');
    this.headerService.setActiveURl('/facialRecognitionApproval');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.userType = localStorage.getItem("UserType");
    this.userId = localStorage.getItem("UserID");
    this.token = localStorage.getItem("TokenNo");

    
    this.fieldName = localStorage.getItem("FieldName");
    this.fieldValue = localStorage.getItem("FieldValue");
    this.formname="facialRecognitionApproval";
    this.actiondesc="Facial Recognition Approval"
    this.cs.GetGeoLocation(this.userId,this.userType,this.formname,this.actiondesc,this.fieldName,this.fieldValue);
    this.onLoad();
  }
  onLoad() {
    let id = 0;
    this.ds.getRegisterEntry(this.userId, id, this.token, this.device, this.deviceType,'Facial Recognition').subscribe(res => {
      this.list = res;
      this.filteredList = this.list;
    });
  }

  rowClick(data: any){
    if(this.userType?.toUpperCase() == "ASM" || this.userType?.toUpperCase() == "SO"){
      localStorage.setItem("facialRecDetails",JSON.stringify(data))
      this.router.navigate(['/home/facialRecDetailsApproval']);
    }else{
      this.notifyMeService.showWarning("You are not authorized for this transaction", 'Sales Genie App');
    }
  }

  get searchTerm(): any{
    return this._searchText;
  }
  set searchTerm(value: any){
     this._searchText = value;
     this.filteredList = this.filteredListMethod(value);
  }

  filteredListMethod(value: any): any {
    return this.list.filter((ele: any) =>(
      ele.pcName.toUpperCase().indexOf(value.toUpperCase()) !== -1 || ele.pcuid.toString().toUpperCase().indexOf(value.toUpperCase()) !== -1));
  }

}
