<div class="mt-3 urjaa_VP">
  <mat-tab-group dynamicHeight mat-align-tabs="center">
    <mat-tab label="New Villages" class="New-VillagesTab">
      <!-- content1 -->
      <div class="row mt-4">
        <div class="col-4">
          <span class="All-India">All India</span>
        </div>
        <div class="col-3">
          <mat-form-field appearance="outline" style="width: 90px">
            <mat-select id="period" name="period">
              <!-- (selectionChange)="changeMonth($event)"
                <mat-option *ngFor="let item of periodList" [value]="item">{{ item }}</mat-option> -->
              <mat-option value="">5 Outlets</mat-option>
              <mat-option value="">10 Outlets</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-1"></div>
        <div class="col-4">
          <mat-form-field appearance="outline" style="width: 90px">
            <mat-select id="period" name="period">
              <!-- (selectionChange)="changeMonth($event)"
                    <mat-option *ngFor="let item of periodList" [value]="item">{{ item }}</mat-option> -->
              <mat-option value="">2K-5K</mat-option>
              <mat-option value="">5K-10K</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-6">
          <span class="VillagesHeading">Villages</span>
        </div>
        <div class="col-6 text-right">
          <span class="MonthHeading">Month</span>
        </div>
      </div>
      <div class="row mt-4" *ngFor="let item of list">
        <div class="col-3">
          <span class="VillagesValue">500</span>
        </div>
        <div class="col-6 p-0">
          <div class="row mt-2">
            <div class="col-12">
              <div class="progress rounded-pill">
                <div class="progress-bar bg_sec" role="progressbar" style="width: 35%" aria-valuenow="35"
                  aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-3 text-right">
          <span class="VillagesMonth">Jan 21</span>
        </div>
      </div>
      <!-- Content1 -->

    </mat-tab>
    <mat-tab label="No Outlet Villages" class="No-Outlet-VillagesTab"> Content 2 </mat-tab>
  </mat-tab-group>
</div>
