import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { GetProgramtargetDetailsDashboard, Resultdata, InputPara } from 'src/app/models/kpi-models';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { FilterComponent } from '../filter/filter.component';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { DataService } from 'src/app/service/data.service';

@Component({
  selector: 'app-common-performance-data',
  templateUrl: './common-performance-data.component.html',
  styleUrls: ['./common-performance-data.component.css']
})
export class CommonPerformanceDataComponent implements OnInit {
  selected: any;
  selectedType: string = '';
  public input: InputPara = new InputPara();
  public getProgramtargetDetailsList: GetProgramtargetDetailsDashboard[] = [];
  deviceType: any;
  device: any;
  data: any;
  datatype: any;
  FieldName: any;
  FieldValue: any;
  UserID: any;
  IsRDValue: boolean = false;
  IsOutlet: boolean = false;
  resultdata: Resultdata = new Resultdata();
  FormName: any;
  IsPC: boolean = false;
  token: any;
  lat:any;
  lng:any;
  UserType:any

  constructor(private activatedRoute: ActivatedRoute, private router: Router, 
    private headerService: HeaderService, private datePipe: DatePipe,
     private mds: MongoDataService, private dialog: MatDialog,private notifyMeService: NotifyMeService,private ds: DataService) { }

  ngOnInit(): void {
    this.headerService.setActiveURl('/CommomPerformanceData');
    this.FormName = 'CommomPerformanceData';
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.token = localStorage.getItem("TokenNo");
    this.UserType=localStorage.getItem("UserType");
    this.activatedRoute.paramMap.forEach(params => {
      this.FieldValue = params.get('FieldValue');
      this.FieldName = params.get('FieldName');
      this.UserID = params.get('UserID');
      this.datatype = params.get('DataType');
      this.selected = params.get('Selected');
   
      switch (this.selected) {
        case "P7": {
          if (this.FieldName == "outlet") {
            this.headerService.setTitle('P7 Payout');
          }
          else {
            this.headerService.setTitle('P7 (' + (this.datatype == 'woa' ? 'WOA' : this.datatype) + ")");
            this.FormName = 'P7 (' + (this.datatype == 'woa' ? 'WOA' : this.datatype) + ')';
          }
          break;
        }

        case "P5": {
          if (this.FieldName == "outlet") {
            this.headerService.setTitle('P5 Payout');
          }
          else {
            this.headerService.setTitle('P5 (' + (this.datatype == 'woa' ? 'WOA' : this.datatype) + ")");
            this.FormName = 'P5 (' + (this.datatype == 'woa' ? 'WOA' : this.datatype) + ')';
          }
          break;
        }
        case "Milan": {
          if (this.FieldName == "outlet") {
            this.headerService.setTitle('Milan Payout');
          } else {
            this.headerService.setTitle('Milan (' + (this.datatype == 'woa' ? 'WOA' : this.datatype) + ")");
            this.FormName = 'Milan (' + (this.datatype == 'woa' ? 'WOA' : this.datatype) + ')';
          }
          break;
        }
        case "Udaan": {
          if (this.FieldName == "outlet") {
            this.headerService.setTitle('Udaan Payout');
          } else {
            this.headerService.setTitle('Udaan (' + (this.datatype == 'woa' ? 'WOA' : this.datatype) + ")");
            this.FormName = 'Udaan (' + (this.datatype == 'woa' ? 'WOA' : this.datatype) + ')';
          }
          break;
        }
        default: {
          console.log("Invalid choice");
          break;
        }
      }
      if (this.selected == 'P7') {
        this.selectedType = 'p7tgt';
      } else if (this.selected == 'P5') {
        this.selectedType = 'p5tgt';
      } else if (this.selected == 'Milan') {
        this.selectedType = 'milantgt';
      } else if (this.selected == 'Udaan') {
        this.selectedType = 'udantgt';
      }
      debugger
      this.GetGeoLocation();
      this.loadData();
    });
  }

  GetGeoLocation() {
    debugger
    if (navigator) {
      navigator.geolocation.getCurrentPosition(pos => {
        this.lat = pos.coords.latitude;
        console.log("  this.lat", this.lat)
        this.lng = pos.coords.longitude;
        this.SetapplicationLogData();
      }, this.handleLocationError, { timeout: 30000 });
    }
    else {
      this.notifyMeService.showError('Location Could not be captured', 'Mera APP');
    }
  }

  handleLocationError(error: { code: any; }) {
    switch (error.code) {
      case 3:
        this.notifyMeService.showError('Timeout in Position capture', 'Mera APP V2');
        break;
      case 2:
        this.notifyMeService.showError('Device is not capable of Position capture', 'Mera APP V2');
        break;
      case 1:
        this.notifyMeService.showError('You denied for Position capture', 'Mera APP V2');
        break;
    }
  }


  SetapplicationLogData() {
    debugger
    let request = {
      "userid": this.UserID,
      "usertype": this.UserType,
      "formname": "CommomPerformanceData",
      "actiondesc": "Commom Performance Data" +" "+this.FieldName,
      "fieldname": this.FieldName,
      "fieldvalue": this.FieldValue,
      "latitude": this.lat,
      "longitude": this.lng
    }
    this.ds.SetapplicationLogData(request).subscribe(res => {
      console.log("res", res)
    })
  }


  loadData(): void {
    debugger
    this.getProgramtargetDetailsList = [];
    if (this.selected == 'P7' || this.selected == 'Milan' || this.selected == 'Udaan' || this.selected == 'P5') {
      if (this.FieldName == "outlet") {
        this.input.FieldName = this.FieldName;
        this.input.FieldValue = this.FieldValue.toString();
        if (this.selected == 'P7') {
          this.input.TagName = "p7";
        } else if (this.selected == 'P5') {
          this.input.TagName = "p5";
        } else {
          this.input.TagName = "other";
        }
        this.input.Datatype = this.datatype;
        this.input.UserID = this.UserID;
        debugger;
        this.mds.getP7payout(this.input, this.token, this.device, this.deviceType, this.FormName).subscribe(res => {
          debugger;
          this.getProgramtargetDetailsList = res;
          console.log("getProgramtargetDetailsList",this.getProgramtargetDetailsList)
          this.getProgramtargetDetailsList.forEach(element => {
            // let indexpaymentdate = element.paymentdate.lastIndexOf(" ");
            // element.paymentdate = element.paymentdate.substr(0, indexpaymentdate);
            let indexuploaddate = element.uploaddate.lastIndexOf(" ");
            element.uploaddate = element.uploaddate.substr(0, indexuploaddate);
          });
        });
      } else {
        this.input.FieldName = this.FieldName;
        this.input.FieldValue = this.FieldValue;
        this.input.TagName = this.selectedType;
        this.input.Datatype = this.datatype;
        this.input.UserID = this.UserID;

        this.mds.getProgramtargetDetailsDashboard(this.input, this.token, this.device, this.deviceType, this.FormName).subscribe(res => {
          this.getProgramtargetDetailsList = res;
          console.log("getProgramtargetDetailsList",this.getProgramtargetDetailsList)
          if (this.getProgramtargetDetailsList.length > 0 && this.getProgramtargetDetailsList[0].fieldname == "rd") {
            this.IsRDValue = true;
          } else {
            this.IsRDValue = false;
          }
          if (this.getProgramtargetDetailsList.length > 0 && this.getProgramtargetDetailsList[0].fieldname == "outlet") {
            this.IsOutlet = true;
          } else {
            this.IsOutlet = false;
          }
        });
      }
    }
  }
  // loaddata(){
  //   this.mds.getProgramtargetDetailsDashboard(this.selectedType, localStorage.getItem("FieldName") , localStorage.getItem("FieldValue"), this.userName, this.device, this.deviceType).subscribe(res => {
  //     console.log("getProgramtargetDetails", res);
  //     this.getProgramtargetDetailsList = res;
  //   });

  // }
  openFilter() {
    if (this.dialog.openDialogs.length == 0) {
      const dialogRef = this.dialog.open(FilterComponent, {
        closeOnNavigation: true,
        data: {
          "FieldName": localStorage.getItem("OriginalFieldName"),
          "FieldValue": localStorage.getItem("OriginalFieldValue"),
          "UserID": this.UserID,
          "MenuUpTo": "5",
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.resultdata = result as Resultdata;
          this.FieldName = this.resultdata.FieldName;
          this.FieldValue = this.resultdata.FieldValue;
          this.loadData();
        }
      });
    }
  }
  RowClick(fieldName: any, fieldvalue: any): void {
    this.router.navigate(['/home/CommomPerformanceData/', fieldName, fieldvalue, this.datatype, this.UserID, this.selected]);
  }

}
