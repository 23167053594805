import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { DataService } from 'src/app/service/data.service';
import { InputPara } from 'src/app/models/kpi-models';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-claim-approval',
  templateUrl: './claim-approval.component.html',
  styleUrls: ['./claim-approval.component.css']
})
export class ClaimApprovalComponent implements OnInit {
  deviceType: any;
  device: any;
  userType: string | null = '';
  userId: any;
  list: any;
  private _searchText = '';
  filteredList: any;
  fieldName: any;
  fieldValue: any;
  input:InputPara=new InputPara();
  token: any;
  formname:any;
  actiondesc:any;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private headerService: HeaderService, private mds: MongoDataService, 
    private notifyMeService:NotifyMeService, private ds: DataService,private cs:CommonUtilitiesService) { }


  ngOnInit(): void {
    this.headerService.setTitle('Claim Approvals');
    this.headerService.setActiveURl('/claimApproval');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.token = localStorage.getItem("TokenNo");
    this.userType = localStorage.getItem("UserType");
    this.userId = localStorage.getItem("UserID");
    this.fieldName = localStorage.getItem("FieldName");
    this.fieldValue = localStorage.getItem("FieldValue");

    this.formname="claimApproval";
    this.actiondesc="claim Approval"
    this.cs.GetGeoLocation(this.userId,this.userType,this.formname,this.actiondesc,this.fieldName,this.fieldValue);
    this.onLoad();
  }

  onLoad() {
    if(this.userType?.toUpperCase() == 'SO'){
      this.ds.soAuthorisation(this.userId, this.token, this.device, this.deviceType, 'Claim Approvals').subscribe(res => {
        console.log("soAuthorisation",res)
        this.list = res;
        this.filteredList = this.list;
      });
    } else if(this.userType?.toUpperCase() == 'ASM'){
      this.ds.asmAuthorisation(this.userId, this.token, this.device, this.deviceType, 'Claim Approvals').subscribe(res => {
        console.log("asmAuthorisation",res)
        this.list = res;
        this.filteredList = this.list;
      });
    } else if(this.userType?.toUpperCase() == 'BSM'){
      this.ds.bsmAuthorisation(this.userId, this.token, this.device, this.deviceType, 'Claim Approvals').subscribe(res => {
        console.log("bsmAuthorisation",res)
        this.list = res;
        this.filteredList = this.list;
      });
    }
  }

  rowClick(data: any){
    localStorage.setItem("claimDetails",JSON.stringify(data))
    this.router.navigate(['/home/claimDetailsApproval']);
  }

  get searchTerm(): any{
    return this._searchText;
  }
  set searchTerm(value: any){
     this._searchText = value;
     this.filteredList = this.filteredListMethod(value);
  }

  filteredListMethod(value: any): any {
    return this.list.filter((ele: any) =>
      ele.rdname.toUpperCase().indexOf(value.toUpperCase()) !== -1);
  }

}
