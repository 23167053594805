import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { GetVPOORVISIScan, InputPara, Resultdata } from 'src/app/models/kpi-models';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterComponent } from '../filter/filter.component';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { DataService } from 'src/app/service/data.service';

@Component({
  selector: 'app-vpo-data',
  templateUrl: './vpo-data.component.html',
  styleUrls: ['./vpo-data.component.css']
})
export class VpoDataComponent implements OnInit {
  FieldName: any;
  FieldValue: any;
  DataType: any;
  UserID: any;
  vpo: any;
  periodList: any[] = [];
  selectedperiod: any;
  UserType: any;
  TagName: any;
  rdData: any = null;
  IsRDValue: boolean = false;
  PrimaryText: any;
  SecondaryText: any;
  resultdata: Resultdata = new Resultdata();
  device: any;
  deviceType: any;
  FormName: any;
  input: InputPara = new InputPara();
  isPc: boolean = false;
  token: any;
  lat: any;
  lng: any;
  constructor(private activatedRoute: ActivatedRoute, private fb: FormBuilder, 
    private router: Router, private headerService: HeaderService, 
    private mds: MongoDataService, private dialog: MatDialog,private notifyMeService: NotifyMeService,private ds: DataService,) { }

  ngOnInit(): void {
    this.headerService.setActiveURl('/VpoData');
    this.UserType = localStorage.getItem("UserType");
    this.DataType = localStorage.getItem("DataType");
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.token = localStorage.getItem("TokenNo");
    this.vpo = [];
    this.selectedperiod = localStorage.getItem("selectedperiod");
    this.activatedRoute.paramMap.forEach(params => {
      this.FieldValue = params.get('FieldValue');
      this.FieldName = params.get('FieldName');
      this.UserID = params.get('UserID');
      this.selectedperiod = params.get('selectedperiod');
      this.TagName = params.get('TagName');

      if (this.TagName == "VPO") {
        this.headerService.setTitle("VPO");
        this.PrimaryText = "VPO Achieved";
        this.SecondaryText = "Total VPO";
        this.FormName = "VPO";
      }
      else {
        this.headerService.setTitle("VC Scan");
        this.PrimaryText = "VC Scan";
        this.SecondaryText = "Total VC";
        this.FormName = "VC Scan";
      }
      if (this.FieldName == "RD") {
        let obj = localStorage.getItem("rdData");
        this.rdData = JSON.parse(obj || '{}') as GetVPOORVISIScan;
        this.IsRDValue = true;
      }
      else { this.IsRDValue = false; }
      this.isPc = this.DataType.toUpperCase() == 'URBAN' ? true : false;
      debugger
      this.GetGeoLocation();
      this.loadData();
    });
  }

  GetGeoLocation() {
    debugger
    if (navigator) {
      navigator.geolocation.getCurrentPosition(pos => {
        this.lat = pos.coords.latitude;
        console.log("  this.lat", this.lat)
        this.lng = pos.coords.longitude;
        this.SetapplicationLogData();
      }, this.handleLocationError, { timeout: 30000 });
    }
    else {
      this.notifyMeService.showError('Location Could not be captured', 'Mera APP');
    }
  }

  handleLocationError(error: { code: any; }) {
    switch (error.code) {
      case 3:
        this.notifyMeService.showError('Timeout in Position capture', 'Mera APP V2');
        break;
      case 2:
        this.notifyMeService.showError('Device is not capable of Position capture', 'Mera APP V2');
        break;
      case 1:
        this.notifyMeService.showError('You denied for Position capture', 'Mera APP V2');
        break;
    }
  }


  SetapplicationLogData() {
    debugger
    let request = {
      "userid": this.UserID,
      "usertype": this.UserType,
      "formname": "VpoData",
      "actiondesc": "Vpo Data" + " " + this.FieldName,
      "fieldname": this.FieldName,
      "fieldvalue": this.FieldValue,
      "latitude": this.lat,
      "longitude": this.lng
    }
    this.ds.SetapplicationLogData(request).subscribe(res => {
      console.log("res", res)
    })
  }

  loadData(): void {
    this.input.FieldName = this.FieldName;
    this.input.FieldValue = this.FieldValue;
    this.input.TagName = this.TagName;
    this.input.Datatype = this.DataType;
    this.input.UserID = this.UserID;

    this.mds.getVPOORVISIScan(this.input, this.token, this.device, this.deviceType, this.FormName).subscribe(res => {
      this.vpo = res as GetVPOORVISIScan;
      debugger;
      this.isPc = this.FieldName.toUpperCase() == 'RD' ? res[0].rdtype.toUpperCase() == 'URBAN' ? true : false : false;
      debugger;
      this.periodList = [...new Set(res.map((bill: { period: any; }) => bill.period))].sort();
      //  this.selectedperiod = this.periodList[0];
    });
  }
  PeriodChange(e: any): void {
    this.selectedperiod = e.source._value;

  }
  filterItemsOfType(type: any) {

    return type != null ? type.filter((x: { [x: string]: any; }) => (x['period'] == this.selectedperiod)) : null;
  }
  RowClick(e: any): void {
    this.FieldName = this.FieldName.toUpperCase() == 'ALLINDIA' ? 'BRANCH' : this.FieldName.toUpperCase() == 'BRANCH' ? 'REGION' : this.FieldName.toUpperCase() == 'REGION' ? 'ASMAREA' : this.FieldName.toUpperCase() == 'ASMAREA' ? 'SOTERRITORY' : this.FieldName.toUpperCase() == 'SOTERRITORY' ? 'RD' : 'RD';
    if (this.FieldName == "RD") {
      let rdData = this.vpo.filter((singleItem: { [x: string]: string; }) => (singleItem['valuetype'] == e && singleItem['period'] == this.selectedperiod));
      localStorage.setItem("rdData", JSON.stringify(rdData));

    }
    else {
      localStorage.setItem("rdData", '');
    }
    this.router.navigate(['/home/VpoData/', this.FieldName, e, this.TagName, localStorage.getItem("DataType"), this.UserID, this.selectedperiod]);
  }
  openFilter() {
    if (this.dialog.openDialogs.length == 0) {
      const dialogRef = this.dialog.open(FilterComponent, {
        closeOnNavigation: true,
        data: {
          "FieldName": localStorage.getItem("OriginalFieldName"),
          "FieldValue": localStorage.getItem("OriginalFieldValue"),
          "UserID": this.UserID,
          "MenuUpTo": "5",
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.FieldName = result.FieldName;
          this.FieldValue = result.FieldValue;
          this.loadData();
        }
      });
    }
  }

}
