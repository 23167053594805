import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { DatePipe } from '@angular/common';
import { getRdList,InputPara } from 'src/app/models/kpi-models';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';
import { MatDialog } from '@angular/material/dialog';
import { FilterComponent } from '../filter/filter.component';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { DataService } from 'src/app/service/data.service';
@Component({
  selector: 'app-rd-list',
  templateUrl: './rd-list.component.html',
  styleUrls: ['./rd-list.component.css']
})
export class RdListComponent implements OnInit {
  deviceType: any;
  device: any;
  isLongPress: boolean = false;
  isSelected: boolean = false;
  list:any;
  listOrigin:any;
  userType: string | null = '';
  userId: any;
  FieldName: any;
  FieldValue: any;
  myBooks: any;
  userList1: any;
  lastkeydown1: number = 0;
  selectedText: any;
  input:InputPara=new InputPara();
  token: any;
  lat:any;
  lng:any;
  UserID:any;
  UserType:any;

  constructor( private dialog: MatDialog,private cs:CommonUtilitiesService,private activatedRoute: ActivatedRoute, 
    private router: Router, private headerService: HeaderService,
     private datePipe: DatePipe, private mds: MongoDataService,
     private notifyMeService: NotifyMeService,private ds: DataService) { }

  ngOnInit(): void {
    this.headerService.setTitle('RD Performance');
    this.headerService.setActiveURl('/RdList');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.userId = localStorage.getItem("UserID");
    this.userType = localStorage.getItem("UserType");
    this.token = localStorage.getItem("TokenNo");
    this.UserID = localStorage.getItem("UserID");
    this.UserType=localStorage.getItem("UserType");
    this.cs.setFieldNamevalue();
    this.FieldName=localStorage.getItem("FieldName");
    this.FieldValue=localStorage.getItem("FieldValue");
    this.list=[];
    this.listOrigin=[];
    debugger
    this.GetGeoLocation();
    this.loadData();
  }
  GetGeoLocation() {
    debugger
    if (navigator) {
      navigator.geolocation.getCurrentPosition(pos => {
        this.lat = pos.coords.latitude;
        console.log("  this.lat", this.lat)
        this.lng = pos.coords.longitude;
        this.SetapplicationLogData();
      }, this.handleLocationError, { timeout: 30000 });
    }
    else {
      this.notifyMeService.showError('Location Could not be captured', 'Mera APP');
    }
  }

  handleLocationError(error: { code: any; }) {
    switch (error.code) {
      case 3:
        this.notifyMeService.showError('Timeout in Position capture', 'Mera APP V2');
        break;
      case 2:
        this.notifyMeService.showError('Device is not capable of Position capture', 'Mera APP V2');
        break;
      case 1:
        this.notifyMeService.showError('You denied for Position capture', 'Mera APP V2');
        break;
    }
  }


  SetapplicationLogData() {
    debugger
    let request = {
      "userid": this.UserID,
      "usertype": this.UserType,
      "formname": "RdList",
      "actiondesc": "Rd List Data",
      "fieldname": this.FieldName,
      "fieldvalue": this.FieldValue,
      "latitude": this.lat,
      "longitude": this.lng
    }
    this.ds.SetapplicationLogData(request).subscribe(res => {
      console.log("res", res)
    })
  }

  loadData() {
    this.input.FieldName=this.FieldName;
    this.input.FieldValue=this.FieldValue;
    this.input.TagName='0';
    this.input.Datatype= '0';
    this.input.UserID=this.userId;

    this.mds.getRdList(this.input, this.token, this.device, this.deviceType,'RD Performance').subscribe(res => {
      this.listOrigin= this.list = res;

      this.fillAutoCompleteSearchBox();
    });
  }

  onLongPress() {
    this.isLongPress = true;
  }

  clickOnSelect() {
    this.isSelected = true;
  }

  RowClick(e: any): void {
    localStorage.setItem("RDCode",e);
    this.router.navigate(['/home/RdPerformance/']);
  }

  fillAutoCompleteSearchBox() {
    this.myBooks = [];
    let mybook1 :any = [...new Set(this.listOrigin.map((bill: { rdname: string; rdcode: number; }) => (bill.rdname + ' [' + bill.rdcode + '] ' )))];
    this.myBooks = [].concat.apply([], mybook1);

  }
  getUserIdsFirstWay($event: any) {
    let userId = (<HTMLInputElement>document.getElementById('userIdFirstWay')).value;
   this.userList1 = [];
    if (userId.length > 2) {
      if ($event.timeStamp - this.lastkeydown1 > 200) {
        this.userList1 = this.searchFromArray(this.myBooks, userId);
      }
    }
  }
  searchFromArray(arr: any, regex : any) {
    let matches = [], i, j = 0;
    for (i = 0; i < arr.length; i++) {
      if (arr[i].toLowerCase().match(regex.toLowerCase())) {
        matches.push(arr[i]);
        j++;
        i = j < 10 ? i : arr.length;
      }
    }
    return matches;
  };

  SearchText(e: any): void {
    this.selectedText = e.target.value;
    // let isfound: boolean = false;
     let selectingType = this.selectedText.substring(this.selectedText.indexOf('[')+1,this.selectedText.indexOf(']'));
     this.RowClick(selectingType);
     (<HTMLInputElement>document.getElementById('userIdFirstWay')).value = '';
  }
  openFilter(){
    if (this.dialog.openDialogs.length == 0) {
      const dialogRef = this.dialog.open(FilterComponent, {
        closeOnNavigation: true,
        data: {
          "FieldName": localStorage.getItem("OriginalFieldName"),
          "FieldValue": localStorage.getItem("OriginalFieldValue"),
          "UserID": this.userId,
          "MenuUpTo": "4",
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.FieldName=result.FieldName;
          this.FieldValue=result.FieldValue;
          this.loadData();
              }
      });
    }
  }
}
