<!-- <div class="w-100 mt-4"> -->
<!-- <div class="row">
        <div class="col-6 pr-0">
            <label for="outlet">Outlet Type : </label>
            <select class="form-select ms-2" aria-label="Default select example" [(ngModel)]="selectedOutletType"
                (change)="onChangeOutletType()">
                <option selected>Selected</option>
                <option value="Upgrade">Upgrade</option>
                <option value="Replacement">Replacement</option>
                <option value="Expansion">Expansion</option>
            </select>
        </div>

        <div class="col-6 pr-0 pl-1">
            <label for="visicooler">Visicooler : </label>
            <select class="form-control form-select ms-2" [(ngModel)]="selectedVisicooler">
                <option selected>Selected</option>
                <option value="{{list?.itemID}}" *ngFor="let list of visicoolerList">{{list?.itemName}}</option>
            </select>
        </div>
    </div> -->

<!-- <div class="row mt-4">
        <div class="col-12">
            <label for="outletCode">Enter Outlet Code : </label>
            <input class="outletCode ms-2" type="text" name="" id="" [(ngModel)]="outletCode"
                (keypress)="numberOnly($event)">
        </div>
    </div> -->

<div>
    <div class="row" style="margin-top: 2rem;">
        <div class="col-12">
            <div class="form-group">
                <!-- <select id="ddlcomplaint" data-toggle="collapse" aria-expanded="false"
                    class="form-control-plaintext btn-block" (change)="changecomplaint($event)">
                    <option value="1">Select complaint</option>
                    <option *ngFor="let obj of reason" [value]="obj.reasonName">
                        {{obj.reasonName}}
                    </option>
                </select> -->

                <select class="form-select" aria-label="Default select example" [(ngModel)]="selectedOutletType"
                    (change)="onChangeOutletType()" [ngClass]="{'midOpacity':(!selectedOutletType)}">
                    <!-- <option selected>Selected</option> -->
                    <option value="" disabled>Select outlet Type</option>
                    <option value="Upgrade">Upgrade</option>
                    <option value="Replacement">Replacement</option>
                    <option value="Expansion">Expansion</option>
                </select>
            </div>
        </div>
    </div>

    <div class="row mt-2">
        <div class="col-12">
            <div class="form-group">
                <select class="form-control form-select " [(ngModel)]="selectedVisicooler"
                    [ngClass]="{'midOpacity':(!selectedVisicooler)}">
                    <option value="" disabled>Select visicooler</option>
                    <option value="{{list?.itemID}}" *ngFor="let list of visicoolerList">{{list?.itemName}}</option>
                </select>
            </div>
        </div>
    </div>

    <div class="row mt-2">
        <div class="col-12">
            <!-- <div class="form-group"> -->
            <!-- <input class="outletCode ms-2" type="text" name="" id="" [(ngModel)]="outletCode"
                    placeholder="Enter Outlet Code" (keypress)="numberOnly($event)"> -->
            <input type="text" class="outletCode1" [(ngModel)]="outletCode" placeholder="Enter Outlet Code" class="w-100"
                (keypress)="numberOnly($event)"  style="height: 145%;" maxlength="20"/>
            <!-- </div> -->
        </div>
    </div>

</div>


<div class="footer">
    <div class="row">
        <div class="col-6 pr-0" (click)="showMessage()">
            <button class="btn btn_GO">ADD</button>
        </div>
        <div class="col-6 pl-0" (click)="reset()">
            <button class="btn btn_RESET">RESET</button>
        </div>
    </div>
</div>