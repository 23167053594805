import { Component, OnInit,EventEmitter,Output } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/service/data.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { HttpEventType, HttpClient, HttpHeaders } from '@angular/common/http';
import { MongoDataService } from 'src/app/service/mongo-data.service';

@Component({
  selector: 'app-gate-meetingnew',
  templateUrl: './gate-meetingnew.component.html',
  styleUrls: ['./gate-meetingnew.component.css']
})
export class GateMeetingnewComponent implements OnInit {
  MeetingForm = this.fb.group({
    // mobNo: ['', [Validators.required]],
    TypeOfMeeting: ['', [Validators.required]],
    Participants: [null, [Validators.required, Validators.pattern("[0-9 ]")]],
    MeetingNote: ['', [Validators.required]],
    IsSharedTarget: [false]
  });
  MeetingDtls: any;
  TypeList: any;
  TypeOfMeetingVal: any;
  imgURL: any;
  image: any;
  isValid: boolean = false;
  UserID: any;
  lat: number = 0;
  lng: number = 0;
  MeetingID: any;
  NewMeetingID: number=3;
  deviceType: any;
  device: any;
  FormName: any;
  isSave: boolean = false;
  progress: number=0;
  @Output() public onUploadFinished = new EventEmitter();
  PhotoPath: any;
  photo: any;
  showModal: boolean=false;
  selectedimagepath: any;
  token: any;


  httpOptions: HttpHeaders =
  new HttpHeaders({
    'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'multipart/form-data',
    'enctype': 'multipart/form-data',
    'charset': 'UTF-8',
    'FolderName': 'GateMeeting',
  })
  constructor(private headerService: HeaderService, private activatedRoute: ActivatedRoute, private router: Router, private ds: DataService, private fb: FormBuilder, private notifyMeService: NotifyMeService, private http: HttpClient,private mds:MongoDataService) { }

  ngOnInit(): void {
    // this.headerService.setTitle("New Gate Meeting");
    this.headerService.setActiveURl('/GateMeetingnew');
    this.device = localStorage.getItem('Device');
    this.deviceType = localStorage.getItem('DeviceType');
    this.token = localStorage.getItem("TokenNo");
    this.FormName = 'GateMeetingnew';

    this.UserID = localStorage.getItem("UserID");
    this.TypeList = ['TSI Day 1', 'PC Market Working', 'TSI market working', 'Other'];
    this.MeetingDtls = [];
    this.activatedRoute.paramMap.forEach(params => {
      this.MeetingID = params.get('MeetingID');
      this.selectedimagepath="";
      if (this.MeetingID == "0") {
        this.isSave=false;
        this.headerService.setTitle("New Gate Meeting");
        this. GetGeoLocation();
      }
      else {
        this.headerService.setTitle("Meeting Details");
        this. GetGeoLocation();
        this.loadData();
      }
    });
  }
  loadData() {
    debugger
    if (this.MeetingID != "0") {
      this.ds.getMeetingDetails(this.UserID, this.MeetingID, this.token, this.device, this.deviceType, this.FormName).subscribe(res => {
        this.MeetingDtls = res;
        console.log("MeetingDtls",this.MeetingDtls)
        this.MeetingDtls.forEach((element: any) => {
          if(element.photoPath != '') {
            this.imagesDownload(element.photoPath, element.meetingID)
          }
        });
      });
    }
  }

  checkin()
  {
        this.ds.setStartMeetingDetails(this.UserID, this.lat.toString(),this.lng.toString(), this.token, this.device,this.deviceType,this.FormName).subscribe(res => {
          this.MeetingDtls = res;
          this.NewMeetingID = res[0].meetingID;
       });
  }
  changeTypeOfMeeting(reasonName: any) {
    this.TypeOfMeetingVal = reasonName.target.value;
  }
 fileProgress(fileInput: any) {
    this.imgURL = <File>fileInput.target.files[0];
    localStorage.setItem("gateMeetingSelectedFile", JSON.stringify(this.imgURL.name))
    if (fileInput.length === 0) {
      return;
    }
    let fileToUpload = <File>fileInput.target.files[0];
    if (fileToUpload.name.toLowerCase().lastIndexOf('.jpeg') == -1 && fileToUpload.name.toLowerCase().lastIndexOf('.jpg') == -1) {

      this.notifyMeService.showError('Image Should Be In JEPG Format', 'Mera APP');
    }

    this.imgURL = fileToUpload;
    const formData = new FormData();
     formData.append('file', fileToUpload, this.NewMeetingID.toString() + fileToUpload.name.substring(fileToUpload.name.indexOf('.')));
    this.PhotoPath = this.NewMeetingID.toString() + fileToUpload.name.substring(fileToUpload.name.indexOf('.'));
    // this.http.post(this.ds.url + 'MeraApp2/UploadGetmeetingPhoto/', formData, { reportProgress: true, observe: 'events' })
    //   .subscribe((event: any) => {
    //     if (event.type === HttpEventType.UploadProgress)
    //       this.progress = Math.round(100 * event.loaded / event.total);
    //     else if (event.type === HttpEventType.Response) {
    //       this.notifyMeService.showSuccess('Image Upload successfuly', 'Mera APP');
    //       this.onUploadFinished.emit(event.body);
    //       this.preview();
    //     }
    //   });
debugger
    formData.append('file', fileToUpload, this.imgURL.name);
    this.http.post(this.mds.url + 'MeraNet/UploadFileToS3Bucket', formData, { reportProgress: true, observe: 'events', headers: this.httpOptions, responseType: 'blob' as 'json' }).subscribe(res => {
      console.log("ATOBUploadFile", res)
      // this.onUploadFinished.emit(event.body);
          this.preview();
    });

  }




  preview() {
    // Show preview
    var mimeType = this.imgURL.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(this.imgURL);
    reader.onload = (_event) => {
      this.image = reader.result;
      this.isValid = true;
    }
  }
  numberOnly(event: { which: any; keyCode: any; }): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  rowClick() {
    this.isSave=true;
    this.GetGeoLocation();
  }

  savedata(){
    const MeetingFormData = this.MeetingForm.value;
    const dataToSend = {
      MeetingID: this.NewMeetingID,
      UserID: this.UserID,
      EndLat: this.lat.toString(),
      EndLong: this.lng.toString(),
      NoOfPCPresent: MeetingFormData.Participants,
      IsTargetProvided: MeetingFormData.IsSharedTarget? '1' : '0',
      IsPhotoTaken: this.isValid? '1' : '0',
      PhotoPath: this.PhotoPath,
      MeetingType: MeetingFormData.TypeOfMeeting,
      MeetingNote: MeetingFormData.MeetingNote
    };

        this.ds.setFinishMeetingDetails(dataToSend, this.token, this.device,this.deviceType,this.FormName).subscribe(res => {
          this.MeetingDtls = res;
          // this.NewMeetingID = res[0].meetingID;
         this.notifyMeService.showSuccess('Records Save Sucessfully', 'Mera APP');
this.clickOnGateMeeting();
        });

  }
 clickOnGateMeeting(){
    this.router.navigate(['/home/GateMeetinglist']);
  }
  GetGeoLocation() {
    if (navigator) {
      navigator.geolocation.getCurrentPosition(pos => {
        this.lat = pos.coords.latitude;
        this.lng = pos.coords.longitude;

        if(this.isSave)
        {
          this.savedata();
        }
        else
        {
            this.checkin();
        }

      }, this.handleLocationError, { timeout: 30000 });
    }
    else {
      this.notifyMeService.showError('Location Could not be captured', 'Mera APP');
      if(this.isSave)
      {
        this.savedata();
      }
      else
      {
          this.checkin();
      }
    }
  }

  handleLocationError(error: { code: any; }) {
        if(this.isSave)
        {
          this.savedata();
        }
        else
        {
            this.checkin();
        }
    switch (error.code) {
      case 3:
        this.notifyMeService.showError('Timeout in Position capture', 'Mera APP');
        break;
      case 2:
        this.notifyMeService.showError('Device is not capable of Position capture', 'Mera APP');
        break;
      case 1:
        // this.notifyMeService.showError('You denied for Position capture', 'Mera APP');
        break;
    }
  }

  imagesDownload(filename:any, meetingID: any) {
    // const dataToSend = {
    //   filename: filename,
    // };
    // this.ds.gateMeetingDownload(dataToSend, this.token, this.device, this.deviceType, this.FormName).subscribe((res)=>{
    //   this.createImageFromBlob(res.body,meetingID)
    // }, (err)=>{
    // });
    debugger
    let extension=".jpg"
      this.imgURL = filename;
    this.ds.getDownloadFileFromS3Bucket("GateMeeting", this.imgURL).subscribe((res) => {
      this.createImageFromBlob(res.body,meetingID)
    }, (error) => { console.log("error", error) });


  }

  createImageFromBlob(image: Blob,meetingID:any) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      this.photo= reader.result;
      this.MeetingDtls.forEach((item: any) => {
        if(item.meetingID == meetingID) {
          item["photo"] = this.photo
        }
      });
    }, false);
     if (image) {
       reader.readAsDataURL(image);
     }
  }
  show(imagepath:any)
  {

    debugger;
    this.selectedimagepath=imagepath;
    this.showModal = true; // Show-Hide Modal Check

  }
  //Bootstrap Modal Close event
  hide()
  {
    this.showModal = false;
  }
}
