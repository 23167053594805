<div>
  <div class="row mt-4">
    <div class="col-12">
      <input class="fullWidth" type="text" name="" id="" placeholder="Search RD Name Or UID"
        [(ngModel)]="searchTerm">
    </div>
  </div>   
  <div class="row mt-3">
    <div class="col-12">
      <div class="row" *ngFor="let item of listOrigin; let i = index;" (click)="RowClick(item)">
        <div class="container-fluid">
            <div class="row mt-1">
              <div class="col-12">
                <span  class="Head_text">{{item.rdname | titlecase}} <span
                  class="ml-1 Head_Val_txt">{{item.rdCode}} </span></span>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12">
                <span class="licNo">{{item.licenseNo}}</span>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-6 text-left">
                <span class="subhead_text">{{item.bcM_Approval=='Pending' && item.asM_Approval=='Pending' ? 'SE' : 
                  item.bcM_Approval=='Pending' && item.asM_Approval!='Pending' ? 'ASM' : 'BCM'}} Approval Status</span>
              </div>
              <div class="col-6 text-right">               
                  <img [src]="item.bcM_Approval=='Pending' && item.asM_Approval=='Pending' ? (item.sE_Approval=='Approved'? 'assets/fssai_approved.svg' :  item.sE_Approval=='Rejected'?'assets/fssai_rejected.svg' : 'assets/fssai_pending.svg'):
                  item.bcM_Approval=='Pending' && item.asM_Approval!='Pending' ? (item.asM_Approval=='Approved'? 'assets/fssai_approved.svg' :  item.asM_Approval=='Rejected'?'assets/fssai_rejected.svg' : 'assets/fssai_pending.svg'):
                  (item.bcM_Approval=='Approved'? 'assets/fssai_approved.svg' :  item.bcM_Approval=='Rejected'?'assets/fssai_rejected.svg' : 'assets/fssai_pending.svg')" /> 
                  <!-- <img *ngIf="item.sE_Approval=='Rejected'" src="assets/fssai_rejected.svg" />
                  <img *ngIf="item.sE_Approval=='Pending'" src="assets/fssai_pending.svg" /> -->
                  <!-- {{item.sE_Approval}} -->
              </div>              
            </div>
            <hr class="hr_cust">
        </div>
    </div>
    </div>
  </div>   
  
</div>

<br><br><br><br>