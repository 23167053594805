import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { DataService } from 'src/app/service/data.service';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-fssai-details-approval',
  templateUrl: './fssai-details-approval.component.html',
  styleUrls: ['./fssai-details-approval.component.css']
})
export class FssaiDetailsApprovalComponent implements OnInit {
  deviceType: any;
  device: any;
  userType: any;
  userId: any
  list: any;
  Status: any='';
  IsValid : boolean=false;
  Remark : any;
  token: any;
  formname:any;
  actiondesc:any;
  FieldName: any;
  FieldValue: any;

  constructor(private activatedRoute: ActivatedRoute,private cs:CommonUtilitiesService, private router: Router, private headerService: HeaderService, private mds: MongoDataService, private notifyMeService:NotifyMeService, private ds: DataService,) { }


  ngOnInit(): void {
    this.headerService.setTitle('FSSAI Details');
    this.headerService.setActiveURl('/fssaiDetailsApproval');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.token = localStorage.getItem("TokenNo");
    this.userType = localStorage.getItem("UserType");
    this.userId = localStorage.getItem("UserID");
    this.FieldName=localStorage.getItem("FieldName");
    this.FieldValue=localStorage.getItem("FieldValue");
    this.list = JSON.parse(localStorage.getItem("FSSAIApproval") || '{}');
    debugger
    if(this.userType.toUpperCase()=='SO' && this.list.sE_Approval == 'Pending')
    {
      this.IsValid=true;
      this.Remark='';
    }
    else if(this.userType.toUpperCase()=='SO')
    {
      if(this.list.sE_Approval != 'Pending' && this.list.asM_Approval == 'Pending')
      {
        this.Remark=this.list.sE_Approval + ' By SE, You can not modify.';
      }
      if(this.list.asM_Approval == 'Approved' && this.list.bcM_Approval != 'Pending')
      {
        this.Remark=this.list.bcM_Approval + ' By BCM, You can not modify.';
      }
      if(this.list.asM_Approval != 'Pending' && this.list.bcM_Approval == 'Pending')
      {
        this.Remark=this.list.asM_Approval + ' By ASM, You can not modify.';
      }
    }
    if(this.userType.toUpperCase()=='ASM' && this.list.sE_Approval == 'Approved' && this.list.asM_Approval == 'Pending')
    {
      this.IsValid=true;
      this.Remark='';
    }
    else if(this.userType.toUpperCase()=='ASM')
    {
      if(this.list.sE_Approval == 'Pending' && this.list.asM_Approval == 'Pending')
      {
        this.Remark='SE Approval is pending, You can not modify.';
      }
      if(this.list.sE_Approval == 'Approved' && this.list.asM_Approval != 'Pending' && this.list.bcM_Approval == 'Pending')
      {
        this.Remark=this.list.asM_Approval + ' By ASM, You can not modify.';
      }
      if(this.list.sE_Approval == 'Approved' && this.list.asM_Approval == 'Approved' && this.list.bcM_Approval != 'Pending')
      {
        this.Remark=this.list.bcM_Approval + ' By BCM, You can not modify.';
      }
    }
    if(this.userType.toUpperCase()=='BCM' && this.list.sE_Approval == 'Approved' && this.list.asM_Approval == 'Approved' && this.list.bcM_Approval == 'Pending')
    {
      this.IsValid=true;
      this.Remark='';
    }

    this.formname="fssaiDetailsApproval";
    this.actiondesc="Fssai Details Approval";
    this.cs.GetGeoLocation(this.userId,this.userType,this.formname,this.actiondesc,this.FieldName,this.FieldValue);
  }



  approve(){
    this.Status='Approved'
    this.saveData();
    this.router.navigate(['/home/fssaiApproval']);
  }

  reject(){
    // this.Status='Rejected'
    //this.saveData();
    // this.router.navigate(['/home/fssaiApproval']);
    this.router.navigate(['/home/facialRecRejReasonApproval'], { queryParams: { selected: 'FSSAI' } });
  }

  saveData(){
    const dataToSend = {
      UserID : this.userId,
      UserType  : this.userType,
      RDCode : this.list.rdCode,
      LicID : this.list.lid,
      LicenseNo :this.list.licenseNo,
      Remark :'',
      Status : this.Status
    };
    console.log(dataToSend);
    this.ds.fSSAIApproval(dataToSend, this.token, this.device, this.deviceType,'FSSAI Approval').subscribe(res => {
      this.notifyMeService.showSuccess('Records Save Sucessfully', 'Sales Genie App');
      localStorage.removeItem("FSSAIApproval");
    });

  }

}
