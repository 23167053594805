import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { DatePipe } from '@angular/common';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import {InputPara} from 'src/app/models/kpi-models';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';
@Component({
  selector: 'app-from-outlet-atob',
  templateUrl: './from-outlet-atob.component.html',
  styleUrls: ['./from-outlet-atob.component.css']
})

export class FromOutletAtobComponent implements OnInit {

  deviceType: any;
  device: any;
  searchText = '';
  isLongPress: boolean = false;
  isSelected: boolean = false;
  list: any;
  userType: any;
  userId: any;
  fieldName:any;
  fieldValue:any;
  input:InputPara=new InputPara();
  token: any;
  formname:any;
  actiondesc:any;
 

  constructor(private activatedRoute: ActivatedRoute,private cs:CommonUtilitiesService, private router: Router, private headerService: HeaderService, private datePipe: DatePipe, private mds: MongoDataService, private notifyMeService:NotifyMeService,) { }

  ngOnInit(): void {
    this.headerService.setTitle('From Outlet');
    this.headerService.setActiveURl('/fromOutletAtob');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.userId = localStorage.getItem("UserID");
    this.userType = localStorage.getItem("UserType");
    this.fieldName=localStorage.getItem("OriginalFieldName");
    this.fieldValue=localStorage.getItem("OriginalFieldValue");
    this.token = localStorage.getItem("TokenNo");
    this.formname="fromOutletAtob";
    this.actiondesc="From Outlet Atob";
    this.cs.GetGeoLocation(this.userId,this.userType,this.formname,this.actiondesc,this.fieldName,this.fieldValue);
  }
  onLongPress() {
    this.isLongPress = true;
  }
  clickOnSelect() {
    this.isSelected = true;
  }
  rowClick(selected: any) {
    localStorage.setItem("outletSelectedA", JSON.stringify(selected));
    this.router.navigate(['/home/outletDetailAtob']);
  }
  searchOutlet(OutletUID: string, Status: string) {
    debugger
    let ForWhom = "ATOB";
    this.input.FieldName=this.fieldName;
    this.input.FieldValue=this.fieldValue;
    this.input.TagName=OutletUID;
    this.input.Datatype= ForWhom;
    this.input.UserID=this.userId;
    this.mds.getOutletDetailByRDWise(this.input, this.token, this.device, this.deviceType,'From Outlet A To B').subscribe(res => {
      console.log("getOutletDetailByRDWise", res);
      this.list = res;
      if(this.list.length == 0){
        this.notifyMeService.showWarning('Outlet Details not found for this Outlet Code', 'Sales Genie App');
      }
    },(error:any)=>{
      debugger
    });
  }

}
