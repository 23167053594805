import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { DataService } from 'src/app/service/data.service';
import { InputPara, Resultdata } from 'src/app/models/kpi-models';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterComponent } from '../filter/filter.component';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';
import { NotifyMeService } from 'src/app/service/notify-me.service';

@Component({
  selector: 'app-unbilled-outlet',
  templateUrl: './unbilled-outlet.component.html',
  styleUrls: ['./unbilled-outlet.component.css']
})
export class UnbilledOutletComponent implements OnInit {
  deviceType: any;
  device: any;
  FieldName: any;
  FieldValue: any;
  DataType: any;
  ParaCode: any;
  UserID: any;
  UnbilledoutletDetails: any;
  data: any;

  periodList: any[] = [];
  selectedType: any;
  UserType: any;
  resultdata: Resultdata = new Resultdata();
  selectedperiod: any;
  input: InputPara = new InputPara();
  token: any;
  lat:any;
  lng:any;

  constructor(private activatedRoute: ActivatedRoute, private cs: CommonUtilitiesService, private notifyMeService: NotifyMeService,
    private fb: FormBuilder, private router: Router, private headerService: HeaderService, private ds: DataService, private mds: MongoDataService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.headerService.setActiveURl('/UnbilledOutlet');
    this.headerService.setTitle("Unbilled Outlet");
    this.UserType = localStorage.getItem("UserType");
    this.UserID = localStorage.getItem("UserID");
    this.token = localStorage.getItem("TokenNo");
    this.DataType = "0";
    this.ParaCode = "0";
    this.UnbilledoutletDetails = [];

    this.activatedRoute.paramMap.forEach(params => {
      this.FieldValue = params.get('FieldValue');
      this.FieldName = params.get('FieldName');
      this.UserID = params.get('UserID');
      this.selectedperiod = params.get('selectedperiod');
      this.GetGeoLocation();
      this.loadData();
    });
  }


  GetGeoLocation() {
    debugger
    if (navigator) {
      navigator.geolocation.getCurrentPosition(pos => {
        this.lat = pos.coords.latitude;
        console.log("  this.lat", this.lat)
        this.lng = pos.coords.longitude;
        this.SetapplicationLogData();
      }, this.handleLocationError, { timeout: 30000 });
    }
    else {
      this.notifyMeService.showError('Location Could not be captured', 'Mera APP');
    }
  }

  handleLocationError(error: { code: any; }) {
    switch (error.code) {
      case 3:
        this.notifyMeService.showError('Timeout in Position capture', 'Mera APP V2');
        break;
      case 2:
        this.notifyMeService.showError('Device is not capable of Position capture', 'Mera APP V2');
        break;
      case 1:
        this.notifyMeService.showError('You denied for Position capture', 'Mera APP V2');
        break;
    }
  }

  SetapplicationLogData() {
    debugger
    let request = {
      "userid": this.UserID,
      "usertype": this.UserType,
      "formname": "UnbilledOutlet",
      "actiondesc": "Unbilled Outlet" + " " + this.FieldName,
      "fieldname": this.FieldName,
      "fieldvalue": this.FieldValue,
      "latitude": this.lat,
      "longitude": this.lng
    }
    this.ds.SetapplicationLogData(request).subscribe(res => {
      console.log("res", res)
    })
  }

  loadData(): void {
    this.input.FieldName = this.FieldName;
    this.input.FieldValue = this.FieldValue;
    this.input.TagName = this.selectedperiod;
    this.input.Datatype = this.DataType;
    this.input.UserID = this.UserID;
    this.mds.getUnbilledoutletDetails(this.input, this.token, this.device, this.deviceType, 'Unbilled Outlet').subscribe(res => {
      this.UnbilledoutletDetails = res;
    });

  }
  TypeChange(e: any): void {
    this.selectedperiod = e.source._value;
    this.loadData();
  }

  RowClick(e: any): void {
    localStorage.setItem("DataType", e);
    localStorage.setItem("selectedType", this.selectedType);
    this.router.navigate(['/home/UnbilledOutletdata/', this.FieldName, this.FieldValue, localStorage.getItem("DataType"), this.UserID, this.selectedperiod]);
  }
  openFilter() {
    if (this.dialog.openDialogs.length == 0) {
      const dialogRef = this.dialog.open(FilterComponent, {
        closeOnNavigation: true,
        data: {
          "FieldName": localStorage.getItem("OriginalFieldName"),
          "FieldValue": localStorage.getItem("OriginalFieldValue"),
          "UserID": this.UserID,
          "MenuUpTo": "4",
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.FieldName = result.FieldName;
          this.FieldValue = result.FieldValue;
          this.ParaCode = "1";
          this.loadData();
        }
      });
    }
  }
}
