import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { DatePipe } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { TreeviewModule } from 'ngx-treeview';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HeaderService } from './service/header.service';
import { MaterialModule } from './material-module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { CountoModule } from 'angular2-counto';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ToastrModule, ToastNoAnimation, ToastNoAnimationModule } from 'ngx-toastr';
import { CountUpModule } from 'countup.js-angular2';

import { NgHttpLoaderModule } from 'ng-http-loader';
import { LongPress } from './long-press';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { MeraAppHomeComponent } from './components/mera-app-home/mera-app-home.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { TargetAchComponent } from './components/target-ach/target-ach.component';
import { CategoryPerformanceComponent } from './components/category-performance/category-performance.component';
import { FilterComponent } from './components/filter/filter.component';
import { SecondaryTrendComponent } from './components/secondary-trend/secondary-trend.component';

import { ValuePerformanceComponent } from './components/value-performance/value-performance.component';
import { CommonPerformanceComponent } from './components/common-performance/common-performance.component';
import { ValuePerformenceDataComponent } from './components/value-performence-data/value-performence-data.component';
import { PcEarningsComponent } from './components/pc-earnings/pc-earnings.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { FaqlistComponent } from './components/faqlist/faqlist.component';

import { PcdetailsMainComponent } from './components/pcdetails-main/pcdetails-main.component';
import { PclocationDetailsComponent } from './components/pclocation-details/pclocation-details.component';
import { AgmCoreModule } from '@agm/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { UnbilledOutletComponent } from './components/unbilled-outlet/unbilled-outlet.component';
import { CommonPerformanceDataComponent } from './components/common-performance-data/common-performance-data.component';
import { ProfileComponent } from './components/profile/profile.component';
import { EffectiveCoverageComponent } from './components/effective-coverage/effective-coverage.component';
import { EffectiveCoverageDataComponent } from './components/effective-coverage-data/effective-coverage-data.component';
import { VpoComponent } from './components/vpo/vpo.component';
import { VpoDataComponent } from './components/vpo-data/vpo-data.component';
import { TodayorderBookingComponent } from './components/todayorder-booking/todayorder-booking.component';
import { NotificationDetailsComponent } from './components/notification-details/notification-details.component';
import { TsidetailsMainComponent } from './components/tsidetails-main/tsidetails-main.component';
import { TsilocationDetailsComponent } from './components/tsilocation-details/tsilocation-details.component';
import { UlbComponent } from './components/ulb/ulb.component';
import { UlbDataComponent } from './components/ulb-data/ulb-data.component';
import { RdPerformanceComponent } from './components/rd-performance/rd-performance.component';
import { NotoficationlistComponent } from './components/notoficationlist/notoficationlist.component';
import { ApprovalsComponent } from './components/approvals/approvals.component';
import { OifApprovalComponent } from './components/oif-approval/oif-approval.component';
import { PullOutApprovalComponent } from './components/pull-out-approval/pull-out-approval.component';
import { A2BComponent } from './components/a2-b/a2-b.component';
import { A2BDataComponent } from './components/a2-bdata/a2-bdata.component';
import { TransactionsComponent } from './components/transactions/transactions.component';
import { PulloutTransactionsComponent } from './components/pullout-transactions/pullout-transactions.component';
import { A2bTransactionsComponent } from './components/a2b-transactions/a2b-transactions.component';
import { FilterPipe } from './search-pipe/filter.pipe'
import { SplashScreenComponent } from './splash-screen/splash-screen.component';
import { FromOutletAtobComponent } from './components/from-outlet-atob/from-outlet-atob.component';
import { OutletDetailAtobComponent } from './components/outlet-detail-atob/outlet-detail-atob.component';
import { ToOutletAtobComponent } from './components/to-outlet-atob/to-outlet-atob.component';
import { OutletDetailOneAtobComponent } from './components/outlet-detail-one-atob/outlet-detail-one-atob.component';
import { OutletDetailTwoAtobComponent } from './components/outlet-detail-two-atob/outlet-detail-two-atob.component';
import { AtobTransferPreviewComponent } from './components/atob-transfer-preview/atob-transfer-preview.component';
import { SearchOutletPulloutreqComponent } from './components/search-outlet-pulloutreq/search-outlet-pulloutreq.component';
import { OutletDetailPulloutreqComponent } from './components/outlet-detail-pulloutreq/outlet-detail-pulloutreq.component';
import { PullOutReasonPulloutreqComponent } from './components/pull-out-reason-pulloutreq/pull-out-reason-pulloutreq.component';
import { ConfirmDailogComponent } from './components/confirm-dailog/confirm-dailog.component';
import { OutletDetailOIFComponent } from './components/outlet-detail-oif/outlet-detail-oif.component';
import { NewRLAComponent } from './components/new-rla/new-rla.component';
import { NewRLADataComponent } from './components/new-rladata/new-rladata.component';
import { RdListComponent } from './components/rd-list/rd-list.component';
import { PcAttendanceComponent } from './components/pc-attendance/pc-attendance.component';
import { TsiAttendanceComponent } from './components/tsi-attendance/tsi-attendance.component';
import { LtmComponent } from './components/ltm/ltm.component';
import { GateMeetingComponent } from './components/gate-meeting/gate-meeting.component';
import { GateMeetingdataComponent } from './components/gate-meetingdata/gate-meetingdata.component';
import { NpdComponent } from './components/npd/npd.component';
import { NpdDataComponent } from './components/npd-data/npd-data.component';
import { PcfrRdlistComponent } from './components/pcfr-rdlist/pcfr-rdlist.component';
import { FrPClistComponent } from './components/fr-pclist/fr-pclist.component';
import { SeEarningComponent } from './components/se-earning/se-earning.component';
import { SeearningDetailComponent } from './components/seearning-detail/seearning-detail.component';
import { PraiseComponent } from './components/praise/praise.component';
import { PcTSIComponent } from './components/pc-tsi/pc-tsi.component';
import { MissingVisicoolerComponent } from './components/missing-visicooler/missing-visicooler.component';
import { MissingVisicoolerOutletDetailComponent } from './components/missing-visicooler-outlet-detail/missing-visicooler-outlet-detail.component';
import { MissingReasonComponent } from './components/missing-reason/missing-reason.component';
import { ScanQRCodeComponent } from './components/scan-qrcode/scan-qrcode.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { ComplaintsComponent } from './components/complaints/complaints.component';
import { ComplaintsListComponent } from './components/complaints-list/complaints-list.component';
import { AddComplaintComponent } from './components/add-complaint/add-complaint.component';
import { SearchOutletComplaintComponent } from './components/search-outlet-complaint/search-outlet-complaint.component';
import { OutletdetailComplaintComponent } from './components/outletdetail-complaint/outletdetail-complaint.component';
import { PraiseDetailsComponent } from './components/praise-details/praise-details.component';
import { UnbilledOutletdataComponent } from './components/unbilled-outletdata/unbilled-outletdata.component';
import { GreenOutletComponent } from './components/green-outlet/green-outlet.component';
import { UrjaaVPComponent } from './components/urjaa-vp/urjaa-vp.component';
import { FaqComponent } from './components/faq/faq.component';
import { PcTSIDetailsComponent } from './components/pc-tsidetails/pc-tsidetails.component';
import { FacialrecognitionComponent } from './components/facialrecognition/facialrecognition.component';
import { FacialrecdetailsComponent } from './components/facialrecdetails/facialrecdetails.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NonMeranetOutletdetailComponent } from './components/non-meranet-outletdetail/non-meranet-outletdetail.component';
import { FacialRecognitionApprovalComponent } from './components/facial-recognition-approval/facial-recognition-approval.component';
import { FacialRecDetailsApprovalComponent } from './components/facial-rec-details-approval/facial-rec-details-approval.component';
import { FacialRecRejReasonApprovalComponent } from './components/facial-rec-rej-reason-approval/facial-rec-rej-reason-approval.component';
import { AnimatedDigitComponent } from './components/animated-digit/animated-digit.component';
import { StockInoutApprovalComponent } from './components/stock-inout-approval/stock-inout-approval.component';
import { StockInoutDetailsApprovalComponent } from './components/stock-inout-details-approval/stock-inout-details-approval.component';
import { StockInoutSelectrdApprovalComponent } from './components/stock-inout-selectrd-approval/stock-inout-selectrd-approval.component';
import { RlaAutoClosureApprovalComponent } from './components/rla-auto-closure-approval/rla-auto-closure-approval.component';
import { RlaAutoClosureDetailsApprovalComponent } from './components/rla-auto-closure-details-approval/rla-auto-closure-details-approval.component';
import { RlaAutoClosureSelectReasonApprovalComponent } from './components/rla-auto-closure-select-reason-approval/rla-auto-closure-select-reason-approval.component';
import { GateMeetinglistComponent } from './components/gate-meetinglist/gate-meetinglist.component';
import { GateMeetingnewComponent } from './components/gate-meetingnew/gate-meetingnew.component';
import { ClaimApprovalComponent } from './components/claim-approval/claim-approval.component';
import { ClaimDetailsApprovalComponent } from './components/claim-details-approval/claim-details-approval.component';
import { ClaimDetailsEditApprovalComponent } from './components/claim-details-edit-approval/claim-details-edit-approval.component';
import { PccrRdlistComponent } from './components/pccr-rdlist/pccr-rdlist.component';
import { FssaiApprovalComponent } from './components/fssai-approval/fssai-approval.component';
import { FssaiDetailsApprovalComponent } from './components/fssai-details-approval/fssai-details-approval.component';
import { ErodsApprovalComponent } from './components/erods-approval/erods-approval.component';
import { ErodsVouchersListApprovalComponent } from './components/erods-vouchers-list-approval/erods-vouchers-list-approval.component';
import { ErodsVoucherApprovalComponent } from './components/erods-voucher-approval/erods-voucher-approval.component';
import { ErodsVoucherDetailsApprovalComponent } from './components/erods-voucher-details-approval/erods-voucher-details-approval.component';
import { ErodsSelectReasonApprovalComponent } from './components/erods-select-reason-approval/erods-select-reason-approval.component';
import { PccreationSettingComponent } from './components/pccreation-setting/pccreation-setting.component';
import { PccreationEditComponent } from './components/pccreation-edit/pccreation-edit.component';
import { VillageProductivityComponent } from './components/village-productivity/village-productivity.component';
import { UrjaaVCScanComponent } from './components/urjaa-vcscan/urjaa-vcscan.component';
import { UrjaaEffectiveCoverageDataComponent } from './components/urjaa-effective-coverage-data/urjaa-effective-coverage-data.component';
import { DmsDataComponent } from './components/dms-data/dms-data.component';
import { UrjaaUlbComponent } from './components/urjaa-ulb/urjaa-ulb.component';
import { PcDeviceTaggingComponent } from './components/pc-device-tagging/pc-device-tagging.component';
import { PcDeviceTaggingSelectPcComponent } from './components/pc-device-tagging-select-pc/pc-device-tagging-select-pc.component';
import { PcDeviceTaggingDeviceIdComponent } from './components/pc-device-tagging-device-id/pc-device-tagging-device-id.component';
import { StockInoutVoucherDetailsApprovalComponent } from './components/stock-inout-voucher-details-approval/stock-inout-voucher-details-approval.component';
import { HealthstatusComponent } from './components/healthstatus/healthstatus.component';
import { HealthstatuslistComponent } from './components/healthstatuslist/healthstatuslist.component';
import { HealthstatusRptComponent } from './components/healthstatus-rpt/healthstatus-rpt.component';
import { ClaimDetailsTwoApprovalComponent } from './components/claim-details-two-approval/claim-details-two-approval.component';
import { ColdChainAuditComponent } from './components/cold-chain-audit/cold-chain-audit.component';
import { ColdChainAuditDetailsComponent } from './components/cold-chain-audit-details/cold-chain-audit-details.component';
import { PrimaryInvoicesComponent } from './components/primary-invoices/primary-invoices.component';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { MediaComponent } from './components/media/media.component';
import { KpiProductivityComponent } from './components/kpi-productivity/kpi-productivity.component';
import { KpiProductivityDataComponent } from './components/kpi-productivity-data/kpi-productivity-data.component';
import { KpiProductivityDaywiseComponent } from './components/kpi-productivity-daywise/kpi-productivity-daywise.component';
import { SubRdApprovalComponent } from './components/sub-rd-approval/sub-rd-approval.component';
import { SubRdApprovalDetailsComponent } from './components/sub-rd-approval-details/sub-rd-approval-details.component';
import { SubRdApprovalDetailsEditComponent } from './components/sub-rd-approval-details-edit/sub-rd-approval-details-edit.component';
import { SubRdApprovalSelectMarketComponent } from './components/sub-rd-approval-select-market/sub-rd-approval-select-market.component';
import { SubRdApprovalSelectRdTypeComponent } from './components/sub-rd-approval-select-rd-type/sub-rd-approval-select-rd-type.component';
import { SubRdApprovalSelectOldRdComponent } from './components/sub-rd-approval-select-old-rd/sub-rd-approval-select-old-rd.component';
import { CertificateComponent } from './components/certificate/certificate.component';
import { MScoreComponent } from './components/mscore/mscore.component';
import { MScoreDataComponent } from './components/mscore-data/mscore-data.component';
import { ScorePcDataComponent } from './components/score-pc-data/score-pc-data.component';
import { MscorePcDataComponent } from './components/mscore-pc-data/mscore-pc-data.component';
import { NgxImageCompressService } from 'ngx-image-compress';
import { AssetApprovalComponent } from './components/asset-approval/asset-approval.component';
import { OtherApplicationsComponent } from './components/other-applications/other-applications.component';
import { VcComponent } from './components/vc/vc.component';
import { PdfComponent } from './components/pdf/pdf.component';
import { MTAFHApprovalComponent } from './components/mtafhapproval/mtafhapproval.component';
import { MTAFHApprovaldetailsComponent } from './components/mtafhapprovaldetails/mtafhapprovaldetails.component';
import { A2bTransferComponent } from './components/a2b-transfer/a2b-transfer.component';
import { A2bVCComponent } from './components/a2b-vc/a2b-vc.component';
import { A2bpdfComponent } from './components/a2bpdf/a2bpdf.component';
import { QrCodeComponent } from './components/qr-code/qr-code.component';
import { NgxScannerQrcodeModule } from 'ngx-scanner-qrcode';
import { SurveyApprovalComponent } from './components/survey-approval/survey-approval.component';
import { SurveyApprovalDetailsComponent } from './components/survey-approval-details/survey-approval-details.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { AddEligibleOutletComponent } from './components/add-eligible-outlet/add-eligible-outlet.component';
import { CheckPhotoOfOutletAndAgreementComponent } from './components/check-photo-of-outlet-and-agreement/check-photo-of-outlet-and-agreement.component';
import { OutletDetailsComponent } from './components/outlet-details/outlet-details.component';
import { QRCodeHistoryComponent } from './components/qrcode-history/qrcode-history.component';
import { QrCodeOutletDetailsComponent } from './components/qr-code-outlet-details/qr-code-outlet-details.component';
import { EligibleOutletSerachComponent } from './components/eligible-outlet-serach/eligible-outlet-serach.component';
import { GaugesModule } from '@progress/kendo-angular-gauges';
import { MarketWorkingComponent } from './components/market-working/market-working.component';
import { MarketWorkingRdListDataComponent } from './components/market-working-rd-list-data/market-working-rd-list-data.component';
import { MarketWorkingPcListComponent } from './components/market-working-pc-list/market-working-pc-list.component';
import { MarketWorkingPcListDataComponent } from './components/market-working-pc-list-data/market-working-pc-list-data.component';
import { MarketWorkingBeatListComponent } from './components/market-working-beat-list/market-working-beat-list.component';
import { MarketWorkingBeatListDataComponent } from './components/market-working-beat-list-data/market-working-beat-list-data.component';
import { MarketWorkingOutletListComponent } from './components/market-working-outlet-list/market-working-outlet-list.component';
import { MarketWorkingOutletListDataComponent } from './components/market-working-outlet-list-data/market-working-outlet-list-data.component';
import { MarketWorkingOutletLocationDetailsComponent } from './components/market-working-outlet-location-details/market-working-outlet-location-details.component';
import { OutletNameChangeRequestComponent } from './components/outlet-name-change-request/outlet-name-change-request.component';
import { OutletNameChangeRequestSendOtpComponent } from './components/outlet-name-change-request-send-otp/outlet-name-change-request-send-otp.component';
import { OutletNameChangeRequestVerifyOtpComponent } from './components/outlet-name-change-request-verify-otp/outlet-name-change-request-verify-otp.component';
import { OutletNameChangedVerifiedOtpConfirmationComponent } from './components/outlet-name-changed-verified-otp-confirmation/outlet-name-changed-verified-otp-confirmation.component';
import { OutletNameChangeApprovalListComponent } from './components/outlet-name-change-approval-list/outlet-name-change-approval-list.component';
import { OutletNameChangeApprovalListDetailsComponent } from './components/outlet-name-change-approval-list-details/outlet-name-change-approval-list-details.component';
import { OutletNameChangeComponent } from './components/outlet-name-change/outlet-name-change.component';
import { TicketLogListComponent } from './components/ticket-log-list/ticket-log-list.component';
import { NewTicketLogComponent } from './components/new-ticket-log/new-ticket-log.component';
import { UpdateTicketDetailsComponent } from './components/update-ticket-details/update-ticket-details.component';
import { SelectMultiselectDropdownComponent } from './components/select-multiselect-dropdown/select-multiselect-dropdown.component';
import { TicketDetailsFeedbackComponent } from './components/ticket-details-feedback/ticket-details-feedback.component';
import { ScanQrCodeInTransactionComponent } from './components/scan-qr-code-in-transaction/scan-qr-code-in-transaction.component';
import { ScanQrCodeDataInTransactionComponent } from './components/scan-qr-code-data-in-transaction/scan-qr-code-data-in-transaction.component';
import { ScanQrCodeMatchInTransactionComponent } from './components/scan-qr-code-match-in-transaction/scan-qr-code-match-in-transaction.component';
import { TodaysVisitsComponent } from './components/todays-visits/todays-visits.component';
import { TodaysVisitsOutletListDetailsComponent } from './components/todays-visits-outlet-list-details/todays-visits-outlet-list-details.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MeraAppHomeComponent,
    HeaderComponent,
    FooterComponent,
    DashboardComponent,
    TargetAchComponent,
    CategoryPerformanceComponent,
    FilterComponent,
    SecondaryTrendComponent,
    LongPress,
    ValuePerformanceComponent,
    CommonPerformanceComponent,
    ValuePerformenceDataComponent,
    PcEarningsComponent,
    NotificationsComponent,
    FaqlistComponent,
    UnbilledOutletComponent,
    CommonPerformanceDataComponent,
    ProfileComponent,
    PcdetailsMainComponent,
    PclocationDetailsComponent,

    EffectiveCoverageComponent,
    EffectiveCoverageDataComponent,
    VpoComponent,
    VpoDataComponent,
    TodayorderBookingComponent,
    NotificationDetailsComponent,
    TsidetailsMainComponent,
    TsilocationDetailsComponent,
    UlbComponent,
    UlbDataComponent,
    RdPerformanceComponent,
    ApprovalsComponent,
    OifApprovalComponent,
    PullOutApprovalComponent,
    NotoficationlistComponent,
    ApprovalsComponent,
    A2BComponent,
    A2BDataComponent,
    TransactionsComponent,
    PulloutTransactionsComponent,
    A2bTransactionsComponent,
    FilterPipe,
    SplashScreenComponent,
    FromOutletAtobComponent,
    OutletDetailAtobComponent,
    ToOutletAtobComponent,
    OutletDetailOneAtobComponent,
    OutletDetailTwoAtobComponent,
    AtobTransferPreviewComponent,
    SearchOutletPulloutreqComponent,
    OutletDetailPulloutreqComponent,
    PullOutReasonPulloutreqComponent,
    ConfirmDailogComponent,
    OutletDetailOIFComponent,
    NewRLAComponent,
    NewRLADataComponent,
    RdListComponent,
    PcAttendanceComponent,
    TsiAttendanceComponent,
    LtmComponent,
    GateMeetingComponent,
    GateMeetingdataComponent,
    NpdComponent,
    NpdDataComponent,
    PcfrRdlistComponent,
    FrPClistComponent,
    SeEarningComponent,
    SeearningDetailComponent,
    PraiseComponent,
    PcTSIComponent,
    MissingVisicoolerComponent,
    MissingVisicoolerOutletDetailComponent,
    MissingReasonComponent,
    ScanQRCodeComponent,
    ComplaintsComponent,
    ComplaintsListComponent,
    AddComplaintComponent,
    SearchOutletComplaintComponent,
    OutletdetailComplaintComponent,
    PraiseDetailsComponent,
    UnbilledOutletdataComponent,
    GreenOutletComponent,
    UrjaaVPComponent,
    FaqComponent,
    PcTSIDetailsComponent,
    FacialrecognitionComponent,
    FacialrecdetailsComponent,
    NonMeranetOutletdetailComponent,
    AnimatedDigitComponent,
    GateMeetinglistComponent,
    GateMeetingnewComponent,
    FacialRecognitionApprovalComponent,
    FacialRecDetailsApprovalComponent,
    FacialRecRejReasonApprovalComponent,
    AnimatedDigitComponent,
    StockInoutApprovalComponent,
    StockInoutDetailsApprovalComponent,
    StockInoutSelectrdApprovalComponent,
    RlaAutoClosureApprovalComponent,
    RlaAutoClosureDetailsApprovalComponent,
    RlaAutoClosureSelectReasonApprovalComponent,
    ClaimApprovalComponent,
    ClaimDetailsApprovalComponent,
    ClaimDetailsEditApprovalComponent,
    PccrRdlistComponent,
    FssaiApprovalComponent,
    FssaiDetailsApprovalComponent,
    ErodsApprovalComponent,
    ErodsVouchersListApprovalComponent,
    ErodsVoucherApprovalComponent,
    ErodsVoucherDetailsApprovalComponent,
    ErodsSelectReasonApprovalComponent,
    PccreationSettingComponent,
    PccreationEditComponent,
    VillageProductivityComponent,
    UrjaaVCScanComponent,
    UrjaaEffectiveCoverageDataComponent,
    DmsDataComponent,
    UrjaaUlbComponent,
    PcDeviceTaggingComponent,
    PcDeviceTaggingSelectPcComponent,
    PcDeviceTaggingDeviceIdComponent,
    StockInoutVoucherDetailsApprovalComponent,
    HealthstatusComponent,
    HealthstatuslistComponent,
    HealthstatusRptComponent,
    ClaimDetailsTwoApprovalComponent,
    ColdChainAuditComponent,
    ColdChainAuditDetailsComponent,
    PrimaryInvoicesComponent,
    FeedbackComponent,
    MediaComponent,
    KpiProductivityComponent,
    KpiProductivityDataComponent,
    KpiProductivityDaywiseComponent,
    SubRdApprovalComponent,
    SubRdApprovalDetailsComponent,
    SubRdApprovalDetailsEditComponent,
    SubRdApprovalSelectMarketComponent,
    SubRdApprovalSelectRdTypeComponent,
    SubRdApprovalSelectOldRdComponent,
    CertificateComponent,
    MScoreComponent,
    MScoreDataComponent,
    ScorePcDataComponent,
    MscorePcDataComponent,
    AssetApprovalComponent,
    OtherApplicationsComponent,
    VcComponent,
    PdfComponent,
    MTAFHApprovalComponent,
    MTAFHApprovaldetailsComponent,
    A2bTransferComponent,
    A2bVCComponent,
    A2bpdfComponent,
    QrCodeComponent,
    SurveyApprovalComponent,
    SurveyApprovalDetailsComponent,
    AddEligibleOutletComponent,
    CheckPhotoOfOutletAndAgreementComponent,
    OutletDetailsComponent,
    QRCodeHistoryComponent,
    QrCodeOutletDetailsComponent,
    EligibleOutletSerachComponent,
    MarketWorkingComponent,
    MarketWorkingRdListDataComponent,
    MarketWorkingPcListComponent,
    MarketWorkingPcListDataComponent,
    MarketWorkingBeatListComponent,
    MarketWorkingBeatListDataComponent,
    MarketWorkingOutletListComponent,
    MarketWorkingOutletListDataComponent,
    MarketWorkingOutletLocationDetailsComponent,
    OutletNameChangeRequestComponent,
    OutletNameChangeRequestSendOtpComponent,
    OutletNameChangeRequestVerifyOtpComponent,
    OutletNameChangedVerifiedOtpConfirmationComponent,
    OutletNameChangeApprovalListComponent,
    OutletNameChangeApprovalListDetailsComponent,
    OutletNameChangeComponent,
    TicketLogListComponent,
    NewTicketLogComponent,
    UpdateTicketDetailsComponent,
    SelectMultiselectDropdownComponent,
    TicketDetailsFeedbackComponent,
    ScanQrCodeInTransactionComponent,
    ScanQrCodeDataInTransactionComponent,
    ScanQrCodeMatchInTransactionComponent,
    TodaysVisitsComponent,
    TodaysVisitsOutletListDetailsComponent,
  ],
  imports: [NgxScannerQrcodeModule,
    Ng2SearchPipeModule,
    CountUpModule,
    BrowserModule, ToastrModule.forRoot(),
    AppRoutingModule, CountoModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MaterialModule,
    NgbModule, SlickCarouselModule,
    ZXingScannerModule,
    FontAwesomeModule, AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCB8eZx_oJJ4lrsSn9BUJHb_4dOYZWMKHM',
      libraries: ["places"]

    })
    , FormsModule, ReactiveFormsModule,
    HttpClientModule, NgbModule,
    ToastNoAnimationModule.forRoot(),
    ToastrModule.forRoot({ timeOut: 10000, positionClass: 'toast-top-right' }),
    MaterialModule, NgHttpLoaderModule.forRoot(),
    FontAwesomeModule, TreeviewModule.forRoot(), ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production, registrationStrategy: 'registerImmediately' }),
    AngularFireModule.initializeApp({ /* See project settings in Firebase Console for this information */
      // apiKey: "AIzaSyDuHP1FLDNbBtFsQ8x8btnc7rCcNPUEay8",
      // projectId: "devmeraapp",
      // storageBucket: "devmeraapp.appspot.com",
      // messagingSenderId: "406995684806",
      // appId: "1:406995684806:web:eef666decc86f3d486b6c7"
      // apiKey: "AIzaSyD0zGN7CPTCMJfVGZGmMwolaPmGq13jPF8",
      // projectId: "salesmitra-5198f",
      // storageBucket: "salesmitra-5198f.appspot.com",
      // messagingSenderId: "543686768265",
      // appId: "1:543686768265:web:9e55d6921bcc3644e0f858",
      // vapidKey:"BGnbTGxHgv6XJBCodslQfTP-YqCGAb6uH58oW62-6PMegNV8l_uJjBIY_2L-rlycys_fqh7VUu2x85YiWW7NS2Q"

      // slaesmitra
      // apiKey: "AIzaSyD0zGN7CPTCMJfVGZGmMwolaPmGq13jPF8",
      // projectId: "salesmitra-5198f",
      // storageBucket: "salesmitra-5198f.appspot.com",
      // messagingSenderId: "543686768265",
      // appId: "1:543686768265:web:9e55d6921bcc3644e0f858",
      // vapidKey:"BGnbTGxHgv6XJBCodslQfTP-YqCGAb6uH58oW62-6PMegNV8l_uJjBIY_2L-rlycys_fqh7VUu2x85YiWW7NS2Q"

      apiKey: "AIzaSyDuHP1FLDNbBtFsQ8x8btnc7rCcNPUEay8",
      projectId: "devmeraapp",
      storageBucket: "devmeraapp.appspot.com",
      messagingSenderId: "406995684806",
      appId: "1:406995684806:web:eef666decc86f3d486b6c7",
      // vapidKey: "cMBZbnGzUEjhuvqcbbYmFXUUK_834sMZ7CtyKVCEcgc"
      vapidKey: "BFDPgmWlar3KZgbuTE7czNWwxW_IiBxCsmEv9LLyp_1ztvinjn7jhDlszzUuYb4OmYyT1-TR569BELBwPXnO6_g"
    }),
    AngularFireMessagingModule,
    NgMultiSelectDropDownModule.forRoot(),
    // ServiceWorkerModule.register('ngsw-worker.js', {
    //   enabled: environment.production,
    //   // Register the ServiceWorker as soon as the app is stable
    //   // or after 30 seconds (whichever comes first).
    //   registrationStrategy: 'registerWhenStable:30000'
    // }),
    GaugesModule,

  ],
  providers: [HeaderService, NgxImageCompressService, DatePipe, { provide: LocationStrategy, useClass: HashLocationStrategy }],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class AppModule { }
