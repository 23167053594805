<div>
    <div class="purpleBgColor row p-0">
        <div class="row mx-auto">
            <div class="col-12 mt-3 text-center">
                <span class="rdheading">{{beatListData.beatname}}</span>
                <span class="ml-1 rdcodecss">{{beatListData.beatcode}}</span>
            </div>
        </div>

        <div class="row mx-auto">
            <div class="col-12 text-center mt-3 ">
                <span class="rdstatusOnTrack"> <img src="../../../assets/onTrack.png" class="img-fluid" />&nbsp;
                    {{beatListData.achstatus | titlecase}}</span>
            </div>
        </div>

        <div class="row mx-auto" *ngIf="showSpeedometer">
            <kendo-radialgauge [pointer]="{ value: value}">
                <kendo-radialgauge-scale [minorUnit]="minorValue" [majorUnit]="majorValue" [max]="maxValue"
                    [rangeSize]="rangeSize" [majorTicks]="{color: ticksColor }" [minorTicks]="{ color: ticksColor }"
                    [startAngle]="startAngle" [endAngle]="endAngle">
                    <kendo-radialgauge-scale-labels [color]="'white'"></kendo-radialgauge-scale-labels>
                    <kendo-radialgauge-scale-ranges>
                        <kendo-radialgauge-scale-range *ngFor="let item of scaleRange" [from]="item.from" [to]="item.to"
                            [color]="item.color">
                        </kendo-radialgauge-scale-range>
                    </kendo-radialgauge-scale-ranges>
                </kendo-radialgauge-scale>

                <kendo-radialgauge-pointers>
                    <kendo-radialgauge-pointer *ngFor="let pointer of pointers" [value]="pointer.value"
                        [color]="pointer.color" [length]="pointer.length">
                    </kendo-radialgauge-pointer>
                </kendo-radialgauge-pointers>
            </kendo-radialgauge>
        </div>


        <div class="row mx-auto mt-2" style='width:26rem;height:4rem'>
            <div class="col-5">
                <!-- <span class="whiteColor p-2">25 Lac</span> -->
                <span class="whiteColor p-2">{{beatListData.beatach}}<span class="unit ml-1">Lac</span></span>

                <br>
                <span class="">
                    <img class="mr-1 p-0" src="assets/sec_dot.png" />
                    <span class="whiteColor p-0">Achievement</span>
                </span>
            </div>

            <div class="col-3 p-2">
                <span class="" style="color: white;font-size:20px ;">{{beatListData.beatachper}}%</span>
            </div>
            <div class="col-3 p-0">
                <!-- <span class=" float-end whiteColor">24 lac</span> -->
                <span class="whiteColor float-end">{{beatListData.beattarget}}<span class="unit ml-1">Lac</span></span>
                <br>
                <span class="float-end">
                    <img class="img-fluid mr-1" src="assets/pri_dot.png" />
                    <span class=" whiteColor">Target</span>
                </span>
            </div>
        </div>


        <div class="row mb-4 mx-auto">
            <div class="col-12 text-center" (click)="clickOnViewOutlet()">
                <button class="btn btn_viewPC ">View Outlets</button>
            </div>
        </div>
    </div>

    <div class="row mt-3">
        <div class="col-12">
            <span class="acctitle">Beat Performance</span>
        </div>
    </div>

    <div class="row mt-3">
        <div class="col-8">
            <span class="purpleColor">Beat Productivity MTD(Overall)</span>
        </div>
        <div class="col-4">
            <span class="float-end">{{beatListData.beatproductivity}}%</span>
        </div>
    </div>
    <hr>
    <div class="row mt-3">
        <div class="col-7">
            <span class="purpleColor">P7 Outlets Count</span>
        </div>
        <div class="col-5">
            <span class="float-end">{{getOutletCountData.p7outletcount}}</span>
        </div>
    </div>
    <hr>
    <div class="row mt-3">
        <div class="col-7">
            <span class="purpleColor">Milan Outlets Count</span>
        </div>
        <div class="col-5">
            <span class="float-end">{{getOutletCountData.milanoutletcount}}</span>
        </div>
    </div>
    <hr>
    <div class="row mt-3">
        <div class="col-7">
            <span class="purpleColor">VC Count</span>
        </div>
        <div class="col-5">
            <span class="float-end">{{getOutletCountData.vcoutletcount}}</span>
        </div>
    </div>
    <hr>
    <div class="row mt-3">
        <div class="col-9">
            <span class="purpleColor">Geocoded + Audited Count</span>
        </div>
        <div class="col-3">
            <span class="float-end">{{getOutletCountData.auditedcount}}</span>
        </div>
    </div>

    <div class="row mt-3 p-0 uv-100">
        <div class="col-12 p-0">
            <div id="content1" #content1>
                <mat-accordion>
                    <mat-expansion-panel class="mt-3" (opened)="panelOpenState = true" [expanded]="true"
                        (closed)="panelOpenState = false">
                        <mat-expansion-panel-header class="title">
                            <mat-panel-title>
                                Unbilled Outlets(L3M)
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <div class="row mt-3" *ngFor="let item of unbilledData">
                            <div class="col-8"><span class="unbilled_text">{{item.outletname}}</span></div>

                            <div class="col-4"><span class="Head_Val_txt">{{item.outletcode}}</span></div>
                        </div>

                    </mat-expansion-panel>
                </mat-accordion>
            </div>

            <!-- <div id="content2" #content2>
                <mat-accordion>
                    <mat-expansion-panel class="mt-3" (opened)="panelOpenState = true" [expanded]="true"
                        (closed)="panelOpenState = false">
                        <mat-expansion-panel-header class="title">
                            <mat-panel-title>
                                YTD Degrowing outlets
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <div class="row mt-3" *ngFor="let item of EarningTrend">
                      <div class="col-4"><span class="Value_text">{{item.fieldvalue}}</span></div>
                      <div class="col-6 p-0" style="margin-top: .35rem;">
                        <div class="progress rounded-pill">
                          <div class="progress-bar bg_sec" role="progressbar" [style.width.%]="item.totalincentive*20"
                            aria-valuenow="25" aria-valuemin="0" aria-valuemax="10"></div>
                        </div>
                      </div>
                      <div class="col-2"><span class="Head_text">{{item.totalincentive}}%</span></div>
                    </div>

                    </mat-expansion-panel>
                </mat-accordion>
            </div> -->

            <div id="content3" #content3>
                <mat-accordion>
                    <mat-expansion-panel class="mt-3" (opened)="panelOpenState = true" [expanded]="true"
                        (closed)="panelOpenState = false">
                        <mat-expansion-panel-header class="title">
                            <mat-panel-title>
                                Non Green Outlets(L3M)
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <div class="row mt-3" *ngFor="let item of getNonGreenOutletData">
                            <div class="col-8"><span class="unbilled_text">{{item.outletname}}</span></div>

                            <div class="col-4"><span class="Head_Val_txt">{{item.outletcode}}</span></div>
                        </div>

                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>
    </div>

    <div class="row mt-3 p-0 uv-100">
        <span class="acctitle">Sales Parameter</span>
    </div>
    <div class="row mt-3 p-0 uv-100">
        <table class="table tbl_custom">
            <thead>
                <tr>
                    <td class="td_cust">Category</td>
                    <td class="td_cust">MTD
                    </td>
                    <td class="td_cust">L3M%
                    </td>
                    <td class="td_cust">LYSM%</td>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of getOutletSalesParameter">
                    <td class="Value_text">{{item.category}}</td>
                    <td class="Head_text">{{item.mtd}}</td>
                    <td class="Head_text">{{item.l3m}}%</td>
                    <td class="Head_text">{{item.lm}}%</td>
                </tr>
            </tbody>
        </table>
    </div>
    <br><br><br><br><br>
</div>