<div>
  <div class="row mt-4">
    <div class="col-9">
      <i class="fa fa-caret-left" style="color: #757575;">&nbsp;&nbsp;
        <span class="field_Value_Head">{{FieldName=='0' ? 'All India' : FieldName=='ALLINDIA' ? 'All India': FieldName |titlecase}}</span>
      </i>
    </div>
    <div class="col-3 text-right" (click)="openFilter()">
      <img src="../../../assets/filter.png" class="img-fluid" />
      <sup><i class="fa fa-circle fa-sm" style="color: #eaab5e;font-size: 5px;"></i></sup>&nbsp;
      <span style="color: #724e8c;font-family: Roboto;font-weight:500 ;font-size: 0.875rem;">Filter</span>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-7 mt-1">
      <img src="../../../assets/info_btn.png" class="img-fluid"/>
      <span class="text-left value_with_tax align-middle"> Value with tax</span>
    </div>
    <!-- <div class="col-5 text-right">
      <select class="form_control_custom rounded-pill" id="period" name="period" (change)="changeMonth($event)">
        <option value="" disabled>Select Option</option>
        <option value="Month">Month</option>
        <option value="Year">Year</option>
      </select>
    </div> -->
  </div>
  <div>
    <div class="row mt-4">
      <div class="col-5">
        <span class="branch">Branch</span>
      </div>
      <div class="col-3">
        <span class="branch">Sales (K)</span>
      </div>
      <div class="col-4 p-0">
        <span class="branch">Distribution (%)</span>
      </div>
    </div>
    <div *ngIf="!IsRDValue">
      <div class="row mt-4" *ngFor="let item of list;let i = index" (click)="RowClick(item.fieldname ,item.fieldvalue)">
        <div class="col-6">
          <span class="field_Value">{{item.fieldvalue}}</span>
        </div>
        <div class="col-3">
          <span class="sale">{{item.sale}}</span>
        </div>
        <div class="col-3">
          <span class="sale">{{item.distribution}}</span>
        </div>
      </div>
    </div>
    <div *ngIf="IsRDValue">
      <div class="row mt-4" *ngFor="let item of list;let i = index">
        <div class="col-6">
          <span class="field_Value">{{item.desc | titlecase}}</span>
          <span class="desc ml-1">{{item.fieldvalue}}</span>
        </div>
        <div class="col-3">
          <span class="sale">{{item.sale}}</span>
        </div>
        <div class="col-3">
          <span class="sale">{{item.distribution}}</span>
        </div>
      </div>
    </div>
  </div>
  <br>
  <br>
  <br>
  <br>
  <br>
</div>
