<div>
    <div class="row mt-4">
        <div class="col-12">
          <button class="btn btn_Scan btn-block" type="button" (click)="action.toggleCamera()">Scan QR Code</button>
    
          <br /><br />
          <div class="qr-scan-area">
            <ngx-scanner-qrcode #action="scanner" [config]="config" (data)="qrCodeResult($event)" (error)="onError($event)">
            </ngx-scanner-qrcode>
            <div class="qr-area">
              <div class="area"></div>
            </div>
          </div>
          <hr />
        </div>
        <div class="col-12 mt-4 text-center" *ngIf="qrResult">
          <img class="img-fluid" src="assets/qr_Icon.svg" />
        </div>
      
        <!-- <div class="col-12 mt-3 text-center" *ngIf="output">
          <span class="qr_Code_Success">QR Code - {{output}} <br>
            scanned successfully</span>
        </div> -->
        <div class="row mt-3 text-center" >
            <div class="col-8">
                <input type="text" class="outletCode" #QRCode placeholder="Enter QR Code"  [(ngModel)]="output"/>
            </div>
            <div class="col-4">
                <button class="btn btn_GO" (click)="GOBtn(output)">GO</button>
            </div>
        </div>
    
      </div>

    <!-- <div class="row mt-4 mb-3 ">
        <div class="col-8">
            <input type="text" class="outletCode" #QRCode placeholder="Enter QR Code" />
        </div>
        <div class="col-4">
            <button class="btn btn_GO" (click)="GOBtn(QRCode)">GO</button>
        </div>
    </div> -->

    <div *ngIf="GoBtnClicked=='true'" class="mt-5 parentEle" id="parentEle">
        <div class="row" *ngFor="let item of QRCodeHistory">
            <div class="row QRCodeName mt-2">
                <div class="col-7 word_break align-items-center d-flex">
                    <span class="text_14 text_black">{{item.rdname}}</span>
                </div>
                <div class="col-5 word_break justify-content-end align-items-center d-flex">
                    <span class="text_14 text_black float-end text-end">{{item.rdcode}}</span>
                </div>
            </div>
            <div class="row mt-1 OutletCode">
                <div class="col-6 word_break align-items-center d-flex">
                    <span>Outlet Code</span>
                </div>
                <div class="col-6 word_break justify-content-end align-items-center d-flex">
                    <span class="float-end text-end">{{item.outletcode}}</span>
                </div>
                <hr class="w-99">
            </div>

            <div class="row mt-1">
                <div class="col-6 word_break align-items-center d-flex">
                    <span>Outlet Name</span>
                </div>
                <div class="col-6 word_break justify-content-end align-items-center d-flex">
                    <span class="float-end text-end">{{item.outletname}}</span>
                </div>
                <hr class="w-99">
            </div>

            

            <!-- <div class="row mt-1">
                <div class="col-6 word_break align-items-center d-flex">
                    <span>Branch Name</span>
                </div>
                <div class="col-6 word_break justify-content-end align-items-center d-flex">
                    <span class="float-end text-end">{{item.branchname}}</span>
                </div>
                <hr class="w-99">
            </div> -->

            <div class="row mt-1">
                <div class="col-6 word_break align-items-center d-flex">
                    <span>Region Name</span>
                </div>
                <div class="col-6 word_break justify-content-end align-items-center d-flex">
                    <span class="float-end text-end">{{item.regionname}}</span>
                </div>
                <hr class="w-99">
            </div>

            <div class="row mt-1">
                <div class="col-6 word_break align-items-center d-flex">
                    <span>ASM Area</span>
                </div>
                <div class="col-6 word_break justify-content-end align-items-center d-flex">
                    <span class="float-end text-end">{{item.asmarea}}</span>
                </div>
                <hr class="w-99">
            </div>

            <div class="row mt-1">
                <div class="col-6 word_break align-items-center d-flex">
                    <span>SO Territory</span>
                </div>
                <div class="col-6 word_break justify-content-end align-items-center d-flex">
                    <span class="float-end text-end">{{item.soterritory}}</span>
                </div>
                <hr class="w-99">
            </div>

            <div class="row mt-1">
                <div class="col-6 word_break align-items-center d-flex">
                    <span>Scanned Date</span>
                </div>
                <div class="col-6 word_break justify-content-end align-items-center d-flex">
                    <span class="float-end text-end">{{item.scandate}}</span>
                </div>
                <hr class="w-99">
            </div>
        
            <div class="row mt-1">
                <div class="col-6 word_break align-items-center d-flex">
                    <span>PC UID</span>
                </div>
                <div class="col-6 word_break justify-content-end align-items-center d-flex">
                    <span class="float-end text-end">{{item.pcuid}}</span>
                </div>
                <hr class="w-99">
            </div>
            <div class="row mt-1">
                <div class="col-6 word_break align-items-center d-flex">
                    <span>PC Name</span>
                </div>
                <div class="col-6 word_break justify-content-end align-items-center d-flex">
                    <span class="float-end text-end text-end">{{item.pcname}}</span>
                </div>
                <hr class="w-99">
            </div>
            <div class="row mt-1">
                <div class="col-6 word_break align-items-center d-flex">
                    <span>QR Code</span>
                </div>
                <div class="col-6 justify-content-end align-items-center d-flex">
                    <span class="float-end text-end">{{item.qrcodeauto}}</span>
                </div>
                <hr class="w-99">
            </div>
            <div class="row mt-1">
                <div class="col-6 word_break align-items-center d-flex">
                    <span>Scan Type</span>
                </div>
                <div class="col-6 word_break justify-content-end align-items-center d-flex">
                    <span class="float-end text-end">{{item.remark}}</span>
                </div>
                <hr class="w-99">
            </div>
        </div>
    </div>
 <br><br><br><br>
</div>
