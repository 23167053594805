import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { InputPara, Resultdata, Seconderytrenddata } from 'src/app/models/kpi-models';
import { FilterComponent } from '../filter/filter.component';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { DataService } from 'src/app/service/data.service';

@Component({
  selector: 'app-secondary-trend',
  templateUrl: './secondary-trend.component.html',
  styleUrls: ['./secondary-trend.component.css']
})
export class SecondaryTrendComponent implements OnInit {
  Branchname: any;
  AreaName: any;
  UserName: any;
  seconderytrenddata: any;
  resultdata: Resultdata[] = [];
  UserType: any;
  FieldName: any;
  FieldValue: any;
  device: any;
  deviceType: any;
  input: InputPara = new InputPara();
  token: any;
  lat:any;
  lng:any;
  UserID: any;

  constructor(private activatedRoute: ActivatedRoute, private cs: CommonUtilitiesService, private headerService: HeaderService, private mds: MongoDataService,
     private dialog: MatDialog,private notifyMeService: NotifyMeService,private ds: DataService,) { }

  ngOnInit(): void {
    this.headerService.setActiveURl('/Secondarytrend');
    this.headerService.setTitle('Secondary Billing Trend');
    this.seconderytrenddata = [];
    this.FieldName = "";
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.token = localStorage.getItem("TokenNo");
    this.UserID = localStorage.getItem("UserID");
    this.UserType=localStorage.getItem("UserType");
    this.activatedRoute.paramMap.forEach(params => {
      this.FieldName = params.get('FieldName');
      this.FieldValue = params.get('FieldValue');
      this.UserName = params.get('UserID');
      this.loadData();
    });
    if (this.FieldName == "") {
      this.FieldName = localStorage.getItem("FieldName");
      this.FieldValue = localStorage.getItem("FieldValue");
    }
    debugger
    this.GetGeoLocation();
  }

  GetGeoLocation() {
    debugger
    if (navigator) {
      navigator.geolocation.getCurrentPosition(pos => {
        this.lat = pos.coords.latitude;
        console.log("  this.lat", this.lat)
        this.lng = pos.coords.longitude;
        this.SetapplicationLogData();
      }, this.handleLocationError, { timeout: 30000 });
    }
    else {
      this.notifyMeService.showError('Location Could not be captured', 'Mera APP');
    }
  }

  handleLocationError(error: { code: any; }) {
    switch (error.code) {
      case 3:
        this.notifyMeService.showError('Timeout in Position capture', 'Mera APP V2');
        break;
      case 2:
        this.notifyMeService.showError('Device is not capable of Position capture', 'Mera APP V2');
        break;
      case 1:
        this.notifyMeService.showError('You denied for Position capture', 'Mera APP V2');
        break;
    }
  }


  SetapplicationLogData() {
    debugger
    let request = {
      "userid": this.UserID,
      "usertype": this.UserType,
      "formname": "Secondarytrend",
      "actiondesc": "Secondary trend Data" + " " + this.FieldName,
      "fieldname": this.FieldName,
      "fieldvalue": this.FieldValue,
      "latitude": this.lat,
      "longitude": this.lng
    }
    this.ds.SetapplicationLogData(request).subscribe(res => {
      console.log("res", res)
    })
  }

  loadData(): void {
    debugger;
    this.input.FieldName = this.FieldName;
    this.input.FieldValue = this.FieldValue;
    this.input.TagName = '0';
    this.input.Datatype = "0";
    this.input.UserID = this.UserName;
    this.mds.getSecondaryTrend(this.input, this.token, this.device, this.deviceType, 'Secondary Trend').subscribe(res => {

      this.seconderytrenddata = res as Seconderytrenddata;
    });
  }
  openFilter() {
    if (this.dialog.openDialogs.length == 0) {

      const dialogRef = this.dialog.open(FilterComponent, {
        closeOnNavigation: true,
        data: {
          "FieldName": localStorage.getItem("OriginalFieldName"),
          "FieldValue": localStorage.getItem("OriginalFieldValue"),
          "UserID": this.UserName,
          "MenuUpTo": "5",
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.FieldName = result.FieldName;
          this.FieldValue = result.FieldValue;
          this.loadData();
        }
      });
    }
  }
}
