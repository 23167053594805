<div>
  <div class="row mt-4">
    <div class="col-12">
      <input class="fullWidth" type="text" name="" id="" placeholder="Search Sub RD Name Or UID"
        [(ngModel)]="searchTerm">
    </div>
  </div>
  <div *ngFor="let item of filteredList; let i = index;" (click)="rowClick(item)">
    <div class="row mt-3">
      <div class="col-12">
        <span class="pc_Name">{{item.outletName | titlecase}}</span>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-12">
        <span class="sst_Code">SST Code:</span>
        <span class="ml-1 pc_Code">{{item.rdCode}}</span>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-6">
        <span class="sst_Code">SO Status:</span>&nbsp;
        <img src="assets/fssai_approved.svg" *ngIf="item.soStatus.toUpperCase() == 'APPROVED'"/>
        <img src="assets/fssai_rejected.svg" *ngIf="item.soStatus.toUpperCase() == 'REJECTED'"/>
        <img src="assets/fssai_pending.svg" *ngIf="item.soStatus.toUpperCase() == ''" />
      </div>
      <div class="col-6 text-right" *ngIf="userType != 'SO'">
        <span class="sst_Code">ASM Status:</span>&nbsp;
        <img src="assets/fssai_approved.svg" *ngIf="item.asmStatus.toUpperCase() == 'APPROVED'"/>
        <img src="assets/fssai_rejected.svg" *ngIf="item.asmStatus.toUpperCase() == 'REJECTED'"/>
        <img src="assets/fssai_pending.svg" *ngIf="item.asmStatus.toUpperCase() == ''" />
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-6 pr-0" *ngIf="userType == 'RSOM' || userType == 'RH'">
        <span class="sst_Code">RSOM Status:</span>&nbsp;
        <img src="assets/fssai_approved.svg" *ngIf="item.rcmmStatus.toUpperCase() == 'APPROVED'"/>
        <img src="assets/fssai_rejected.svg" *ngIf="item.rcmmStatus.toUpperCase() == 'REJECTED'"/>
        <img src="assets/fssai_pending.svg" *ngIf="item.rcmmStatus.toUpperCase() == ''" />
      </div>
      <div class="col-6 text-right" *ngIf="userType == 'RH'">
        <span class="sst_Code">RH Status:</span>&nbsp;
        <img src="assets/fssai_approved.svg" *ngIf="item.rheadStatus.toUpperCase() == 'APPROVED'"/>
        <img src="assets/fssai_rejected.svg" *ngIf="item.rheadStatus.toUpperCase() == 'REJECTED'"/>
        <img src="assets/fssai_pending.svg" *ngIf="item.rheadStatus.toUpperCase() == ''" />
      </div>
    </div>
    <hr>
  </div>
  <br>
  <br>
  <br>
  <br>
  <br>
</div>
