import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { DataService } from 'src/app/service/data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-sub-rd-approval-details',
  templateUrl: './sub-rd-approval-details.component.html',
  styleUrls: ['./sub-rd-approval-details.component.css']
})
export class SubRdApprovalDetailsComponent implements OnInit {
  @ViewChild('openModalThankYou', { static: true }) elRefOpenModalThankYou: ElementRef | undefined;
  deviceType: any;
  device: any;
  userType: string | null = '';
  userId: any;
  fieldName: any;
  fieldValue: any;
  subRdApprovalDetails: any;
  list: any;
  beatnameList: any[] = [];
  marketNameList: any;
  oldSubList: any;
  selectedBeat: string = '';
  selectedSubRDType: any;
  isNew: boolean = false;
  selectedOldSub: string = '';
  isBufircate: boolean = false;
  selected = -1;
  selectedMarketNameList: any[] = [];
  selectedReasons: any;
  marketNameListForPopup: any;
  oldSubStkUID: string = '';
  dtTemplate: any[] = [];
  beatCode: any;
  message: string = '';
  isReject: boolean = false;
  rhList: any;
  showModal: boolean = false;
  selectedimagepath: any;
  photopath: any;
  token: any;
  formname: any;
  actiondesc: any;

  constructor(private activatedRoute: ActivatedRoute, private cs: CommonUtilitiesService, private router: Router, private headerService: HeaderService, private mds: MongoDataService, private notifyMeService: NotifyMeService, private ds: DataService, private modalService: NgbModal,) { }

  ngOnInit(): void {
    this.headerService.setTitle('Sub RD Approval');
    this.headerService.setActiveURl('/subRdApprovalDetails');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.token = localStorage.getItem("TokenNo");
    this.userId = localStorage.getItem("UserID");
    this.photopath = localStorage.getItem("baseappurl") + "NewSubPhoto/";
    this.subRdApprovalDetails = JSON.parse(localStorage.getItem("subRdApprovalDetails") || '{}');
    console.log("subRdApprovalDetails", this.subRdApprovalDetails)
    this.selectedBeat = this.subRdApprovalDetails.beatName;
    this.beatCode = this.subRdApprovalDetails.beatCode;
    this.selectedimagepath = "";
    this.fieldName = localStorage.getItem("FieldName");
    this.fieldValue = localStorage.getItem("FieldValue");
    this.formname = "subRdApprovalDetails"
    this.actiondesc = "Sub Rd Approval Details";
    this.mds.getUserDetails(this.userId, this.token, this.device, this.deviceType, 'subRdApprovalDetails').subscribe(res => {
      console.log("getUserDetails", res)
      this.userType = res[0].userType.toUpperCase();
      this.cs.GetGeoLocation(this.userId, this.userType, this.formname, this.actiondesc, this.fieldName, this.fieldValue);

      this.onLoad();
    });
  }

  onLoad() {
    if (this.userType?.toUpperCase() == 'SO') {
      this.ds.ma_RLA_GetOutletsForSUB(this.subRdApprovalDetails.rdCode, this.token, this.device, this.deviceType, 'subRdApprovalDetails').subscribe(res => {
        console.log("ma_RLA_GetOutletsForSUB", res)
        this.list = res;
        this.beatnameList = [...new Set(res.map((bill: { beatname: any; }) => bill.beatname.trim()))].sort();
        console.log("beatnameList", this.beatnameList)
        this.oldSubList = [...new Set(res.map((bill: { outletName: any; }) => bill.outletName))].sort();
        console.log("oldSubList", this.oldSubList)
      });
    } else if (this.userType?.toUpperCase() == 'RH') {
      this.ds.MA_GetOutletsForApproval(this.subRdApprovalDetails.subid, this.token, this.device, this.deviceType, 'subRdApprovalDetails').subscribe(res => {
        console.log("getOutletsForApproval", res)
        this.rhList = res;
      });
      this.ds.getsubmarket(this.subRdApprovalDetails.subid, this.token, this.device, this.deviceType, 'subRdApprovalDetails').subscribe(res => {
        console.log("getsubmarket", res)
        debugger;
        this.marketNameList = res;
      });
    } else {
      this.ds.getsubmarket(this.subRdApprovalDetails.subid, this.token, this.device, this.deviceType, 'subRdApprovalDetails').subscribe(res => {
        console.log("getsubmarket", res)
        debugger;
        this.marketNameList = res;
      });
    }
  }

  clickOnMoreDetails() {
    this.router.navigate(['/home/subRdApprovalDetailsEdit']);
  }

  clickOnSubRDType() {
    this.router.navigate(['/home/subRdApprovalSelectRdType']);
  }

  clickOnOldRD() {
    this.router.navigate(['/home/subRdApprovalSelectOldRd']);
  }

  clickOnMarkets(model: any) {
    if (this.selectedSubRDType == 'bifurcate') {
      this.marketNameList = this.marketNameListForPopup;
      this.modalService.open(model,
        {
          size: 'lg',
          backdrop: 'static',
          windowClass: '',
          keyboard: false,
          centered: true,
        });
    }
  }

  approve() {
    debugger
    this.dtTemplate = [];
    if (this.userType?.toUpperCase() == 'SO') {
      if (this.selectedSubRDType == 'new') {
        this.dtTemplate.push({ Para1: "", Para2: "", Para3: "", Para4: "", Para5: "", Para6: "", Para7: "", Para8: "", Para9: "", Para10: "", });
        this.oldSubStkUID = '';
        this.selectedOldSub = '';
      }
      if (this.selectedSubRDType == 'replacement') {
        this.selectedOldSub = this.selectedOldSub.split('[')[0].toString();
        this.dtTemplate.push({ Para1: "", Para2: "", Para3: "", Para4: "", Para5: "", Para6: "", Para7: "", Para8: "", Para9: "", Para10: "", });
      }
      if (this.selectedSubRDType == 'bifurcate') {
        this.selectedOldSub = this.selectedOldSub.split('[')[0].toString();
        this.selectedMarketNameList.forEach(element => {
          this.dtTemplate.push({ Para1: element.marketUID, Para2: "", Para3: "", Para4: "", Para5: "", Para6: "", Para7: "", Para8: "", Para9: "", Para10: "", });
        });
      }

      this.list.forEach((element: any) => {
        if (this.selectedBeat == element.beatname.trim()) {
          this.beatCode = element.beatcode;
        }
      });

      const dataToSend = {
        SUBID: this.subRdApprovalDetails.subid,
        phoneNo: this.subRdApprovalDetails.phone,
        BeatCode: this.beatCode.trim(),
        BeatName: this.selectedBeat,
        SubstokistStatus: this.selectedSubRDType,
        OldSubStkUID: this.oldSubStkUID,
        OldSubStkName: this.selectedOldSub.trim(),
        UserID: this.userId,
        Status: 'APPROVED',
        OutletTypeID: '0',
        DTTemplate: this.dtTemplate,
      };
      this.ds.rla_SetSubstockistApproval(dataToSend, this.token, this.device, this.deviceType, 'subRdApprovalDetails').subscribe(res => {
        if (res[0].msg != "OK") {
          this.message = res[0].msg;
        }
        this.openModal(this.elRefOpenModalThankYou);
      },(error:any)=>{
        debugger
      });
    } else if (this.userType?.toUpperCase() == 'RH') {

    }
  }

  openModal(modal: ElementRef<any> | undefined) {
    this.modalService.open(modal,
      {
        size: 'lg',
        backdrop: 'static',
        windowClass: '',
        keyboard: false,
        centered: true,
      })
  }

  okay() {
    this.modalService.dismissAll();
    this.router.navigate(['/home/subRdApproval']);
  }

  modalClose() {
    this.modalService.dismissAll();
    this.router.navigate(['/home/subRdApproval']);
  }

  onChangeBeat(event: any) {
    this.selectedBeat = event.target.value;
  }

  onChangeSubRDType(event: any) {
    this.marketNameList = [];
    this.selectedSubRDType = event.target.value;
    if (this.selectedSubRDType == 'new') {
      this.isNew = false;
    } else {
      this.isNew = true;
    }
    if (this.selectedSubRDType == 'bifurcate') {
      this.isBufircate = false;
    } else {
      this.isBufircate = true;
    }
  }

  onChangeOldSub(event: any) {
    this.selectedMarketNameList = [];
    this.selectedOldSub = event.target.value;
    this.marketNameList = this.list.filter((singleItem: { [x: string]: string; }) => singleItem['outletName'] == this.selectedOldSub);
    this.marketNameListForPopup = this.marketNameList;
    console.log("marketNameList", this.marketNameList)
    this.list.forEach((element: any) => {
      if (this.selectedOldSub == element.outletName) {
        this.oldSubStkUID = element.outletCode;
      }
    });
  }

  modalCloseForMarket() {
    this.marketNameList = this.selectedMarketNameList;
    this.modalService.dismissAll();
  }

  selected_Reason(e: any) {
    if (e.target.checked) {
      this.selectedReasons = e.target.value;
      this.marketNameList.forEach((element: any) => {
        if (this.selectedReasons == element.marketName) {
          this.selectedMarketNameList.push(element);
        }
      });
    } else {
      this.selectedMarketNameList = this.selectedMarketNameList.filter((singleItem: { [x: string]: string; }) => singleItem['marketName'] != e.target.value);
    }
    console.log("selectedMarketNameList", this.selectedMarketNameList)
  }

  save() {
    this.marketNameList = this.selectedMarketNameList;
    this.isBufircate = true;
    console.log("marketNameList after market selection", this.marketNameList)
    this.modalService.dismissAll();
  }

  reject() {
    this.isReject = true;
    if (this.userType?.toUpperCase() == 'RH') {
      this.dtTemplate = [];
      this.dtTemplate.push({ Para1: "RLAOutletApproval", Para2: this.rhList[0].rlaid.toString(), Para3: this.rhList[0].newSubID.toString(), Para4: "", Para5: "", Para6: "", Para7: "", Para8: "", Para9: "", Para10: "", });
      const dataToSend = {
        DTApproval: this.dtTemplate,
        UserID: this.userId,
        Status: 'REJECTED',
        KeyID: '',
      };
      console.log("dataToSend", dataToSend)
      this.ds.setOutletsForApproval(dataToSend, this.token, this.device, this.deviceType, 'subRdApprovalDetails').subscribe(res => {
        console.log("setSubStkForApprovalRejection", res)
        this.isReject = true;
        this.openModal(this.elRefOpenModalThankYou);

      });
    } else {
      const dataToSend = {
        UserID: this.userId,
        Status: 'REJECTED',
        SUbID: this.subRdApprovalDetails.subid,
        UserType: this.userType,
      };
      console.log("dataToSend", dataToSend)
      this.ds.setSubStkForApprovalRejection(dataToSend, this.token, this.device, this.deviceType, 'subRdApprovalDetails').subscribe(res => {
        debugger;
        console.log("setSubStkForApprovalRejection", res)
        this.isReject = true;
        this.openModal(this.elRefOpenModalThankYou);
      });
    }
  }

  approveForOther() {
    if (this.userType?.toUpperCase() == 'RH') {
      this.dtTemplate = [];
      this.dtTemplate.push({ Para1: "RLAOutletApproval", Para2: this.rhList[0].rlaid.toString(), Para3: this.rhList[0].newSubID.toString(), Para4: "", Para5: "", Para6: "", Para7: "", Para8: "", Para9: "", Para10: "", });
      const dataToSend = {
        DTApproval: this.dtTemplate,
        UserID: this.userId,
        Status: 'APPROVED',
        KeyID: '',
      };
      console.log("dataToSend", dataToSend)
      this.ds.setOutletsForApproval(dataToSend, this.token, this.device, this.deviceType, 'subRdApprovalDetails').subscribe(res => {
        console.log("setSubStkForApproval", res)
        this.openModal(this.elRefOpenModalThankYou);
      });
    } else {
      const dataToSend = {
        SubID: this.subRdApprovalDetails.subid,
        UserID: this.userId,
        Status: 'APPROVED',
        RDCode: this.subRdApprovalDetails.rdCode,
        OutletName: this.subRdApprovalDetails.oldSubStkName,
        UserType: this.userType,
      };
      console.log("dataToSend", dataToSend)
      this.ds.setSubStkForApproval(dataToSend, this.token, this.device, this.deviceType, 'subRdApprovalDetails').subscribe(res => {
        console.log("setSubStkForApproval", res)
        this.openModal(this.elRefOpenModalThankYou);
      });
    }
  }

  show(imagepath: any) {
    this.selectedimagepath = imagepath;
    this.showModal = true; // Show-Hide Modal Check

  }
  //Bootstrap Modal Close event
  hide() {
    this.showModal = false;
  }

}
