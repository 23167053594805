import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/service/data.service';
import { HeaderService } from 'src/app/service/header.service';
import { QRCodeData } from 'src/app/models/kpi-models';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';
import { EncryptionService } from 'src/app/service/encryption.service';
import { Enclass, ResultClass } from 'src/app/models/encryption-class';
import { MongoDataService } from 'src/app/service/mongo-data.service';


@Component({
  selector: 'app-scan-qr-code-in-transaction',
  templateUrl: './scan-qr-code-in-transaction.component.html',
  styleUrls: ['./scan-qr-code-in-transaction.component.css']
})
export class ScanQrCodeInTransactionComponent implements OnInit {
  public config: Object = {
    isAuto: true,
    text: { font: '25px serif' }, // Hiden { font: '0px' },
    frame: { lineWidth: 8 },
    medias: {
      audio: false,
      video: {
        facingMode: 'environment', // To require the rear camera https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia
        width: { ideal: 1280 },
        height: { ideal: 720 }
      }
    }
  };

  qrResult: any;
  output = "";
  deviceType: any;
  device: any;
  userType: string | null = '';
  userId: any;
  list: any;
  filteredList: any;
  fieldName: any;
  fieldValue: any;
  token: any;
  formname: any;
  actiondesc: any;
  regex = /^[A-Za-z]{2}\d{4}[A-Za-z0-9]{6,14}$/;
  secretKey: any;
  clientId: any;
  @ViewChild('openModalQRcCodeAvailable', { static: true }) elRefOpenModalQRcCodeAvailable: ElementRef | undefined;
  public enclass: Enclass = new Enclass();
  public resultClass: ResultClass = new ResultClass();
  constructor(private headerService: HeaderService, private toastr: ToastrService, private router: Router, private ds: DataService,
    private notifyService: NotifyMeService, private es: EncryptionService, private modalService: NgbModal, private cs: CommonUtilitiesService, private mds: MongoDataService) { }

  ngOnInit(): void {
    this.headerService.setTitle('QR Code Scan');
    this.headerService.setActiveURl('/ScanQrCodeInTransaction');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.token = localStorage.getItem("TokenNo");
    this.userType = localStorage.getItem("UserType");
    this.userId = localStorage.getItem("UserID");
    this.fieldName = localStorage.getItem("FieldName");
    this.fieldValue = localStorage.getItem("FieldValue");
    this.formname = "QRCodeScan";
    this.actiondesc = "QrCode Scan";
    this.secretKey = localStorage.getItem("SecreteKey")
    this.clientId = localStorage.getItem("ClientID");
    this.cs.GetGeoLocation(this.userId, this.userType, this.formname, this.actiondesc, this.fieldName, this.fieldValue);

  }


  public qrCodeResult(result: any): void {
    debugger
    if (result) {
      this.output = result;

      if (!this.regex.test(this.output)) {
        this.toastr.warning('Invalid QR Code.');
        // Please enter two letters followed by four digits, and ensure the total length is in between 12 to 20 characters.
        return
      }
      localStorage.setItem("qrCodeName", this.output);
      this.getMA_getQRCodeDetails();
      (<HTMLInputElement>document.getElementsByClassName("btn_Scan")[0])?.click();

    }
  }


  getMA_getQRCodeDetails() {
    debugger
    let request = {
      "QRCode": this.output,
    }
    this.es.Word = this.es.WordKey;
    this.es.encrypt(this.secretKey);
    this.es.Word = JSON.stringify(request);
    this.enclass.buggerstring = this.es.encrypt(this.secretKey)
    this.mds.MA_getQRCodeDetails(this.enclass, this.token, this.device, this.deviceType, this.formname, this.clientId).subscribe(res => {
      console.log("getSurveydetails", res)

      this.resultClass = res;
      this.es.Word = this.resultClass.resultString;

      let response = JSON.parse(this.es.decrypt(this.secretKey));

      if (response[0]?.msg?.toLowerCase() == "invalid qr code") {
        this.openModal(this.elRefOpenModalQRcCodeAvailable);
      }
      else if (response[0]?.msg?.toLowerCase() == "ok") {
        this.router.navigate(['home/ScanQrCodeMatchInTransaction'])
        localStorage.setItem("qrCodeDetails", JSON.stringify(response))
      }
      else {
        this.toastr.warning("Something Went Wrong!")
        return;
      }


      (<HTMLInputElement>document.getElementsByClassName("btn_Scan")[0])?.click();

      return;

    }, (error: any) => {
      console.log("error", error)
    });

  }

  clickOnYes() {
    this.modalClose()
    this.router.navigate(['/home/ScanQrCodeDataInTransaction'])
  }

  clickOnNo() {
    this.modalClose()
    this.router.navigate(['/home/transactions']);
  }

  modalClose() {
    this.modalService.dismissAll();

  }
  openModal(modal: ElementRef<any> | undefined) {
    this.modalService.open(modal,
      {
        size: 'lg',
        backdrop: 'static',
        windowClass: '',
        keyboard: false,
        centered: true,
      })
  }

  GOBtn(QRCode: any) {

  }

  public onError(e: any): void {
    // debugger
    alert(e);
  }
}
