<div class="app-splash-screen" [ngStyle]="{
    left: windowWidth,
    opacity: opacityChange,
    transition: splashTransition
  }" *ngIf="showSplash">

  <div class="app-splash-inner">
    <div>
        <img src="../../assets/merapp2logo.svg"   class="app-logo" >
    </div> 
     <!-- <div class="app-label">Splashy</div> -->
    <div class="app-loader"></div>
    <!-- <img src="../../assets/flashgini.svg" class="Group-341"> -->
    <img src="../../assets/flashimg.svg"  class="Frame">
  </div>

</div>

