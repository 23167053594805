import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { MatDialog } from '@angular/material/dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterComponent } from '../filter/filter.component';
import { DataService } from '../../service/data.service';
import { OutputGetVisiAgrOTP,OutputAgreeDetails } from 'src/app/models/kpi-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-a2b-vc',
  templateUrl: './a2b-vc.component.html',
  styleUrls: ['./a2b-vc.component.css']
})
export class A2bVCComponent implements OnInit {
  @ViewChild('openModalThankYou', { static: true }) elRefOpenModalThankYou: ElementRef | undefined;
  @ViewChild('openDPNModalThankYou', { static: true }) elRefDPNOpenModalThankYou: ElementRef | undefined;
  @ViewChild('openAGRModalThankYou', { static: true }) elRefAGROpenModalThankYou: ElementRef | undefined;
  @ViewChild('openBothModalThankYou', { static: true }) elRefBothOpenModalThankYou: ElementRef | undefined;
  @ViewChild('openModalAGRSignedin', { static: true }) elRefopenModalAGRSignedin: ElementRef | undefined;
  @ViewChild('openModalDPNSignedin', { static: true }) elRefopenModalDPNSignedin: ElementRef | undefined;
  
  constructor(private activatedRoute: ActivatedRoute,private fb: FormBuilder,private router: Router,
    private dialog: MatDialog,private ds:DataService,private headerService: HeaderService, private modalService: NgbModal,
    private deviceService: DeviceDetectorService,private cs:CommonUtilitiesService) 
 { }
 loginForm = this.fb.group({
   otp: ['', [Validators.required]]
 });
 loginFormagr = this.fb.group({   
   agrOTP: ['', [Validators.required]]
 });
 deviceType: any;
 device: any;
 userType: string | null = '';
 userId: any;
 token: any;
 DPNText: string | null = '';
 AgrText: string | null = '';
 submiton:string|null='';
 dpnsubmiton:string|null='';
 agrsubmiton:string|null='';
 IsOTP: boolean = false;
 IsChecked :boolean=false;
 statusobj: OutputGetVisiAgrOTP = new OutputGetVisiAgrOTP();
 agrdetails:OutputAgreeDetails=new OutputAgreeDetails();
 ID:any;
 errormsg: any='';
 IsError: boolean=false;
 KeyID: string|null='';
 OTP:string|null='';
 agrtype:string|null='';
 deviceInfo: any;
 formname:any;
 actiondesc:any;
 FieldName:any;
 FieldValue:any;
 userID:any;
 ngOnInit(): void {
   debugger;
   
   this.headerService.setTitle('Check Agreement');
   this.headerService.setActiveURl('/vc');
   this.deviceInfo = this.deviceService.getDeviceInfo();
     this.deviceType= this.deviceInfo.deviceType;
     this.device= this.deviceInfo.device;
   this.token = localStorage.getItem("TokenNo");
  // this.userId = localStorage.getItem("UserID");
  this.userID = localStorage.getItem("UserID");
  this.userType = localStorage.getItem("UserType");
  this.FieldName = localStorage.getItem("FieldName");
  this.FieldValue = localStorage.getItem("FieldValue");


    this.activatedRoute.paramMap.forEach(params => {
      this.KeyID = params.get('KeyID');
      if (this.KeyID != null) {
        if (this.KeyID.length > 0 && this.KeyID.length > 0) {
          this.checkuserlink(this.KeyID);
        }
      }
     
   });
   this.formname="login/a2bvc";
   this.actiondesc="a2bvc"+" "+this.KeyID
   this.cs.GetGeoLocation(this.userID,this.userType,this.formname,this.actiondesc,this.FieldName,this.FieldValue);
   this.getAgreementDetails();
 }

 checkuserlink(e:any): void{
   const dataToSend = {
     KeyID:e,
   };
   debugger;
    this.ds.MA_A2BEAgreeCheckLink(dataToSend,this.token,this.device,this.deviceType,'checkA2BDPNAgreementLink').subscribe((res: any) => {
        debugger;
     console.log("MA_A2BEAgreeCheckLink",res)
     debugger;
     this.statusobj =res; 
     debugger;
     //check for submiton date for DPN and Agreement
     if((res[0].submitOn!="")&&(res[1].submitOn!=""))
     {
       // if((res[0].result=="OK")&&(res[1].result=="OK"))
       // {
         this.IsError=false;
         this.errormsg=res[0].result;
         this.dpnsubmiton =res[0].submitOn; 
         this.agrsubmiton =res[1].submitOn; 
         this.openModal(this.elRefOpenModalThankYou);  
       // }
       // else{this.IsError=true;
       // this.errormsg=res[0].result;
       // }
     }
     //check submiton for DPN 
     if((res[0].submitOn=="")&&(res[0].agreementType=="DPN")){
       this.errormsg="";
      if (res[0].result=="OK")
      {
       this.IsError=false;
        this.ID=res[0].id;
        this.agrtype='DPN';
       this.openModal(this.elRefDPNOpenModalThankYou);  
      }
      else
      {this.IsError=true;
       this.errormsg=res[0].result;}

     }
     //check submiton for Agreement
     else if ((res[1].submitOn=="")&&(res[1].agreementType=="Agreement")){
       this.errormsg="";
if((res[1].result=="OK"))
{
 this.IsError=false;
  this.ID=res[1].id; 
 this.agrtype='Agreement'; 
 this.openModal(this.elRefAGROpenModalThankYou);
}
else{this.IsError=true;
 this.errormsg=res[1].result;}
     }
  
   });
   
 }

 selected_Reason(e: any) {
   if (e.target.checked) {
     this.IsChecked = e.target.value;        
 }}

 selected_agrcheckbox(e: any){  if (e.target.checked) {
   this.IsChecked = e.target.value;        
}}

 submitOTP(OTP: string,IsChecked:boolean) {
   debugger;
   this.errormsg='';
   if ((OTP == "")||(!IsChecked)) { this.IsOTP = false;IsChecked=false;  } 
   else {this.IsOTP=true;IsChecked=true;
     const dataToSend = {
       KeyID:this.KeyID,
       ID:this.ID,
       OTp:OTP
     };
     this.ds.MA_A2BEAgreeSetOtp(dataToSend,this.token,this.device,this.deviceType,'MA_A2BEAgreeSetOtp').subscribe((res: any) => {
       debugger;
    console.log("MA_A2BEAgreeSetOtp",res)
    debugger;
    this.statusobj =res; 
    if(res[0].result=='OK')
    {this.IsError=false;
   
      this.openModal(this.elRefopenModalDPNSignedin);
   
   }
    else{
    this.errormsg=res[0].result;this.IsError=true;}
   });
   }
 }

 submitagrOTP(OTP: string,IsChecked:boolean) {
   debugger;
   this.errormsg='';
   if ((OTP == "")||(!IsChecked)) { this.IsOTP = false;IsChecked=false;  } 
   else {this.IsOTP=true;IsChecked=true;
     const dataToSend = {
       KeyID:this.KeyID,
       ID:this.ID,
       OTp:OTP
     };
     this.ds.MA_A2BEAgreeSetOtp(dataToSend,this.token,this.device,this.deviceType,'MA_A2BEAgreeSetOtp').subscribe((res: any) => {
       debugger;
    console.log("MA_A2BEAgreeSetOtp",res)
    debugger;
    this.statusobj =res; 
    if(res[0].result=='OK')
    {this.IsError=false;
   
    this.openModal(this.elRefopenModalAGRSignedin);
    }
    else{
    this.errormsg=res[0].result;this.IsError=true;}
   });
     }
 }

 okay(){
   this.modalService.dismissAll();
   this.checkuserlink(this.KeyID);
    }

 modalClose() {
   this.modalService.dismissAll();
   
 }
 openModal(modal: ElementRef<any> | undefined) {
   this.modalService.open(modal,
     {
       size: 'lg',
       backdrop: 'static',
       windowClass: '',
       keyboard: false,
       centered: true,
     })
 }
 

 getAgreementDetails()
 {
   this.ds.MA_A2BEAgreeGetAgreementdetails(this.token,this.device,this.deviceType,'MA_A2BEAgreeGetAgreementdetails').subscribe((agrdtls: any) => {
   debugger;
console.log("MA_A2BEAgreeGetAgreementdetails",agrdtls)
debugger;
this.agrdetails=agrdtls;
this.AgrText=agrdtls[0].details;
this.DPNText=agrdtls[1].details;
debugger;
 });

 }

}
