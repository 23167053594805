import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from 'src/app/service/header.service';
import { DataService } from 'src/app/service/data.service';
import { InputPara } from '../../models/kpi-models';
import { statusreport } from 'src/app/models/kpi-models';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';


@Component({
  selector: 'app-healthstatuslist',
  templateUrl: './healthstatuslist.component.html',
  styleUrls: ['./healthstatuslist.component.css']
})
export class HealthstatuslistComponent implements OnInit {
  input: InputPara = new InputPara();
  UserType: any;
  device: any;
  deviceType: any;
  UserID: any;
  listOrigin: any;
  private _searchText: any;
  list: any;
  FieldName: any;
  Tital: any;
  FieldValue: any;
  DataType: any;
  reasonlist: any;
  firstchk: string = '';
  secondchk: string = '';
  defaultchk: string = '';
  statusobj: Array<statusreport> = new Array<statusreport>();
  OriginalFieldName: any;
  OriginalFieldValue: any;
  asmarea: string = '';
  soterritory: string = '';
  rdcode: number = 0;
  Paraname: any;
  token: any;
  formname:any;
  actiondesc:any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private headerService: HeaderService,
    private ds: DataService,
    private notifyMeService: NotifyMeService,
    private mds: MongoDataService,
    private cs:CommonUtilitiesService,
  ) { }

  ngOnInit(): void {
    this.statusobj = [];
    this.OriginalFieldName = localStorage.getItem("OriginalFieldName");
    this.OriginalFieldValue = localStorage.getItem("OriginalFieldValue");
    this.asmarea = this.OriginalFieldName?.toUpperCase() == 'ASMAREA' ? this.OriginalFieldValue : '';
    this.soterritory = this.OriginalFieldName?.toUpperCase() == 'SOTERRITORY' ? this.OriginalFieldValue : '';
    this.UserType = localStorage.getItem("UserType");
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.token = localStorage.getItem("TokenNo");
    this.UserID = localStorage.getItem("UserID");
    this.activatedRoute.paramMap.forEach(params => {
      this.FieldName = params.get('FieldName');
      this.FieldValue = params.get('FieldValue');
      this.UserID = params.get('UserID');
      this.DataType = params.get('DataType');
      console.log("DataType", this.DataType)
      if (this.DataType == 'PCL') {
        this.headerService.setActiveURl('/HealthstauslistPCL');
      } else if (this.DataType == 'PC') {
        this.headerService.setActiveURl('/HealthstauslistPC');
      } else{
        this.headerService.setActiveURl('/Healthstauslist');
      }

      this.Paraname = params.get('Paraname');
      this.Tital = this.DataType == 'PCL' ? 'PC Field Status' : this.DataType + " Field Status";
      this.headerService.setTitle(this.Tital);
      this.formname="Healthstatuslist";
      this.actiondesc="Health Status List"+" "+this.FieldName;
      this.cs.GetGeoLocation(this.UserID,this.UserType,this.formname,this.actiondesc,this.FieldName,this.FieldValue);
      this.getreasondata();
      this.loaddata();
    });
  }
  RowClick(e: any, desc: any): void {
    if (this.DataType == 'PC') {
      this.router.navigate(['/Healthstatuslist/', 'RD', e, desc, 'PCL', this.UserID]);
    }
  }
  get searchTerm(): any {
    return this._searchText;
  }
  set searchTerm(value: any) {
    this._searchText = value;
    this.listOrigin = this.filteredListMethod(value);
  }

  filteredListMethod(value: any): any {
    return this.list.filter((ele: any) => (
      (this.DataType == 'RD' || this.DataType == 'PC' ? ele.rdname : ele.pcname).toUpperCase().indexOf(value.toUpperCase()) !== -1 || (this.DataType == 'RD' || this.DataType == 'PC' ? ele.rdcode : ele.pcuid).toString().toUpperCase().indexOf(value.toUpperCase()) !== -1));
  }


  btnClick() {
    this.mds.UpdateHealthStatusData(this.statusobj, this.token, this.device, this.deviceType, 'Field Status Update').subscribe(res => {
      this.notifyMeService.showSuccess('Records Save Sucessfully', 'Sales Genie App');
      if (this.DataType == 'PCL') {
        this.router.navigate(['/Healthstatuslist/', this.OriginalFieldName, this.OriginalFieldValue, "0", 'PC', this.UserID]);
      } else {
        this.router.navigate(['/Healthstatus/']);
      }
    });
  }
  StatusChange(item: any, e: number): void {
    let index = this.statusobj.indexOf(item);
    this.statusobj[index].status = e;
  }
  loaddata(): void {
    this.input.FieldName = this.FieldName;
    this.input.FieldValue = this.FieldValue;
    this.input.TagName = '0';
    this.input.Datatype = this.DataType == 'PC' ? 'RDLIST' : this.DataType;
    this.input.UserID = this.UserID;
    this.mds.GetHealthStatusData(this.input, this.token, this.device, this.deviceType, "Field Status").subscribe(res => {
      this.statusobj = new Array<statusreport>();
      debugger;
      //this.list=this.listOrigin= res;
      for (let obj of res) {
        this.statusobj.push({
          tagname: 'healthstatus', fieldname: this.OriginalFieldName, fieldvalue: this.OriginalFieldValue,
          branch: '', region: '', asmarea: this.asmarea,
          soterritory: obj.fieldname.toUpperCase() == 'SOTERRITORY' ? obj.fieldvalue : obj.town,
          rdcode: obj.fieldname.toUpperCase() == 'RD' ? Number(obj.fieldvalue) : this.FieldName.toUpperCase() == 'RD' ? Number(this.FieldValue) : 0,
          rdname: obj.fieldname.toUpperCase() == 'RD' ? obj.desc : this.FieldName.toUpperCase() == 'RD' ? this.Paraname : '',
          rdtype: obj.rdtype,
          pcuid: obj.fieldname.toUpperCase() == 'PC' ? Number(obj.fieldvalue) : 0,
          pccode: obj.isupdated,
          pcname: obj.fieldname.toUpperCase() == 'PC' ? obj.desc : ''
          , code: this.DataType == 'PCL' ? 'PC' : this.DataType, codename: obj.fieldname
          , status: obj.isupdated == 'Y' ? obj.status : this.defaultchk == 'first' ? 0 : 1
        })
      }
      this.list = this.listOrigin = this.statusobj;
    });
  }
  getreasondata(): void {
    this.input.FieldName = this.FieldName;
    this.input.FieldValue = this.FieldValue;
    this.input.TagName = 'pflag';
    this.input.Datatype = '0';
    this.input.UserID = this.UserID;
    this.mds.GetReasonList(this.input, this.token, this.device, this.deviceType, "Field Status").subscribe(res => {
      this.reasonlist = res;
      this.firstchk = res[0].reason;
      this.secondchk = res[1].reason;
      this.defaultchk = res[0].reason == 'Y' ? 'first' : 'second';
    });
  }
}
