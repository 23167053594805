<!-- <div class="container-fluid header">
  <div class="example-container" [class.example-is-mobile]="mobileQuery.matches">
    <mat-sidenav-container class="example-sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
      <mat-sidenav #snav [mode]="mobileQuery.matches ? 'side' : 'side'" [fixedInViewport]="mobileQuery.matches">
        <div class="header">
          <div class="row">
            <div class="col-12 text-right">
              <i class="mt-1 p-1 fa fa-chevron-right fa-lg" (click)="snav.toggle()" style="color: white;"
                aria-hidden="true"></i>

            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <!-- <i class="p-1 fa fa-user-circle fa-4x" style="color: white;" aria-hidden="true"></i>
              <img class="img-fluid" src="assets/profile_icon.png"/>
            </div>
            <div class="col-9">
              <div class="row mt-1">
                <div class="col-12 text-center" style="color: #ffffff">{{title}}</div>
              </div>
              <div class="row">
                <div class="col-12 text-center">
                  <a class="view_Profile" routerLink="/home/profile" (click)="snav.toggle()">View Profile</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <mat-nav-list>
          <a mat-list-item routerLink={{nav.link}} *ngFor="let nav of fillerOtherNav ; let i = index">
            <mat-icon mat-list-icon>home</mat-icon>
            {{nav.name}}
          </a>
        </mat-nav-list>
      </mat-sidenav>
    </mat-sidenav-container>
  </div>
  <nav class="navbar navbar-expand-lg navbar-light bg_custom">
    <!-- <i class="fa fa-user-circle fa-2x" (click)="snav.toggle()" *ngIf="activeURl == '/home'" style="color: white;" aria-hidden="true"></i>
    <img class="img_fluid_custom" src="assets/profile_icon.png" (click)="snav.toggle()" *ngIf="activeURl == '/home'"/>
    <i class="mt-1 p-1 fa fa-chevron-left fa-lg" (click)="goBack()" *ngIf="activeURl != '/home'" style="color: white;" aria-hidden="true"></i>
    <span class="title_text">{{title}}</span>
    <span>

    <i class="fa fa-bell fa-lg"  style="color: white;" aria-hidden="true" (click)="Gonotification()" ></i>&nbsp;&nbsp;
      <i class="fa fa-refresh fa-lg" *ngIf="activeURl != '/profile'" style="color: white;" aria-hidden="true"></i>
      <!-- <i class="fa fa-bell fa-lg" *ngIf="activeURl != '/profile'" style="color: white;" aria-hidden="true"></i>&nbsp;&nbsp;
      <i class="fa fa-refresh fa-lg" *ngIf="activeURl != '/profile'" style="color: white;" aria-hidden="true"></i>
      <img class="img_icon" src="assets/header_icon.png" />&nbsp;&nbsp;
      <img class="img_icon" src="assets/notification.png"  (click)="notificationform()"/>

    </span>
  </nav>
</div> -->

<div class="container-fluid header">
  <div class="example-container" [class.example-is-mobile]="mobileQuery.matches">
    <mat-sidenav-container class="example-sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
      <mat-sidenav #snav [mode]="mobileQuery.matches ? 'side' : 'side'" [fixedInViewport]="mobileQuery.matches">
        <div class="header">
          <div class="row">
            <div class="col-12 text-right">
              <i class="mt-1 p-2 fa fa-chevron-right fa-lg" (click)="snav.toggle()" style="color: white;"
                aria-hidden="true"></i>

            </div>
          </div>
          <div class="row">
            <div class="col-12 mt-3">
              <!-- <i class="p-1 fa fa-user-circle fa-4x" style="color: white;" aria-hidden="true"></i> -->
              <img class="img-fluid" style="margin-left: 1.25rem;" src="assets/profile_Image_SideNav.svg"
                *ngIf="!isImage"/>
              <img class="img-fluid user_Profile_SideNav" style="margin-left: 1.25rem;" [src]="image" *ngIf="isImage" />
            </div>
            <div class="col-12 mt-2 userName">{{UserName}}</div>
            <div class="col-12 mt-1 userType">{{userType | uppercase}}</div>
            <div class="col-12 mt-2" style="margin-bottom: 0.75rem;">
              <a class="view_Profile" style="margin-left: 1.25rem;" routerLink="/home/profile"
                (click)="snav.toggle()">View Profile</a>
            </div>
          </div>
        </div>
        <div class="row">&nbsp;</div>
        <mat-nav-list>
          <a mat-list-item routerLink={{nav.link}} *ngFor="let nav of fillerOtherNav ; let i = index"
            (click)="sideNavClick(nav.name); snav.toggle()">
            <!-- <mat-icon mat-list-icon>home</mat-icon> -->
            <img class="img-fluid imgSideNavIcons my-icon-margin" src={{nav.path}} />
            <span class="nav_text">{{nav.name}}</span>
          </a>
        </mat-nav-list>
      </mat-sidenav>
    </mat-sidenav-container>
  </div>
  <nav class="navbar navbar-expand-lg navbar-light bg_custom">
    <img class="ml-3 img_fluid_custom" src="assets/profile_Image_Dashboard.svg" (click)="snav.toggle()"
      *ngIf="activeURl == '/home' && !isImage" />
    <img class="ml-3 img_fluid_custom user_Profile" [src]="image" (click)="snav.toggle()"
      *ngIf="activeURl == '/home' && isImage" />
    <!-- <i class="ml-2 mt-1 p-1 fa fa-chevron-left fa-lg" (click)="goBack()" *ngIf="activeURl != '/home'" style="color: white;" aria-hidden="true"></i> -->
    <img class="ml-2 img-fluid" style="opacity: 0.74;" src="assets/back_icon.svg" (click)="goBack()"
      *ngIf="activeURl != '/home' && activeURl != '/Healthstaus' && activeURl!='/outletNameChangeRequestVerifyOtp'" />

    <span class="title_text ml-2">{{title}}</span>
    <span class="example-spacer"></span>
    <span class="text-right"
      *ngIf="activeURl != '/a2bTransactions' && activeURl != '/Healthstaus' && activeURl != '/HealthstauslistPCL' && activeURl != '/HealthstauslistPC'">
      <!-- <button data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="QrCodeClick()">scan</button> -->
      <img class="img_icon" src="assets/header_icon_new.png" (click)="Gopraise()" />&nbsp;&nbsp;
      <img class="img_icon mr-3" src="assets/notification_new.png" (click)="Gonotification()" />
      <img class="notificationcountcss" src="assets/icons/notificationcount.svg" (click)="Gonotification()"
        *ngIf="IsShowNotification==true" />

    </span>


    <!-- <div class="row">
      <div class="col-3">
        <img class="img_fluid_custom" src="assets/profile_icon.png" (click)="snav.toggle()" *ngIf="activeURl == '/home'"/>
    <i class="mt-1 p-1 fa fa-chevron-left fa-lg" (click)="goBack()" *ngIf="activeURl != '/home'" style="color: white;" aria-hidden="true"></i>
      </div>
      <div class="col-5">
        <span class="title_text ml-3">{{title}}</span>
      </div>
      <div class="col-4">
        <img class="img_icon" src="assets/header_icon_new.png" />&nbsp;&nbsp;
        <img class="img_icon" src="assets/notification_new.png"  (click)="Gonotification()" />
      </div>
      </div> -->

  </nav>

    <!-- <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" *ngIf="openQrCode==true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <app-qr-code></app-qr-code>
          </div>
        </div>
      </div>
    </div> -->
</div>