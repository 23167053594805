<div>
    <div class="purpleColor row p-0">
        <div class="row mx-auto">
            <div class="col-12 mt-3 text-center">
                <span class="rdheading">{{rdListData.rdname}}</span>
                <span class="ml-1 rdcode">{{rdListData.rdcode}}</span>
            </div>
        </div>

        <div class="row mx-auto">
            <div class="col-12 text-center mt-3 ">
                <span class="rdstatusOnTrack"> <img src="../../../assets/onTrack.png" class="img-fluid" />&nbsp;
                    {{rdListData.achstatus | titlecase}}</span>
            </div>
        </div>

        <div class="row mx-auto" *ngIf="showSpeedometer">
            <kendo-radialgauge [pointer]="{ value: value}">
                <kendo-radialgauge-scale [minorUnit]="minorValue" [majorUnit]="majorValue" [max]="maxValue"
                    [rangeSize]="rangeSize" [color]="'white'" [majorTicks]="{color: ticksColor }"
                    [minorTicks]="{ color: ticksColor }" [startAngle]="startAngle" [endAngle]="endAngle">
                    <kendo-radialgauge-scale-labels [color]="'white'"></kendo-radialgauge-scale-labels>
                    <kendo-radialgauge-scale-ranges>
                        <kendo-radialgauge-scale-range *ngFor="let item of scaleRange" [from]="item.from" [to]="item.to"
                            [color]="item.color">
                        </kendo-radialgauge-scale-range>
                    </kendo-radialgauge-scale-ranges>
                </kendo-radialgauge-scale>

                <kendo-radialgauge-pointers>
                    <kendo-radialgauge-pointer *ngFor="let pointer of pointers" [value]="pointer.value"
                        [color]="pointer.color" [length]="pointer.length">
                    </kendo-radialgauge-pointer>
                </kendo-radialgauge-pointers>
            </kendo-radialgauge>
        </div>

        <div class="row mx-auto mt-2" style='width:26rem;height:4rem'>
            <div class="col-5">
                <!-- <span class="whiteColor p-2">{{rdListData.ach}} {{rdListData.unit}}</span> -->
                <span class="whiteColor p-2">{{rdListData.ach}}<span class="unit ml-1">{{rdListData.unit}}</span></span>
                <br>
                <span class="">
                    <img class="mr-1 p-0" src="assets/sec_dot.png" />
                    <span class="whiteColor p-0">Achievement</span>
                </span>
            </div>

            <div class="col-3 p-2 ">
                <span class="" style="color: white;font-size:20px ;">{{rdListData.percentage}}%</span>
            </div>
            <div class="col-3 p-0">
                <!-- <span class=" float-end whiteColor">{{rdListData.tgt}} {{rdListData.unit}}</span> -->
                <span class="float-end whiteColor">{{rdListData.tgt}}<span
                        class="unit ml-1">{{rdListData.unit}}</span></span>
                <br>
                <span class="float-end">
                    <img class="img-fluid mr-1" src="assets/pri_dot.png" />
                    <span class=" whiteColor">Target</span>
                </span>
            </div>
        </div>


        <div class="row mx-auto">
            <div class="col-12 text-center" (click)="clickOnViewPC()">
                <button class="btn btn_viewPC">View PC</button>
            </div>
        </div>

        <div class="row mt-4 mx-auto">
            <div class="col-4">
                <div class="card count">
                    <div class="card-body text-center">
                        <span class="rdheading">{{PCOutletCount.pccount}}</span><br>
                        <span class="rdcodecss">PC Count</span>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="card count">
                    <div class="card-body text-center">
                        <span class="rdheading">{{PCOutletCount.outletcount}}</span><br>
                        <span class="rdcodecss">RLA Count</span>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="card count">
                    <div class="card-body text-center">
                        <span class="rdheading">{{PCOutletCount.p7outletcount}}</span><br>
                        <span class="rdcodecss">P7 Count</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-4 mx-auto mb-4">
            <div class="col-4">
                <div class="card count">
                    <div class="card-body text-center">
                        <span class="rdheading">{{PCOutletCount.p5outletcount}}</span><br>
                        <span class="rdcodecss">P5 Count</span>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="card count">
                    <div class="card-body text-center">
                        <span class="rdheading">{{PCOutletCount.bbcount}}</span><br>
                        <span class="rdcodecss">BB Count</span>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="card count">
                    <div class="card-body text-center">
                        <span class="rdheading">{{PCOutletCount.wscount}}</span><br>
                        <span class="rdcodecss">WS Count</span>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div class="row mt-3 p-0 uv-100">
        <div class="col-12 p-0">
            <div id="content1" #content1>
                <mat-accordion>
                    <mat-expansion-panel (opened)="panelOpenState = true" [expanded]="true"
                        (closed)="panelOpenState = false">
                        <mat-expansion-panel-header class="acctitle">
                            <mat-panel-title>
                                Grow Performance
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="row mt-2">
                            <table class="table tbl_custom">
                                <thead>
                                    <tr>
                                        <td class="td_cust">Parameter</td>
                                        <td class="td_cust">Target</td>
                                        <td class="td_cust">Achievement</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let data of rdGrowData;let i = index">
                                        <td class="Value_text">{{data.paraname}}</td>
                                        <td class="Head_text">{{data.tgt}}</td>
                                        <td class="Head_text">{{data.ach}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </mat-expansion-panel>
                </mat-accordion>
            </div>
            <div id="content2" #content2>
                <mat-accordion>
                    <mat-expansion-panel class="mt-3" (opened)="panelOpenState = true" [expanded]="true"
                        (closed)="panelOpenState = false">
                        <mat-expansion-panel-header class="acctitle">
                            <mat-panel-title>
                                Growth Over LYSM
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="row mt-2">
                            <table class="table tbl_custom">
                                <thead>
                                    <tr>
                                        <td class="td_cust">Parameter</td>
                                        <td class="td_cust">{{previousPreviousPeriod}}({{GrowthOverLYSMUnit}})
                                        </td>
                                        <td class="td_cust">{{previousPeriod}}({{GrowthOverLYSMUnit}})
                                        </td>
                                        <td class="td_cust">Growth</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of GrowthOverLYSM">
                                        <td class="Value_text">
                                            {{item.fieldvalue==rdListData.rdcode?'Total':item.fieldvalue.toUpperCase()}}
                                        </td>
                                        <td class="Head_text">{{item.salespy}}</td>
                                        <td class="Head_text">{{item.salescy}}</td>
                                        <td class="Head_text">{{item.growth}}%</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </mat-expansion-panel>
                </mat-accordion>
            </div>
            <div id="content3" #content3>
                <mat-accordion>
                    <mat-expansion-panel class="mt-3" (opened)="panelOpenState = true" [expanded]="true"
                        (closed)="panelOpenState = false">
                        <mat-expansion-panel-header class="acctitle">
                            <mat-panel-title>
                                ULB
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="row mt-3">
                            <div class="col-6 td_cust">{{previousPreviousPeriod}}</div>
                            <div class="col-6 td_cust">{{previousPeriod}}</div>
                            <!-- <div class="col-4 td_cust">Gain</div> -->
                        </div>
                        <div class="row mt-3" *ngFor="let item of UlbList">
                            <div class="col-6"><span class="Value_text">{{item.cmulb}}</span></div>
                            <div class="col-6"><span class="Value_text">{{item.lmulb}}</span></div>
                            <!-- <div class="col-4"><span class="Value_text">{{item.ulbdiff}}</span></div> -->
                        </div>

                    </mat-expansion-panel>
                </mat-accordion>
            </div>
            <div id="content4" #content4>
                <mat-accordion>
                    <mat-expansion-panel class="mt-3" (opened)="panelOpenState = true" [expanded]="true"
                        (closed)="panelOpenState = false">
                        <mat-expansion-panel-header class="acctitle">
                            <mat-panel-title>
                                Earning Trend
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <div class="row mt-3" *ngFor="let item of EarningTrend">
                            <div class="col-4"><span class="Value_text">{{item.fieldvalue}}</span></div>
                            <div class="col-6 p-0" style="margin-top: .35rem;">
                                <div class="progress rounded-pill">
                                    <div class="progress-bar bg_sec" role="progressbar"
                                        [style.width.%]="item.totalincentive*45" aria-valuenow="25" aria-valuemin="0"
                                        aria-valuemax="10"></div>
                                </div>
                            </div>
                            <div class="col-2"><span class="Head_text">{{item.totalincentive}}%</span></div>
                        </div>

                    </mat-expansion-panel>
                </mat-accordion>
            </div>

            <div id="content5" #content5>
                <mat-accordion>
                    <mat-expansion-panel class="mt-3" (opened)="panelOpenState = true" [expanded]="true"
                        (closed)="panelOpenState = false">
                        <mat-expansion-panel-header class="acctitle">
                            <mat-panel-title>
                                Sales Parameter
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <div class="row mt-2">
                            <div class="col-12">

                            </div>
                            <table class="table tbl_custom">
                                <thead>
                                    <tr>
                                        <td class="td_cust">Category</td>
                                        <td class="td_cust">MTD
                                        </td>
                                        <td class="td_cust">L3M%
                                        </td>
                                        <td class="td_cust">LYSM%</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of getOutletSalesParameter">
                                        <td class="Value_text">{{item.category}}</td>
                                        <td class="Head_text">{{item.mtd}}</td>
                                        <td class="Head_text">{{item.l3m}}%</td>
                                        <td class="Head_text">{{item.lm}}%</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </mat-expansion-panel>
                </mat-accordion>
            </div>


            <div id="content6" #content6>
                <mat-accordion>
                    <mat-expansion-panel class="mt-3" (opened)="panelOpenState = true" [expanded]="true"
                        (closed)="panelOpenState = false">
                        <mat-expansion-panel-header class="acctitle">
                            <mat-panel-title>
                                Non Sales Parameters
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="row mt-2">
                            <table class="table tbl_custom">
                                <thead>
                                    <tr>
                                        <td class="Value_text">
                                            <span class="Value_text">Last Recorded Neev Score</span>
                                            <span
                                                class="Head_text float-end">{{getOutletNonSalesParameter.neevscore}}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="Value_text">
                                            <span class="Value_text">Cheque/NACH Failures(L3M%)</span>
                                            <span
                                                class="Head_text float-end">{{getOutletNonSalesParameter.checkbounceper}}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="Value_text">
                                            <span class="Value_text">Daily Sync(MTD%)</span>
                                            <span
                                                class="Head_text float-end">{{getOutletNonSalesParameter.dailysyncper}}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="Value_text">
                                            <span class="Value_text">RODS(L3M%)</span>
                                            <span
                                                class="Head_text float-end">{{getOutletNonSalesParameter.rodsper}}</span>
                                        </td>
                                    </tr>
                                    <!-- <tr>
                                    <td class="Value_text">
                                        <span class="Value_text">CRS Compliance(OON%)</span>
                                        <span class="Head_text float-end">50</span>
                                    </td>
                                </tr> -->
                                </thead>
                            </table>
                        </div>

                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>
    </div>
    <br><br><br><br>

</div>