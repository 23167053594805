<div class="row" style="margin-top: 2rem;">
  <div class="col-12">
    <span class="text_Class">Super Stockist Code</span>
  </div>
  <div class="col-12">
    <span class="value_Class">{{subRdApprovalDetails.rdName | titlecase}}</span>
    <span class="ml-1 value_Class">({{subRdApprovalDetails.rdCode}})</span>
  </div>
</div>
<hr>
<div class="row">
  <div class="col-12">
    <span class="text_Class">Sub Name</span>
  </div>
  <div class="col-12">
    <span class="value_Class">{{subRdApprovalDetails.outletName | titlecase}}</span>
  </div>
</div>
<hr>
<div class="row">
  <div class="col-12">
    <span class="text_Class">Address</span>
  </div>
  <div class="col-12">
    <span class="value_Class">{{subRdApprovalDetails.address | titlecase}}</span>
  </div>
</div>
<hr>
<div class="row">
  <div class="col-12">
    <span class="text_Class">City</span>
  </div>
  <div class="col-12">
    <span class="value_Class">{{subRdApprovalDetails.city | titlecase}}</span>
  </div>
</div>
<hr>
<div class="row">
  <div class="col-12">
    <span class="text_Class">Pin Code</span>
  </div>
  <div class="col-12">
    <span class="value_Class">{{subRdApprovalDetails.pin}}</span>
  </div>
</div>
<hr>
<div class="row">
  <div class="col-12">
    <span class="text_Class">Phone No</span>
  </div>
  <div class="col-12">
    <span class="value_Class">{{subRdApprovalDetails.phone}}</span>
  </div>
</div>
<hr>
<div class="row">
  <div class="col-12">
    <span class="text_Class">Contact Person</span>
  </div>
  <div class="col-12">
    <span class="value_Class">{{subRdApprovalDetails.contactperson | titlecase}}</span>
  </div>
</div>
<hr>
<div class="row">
  <div class="col-12">
    <span class="text_Class">IMEI No</span>
  </div>
  <div class="col-12">
    <span class="value_Class">{{subRdApprovalDetails.imeino}}</span>
  </div>
</div>
<hr>
<div class="row mt-3">
  <div class="col-12">
    <span class="Selfie_facing_text">Sub Details</span>
  </div>
  <div class="col-12 mt-3">
    <img [src]="photopath + 'SUB_' + subRdApprovalDetails.subid + '.jpg'"  class="img-fluid img-thumbnail Rounded Corners small" onerror="this.onerror=null;this.src='../assets/images/NoBlogImage.jpg'"/>
  </div>
</div>
<div class="row mt-3">
  <div class="col-12">
    <span class="Selfie_facing_text">Pan Details</span>
  </div>
  <div class="col-12 mt-3">
    <img [src]="photopath + 'PAN_' + subRdApprovalDetails.subid + '.jpg'"  class="img-fluid img-thumbnail Rounded Corners small" onerror="this.onerror=null;this.src='../assets/images/NoBlogImage.jpg'"/>
  </div>
</div>
<div class="row mt-3">
  <div class="col-12">
    <span class="Selfie_facing_text">Bank Details</span>
  </div>
  <div class="col-12 mt-3">
    <img [src]="photopath + 'BANK_' + subRdApprovalDetails.subid + '.jpg'"  class="img-fluid img-thumbnail Rounded Corners small" onerror="this.onerror=null;this.src='../assets/images/NoBlogImage.jpg'"/>
  </div>
</div>
<br>
<br>
<br>
<br>
<br>
<div class="footer">
  <div class="row text-center mt-3">
    <div class="col-12" (click)="back()">
      BACK
    </div>
  </div>
</div>


