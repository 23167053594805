<div>
  <div class="row mt-4">
    <div class="col-12">
      <span class="text_16 text_black">Outlet</span>
    </div>
    <div class="col-12 mt-1">
      <span class="text_14 text_black">{{outletSelectedB.outletname | titlecase}}</span>
      <span class="ml-1 text_14 text_black text_bold">{{outletSelectedB.outletcode}}</span>
    </div>
  </div>
  <hr>
  <div class="row">
    <div class="col-12">
      <span class="text_16 text_black">RD</span>
    </div>
    <div class="col-12 mt-1">
      <span class="text_14 text_black">{{outletSelectedB.rdname | titlecase}}</span>
      <span class="ml-1 text_14 text_black text_bold">{{outletSelectedB.rdcode}}</span>
    </div>
  </div>
  <hr>
  <div class="row">
    <div class="col-12">
      <span class="text_16 text_black">Mobile No</span>
    </div>
    <div class="col-12 mt-1">
      <span class="text_14 text_black">{{outletSelectedB.cellno}}</span>
    </div>
  </div>
  <hr>
  <div class="row">
    <div class="col-12">
      <span class="text_16 text_black">Address 01</span>
    </div>
    <div class="col-12 mt-1">
      <span class="text_14 text_black">{{outletSelectedB.address1 | titlecase}}</span>
    </div>
  </div>
  <hr>
  <div class="row">
    <div class="col-12">
      <span class="text_16 text_black">SO Territory</span>
    </div>
    <div class="col-12 mt-1">
      <span class="text_14 text_black">{{outletSelectedB.soterritory}}</span>
    </div>
  </div>
  <hr>
  <br>
  <br>
  <br>
  <br>
  <br>
</div>
<ng-template #openModalVerifiedOutlet let-modal id="test">
  <div class="modal-header justify-content-center">
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12 text-center">
        <span class="model_PopUp_Text text-center" style="font-size: 14px;
    font-weight: 500;">The selected outlet is NOT verified. Please finish the outlet verification as per Company
          Policy</span>
      </div>
    </div>
  </div>
  <div class="modal-footer text-center justify-content-center" (click)="okay()">
    <button type="button" class="btn okBtn">OK</button>
  </div>
</ng-template>

<div class="footer">
  <div class="row text-center mt-3">
    <div class="col-12" (click)="rowClick()">
      MOVE HERE
    </div>
  </div>
</div>