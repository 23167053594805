<div>
  <div class="row bg_color">
    <div class="col-12" style="margin-top: 1.25rem;">
      <span class="text_16 text_black">From Outlet</span>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-12">
      <span class="text_14 text_black">Outlet</span>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <span class="text_12 text_black">{{selectedAtoB.fromOutletName | titlecase}}</span>
    </div>
  </div>
  <hr>
  <div class="row mt-3">
    <div class="col-12">
      <span class="text_14 text_black">UID</span>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <span class="text_12 text_black">{{selectedAtoB.fromOutletCode}}</span>
    </div>
  </div>
  <hr>
  <div class="row mt-3">
    <div class="col-12">
      <span class="text_14 text_black">RD</span>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <span class="text_12 text_black">{{selectedAtoB.fromRDName | titlecase}} {{selectedAtoB.fromRDCode}}</span>
    </div>
  </div>
  <hr>
  <div class="row mt-3">
    <div class="col-12">
      <span class="text_14 text_black">Visicooler</span>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <span class="text_12 text_black">{{selectedAtoB.fromVisiName}}</span>
    </div>
  </div>
  <div class="row mt-3 bg_color">
    <div class="col-12" style="margin-top: .75rem!important;">
      <span class="text_16 text_black">To Outlet</span>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-12">
      <span class="text_14 text_black">Outlet</span>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <span class="text_12 text_black">{{selectedAtoB.toOutletName | titlecase}}</span>
    </div>
  </div>
  <hr>
  <div class="row mt-3">
    <div class="col-12">
      <span class="text_14 text_black">UID</span>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <span class="text_12 text_black">{{selectedAtoB.toOUtletCode}}</span>
    </div>
  </div>
  <hr>
  <div class="row mt-3">
    <div class="col-12">
      <span class="text_14 text_black">RD</span>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <span class="text_12 text_black">{{selectedAtoB.toRDName | titlecase}} {{selectedAtoB.toRDCode}}</span>
    </div>
  </div>
  <hr>
  <div class="row mt-3">
    <div class="col-12">
      <span class="text_14 text_black">Visicooler</span>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <span class="text_12 text_black">{{selectedAtoB.toVisiName}}</span>
    </div>
  </div>
  <div class="row mt-3 bg_color">
    <div class="col-12" style="margin-top: .75rem!important;">
      <span class="text_16 text_black">Documents</span>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-9 mt-3">
      <span class="text_14 text_black">Cooler Photo</span>
    </div>
    <div class="col-3">
      <img [src]="coolerPhoto"  class="img-fluid" />
    </div>
  </div>
  <hr>
  <div class="row">
    <div class="col-9 mt-3">
      <span class="text_14 text_black">Outlet Exterior Photo</span>
    </div>
    <div class="col-3">
      <img [src]="outletPhoto" class="img-fluid" />
    </div>
  </div>
  <hr>
  <div class="row">
    <div class="col-9 mt-3">
      <span class="text_14 text_black">Owner ID Proof</span>
    </div>
    <div class="col-3">
      <img [src]="outletIDPhoto" class="img-fluid" />
    </div>
  </div>
  <hr>
  <div class="row">
    <div class="col-9 mt-3">
      <span class="text_14 text_black" >E-Agreement</span>
    </div>
    <div class="col-3 text-center mt-3">
      <a class="img-fluid downloadDPNAgr" (click)="downloadDPNAgr()">Download E-Agreement</a>
    </div>
  </div>
  <!-- <hr> -->
  <!-- <div class="row">
    <div class="col-9 mt-3">
      <span class="text_14 text_black">Agreement Page 2</span>
    </div>
    <div class="col-3">
      <img [src]="pagePhoto2" class="img-fluid" />
    </div>
  </div> -->
  <hr>
  <br>
  <br>
  <br>
  <br>
  <br>
  <div class="footer">
    <div class="row">
      <div class="col-6 pr-0" (click)="Reject()">
        <button class="btn btn_Cancel">Reject</button>
      </div>
      <div class="col-6 pl-0" (click)="saveRecords()">
        <button class="btn btn_APPROVE">APPROVE</button>
      </div>
    </div>
  </div>
</div>
