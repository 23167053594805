import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HeaderService } from 'src/app/service/header.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  activeURl: string = '';
  constructor(private router: Router, private headerService: HeaderService, ) { }

  ngOnInit(): void {
    this.headerService.activeURl.subscribe((activeURl: string) => {
      this.activeURl = activeURl;
    });
  }

  onClickHome(){
    this.router.navigate(['/home']);
  }

  onClickApprovals(){
    this.router.navigate(['/home/Approvals']);
  }

  onClickTransactions(){
    this.router.navigate(['/home/transactions']);
  }

  onClickHelp(){
    this.router.navigate(['/home/ticketLogList']);
  }
}
