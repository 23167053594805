import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Result } from '@zxing/library';
import { QRCodeData } from 'src/app/models/kpi-models';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { SaveFirReason } from 'src/app/models/kpi-models';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgxImageCompressService } from 'ngx-image-compress';
import { isReturnStatement } from 'typescript';
import { DataService } from 'src/app/service/data.service';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-scan-qrcode',
  templateUrl: './scan-qrcode.component.html',
  styleUrls: ['./scan-qrcode.component.css']
})
export class ScanQRCodeComponent implements OnInit {
  deviceType: any;
  device: any;
  userType: string | null = '';
  userId: any;
  scannerEnabled: boolean = true;
  currentDevice: MediaDeviceInfo | undefined;
  guestExist: boolean = false;
  qrResult: any;
  output = "";
  public qrCodeData: QRCodeData = new QRCodeData();
  hasPermission: boolean = false;
  outletSelectedMV: any;
  isVisicooler: any;
  token: any;

  saveFirReason: SaveFirReason = new SaveFirReason();
  public FirStatus = "VisiFound";
  public slectedIndex = 1;

  lat: number = 0;
  lng: number = 0;
  firList: any;

  public config: Object = {
    isAuto: true,
    text: { font: '25px serif' }, // Hiden { font: '0px' },
    frame: { lineWidth: 8 },
    medias: {
      audio: false,
      video: {
        facingMode: 'environment', // To require the rear camera https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia
        width: { ideal: 1280 },
        height: { ideal: 720 }
      }
    }
  };

  public coolerPhoto: any = "";
  public outletPhoto: any = "";
  searchText = '';
  isLongPress: boolean = false;
  isSelected: boolean = false;
  isValid: boolean = false;
  outletSelectedA: any;
  imgURL: any;
  photourlstr: string = '';
  public progress: number = 0;
  public message: string = '';
  public mobileNo: string = "";
  public photoUploaded: any
  public submitted = false;

  public photos: any = {
    CoolerPhoto: 0,
    OutletPhoto: 0,
  }
  file: any;
  imgResultBeforeCompress: string = '';
  imgResultAfterCompress: string = '';
  localUrl: any;
  localCompressedURl: any;
  sizeOfOriginalImage: number = 0;
  sizeOFCompressedImage: number = 0;
  TokenNo: any;
  ClientID: any;
  outLetDetails: any;
  CoolerPhoto: any = "";
  OutletPhoto: any = "";
  qrCodeResp: any;
  httpOptions: HttpHeaders =
    new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'enctype': 'multipart/form-data',
      'charset': 'UTF-8',
      'FolderName': 'SGVisicoolerPhoto',
    })
  @ViewChild('openConfirmationBox', { static: true }) elRefopenConfirmationBox: ElementRef | undefined;
  formname:any;
  actiondesc:any;
  fieldName: any;
  fieldValue: any;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private headerService: HeaderService,
    private toastr: ToastrService, private mds: MongoDataService,private cs:CommonUtilitiesService,
    private notifyMeService: NotifyMeService, private modalService: NgbModal, private imageCompress: NgxImageCompressService
    , private http: HttpClient, private ds: DataService) { }

  ngOnInit(): void {
    this.headerService.setTitle('Scan the QR Code');
    this.headerService.setActiveURl('/scanQRCode');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.userType = localStorage.getItem("UserType");
    this.userId = localStorage.getItem("UserID");
    this.token = localStorage.getItem("TokenNo");
    this.outletSelectedMV = JSON.parse(localStorage.getItem("outletSelectedMV") || '{}');
    console.log("outletSelectedMV", this.outletSelectedMV);
    this.outLetDetails = JSON.parse(localStorage.getItem("outLetDetails") || '{}');
    this.isVisicooler = JSON.parse(localStorage.getItem("isVisicooler") || '{}');
    console.log("isVisicooler", this.isVisicooler);

    this.fieldName = localStorage.getItem("FieldName");
    this.fieldValue = localStorage.getItem("FieldValue");
    this.formname = "scanQRCode"
    this.actiondesc = "Scan QR Code";
    // this.cs.GetGeoLocation(this.userId, this.userType, this.formname, this.actiondesc, this.fieldName, this.fieldValue);
    this.GetGeoLocation()
    // this.startMeeting();
  }

  // startMeeting(): void {
  //   debugger
  //   this.qrCodeData.Latitude = 0;
  //   this.qrCodeData.Longitude = 0;
  //   if (navigator) {
  //     navigator.geolocation.getCurrentPosition(pos => {
  //       this.qrCodeData.Latitude = pos.coords.longitude;
  //       this.qrCodeData.Longitude = pos.coords.latitude;
  //     }, this.handleLocationError, { timeout: 30000 });
  //   }
  //   else {
  //     this.notifyMeService.showError('Location Could not be captured', 'Mera APP');
  //   }
  // }

  GetGeoLocation() {
    if (navigator) {
      navigator.geolocation.getCurrentPosition(pos => {
        this.lat = pos.coords.latitude;
        this.lng = pos.coords.longitude;
        this.qrCodeData.Latitude = pos.coords.latitude;
        this.qrCodeData.Longitude = pos.coords.longitude;
        this.SetapplicationLogData();
      }, this.handleLocationError, { timeout: 30000 });
    }
    else {
      this.notifyMeService.showError('Location Could not be captured', 'Mera APP');
    }
  }

  
  SetapplicationLogData() {
    debugger
    let request = {
      "userid": this.userId,
      "usertype": this.userType,
      "formname": this.formname,
      "actiondesc": this.actiondesc,
      "fieldname": this.fieldName,
      "fieldvalue": this.fieldValue,
      "latitude": this.lat,
      "longitude": this.lng
    }
    this.ds.SetapplicationLogData(request).subscribe(res => {
      console.log("res", res)
    })
  }

  handleLocationError(error: { code: any; }) {
    debugger
    switch (error.code) {
      case 3:
        this.notifyMeService.showError('Timeout in Position capture', 'Sales Genie App');
        break;
      case 2:
        this.notifyMeService.showError('Device is not capable of Position capture', 'Sales Genie App');
        break;
      case 1:
        this.notifyMeService.showError('You denied for Position capture', 'Sales Genie App');
    }
  }

  startQrcodeScanner() {
    this.scannerEnabled = true;
  }

  onCodeResult(resultString: string): void {
    // this.guestExist = null;
    if (resultString.length > 0) {
      //if (this.checkQRJSON(resultString)) {
      //this.qrResult = JSON.parse(resultString);
      this.qrResult = resultString;
      this.qrCodeData.QRCode = this.output;
      this.scannerEnabled = false;
      //this.checkInGuest(this.qrResult);
      //this.clearMessage();
    } else {
      this.guestExist = false;
      this.clearMessage();
    }
  }

  checkQRJSON(qrString: string): boolean {
    if (
      /^[\],:{​​​​​​​}​​​​​​​\s]*$/.test(
        qrString
          .replace(/\\["\\\/bfnrtu]/g, "@")
          .replace(
            /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
            "]"
          )
          .replace(/(?:^|:|,)(?:\s*\[)+/g, "")
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

  clearMessage() {
    setTimeout(() => {
      this.guestExist = false;
      this.qrCodeData = new QRCodeData();
    }, 3000);
  }

  //Permission for the app to use the device camera
  onHasPermission(has: any): void {
    this.hasPermission = has;
  }

  saveRecords() {
    debugger

    if (this.output == '') {
      this.toastr.warning("Please scan QR code");
      return;
    }
    if (this.CoolerPhoto == '') {
      document.getElementById("photo")?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
      this.toastr.warning("Please upload cooler photo");
      return;
    }
    if (this.OutletPhoto == '') {
      // document.getElementById("photo")?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
      document.getElementById("outletExterior")?.scrollIntoView({ behavior: "smooth", inline: "nearest" });
      this.toastr.warning("Please upload outlet exterior photo");
      return;
    }

    if (this.output != '') {
      this.qrCodeData.OutletCode = this.outletSelectedMV.outletcode;
      this.qrCodeData.UserID = this.userId;
      this.qrCodeData.VisiCooler = this.outletSelectedMV.visicoolertype;
      this.qrCodeData.IsWorking = this.isVisicooler;
      this.qrCodeData.OutletCoderd = this.outletSelectedMV.outletCodeRD;
      this.qrCodeData.RDCOde = this.outletSelectedMV.rdCode;
      this.qrCodeData.QRCode = this.output;
      this.qrCodeData.Latitude = this.qrCodeData.Latitude;
      this.qrCodeData.Longitude = this.qrCodeData.Longitude;
      this.qrCodeData.coolerphoto = this.CoolerPhoto;
      this.qrCodeData.exteriorphoto = this.OutletPhoto;
      console.log("qrCodeData", this.qrCodeData);

      // this.foundVisiCooler();

      // let request: any = {
      //   "qrcode": this.qrCodeData.QRCode,
      //   "userId": this.userId
      // }

      // this.mds.isvalidqrcode(request, this.token, this.userType, this.device, this.deviceType, 'Scan the QR Code').subscribe(data => {
      //   this.qrCodeResp = data;
      //   if (data.result.toLowerCase() == "ok") {
          this.mds.saveQRCodeScanningData(this.qrCodeData, this.token, this.device, this.deviceType, 'Scan the QR Code').subscribe(data => {
            console.log("data", data);
            if (data[0].msg.toLowerCase() == "ok") {
              this.toastr.success("QR code scan sucessfully");
              this.foundVisiCooler();
              // this.router.navigate(['/home/missingVisicooler']);
            }
            else {
              this.toastr.warning(data[0].msg);
              this.deleteImage(this.qrCodeData.coolerphoto)
              this.deleteImage(this.qrCodeData.exteriorphoto);
              this.output = "";
              this.coolerPhoto = "";
              this.outletPhoto = "";
              return;
            }
          // });
        // }
        // else {
        //   this.toastr.warning(data.result);
        //   this.deleteImage(this.qrCodeData.coolerphoto)
        //   this.deleteImage(this.qrCodeData.exteriorphoto);
        //   this.output = "";
        //   this.coolerPhoto = "";
        //   this.outletPhoto = "";
        //   return;
        // }
      });
    }
    else {
      this.openModal(this.elRefopenConfirmationBox);
    }
  }

  openModal(modal: ElementRef<any> | undefined) {
    this.modalService.open(modal,
      {
        size: 'lg',
        backdrop: 'static',
        windowClass: '',
        keyboard: false,
        centered: true,
      })
  }
  modalClose() {
    this.modalService.dismissAll();

  }

  deleteImage(filename: any) {
    debugger
    const dataToSend = {
      filename: filename,
      folderName: "SGVisicoolerPhoto",
    };
    this.ds.deleteFileFromS3Bucket(dataToSend, this.token, this.device, this.deviceType, 'Scan QR code').subscribe((res: any) => {
      console.log("deleteFileFromS3Bucket", res);
    });
  }

  foundVisiCooler() {
    this.saveFirReason.UserId = this.userId;
    this.saveFirReason.FIRID = this.outletSelectedMV.firid;
    this.saveFirReason.OutletUID = this.outletSelectedMV.outletcode;
    this.saveFirReason.FirStatus = this.FirStatus;
    this.saveFirReason.ReasonID = this.slectedIndex;
    this.saveFirReason.RDCode = this.outletSelectedMV.rdCode;
    this.saveFirReason.Visicooler = this.outletSelectedMV.visicoolertype;
    this.saveFirReason.latitude = this.lat;
    this.saveFirReason.longitude = this.lng;
    console.log("saveFirReason", this.saveFirReason);
    this.mds.saveFIRRequest(this.saveFirReason, this.token, this.device, this.deviceType, 'Missing Visicooler').subscribe(res => {
      console.log("saveFIRRequest", res);
      this.firList = res;
      if (res[0]?.msg.toLowerCase() == 'ok') {
        // this.notifyMeService.showSuccess('Records Updated Sucessfully', 'Mera APP');
        this.toastr.success("Record updated sucessfully");
        this.router.navigate(['/home/missingVisicooler']);
      }
    });
  }

  clearResult(): void {
    this.qrResult = null;
  }

  public frameConfig: Object = {
    lineWidth: 4,
    strokeStyle: 'red'
  };
  public textConfig: Object = {
    font: 'bold 18px serif',
    fillStyle: 'red'
  };


  public onError(e: any): void {
    debugger
    alert(e);
  }

  public qrCodeResult(result: any): void {
    if (result) {
      this.output = result;
      (<HTMLInputElement>document.getElementsByClassName("btn_Scan")[0]).click();
      return;
    }
  }

  okay() {
    this.router.navigate(['/home/missingVisicooler']);
    this.modalClose();
  }

  cancle() {
    this.toastr.warning("Please scan QR code", "Warning");
    this.modalClose();
  }

  fileProgress(fileInput: any, photoUrl: string) {
    debugger
    this.photos[photoUrl] += 1;

    this.imgURL = <File>fileInput.target.files[0];

    if (fileInput.target.files.length === 0) {
      return;
    }


    let fileToUpload = <File>fileInput.target.files[0];
    this.photoUploaded = fileToUpload

    if (fileToUpload.name.toLowerCase().lastIndexOf('.jpg') == -1 && fileToUpload.name.toLowerCase().lastIndexOf('.jpeg') == -1) {
      console.log("Other file format...");
      this.notifyMeService.showWarning("Please upload jpg or jpeg format only", 'Sales Genie');
      return;
    }

    this.imgURL = fileToUpload;
    const formData = new FormData();
    this.loaddatatoMemberVariable(fileToUpload.name, photoUrl);

    var fileName: any;
    this.file = fileInput.target.files[0];
    fileName = this.file['name'];
    if (fileInput.target.files && fileInput.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.localUrl = event.target.result;
        var orientation = -1;
        this.sizeOfOriginalImage = this.imageCompress.byteCount(this.localUrl) / (1024 * 1024);

        this.imageCompress.compressFile(this.localUrl, orientation, 50, 50).then(result => {
          this.imgResultAfterCompress = result;
          this.localCompressedURl = result;
          this.sizeOFCompressedImage = this.imageCompress.byteCount(result) / (1024 * 1024)
          // create file from byte
          const imageName = fileName;
          // call method that creates a blob from dataUri
          const imageBlob = this.dataURItoBlob(this.imgResultAfterCompress.split(',')[1]);
          //imageFile created below is the new compressed file which can be send to API in form data
          const imageFile = new File([imageBlob], imageName, { type: 'image/jpeg' });

          formData.append('file', imageFile, this.photourlstr);

          this.http.post(this.mds.url + 'MeraNet/UploadFileToS3Bucket', formData, { reportProgress: true, observe: 'events', headers: this.httpOptions, responseType: 'blob' as 'json' }).subscribe(res => {
            console.log("ATOBUploadFile", res)
            this.preview(photoUrl);
          },
            error => { console.log("error", error) });
        });
      }
      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

  dataURItoBlob(dataURI: string) {
    debugger
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }

  loaddatatoMemberVariable(filename: string, photoUrl: string) {
    debugger
    switch (photoUrl) {
      case 'CoolerPhoto':
        this.photourlstr = this.outLetDetails[0]?.outletCode.toString() + "_cp" + filename.substring(filename.indexOf('.'));
        this.CoolerPhoto = this.photourlstr;
        break;
      case 'OutletPhoto':
        this.photourlstr = this.outLetDetails[0]?.outletCode.toString() + "_ep" + filename.substring(filename.indexOf('.'));
        this.OutletPhoto = this.photourlstr;
        break;
    }

    if (this.CoolerPhoto != '' && this.OutletPhoto != '') {
      this.isValid = true;
    }
  }

  preview(photoUrl: string) {
    debugger
    var mimeType = this.imgURL.type;
    if (mimeType?.match(/image\/*/) == null) {
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(this.imgURL);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
      switch (photoUrl) {
        case 'CoolerPhoto':
          this.coolerPhoto = this.imgURL;
          break;
        case 'OutletPhoto':
          this.outletPhoto = this.imgURL;
          break;

      }
    }
  }

}