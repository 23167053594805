<div class="container-fluid">
    <div class="row mt-4">
        <div class="col-9">
            <span class="header_text">{{FieldValue=='0'? 'All India' : FieldValue=='ALLINDIA' ? 'All India': FieldValue |titlecase}}</span>
        </div>
        <div class="col-3 text-right">
            <img src="../../../assets/filter.png" class="img-fluid" (click)="openFilter()" /><sup><i
                    class="fa fa-circle fa-sm" style="color: #eaab5e;font-size: 5px;"></i></sup>&nbsp;<span
                style="color: #724e8c;font-family: Roboto-Medium;font-weight:400 ;font-size: 14px;">Filter</span>
        </div>
    </div>
    <br>
    <div class="row mt-2">
        <div class="col-4">
            <!-- <select class="form-control rounded-pill Period_Cust" id="period"  (change)="PeriodChange($event)"   >
                <option value="" disabled>Select Month</option>
                <option *ngFor="let item of periodList" [value]="item">
                {{ item }}
                </option>
            </select> -->
            <mat-form-field appearance="outline" style="width: 100px">
                <!-- <mat-label>Period</mat-label> -->
                <mat-select id="period" name="period" (selectionChange)="PeriodChange($event)" [(value)]="selectedperiod">
                    <mat-option value="" disabled>Select Month</mat-option>
                    <mat-option *ngFor="let item of periodList" [value]="item">
                        {{ item }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-8 text-right">

            <i class="fa fa-circle fa-sm" style="color: #eaab5e; font-size: 6px;">&nbsp;&nbsp;<span
                    class="Secondary_text">Billed RLA</span></i>&nbsp;&nbsp;

            <i class="fa fa-circle fa-sm" style="color: #f4d5ae; font-size: 6px;">&nbsp;&nbsp;<span
                    class="Secondary_text">Total RLA</span></i>
        </div>
    </div>
    <br>
    <div style="overflow: auto;">
        <div class="row mt-2" *ngFor="let item of filterItemsOfType(effectiveCoverage)"
            (click)="RowClick(item.valuetype)">
            <div class="col-12">
                <div class="row">
                    <div class="col-12">
                        <span class="header_text">{{item.desc + ' ' + item.valuetype|titlecase}}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <div class="row mt-2">
                            <div class="col-12">
                                <span class="Value_text">{{item.percentage}}%</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-9">
                        <div class="row mt-2">
                            <div class="col-12">
                                <div class="progress rounded-pill">
                                    <div class="progress-bar bg_sec" role="progressbar" [style.width.%]="item.billedrla"
                                        aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                    <div class="progress-bar bg_pri" role="progressbar" [style.width.%]="item.totrla"
                                        aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-6 text-center">
                                <span class="text_14">{{item.billedrla}}</span>
                            </div>
                            <div class="col-6 text-center">
                                <span class="text_14">{{item.totrla}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <hr class="hr_cust">
        </div>
    </div>
</div>
