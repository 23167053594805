<div class="container-fluid">
 <div  *ngFor ="let item of OutletDtl">
    <div class="row mt-4">
      <div class="col-12">
        <span class="text_16 text_black">Name</span>
      </div>
      <div class="col-12 ">
        <span class="text_12 text_black">{{item.outletName}} {{item.outletCode}}</span>

      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-12">
        <span class="text_16 text_black">RD</span>
      </div>
      <div class="col-12">
        <span class="text_12 text_black">{{item.rdName}} {{item.rdCode}}</span>

      </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-12">
          <span class="text_16 text_black">Location</span>
        </div>
        <div class="col-12">
          <span class="text_12 text_black">{{item.town}}</span>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-6">
          <span class="text_16 text_black">Suggested Visicooler</span><br />
          <span class="text_12 text_black">{{item.suggVisicooler}}</span>
        </div>
        <div class="col-6 text-right"  *ngIf="UserType!='RCCM' && item.soApprovalStatus=='PENDING'" >

            <img src="assets/icons/ATSIcons/edit.png" class="small" (click)="showVisiDialog()"/><span class="txtsmall" (click)="showVisiDialog()"> Edit </span>

        </div>
      </div>
      <div class="row" *ngIf="(item.suggVisicooler == '200L/340L' || item.suggVisicooler == '200L/340L/Pzone') && (NewVISI == '')">
        <div class="col-12">
        <span class="text_12 text-danger">Please Edit Suggested Visicooler</span>
        </div>
      </div>

    <hr>
      <div class="row" *ngIf="NewVISI != ''">
        <div class="col-12">
          <span class="text_16 text_black">Approved Visicooler</span><br />
          <span class="text_12 text_black">{{NewVISI}}</span>
        </div>
      </div>

    <hr *ngIf="NewVISI != ''">
    <div class="row">
      <div class="col-12">
        <span class="text_16 text_black">L3MA</span>
      </div>
      <div class="col-12">
        <span class="text_12 text_black">{{item.ltma}}</span>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-12">
        <span class="text_16 text_black">Priority Level</span>
      </div>
      <div class="col-12">
        <span class="text_12 text_black">{{item.priorityLevel}}</span>
      </div>
    </div>
    <hr>

    <div class="row">
      <div class="col-12">
        <span class="text_16 text_black">Recommend By PC</span>
      </div>
      <div class="col-12">
        <span class="text_12 text_black">{{item.recommendByPC}}</span>
      </div>
    </div>
    
    <hr>
    <div class="row" *ngIf="item.newVisicooler!='' && ReasonRequired">
      <div class="col-12">
        <div class="form-group">
          <label for="sel1" class="text_16 text_black">Reason:</label>
          <select class="form-control" id="sel1" (change)="changeReason($event)" [disabled]="item.reasonID!=''">
            <option value="-1">Select</option>
            <option *ngFor="let cate1 of reasonlist" [value]="cate1.reasonID" [selected]="cate1.reasonID==item.reasonID">{{cate1.reasonName}}</option>
          </select>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="item.soApprovalStatus!='PENDING'">
      <hr>
      <div class="col-12">
        <span class="text_16 text_black">Date & time of Approval SO Request</span><br />

        <span class="text_12 text_black">{{item.soApprovalDate}}</span>
        <hr />
      </div>
    </div>

      <div class="row" *ngIf="item.asmApprovalStatus!='PENDING'">
        <hr>
        <div class="col-12">
          <span class="text_16 text_black">Date & time of Approval ASM Request</span><br />
          <span class="text_14 text_black">{{item.asmApprovalDate}}</span>
          <hr />
        </div>
      </div>

      <div [class]="IsSaved ? 'visible':'invisible' ">
        <label class="text-success">
          {{msg}}
        </label>
      </div>
      <br /><br />

    <hr>
    <br>
    <br>
    <br>
    <br>
    <br>
  </div>
</div>
  <div class="footer" *ngIf="(suggVisicooler == '200L/340L' || suggVisicooler == '200L/340L/Pzone') && (NewVISI != '')">
    <div class="row">
      <div class="col-12 text-center "  (click)="SaveRecords()" >
       APPROVE
      </div>
    </div>
  </div>
  <div class="footer" *ngIf="(suggVisicooler != '200L/340L' && suggVisicooler != '200L/340L/Pzone')">
    <div class="row">
      <div class="col-12 text-center "  (click)="SaveRecords()" >
       APPROVE
      </div>
    </div>
  </div>

 <div class="modal" id="myModal" [style.display]="showModal ? 'block' : 'none'">
  <div class="modal-dialog  modal-dialog-scrollable">
    <div class="modal-content">

      <div class="modal-header">
        <span class="title">Suggested Visicooler</span>
        <button type="button" class="close" data-dismiss="modal" (click)="hide()">&times;</button>
      </div>

      <div class="modal-body">
        <div *ngFor="let cate2 of visiList; let i = index;">
          <div class="form-check">
          <span class="chkbox">
              <input type="checkbox" class="form-check-input" [value]="cate2.reasonID + ':' + cate2.reasonName" (change)="selected = i; OnChangeVISI(cate2.reasonID,cate2.reasonName)" [checked]="selected === i">
              <label class="form-check-label" style="margin-top: .15rem;" >{{cate2.reasonName}}</label>
              </span>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-6" (click)="onNoClick()">
            <button class="btn btn_Cancel">Cancel</button>
          </div>
          <div class="col-6" (click)="onYesClick()">
            <button class="btn btn_Apply">Apply</button>
          </div>
        </div>
        <br>
        <br>
        <br>
      </div>
    </div>
  </div>
</div>

<ng-template #openModalThankYou let-modal>
  <div class="modal-header">
    <h4 class="modal-title text_color_head text_20" id="modal-basic-title">
      <img class="img-fluid" src="assets/thanks_icon.png" />
      <span class="ml-3">Thank You</span>
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modalClose()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <span class="text_16">OIF Request against {{OutletCode}}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <span class="text_16">approved by {{ApprovalType}}</span>
      </div>
    </div>


  </div>

  <div class="modal-footer" (click)="modalClose()">
    CONTINUE
  </div>
</ng-template>
