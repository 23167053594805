import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { MatDialog } from '@angular/material/dialog';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { PcTsiEarnings, Resultdata, InputPara } from 'src/app/models/kpi-models';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterComponent } from '../filter/filter.component';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { DataService } from 'src/app/service/data.service';

@Component({
  selector: 'app-pc-earnings',
  templateUrl: './pc-earnings.component.html',
  styleUrls: ['./pc-earnings.component.css']
})
export class PcEarningsComponent implements OnInit {
  FieldName: any;
  FieldValue: any;
  ParaName: any;
  UserID: any;
  pcTsiEarnings: any;
  UserType: any;
  ParaValue: any;
  displayedColumns: string[] = ['paraName', 'totalincentive'];
  count: number = 0;
  resultdata: Resultdata = new Resultdata();
  IsRDValue: boolean = false;
  IsPCValue: boolean = false;
  device: any;
  deviceType: any;
  input: InputPara = new InputPara();
  token: any;
  lat:any;
  lng:any;

  constructor(private activatedRoute: ActivatedRoute, private notifyMeService: NotifyMeService,
    private fb: FormBuilder, private router: Router,private ds: DataService,private headerService: HeaderService, private mds: MongoDataService, private dialog: MatDialog) { }

  ngOnInit(): void {
    //this.headerService.setTitle("PC Earnings");
    this.headerService.setActiveURl('/PcEarnings');
    this.UserType = localStorage.getItem("UserType");
    this.device = localStorage.getItem('Device');
    this.deviceType = localStorage.getItem('DeviceType');
    this.token = localStorage.getItem("TokenNo");
    this.activatedRoute.paramMap.forEach(params => {
      this.FieldValue = params.get('FieldValue');
      this.FieldName = params.get('FieldName');
      this.UserID = params.get('UserID');
      this.ParaName = params.get('ParaName');
      if (this.ParaName == "PC") { this.headerService.setTitle("PC Earnings"); }
      else { this.headerService.setTitle("TSI Earnings"); }
      if (this.FieldName == "RD" || this.FieldName == "SOTERRITORY") { this.IsRDValue = true; }
      else { this.IsRDValue = false; }
      if (this.FieldName == "PC") { this.IsPCValue = true; }
      else { this.IsPCValue = false; }
      this.pcTsiEarnings = [];
      this.GetGeoLocation();
      this.loadData();
    });
  }


  GetGeoLocation() {
    debugger
    if (navigator) {
      navigator.geolocation.getCurrentPosition(pos => {
        this.lat = pos.coords.latitude;
        console.log("  this.lat", this.lat)
        this.lng = pos.coords.longitude;
        this.SetapplicationLogData();
      }, this.handleLocationError, { timeout: 30000 });
    }
    else {
      this.notifyMeService.showError('Location Could not be captured', 'Mera APP');
    }
  }

  handleLocationError(error: { code: any; }) {
    switch (error.code) {
      case 3:
        this.notifyMeService.showError('Timeout in Position capture', 'Mera APP V2');
        break;
      case 2:
        this.notifyMeService.showError('Device is not capable of Position capture', 'Mera APP V2');
        break;
      case 1:
        this.notifyMeService.showError('You denied for Position capture', 'Mera APP V2');
        break;
    }
  }

  SetapplicationLogData() {
    debugger
    let request = {
      "userid": this.UserID,
      "usertype": this.UserType,
      "formname": "PcEarnings",
      "actiondesc": "Pc Earnings" + " " + this.FieldName +" "+this.ParaName,
      "fieldname": this.FieldName,
      "fieldvalue": this.FieldValue,
      "latitude": this.lat,
      "longitude": this.lng
    }
    this.ds.SetapplicationLogData(request).subscribe(res => {
      console.log("res", res)
    })
  }

  loadData(): void {
    this.input.FieldName = this.FieldName;
    this.input.FieldValue = this.FieldValue;
    this.input.TagName = this.ParaName;
    this.input.Datatype = '0';
    this.input.UserID = this.UserID;

    this.mds.getPCtsiincentive(this.input, this.token, this.device, this.deviceType, 'PC Earning').subscribe(res => {

      this.pcTsiEarnings = res as PcTsiEarnings;
      console.log("pcTsiEarnings", this.pcTsiEarnings)
      for (let row of this.pcTsiEarnings) {
        this.ParaValue = row.fieldname;
        if (this.ParaName == "TSI" && this.ParaValue == "PC") {
          this.ParaValue = "TSI";
        }
        break;
      }
    });
  }

  RowClick(fName: any, fValue: any): void {

    if (this.FieldName.toUpperCase() != 'PC') {
      this.router.navigate(['/home/PcEarnings/', fName, fValue, this.ParaName, this.UserID]);
    }

  }

  openFilter() {
    if (this.dialog.openDialogs.length == 0) {
      const dialogRef = this.dialog.open(FilterComponent, {
        closeOnNavigation: true,
        data: {
          "FieldName": localStorage.getItem("OriginalFieldName"),
          "FieldValue": localStorage.getItem("OriginalFieldValue"),
          "UserID": this.UserID,
          "MenuUpTo": "5",
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.FieldName = result.FieldName;
          this.FieldValue = result.FieldValue;
          this.loadData();
        }
      });
    }

  }
}


