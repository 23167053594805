<div class="container-fluid"> 
    <div class="row mt-4" >
<div class="col-12">
    <span class="header_Value">Outlet</span>
</div>
</div>
    <div class="row mt-2" >
        <div class="col-12">
            <span class="header_text">Outlet</span> <span class="header_code">12345</span>
        </div>
    </div>
    <div class="row mt-2" >
        <div class="col-12">
            <span class="header_Value">Mobile No</span>
        </div>
        </div>
    <div class="row mt-2" >
        <div class="col-12">
            <span class="header_text">9988774455</span> 
        </div>
    </div>
    <div class="row mt-2" >
        <div class="col-12">
            <span class="header_Value">Contact Person</span>
        </div>
        </div>
    <div class="row mt-2" >
        <div class="col-12">
            <span class="header_text">Nitin K</span> 
        </div>
    </div>
    <div class="row mt-2" >
        <div class="col-12">
            <span class="header_Value">Adress 1</span>
        </div>
        </div>
    <div class="row mt-2" >
        <div class="col-12">
            <span class="header_text">Mrunal Flat</span>
        </div>
    </div>
    <div class="row mt-2" >
        <div class="col-12">
            <span class="header_Value">VisiCooler</span>
        </div>
        </div>
    <div class="row mt-2" >
        <div class="col-12">
            <span class="header_text">VC 33/35 L</span> 
        </div>
    </div>
    <div class="row mt-2" >
        <div class="col-12">
            <span class="header_Value">Visi Make</span>
        </div>
        </div>
        <div class="row mt-2" >
            <div class="col-12">
                <span class="header_text">saga</span> 
            </div>
        </div>
        <div class="row mt-2 fixed-bottom">
            <div class="col-12">    
               <button class="btnrectangle" (click)="ClickAddComplaint()"> 
                 <span class="btnText">Add Complaint</span>
               </button>
           </div>

</div>