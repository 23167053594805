<div>
  <div class="row mt-4">
    <div class="col-12">
      <input class="textBox" type="text" name="" id="" placeholder="Search Outlet by Outlet Code" [(ngModel)]="searchTerm">
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-12">
      <span class="primary">Primary</span>
      <span class="primary ml-2">{{primarynos}}</span>
      <span class="primary" style="margin-left: 2rem;">Secondary</span>
      <span class="primary ml-2">{{secondarynos}}</span>
    </div>
  </div>
  <div *ngFor="let item of filteredList; let i = index;" (click)="rowClick(item)">
    <div class="myDiv">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 mt-3">
            <span class="outlet_Name">{{item.outletname | titlecase}}</span>
            <span class="outlet_Code ml-1">{{item.outletcode}}</span>
          </div>
          <div class="col-12 mt-1">
            <span class="rd_Name">{{item.rdName | titlecase}}</span>
            <span class="rd_Code ml-1">{{item.rdCode}}</span>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-6 text-left">
            <span class="reason">Reason</span>
          </div>
          <div class="col-6 text-right">
            <span class="fir_status">{{item.firStatus | titlecase}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-1 mb-3">
            <span class="reason_Value">{{item.reason | titlecase}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-12 text-right">
        <span class="date_Text">{{item.firdate | date:'dd/MM/yyyy'}}</span>
      </div>
    </div>
  </div>
</div>
