import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';

@Component({
  selector: 'app-market-working-beat-list-data',
  templateUrl: './market-working-beat-list-data.component.html',
  styleUrls: ['./market-working-beat-list-data.component.css']
})
export class MarketWorkingBeatListDataComponent implements OnInit {
  deviceType: any;
  device: any;
  userType: string | null = '';
  userId: any;
  list: any;
  filteredList: any;
  firMenuCountList: any;
  primarynos: any;
  secondarynos: any;
  token: any;
  beatListData: any;
  panelOpenState: boolean = true;
  value: any = 0;
  public rangeSize = 15;
  public ticksColor = 'rgba(252, 252, 255, 0.98)';
  public startAngle = -40;
  public endAngle = 220;

  // public scaleRange: any = [
  //   {
  //     from: 0,
  //     to: 20,
  //     color:"#EAAB5E ",
  //   },
  //   {
  //     from: 20,
  //     to: 120,
  //     color:"#e6c4ff"
  //   },
  //   {
  //     from: 120,
  //     to: 180,
  //     color:"#DCABFF"
  //   }
  // ]

  // public pointers = [
  //   {
  //     value: 20,
  //     color: "#FFFFFF",
  //     length: 0.75,
  //   },

  // ];

  scaleRange: any = [
    {
      from: 0,
      to: 660,
      color: "#DCABFF",
    }
  ];
  pointers: any = [
    {
      value: 0,
      color: "#FFFFFF",
      length: 0.75,
    },
  ];

  public maxValue = 0;
  public majorValue = 0;
  public minorValue = 0;
  public showSpeedometer: any = false;
  public unbilledData: any = [];
  public getNonGreenOutletData:any=[];
  public getOutletCountData:any=[];
  public getOutletSalesParameter: any = [];
  formname:any;
  actiondesc:any;
  constructor(private router: Router,private cs:CommonUtilitiesService, private headerService: HeaderService, private mds: MongoDataService,) { }

  ngOnInit(): void {
    this.panelOpenState = true;
    this.headerService.setTitle('Market Ka Saathi');
    this.headerService.setActiveURl('/marketWorkingBeatListData');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.userType = localStorage.getItem("UserType");
    this.userId = localStorage.getItem("UserID");
    this.token = localStorage.getItem("TokenNo");
    this.showSpeedometer = false;
    let beatListData: any = localStorage.getItem("marketWorkingBeatListData");
    this.beatListData = JSON.parse(beatListData);
    console.log("beatListData",this.beatListData);

    setTimeout(() => {
      this.scaleRange = [
        {
          from: this.beatListData.beattarget > this.beatListData.beatach ? this.beatListData.beattarget : this.beatListData.beatach,
          to: this.beatListData.beattarget > this.beatListData.beatach ? (Math.ceil(this.beatListData.beattarget) + 10) : (Math.ceil(this.beatListData.beatach) + 10),
          color: "#DCABFF",
        },
        {
          from: 0,
          to: this.beatListData.beattarget,
          color: "#e6c4ff",
        },
        {
          from: 0,
          to: this.beatListData.beatach,
          color: "#EAAB5E",
        }
      ]
      console.log("this.scaleRange", this.scaleRange);
      debugger

      this.maxValue = this.beatListData.beattarget > this.beatListData.beatach ? (Math.ceil(this.beatListData.beattarget) + 10) : (Math.ceil(this.beatListData.beatach) + 10);
      this.majorValue = (this.maxValue % 10);
      if (this.majorValue > 0) {
        this.maxValue = this.maxValue - this.majorValue
      }
      this.majorValue = this.maxValue / 10;
      this.minorValue = this.majorValue / 10;
      this.pointers = [
        {
          value: this.beatListData.beatach,
          color: "#FFFFFF",
          length: 0.75,
        },
      ];
      this.showSpeedometer = true;

    }, 1000);
    debugger
    this.formname="MarketWorkingBeatListData";
    this.actiondesc="Market Working Beat List Data"+" "+this.beatListData.beatuid;
    this.cs.GetGeoLocation(this.userId,this.userType,this.formname,this.actiondesc,'beat',this.beatListData.beatuid);
    this.MarketWorking_GetOutletData();
    this.loadUnbilledOutletData();
  }

  MarketWorking_GetOutletData() {
    debugger
    let request =
    {
      "FieldName": "beat",
      "FieldValue": this.beatListData.beatuid.toString(),
      "Datatype": "",
      "TagName": "",
      "UserID": this.userId
    }
    this.mds.MarketWorking_GetOutletData(request, this.token, this.device, this.deviceType, 'Market Ka Sathi Get Outlet Data Of Selected Beat').subscribe(res => {
      this.getOutletCountData = res;
      console.log("getOutletCountData", this.getOutletCountData)
    });
  }

  loadUnbilledOutletData() {
    debugger
    let request =
    {
      "FieldName": "beat",
      "FieldValue": this.beatListData.beatuid.toString(),
      "Datatype": "",
      "TagName": "",
      "UserID": this.userId
    }
    this.mds.MarketWorking_GetUnbilledOutletData(request, this.token, this.device, this.deviceType, 'Market Ka Sathi Get Unbilled Outlet Data Of Selected Beat').subscribe(res => {
      this.unbilledData = res;
      console.log("unbilledData", this.unbilledData)
    });

    this.MarketWorking_GetGreenOutletData();
  }

  MarketWorking_GetGreenOutletData() {
    let request =
    {
      "FieldName": "beat",
      "FieldValue": this.beatListData.beatuid.toString(),
      "Datatype": "",
      "TagName": "",
      "UserID": this.userId
    }
    this.mds.MarketWorking_GetGreenOutletData(request, this.token, this.device, this.deviceType, 'Market Ka Sathi Get Green Outlet Data Of Selected Beat').subscribe(res => {
      this.getNonGreenOutletData = res;
      console.log("getNonGreenOutletData", this.getNonGreenOutletData)
    });
    this.MarketWorking_GetOutletSalesParameter();
  }

  MarketWorking_GetOutletSalesParameter() {
    let request =
    {
      "FieldName": "beat",
      "FieldValue": this.beatListData.beatuid.toString(),
      "Datatype": "",
      "TagName": "",
      "UserID": this.userId
    }
    this.mds.MarketWorking_GetOutletSalesParameter(request, this.token, this.device, this.deviceType, 'Market Ka Sathi Get Sales Parameter of Selected Beat').subscribe(res => {
      this.getOutletSalesParameter = res;
      console.log("getOutletSalesParameter", this.getOutletSalesParameter)
    });
  }
  clickOnViewOutlet() {
    this.router.navigate(['/home/MarketWorkingOutletList']);
  }

}
