<div>
  <div class="row">
    <div class="col-2">&nbsp;</div>
  </div>

  <div class="row mt-2"  (click)="clickOnAddEligibleOutlet()" >
    <div class="col-2">
      <img class="img-fluid" src="assets/transactions_pullout.png"  />
    </div>
    <div class="col-8 mt-1">
      <span [class]="IsOIFApproval ?  'text_16 mEnable' : 'text_16 mDisable'">Add Eligible Outlet</span>
    </div>
    <div class="col-2">
      <i class="mt-1 p-1 fa fa-chevron-right fa-sm" style="color: #9e9e9e;" aria-hidden="true"></i>
    </div>
  </div>

  <hr>


  <div class="row mt-2"  (click)="clickOnEligibleOutletSearch()" >
    <div class="col-2">
      <img class="img-fluid" src="assets/transactions_pullout.png"  />
    </div>
    <div class="col-8 mt-1">
      <span class="text_16 mEnable">Eligible Outlet Search</span>
    </div>
    <div class="col-2">
      <i class="mt-1 p-1 fa fa-chevron-right fa-sm" style="color: #9e9e9e;" aria-hidden="true"></i>
    </div>
  </div>

  <hr>

  <div class="row mt-2"  (click)="clickOnOIF()" >
    <div class="col-2">
      <img class="img-fluid" src="assets/oif.png"  />
    </div>
    <div class="col-8 mt-1">
      <span [class]="IsOIFApproval ?  'text_16 mEnable' : 'text_16 mDisable'">OIF Request</span>
    </div>
    <div class="col-2">
      <i class="mt-1 p-1 fa fa-chevron-right fa-sm" style="color: #9e9e9e;" aria-hidden="true"></i>
    </div>
  </div>

  <hr>
  <div class="row mt-2" (click)="clickOnPullOutRequest()">
    <div class="col-2">
      <img class="img-fluid" src="assets/transactions_pullout.png" />
    </div>
    <div class="col-8 mt-1">
      <span  [class]="IsPullout ?  'text_16 mEnable' : 'text_16 mDisable'">Pull Out Request</span>
    </div>
    <div class="col-2">
      <i class="mt-1 p-1 fa fa-chevron-right fa-sm" style="color: #9e9e9e;" aria-hidden="true"></i>
    </div>
  </div>
  <hr>
  <div class="row" (click)="clickOnAtoBTransfer()">
    <div class="col-2">
      <img class="img-fluid" src="assets/transactions_a2b.png" />
    </div>
    <div class="col-8 mt-1">
      <span [class]="IsATOBTransfer ?  'text_16 mEnable' : 'text_16 mDisable'" >A to B Transfer</span>
    </div>
    <div class="col-2">
      <i class="mt-1 p-1 fa fa-chevron-right fa-sm" style="color: #9e9e9e;" aria-hidden="true"></i>
    </div>
  </div>
  <hr>
  <div class="row" (click)="clickOncomplaints()">
    <div class="col-2">
      <img class="img-fluid" src="assets/icons/ATSIcons/complaint.png" />
    </div>
    <div class="col-8 mt-1">
      <span class="text_16 text_black">Cooler Complaints</span>
    </div>
    <div class="col-2">
      <i class="mt-1 p-1 fa fa-chevron-right fa-sm" style="color: #9e9e9e;" aria-hidden="true"></i>
    </div>
  </div>
  <hr>
  <div class="row" (click)="clickOnMissingVisicoolerFIR()">
    <div class="col-2">
      <img class="img-fluid" src="assets/fir.png" />
    </div>
    <div class="col-8 mt-1">
      <span class="text_16 text_black">Missing Visicooler FIR</span>
    </div>
    <div class="col-2">
      <i class="mt-1 p-1 fa fa-chevron-right fa-sm" style="color: #9e9e9e;" aria-hidden="true"></i>
    </div>
  </div>
  <hr>
  <div class="row mt-2" (click)="clickOnGateMeeting()">
    <div class="col-2">
      <img class="img-fluid" src="assets/gatemeeting.png" />
    </div>
    <div class="col-8 mt-1">
      <span [class]="IsGateMeeting ?  'text_16 mEnable' : 'text_16 mDisable'" >Gate Meeting</span>
    </div>
    <div class="col-2">
      <i class="mt-1 p-1 fa fa-chevron-right fa-sm" style="color: #9e9e9e;" aria-hidden="true"></i>
    </div>

  </div>
<hr>


  <div class="row mt-2" (click)="clickOnPCCreationSetting()">
    <div class="col-2">
      <img class="img-fluid" src="assets/pcCreation.png" />
    </div>
    <div class="col-8 mt-1">
      <span [class]="ISPCCreationSetting ?  'text_16 mEnable' : 'text_16 mDisable'">PC Creation Setting</span>
    </div>
    <div class="col-2">
      <i class="mt-1 p-1 fa fa-chevron-right fa-sm" style="color: #9e9e9e;" aria-hidden="true"></i>
    </div>

  </div>
<hr>

  <div class="row mt-2" (click)="clickOnPCDeviceTagging()">
    <div class="col-2">
      <img class="img-fluid" src="assets/PC_Device_Tagging_icon.svg" />
    </div>
    <div class="col-8 mt-1">
      <span [class]="IsDeviceTagging ?  'text_16 mEnable' : 'text_16 mDisable'">PC/TSI Device Tagging</span>
    </div>
    <div class="col-2">
      <i class="mt-1 p-1 fa fa-chevron-right fa-sm" style="color: #9e9e9e;" aria-hidden="true"></i>
    </div>

  </div>
<hr>

<div class="row mt-2" (click)="clickOnQRCodeHistory()">
  <div class="col-2">
    <img class="img-fluid" src="assets/QRCodeHistory.svg" />
  </div>
  <div class="col-8 mt-1">
    <span class="text_16 mEnable">QR Code History</span>
  </div>
  <div class="col-2">
    <i class="mt-1 p-1 fa fa-chevron-right fa-sm" style="color: #9e9e9e;" aria-hidden="true"></i>
  </div>
</div>
<hr>

<div class="row mt-2 mb-2" (click)="clickOnVCDeploymentStatus()">
  <div class="col-2">
    <img class="img-fluid" src="assets/vcDeploymentStatus.svg" />
  </div>
  <div class="col-8 mt-1">
    <span [class]="IsDeviceTagging ?  'text_16 mEnable' : 'text_16 mDisable'">VC Deployment Status</span>
  </div>
  <div class="col-2">
    <i class="mt-1 p-1 fa fa-chevron-right fa-sm" style="color: #9e9e9e;" aria-hidden="true"></i>
  </div>
</div>
<hr>


<div class="row mt-2 mb-2" (click)="clickOnUniverseMapping()">
  <div class="col-2">
    <img class="img-fluid" src="assets/universeRemapping.svg" />
  </div>
  <div class="col-8 mt-1">
    <span class="text_16 mEnable">Universe Re-Mapping    
      </span>
  </div>
  <div class="col-2">
    <i class="mt-1 p-1 fa fa-chevron-right fa-sm" style="color: #9e9e9e;" aria-hidden="true"></i>
  </div>
</div>
<hr>

<div class="row mt-2 mb-2" (click)="clickOnOutletNameChangeRequest()">
  <div class="col-2">
    <img class="img-fluid" src="../../../assets/icons/outletNameChangeIcon.svg" />
  </div>
  <div class="col-8 mt-1">
    <span class="text_16 mEnable">Outlet Name Change Request   
      </span>
  </div>
  <div class="col-2">
    <i class="mt-1 p-1 fa fa-chevron-right fa-sm" style="color: #9e9e9e;" aria-hidden="true"></i>
  </div>
</div>
<hr>


<div class="row mt-2 mb-2" (click)="clickOnScanQRCode()">
  <div class="col-2">
    <img class="img-fluid" src="assets/QRCodeHistory.svg" />
  </div>
  <div class="col-8 mt-1">
    <span class="text_16 mEnable">Scan QR Code   
      </span>
  </div>
  <div class="col-2">
    <i class="mt-1 p-1 fa fa-chevron-right fa-sm" style="color: #9e9e9e;" aria-hidden="true"></i>
  </div>
</div>
<hr>
<br><br><br><br>

</div>
<ng-template #openModalThankYou let-modal>
  <div class="modal-header">
    <h4 class="modal-title text_color_head text_20" id="modal-basic-title">
      <img class="img-fluid" src="assets/thanks_icon.png" />
      <span class="ml-3">Thank You</span>
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modalClose()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="isPullOut">
      <div class="row">
        <div class="col-12">
          <span class="text_16">Pull Out Request Generated</span>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <span class="text_16">successfully against</span>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <span class="text_16">{{outLetSelected.outletname | titlecase}}</span>
          <span class="text_16 ml-2" style="opacity: 40%;">{{outLetSelected.outletcode}}</span>
        </div>
      </div>
    </div>
    <div *ngIf="isAtoB">
      <div class="row">
        <div class="col-12">
          <span class="text_16">A to B Transfer Request Generated </span>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <span class="text_16">successfully against</span>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <span class="text_18">From</span>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-12">
          <span class="text_16" *ngIf="outletSelectedA.outletName">{{outletSelectedA.outletname | titlecase}}</span>
          <span class="text_16 ml-2" style="opacity: 40%;" *ngIf="outletSelectedA.outletCode">{{outletSelectedA.outletcode}}</span>
          <span class="text_16" *ngIf="outletSelectedA.outletname">{{outletSelectedA.outletname | titlecase}}</span>
          <span class="text_16 ml-2" style="opacity: 40%;" *ngIf="outletSelectedA.outletcode">{{outletSelectedA.outletcode}}</span>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <span class="text_18">To</span>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-12">
          <span class="text_16">{{outletSelectedB.outletname | titlecase}}</span>
          <span class="text_16 ml-2" style="opacity: 40%;">{{outletSelectedB.outletcode}}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer" (click)="modalClose()">
    CONTINUE
  </div>
</ng-template>
