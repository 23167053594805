<div class="row mt-2 bg_Color">
  <div class="col-12 mt-4">
    <span class="rd_Name">{{stockInoutDetails.rdName | titlecase}}</span>
    <span class="ml-1 rd_Code">{{stockInoutDetails.rdCode}}</span>
  </div>
  <div class="col-6 mt-2 mb-4">
    <span class="state_Name">{{stockInoutDetails.state}}</span>
  </div>
  <div class="col-6 mt-2 mb-4 text-right">
    <span class="status_Value">{{stockInoutDetails.status}}</span>
  </div>
</div>
<div class="row mt-4">
  <div class="col-12">
    <span class="Transaction_No_Text">Transaction No</span>
  </div>
  <div class="col-12 mt-1">
    <span class="Transaction_No_Value">{{stockInoutDetails.trnNo}}</span>
  </div>
</div>
<hr>
<div class="row">
  <div class="col-12">
    <span class="Transaction_No_Text">Transaction Type</span>
  </div>
  <div class="col-12 mt-1">
    <span class="Transaction_No_Value">{{stockInoutDetails.trnType}}</span>
  </div>
</div>
<hr>
<div class="row">
  <div class="col-12">
    <span class="Transaction_No_Text">Date</span>
  </div>
  <div class="col-12 mt-1">
    <span class="Transaction_No_Value">{{stockInoutDetails.trnDate | date:'dd/MM/yyyy' }}</span>
  </div>
</div>
<hr>
<div class="row">
  <div class="col-12">
    <span class="Transaction_No_Text">Narration</span>
  </div>
  <div class="col-12 mt-1">
    <span class="Transaction_No_Value">{{stockInoutDetails.narr}}</span>
  </div>
</div>
<hr>
<div class="row">
  <div class="col-6">
    <span class="Transaction_No_Text">Total Amount</span>
  </div>
  <div class="col-6 text-right" (click)="clickOnVoucherDetails()">
    <img class="img-fluid" src="assets/voucher_Details_icon.svg" />
    <span class="ml-1 voucher_Details">Voucher Details</span>
  </div>
  <div class="col-12 mt-1">
    <img class="img-fluid" src="assets/rupee.png" />
    <span class="Transaction_No_Value ml-1">{{stockInoutDetails.totAmt}}</span>
  </div>
</div>
<hr>
<br>
<br>
<br>
<br>
<br>
<div class="footer">
  <div class="row">
    <div class="col-6 pr-0" (click)="reject()">
      <button class="btn btn_REJECT">REJECT</button>
    </div>
    <div class="col-6 pl-0" (click)="approve()" *ngIf="stockInoutDetails.status != 'RD TO RD'">
      <button class="btn btn_APPROVE">APPROVE</button>
    </div>
    <div class="col-6 pl-0" (click)="selectRD()" *ngIf="stockInoutDetails.status == 'RD TO RD'">
      <button class="btn btn_APPROVE">SELECT RD</button>
    </div>
  </div>
</div>
<ng-template #openModalThankYou let-modal>
  <div class="modal-header">
    <h4 class="modal-title text_color_head text_20" id="modal-basic-title">
      <img class="img-fluid" src="assets/thanks_icon.png" />
      <span class="ml-3">Thank You</span>
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modalClose()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row" *ngIf="!isReject">
      <div class="col-12">
        <span class="model_PopUp_Text">Stock In Approved Successfully</span>
      </div>
    </div>
    <div class="row" *ngIf="isReject">
      <div class="col-12">
        <span class="model_PopUp_Text">Stock In Rejected Successfully</span>
      </div>
    </div>
    <div class="row mt-4 mb-2">
      <div class="col-12">
        <span class="rd_Name_PopUp">{{stockInoutDetails.rdName}}</span>
        <span class="ml-2 rd_Code_PopUp">{{stockInoutDetails.rdCode}}</span>
      </div>
    </div>
  </div>
  <div class="modal-footer" (click)="okay()">
    OKAY
  </div>
</ng-template>
