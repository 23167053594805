<div class="agmHead" *ngIf="isMarketWorkingViewClicked=='false'">
  <div class="row mt-2">
    <div class="col-9">
      <i class="fa fa-caret-left" style="color: #757575;">&nbsp;
        <span class="header_text">{{FieldValue=='0'? 'All India' : FieldValue=='ALLINDIA' ? 'All India': FieldValue
          |titlecase}}</span></i>
    </div>
    <div class="col-3 text-left">

    </div>
  </div>
  <div class="row mt-2" *ngIf="isMarketWorkingViewClicked=='false'">
    <div class="col-12 text-center">
      <span class="menu1">All</span>
      <span class="menu2"><i class="fa fa-circle fa-sm" style="color:#0fc755dc; font-size: 7px;"></i><span
          class="menutextmap">{{isPC ? '30min Act' :'FTD Billed Outlets'}}</span></span>
      <span class="menu2" *ngIf="isPC"><i class="fa fa-circle fa-sm" style="color:blue; font-size: 7px;"
          *ngIf="isPC"></i><span class="menutextmap">Active since Morning</span></span>
      <span class="menu2"><i class="fa fa-circle fa-sm" style="color: #f03434; font-size: 7px;"></i><span
          class="menutextmap">{{isPC ? 'Not Active' :'UnBilled Outlets'}}</span></span>
    </div>
  </div>
</div>
<span class="header_text" *ngIf="isMarketWorkingViewClicked=='false'">{{FieldName}}:{{FieldValue}}</span>
<div class="map p-0" #map>
  <agm-map style="width:100vw; height:100vh;" [latitude]="lat" [longitude]="lng" [style.height.px]="map.offsetHeight"
    [style.width.px]="map.offsetWidth" [fitBounds]="IsFitBounds ">
    <div *ngIf="isPC">
      <agm-marker *ngFor="let obj of pcData;let i = index" [longitude]="obj.tlongitude >0 ? obj.tlongitude : null "
        [latitude]="obj.tlatitude" [iconUrl]="obj.iconurl" [agmFitBounds]="true"
        (markerClick)="PCMarkerClick(slickModal,i)">
      </agm-marker>
    </div>
    <div *ngIf="!isPC">
      <agm-marker *ngFor="let data of outletlist;let j = index" [latitude]="data.mLat > 1.2 ? data.mLat : null  "
        [longitude]="data.mLong" [agmFitBounds]="true" [iconUrl]="data.iconurl"
        (markerClick)="PCOutletMarkerClick(slickModaloutlet,j)">
      </agm-marker>

    </div>
  </agm-map>

</div>
<div class="divMenu" [style.visibility]="IsShowInfoWindow ? 'visible' : 'hidden'">
  <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig" (init)="slickInit($event)"
    (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)" (beforeChange)="beforeChange($event)">
    <div ngxSlickItem *ngFor="let slide of pcData" class="slide">
      <div class="row text-center">
        <div class="col-12">
          <div class="divinfowindow">
            <div class="row mt-2">
              <div class="col-8">
                <span
                  [class]="slide.currActivePC==1 ? 'l3m' : slide.phyworking==1 ? 'pworking' :'active' ">{{slide.currActivePC==1
                  ? 'Last 30 min Active' : slide.phyworking==1?'Active since Morning':'Not Active' }}</span>
              </div>
              <div class="col-4 text-right" style="margin-left: -13px;">
                <i class="fa fa-window-close" aria-hidden="true" (click)="CloseClick()"></i>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12">
                <span class="pcName"> {{slide.desc |titlecase}}</span> <span class="value_text"> ({{slide.fieldvalue
                  }})</span>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-1"></div>
              <div class="col-5">
                <Span class="text">Order Value: {{slide.sales}}</Span>
              </div>
              <div class="col-5">
                <Span class="text">No OfOrder: {{slide.noOfOrder}}</Span>
              </div>
              <div class="col-1"></div>
            </div>

            <div class="row mt-2">
              <div class="col-1"></div>
              <div class="col-5 text-left" *ngIf="slide.tlongitude >0">
                <a [href]="IsIOs ? 'http://maps.apple.com/?saddr=' + lat + ',' + lng + '&daddr=' + slide.tlatitude + ',' + slide.tlongitude + '&dirflg=r' : 'https://www.google.com/maps/dir/' + lat + ',' + lng + '/' + slide.tlatitude + ',' + slide.tlongitude + '/@' + slide.tlatitude + ',' + slide.tlongitude"
                  target="_blank">
                  <button type="button" class="btn button ">Take me there</button>
                </a>
              </div>
              <div class="col-5 text-left" *ngIf="slide.tlongitude==0">
                &nbsp;
              </div>
              <div class="col-5 text-right">
                <button type="button" class="btn button " (click)="ViewOutlet(slide.fieldvalue,FieldValue)">View
                  Outlets</button>
              </div>
              <div class="col-1"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ngx-slick-carousel>
</div>
<div class="divOutletMenu" [style.visibility]="IsShowOutletInfoWindow ? 'visible' : 'hidden'">
  <ngx-slick-carousel class="carousel" #slickModaloutlet="slick-carousel" [config]="slideConfig"
    (init)="slickInit($event)" (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)"
    (beforeChange)="beforeChange($event)">
    <div ngxSlickItem *ngFor="let slide of outletlist" class="slide">
      <div class="row text-center">
        <div class="col-12">
          <div class="divinfowindow">

            <div class="row mt-2">
              <div class="col-8">
                <span [class]="slide.orderValue>0 ? 'pworking' :'active' ">{{slide.orderValue>0 ? 'FTD Billed
                  Outlets':'Unbill outlet' }}</span>
              </div>
              <div class="col-4 text-right" style="margin-left: -13px;">
                <i class="fa fa-window-close" aria-hidden="true" (click)="CloseOutletClick()"></i>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12 text-center">
                <span class="pcName">{{slide.outletName |titlecase}}</span> <span
                  class="value_text">({{slide.outletcode}})</span>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-1"></div>
              <div class="col-5 text-left">
                <Span class="text">RE: {{slide.re|titlecase}}</Span>
              </div>
              <div class="col-5 text-right">
                <Span class="text">VC: {{slide.visiCooler|titlecase}}</Span>
              </div>

            </div>
            <div class="row">
              <div class="col-1"></div>
              <div class="col-5 text-left">
                <Span class="text">Lines FTD: {{slide.noOfLines}}</Span>
              </div>
              <div class="col-5 text-right">
                <Span class="text">Order Value: {{slide.orderValue}}</Span>
              </div>

            </div>
            <div class="row">
              <div class="col-1"></div>
              <div class="col-5 text-left">
                <Span class="text">MTD Sales: {{slide.mtdSale}}</Span>
              </div>
              <div class="col-5 text-right">
              </div>

            </div>

            <div class="row">
              <div class="col-1"></div>
              <div class="col-11 text-center" *ngIf="slide.tLat >0">
                <a [href]="IsIOs ? 'http://maps.apple.com/?saddr=' + lat + ',' + lng + '&daddr=' + slide.mLat + ',' + slide.mLong + '&dirflg=r' : 'https://www.google.com/maps/dir/' + lat + ',' + lng + '/' + slide.mLat + ',' + slide.mLong + '/@' + slide.mLat + ',' + slide.mLong"
                  target="_blank">
                  <button type="button" class="btn button ">Take me there</button>
                </a>
              </div>
              <div class="col-1"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ngx-slick-carousel>
</div>
<div class="viewlist">

</div>

<img [src]="IsShowOutletList ? '../../../assets/viewmap.svg' : '../../../assets/viewlist.svg'"
  [class]="IsShowInfoWindow || IsShowOutletInfoWindow ? 'viewlistafteriw' : 'viewlist' " (click)="ViewList()">
<div class="row">
  <div class="col-12 outletlist_box border" *ngIf="IsShowOutletList">
    <div class="row">
      <div class="col-12 text-center">
        <img src="../../../assets/line45.svg" class="Lineinoutletlist">
      </div>
    </div>
    <div *ngIf="isPC">
      <div class="row">
        <div class="col-6 text-left">
          <span class="Outlet-List">PC List</span>
        </div>
        <div class="col-6 text-right">
          <span class="FTD-Value">Status</span>
        </div>
      </div>
      <div class="pcblock">
        <div class="row mt-4" *ngFor="let slide of pcData">

          <div class="col-9 text-left">
            <span class="PCDetails">{{slide.desc |titlecase}}</span>
            <span class="pcuidcss">&nbsp;{{slide.fieldvalue}}</span>
          </div>
          <div class="col-3 text-right">
            <span> <i
                [class]="slide.currActivePC==1 ? 'fa fa-circle fa-sm greendot' : slide.phyworking==1 ? 'fa fa-circle fa-sm bluedot' :'fa fa-circle fa-sm reddot'"></i></span>
          </div>
          <div class="Divider"></div>
        </div>
        <br><br><br><br>
      </div>
    </div>
    <div *ngIf="!isPC">
      <div class="row">
        <div class="col-6 text-left">
          <span class="Outlet-List">Outlet List</span>
        </div>
        <div class="col-6 text-right">
          <span class="FTD-Value">Order Value (&#8360;)</span>
        </div>
      </div>
      <div class="pcblock">
        <div class="row mt-4" *ngFor="let slide of outletlist">

          <div class="col-9 text-left">
            <span class="PCDetails">{{slide.outletName |titlecase}}</span>
            <span class="pcuidcss">&nbsp;{{slide.outletcode}}</span>
          </div>
          <div class="col-3 text-right">
            <span>{{slide.orderValue}}</span>
          </div>
          <div class="Divider"></div>
        </div>
        <br><br><br><br>
      </div>
    </div>

  </div>
</div>