<br>
<div *ngIf="IsShowNotification">
  <div class="row uv-100" *ngFor="let item of notificationlist | slice:0:1">
    <div class="col-12 RectangleNotification">
      <div class="row">
        <div class="col-2">
          <img [src]="notificationImageUrl + item.notificationImage" class="img-fluid"
            onerror="this.onerror=null;this.src='../assets/images/NoBlogImage.jpg'" *ngIf="item.notificationImage!=''">
        </div>
        <div class="col-8 p-0">
          <div class="row col-12 notifationhead">
            {{item.notificationHeader.length>20 ? item.notificationHeader.substring(0,20) +
            '...':item.notificationHeader}}
          </div>
          <div class="row col-12 notifationbody">
            {{item.notificationText.length>80 ? item.notificationText.substring(0,80) + '...':item.notificationText}}
          </div>
        </div>
        <div class="col-1 p-0 text-center ">
          <div class="viewAllbox p-0 align-middle">
            <div class="ViewAll p-0" (click)="getNotificationurl()">View All</div>
          </div>
        </div>
        <div class="col-1 text-right align-middle">
          <img src="../../../assets/exit.svg" (click)="CloseNotification()" class="noticancel">
        </div>
      </div>
    </div>
  </div>
</div>
<div>
  <div class="row mt-2">
    <div class="col-12">

      <div *ngIf="IsBanner">
        <ngb-carousel>
          <ng-template *ngFor="let item of brandImage" ngbSlide>
            <div class="picsum-img-wrapper">
              <img src='{{defaultImageUrl + item.imgName}}' alt="">
              <!-- '{{ "assets/images/" + wonder.id + ".jpg" }}' -->
            </div>
          </ng-template>
        </ngb-carousel>
      </div>
      <div *ngIf="!IsBanner">
        <ngb-carousel>

          <ng-template ngbSlide>
            <div class="picsum-img-wrapper">
              <img [src]="defaultImage" alt="">
            </div>

          </ng-template>
        </ngb-carousel>
      </div>
      <!-- <img class="img-fluid" src="assets/banner.png" /> -->
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-7 mt-1">
      <span class="text-left text_20 align-middle">Value performance</span>
      <!-- <span class="text-left text_20 align-middle">Value performance (Second Half)</span> -->
    </div>
    <div class="col-5 text-right" [formGroup]="valuePerformanceForm">
      <mat-form-field appearance="outline" style="width: 100px">
        <!-- <mat-label>Select Month</mat-label>  -->
        <mat-select id="period" name="period" (selectionChange)="changeMonth($event)" [(value)]="selectedperiod">
          <mat-option *ngFor="let item of periodList" [value]="item">
            {{ item }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="row mt-3" *ngFor="let item of resTargetdata;let i = index">
    <div class="col-3">
      <div class="row">
        <div class="col-12 text-center">
          <h1 class="m-0 text_Per">{{ValuePer}}<span class="">%</span></h1>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <span class="text_black text_10">Achievement</span>
        </div>
      </div>
    </div>
    <div class="col-9" (click)="clickOnValuePerformance()">
      <div class="row mt-2">
        <div class="col-12">
          <div class="progress rounded-pill">
            <div class="progress-bar bg_sec" role="progressbar" [style.width.%]="item.ach" aria-valuenow="25"
              aria-valuemin="0" aria-valuemax="100"></div>
            <div class="progress-bar bg_pri" role="progressbar" [style.width.%]="item.primarysale" aria-valuenow="15"
              aria-valuemin="0" aria-valuemax="100"></div>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-4 text-center">
          <h2 class="m-0 text_16_black">{{item.ach | number: '1.0-0'}}<span class="text_10"> {{item.unit}}</span></h2>
        </div>
        <div class="col-4 text-center">
          <h2 class="m-0 text_16_black">{{item.primarysale | number: '1.0-0'}}<span class="text_10">
              {{item.unit}}</span></h2>
        </div>
        <div class="col-4 text-center">
          <h2 class="m-0 text_16_black">{{item.tgt | number: '1.0-0'}}<span class="text_10"> {{item.unit}}</span></h2>
        </div>
      </div>
      <div class="row" style="margin-top: -5px;">
        <div class="col-4 pr-0">
          <img class="img-fluid" src="assets/sec_dot.png" />
          <span class="secondary_text"> Secondary</span>
        </div>
        <div class="col-4">
          <img class="img-fluid" src="assets/pri_dot.png" />
          <span class="primary_text"> Primary</span>
        </div>
        <div class="col-4">
          <img class="img-fluid" src="assets/tar_dot.png" />
          <span class="target_text"> Target</span>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-7">
    </div>
    <div class="col-5 text-right">
      <button class="border_rounded btn" (click)="clickOnSecTrend()">
        Secondary Trend
      </button>
    </div>
  </div>

  <!-- *ngIf="list[0]?.userType=='MARKETKASAATHI'" -->
  <div class="row mt-3 mx-auto border_marketWorking align-items-center"  (click)="clickOnMarketWorking()">
    <div class="col-10">
      <img class="img-fluid MarketKaSathiLogo" src="assets/MarketKaSathiLogo.gif" />
      <span class="col-7 text-left marketKaSathiLabel align-middle">Market Ka Saathi</span>
    </div>
    <div class="col-2">
      <img class="img-fluid MarketKaSathiArrow" src="assets/MarketKaSathiArrow.gif" />
    </div>
  </div>


  <div class="row bg_Performance" style="margin-top:1.75rem;">
    <div class="col-12 mt-4">
      <span class="text-left text_20 align-middle">Performance Deepdive</span>
    </div>
  </div>
  <div class="row bg_Performance">
    <div class="col-3 text-center mt-4">
      <img class="img-fluid bg_Channel" src="assets/rd_icon.png" (click)="clickOnRD()" />
    </div>
    <div class="col-3 text-center mt-4">
      <img class="img-fluid bg_Channel" src="assets/channel_icon.png" (click)="openModalForChannel(openModalChannel)" />
    </div>
    <div class="col-3 text-center mt-4">
      <img class="img-fluid bg_Channel" src="assets/category_icon.png" (click)="clickOnCommomPerformance('Category')" />
    </div>
    <div class="col-3 text-center mt-4">
      <img class="img-fluid bg_Channel" src="assets/town_Class_icon.png"
        (click)="clickOnCommomPerformance('Town Class')" />
    </div>
  </div>
  <div class="row bg_Performance">
    <div class="col-3 text-center mb-4" style="margin-top: 0.6875rem;">
      <span class="text_channel" (click)="clickOnRD()">RD</span>
    </div>
    <div class="col-3 text-center" style="margin-top: 0.6875rem;">
      <span class="text_channel" (click)="openModalForChannel(openModalChannel)">Channel</span>
    </div>
    <div class="col-3 text-center" style="margin-top: 0.6875rem;">
      <span class="text_channel" (click)="clickOnCommomPerformance('Category')">Category</span>
    </div>
    <div class="col-3 p-0 text-center" style="margin-top: 0.6875rem;">
      <span class="text_channel" (click)="clickOnCommomPerformance('Town Class')">Town Class</span>
    </div>
  </div>
  <div class="row" style="margin-top: 1.625rem;">
    <div class="col-12">
      <span class="text-left text_20 align-middle">KPI</span>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-12">
      <div class="scrollmenu">
        <div class="horizontal-scroll-wrapper squares ">
          <ul class="list-inline Box">
            <li class="list-inline-item">
              &nbsp;<label>
                <input type="checkbox" name="options" style="display:none;" autocomplete="off" class="Chkbtn" />
                &nbsp;<button [class]="expansionbtnclass" (click)="showDetails('EC')"><span
                    class="oiftitle">EC</span></button>
              </label>
            </li>
            <li class="list-inline-item">
              &nbsp;<label>
                <input type="checkbox" name="options" style="display:none;" autocomplete="off" class="Chkbtn" />
                &nbsp;<button [class]="mscorebtnclass" (click)="showDetails('M Score')"><span class="oiftitle">M
                    Score</span></button>
              </label>
            </li>
            <li class="list-inline-item">
              <label>
                <input type="checkbox" name="options" style="display:none;" autocomplete="off" class="Chkbtn">
                &nbsp;<button [class]="vcscanbtnclass" (click)="showDetails('VC Scan')">
                  <span class="oiftitle">VC Scan</span></button>
              </label>
            </li>
            <li class="list-inline-item">
              <label>
                <input type="checkbox" name="options" style="display:none;" autocomplete="off" class="Chkbtn">
                &nbsp;<button [class]="newrlabtnclass" (click)="showDetails('New RLA')"><span class="oiftitle">NET
                    RLA</span></button>
              </label>
            </li>
            <li class="list-inline-item">
              <label>
                <input type="checkbox" name="options" style="display:none;" autocomplete="off" class="Chkbtn">
                &nbsp;<button [class]="vpobtnclass" (click)="showDetails('VPO')">
                  <span class="oiftitle">VPO</span></button>
              </label>
            </li>
            <li class="list-inline-item">
              <label>
                <input type="checkbox" name="options" style="display:none;" autocomplete="off" class="Chkbtn">
                &nbsp;<button [class]="greenoutletbtnclass" (click)="showDetails('Green Outlet')">
                  <span class="oiftitle">Green Outlet</span></button>
              </label>
            </li>
            <li class="list-inline-item">
              <label>
                <input type="checkbox" name="options" style="display:none;" autocomplete="off" class="Chkbtn">
                &nbsp;<button [class]="ulbbtnclass" (click)="showDetails('ULB')">
                  <span class="oiftitle">ULB</span></button>
              </label>
            </li>
            <li class="list-inline-item">
              <label>
                <input type="checkbox" name="options" style="display:none;" autocomplete="off" class="Chkbtn">
                &nbsp;<button [class]="productivitybtnclass" (click)="showDetails('Productivity')">
                  <span class="oiftitle">Productivity</span></button>
              </label>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="RequirementType == 'EC'">
    <div class="row" style="margin-top: 1.875rem;">
      <div class="col-7 mt-1">
        <span class="text-left text_Sub align-middle">Effective Coverage</span>
      </div>
      <div class="col-5 text-right">
        <!-- <select class="form_control_custom rounded-pill" id="month" (change)="changeMonthKPIEC($event)">
          <option value="" disabled>Current Month</option>
          <option *ngFor="let item of periodForKPIEC" [value]="item">
            {{ item }}
          </option>
        </select> -->
        <mat-form-field appearance="outline" style="width: 100px">
          <!-- <mat-label>Period</mat-label> -->
          <mat-select id="month" name="month" (selectionChange)="changeMonthKPIEC($event)"
            [(value)]="selectedperiodForKPIEC">
            <mat-option value="" disabled>Current Month</mat-option>
            <mat-option *ngFor="let item of periodForKPIEC" [value]="item">
              {{ item }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row mt-3" (click)="clickOnEC()">
      <!-- <div class="col-3">
        <div class="row">
          <div class="col-12 text-center">
            <h1 class="m-0 text_Per">{{urbanPer}}%</h1>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <span class="text_black text_10">Average EC</span>
          </div>
        </div>
      </div> -->
      <div class="col-12">
        <div class="row mt-2">
          <div class="col-12">
            <div class="progress rounded-pill">
              <div class="progress-bar bg_sec" role="progressbar" [style.width.%]="ruralPer" aria-valuenow="20"
                aria-valuemin="0" aria-valuemax="100"></div>
              <div class="progress-bar bg_pri" role="progressbar" [style.width.%]="urbanPer" aria-valuenow="40"
                aria-valuemin="0" aria-valuemax="100"></div>
            </div> 
          </div>
        </div>
        <div class="row" style="margin-top: 0.5625rem;">
          <div class="col-6 text-center">
            <h2 class="m-0 text_16_black">{{ruralPer}}%</h2>
          </div>
          <div class="col-6 text-center">
            <h2 class="m-0 text_16_black">{{urbanPer}}%</h2>
          </div>
        </div>
        <div class="row" style="margin-top: -5px;">
          <div class="col-6 text-center">
            <img class="img-fluid" src="assets/sec_dot.png" />
            <span class="secondary_text"> Rural</span>
          </div>
          <div class="col-6 text-center">
            <img class="img-fluid" src="assets/pri_dot.png" />
            <span class="primary_text"> Urban</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="RequirementType == 'M Score'">
    <div class="row" style="margin-top: 1.875rem;">
      <div class="col-7 mt-2">
        <span class="text-left text_Sub align-middle">M Score</span>
      </div>
      <div class="col-5 text-center">

      </div>
    </div>
    <div class="row mt-2 bg_MScore">
      <div class="col-12 mt-2">
        <div class="myDiv" (click)="clickOnLines_MScore('Audited')">
          <div class="row mt-1"></div>
          <div class="row mt-2">
            <div class="col-12 text-center">
              <span class="text_Head">{{audited_rla}}</span>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-12 text-center">
              <span class="text_Footer">Audited RLA</span>
            </div>
          </div>

        </div>
      </div>
      <!-- <div class="col-6 mt-2">
        <div class="myDiv" (click)="clickOnLines_MScore('Visi')">
          <div class="row mt-1"></div>
          <div class="row mt-2">
            <div class="col-12 text-center">
              <span class="text_Head">{{vis_rla}}</span>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-12 text-center">
              <span class="text_Footer">Visited RLA</span>
            </div>
          </div>
         
        </div>
      </div> -->

    </div>
  </div>


  <div *ngIf="RequirementType == 'VC Scan'">
    <div class="row" style="margin-top: 1.875rem;">
      <div class="col-7 mt-2">
        <span class="text-left text_Sub align-middle">VC Scan</span>
      </div>
      <div class="col-5 text-center">
        <!-- <select class="form_control_custom rounded-pill" id="month" (change)="changeMonthVCScan($event)">
          <option value="" disabled>Current Month</option>
          <option *ngFor="let item of periodForVCScan" [value]="item">
            {{ item }}
          </option>
        </select> -->
        <mat-form-field appearance="outline" style="width: 100px">
          <!-- <mat-label>Period</mat-label> -->
          <mat-select id="month" name="month" (selectionChange)="changeMonthVCScan($event)"
            [(value)]="selectedperiodForVCScan">
            <mat-option *ngFor="let item of periodForVCScan" [value]="item">
              {{ item }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row mt-3" (click)="clickOnVCScan()">
      <div class="col-3">
        <div class="row">
          <div class="col-12 text-center">
            <h1 class="m-0 text_Per">{{(ruralTotforVCScan + urbanTotforVCScan == 0 ? 0 : (100 * (ruralAchforVCScan
              +urbanAchforVCScan))/(ruralTotforVCScan + urbanTotforVCScan)).toFixed(0) }}%</h1>
            <!-- <h1 class="m-0 text_Per" *ngIf="isNull">0%</h1> -->
          </div>
        </div>
      </div>
      <div class="col-9">
        <div class="row mt-2">
          <div class="col-12">
            <div class="progress rounded-pill">
              <div class="progress-bar bg_sec" role="progressbar" [style.width.%]="ruralPerForVCScan" aria-valuenow="35"
                aria-valuemin="0" aria-valuemax="100"></div>
              <div class="progress-bar bg_pri" role="progressbar" [style.width.%]="urbanPerForVCScan" aria-valuenow="45"
                aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
        </div>
        <div class="row" style="margin-top: 0.5625rem;">
          <div class="col-6 text-center">
            <h2 class="m-0 text_16_black">{{ruralPerForVCScan}}%</h2>
          </div>
          <div class="col-6 text-center">
            <h2 class="m-0 text_16_black">{{urbanPerForVCScan}}%</h2>
          </div>
        </div>
        <div class="row" style="margin-top: -5px;">
          <div class="col-6 text-center">
            <img class="img-fluid" src="assets/sec_dot.png" />
            <span class="secondary_text"> Rural</span>
          </div>
          <div class="col-6 text-center">
            <img class="img-fluid" src="assets/pri_dot.png" />
            <span class="primary_text"> Urban</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="RequirementType == 'New RLA'">
    <div class="row" style="margin-top: 1.875rem;">
      <div class="col-7 mt-1">
        <span class="text-left text_Sub align-middle">NET RLA</span>
      </div>
      <div class="col-5 text-center">
        <mat-form-field appearance="outline" style="width: 100px">
          <mat-select id="month" name="month" (selectionChange)="changeMonthNewRLA($event)"
            [(value)]="selectedperiodForNewRLA">
            <mat-option *ngFor="let item of periodForNewRLA" [value]="item">
              {{ item }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row mt-3" (click)="clickOnNewRLA()">
      <div class="col-12">
        <div class="row mt-2">
          <div class="col-12">
            <div class="progress rounded-pill">
              <div class="progress-bar bg_sec" role="progressbar" [style.width.%]="ruralPerForNewRLA" aria-valuenow="25"
                aria-valuemin="0" aria-valuemax="100"></div>
              <div class="progress-bar bg_pri" role="progressbar" [style.width.%]="urbanPerForNewRLA" aria-valuenow="15"
                aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
        </div>
        <div class="row" style="margin-top: 0.5625rem;">
          <div class="col-6 text-center">
            <h2 class="m-0 text_16_black">{{ruralPerForNewRLA}}</h2>
          </div>
          <div class="col-6 text-center">
            <h2 class="m-0 text_16_black">{{urbanPerForNewRLA}}</h2>
          </div>
        </div>
        <div class="row" style="margin-top: -5px;">
          <div class="col-6 text-center">
            <img class="img-fluid" src="assets/sec_dot.png" />
            <span class="secondary_text"> Rural RLA</span>
          </div>
          <div class="col-6 text-center">
            <img class="img-fluid" src="assets/pri_dot.png" />
            <span class="primary_text"> Urban NET RLA</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="RequirementType == 'VPO'">
    <div class="row" style="margin-top: 1.875rem;">
      <div class="col-7 mt-2">
        <span class="text-left text_Sub align-middle">VPO</span>
      </div>
      <div class="col-5 text-center">
        <mat-form-field appearance="outline" style="width: 100px">
          <!-- <mat-label>Period</mat-label> -->
          <mat-select id="month" name="month" (selectionChange)="changeMonthVCScan($event)"
            [(value)]="selectedperiodForVCScan">
            <mat-option value="" disabled>Current Month</mat-option>
            <mat-option *ngFor="let item of periodForVCScan" [value]="item">
              {{ item }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row mt-3" (click)="clickOnVPO()">
      <div class="col-3">
        <div class="row">
          <div class="col-12 text-center">
            <h1 class="m-0 text_Per">{{(ruralTotforVCScan + urbanTotforVCScan == 0 ? 0 : (100 * (ruralAchforVCScan
              +urbanAchforVCScan))/(ruralTotforVCScan + urbanTotforVCScan)).toFixed(0) }}%</h1>
            <!-- <h1 class="m-0 text_Per">{{ruralPerForVCScan + urbanPerForVCScan}}%</h1> -->
          </div>
        </div>
      </div>
      <div class="col-9">
        <div class="row mt-2">
          <div class="col-12">
            <div class="progress rounded-pill">
              <div class="progress-bar bg_sec" role="progressbar" [style.width.%]="ruralPerForVCScan" aria-valuenow="20"
                aria-valuemin="0" aria-valuemax="100"></div>
              <div class="progress-bar bg_pri" role="progressbar" [style.width.%]="urbanPerForVCScan" aria-valuenow="40"
                aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
        </div>
        <div class="row" style="margin-top: 0.5625rem;">
          <div class="col-6 text-center">
            <h2 class="m-0 text_16_black">{{ruralPerForVCScan}}%</h2>
          </div>
          <div class="col-6 text-center">
            <h2 class="m-0 text_16_black">{{urbanPerForVCScan}}%</h2>
          </div>
        </div>
        <div class="row" style="margin-top: -5px;">
          <div class="col-6 text-center">
            <img class="img-fluid" src="assets/sec_dot.png" />
            <span class="secondary_text"> Rural</span>
          </div>
          <div class="col-6 text-center">
            <img class="img-fluid" src="assets/pri_dot.png" />
            <span class="primary_text"> Urban</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="RequirementType == 'Green Outlet'">
    <div class="row" style="margin-top: 1.875rem;">
      <div class="col-7 mt-2">
        <span class="text-left text_Sub align-middle">Green Outlet</span>
      </div>
      <div class="col-5 text-center">
        <!-- <select class="form_control_custom rounded-pill" id="month">
          <option value="">Current Month</option>
          <option value="jan 2021">Jan 2021</option>
          <option value="feb 2021">Feb 2021</option>
          <option value="mar 2021">Mar 2021</option>
          <option value="apr 2021">Apr 2021</option>
        </select> -->
        <mat-form-field appearance="outline" style="width: 100px">

          <mat-select id="period" name="period" (selectionChange)="changeMonthGreenOutlet($event)"
            [(value)]="greenselectedperiod">
            <mat-option *ngFor="let item of greenperiodList" [value]="item">
              {{ item }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row mt-3" (click)="clickOnGreenOutlet()">
      <!-- <div class="col-3">
        <div class="row">
          <div class="col-12 text-center">
            <h1 class="m-0 text_Per">80%</h1>
          </div>
        </div>
      </div> -->
      <div class="col-12">
        <div class="row mt-2">
          <div class="col-12">
            <div class="progress rounded-pill">
              <div class="progress-bar bg_sec" role="progressbar" style="width: 30%" aria-valuenow="20"
                aria-valuemin="0" aria-valuemax="100"></div>
              <div class="progress-bar bg_pri" role="progressbar" style="width: 70%" aria-valuenow="40"
                aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
        </div>
        <div class="row" style="margin-top: 0.5625rem;"
          *ngFor="let item of filterItemsOfGreenOutlet(GreenOutlets);let i = index">
          <div class="col-6 text-center">
            <h2 class="m-0 text_16_black">{{item.greenach}}%</h2>
          </div>
          <div class="col-6 text-center">
            <h2 class="m-0 text_16_black">{{item.greentgt}}%</h2>
          </div>
        </div>
        <div class="row" style="margin-top: -5px;">
          <div class="col-6 text-center">
            <img class="img-fluid" src="assets/sec_dot.png" />
            <span class="secondary_text"> Green Outlet Ach</span>
          </div>
          <div class="col-6 text-center">
            <img class="img-fluid" src="assets/pri_dot.png" />
            <span class="primary_text"> Green Outlet Tgt</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="RequirementType == 'ULB'">
    <div class="row" style="margin-top: 1.875rem;">
      <div class="col-7 mt-2">
        <span class="text-left text_Sub align-middle">Lines</span>
      </div>
      <div class="col-5 text-center">
        <!-- <select class="form_control_custom rounded-pill" id="month" (change)="changeMonthULB($event)">
          <option value="cmulb">Current Month</option>
          <option value="lmulb">Last Month</option>
        </select> -->

      </div>
    </div>
    <div class="row mt-2 bg_ULB">
      <div class="col-6 mt-2">
        <div class="myDiv" (click)="clickOnLines('Billed')">
          <div class="row mt-1"></div>
          <div class="row mt-2">
            <div class="col-12 text-center">
              <span class="text_Head">{{cmulb}}</span>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-12 text-center">
              <span class="text_Footer">Billed RLA</span>
            </div>
          </div>

        </div>
      </div>
      <div class="col-6 mt-2">
        <div class="myDiv" (click)="clickOnLines('Total')">
          <div class="row mt-1"></div>
          <div class="row mt-2">
            <div class="col-12 text-center">
              <span class="text_Head">{{cmulb_Total}}</span>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-12 text-center">
              <span class="text_Footer">Total RLA</span>
            </div>

          </div>

        </div>
      </div>
      <!-- <div class="col-7 text-center mt-2">
        <span class="text-left text_16 align-middle">Unique Lines Billed</span>
      </div>
     
      <div class="col-5 text-center mt-2">
        <span class="text-left text_16 align-middle">{{cmulb}}</span>
    
    </div>-->
    </div>
  </div>

  <div *ngIf="RequirementType == 'Productivity'">
    <div class="row" style="margin-top: 1.875rem;">
      <div class="col-7" style="margin-top: 0.75rem;">
        <span class="text-left text_Sub align-middle">Productivity</span>
      </div>
      <div class="col-5 text-right">
        <mat-form-field appearance="outline" style="width: 100px">
          <mat-select id="month" name="month" (selectionChange)="PeriodChangekpiProductivity($event)"
            [(value)]="selectedperiodProductivity">
            <mat-option value="" disabled>Current Month</mat-option>
            <mat-option *ngFor="let item of periodListForProductivity" [value]="item">
              {{ item }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row mt-3" (click)="clickOnProductivity()">
      <div class="col-12" *ngFor="let item of filterItemsOfTypeKPIProductivity(kpiProductivity)">
        <div class="row mt-2">
          <div class="col-12">
            <div class="progress rounded-pill">
              <div class="progress-bar bg_sec" role="progressbar" [style.width.%]="item.pjpper" aria-valuenow="20"
                aria-valuemin="0" aria-valuemax="100"></div>
              <div class="progress-bar bg_pri" role="progressbar" [style.width.%]="item.prodper" aria-valuenow="40"
                aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
        </div>
        <div class="row" style="margin-top: 0.5625rem;">
          <div class="col-6 text-center">
            <h2 class="m-0 text_16_black">{{item.pjpper}}%</h2>
          </div>
          <div class="col-6 text-center">
            <h2 class="m-0 text_16_black">{{item.prodper}}%</h2>
          </div>
        </div>
        <div class="row" style="margin-top: -5px;">
          <div class="col-6 text-center">
            <img class="img-fluid" src="assets/sec_dot.png" />
            <span class="secondary_text"> PJP</span>
          </div>
          <div class="col-6 text-center">
            <img class="img-fluid" src="assets/pri_dot.png" />
            <span class="primary_text"> Month</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row bg_activePC mt-4">
    <div class="col-7 mt-3">
      <span class="text-left text_20 align-middle">Active PC & TSI</span>
    </div>
    <div class="col-5 mt-3 text-right" (click)="RefreshActivePCTSI()">
      <img class="img-fluid" src="assets/images/refresh.png" />
      <span class="primary_text" style="vertical-align: middle;"> Refresh</span>
    </div>
  </div>
</div>
<div class="row  bg_activePC">

  <div class="col-6 mt-4">
    <div class="myDiv" (click)="clickOnActivePC()">
      <div class="row mt-1">
        <div class="col-12 text-center mt-2">
          <span class="text_PC_10_bold">PC</span>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12 text-center">
          <span class="text_PC_35">{{ActivePCcounter}}</span>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-12 text-center">
          <span class="text_PC_10">{{TotalPC}} Total</span>
        </div>
      </div>
      <h1 class="myH1 mt-1"><button
          [class]="ActivePCPer>50 ? 'btn btn_ActivePC rounded-pill mySpanGreen text_10' : 'btn btn_ActivePC rounded-pill mySpanRed text_10'">{{ActivePCPer.toFixed(2)}}%
          Active</button>
      </h1>
    </div>
  </div>
  <div class="col-6 mt-4">
    <div class="myDiv" (click)="clickOnActiveTSI()">
      <div class="row mt-1">
        <div class="col-12 text-center mt-2">
          <span class="text_PC_10_bold">TSI</span>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12 text-center">
          <span class="text_PC_35">{{this.ActiveTSIcounter}}</span>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-12 text-center">
          <span class="text_PC_10">{{TotalTSI}} Total</span>
        </div>

      </div>
      <h1 class="myH1 mt-1">
        <button
          [class]="ActiveTSIPer>50 ? 'btn btn_ActivePC rounded-pill mySpanGreen text_10' : 'btn btn_ActivePC rounded-pill mySpanRed text_10' ">{{ActiveTSIPer.toFixed(2)}}%
          Active</button>
      </h1>
    </div>
  </div>
</div>
<div class="row bg_activePC">
  <div class="col-4 text-center customColForButton mb-4" style="margin-top: 2rem!important;">
    <button class="btn btn_custom bg_btn" (click)="clickOnPCAttendance()">PC
      Attendance</button>
  </div>
  <div class="col-4 text-center customColForButton" style="margin-top: 2rem!important;">
    <button class="btn btn_custom bg_btn" (click)="clickOnTSIAttendance()">TSI Attendance</button>
  </div>
  <div class="col-4 text-center customColForButton" style="margin-top: 2rem!important;">
    <button class="btn btn_custom bg_btn" (click)="clickOnGateMeeting()">Gate
      Meeting</button>
  </div>
</div>
<div class="row" style="margin-top: 0.75rem!important;">
  <div class="col-12 mt-1 text-right" (click)="RefreshTodaysOrderBooking()">
    <img class="img-fluid" src="assets/images/refresh.png" />
    <span class="primary_text" style="vertical-align: middle;"> Refresh</span>
  </div>
</div>

<div class="row">
  <div class="col-3" (click)="clickOnTodaysVisits()">
    <img class="img-fluid" src="assets/todaysVisit.png" />
  </div>
  <div class="col-5 p-0" style="margin-top: 1.25rem!important;" (click)="clickOnTodaysVisits()">
    <span class="text-left text_20 align-middle">Todays Visits</span>
  </div>
  <div class="col-4 p-0 mt-2 " (click)="clickOnTodaysVisits()">
    <button class="btn border_today text-center text_22 align-middle"
      (click)="clickOnTodaysVisits()">{{Visitpercent}}%
      <!-- <span class="text_12" style="font-weight: normal;" (click)="clickOnTodaysVisits()">%</span> -->
    </button>
  </div>
</div>
<hr>
<div class="row">
  <div class="col-3" (click)="clickOnTodaysOrderBooking()">
    <img class="img-fluid" src="assets/cart.png" />
  </div>
  <div class="col-5 p-0" style="margin-top: 1.25rem!important;" (click)="clickOnTodaysOrderBooking()">
    <span class="text-left text_20 align-middle">Todays Orders</span>
  </div>
  <div class="col-4 p-0 mt-2 " (click)="clickOnTodaysOrderBooking()">
    <button class="btn border_today text-center text_22 align-middle"
      (click)="clickOnTodaysOrderBooking()">{{projectcount}}
      <span class="text_12" style="font-weight: normal;" (click)="clickOnTodaysOrderBooking()"> {{orderUnit}}</span>
    </button>
    <!-- <i class="fa fa-refresh fa-sm" style="color: #979797"><span class="secondary_text"> Refresh</span></i> -->
  </div>
</div>
<hr>
<div class="row">
  <div class="col-3" (click)="clickOnSEEarnings()">
    <img class="img-fluid" src="assets/SE_Earning.png"/>
  </div>
  <div class="col-5 p-0" style="margin-top: 1.25rem!important;" (click)="clickOnSEEarnings()">
    <span class="text-left text_20 align-middle">SE Earnings</span>
  </div>
  <div class="col-4 p-0 mt-2" (click)="clickOnSEEarnings()">
    <button class="btn border_today text_22 align-middle">{{seearning}}%</button>
  </div>
</div>
<div class="row mt-3">
  <div class="col-2"></div>
  <div class="col-5 text-center">
    <button class="btn customLineHeight btn_custom" (click)="clickOnPCEarnings()">PC Earnings</button>
  </div>
  <div class="col-5 text-center">
    <button class="btn customLineHeight btn_custom" (click)="clickOnTSIEarnings()">TSI Earnings</button>
  </div>
</div>
<hr>
<div class="row">
  <div class="col-8 mt-1">
    <span class="text-left text_20 align-middle">Unbilled Outlet</span>
  </div>
  <div class="col-4 text-center">
    <!-- <select class="form_control_custom rounded-pill" id="ftd">
        <option value="ftd">FTD</option>
      </select> -->
    <mat-form-field appearance="outline" style="width: 100px">
      <!-- <mat-label>Period</mat-label> -->
      <mat-select value="mtd" (selectionChange)="changeunbilledOutlet($event)" [(value)]="unbilledOutletType">
        <mat-option value="MTD">MTD</mat-option>
        <mat-option value="FTD">FTD</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
<div class="row mt-4" (click)="clickOnUnbilledOutlet()">
  <div class="col-12">
    <div class="progress rounded-pill">
      <div class="progress-bar bg_sec" role="progressbar" style="width: 20%" aria-valuenow="25" aria-valuemin="0"
        aria-valuemax="100"></div>
      <div class="progress-bar bg_pri" role="progressbar" style="width: 40%" aria-valuenow="15" aria-valuemin="0"
        aria-valuemax="100"></div>

    </div>

  </div>

</div>
<div class="row mt-2" (click)="clickOnUnbilledOutlet()">
  <div class="col-4 text-center">
    <h2 class="m-0">{{unbilledrural}}</h2>
  </div>
  <div class="col-4 text-center">
    <h2 class="m-0">{{unbilledurban}}</h2>
  </div>
  <div class="col-4 text-center">
    <h2 class="m-0">{{unbilledtotal}}</h2>
  </div>
</div>
<div class="row" style="margin-top: -5px;" (click)="clickOnUnbilledOutlet()">
  <div class="col-4 text-center">
    <img class="img-fluid" src="assets/sec_dot.png" />
    <span class="secondary_text"> Rural</span>
  </div>
  <div class="col-4 text-center">
    <img class="img-fluid" src="assets/pri_dot.png" />
    <span class="primary_text"> Urban</span>
  </div>
  <div class="col-4 text-center">
    <img class="img-fluid" src="assets/tar_dot.png" />
    <span class="target_text">Total</span>
  </div>
</div>
<div class="row mt-3">
  <div class="col-2"></div>
  <div class="col-5 text-right">
    <button class="btn customLineHeight btn_custom" (click)="clickOnL3M()">Last 3 Months</button>
  </div>
  <div class="col-5 text-right">
    <button class="btn customLineHeight btn_custom" (click)="clickOnL6M()">L6M Risk Outlet</button>
  </div>
</div>
<hr>
<div *ngIf="userstatus != 'URBAN'">
  <div class="row">
    <div class="col-12">
      <span class="text-left text_20 align-middle">Rural DMS Usage</span>
    </div>
  </div>


  <div class="row mt-3">
    <div class="col-12">
      <div class="scrollmenu">
        <div class="horizontal-scroll-wrapper squares ">
          <ul class="list-inline Box">
            <li class="list-inline-item">
              &nbsp;<label>
                <input type="checkbox" name="options" style="display:none;" autocomplete="off" class="Chkbtn" />
                &nbsp;<button [class]="expansionbtnclassfordms" (click)="showDetailsForDMS('Sales')"><span
                    class="oiftitle">Sales</span></button>
              </label>
            </li>
            <li class="list-inline-item">
              <label>
                <input type="checkbox" name="options" style="display:none;" autocomplete="off" class="Chkbtn">
                &nbsp;<button [class]="ecfordmsbtnclass" (click)="showDetailsForDMS('EC')">
                  <span class="oiftitle">EC</span></button>
              </label>
            </li>
            <li class="list-inline-item">
              <label>
                <input type="checkbox" name="options" style="display:none;" autocomplete="off" class="Chkbtn">
                &nbsp;<button [class]="linesbtnclass" (click)="showDetailsForDMS('Lines')">
                  <span class="oiftitle">ULB</span></button>
              </label>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>


  <div *ngIf="RequirementTypeForDMS == 'Sales'">
    <div class="row mt-3">
      <div class="col-7" style="margin-top: 0.75rem;">
        <span class="text-left text_Sub align-middle">Sales</span>
      </div>
      <div class="col-5 text-right">
        <mat-form-field appearance="outline" style="width: 100px">
          <mat-select id="month" name="month" (selectionChange)="PeriodChangeDMS($event)" [(value)]="selectedperiodDMS">
            <mat-option value="" disabled>Current Month</mat-option>
            <mat-option *ngFor="let item of periodListForDMS" [value]="item">
              {{ item }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row mt-3" *ngFor="let item of filterItemsOfTypeDMS(dmsData)" (click)="clickOnDMS(item.rdtype)">
      <div class="col-3">
        <div class="row">
          <div class="col-12">
            <h1 class="m-0 text_Per">{{item.percentage}}%</h1>
          </div>
        </div>
      </div>
      <div class="col-9">
        <div class="row mt-2">
          <div class="col-12">
            <div class="progress rounded-pill">
              <div class="progress-bar bg_sec" role="progressbar" [style.width.%]="item.sale" aria-valuenow="20"
                aria-valuemin="0" aria-valuemax="100"></div>
              <div class="progress-bar bg_pri" role="progressbar" [style.width.%]="item.primarysale" aria-valuenow="40"
                aria-valuemin="0" aria-valuemax="100"></div>
              <div class="progress-bar bg_pri" role="progressbar" [style.width.%]="item.tgt" aria-valuenow="40"
                aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-4 text-center">
            <h2 class="m-0 vp_Bar_value">{{item.sale}}<span class="text_10"> {{item.unit}}</span></h2>
          </div>
          <div class="col-4 text-center">
            <h2 class="m-0 vp_Bar_value">{{item.primarysale}}<span class="text_10"> {{item.unit}}</span></h2>
          </div>
          <div class="col-4 text-center">
            <h2 class="m-0 vp_Bar_value">{{item.tgt}}<span class="text_10"> {{item.unit}}</span></h2>
          </div>
        </div>
        <div class="row">
          <div class="col-4 text-center">
            <img class="img-fluid" src="assets/sec_dot.png" />
            <span class="ml-1 secondary_text">Secondary</span>
          </div>
          <div class="col-4 text-center">
            <img class="img-fluid" src="assets/pri_dot.png" />
            <span class="ml-1 primary_text">Primary</span>
          </div>
          <div class="col-4 text-center">
            <img class="img-fluid" src="assets/pri_dot.png" />
            <span class="ml-1 primary_text">Tgt</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="RequirementTypeForDMS == 'EC'">
    <div class="row mt-3">
      <div class="col-7" style="margin-top: 0.75rem;">
        <span class="text-left text_Sub align-middle">EC (DMS)</span>
      </div>
      <div class="col-5 text-right">
        <mat-form-field appearance="outline" style="width: 100px">
          <mat-select id="month" name="month" (selectionChange)="PeriodChangeDMS($event)" [(value)]="selectedperiodDMS">
            <mat-option value="" disabled>Current Month</mat-option>
            <mat-option *ngFor="let item of periodListForDMS" [value]="item">
              {{ item }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row mt-3" *ngFor="let item of filterItemsOfTypeDMS(dmsData)" (click)="clickOnDMS(item.rdtype)">
      <div class="col-3">
        <div class="row">
          <div class="col-12">
            <h1 class="m-0 text_Per">{{item.percentage}}%</h1>
          </div>
        </div>
      </div>
      <div class="col-9">
        <div class="row mt-2">
          <div class="col-12">
            <div class="progress rounded-pill">
              <div class="progress-bar bg_sec" role="progressbar" [style.width.%]="item.billedrla" aria-valuenow="20"
                aria-valuemin="0" aria-valuemax="100"></div>
              <div class="progress-bar bg_pri" role="progressbar" [style.width.%]="item.rla" aria-valuenow="40"
                aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-6 text-center">
            <h2 class="m-0 vp_Bar_value">{{item.billedrla}}</h2>
          </div>
          <div class="col-6 text-center">
            <h2 class="m-0 vp_Bar_value">{{item.rla}}</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-6 text-center">
            <img class="img-fluid" src="assets/sec_dot.png" />
            <span class="ml-1 secondary_text">Billed RLA</span>
          </div>
          <div class="col-6 text-center">
            <img class="img-fluid" src="assets/pri_dot.png" />
            <span class="ml-1 primary_text">Total RLA</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="RequirementTypeForDMS == 'Lines'">
    <div class="row mt-3">
      <div class="col-7" style="margin-top: 0.75rem;">
        <span class="text-left text_Sub align-middle">ULB</span>
      </div>
      <div class="col-5 text-right">
        <mat-form-field appearance="outline" style="width: 100px">
          <mat-select id="month" name="month" (selectionChange)="PeriodChangeDMS($event)" [(value)]="selectedperiodDMS">
            <mat-option value="" disabled>Current Month</mat-option>
            <mat-option *ngFor="let item of periodListForDMS" [value]="item">
              {{ item }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row mt-3" *ngFor="let item of filterItemsOfTypeDMS(dmsData)" (click)="clickOnDMS(item.rdtype)">

      <div class="col-12">
        <div class="row mt-2">
          <div class="col-12">
            <div class="progress rounded-pill">
              <div class="progress-bar bg_sec" role="progressbar" [style.width.%]="item.rla" aria-valuenow="20"
                aria-valuemin="0" aria-valuemax="100"></div>
              <div class="progress-bar bg_pri" role="progressbar" [style.width.%]="item.linebilled" aria-valuenow="40"
                aria-valuemin="0" aria-valuemax="100"></div>
              <div class="progress-bar bg_pri" role="progressbar" [style.width.%]="item.tgt" aria-valuenow="40"
                aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-4 text-center">
            <h2 class="m-0 vp_Bar_value">{{item.rla}}</h2>
          </div>
          <div class="col-4 text-center">
            <h2 class="m-0 vp_Bar_value">{{item.linebilled}}</h2>
          </div>
          <div class="col-4 text-center">
            <h2 class="m-0 vp_Bar_value">{{item.tgt}}</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-4 text-center">
            <img class="img-fluid" src="assets/sec_dot.png" />
            <span class="ml-1 secondary_text">Secondary ULB</span>
          </div>
          <div class="col-4 text-center">
            <img class="img-fluid" src="assets/pri_dot.png" />
            <span class="ml-1 primary_text">Primary ULB</span>
          </div>
          <div class="col-4 text-center">
            <img class="img-fluid" src="assets/pri_dot.png" />
            <span class="ml-1 primary_text">ULB Target</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <hr>


  <div class="row">
    <div class="col-12">
      <span class="text-left text_20 align-middle">Urjaa</span>
    </div>
  </div>


  <div class="row mt-3">
    <div class="col-12">
      <div class="scrollmenu">
        <div class="horizontal-scroll-wrapper squares ">
          <ul class="list-inline Box">
            <li class="list-inline-item">
              &nbsp;<label>
                <input type="checkbox" name="options" style="display:none;" autocomplete="off" class="Chkbtn" />
                &nbsp;<button [class]="expansionbtnclassforurjaa"
                  (click)="showDetailsForUrjaa('Village Productivity')"><span class="oiftitle">Village
                    Productivity</span></button>
              </label>
            </li>
            <li class="list-inline-item">
              <label>
                <input type="checkbox" name="options" style="display:none;" autocomplete="off" class="Chkbtn">
                &nbsp;<button [class]="ecforurjaabtnclass" (click)="showDetailsForUrjaa('EC')">
                  <span class="oiftitle">EC</span></button>
              </label>
            </li>
            <li class="list-inline-item">
              <label>
                <input type="checkbox" name="options" style="display:none;" autocomplete="off" class="Chkbtn">
                &nbsp;<button [class]="vcscanforurjaabtnclass" (click)="showDetailsForUrjaa('VC Scan')">
                  <span class="oiftitle">VC Scan</span></button>
              </label>
            </li>
            <li class="list-inline-item">
              <label>
                <input type="checkbox" name="options" style="display:none;" autocomplete="off" class="Chkbtn">
                &nbsp;<button [class]="ulbforurjaabtnclass" (click)="showDetailsForUrjaa('ULB')">
                  <span class="oiftitle">ULB</span></button>
              </label>
            </li>

          </ul>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="RequirementTypeForUrjaa == 'Village Productivity'">
    <div class="row mt-3">
      <div class="col-4 pr-0">
        <mat-form-field appearance="outline" style="width: 90px">
          <mat-select id="month" name="month" (selectionChange)="outletrangeChange($event)"
            [(value)]="selectedOutletRange">
            <mat-option value="" disabled>Select</mat-option>
            <mat-option *ngFor="let item of outletrangeListForVP" [value]="item">
              {{ item }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-4 p-0">
        <mat-form-field appearance="outline" style="width: 90px">
          <mat-select id="month" name="month" (selectionChange)="billslabChange($event)" [(value)]="selectedbillRange">
            <mat-option value="" disabled>Select</mat-option>
            <mat-option *ngFor="let item of billslabListForVP" [value]="item">
              {{ item }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-4 pl-0">
        <mat-form-field appearance="outline" style="width: 90px">
          <mat-select id="month" name="month" (selectionChange)="popslabChange($event)" [(value)]="selectedpopslab">
            <mat-option value="" disabled>Select</mat-option>
            <mat-option *ngFor="let item of popslabListForVP" [value]="item">
              {{ item }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row mt-3" *ngFor="let item of filterItemsOfTypeUrjaaVP(urjaaVP)" (click)="clickOnVillageProductivity()">
      <div class="col-3">
        <div class="row">
          <div class="col-12">
            <h1 class="m-0 text_Per">{{item.percentage}}%</h1>
          </div>
        </div>
      </div>
      <div class="col-9">
        <div class="row mt-2">
          <div class="col-12">
            <div class="progress rounded-pill">
              <div class="progress-bar bg_sec" role="progressbar" [style.width.%]="item.billedvillages"
                aria-valuenow="20" aria-valuemin="0" aria-valuemax="100"></div>
              <div class="progress-bar bg_pri" role="progressbar" [style.width.%]="item.totalvillages"
                aria-valuenow="40" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-6 text-center">
            <h2 class="m-0 vp_Bar_value">{{item.billedvillages}}</h2>
          </div>
          <div class="col-6 text-center">
            <h2 class="m-0 vp_Bar_value">{{item.totalvillages}}</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-6 text-center">
            <img class="img-fluid" src="assets/sec_dot.png" />
            <span class="secondary_text"> Billed Villages</span>
          </div>
          <div class="col-6 text-center">
            <img class="img-fluid" src="assets/pri_dot.png" />
            <span class="primary_text"> Total Villages</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="RequirementTypeForUrjaa == 'EC'">
    <div class="row mt-3">
      <div class="col-7" style="margin-top: 0.75rem;">
        <span class="text-left text_Sub align-middle">Effective Coverage</span>
      </div>
      <div class="col-5 text-right">
        <mat-form-field appearance="outline" style="width: 100px">
          <mat-select id="month" name="month" (selectionChange)="PeriodChange($event)"
            [(value)]="selectedperiodForUrjaaEC">
            <mat-option value="" disabled>Current Month</mat-option>
            <mat-option *ngFor="let item of periodListForUrjaaEC" [value]="item">
              {{ item }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row mt-3" *ngFor="let item of filterItemsOfTypeUrjaaEC(effectiveCoverageUrjaa)"
      (click)="clickOnUrjaaEC(item.valuetype)">
      <div class="col-3">
        <div class="row">
          <div class="col-12">
            <h1 class="m-0 text_Per">{{item.percentage}}%</h1>
          </div>
        </div>
      </div>
      <div class="col-9">
        <div class="row mt-2">
          <div class="col-12">
            <div class="progress rounded-pill">
              <div class="progress-bar bg_sec" role="progressbar" [style.width.%]="item.billedrla" aria-valuenow="20"
                aria-valuemin="0" aria-valuemax="100"></div>
              <div class="progress-bar bg_pri" role="progressbar" [style.width.%]="item.totrla" aria-valuenow="40"
                aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-6 text-center">
            <h2 class="m-0 vp_Bar_value">{{item.billedrla}}</h2>
          </div>
          <div class="col-6 text-center">
            <h2 class="m-0 vp_Bar_value">{{item.totrla}}</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-6 text-center">
            <img class="img-fluid" src="assets/sec_dot.png" />
            <span class="ml-1 secondary_text">Billed RLA</span>
          </div>
          <div class="col-6 text-center">
            <img class="img-fluid" src="assets/pri_dot.png" />
            <span class="ml-1 primary_text">Total RLA</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="RequirementTypeForUrjaa == 'VC Scan'">
    <div class="row mt-3">
      <div class="col-7" style="margin-top: 0.75rem;">
        <span class="text-left text_Sub align-middle">SUB VC Scan</span>
      </div>
      <div class="col-5 text-right">
        <mat-form-field appearance="outline" style="width: 100px">
          <mat-select id="month" name="month" (selectionChange)="PeriodChangeUrjaaVCSCan($event)"
            [(value)]="selectedperiodForUrjaaVCScan">
            <mat-option value="" disabled>Current Month</mat-option>
            <mat-option *ngFor="let item of periodListForUrjaaVCScan" [value]="item">
              {{ item }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row mt-3" *ngFor="let item of filterItemsOfTypeUrjaaVCScan(VScanUrjaa)"
      (click)="clickOnUrjaaVCScan(item.valuetype)">
      <div class="col-3">
        <div class="row">
          <div class="col-12">
            <h1 class="m-0 text_Per">{{item.percentage}}%</h1>
          </div>
        </div>
      </div>
      <div class="col-9">
        <div class="row mt-2">
          <div class="col-12">
            <div class="progress rounded-pill">
              <div class="progress-bar bg_sec" role="progressbar" [style.width.%]="item.totalAch" aria-valuenow="20"
                aria-valuemin="0" aria-valuemax="100"></div>
              <div class="progress-bar bg_pri" role="progressbar" [style.width.%]="item.totalOutlet" aria-valuenow="40"
                aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-6 text-center">
            <h2 class="m-0 vp_Bar_value">{{item.totalAch}} </h2>
          </div>
          <div class="col-6 text-center">
            <h2 class="m-0 vp_Bar_value">{{item.totalOutlet}} </h2>
          </div>
        </div>
        <div class="row">
          <div class="col-6 text-center">
            <img class="img-fluid" src="assets/sec_dot.png" />
            <span class="ml-1 secondary_text">Scan Outlet</span>
          </div>
          <div class="col-6 text-center">
            <img class="img-fluid" src="assets/pri_dot.png" />
            <span class="ml-1 primary_text">Total Outlet</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="RequirementTypeForUrjaa == 'ULB'">
    <div class="row mt-3">
      <div class="col-12 mt-2">
        <span class="text-left text_Sub align-middle">Lines</span>
      </div>
    </div>
    <div class="row mt-3 bg_ULB" (click)="clickOnUrjaaULB()">
      <div class="col-7 text-center mt-2">
        <span class="text-left text_16 align-middle">Unique Lines Billed</span>
      </div>
      <div class="col-5 text-center mt-2">
        <span class="text-left text_16 align-middle">{{cmulbUrjaa}}</span>
      </div>
    </div>
  </div>

  <div *ngIf="RequirementTypeForUrjaa == 'Villages'">
    <div class="row mt-3">
      <div class="col-4 mt-2">
        <span class="text-left text_Sub align-middle">Villages</span>
      </div>
      <div class="col-4">
        <select class="form-control rounded-pill" id="range">
          <option value="2k-5k">2k - 5k</option>
          <option value="6k-10k">6k - 10k</option>
        </select>
      </div>
      <div class="col-4">
        <select class="form-control rounded-pill" id="outlet">
          <option value="5 outlets">5 outlets</option>
          <option value="10 outlets">10 outlets</option>
        </select>
      </div>
    </div>
    <div class="row mt-3" (click)="clickOnVillages()">
      <div class="col-6 text-center">
        <span class="text_14">22 New Villages</span>
      </div>
      <div class="col-6 text-center">
        <span class="text_14">22 No Outlet Villages</span>
      </div>
    </div>
  </div>

  <div *ngIf="RequirementTypeForUrjaa == 'Rural Landscape'">
    <div class="row mt-3">
      <div class="col-4 mt-2">
        <span class="text-left text_Sub align-middle">Rural Landscape</span>
      </div>
      <div class="col-4">
        <select class="form-control rounded-pill" id="range">
          <option value="2k-5k">2k - 5k</option>
          <option value="6k-10k">6k - 10k</option>
        </select>
      </div>
      <div class="col-4">
        <select class="form-control rounded-pill" id="outlet">
          <option value="5 outlets">5 outlets</option>
          <option value="10 outlets">10 outlets</option>
        </select>
      </div>
    </div>
    <div class="row mt-3" (click)="clickOnVillages()">
      <div class="col-6 text-center">
        <span class="text_14">22 New Villages</span>
      </div>
      <div class="col-6 text-center">
        <span class="text_14">22 No Outlet Villages</span>
      </div>
    </div>
  </div>


</div>
<br>
<br>
<br>
<br>
<br>


<ng-template #openModalChannel let-modal>
  <div class="modal-header">
    <!-- <h4 class="modal-title text_color_head text_20" id="modal-basic-title">
      <img class="img-fluid" src="assets/thanks_icon.png" />
      <span class="ml-3">Thank You</span>
    </h4> -->
    <button type="button" class="close" aria-label="Close" (click)="modalClose()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row mt-4">
      <div class="col-4 text-center">
        <img class="img-fluid bg_Channel" src="assets/re.png" (click)="clickOnCommomPerformance('RE')" />
      </div>
      <div class="col-4 text-center">
        <img class="img-fluid bg_Channel" src="assets/p7.png" (click)="clickOnCommomPerformance('P7')" />
      </div>
      <div class="col-4 text-center">
        <img class="img-fluid bg_Channel" src="assets/milan.png" (click)="clickOnCommomPerformance('Milan')" />
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-4 text-center">
        <span (click)="clickOnCommomPerformance('RE')">RE</span>
      </div>
      <div class="col-4 text-center">
        <span (click)="clickOnCommomPerformance('P7')">P7</span>
      </div>
      <div class="col-4 text-center">
        <span (click)="clickOnCommomPerformance('Milan')">Milan</span>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-4 text-center">
        <img class="img-fluid bg_Channel" src="assets/br.png" (click)="clickOnCommomPerformance('BR')" />
      </div>
      <div class="col-4 text-center">
        <img class="img-fluid bg_Channel" src="assets/non_p7_vc.png" (click)="clickOnCommomPerformance('Non P7 VC')" />
      </div>
      <div class="col-4 text-center">
        <img class="img-fluid bg_Channel" src="assets/udaan.png" (click)="clickOnCommomPerformance('Udaan')" />
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-4 text-center">
        <span (click)="clickOnCommomPerformance('BR')">BR</span>
      </div>
      <div class="col-4 text-center">
        <span (click)="clickOnCommomPerformance('Non P7 VC')">Non P7 VC</span>
      </div>
      <div class="col-4 text-center">
        <span (click)="clickOnCommomPerformance('Udaan')">Udaan</span>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-4 text-center">
        <img class="img-fluid bg_Channel" src="assets/top_sku.png" (click)="clickOnCommomPerformance('Top SKU')" />
      </div>
      <div class="col-4 text-center">
        <img class="img-fluid bg_Channel" src="assets/npd.png" (click)="clickOnNPD()" />
      </div>
      <div class="col-4 text-center">
        <img class="img-fluid bg_Channel" src="assets/p7.png" (click)="clickOnCommomPerformance('P5')" />
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-4 text-center">
        <span (click)="clickOnCommomPerformance('Top SKU')">Top SKU</span>
      </div>
      <div class="col-4 text-center">
        <span (click)="clickOnNPD()">NPD</span>
      </div>
      <div class="col-4 text-center">
        <span (click)="clickOnCommomPerformance('P5')">P5</span>
      </div>
    </div>
    <br>
  </div>
</ng-template>