<div class="row mt-4">
  <div class="col-12">
    <span class="store_Text">RD Name</span>
  </div>
  <div class="col-12">
    <span class="store_Name">{{list.rdname}}</span>
    <span class="ml-1 store_Code">{{list.rdCode}}</span>
  </div>
</div>
<hr>
<div class="row">
  <div class="col-12">
    <span class="store_Text">FSSAI License Number</span>
  </div>
  <div class="col-12">
    <span class="store_Name">{{list.licenseNo}}</span>
  </div>
</div>
<hr>
<div class="row">
  <div class="col-12">
    <span class="store_Text">FSSAI License Validity From</span>
  </div>
  <div class="col-12">
    <span class="store_Name">{{list.fromDate}}</span>
  </div>
</div>
<hr>
<div class="row">
  <div class="col-12">
    <span class="store_Text">FSSAI License Expiry Date</span>
  </div>
  <div class="col-12">
    <span class="store_Name">{{list.expiryDate}}</span>
  </div>
</div>
<hr>
<div class="row">
  <div class="col-12">
    <span class="store_Text">Product Category</span>
  </div>
  <div class="col-12">
    <span class="store_Name">{{list.confectionary.toUpperCase()=='YES' ? 'Confectionary,' : ''}} {{list.biscuits.toUpperCase()=='YES' ? 'Biscuits,' : ''}} {{list.beverages.toUpperCase()=='YES' ? 'Beverages,' : ''}}{{list.others.toUpperCase()=='YES' ? 'Others' : list.others}} </span>
  </div>
</div>
<hr>
<div class="row mt-3">
  <div class="col-12">
    <span class="remark_Text">{{Remark}}</span>
  </div>
</div>
<br>
<br>
<br>
<br>
<br>
<div class="footer" *ngIf="IsValid">
  <div class="row">
    <div class="col-6 pr-0" (click)="reject()">
      <button class="btn btn_REJECT">REJECT</button>
    </div>
    <div class="col-6 pl-0" (click)="approve()">
      <button class="btn btn_APPROVE">APPROVE</button>
    </div>
  </div>
</div>
