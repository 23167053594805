<div>
    <div class="row mt-4">
        <div class="col-12">
            <input class="fullWidth" type="text" name="" id="" placeholder="Search Outlet" [(ngModel)]="searchTerm">
        </div>
    </div>

    <div *ngFor="let item of filteredList; let i = index;" (click)="rowClick(item)">
        <div class="row mt-3">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <span class="Name">{{item.OldOutletName}}</span>
                        <span class="ml-1 Code">{{item.OutletCode}}</span>
                        <br>
                        <span class="sub_pc_Name">{{item.RDName}}</span>
                        <span class="ml-1 sub_pc_Code">{{item.RDCode}}</span>
                        <br>
                        <span class="sub_pc_Name">Program : {{item.ProgramType}}</span>
                        <br>
                        <span class="sub_pc_Code">Visicooler : {{item.VisiCooler}}</span>
                        <br>
                        <span class="sub_pc_Code">SOTerritory : {{item.SOTerritory}}</span>
                        <br>
                        <span class="sub_pc_Code">ASMArea : {{item.ASMArea}}</span>
                    </div>
                </div>
            </div>
        </div>
    <!-- </div> -->

</div>
<br>
<br>
<br>
<br>
<br>