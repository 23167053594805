import { Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/service/data.service';
import { HeaderService } from 'src/app/service/header.service';
import { QRCodeData } from 'src/app/models/kpi-models';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';
import { NgxImageCompressService } from 'ngx-image-compress';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { EncryptionService } from 'src/app/service/encryption.service';
import { Enclass, ResultClass } from 'src/app/models/encryption-class';

@Component({
  selector: 'app-scan-qr-code-data-in-transaction',
  templateUrl: './scan-qr-code-data-in-transaction.component.html',
  styleUrls: ['./scan-qr-code-data-in-transaction.component.css']
})
export class ScanQrCodeDataInTransactionComponent implements OnInit {

  qrResult: any;
  output = "";
  deviceType: any;
  device: any;
  userType: string | null = '';
  userId: any;
  list: any;
  filteredList: any = [];
  fieldName: any;
  fieldValue: any;
  token: any;
  formname: any;
  actiondesc: any;

  public selectedVisicooler = "";

  coolerPhoto: any = "";
  outletPhoto: any = "";

  public photos: any = {
    CoolerPhoto: 0,
    OutletPhoto: 0
  }
  imgURL: any;
  photourlstr: string = '';
  public photoUploaded: any
  imgResultBeforeCompress: string = '';
  imgResultAfterCompress: string = '';
  file: any;
  localUrl: any;
  localCompressedURl: any;
  sizeOfOriginalImage: number = 0;
  sizeOFCompressedImage: number = 0;

  httpOptions: HttpHeaders =
    new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      // 'Content-Type': 'multipart/form-data',
      'enctype': 'multipart/form-data',
      'charset': 'UTF-8',
      'FolderName': 'QRCodeHistoryData',
    });
  visicoolerList: any = []
  OutletUID: any = ""
  qrCodeName: any = ""
  public enclass: Enclass = new Enclass();
  public resultClass: ResultClass = new ResultClass();
  secretKey: any;
  clientId: any;
  Soterritory: any = "";
  ASMArea: any = "";
  coolerPhoto1:any=""
  outletPhoto1:any=""
  constructor(private headerService: HeaderService, private es: EncryptionService, private toastr: ToastrService, private router: Router, private ds: DataService, private mds: MongoDataService,
    private notifyService: NotifyMeService, private modalService: NgbModal, private cs: CommonUtilitiesService, private imageCompress: NgxImageCompressService, private http: HttpClient) { }

  ngOnInit(): void {
    this.headerService.setTitle('QR Code Scan Data');
    this.headerService.setActiveURl('/ScanQrCodeDataInTransaction');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.token = localStorage.getItem("TokenNo");
    this.userType = localStorage.getItem("UserType");
    this.userId = localStorage.getItem("UserID");
    this.fieldName = localStorage.getItem("FieldName");
    this.fieldValue = localStorage.getItem("FieldValue");
    this.qrCodeName = localStorage.getItem("qrCodeName")
    this.formname = "QRCodeScanData";
    this.actiondesc = "QrCode Scan Data";

    this.secretKey = localStorage.getItem("SecreteKey")
    this.clientId = localStorage.getItem("ClientID");
    this.Soterritory = localStorage.getItem("Soterritory");
    this.ASMArea = localStorage.getItem("ASMArea");
    this.cs.GetGeoLocation(this.userId, this.userType, this.formname, this.actiondesc, this.fieldName, this.fieldValue);

    this.GetVisicoolerList()
  }


  getOutletDeatils() {
    if(this.OutletUID==""){
      this.filteredList=[]
    }
    let request = {
      "OutletCode": this.OutletUID,
      "ASMArea": this.ASMArea,
      "SOTerritory": this.Soterritory
    }
    this.es.Word = this.es.WordKey;
    this.es.encrypt(this.secretKey);
    this.es.Word = JSON.stringify(request);
    this.enclass.buggerstring = this.es.encrypt(this.secretKey)
    this.mds.GetOutletMasterDetails(this.enclass, this.token, this.device, this.deviceType, "qr code scan data", this.clientId).subscribe(res => {
      console.log("getSurveydetails", res)

      this.resultClass = res;
      this.es.Word = this.resultClass.resultString;
      let response = JSON.parse(this.es.decrypt(this.secretKey));

      console.log("response", response)
      // this.list = res;
      this.list = this.filteredList = response;
      console.log("filteredList", this.filteredList)

      // if (this.filteredList?.length == 0) {
      //   this.toastr.warning("Outlet UID not found")
      // }
    }, (error) => {
      console.log("error", error)
    });
  }


  GetVisicoolerList() {
    debugger
    let request = "all"
    this.ds.GetVisicoolerList(request).subscribe(res => {
      this.visicoolerList = res;
      console.log("this.visicoolerList", this.visicoolerList)
    }, (error: any) => {
      console.log("error:Invalid API", error)
    },
    )
  }


  changeOutletUId(event: any) {
    this.getOutletDeatils()
  }

  fileProgress(fileInput: any, photoUrl: string) {
    this.photos[photoUrl] += 1;

    this.imgURL = <File>fileInput.target.files[0];

    if (fileInput.target.files.length === 0) {
      return;
    }


    let fileToUpload = <File>fileInput.target.files[0];
    this.photoUploaded = fileToUpload

    if (fileToUpload.name.toLowerCase().lastIndexOf('.jpg') == -1 && fileToUpload.name.toLowerCase().lastIndexOf('.jpeg') == -1) {
      console.log("Other file format...");
      this.notifyService.showWarning("Please upload jpg or jpeg format only", 'Sales Genie');
      return;
    }

    this.imgURL = fileToUpload;
    const formData = new FormData();
    this.loaddatatoMemberVariable(fileToUpload.name, photoUrl);

    var fileName: any;
    this.file = fileInput.target.files[0];
    fileName = this.file['name'];
    if (fileInput.target.files && fileInput.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.localUrl = event.target.result;
        var orientation = -1;
        this.sizeOfOriginalImage = this.imageCompress.byteCount(this.localUrl) / (1024 * 1024);

        this.imageCompress.compressFile(this.localUrl, orientation, 50, 50).then(result => {
          this.imgResultAfterCompress = result;
          this.localCompressedURl = result;
          this.sizeOFCompressedImage = this.imageCompress.byteCount(result) / (1024 * 1024)
          // create file from byte
          const imageName = fileName;
          // call method that creates a blob from dataUri
          const imageBlob = this.dataURItoBlob(this.imgResultAfterCompress.split(',')[1]);
          //imageFile created below is the new compressed file which can be send to API in form data
          const imageFile = new File([imageBlob], imageName, { type: 'image/jpeg' });

          formData.append('file', imageFile, this.photourlstr);

          this.http.post(this.mds.url + 'MeraNet/UploadFileToS3Bucket', formData, { reportProgress: true, observe: 'events', headers: this.httpOptions, responseType: 'blob' as 'json' }).subscribe(res => {
            console.log("ATOBUploadFile", res)
            this.preview(photoUrl);
          },
            (error: any) => { console.log("error", error) });
        });
      }
      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

  loaddatatoMemberVariable(filename: string, photoUrl: string) {
    debugger
    switch (photoUrl) {
      case 'CoolerPhoto':
        this.photourlstr = this.OutletUID.toString() +"_Visi" + filename.substring(filename.indexOf('.'));
        this.coolerPhoto1 = this.photourlstr;
        break;
      case 'OutletPhoto':
        this.photourlstr = this.OutletUID?.toString() + "_OutExt" + filename.substring(filename.indexOf('.'));
        this.outletPhoto1 = this.photourlstr;
        break;
    }
  }

  dataURItoBlob(dataURI: string) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }

  preview(photoUrl: string) {
    // Show preview
    debugger
    var mimeType = this.imgURL.type;
    if (mimeType?.match(/image\/*/) == null) {
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(this.imgURL);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
      switch (photoUrl) {
        case 'CoolerPhoto':
          this.coolerPhoto = this.imgURL;
          break;
        case 'OutletPhoto':
          this.outletPhoto = this.imgURL;
          break;
      }
    }
  }

  clickOnSubmit() {
    console.log("coolerPhoto", this.coolerPhoto)
    console.log("outletPhoto", this.outletPhoto)
    console.log("selectedVisicooler", this.selectedVisicooler)
    if (this.OutletUID == "") {
      this.toastr.warning("Please Enter Outlet UID")
      return;
    }
    if (this.filteredList[0]?.Outletname?.length == 0) {
      this.toastr.warning("Please Enter valid outlet UID")
      return;
    }
    if (this.selectedVisicooler == "") {
      this.toastr.warning("Please Select Visicooler")
      return;
    }
    if (this.coolerPhoto1 == "") {
      this.toastr.warning("Please Upload Visicooler Photo")
      return;
    }
    if (this.outletPhoto1 == "") {
      this.toastr.warning("Please upload outlet exterior Photo")
      return;
    }
    let request = {
      OutletCode: this.OutletUID,
      OutletName: this.filteredList[0]?.Outletname,
      QRCode: this.qrCodeName,
      Visicooler: this.selectedVisicooler,
      VisicoolerPhoto: this.coolerPhoto1,
      OutletExteriorPhoto: this.outletPhoto1,
      DataSource: "so",
      CreatedBy: this.userId,
      Lattitude: this.cs.lat?.toString(),
      Longitude: this.cs.lng?.toString()
    }
    this.ds.ATS_AmmsSaveQRHistroryData(request, this.token).subscribe(res => {
      debugger
      console.log("res", res)
      if (res[0].msg?.toLowerCase() == "ok") {
        this.toastr.success("Data Save Successfully")
        this.router.navigate(['/home/ScanQrCodeInTransaction'])
      } else {
        this.toastr.warning(res[0].msg)
        this.router.navigate(['/home/ScanQrCodeInTransaction'])

      }
    },
      error => {
        console.log("error:Invalid API")
      },
    )
  }

  numberOnly(event: { which: any; keyCode: any; }): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

}
