import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from 'src/app/service/header.service';
import {statusreport} from 'src/app/models/kpi-models';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { InputPara } from '../../models/kpi-models';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-healthstatus',
  templateUrl: './healthstatus.component.html',
  styleUrls: ['./healthstatus.component.css']
})
export class HealthstatusComponent implements OnInit {
  input:InputPara=new InputPara();
  UserType: any;
  device: any;
  deviceType: any;
  UserID: any;
  FieldName: any;
  FieldValue: any;
  DataType:any;
  statusobj:Array<statusreport>=new Array<statusreport>();
  list: any;
  selfstatus: number=0;
  selfupdated: any;
  sestatus: number=0;
  seupdated: any;
  rdstatus: number=0;
  rdupdated: any;
  pcstatus: number=0;
  pcupdated: any;
  reasonlist: any;
  firstchk: any;
  secondchk: any;
  defaultchk: any;
  token: any;
  formname:any;
  actiondesc:any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private headerService: HeaderService,
    private notifyMeService: NotifyMeService,
    private mds: MongoDataService,
    private cs:CommonUtilitiesService
  ) { }

  ngOnInit(): void {
    this.headerService.setActiveURl('/Healthstaus');
    this.headerService.setTitle("Health Status of Team");
    this.UserType = localStorage.getItem("UserType");
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.token = localStorage.getItem("TokenNo");
    this.UserID = localStorage.getItem("UserID");
    this.FieldName= localStorage.getItem("OriginalFieldName");
    this.FieldValue= localStorage.getItem("OriginalFieldValue");
    this.DataType=this.FieldName.toUpperCase()=='ASMAREA'? 'ASM':'SE';
    this.statusobj.push({tagname:'healthstatus',fieldname :this.FieldName,fieldvalue :this.FieldValue,
    branch :'',region:'',asmarea:this.FieldName.toUpperCase()=='ASMAREA' ? this.FieldValue:''
    ,soterritory :this.FieldName.toUpperCase()=='SOTERRITORY' ? this.FieldValue:'',rdcode :0,rdname :'',
    rdtype:'', pcuid :0,pccode :'',pcname :'',code :this.DataType,codename:this.FieldName,status:1})

    this.formname="Healthstatus";
    this.actiondesc="Health Status";
    this.cs.GetGeoLocation(this.UserID,this.UserType,this.formname,this.actiondesc,this.FieldName,this.FieldValue);
    this.getreasondata();
    this.loaddata();
  }
  OnClickLink(e : any){
    this.router.navigate(['/Healthstatuslist/',this.FieldName, this.FieldValue,"0", e, this.UserID]);
  }
  loaddata(): void {
    this.input.FieldName=this.FieldName;
    this.input.FieldValue=this.FieldValue;
    this.input.TagName='0';
    this.input.Datatype="0";
    this.input.UserID=this.UserID;
    this.mds.GetHealthStatusAbt(this.input, this.token, this.device, this.deviceType,"Health Status").subscribe(res => {
    res.forEach((element: { desc: string;status:number; isupdated: any; }) => {
      if (element.desc == "self") {
        this.selfstatus = element.status;
        this.selfupdated = element.isupdated;
      }
      if (element.desc == "se") {
        this.sestatus = element.status;
        this.seupdated = element.isupdated;
      }
      if (element.desc == "rd") {
        this.rdstatus = element.status;
        this.rdupdated = element.isupdated;
      }
      if (element.desc == "pc") {
        this.pcstatus = element.status;
        this.pcupdated = element.isupdated;
      }
    });

  });

  }
  StatusChange(item:any,e: number): void {
    let index = this.statusobj.indexOf(item);
     this.statusobj[index].status = e;
  }
  btnClick(){
    this.mds.UpdateHealthStatusData(this.statusobj, this.token, this.device,this.deviceType,'PC Creation Setting').subscribe(res => {
      this.notifyMeService.showSuccess('Records Save Sucessfully', 'Sales Genie App');
      this.router.navigate(['/home/']);
      });
  }
  getreasondata(): void {
    this.input.FieldName=this.FieldName;
    this.input.FieldValue=this.FieldValue;
    this.input.TagName='pflag';
    this.input.Datatype='0';
    this.input.UserID=this.UserID;
    this.mds.GetReasonList(this.input, this.token, this.device, this.deviceType,"Health Status").subscribe(res => {
      this.reasonlist= res;
      this.firstchk=res[0].reason;
      this.secondchk=res[1].reason;
      this.defaultchk= res[0].reason=='Y'? 'first':'second';

    });
  }
}
