import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { GetNewRLADashboard,InputPara } from 'src/app/models/kpi-models';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterComponent } from '../filter/filter.component';
import { MatDialog } from '@angular/material/dialog';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { DataService } from 'src/app/service/data.service';

@Component({
  selector: 'app-new-rla',
  templateUrl: './new-rla.component.html',
  styleUrls: ['./new-rla.component.css']
})
export class NewRLAComponent implements OnInit {
  FieldName: any;
  FieldValue: any;
  ParaName: any;
  UserID: any;
  getNewRLADashboard: any;
  periodList: any[] = [];
  selectedperiod: any;
  UserType: any;
  device: any;
  deviceType: any;
input:InputPara=new InputPara();
  token: any ;
  lat:any;
  lng:any;

  constructor(private activatedRoute: ActivatedRoute, private dialog: MatDialog,
    private fb: FormBuilder,private router: Router,
     private headerService: HeaderService, private mds: MongoDataService,
     private notifyMeService: NotifyMeService,private ds: DataService,) { }

  ngOnInit(): void {
    this.selectedperiod=localStorage.getItem("selectedperiod");
    this.headerService.setActiveURl('/NewRLA');
    this.headerService.setTitle("NET RLA");
    this.UserType = localStorage.getItem("UserType");
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.UserID = localStorage.getItem("UserID");
    this.token = localStorage.getItem("TokenNo");
    this.ParaName = "0";
    this.getNewRLADashboard = [];
    this.activatedRoute.paramMap.forEach(params => {
      this.selectedperiod = params.get('selectedperiod');
      this.FieldName = params.get('FieldName');
      this.FieldValue = params.get('FieldValue');
      this.ParaName = params.get('ParaName');
      debugger
      this.GetGeoLocation();
      this.loadData();
    });
  }

  GetGeoLocation() {
    debugger
    if (navigator) {
      navigator.geolocation.getCurrentPosition(pos => {
        this.lat = pos.coords.latitude;
        console.log("  this.lat", this.lat)
        this.lng = pos.coords.longitude;
        this.SetapplicationLogData();
      }, this.handleLocationError, { timeout: 30000 });
    }
    else {
      this.notifyMeService.showError('Location Could not be captured', 'Mera APP');
    }
  }

  handleLocationError(error: { code: any; }) {
    switch (error.code) {
      case 3:
        this.notifyMeService.showError('Timeout in Position capture', 'Mera APP V2');
        break;
      case 2:
        this.notifyMeService.showError('Device is not capable of Position capture', 'Mera APP V2');
        break;
      case 1:
        this.notifyMeService.showError('You denied for Position capture', 'Mera APP V2');
        break;
    }
  }


  SetapplicationLogData() {
    debugger
    let request = {
      "userid": this.UserID,
      "usertype": this.UserType,
      "formname": "NewRLA",
      "actiondesc": "NewRLA" + " " + this.FieldName,
      "fieldname": this.FieldName,
      "fieldvalue": this.FieldValue,
      "latitude": this.lat,
      "longitude": this.lng
    }
    this.ds.SetapplicationLogData(request).subscribe(res => {
      console.log("res", res)
    })
  }
  
  loadData(): void {
    this.input.FieldName=this.FieldName;
    this.input.FieldValue=this.FieldValue;
    this.input.TagName=this.ParaName;
    this.input.Datatype= this.ParaName;
    this.input.UserID=this.UserID;
    this.mds.getNewRLAdata(this.input,this.token,this.device, this.deviceType,'New RLA').subscribe(res => {
      this.getNewRLADashboard = res as GetNewRLADashboard;
      this.periodList = [...new Set(res.map((bill: { tagname: any; }) => bill.tagname))].sort();
    });
  }
  PeriodChange(e: any): void {
    this.selectedperiod =e.source._value;
  }
  filterItemsOfType(type: any) {
    return type != null ? type.filter((x: { [x: string]: any; }) => (x['tagname'] == this.selectedperiod)) : null;
  }
  RowClick(e:any): void{
    localStorage.setItem("ParaName",e);
    this.router.navigate(['/home/NewRLAData/',this.FieldName,this.FieldValue,localStorage.getItem("ParaName"),this.UserID,this.selectedperiod]);
  }
  openFilter(){
    if (this.dialog.openDialogs.length == 0) {
      const dialogRef = this.dialog.open(FilterComponent, {
        closeOnNavigation: true,
        data: {
          "FieldName": localStorage.getItem("OriginalFieldName"),
          "FieldValue": localStorage.getItem("OriginalFieldValue"),
          "UserID": this.UserID,
          "MenuUpTo": "4",
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.FieldName=result.FieldName;
          this.FieldValue=result.FieldValue;
          this.loadData();
              }
      });
    }
  }
}
