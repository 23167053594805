<div>
    <div class="row mt-4">
        <div class="col-8">
            <i class="fa fa-caret-left" style="color: #757575;">&nbsp;&nbsp;
                <span class="header_text">{{FieldValue=='0'? 'All India' : FieldValue=='ALLINDIA' ? 'All India': FieldValue |titlecase}}</span></i>
        </div>
        <div *ngIf="!isASMArea" class="col-4 text-right" (click)="openFilter()" >
            <img src="../../../assets/filter.png" class="img-fluid" /><sup><i
                    class="fa fa-circle fa-sm" style="color: #eaab5e;font-size: 5px;"></i></sup>&nbsp;<span
                class="filter_text">Filter</span>
        </div>
    </div>
    <br>
    <div *ngIf="!isASMArea" class="row">
        <div class="col-4">

        </div>
        <div class="col-8 text-right">
            <i class="fa fa-circle fa-sm" style="color: #eaab5e; font-size: 6px;">&nbsp;&nbsp;<span
                    class="Secondary_text">{{DataType=='RURAL'? 'Day One Meeting':'Gate Meeting'}}</span></i>&nbsp;&nbsp;&nbsp;

            <i class="fa fa-circle fa-sm" style="color: #ebe6f1; font-size: 6px;">&nbsp;&nbsp;<span
                    class="Secondary_text">Total SE</span></i>
        </div>
    </div>
    <br *ngIf="!isASMArea">
    <div *ngIf="!isASMArea" style="overflow: auto;">
        <div class="row mt-2" *ngFor="let item of gateMeeting"
            (click)="RowClick(item.paraValue, item.paraName)">
            <div class="col-12">
                <div class="row">
                    <div class="col-12">
                        <span class="Head_text">{{item.paraValue}}</span>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-3">
                        <div class="row mt-2">
                            <div class="col-12">
                                <span class="Value_text">{{item.sePer | number: '1.0-0'}}%</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-9">
                        <div class="row">
                            <div class="col-12 text-right">
                                <span class="Attendedper_text">{{item.pcPer}}% <span class="Attended_text">{{DataType=='RURAL'? 'TSI':'PC'}} Attended</span></span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="progress rounded-pill">
                                    <div class="progress-bar bg_sec" role="progressbar" [style.width.%]="item.gateMeetingCount"
                                        aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                    <div class="progress-bar bg_pri" role="progressbar" [style.width.%]="item.seCount"
                                        aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-6 text-center">
                                <span class="val_text">{{item.gateMeetingCount}}</span>
                            </div>
                            <div class="col-6 text-center">
                                <span class="val_text">{{item.seCount}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="hr_cust">
            </div>
        </div>
    </div>
    <div *ngIf="isASMArea" style="overflow: auto;">
        <div class="row mt-2">
            <div class="col-12">
                <span class="header_text">Meeting Details</span>
            </div>
        </div>
        <div class="row mt-2" *ngFor="let item of gateMeeting">
            <div class="col-10">
                <div class="row mt-2">
                    <div class="col-12">
                        <span class="rdheading">{{item.soName}}</span>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-7">
                        <span class="type_text">Type </span><span class="subhead_text">{{item.meetingType}}</span>
                    </div>
                    <div class="col-5 p-0 text-left">
                        <span class="type_text">{{DataType=='RURAL'? 'TSI':'PC'}} Attended </span><span class="subhead_text">{{item.noOfPCPresent}}</span>
                    </div>
                </div>
            </div>
            <div class="col-2 p-0 text-center" (click)="open(ImageView, item.photoPath)">
                <button class="button buttonimg"></button>
            </div>

        </div>
        <hr class="hr_cust">
    </div>
    <br><br><br><br><br><br>
</div>

<ng-template #ImageView let-modal>
  <div class="modal-header text-center">
    <!-- <h4 class="modal-title text_color_head text_20" id="modal-basic-title">Forgot Password </h4> -->
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
        <img [src]="photo" class="img-fluid" />
    </div>
  </div>
</ng-template>
