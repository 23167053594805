import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/service/data.service';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-gate-meetinglist',
  templateUrl: './gate-meetinglist.component.html',
  styleUrls: ['./gate-meetinglist.component.css']
})
export class GateMeetinglistComponent implements OnInit {
  list : any;
  deviceType: any;
  device: any;
  FormName: any;
  UserID: any;
  MeetingID: any;
  lat: number=0;
  lng: number=0;
  photo: any;
  showModal: boolean=false;
  selectedimagepath: any;
  token: any;
  formname:any;
  actiondesc:any;
  FieldName: any;
  FieldValue: any;
  userType:any;

  constructor(private headerService: HeaderService,private cs:CommonUtilitiesService,private activatedRoute: ActivatedRoute,  private router: Router,private ds: DataService) { }

  ngOnInit(): void {
    this.headerService.setTitle("Gate Meeting");
    this.headerService.setActiveURl('/GateMeetinglist');
    this.device = localStorage.getItem('Device');
    this.deviceType = localStorage.getItem('DeviceType');
    this.token = localStorage.getItem("TokenNo");
    this.FormName = 'GateMeetinglist';
    this.UserID = localStorage.getItem('UserID');
    this.userType = localStorage.getItem("UserType");
    this.FieldName=localStorage.getItem("FieldName");
    this.FieldValue=localStorage.getItem("FieldValue");
    this.MeetingID = '0';
    this.list = [];
    this.selectedimagepath="";
    
    this.formname="GateMeetinglist";
    this.actiondesc="Gate Meeting list";
    this.cs.GetGeoLocation(this.UserID,this.userType,this.formname,this.actiondesc,this.FieldName,this.FieldValue);
    this.loadData();
  }
  loadData(){
    debugger
    this.ds.getMeetingDetails(this.UserID, this.MeetingID, this.token, this.device,this.deviceType,this.FormName).subscribe(res => {
      this.list = res;
      if(this.list.length > 0) {
        this.list.forEach((element: any) => {
          if(element.photoPath != '') {
            this.imagesDownload(element.photoPath, element.meetingID)
          }
        });
      }
    });
  }
  CheckIN(){
    this.router.navigate(['home/GateMeetingnew/',"0"]);
  }
  rowClick(meetingid: any)
  {
    this.router.navigate(['home/GateMeetingnew/',meetingid]);
  }

  imagesDownload(filename:any, meetingID: any) {
    // const dataToSend = {
    //   filename: filename,
    // };
    // this.ds.gateMeetingDownload(dataToSend, this.token, this.device, this.deviceType, 'GateMeetinglist').subscribe((res)=>{
    //   this.createImageFromBlob(res.body,meetingID)
    // }, (err)=>{
    // });
debugger
    this.ds.getDownloadFileFromS3Bucket("GateMeeting", filename).subscribe((res) => {
      this.createImageFromBlob(res.body,meetingID)
    }, (error) => { console.log("error", error) });
  }

  createImageFromBlob(image: Blob,meetingID:any) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      this.photo= reader.result;
      this.list.forEach((item: any) => {
        if(item.meetingID == meetingID) {
          item["photo"] = this.photo
        }
      });
    }, false);
     if (image) {
       reader.readAsDataURL(image);
     }
  }
  show(imagepath:any)
  {

    debugger;
    this.selectedimagepath=imagepath;
    this.showModal = true; // Show-Hide Modal Check

  }
  //Bootstrap Modal Close event
  hide()
  {
    this.showModal = false;
  }
}
