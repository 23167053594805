import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class HeaderService {
  public title = new BehaviorSubject('Title');
  public activeURl = new BehaviorSubject('/home');
  public profileImage = new BehaviorSubject('');

  constructor() { }

  setTitle(title: any) {
    this.title.next(title);
  }

  setActiveURl(activeURl: any) {
    this.activeURl.next(activeURl);
  }

  setProfileImage(profileImage: any) {
    this.profileImage.next(profileImage);
  }

}
