import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { DataService } from 'src/app/service/data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InputPara } from '../../models/kpi-models';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-erods-voucher-approval',
  templateUrl: './erods-voucher-approval.component.html',
  styleUrls: ['./erods-voucher-approval.component.css']
})
export class ErodsVoucherApprovalComponent implements OnInit {
  @ViewChild('openModalThankYou', { static: true }) elRefOpenModalThankYou: ElementRef | undefined;
  deviceType: any;
  device: any;
  userType: string | null = '';
  userId: any;
  list: any;
  reasonList: any;
  fieldName: any;
  fieldValue: any;
  erodsVoucherApproval: any;
  input:InputPara=new InputPara();
  FieldName: any;
  FieldValue: any;
  selectedReason: string = '';
  uidList: any[] = [];
  selectedReasonList: any[] = [];
  finalUIDList: string = '';
  finalReasonList: string = '';
  token: any;
  formname:any;
  actiondesc:any;

  constructor(private activatedRoute: ActivatedRoute,private cs:CommonUtilitiesService, private router: Router, private headerService: HeaderService, private mds: MongoDataService, private notifyMeService:NotifyMeService, private ds: DataService, private modalService: NgbModal,) { }

  ngOnInit(): void {
    this.headerService.setTitle('Voucher');
    this.headerService.setActiveURl('/erodsVoucherApproval');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.token = localStorage.getItem("TokenNo");
    this.userType = localStorage.getItem("UserType");
    this.userId = localStorage.getItem("UserID");
    this.FieldName= localStorage.getItem("OriginalFieldName");
    this.FieldValue= localStorage.getItem("OriginalFieldValue");
    this.erodsVoucherApproval = JSON.parse(localStorage.getItem("erodsVoucherApproval") || '{}');
    this.FieldName= localStorage.getItem("OriginalFieldName");
    this.FieldValue= localStorage.getItem("OriginalFieldValue");

    this.formname="erodsVoucherApproval";
    this.actiondesc="Erods Voucher Approval"
    this.cs.GetGeoLocation(this.userId,this.userType,this.formname,this.actiondesc,this.FieldName,this.FieldValue);
    this.onLoad();
  }

  onLoad() {
    this.input.FieldName=this.FieldName;
    this.input.FieldValue=this.FieldValue;
    this.input.TagName='erods';
    this.input.Datatype='0';
    this.input.UserID=this.userId;
    this.mds.GetordermasterForErods(this.input, this.token, this.device, this.deviceType,"Health Status").subscribe(res => {
      console.log("GetordermasterForErods",res)
      this.reasonList = res;
    });

    this.ds.ERODS_GetClaimSummaryDetails(this.erodsVoucherApproval.erodsNo, this.token, this.device, this.deviceType, 'Voucher').subscribe(res => {
      console.log("ERODS_GetClaimSummaryDetails",res)
      this.list = res.table2;
      this.list.forEach((item: any) => {
        item["reason"] = '';
      });
    });
  }

  clickOnVoucherDetails(){
    this.router.navigate(['/home/erodsVoucherDetailsApproval']);
  }

  submit(){
    this.selectedReasonList = [];
    this.uidList = [];
    this.list.forEach((element: any) => {
      this.uidList.push(element.uid);
    });

    this.list.forEach((element: any) => {
      if(element.reason == '') {
        this.selectedReasonList.push('0');
      } else {
        this.selectedReasonList.push(element.reason);
      }
    });

    const uid = this.uidList.toString();
    const regexforUID = /,/gi;
    this.finalUIDList = uid.replace(regexforUID, '||');

    const reason = this.selectedReasonList.toString();
    const regexforReason = /,/gi;
    this.finalReasonList = reason.replace(regexforReason, '||');

    const dataToSend = {
      UserID: this.userId,
      ERODSNo: this.erodsVoucherApproval.erodsNo,
      UIDsel: this.finalUIDList,
      ASMRemark: this.finalReasonList,
    };
    console.log("dataToSend",dataToSend)
    this.ds.MA_ERODS_SetApproval(dataToSend, this.token, this.device, this.deviceType, 'erodsVoucherApproval').subscribe(res => {
      console.log("MA_ERODS_SetApproval",res)
      this.openModal(this.elRefOpenModalThankYou);
    });
  }

  openModal(modal: ElementRef<any> | undefined) {
    this.modalService.open(modal,
      {
        size: 'lg',
        backdrop: 'static',
        windowClass: '',
        keyboard: false,
        centered: true,
      })
  }

  modalClose() {
    this.modalService.dismissAll();
    this.router.navigate(['/home/erodsApproval']);
  }

  okay(){
    this.modalService.dismissAll();
    this.router.navigate(['/home/erodsApproval']);
  }

  reasonChange(e: any): void {
    this.selectedReason = e.source._value;
  }

}
