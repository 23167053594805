import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SaveFirReason } from 'src/app/models/kpi-models';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-missing-visicooler-outlet-detail',
  templateUrl: './missing-visicooler-outlet-detail.component.html',
  styleUrls: ['./missing-visicooler-outlet-detail.component.css']
})
export class MissingVisicoolerOutletDetailComponent implements OnInit {
  deviceType: any;
  device: any;
  userType: string | null = '';
  userId: any;
  outletSelectedMV: any;
  isVisicooler: string = '';
  outLetDetails: any;
  address: any;
  firList: any;
  saveFirReason: SaveFirReason = new SaveFirReason();
  slectedIndex: number = 0;
  lat: number = 0;
  lng: number = 0;
  FirStatus: string = '';
  token: any;
   formname:any;
  actiondesc:any;
  fieldName:any;
  fieldValue:any;

  @ViewChild('openConfirmationBox', { static: true }) elRefopenConfirmationBox: ElementRef | undefined;
  @ViewChild('openModalThankYou', { static: true }) elRefopenModalThankYou: ElementRef | undefined;

  constructor(private activatedRoute: ActivatedRoute,private cs:CommonUtilitiesService, private router: Router, private headerService: HeaderService, private mds: MongoDataService, private notifyMeService: NotifyMeService, private modalService: NgbModal,) { }


  ngOnInit(): void {
    this.headerService.setTitle('Outlet Details');
    this.headerService.setActiveURl('/missingVisicoolerOutletDetail');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.userType = localStorage.getItem("UserType");
    this.userId = localStorage.getItem("UserID");
    this.token = localStorage.getItem("TokenNo");
    this.fieldName = localStorage.getItem("FieldName");
    this.fieldValue = localStorage.getItem("FieldValue");
    this.outletSelectedMV = JSON.parse(localStorage.getItem("outletSelectedMV") || '{}');
    console.log("outletSelectedMV", this.outletSelectedMV);
    this.formname="missingVisicoolerOutletDetail";
    this.actiondesc="Missing Visicooler Outlet Detail";
    this.cs.GetGeoLocation(this.userId,this.userType,this.formname,this.actiondesc,this.fieldName,this.fieldValue);
    this.searchOutlet(this.outletSelectedMV.outletcode, 'FIR');
    this.GetGeoLocation();

  }

  searchOutlet(OutletUID: string, Status: string) {
    this.mds.getOutletDetailsPullout(OutletUID, this.token, this.userId, Status, this.device, this.deviceType, 'Missing Visicooler').subscribe(res => {
      this.outLetDetails = res;
      console.log("outLetDetails", this.outLetDetails);
      localStorage.setItem("outLetDetails", JSON.stringify(this.outLetDetails));
      if (this.outLetDetails.length > 0) {
        this.address = this.outLetDetails[0].address1;
      }
    });
  }

  missing() {
    this.FirStatus = "VisiMissing"
    // this.saveRecord();
    this.router.navigate(['/home/missingReason']);
  }

  found(modal: any) {
    this.slectedIndex = 1;
    this.FirStatus = "VisiFound"
    // this.saveRecord();
    this.modalService.open(modal,
      {
        size: 'lg',
        backdrop: 'static',
        windowClass: '',
        keyboard: false,
        centered: true,
      })
  }

  modalClose() {
    this.isVisicooler = '';
    this.modalService.dismissAll();
  }

  continue() {
    // this.saveRecord();
    this.modalService.dismissAll();
    this.router.navigate(['/home/scanQRCode']);
  }

  cancle(modal: any) {
    this.modalClose();
    // this.openModal(this.elRefopenConfirmationBox); 
    this.modalService.open(modal,
      {
        size: 'lg',
        backdrop: 'static',
        windowClass: '',
        keyboard: false,
        centered: true,
      })
  }

  cancleBtn() {
    this.found(this.elRefopenModalThankYou);
  }


  openModal(modal: ElementRef<any> | undefined) {
    this.modalService.open(modal,
      {
        size: 'lg',
        backdrop: 'static',
        windowClass: '',
        keyboard: false,
        centered: true,
      })
  }

  okay() {
    this.router.navigate(['/home/missingVisicooler']);
    this.modalClose();
  }

  // cancle(){
  //   this.modalClose();
  // }

  onItemChange(value: any) {
    localStorage.setItem("isVisicooler", JSON.stringify(this.isVisicooler));
  }

  saveRecord() {
    this.saveFirReason.UserId = this.userId;
    this.saveFirReason.FIRID = this.outletSelectedMV.firid;
    this.saveFirReason.OutletUID = this.outletSelectedMV.outletcode;
    this.saveFirReason.FirStatus = this.FirStatus;
    this.saveFirReason.ReasonID = this.slectedIndex;
    this.saveFirReason.RDCode = this.outletSelectedMV.rdCode;
    this.saveFirReason.Visicooler = this.outletSelectedMV.visicoolertype;
    this.saveFirReason.latitude = this.lat;
    this.saveFirReason.longitude = this.lng;
    console.log("saveFirReason", this.saveFirReason);
    this.mds.saveFIRRequest(this.saveFirReason, this.token, this.device, this.deviceType, 'Missing Visicooler').subscribe(res => {
      console.log("saveFIRRequest", res);
      this.firList = res;
      if (res[0].msg == 'OK') {
        // this.notifyMeService.showSuccess('Records Updated Sucessfully', 'Mera APP');
      }
    });
  }

  GetGeoLocation() {
    if (navigator) {
      navigator.geolocation.getCurrentPosition(pos => {
        this.lat = pos.coords.latitude;
        this.lng = pos.coords.longitude;
      }, this.handleLocationError, { timeout: 30000 });
    }
    else {
      this.notifyMeService.showError('Location Could not be captured', 'Mera APP');
    }
  }

  handleLocationError(error: { code: any; }) {
    switch (error.code) {
      case 3:
        this.notifyMeService.showError('Timeout in Position capture', 'Mera APP V2');
        break;
      case 2:
        this.notifyMeService.showError('Device is not capable of Position capture', 'Mera APP V2');
        break;
      case 1:
        this.notifyMeService.showError('You denied for Position capture', 'Mera APP V2');
        break;
    }
  }


}
