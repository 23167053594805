import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from 'src/app/service/header.service';
import { DataService } from 'src/app/service/data.service';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-pccreation-setting',
  templateUrl: './pccreation-setting.component.html',
  styleUrls: ['./pccreation-setting.component.css']
})
export class PccreationSettingComponent implements OnInit {
  PCCreationSettingForm = this.fb.group({
    Additional: [null],
    Replacement: [null]
  });
RDCode : any;
deviceType: any;
device: any;
List:any;
Additional: number = 0;
Replacement: number = 0;
  UserID: any;
  PccreationSDetails: any;
  token: any;
  formname: any;
  actiondesc: any;
  fieldName: any;
  fieldValue: any;
  userType:any;
  constructor(private fb:FormBuilder,private cs:CommonUtilitiesService,private activatedRoute: ActivatedRoute,private router: Router, private headerService: HeaderService,private ds: DataService, private notifyMeService: NotifyMeService) { }

  ngOnInit(): void {
    localStorage.setItem("RDCode",'');
    this.headerService.setTitle('PC Creation Setting');
    this.headerService.setActiveURl('/PccreationSetting');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.UserID = localStorage.getItem("UserID");
    this.token = localStorage.getItem("TokenNo");
    this.PccreationSDetails = JSON.parse(localStorage.getItem("PccreationSDetails") || '{}');
    this.RDCode=this.PccreationSDetails.rdcode;

    this.userType = localStorage.getItem("UserType");
    this.fieldName=localStorage.getItem("FieldName");
    this.fieldValue=localStorage.getItem("FieldValue");
    this.formname = "PccreationSetting";
    this.actiondesc = "Pc Creation Setting";
    this.cs.GetGeoLocation(this.UserID, this.userType, this.formname, this.actiondesc, this.fieldName, this.fieldValue);

    this.onLoad();
  }
  onLoad() {
      this.ds.getPCCreationSetting(this.RDCode, this.token, this.device, this.deviceType,'PC Creation Setting').subscribe(res => {
      this.List = res;
    });
  }
  EditPC(){
    localStorage.setItem("RDCode",this.RDCode);
    this.router.navigate(['/home/PccreationEdit']);
  }
  btnClick(){
    const PcCreationFormData = this.PCCreationSettingForm.value;
    this.Additional=(PcCreationFormData.Additional == null) ? 0 : PcCreationFormData.Additional;
    this.Replacement=(PcCreationFormData.Replacement == null) ? 0 : PcCreationFormData.Replacement;
    if(this.Additional!=0 || this.Replacement!=0)
    {
      const dataToSend = {
        RDCode : this.RDCode,
        UserID : this.UserID,
        Additional : 'A',
        NoOfAdditional :  this.Additional,
        Replacement :'R',
        NoOfReplacement : this.Replacement,
        Edit :  'E',
        EditPCUID : '',
      };
      // console.log(dataToSend);
          this.ds.setPCCreationSetting(dataToSend, this.token, this.device,this.deviceType,'PC Creation Setting').subscribe(res => {
          this.notifyMeService.showSuccess('Records Save Sucessfully', 'Sales Genie App');
          localStorage.removeItem("PccreationSDetails");
          this.gotonextpage();
          });
      }
      else{
        this.notifyMeService.showInfo('Please Input Value', 'Sales Genie App');
      }

  }
  gotonextpage(){
    this.router.navigate(['/home/PccrRdlist']);
  }
}
