<div>
  <div class="row mt-4">
    <div class="col-12">
      <!-- <button class="btn btn_Scan btn-block" type="button" (click)="startQrcodeScanner()" >Scan QR Code</button> -->
      <button class="btn btn_Scan btn-block" type="button" (click)="action.toggleCamera()">Scan QR Code</button>

      <br /><br />
      <div class="qr-scan-area">
        <!-- <zxing-scanner #scanner
                       [enable]="scannerEnabled"
                       [(device)]="currentDevice"
                       (scanSuccess)="onCodeResult($event)"
                       (permissionResponse)="onHasPermission($event)">
        </zxing-scanner> -->

        <ngx-scanner-qrcode #action="scanner" [config]="config" (data)="qrCodeResult($event)" (error)="onError($event)">
        </ngx-scanner-qrcode>
        <div class="qr-area">
          <div class="area"></div>
        </div>
      </div>
      <hr />
    </div>
    <div class="col-12 mt-4 text-center" *ngIf="qrResult">
      <img class="img-fluid" src="assets/qr_Icon.svg" />
    </div>
  
    <div class="col-12 mt-3 text-center" *ngIf="output">
      <span class="qr_Code_Success">QR Code - {{output}} <br>
        scanned successfully</span>
    </div>
  </div>

  <div  id="photo">
    <div class="row mt-4">
      <div class="col-3">
        <img [src]="coolerPhoto" alt="Image" *ngIf="coolerPhoto" class="photo">
        <div class="Rectangle" *ngIf="!coolerPhoto">
          <span class="Image">Image</span>
        </div>
      </div>
      <div class="col-7 mt-3 word_break"  id="CoolerPhoto">
        <span class="text_14 text_black">Cooler Photo</span>
      </div>
      <div class="col-2 mt-3 word_break">
        <img class="img-fluid" src="assets/camera.png" style="margin-top: -4px;" (click)="CoolerPhoto.click()" />
        <input #CoolerPhoto id="CoolerPhoto" type="file" accept="image/*" (change)="fileProgress($event,'CoolerPhoto')"
          style="display: none" />
      </div>
      <hr>
    </div>

    <div class="row" id="outletExterior">
      <div class="col-3">
        <img [src]="outletPhoto" alt="Image" *ngIf="outletPhoto" class="img-fluid photo">
        <div class="Rectangle" *ngIf="!outletPhoto">
          <span class="Image">Image</span>
        </div>
      </div>
      <div class="col-7 mt-3">
        <span class="text_14 text_black">Outlet Exterior Photo</span>
      </div>
      <div class="col-2 mt-3">
        <img class="img-fluid" src="assets/camera.png" style="margin-top: -4px;" (click)="OutletPhoto.click()" />
        <input #OutletPhoto id="OutletPhoto" type="file" accept="image/*" (change)="fileProgress($event,'OutletPhoto')"
          style="display: none" />
      </div>
      <!-- <hr> -->
    </div>
  </div>
  <br><br><br><br><br>
</div>

<ng-template #openConfirmationBox let-modal id="test">
  <div class="modal-header justify-content-center">
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12 text-center">
        <span class="model_PopUp_Text text-center">QR Code scanning is not completed.Do you want to continue ? </span>
      </div>
    </div>
  </div>
  <div class="modal-footer text-center justify-content-center">
    <button type="button" class="btn okBtn" (click)="okay()">Yes</button>
    <button type="button" class="btn cancleBtn" (click)="cancle()">No</button>
  </div>
</ng-template>





<div class="footer">
  <div class="row">
    <div class="col-12 text-center" (click)="saveRecords()">
      <button class="btn btn_Done">DONE</button>
    </div>
  </div>
</div>