import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { GetChannelPerformance } from 'src/app/models/encryption-class';
import { GetProgramtargetDetailsDashboard, Resultdata,InputPara } from 'src/app/models/kpi-models';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { FilterComponent } from '../filter/filter.component';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { DataService } from 'src/app/service/data.service';

@Component({
  selector: 'app-common-performance',
  templateUrl: './common-performance.component.html',
  styleUrls: ['./common-performance.component.css']
})
export class CommonPerformanceComponent implements OnInit {
  selected: string = '';
  myDate: any;
  curYear: any;
  curMonth: any;
  prevYear: string = '';
  branchName: string = '';
  UserID:any;
  dashboardValue: number = 0;
  isMonthClicked = false;
  isYearClicked = true;
  public data: GetChannelPerformance[] = [];
  public input:InputPara=new InputPara();
  selectedType: string = '';
  prevYearTwoDigit: string = '';
  public getAchProgramtargetList: GetProgramtargetDetailsDashboard[] = [];
  public getWOAProgramtargetList: GetProgramtargetDetailsDashboard[] = [];
  deviceType: any;
  device: any;
  resultdata:Resultdata=new Resultdata();
  achPer: number =0;
  achievement: number=0;
  target: number=0;
  woaPer: number=0;
  woaachievement: number=0;
  woatarget: number=0;
  selectedCategory: string='Month';
  selectedperiod: any;
  FieldValue: any;
  FieldName: any;
  FormName:any;
  unit: any;
  token: any ;
  lat:any;
  lng:any;
  UserType:any;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private headerService: HeaderService, private datePipe: DatePipe,
     private mds: MongoDataService, private dialog: MatDialog,private notifyMeService: NotifyMeService,private ds: DataService) { }

  ngOnInit(): void {
    this.headerService.setActiveURl('/CommomPerformance');
    this.FormName='CommomPerformance'
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.selected = this.activatedRoute.snapshot.queryParams["selected"];
    this.FieldName=localStorage.getItem("OriginalFieldName");
    this.FieldValue=localStorage.getItem("OriginalFieldValue");
    this.token = localStorage.getItem("TokenNo");
    this.myDate = Date.now();
    this.curMonth = this.datePipe.transform(this.myDate, 'MMM');
    this.curYear = this.datePipe.transform(this.myDate, 'yy');
    this.prevYear = JSON.stringify(new Date().getFullYear() - 1);
    this.prevYearTwoDigit = this.prevYear.substring(2, 4);

    this.UserID = localStorage.getItem("UserID");
    this.UserType=localStorage.getItem("UserType");
    debugger
    this.GetGeoLocation();
    this.selectedType = this.selected?.toLowerCase();
    if (this.selectedType == 'category') {
      this.selectedType = 'cat';
    } else if (this.selectedType == 'town class') {
      this.selectedType = 'townclass';
    }

    switch (this.selected) {
      case "RE": {
        this.headerService.setTitle('RE Performance');
        this.FormName='RE Performance';
        break;
      }
      case "P7": {
        this.headerService.setTitle('P7 Performance');
        this.FormName='P7 Performance';
        break;
      }
      case "Milan": {
        this.headerService.setTitle('Milan Performance');
        this.FormName='Milan Performance';
        break;
      }
      case "BR": {
        this.headerService.setTitle('Balance Retailer');
        this.FormName='Balance Retailer';
        break;
      }
      case "Non P7 VC": {
        this.headerService.setTitle('Non P7 VC');
        this.FormName='Non P7 VC';
        this.selectedType="nonp7vc";
        break;
      }
      case "Udaan": {
        this.headerService.setTitle('Udaan Performance');
        this.FormName='Udaan Performance';
        break;
      }
      case "Top SKU": {
        this.headerService.setTitle('Top SKU');
        this.selectedType="topsku";
        this.FormName='Top SKU';
        break;
      }
      case "Category": {
        this.headerService.setTitle('Category Performance');
        this.FormName='Category Performance';
        break;
      }
      case "Town Class": {
        this.headerService.setTitle('Town Class');
        this.FormName='Town Class';
        break;
      }
      case "NPD": {
        this.headerService.setTitle('NPD');
        this.FormName='NPD';
        break;
      }
      case "P5": {
        this.headerService.setTitle('P5 Performance');
        this.FormName='P5 Performance';
        break;
      }
      default: {
        console.log("Invalid choice");

        break;
      }
    }


    this.onMonthClick();
    if (this.selected == 'P7' || this.selected == 'Milan' || this.selected == 'Udaan' || this.selected=="P5") {
      this.getProgramtargetData(this.FieldName,this.FieldValue);
    }
  }

  GetGeoLocation() {
    debugger
    if (navigator) {
      navigator.geolocation.getCurrentPosition(pos => {
        this.lat = pos.coords.latitude;
        console.log("  this.lat", this.lat)
        this.lng = pos.coords.longitude;
        this.SetapplicationLogData();
      }, this.handleLocationError, { timeout: 30000 });
    }
    else {
      this.notifyMeService.showError('Location Could not be captured', 'Mera APP');
    }
  }

  handleLocationError(error: { code: any; }) {
    switch (error.code) {
      case 3:
        this.notifyMeService.showError('Timeout in Position capture', 'Mera APP V2');
        break;
      case 2:
        this.notifyMeService.showError('Device is not capable of Position capture', 'Mera APP V2');
        break;
      case 1:
        this.notifyMeService.showError('You denied for Position capture', 'Mera APP V2');
        break;
    }
  }


  SetapplicationLogData() {
    debugger
    let request = {
      "userid": this.UserID,
      "usertype": this.UserType,
      "formname": "CommomPerformance",
      "actiondesc": "Commom Performance Data" +" "+this.selected,
      "fieldname": this.FieldName,
      "fieldvalue": this.FieldValue,
      "latitude": this.lat,
      "longitude": this.lng
    }
    this.ds.SetapplicationLogData(request).subscribe(res => {
      console.log("res", res)
    })
  }

  onMonthClick() {
    this.isMonthClicked = true;
    this.isYearClicked = false;
    this.selectedCategory = 'Month';
    this.input.FieldName=this.FieldName;
    this.input.FieldValue=this.FieldValue;
    this.input.TagName=this.selectedType;
    this.input.Datatype=this.selectedCategory;
    this.input.UserID=this.UserID;

    debugger
    this.mds.getCommonPerformance(this.input, this.token, this.device, this.deviceType, this.FormName).subscribe(res => {
     this.data = res;
     debugger;
     this.unit=res[0].unit;
    });
  }

  onYearClick() {
    this.isYearClicked = true;
    this.isMonthClicked = false;
    this.selectedCategory = 'Year';
    this.input.FieldName=this.FieldName;
    this.input.FieldValue=this.FieldValue;
    this.input.TagName=this.selectedType;
    this.input.Datatype=this.selectedCategory;
    this.input.UserID=this.UserID;
  this.mds.getCommonPerformance(this.input, this.token, this.device, this.deviceType,this.FormName).subscribe(res => {
      this.data = res;
      this.unit=res[0].unit;
    });
  }

  clickOnAchievement(selected: any) {
    this.router.navigate(['/home/CommomPerformanceData/',this.FieldName, this.FieldValue, "achievement", this .UserID, selected]);
  }

  clickOnWOA(selected: any) {
    this.router.navigate(['/home/CommomPerformanceData/', this.FieldName, this.FieldValue, "woa", this.UserID, selected]);
  }
  getProgramtargetData(branchName:string,areaName:string) {
    let selectedType ='';
    if (this.selectedType == 'p7') {
      selectedType = 'p7tgt';
    } else if (this.selectedType == 'milan') {
      selectedType = 'milantgt';
    } else if (this.selectedType == 'udaan') {
      selectedType = 'udantgt';
    }
    else if (this.selectedType == 'p5') {
      selectedType = 'p5tgt';
    }
    
    this.input.FieldName=branchName;
    this.input.FieldValue=areaName;
    this.input.TagName=selectedType;
    this.input.Datatype="";
    this.input.UserID=this.UserID;
   this.mds.getProgramtargetDashboard(this.input, this.token, this.device, this.deviceType,this.FormName).subscribe(res => {

      this.getAchProgramtargetList = res.filter((singleItem: { [x: string]: string; }) => singleItem['type'].startsWith("achievement"));
      console.log("getAchProgramtargetList", this.getAchProgramtargetList);
      this.achPer = this.getAchProgramtargetList[0].percentage;
      this.achievement = this.getAchProgramtargetList[0].ach;
      this.target = this.getAchProgramtargetList[0].tgt;


      this.getWOAProgramtargetList = res.filter((singleItem: { [x: string]: string; }) => singleItem['type'].startsWith("WOA"));
      console.log("getWOAProgramtargetList", this.getWOAProgramtargetList);
      this.woaPer = this.getWOAProgramtargetList[0].percentage;
      this.woaachievement = this.getWOAProgramtargetList[0].ach;
      this.woatarget = this.getWOAProgramtargetList[0].tgt;

    });
  }
  openFilter(){
    if (this.dialog.openDialogs.length == 0) {
      const dialogRef = this.dialog.open(FilterComponent, {
        closeOnNavigation: true,
        data: {
          "FieldName": localStorage.getItem("OriginalFieldName"),
          "FieldValue": localStorage.getItem("OriginalFieldValue"),
          "UserID": this.UserID,
          "MenuUpTo": "5",
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.resultdata = result as Resultdata;
          this.input.FieldName=this.resultdata.FieldName;
          this.input.FieldValue=this.resultdata.FieldValue;
          this.input.TagName=this.selectedType;
          this.input.Datatype=this.selectedCategory;
          this.input.UserID=this.UserID;
          this.FieldName=this.resultdata.FieldName;
          this.FieldValue=this.resultdata.FieldValue;
          this.mds.getCommonPerformance(this.input, this.token, this.device, this.deviceType,this.FormName).subscribe(res => {
            this.data = res;
            this.unit=res[0].unit;
          });
          this.getProgramtargetData(this.resultdata.FieldName,this.resultdata.FieldValue);

              }
      });
    }
  }

  changeMonth(e: any) {
    this.selectedCategory = e.source._value;;
    if(this.selectedCategory == 'Year'){
      this.onYearClick();
    }else {
      this.onMonthClick();
    }
  }
}
