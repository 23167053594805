import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { DataService } from 'src/app/service/data.service';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-check-photo-of-outlet-and-agreement',
  templateUrl: './check-photo-of-outlet-and-agreement.component.html',
  styleUrls: ['./check-photo-of-outlet-and-agreement.component.css']
})
export class CheckPhotoOfOutletAndAgreementComponent implements OnInit {
  deviceType: any;
  device: any;
  userType: string | null = '';
  userId: any;
  list: any;
  filteredList: any;
  fieldName: any;
  fieldValue: any;
  token: any;
  public GoBtnClicked = "false"
  public outletDetails: any = [];
  formname:any;
  actiondesc:any;
  constructor(private headerService: HeaderService, private toastr: ToastrService,private cs:CommonUtilitiesService,
    private router: Router, private ds: DataService) { }

  ngOnInit(): void {
    this.headerService.setTitle('VC Deployment Status');
    this.headerService.setActiveURl('/VCDeploymentStatus');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.token = localStorage.getItem("TokenNo");
    this.userType = localStorage.getItem("UserType");
    this.userId = localStorage.getItem("UserID");
    this.fieldName = localStorage.getItem("FieldName");
    this.fieldValue = localStorage.getItem("FieldValue");

  
    this.formname="VCDeploymentStatus";
    this.actiondesc="VC Deployment Status"
    this.cs.GetGeoLocation(this.userId,this.userType,this.formname,this.actiondesc,this.fieldName,this.fieldValue);
  }

  numberOnly(event: { which: any; keyCode: any; }): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  GOBtn(outletCode: any) {
    debugger
    this.outletDetails=[];
    if (outletCode.value == "") {
      this.toastr.warning("Please enter outlet code");
      return;
    }
    this.GoBtnClicked = "true";
    let dataToSend=outletCode.value
    this.ds.ATS_GetOutletwiseDeploymentDetails(dataToSend).subscribe(res => {
     console.log("res",res);
     this.outletDetails=res;
     if(this.outletDetails.length==0){
       this.toastr.warning("Outlet code not found")
     }
    });
  }

  rowClick(item: any) {
    debugger
    localStorage.setItem("outletCodeDetails",JSON.stringify(item))
    this.router.navigate(['/home/OutletDetails']);

  }



}
