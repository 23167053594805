import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HostListener, Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
 
// declare global {
//   interface Window {
//       Android: {
//         sendNotificationToAndroid(title:string,message: string): void;
//       };
//   }
// }



@Injectable({
  providedIn: 'root'
})

export class NotificationService {
//  public url = 'https://devsalespulseapi.cilsales.net/api/';
  //  public url='https://salespulseapi.cilpwa.net/api/';
  // public url = 'https://testapi1.cilsales.net/api/';

  // public url = 'https://localhost:44319/api/';
  // constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }
  public url = environment.apiUrl;
  // public Android = {
  //   sendNotificationToAndroid: function(title:any, message:any) {
  //     var event = new CustomEvent('showNotification', { detail: { title: title, message: message } });
  //     document.dispatchEvent(event);
  //   }
  // };
  public fcmSendUrl="https://fcm.googleapis.com/fcm/send"
  // public fcmSendUrl="https://fcm.googleapis.com/v1/projects/devmeraapp/messages:send"
  // secretKey="AAAAfpZBtok:APA91bFg0XpotEIzx2N9Qv0myx3aKB7Kq4c6trkpBfRSANHiovBWU0JWO9Lm4jCw6Prepfo6bu0cJiJloCzbKS-WesPc4g-wB-Woj4BmnWn1UgFqwT0x6VBMW3IyyDFuk_6_NXXTCdef"
  secretKey="AAAAXsLVTcY:APA91bHeTCXqC1_4FLBz65OccTFooDv6BOdCHbMKbZ1Z1C0aQ_X8d1qleueRSYrUr6hpt_P-4O6YlJgF3Z4GITTxqGhthp3DBWEMJC5-FkSHKSPrt2OCWD5Ttw4zqff-gQpANm25rzN9"
  constructor(private http: HttpClient ) { }

  FCMSend(requestObj:any): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Authorization':"key=" + this.secretKey,
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': '*',
      })
    };
    console.log("httpOptions", httpOptions);
    return this.http.post<any>(this.fcmSendUrl , requestObj, httpOptions);
  }
  
  registerForPush = (UserID: string, TokenNo: string) =>
    this.http.post<any>(this.url + 'PushNotification/register', { UserID, TokenNo })

  sendNotification = (notification: any) =>
    this.http.post(this.url + 'PushNotification/send', notification)

    RemoveSubscription = (UserID: string, TokenNo: string) =>
    this.http.post<any>(this.url + 'PushNotification/RemoveSubscription', { UserID, TokenNo })

    IsRegisterForNotification(UserID: string): Observable<any> {
      let httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        })
      };
      console.log("httpOptions", httpOptions);
      return this.http.get<any>(this.url + "PushNotification/IsRegisterForNotification/" + UserID, httpOptions);

    }

    requestPermission(): Promise<NotificationPermission> {
      debugger
      return Notification.requestPermission();
    }

    @HostListener('window:showNotification', ['$event'])
    onShowNotification(event: CustomEvent) {
      const title = event.detail.title;
      const message = event.detail.message;
  
      // Handle the notification
      console.log(`Received notification: ${title} - ${message}`);
      // You can add further logic here to process the notification in Angular
    }

    sendNotificationToAndroid(): void {
      debugger
      // if (typeof this.Android !== 'undefined' && this.Android !== null) {
      //   this.Android.sendNotificationToAndroid("New Message", "You have received a new message!");
      // } else {
      //   console.error('Android interface not available');
      // }
      // (window as any).Android.showNotification("title","Hello from Angular!");
      // Android.showUser

    }

  //   showNativeNotification(title: string, message: string): void {
  //     debugger
  //     if (window.Android && window.Android.showNativeNotification) {
  //         window.Android.showNativeNotification(title, message);
  //     } else {
  //         console.warn('Android interface or method not available.');
  //     }
  // }
  }
