import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { DataService } from 'src/app/service/data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-facial-rec-details-approval',
  templateUrl: './facial-rec-details-approval.component.html',
  styleUrls: ['./facial-rec-details-approval.component.css']
})
export class FacialRecDetailsApprovalComponent implements OnInit {
  @ViewChild('openModalThankYou', { static: true }) elRefOpenModalThankYou: ElementRef | undefined;
  deviceType: any;
  device: any;
  userType: string | null = '';
  userId: any;
  facialRecDetails: any;
  showModal: boolean=false;
  selectedimagepath: any;
  photopath:any="https://cilsales.net/PCRegistration/";
  token: any;
  formname:any;
  actiondesc:any;
  fieldName: any;
  fieldValue: any;
  constructor(private activatedRoute: ActivatedRoute, private cs:CommonUtilitiesService,private router: Router, private headerService: HeaderService, private mds: MongoDataService, private ds: DataService, private modalService: NgbModal,) { }

  ngOnInit(): void {
    this.headerService.setTitle('Facial Rec Details');
    this.headerService.setActiveURl('/facialRecDetailsApproval');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.token = localStorage.getItem("TokenNo");
    this.userType = localStorage.getItem("UserType");
    this.userId = localStorage.getItem("UserID");
    this.photopath=localStorage.getItem("baseappurl") +"PCRegistration/";
    this.facialRecDetails = JSON.parse(localStorage.getItem("facialRecDetails") || '{}');
    this.selectedimagepath="";

    this.fieldName = localStorage.getItem("FieldName");
    this.fieldValue = localStorage.getItem("FieldValue");
    this.formname="facialRecDetailsApproval";
    this.actiondesc="Facial Rec Details Approval"
    this.cs.GetGeoLocation(this.userId,this.userType,this.formname,this.actiondesc,this.fieldName,this.fieldValue);
  }

  approve(){
    let status = "APPROVED";
    let RejectedRemark = "0";
    this.ds.FRSetPCApprovalData(this.userId, status, this.facialRecDetails.pcuid, RejectedRemark, this.token, this.device, this.deviceType,'Facial Rec Details').subscribe(res => {
      console.log("FRSetPCApprovalData",res);
      this.openModal(this.elRefOpenModalThankYou);
    });
  }

  openModal(modal: ElementRef<any> | undefined) {
    this.modalService.open(modal,
      {
        size: 'lg',
        backdrop: 'static',
        windowClass: '',
        keyboard: false,
        centered: true,
      })
  }

  reject(){
    this.router.navigate(['/home/facialRecRejReasonApproval'], { queryParams: { selected: 'Facial Rec' } });
  }

  okay(){
    this.modalService.dismissAll();
    this.router.navigate(['/home/facialRecognitionApproval']);
  }

  modalClose() {
    this.modalService.dismissAll();
    this.router.navigate(['/home/facialRecognitionApproval']);
  }

  show(imagepath:any)
  {
    debugger;
    this.selectedimagepath=imagepath;
    this.showModal = true; // Show-Hide Modal Check

  }
  //Bootstrap Modal Close event
  hide()
  {
    this.showModal = false;
  }

}
