import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { EncryptionService } from '../../service/encryption.service';
import { DataService } from '../../service/data.service';
import { UserLogin, LoginDtls, Enclass, ResultClass } from '../../models/encryption-class';
import { DeviceDetectorService } from 'ngx-device-detector';
import { FirebasePushNotificationService } from 'src/app/service/firebase-push-notification.service';
import { NotificationService } from 'src/app/service/notification.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { InputPara } from 'src/app/models/kpi-models';
import { HttpClient } from '@angular/common/http';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { mergeMapTo } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

// declare global {
//   interface Window {
//     Android: {
//       AndroidFunction: any; // Declare AndroidFunction for TypeScript
//     }
//   }
// }



interface AndroidInterface {
  receiveDataFromAndroid: () => void; // Adjust the return type and parameters as needed
}


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})

export class LoginComponent implements OnInit {
  message: string = 'hello'; // Initial message

  @ViewChild('openModalForPushNotification', { static: true }) elRefopenModalForPushNotification: ElementRef | undefined;
  @ViewChild('openFeedbackModal', { static: true }) elRefopenFeedbackModal: ElementRef | undefined;

  input: InputPara = new InputPara();
  loginForm = this.fb.group({
    userName: ['', [Validators.required]],
    password: ['', [Validators.required]],
  });
  closeResult: string = '';
  IsUserID: boolean = false;
  IsPwd: boolean = false;
  UserType: string = '';
  SOTerritory: string = '';
  UserName: string = '';
  UserLock: string = '';
  pwpage: number = 0;
  FirstLogin: string = '';
  deviceInfo: any;

  public UserDetails: UserLogin = new UserLogin();
  public Ulogin: LoginDtls = new LoginDtls();
  public enclass: Enclass = new Enclass();
  public resultClass: ResultClass = new ResultClass();
  secretKey: string = '';
  deviceType: any;
  device: any;
  messages = [];
  isRegisteredForPushNotification: boolean = false;
  versionNo: any;
  ValidUserID: boolean = false;
  UserID: any;
  errormsg: any = '';
  IsError: boolean = false;
  selfupdated: any;
  configdata: any = [];
  showPassword: boolean = false;
  // user:any="";
  // userPassword:any="";
  ;
  IsHealthstatusReport: Boolean = false;
  lat: number = 0;
  lng: number = 0;
  public fcmToken: any = "";
  notificationPermission: any = ""
  fcmTokenAtLogin: any = ""
  token: any;
  stars: number[] = [1, 2, 3, 4, 5];
  selectedValue: any = 0;
  feedBackOption: any = []
  ticketDetails: any = []
  ticketNumber: any;
  ticketSubject: any = ""
  feedbackUserName: any = ""
  @ViewChild('opennotificationModal', { static: true }) elRefopennotificationModal: ElementRef | undefined;


  output: any = ""
  android: AndroidInterface;
  constructor(private httpClient: HttpClient, private cs: CommonUtilitiesService, private mds: MongoDataService, private notifyMeService: NotifyMeService, private fcmService: FirebasePushNotificationService, private modalService: NgbModal,
    private notificationService: NotificationService, private fb: FormBuilder, private router: Router, private es: EncryptionService,
    private toaster: ToastrService, private ds: DataService, private deviceService: DeviceDetectorService, private angularFireMessaging: AngularFireMessaging,
  ) {
    // fcmService.receiveMessage().subscribe(message => this.messages.push());
    this.android = {} as AndroidInterface; // Initialize the android object with the interface type
    this.android['receiveDataFromAndroid'] = this.receiveDataFromAndroid.bind(this);
    this.notifyMe()
  }

  ngOnInit(): void {
    debugger

    this.errormsg = '';
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.deviceType = this.deviceInfo.deviceType;
    this.device = this.deviceInfo.device;
    localStorage.setItem("Device", this.deviceInfo.device);
    localStorage.setItem("DeviceType", this.deviceInfo.deviceType);
    debugger
    // window['receiveDataFromAndroid'] = this.receiveDataFromAndroid.bind(this);

    // this.notifyMe()
    this.getconfigdata()

    // let buggerString:any=this.es.decryptInfo(localStorage.getItem("buggerstring"))
    // this.user=JSON.parse(buggerString).UserID;
    // this.userPassword=JSON.parse(buggerString).UserPassword;
  }

  myMethod(): void {
    debugger
    const tct = window.AndroidFunction?.getString();
    this.message = tct || 'No data received'; // Update message based on the result
    // this.toaster.success("this.message, deviceType,browser", this.message + "_" + this.deviceType + "_" + this.deviceInfo.browser)
  }

  receiveDataFromAndroid(): void {
    // this.message = data; // Update the message with the received data from Android
    // alert(`Data received from Android: ${data}`); // Alert the received data
    debugger
  }

  notifyMe() {
    debugger
    if (!("Notification" in window)) {
      // alert("This browser does not support desktop notification");
    }
    else if (Notification.permission !== "granted") {
      this.notificationService.requestPermission().then((permission) => {
        if (permission === "denied") {
          alert("Please Allow Notification Permission To Get Latest Update")
          // this.openModal(this.elRefopennotificationModal);
        }
      }, (error: any) => {
        debugger
      });
    }

  }

  openModal(modal: ElementRef<any> | undefined) {
    this.modalService.open(modal,
      {
        size: 'lg',
        backdrop: 'static',
        windowClass: '',
        keyboard: false,
        centered: true,
      })
  }

  GetGeoLocation() {
    debugger
    if (navigator) {
      navigator.geolocation.getCurrentPosition(pos => {
        debugger
        this.lat = pos.coords.latitude;
        console.log("  this.lat", this.lat)
        this.lng = pos.coords.longitude;
      }, this.handleLocationError, { timeout: 30000 });
    }
    else {
      this.notifyMeService.showError('Location Could not be captured', 'Mera APP');
    }
  }

  handleLocationError(error: { code: any; }) {
    switch (error.code) {
      case 3:
        this.notifyMeService.showError('Timeout in Position capture', 'Mera APP V2');
        break;
      case 2:
        this.notifyMeService.showError('Device is not capable of Position capture', 'Mera APP V2');
        break;
      case 1:
        this.notifyMeService.showError('You denied for Position capture', 'Mera APP V2');
        break;
    }
  }


  SetapplicationLogData() {
    debugger
    let request = {
      "userid": this.UserID,
      "usertype": this.UserType,
      "formname": "login",
      "actiondesc": "login page",
      "fieldname": "login",
      "fieldvalue": "login",
      "latitude": this.lat,
      "longitude": this.lng
    }
    this.ds.SetapplicationLogData(request).subscribe(res => {
      console.log("res", res)
    })
  }

  getconfigdata() {
    debugger
    this.httpClient.get("assets/config.json").subscribe(data => {

      this.configdata = data;
      for (let i in this.configdata) {
        localStorage.setItem(this.configdata[i].name, this.configdata[i].description);
      }
      this.loadVersion();
      this.loadBasicInformation();
    })
  }



  testNotificationPermission() {
    if ('Notification' in window) {
      try {
        // new Notification('Test');
        // Notification was created successfully, permissions are likely 'granted'
        this.notificationPermission = 'granted';
        // alert("inside granted")
      } catch (e) {
        // Permission denied despite 'granted' status
        // alert("inside denied")
        this.notificationPermission = 'denied';
        this.redirectToNotificationSettings();
      }
    }
  }

  OnHealthStatus() {

    this.router.navigate(['/Healthstatus/']);
  }
  login() {

    for (let prop in this.loginForm.controls) {
      this.loginForm.get(prop)?.markAsTouched();
    }
    if (this.loginForm.status === 'VALID') {
      const loginFormData = this.loginForm.value;
      const dataToSend = {
        userName: loginFormData.userName,
        password: loginFormData.password,
      };
    }
  }
  CheckLogin(UserID: string, Password: string, isSetOriginalUrl: boolean = true) {
    debugger
    this.myMethod()
    if ((UserID == "") || (Password == "")) { this.IsUserID = false; this.IsPwd = false; }
    else {
      // window.Android.showNotification("hiii","there")
      this.IsUserID = true;
      this.IsPwd = true;
      this.Ulogin.UserID = UserID;
      this.Ulogin.UserPassword = Password;
      this.es.Word = this.es.WordKey;
      this.secretKey = this.es.encrypt(this.es.WordKey);
      this.es.Word = JSON.stringify(this.Ulogin);
      this.enclass.buggerstring = this.es.encrypt(this.es.WordKey)
      // this.ds.isSetOriginalUrl(isSetOriginalUrl)
      this.ds.authentication(this.enclass, this.secretKey, this.device, this.deviceType, 'Login Form').subscribe(data => {
        this.clearCache()
        this.resultClass = data as ResultClass;
        // localStorage.setItem("buggerstring",this.enclass.buggerstring )



        this.es.Word = this.resultClass.resultString;
        this.UserDetails = JSON.parse(this.es.decrypt(this.es.WordKey));
        this.UserID = this.Ulogin.UserID.toLowerCase()
        // if ((!this.UserDetails || Object.keys(this.UserDetails)?.length == 0) && isSetOriginalUrl) {
        //   this.CheckLogin(UserID, Password, false)
        // }
        if (this.deviceType?.toLowerCase() == "mobile") {
          // this.toaster.success("this is mobile device")
          this.fcmTokenAtLogin = this.message
          // this.toaster.success("fcmTokenAtLogin", this.fcmTokenAtLogin)
          localStorage.setItem("fcmTokenNo", JSON.stringify(this.message))
          this.isRegisteredForPush()
        } else {
          this.isRegisteredForPushTokenGenerate()
        }

        if (Object.keys(this.UserDetails).length > 0) {
          debugger;
          if ((this.UserDetails.UserType != "") && (this.UserDetails.UserType != null)) {
            if (this.UserDetails.UserLock != null) {
              // this.UserDetails.UserLock = "N";

              if (this.UserDetails.firstlogin == "Y") {
                this.modalService.dismissAll();
                this.notifyMeService.showInfo('This is Your first login, Please login to Meranet first and then connect to Sales Genie', 'Sales Genie');
                this.errormsg = 'This is Your first login, Please login to Meranet first and then connect to Sales Genie';
              }
              else {
                if (this.UserDetails.firstlogin == null) {
                  this.UserDetails.firstlogin = "N";
                }
                if (this.UserDetails.UserLock != "" && this.UserDetails.UserLock.toUpperCase() == "N" && this.UserDetails.firstlogin == "N" && this.UserDetails.pwage <= 60) {
                  this.UserType = this.UserDetails.UserType.toUpperCase();
                  this.SOTerritory = this.UserDetails.Soterritory;
                  let RedirectfromFlag = this.UserDetails.implementer;
                  this.UserName = this.UserDetails.UserName;
                  this.IsHealthstatusReport = this.UserDetails.Covidstatus.toUpperCase() == 'Y' ? true : false;
                  localStorage.setItem("TokenNo", this.UserDetails.TokenNo);
                  this.token = this.UserDetails.TokenNo
                  localStorage.setItem("ClientID", this.UserDetails.ClientID.toString());
                  localStorage.setItem("SecreteKey", this.UserDetails.SecreteKey);
                  localStorage.setItem("UserType", this.UserDetails.UserType);
                  localStorage.setItem("UserName", this.UserDetails.UserName);
                  localStorage.setItem("ASMArea", this.UserDetails.ASMArea);
                  localStorage.setItem("BranchName", this.UserDetails.branchName);
                  localStorage.setItem("Soterritory", this.UserDetails.Soterritory);
                  localStorage.setItem("RegionName", this.UserDetails.BSM);
                  localStorage.setItem("SO", this.UserDetails.Soterritory);
                  localStorage.setItem("UserID", this.Ulogin.UserID.toLowerCase());
                  localStorage.setItem("MobileNo", this.UserDetails.MobileNo);
                  localStorage.setItem("EmailID", this.UserDetails.EmailID);
                  localStorage.setItem("MobileNo", this.UserDetails.MobileNo);
                  localStorage.setItem("SessionID", this.UserDetails.sessionNo);
                  localStorage.setItem("userstatus", this.UserDetails.userstatus);
                  this.UserID = this.Ulogin.UserID;
                  this.UserName = this.UserDetails.UserName;
                  this.loadTicketDetails()

                  this.cs.setFieldNamevalue();

                  try {
                    if (localStorage.getItem("fcmTokenNo") == null) {
                      this.isRegisteredForPush();
                    }
                  }
                  catch {
                    this.isRegisteredForPush();
                  }
                  localStorage.setItem("UserType", this.UserDetails.UserType);
                  if (this.IsHealthstatusReport && (this.UserDetails.UserType.toUpperCase() == 'ASM' || this.UserDetails.UserType.toUpperCase() == 'SO')) {
                    this.healthreport();
                  }
                  else {
                    this.router.navigate(['/home']);
                  }

                }
                else {

                  this.modalService.dismissAll();
                  this.notifyMeService.showInfo(this.UserDetails.sessionNo, 'Sales Genie');
                  this.errormsg = this.UserDetails.sessionNo;

                  //   this.notifyMeService.showInfo('User is Locked. Use Forgot password option to unlock','MeraApp 2');
                  // this.errormsg='User is Locked. Use Forgot password option to unlock';
                  this.IsError = true;
                }
              }
            }
            else {

              this.modalService.dismissAll();
              this.notifyMeService.showInfo(this.UserDetails.sessionNo, 'Sales Genie');
              this.errormsg = this.UserDetails.sessionNo;
            }
          }
          else {

            this.modalService.dismissAll();
            this.notifyMeService.showInfo(this.UserDetails.sessionNo, 'Sales Genie');
            this.errormsg = this.UserDetails.sessionNo;

            // this.notifyMeService.showInfo('Oops! Something is wrong Please contact support','MeraApp 2');
            // this.errormsg='Oops! Something is wrong Please contact support';
            this.IsError = true;
          }
        }
        else {

          this.modalService.dismissAll();
          this.notifyMeService.showInfo(this.UserDetails.sessionNo, 'Sales Genie');
          this.errormsg = this.UserDetails.sessionNo;

          // this.notifyMeService.showInfo('Oops! Something is wrong Please contact support','MeraApp 2');
          // this.errormsg='Oops! Something is wrong Please contact support';
          this.IsError = true;
        }
      }, (error: any) => {
        debugger
        this.CheckLogin(UserID, Password, false)
      });
    }
  }


  clearCache() {
    debugger
    if ('caches' in window) {
      caches.keys().then(cacheNames => {
        console.log("cacheNames", cacheNames)
        cacheNames.forEach(cacheName => {
          caches.delete(cacheName);
          console.log("delete cache")
        });
      });
    }
  }

  registerForPush() {
    debugger
    // alert("in registerforpush")
    // this.angularFireMessaging.requestToken.subscribe(
    //   (token) => {
    //     console.log('Permission granted! Save to the server!', token);
    //     this.fcmToken = token
    //     localStorage.setItem("fcmTokenNo", JSON.stringify(token));
    this.fcmSend(this.fcmTokenAtLogin)

    //   },
    //   (error) => {
    //     // alert(error)
    //     console.error('Unable to get permission to notify.', error);
    //   }
    // );

    // this.notificationService.registerForPush(this.UserID, this.fcmTokenAtLogin).subscribe((res: any) => {
    //   debugger
    //   console.log("res", res)
    // })
  }

  isRegisteredForPushTokenGenerate() {
    debugger
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        console.log('Permission granted! Save to the server!', token);
        // alert(token)
        this.fcmTokenAtLogin = token
        localStorage.setItem("fcmTokenNo", JSON.stringify(token))
        this.isRegisteredForPush()
      },
      (error) => {
        // alert(error)
        console.error('Unable to get permission to notify.', error);
      }
    );
  }

  fcmSend(token: any) {
    debugger
    let requestObjectForFCMSend: any = {
      "to": token,
      "notification": {
        "body": "Notification",
        "title": "Successfuly registered for push notifications",
        "content_available": true,
      }
    }
    this.notificationService.FCMSend(requestObjectForFCMSend).subscribe((res: any) => {
      console.log("FCM SEND res==>", res)
      if (res.success == 1) {
        this.listen()
        this.UserID = localStorage.getItem("UserID")
        this.notificationService.registerForPush(this.UserID, token).subscribe((res: any) => {
          debugger
          console.log("res", res)
        })
      }
    })
  }


  listen() {
    // alert("in listen function")
    this.fcmService.receiveMessage()
  }


  sendNotification = (title: string, body: string) =>
    this.notificationService.sendNotification({ title, body }).subscribe()

  sendNotificationWithDelay(title: string, body: string) {

    const newWindow: any = window.open();
    newWindow.document.write('Wait to see notification');
    setTimeout(() => {
      this.sendNotification(title, body);
    }, 1000);
  }
  isRegisteredForPush(): void {
    debugger
    // this.toaster.success("call isRegisteredForPush() function")
    //get token of user
    this.notificationService.IsRegisterForNotification(this.UserID).subscribe(res => {
      // this.toaster.success("res", res)
      if (res?.length > 0) {
        // this.toaster.success("res[0]?.tokenNo", res[0]?.tokenNo)
        // this.toaster.success("fcmTokenAtLogin", this.fcmTokenAtLogin)
        if (res[0]?.tokenNo != this.fcmTokenAtLogin) {
          //save token
          this.notificationService.registerForPush(this.UserID, this.fcmTokenAtLogin).subscribe((res: any) => {
            debugger
            // this.toaster.success("inside registerForPush() funation")
            console.log("res", res)
          })
        }
        this.isRegisteredForPushNotification = true;
        for (let i = 0; i < this.ticketDetails?.length; i++) {
          if (this.ticketDetails[i].feedbackFlag == "N" && this.ticketDetails[i].ticketStatus?.toLowerCase() == 'closed' && this.ticketDetails[i].userId?.toLowerCase() == this.UserID?.toLowerCase() && (this.ticketDetails[i]?.feedbackstatus.length == 0 || this.ticketDetails[i]?.feedbackStar == 0 || this.ticketDetails[i]?.feedbackStar == '')) {
            this.ticketNumber = this.ticketDetails[i].ticketNo;
            this.ticketSubject = this.ticketDetails[i].subject;
            this.modalService.open(this.elRefopenFeedbackModal,
              {
                size: 'lg',
                backdrop: 'static',
                windowClass: '',
                keyboard: false,
                centered: true,
              });
          }
        }
      }
      else {
        this.isRegisteredForPushNotification = false;
        // this.toaster.success("fcmTokenAtLogin", this.fcmTokenAtLogin)
        this.notificationService.registerForPush(this.UserID, this.fcmTokenAtLogin).subscribe((res: any) => {
          debugger
          // this.toaster.success("save token and userid")
          console.log("res", res)
        })

        this.modalService.open(this.elRefopenModalForPushNotification,
          {
            size: 'lg',
            backdrop: 'static',
            windowClass: '',
            keyboard: false,
            centered: true,
          });
      }


    });
  }

  loadTicketDetails() {
    debugger
    let request = {
      "companyCode": "mdlz",
      "userId": localStorage.getItem("UserID"),
      "userType": localStorage.getItem("UserType")
    }
    this.es.Word = this.es.WordKey;
    this.es.encrypt(this.UserDetails.SecreteKey);
    this.es.Word = JSON.stringify(request);
    this.enclass.buggerstring = this.es.encrypt(this.UserDetails.SecreteKey)
    this.mds.HD_GetAllTicketDetails(this.enclass, this.token, this.device, this.deviceType, "ticket details", localStorage.getItem("ClientID")).subscribe(res => {
      console.log("getSurveydetails", res)

      this.resultClass = res;
      this.es.Word = this.resultClass.resultString;
      let response = JSON.parse(this.es.decrypt(this.UserDetails.SecreteKey));
      console.log("ticket response", response)
      this.ticketDetails = response.Data

      for (let i = 0; i < this.ticketDetails?.length; i++) {
        this.ticketDetails[i].sortedtransactionDetails = this.ticketDetails[i].transactionDetails?.sort((a: any, b: any) => {
          const dateA = new Date(a.transaction_date);
          const dateB = new Date(b.transaction_date);
          return dateB.getTime() - dateA.getTime(); // Ascending order
        });
      }




      let request = {
        "companyCode": "mdlz",
      }

      console.log("request", request)
      this.es.Word = this.es.WordKey;
      this.es.encrypt(this.UserDetails.SecreteKey);
      this.es.Word = JSON.stringify(request);
      this.enclass.buggerstring = this.es.encrypt(this.UserDetails.SecreteKey)
      this.mds.HD_GetFeedBackOptions(this.enclass, this.token, this.device, this.deviceType, "", this.UserDetails.ClientID.toString()).subscribe(res => {
        console.log("getSurveydetails", res)

        this.resultClass = res;
        this.es.Word = this.resultClass.resultString;
        let response = JSON.parse(this.es.decrypt(this.UserDetails.SecreteKey));
        console.log("response", response)

        this.feedBackOption = []
        if (response.Data[0].feedBackOption?.length > 0) {
          this.feedBackOption = response.Data[0].feedBackOption
        }

        for (let i = 0; i < this.feedBackOption?.length; i++) {
          this.feedBackOption[i].id = i + 1
          this.feedBackOption[i].isChecked = false
        }

      }, (error: any) => {
        console.log("error", error)
      });


      // for (let i = 0; i < this.ticketDetails?.length; i++) {
      //   if (this.ticketDetails[i].feedbackFlag == "N" || this.ticketDetails[i].ticketStatus?.toLowerCase() == 'closed' && this.ticketDetails[i].userId?.toLowerCase() == this.UserID?.toLowerCase() && (this.ticketDetails[i]?.feedbackstatus.length == 0 || this.ticketDetails[i]?.feedbackStar == 0 || this.ticketDetails[i]?.feedbackStar == '')) {
      //     this.ticketNumber=this.ticketDetails[i].ticketNo;
      //     this.modalService.open(this.elRefopenFeedbackModal,
      //       {
      //         size: 'lg',
      //         backdrop: 'static',
      //         windowClass: '',
      //         keyboard: false,
      //         centered: true,
      //       });
      //   }
      // }
      for (let i = 0; i < this.ticketDetails?.length; i++) {
        for (let j = 0; j < this.ticketDetails[i]?.sortedtransactionDetails?.length; j++) {
          if (this.ticketDetails[i]?.sortedtransactionDetails[j]?.transactionstatus?.toLowerCase() == "resolved") {
            this.feedbackUserName = this.ticketDetails[i]?.sortedtransactionDetails[j]?.transactionBy;
            break
          }
        }
      }

    }, (error: any) => {
      console.log("error", error)
    });
  }

  open(content: any) {
    this.modalService.open(content,
      {
        size: 'lg',
        backdrop: 'static',
        windowClass: '',
        keyboard: false,
        centered: true,
      })
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  loadVersion() {
    debugger
    this.ds.GetMeraAppVersion().subscribe(res => {
      this.versionNo = res[0].versionNo;
      try {
        if (localStorage.getItem("AppVersion") == null) {
          localStorage.setItem("AppVersion", this.versionNo);
          window.location.reload();

        }
        else if (localStorage.getItem("AppVersion") != this.versionNo) {
          this.notifyMeService.showWarning('New Update available! App Will Automatically Update.', 'MeraApp 2');
          localStorage.setItem("AppVersion", this.versionNo);
          window.location.reload();
        }
      }
      catch {
        this.notifyMeService.showWarning('New Update available! App Will Automatically Update.', 'MeraApp 2');
        localStorage.setItem("AppVersion", this.versionNo);
        window.location.reload();
      }
    }, err => {
      debugger
    }
    );
  }
  loadBasicInformation() {

    this.mds.GetMadates().subscribe(res => {

      localStorage.setItem("currmonthno", res[0].currmonthno);
      localStorage.setItem("curryearno", res[0].curryearno);
      localStorage.setItem("prevmonthno", res[0].prevmonthno);
      localStorage.setItem("prevyearno", res[0].prevyearno);
      localStorage.setItem("currperiod", res[0].currperiod);
      localStorage.setItem("prevperiod", res[0].prevperiod);
    }, err => { }
    );
  }
  ChgUserName(e: any): void {
    if (e.target.value.length > 0) {
      this.ValidUserID = true;
      this.UserID = e.target.value.trim();
    }
    else {
      this.ValidUserID = false;
    }
  }
  modalClose() {
    this.modalService.dismissAll();
    debugger
    for (let i = 0; i < this.ticketDetails?.length; i++) {
      if (this.ticketDetails[i]?.feedbackFlag == "N" && this.ticketDetails[i]?.ticketStatus?.toLowerCase() == 'closed' && this.ticketDetails[i]?.userId?.toLowerCase() == this.UserID?.toLowerCase() && (this.ticketDetails[i]?.feedbackstatus.length == 0 || this.ticketDetails[i]?.feedbackStar == 0 || this.ticketDetails[i]?.feedbackStar == '')) {
        this.ticketNumber = this.ticketDetails[i].ticketNo;
        this.ticketSubject = this.ticketDetails[i].subject;
        this.modalService.open(this.elRefopenFeedbackModal,
          {
            size: 'lg',
            backdrop: 'static',
            windowClass: '',
            keyboard: false,
            centered: true,
          });
      }
    }
  }

  modalFeedbackClose() {
    let request = {
      "companyCode": "mdlz",
      "ticketNo": this.ticketNumber,
      "feedbackFlag": "Y"
    }

    console.log("request", request)
    this.es.Word = this.es.WordKey;
    this.es.encrypt(this.UserDetails.SecreteKey);
    this.es.Word = JSON.stringify(request);
    this.enclass.buggerstring = this.es.encrypt(this.UserDetails.SecreteKey)
    this.mds.HD_FeedBackFlagChange(this.enclass, this.token, this.device, this.deviceType, "", this.UserDetails.ClientID.toString()).subscribe(res => {
      console.log("getSurveydetails", res)

      this.resultClass = res;
      this.es.Word = this.resultClass.resultString;
      // let response = JSON.parse(this.es.decrypt(this.UserDetails.SecreteKey));
      // console.log("response", response)
      this.modalService.dismissAll();
    }, (error: any) => {
      console.log("error", error)
    });
  }

  allow() {
    debugger
    this.modalService.dismissAll();
    for (let i = 0; i < this.ticketDetails?.length; i++) {
      if (this.ticketDetails[i].feedbackFlag == "N" && this.ticketDetails[i].ticketStatus?.toLowerCase() == 'closed' && this.ticketDetails[i].userId?.toLowerCase() == this.UserID?.toLowerCase() && (this.ticketDetails[i]?.feedbackstatus.length == 0 || this.ticketDetails[i]?.feedbackStar == 0 || this.ticketDetails[i]?.feedbackStar == '')) {
        this.ticketNumber = this.ticketDetails[i].ticketNo;
        this.ticketSubject = this.ticketDetails[i].subject;
        this.modalService.open(this.elRefopenFeedbackModal,
          {
            size: 'lg',
            backdrop: 'static',
            windowClass: '',
            keyboard: false,
            centered: true,
          });
      }
    }
    this.registerForPush();
  }

  redirectToNotificationSettings() {
    // Provide instructions on how to manually enable notifications on the device
    // alert('Please enable notifications for this app in your device settings.');
    // Open the Android notification settings

    // if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
    //   alert('To enable notifications, go to Settings > Notifications.');
    // } else if (/Android/.test(navigator.userAgent)) {
    //   alert('To enable notifications, go to Settings > Apps > Your App > Notifications.');
    //   window.location.href = 'intent:#Intent;action=android.settings.APP_NOTIFICATION_SETTINGS;end';

    // }
    // Optionally, you can provide additional guidance specific to iOS and Android
  }
  submit() {
    this.ds.forgotpasswordNew(this.UserID, this.device, this.deviceType, 'login Form').subscribe(res => {
      this.modalService.dismissAll();
      this.notifyMeService.showInfo('Password is send to your registered Mail Id', 'Sales Genie App');
      this.errormsg = "Link for changing current password will be sent to your registered email ID";
      this.IsError = false;
    });
  }
  healthreport(): void {
    let fieldname: any = localStorage.getItem("FieldName");
    let fieldvalue: any = localStorage.getItem("FieldValue");

    this.input.FieldName = fieldname;
    this.input.FieldValue = fieldvalue;
    this.input.TagName = '0';
    this.input.Datatype = "0";
    this.input.UserID = this.UserID;
    let RDCheck: any = "";
    let PCCheck: any = "";
    this.mds.GetHealthStatusAbt(this.input, this.UserDetails.TokenNo, this.device, this.deviceType, "Health Status").subscribe(res => {

      res.forEach((element: { desc: string; status: number; isupdated: any; }) => {
        if (fieldname == "ASMAREA") {
          if (element.desc == "self") {
            if (element.isupdated == "N") {

              this.OnHealthStatus();
            }
            else {
              this.router.navigate(['/home']);
            }
          }
        }
        else {

          if (element.desc == "rd") {
            RDCheck = element.isupdated;
          }
          if (element.desc == "pc") {
            PCCheck = element.isupdated;
          }
          if (RDCheck == 'Y' && PCCheck == 'Y') {
            this.router.navigate(['/home']);
          }
          else if (RDCheck == 'N' || PCCheck == 'N') {
            this.OnHealthStatus();
          }
        }

      });

    });

  }
  toggleShow() {
    this.showPassword = !this.showPassword;

  }

  checkValue(e: any, ind: any) {
    debugger
    console.log("e", e.target.checked)
    for (let i = 0; i < this.feedBackOption.length; i++) {
      if (e.target.checked) {
        this.feedBackOption[ind - 1].isChecked = true
      }
      else {
        this.feedBackOption[ind - 1].isChecked = false
      }
    }
    console.log("feedBackOption", this.feedBackOption)
  }

  submitFeedback() {
    let request = {
      "companyCode": "mdlz",
      "ticketNo": this.ticketNumber,
      "feedbackStar": this.selectedValue,
      "feedbackstatus": this.feedBackOption.filter((f: any) => f.isChecked).map((m: any) => m.option),
      "feedbackFlag": "Y"
    }

    console.log("request", request)
    this.es.Word = this.es.WordKey;
    this.es.encrypt(this.UserDetails.SecreteKey);
    this.es.Word = JSON.stringify(request);
    this.enclass.buggerstring = this.es.encrypt(this.UserDetails.SecreteKey)
    this.mds.HD_UpdateFeedBackStatus(this.enclass, this.token, this.device, this.deviceType, "", this.UserDetails.ClientID.toString()).subscribe(res => {
      console.log("getSurveydetails", res)

      this.resultClass = res;
      this.es.Word = this.resultClass.resultString;
      let response = JSON.parse(this.es.decrypt(this.UserDetails.SecreteKey));
      console.log("response", response)
      this.modalService.dismissAll();
      this.toaster.success(response.Data)


    }, (error: any) => {
      console.log("error", error)
    });
  }
  countStar(star: any) {
    debugger
    this.selectedValue = star;
    console.log('Value of star', star);
  }
}
