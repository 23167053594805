import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { DataService } from 'src/app/service/data.service';
import { InputPara } from 'src/app/models/kpi-models';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-pc-device-tagging-select-pc',
  templateUrl: './pc-device-tagging-select-pc.component.html',
  styleUrls: ['./pc-device-tagging-select-pc.component.css']
})
export class PcDeviceTaggingSelectPcComponent implements OnInit {
  deviceType: any;
  device: any;
  userType: string | null = '';
  userId: any;
  fieldName: any;
  fieldValue: any;
  list: any;
  msg: any;
  private _searchText = '';
  filteredList: any;
  input:InputPara=new InputPara();
  count: any = 0;
  deviceTaggingDetails: any;
  token: any;
  formname: any;
  actiondesc: any;

  constructor(private activatedRoute: ActivatedRoute,private cs:CommonUtilitiesService, private router: Router, private headerService: HeaderService, private mds: MongoDataService, private notifyMeService:NotifyMeService, private ds: DataService,) { }

  ngOnInit(): void {
    this.headerService.setTitle('PC/TSI Device Tagging');
    this.headerService.setActiveURl('/pcDeviceTaggingSelectPc');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.token = localStorage.getItem("TokenNo");
    this.userType = localStorage.getItem("UserType");
    this.userId = localStorage.getItem("UserID");
    this.fieldName=localStorage.getItem("FieldName");
    this.fieldValue=localStorage.getItem("FieldValue");
    this.deviceTaggingDetails = JSON.parse(localStorage.getItem("DeviceTaggingDetails") || '{}');
    
    this.formname = "pcDeviceTaggingSelectPc";
    this.actiondesc = "Pc Device Tagging Select Pc";
    this.cs.GetGeoLocation(this.userId, this.userType, this.formname, this.actiondesc, this.fieldName, this.fieldValue);
    this.onLoad();
  }

  onLoad() {
    this.input.FieldName="RD";
    this.input.FieldValue=this.deviceTaggingDetails.rdcode.toString();
    this.input.TagName='EC';
    this.input.Datatype='RURAL';
    this.input.UserID=this.userId;
    this.mds.getPCList(this.input, this.token, this.device, this.deviceType, 'pcDeviceTaggingSelectPc').subscribe(res => {
      this.list = res;
      this.filteredList = this.list;
      if(this.list.length > 0) {
        this.count = this.list.length;
      }
    });
  }

  rowClick(data: any){
    localStorage.setItem("DeviceTaggingSelectPcDetails",JSON.stringify(data))
    this.router.navigate(['/home/pcDeviceTaggingDeviceId']);
  }

  get searchTerm(): any{
    return this._searchText;
  }
  set searchTerm(value: any){
     this._searchText = value;
     this.filteredList = this.filteredListMethod(value);
  }

  filteredListMethod(value: any): any {
    return this.list.filter((ele: any) =>
      ele.pcname.toUpperCase().indexOf(value.toUpperCase()) !== -1);
  }

}
