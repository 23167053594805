import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { DatePipe } from '@angular/common';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-outlet-detail-pulloutreq',
  templateUrl: './outlet-detail-pulloutreq.component.html',
  styleUrls: ['./outlet-detail-pulloutreq.component.css']
})
export class OutletDetailPulloutreqComponent implements OnInit {

  deviceType: any;
  device: any;
  searchText = '';
  isLongPress: boolean = false;
  isSelected: boolean = false;
  outLetSelected: any;
  selected: string = '';
  outLetDetails: any;
  address: any;
  formname:any;
  actiondesc:any;
  UserType:any;
  fieldName:any;
  fieldValue:any;
  userId:any;

  constructor(private activatedRoute: ActivatedRoute, private cs:CommonUtilitiesService,private router: Router, private headerService: HeaderService, private datePipe: DatePipe, private mds: MongoDataService,) { }

  ngOnInit(): void {
    debugger;
    this.headerService.setTitle('Outlet Detail');
    this.headerService.setActiveURl('/outletDetailPulloutreq');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.userId = localStorage.getItem("UserID");
    this.UserType= localStorage.getItem('UserType');
    this.fieldName = localStorage.getItem("FieldName");
    this.fieldValue = localStorage.getItem("FieldValue");
    this.outLetSelected = JSON.parse(localStorage.getItem("outLetSelected") || '{}');
    console.log("outLetSelected",this.outLetSelected);
    this.selected = this.activatedRoute.snapshot.queryParams["selected"];
    console.log("selected",this.selected);
    if(this.selected == 'VS'){
      this.outLetDetails = JSON.parse(localStorage.getItem("outLetDetails") || '{}');
      console.log("outLetDetails",this.outLetDetails);
      this.outLetSelected.address1 = this.outLetDetails[0].address1;
    }

        
    this.formname="outletDetailPulloutreq";
    this.actiondesc="Outlet Detail pull Out Req";
    this.cs.GetGeoLocation(this.userId,this.UserType,this.formname,this.actiondesc,this.fieldName,this.fieldValue); 
  }

  onLongPress() {
    this.isLongPress = true;
  }

  clickOnSelect() {
    this.isSelected = true;
  }

  rowClick() {
    if(this.selected == 'VS'){
      this.router.navigate(['/home/pullOutReasonPulloutreq'], { queryParams: { selected: this.selected } });
    }else{
      this.router.navigate(['/home/pullOutReasonPulloutreq']);
    }
  }
}
