<div class="row mt-2 bg_Color">
  <div class="col-12 mt-4"  *ngIf="userType == 'so'">
    <span class="rd_Name_Head">{{rlaAutoClosureDetails.desc | titlecase}}</span>
    <span class="ml-1 rd_Code_Head">{{rlaAutoClosureDetails.fieldvalue}}</span>
  </div>
  <div class="col-12 mt-4"  *ngIf="userType == 'asm'">
    <span class="rd_Name_Head">{{rlaAutoClosureDetails.rdname | titlecase}}</span>
    <span class="ml-1 rd_Code_Head">{{rlaAutoClosureDetails.rdcode}}</span>
  </div>
  <div class="col-6 mt-2 mb-4"  *ngIf="userType == 'so'">
    <span class="state_Name">{{rlaAutoClosureDetails.beatname}}</span>
  </div>
  <div class="col-6 mt-2 mb-4"  *ngIf="userType == 'asm'">
    <span class="state_Name">{{rlaAutoClosureDetails.soterritory | titlecase}}</span>
  </div>
  <div class="col-6 mt-2 mb-4 text-right" *ngIf="userType == 'so'">
    <span class="status_Value">{{rlaAutoClosureDetails.totaloutlets}}</span>
    <span class="ml-1 outlet_Text">Outlets</span>
  </div>
  <div class="col-6 mt-2 mb-4 text-right" *ngIf="userType == 'asm'">
    <span class="status_Value">{{rlaAutoClosureDetails.rla}}</span>
    <span class="ml-1 outlet_Text">RLA</span>
  </div>
</div>
<div class="row mt-4" *ngIf="userType == 'so'">
  <div class="col-6">
    <span class="unbilled_Outlet">Unbilled Outlet {{rlaAutoClosureDetails.totaloutlets}}</span>
  </div>
  <div class="col-6 text-right">
    <span class="beat_Name">Beat Name</span>
  </div>
</div>
<div *ngIf="userType == 'so'">
  <div *ngFor="let item of list; let i = index;" (click)="rowClick(item)">
    <div class="row mt-3">
      <div class="col-8 pr-0">
        <span class="rd_Name">{{item.desc | titlecase}}</span>
        <span class="ml-1 rd_Code">{{item.fieldvalue}}</span>
      </div>
      <div class="col-4 text-right">
        <span class="beat_Value">{{item.beatname | titlecase}}</span>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12">
        <span class="reason_Text">Reason</span>
        <span class="ml-1 reason_Value">{{item.rdName | titlecase}}</span>
      </div>
    </div>
    <hr>
  </div>
</div>
<div *ngIf="userType == 'asm'">
    <div class="row mt-3">
      <div class="col-10">
        <span class="reason_Value">L3M Unbilled Base</span>
      </div>
      <div class="col-2 text-right">
        <span class="status_Value">{{rlaAutoClosureDetails.l3MUnbilledBase}}</span>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-10">
        <span class="reason_Value">YTD Base Permanently Closed   </span>
      </div>
      <div class="col-2 text-right">
        <span class="status_Value">{{rlaAutoClosureDetails.ytdbasEperclosed}}</span>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-10">
        <span class="reason_Value">PC confirmed as Permanently Closed</span>
      </div>
      <div class="col-2 text-right">
        <span class="status_Value">{{rlaAutoClosureDetails.pcConfirmed}}</span>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-10">
        <span class="reason_Value">SE confirmed as Permanently Closed</span>
      </div>
      <div class="col-2 text-right">
        <span class="status_Value">{{rlaAutoClosureDetails.seConfirmed}}</span>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-10 pr-0">
        <span class="reason_Value">Projected YTD Base % Permanently Closed</span>
      </div>
      <div class="col-2 text-right">
        <span class="status_Value">{{rlaAutoClosureDetails.projYTDpermclosed}}%</span>
      </div>
    </div>
    <hr>
</div>
<br>
<br>
<br>
<br>
<br>
<div class="footer" *ngIf="userType == 'asm'">
  <div class="row">
    <div class="col-6 pr-0" (click)="reject()">
      <button class="btn btn_reject">REJECT</button>
    </div>
    <div class="col-6 pl-0" (click)="approve()">
      <button class="btn btn_APPROVE">APPROVE</button>
    </div>
  </div>
</div>
