<div *ngFor="let item of list; let i = index" (click)="RowClick(item.fieldname ,item.fieldvalue, item.listno)">
  <div class="row mt-4">
    <div class="col-12">
      <span class="skuname">{{item.skuname | titlecase}}</span>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-12">
      <span class="listno">{{item.listno}}</span>
    </div>
  </div>
  <hr class="hr_line">
</div>
<br><br><br><br>