import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { GetNewRLADashboard,InputPara } from 'src/app/models/kpi-models';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterComponent } from '../filter/filter.component';
import { MatDialog } from '@angular/material/dialog';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { DataService } from 'src/app/service/data.service';

@Component({
  selector: 'app-new-rladata',
  templateUrl: './new-rladata.component.html',
  styleUrls: ['./new-rladata.component.css']
})
export class NewRLADataComponent implements OnInit {
  FieldName: any;
  FieldValue: any;
  ParaName: any;
  UserID: any;
  getNewRLADashboard: any;
  periodList: any[] = [];
  selectedperiod: any;
  IsRDValue: boolean=false;
  rdData: any=null;
  RLAList: any;
  device: any;
  deviceType:any;
input:InputPara=new InputPara();
  token: any ;
  lat:any;
  lng:any;
  UserType:any;

  constructor(private activatedRoute: ActivatedRoute, private dialog: MatDialog,private fb: FormBuilder,private router: Router,
     private headerService: HeaderService, private mds: MongoDataService
     ,private notifyMeService: NotifyMeService,private ds: DataService,) { }

  ngOnInit(): void {
    this.headerService.setActiveURl('/EffectiveCoverageData');
    this.headerService.setTitle("NET RLA");
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.token = localStorage.getItem("TokenNo");
    this.UserType = localStorage.getItem("UserType");
    this.activatedRoute.paramMap.forEach(params => {
      this.FieldValue = params.get('FieldValue');
      this.FieldName = params.get('FieldName');
      this.UserID = params.get('UserID');
      this.ParaName = params.get('ParaName');
      this.selectedperiod = params.get('selectedperiod');
      this.getNewRLADashboard = [];
      if(this.FieldName=="RD")
      {
        let obj=localStorage.getItem("rdData");
        this.rdData = JSON.parse(obj || '{}') as GetNewRLADashboard;
        this.IsRDValue=true;
      }
      else{this.IsRDValue=false;}
      debugger
      this.GetGeoLocation();
      this.loadData();
    });
  }

  GetGeoLocation() {
    debugger
    if (navigator) {
      navigator.geolocation.getCurrentPosition(pos => {
        this.lat = pos.coords.latitude;
        console.log("  this.lat", this.lat)
        this.lng = pos.coords.longitude;
        this.SetapplicationLogData();
      }, this.handleLocationError, { timeout: 30000 });
    }
    else {
      this.notifyMeService.showError('Location Could not be captured', 'Mera APP');
    }
  }

  handleLocationError(error: { code: any; }) {
    switch (error.code) {
      case 3:
        this.notifyMeService.showError('Timeout in Position capture', 'Mera APP V2');
        break;
      case 2:
        this.notifyMeService.showError('Device is not capable of Position capture', 'Mera APP V2');
        break;
      case 1:
        this.notifyMeService.showError('You denied for Position capture', 'Mera APP V2');
        break;
    }
  }


  SetapplicationLogData() {
    debugger
    let request = {
      "userid": this.UserID,
      "usertype": this.UserType,
      "formname": "NewRLAData",
      "actiondesc": "New RLA Data" + " " + this.FieldName,
      "fieldname": this.FieldName,
      "fieldvalue": this.FieldValue,
      "latitude": this.lat,
      "longitude": this.lng
    }
    this.ds.SetapplicationLogData(request).subscribe(res => {
      console.log("res", res)
    })
  }

  loadData(): void {
    this.input.FieldName=this.FieldName;
    this.input.FieldValue=this.FieldValue;
    this.input.TagName=this.ParaName;
    this.input.Datatype= this.ParaName;
    this.input.UserID=this.UserID;
   this.mds.getNewRLAdata(this.input,this.token,this.device,this.deviceType,'NET RLA').subscribe(res => {
    this.RLAList=res;
      this.periodList = [...new Set(res.map((bill: { tagname: any; }) => bill.tagname))].sort();
      this.getNewRLADashboard=this.RLAList.filter((x: { [x: string]: any; }) => (x['tagname'] == this.selectedperiod))
    });
  }
  PeriodChange(e: any): void {
    this.selectedperiod =e.source._value;
    this.getNewRLADashboard=this.RLAList.filter((x: { [x: string]: any; }) => (x['tagname'] == this.selectedperiod))

  }
  filterItemsOfType(type: any) {
    return type != null ? type.filter((x: { [x: string]: any; }) => (x['tagname'] == this.selectedperiod)) : null;
  }
  RowClick(fieldName:any, e:any): void{
   if(fieldName=="RD" || fieldName=="rd" )
    {
      let rdData = this.getNewRLADashboard.filter((singleItem: { [x: string]: string; }) => (singleItem['valuetype']==e && singleItem['tagname']==this.selectedperiod));
      localStorage.setItem("rdData", JSON.stringify(rdData));
      this.router.navigate(['/home/NewRLAData/',"RD",e,localStorage.getItem("ParaName"),this.UserID,this.selectedperiod]);
    }
    else {
    this.router.navigate(['/home/NewRLAData/',fieldName,e,localStorage.getItem("ParaName"),this.UserID,this.selectedperiod]);
    }
  }
  openFilter(){
    if (this.dialog.openDialogs.length == 0) {
      const dialogRef = this.dialog.open(FilterComponent, {
        closeOnNavigation: true,
        data: {
          "FieldName": localStorage.getItem("OriginalFieldName"),
          "FieldValue": localStorage.getItem("OriginalFieldValue"),
          "UserID": this.UserID,
          "MenuUpTo": "5",
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.FieldName=result.FieldName;
          this.FieldValue=result.FieldValue;
          if(result.FieldName == "RD") {
            this.IsRDValue=true;
            this.loadRDData();
          }else {
            this.IsRDValue=false;
          }
          this.loadData();
              }
      });
    }
  }

  loadRDData(): void {
    this.input.FieldName=this.FieldName;
    this.input.FieldValue=this.FieldValue;
    this.input.TagName='0';
    this.input.Datatype='0';
    this.input.UserID=this.UserID;
    this.mds.getNewRLAdata(this.input, this.token, this.device, this.deviceType, 'NET RLA').subscribe(res => {
      this.rdData = res.filter((singleItem: { [x: string]: string; }) => (singleItem['tagname']==this.selectedperiod));
    });
  }
}
