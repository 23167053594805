<div class="container-fluid">
    <div class="row mt-4">
      <div class="col-8">
        <i class="fa fa-caret-left" style="color: #757575;" >&nbsp;&nbsp;
        <span class="header_text">{{FieldValue=='0'? 'All India' : FieldValue=='ALLINDIA' ? 'All India': FieldValue |titlecase}}</span></i>
      </div>
      <div class="col-4 text-right">
        <img src="../../../assets/filter.png" class="img-fluid" (click)="openFilter()" /><sup><i
            class="fa fa-circle fa-sm" style="color: #eaab5e;font-size: 5px;"></i></sup>&nbsp;<span
            style="color: #724e8c;font-weight:400 ;font-size: 0.8125rem;">Filter</span>
      </div>
    </div>
    <br>
    <div class="row mt-2" *ngFor="let item of VData" (click)="RowClick(item.fieldName,item.fieldvalue)">
      <div class="col-12">
        <div class="row">
          <div class="col-12">
            <span class="Head_text">{{item.desc + ' ' + item.fieldvalue|titlecase}}</span>
          </div>
        </div>      
        <div class="row">
          <div class="col-9">
            <div class="row mt-2">
              <div class="col-12">
                <div class="progress rounded-pill">
                  <div class="progress-bar bg_sec" role="progressbar" [style.width.%]="item.orderValue"
                      aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="row">
              <div class="col-12 text-right">
                <span class="val_text">{{item.orderValue}}&nbsp;<span class="unit_text">{{item.unit}}</span> </span> 
              </div>        
            </div>
          </div>
        </div>
        <hr class="hr_cust">
      </div>    
    </div> 
    <br><br><br><br><br><br><br>
  </div>
  