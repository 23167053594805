import { Component, OnInit } from '@angular/core';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';

@Component({
  selector: 'app-faqlist',
 templateUrl: './faqlist.component.html',
   styleUrls: ['./faqlist.component.css']
})


export class FaqlistComponent implements OnInit {
  List: any;
  panelOpenState = false;
  UserID: any;
  device: any;
  deviceType: any;
  token: any;
  formname:any;
  actiondesc:any;
  fieldName: any;
  fieldValue: any;
  userType:any;

  constructor(private headerService: HeaderService,private mds: MongoDataService,private cs:CommonUtilitiesService) { }

  ngOnInit(): void {
    this.List=[];
    this.headerService.setTitle('FAQ');
    this.headerService.setActiveURl('/faqlist');
    this.UserID = localStorage.getItem('UserID');
    this.device = localStorage.getItem('Device');
    this.deviceType = localStorage.getItem('DeviceType');
    this.token = localStorage.getItem("TokenNo");
    this.userType = localStorage.getItem("UserType");
    this.fieldName = localStorage.getItem("FieldName");
    this.fieldValue = localStorage.getItem("FieldValue");
    this.formname="faqlist";
    this.actiondesc="faq List"
    this.cs.GetGeoLocation(this.UserID,this.userType,this.formname,this.actiondesc,this.fieldName,this.fieldValue);

    this.loadData();
  }
  loadData(){
    this.mds.getFAQ(this.UserID,this.token,this.device,this.deviceType,'FAQ').subscribe(res => {
      this.List = res;
      debugger;
    });
  }

}
