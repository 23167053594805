<div class="container-fluid p-0">
    <div class="row mt-5">
        <div class="col-12" style=" color: #757575;">
            <i class="fa fa-caret-left">&nbsp;&nbsp;
                <span class="header_text">{{FieldValue}}</span></i>
        </div>
        <!-- <div class="col-4 text-right">
            <img src="../../../assets/filter.png" class="img-fluid" (click)="openFilter()" /><sup><i
                    class="fa fa-circle fa-sm" style="color: #eaab5e;font-size: 5px;"></i></sup>&nbsp;<span
                style="color: #724e8c;font-weight:400 ;font-size: 0.8125rem;">Filter</span>
        </div> -->
    </div>
    <br>

    <div class="row mt-4 mx-auto" *ngFor="let item of VData">
        <div class="col-8 p-0">
            <div class="row">
                <div class="col-12">
                    <span class="Head_text">{{item?.description}}</span>
                    <span class="Head_text ms-2"
                        [ngStyle]="{ 'opacity': item.description ? '0.4' : 'normal' }">{{item?.fieldvalue  }} ({{item?.OutletType}})</span>
                </div>
            </div>
        </div>
        <div class="col-4 p-0 text-end" style="font-weight: 500;">
            <span *ngIf="item?.rightvisit==1">
                Right Visited<img class="mb-1" src="../../../assets/rightVisitedIcon.svg">
            </span>

            <span *ngIf="item?.wrongvisit==1">
                Wrong Visited<img class="mb-1" src="../../../assets/wrongVisitedIcon.svg">
            </span> 

            <span *ngIf="item?.notvisited==1">
                Not Visited<img class="mb-1" src="../../../assets/notVisitedIcon.svg">
            </span> 

        </div>
        <hr class="hr_cust mt-4">

    </div>

    <br><br><br><br><br><br><br>
</div>