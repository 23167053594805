<div>
  <div class="row mt-4">
    <div class="col-9">
      <i class="fa fa-caret-left" style="color: #757575;" *ngIf="FieldName != 'outlet'">&nbsp;&nbsp;
        <span class="field_Value">{{FieldValue=='ALLINDIA'? "All India" : FieldValue | titlecase}}</span>
      </i>
    </div>
    <div class="col-3 text-right" *ngIf="FieldName != 'outlet'" (click)="openFilter()">
      <img src="../../../assets/filter.png" class="img-fluid" />
      <sup><i class="fa fa-circle fa-sm" style="color: #eaab5e;font-size: 5px;"></i></sup>&nbsp;
      <span class="filter">Filter</span>
    </div>
  </div>
  <div class="row mt-3" *ngIf="FieldName != 'outlet'">
    <div class="col-3 text-left" *ngIf="datatype == 'achievement'">
      <span class="ml-2"><img src="../../../assets/info_btn.png" class="img-fluid" /></span>
      <span class="value_with_tax"> pre-tax</span>
    </div>
    <div class="col-9 text-right" *ngIf="datatype == 'achievement'">
      <img class="img-fluid" src="assets/sec_dot.png" />
      <span class="achivement mr-2"> Achievement</span>
      <img class="img-fluid" src="assets/pri_dot.png" />
      <span class="achivement"> Target</span>
    </div>
    <div class="col-12 text-right" *ngIf="datatype == 'woa' && (selected == 'P7' || selected == 'P5')">
      <img class="img-fluid" src="assets/sec_dot.png" />
      <span class="achivement mr-2"> Tgt Ach Outlets</span>
      <img class="img-fluid" src="assets/pri_dot.png" />
      <span class="achivement"> Total Outlets</span>
    </div>
    <div class="col-12 text-right" *ngIf="datatype == 'woa' && selected == 'Udaan'">
      <img class="img-fluid" src="assets/sec_dot.png" />
      <span class="achivement mr-2"> Tgt Ach Sub</span>
      <img class="img-fluid" src="assets/pri_dot.png" />
      <span class="achivement"> Total Sub</span>
    </div>
    <div class="col-12 text-right" *ngIf="datatype == 'woa' && selected == 'Milan'">
      <img class="img-fluid" src="assets/sec_dot.png" />
      <span class="achivement mr-2"> Tgt Ach Wholesaler</span>
      <img class="img-fluid" src="assets/pri_dot.png" />
      <span class="achivement"> Total Wholesalers</span>
    </div>
  </div>
  <div *ngIf="!IsOutlet && FieldName != 'outlet'">
    <div *ngFor="let item of getProgramtargetDetailsList; let i = index"
      (click)="RowClick(item.fieldname ,item.fieldvalue)">
      <div class="row mt-3">
        <div class="col-12" *ngIf="!IsRDValue">
          <span class="fieldValue">{{item.fieldvalue}}</span>
        </div>
        <div class="col-12" *ngIf="IsRDValue">
          <span class="fieldValue">{{item.desc | titlecase}}</span>
          <span class="desc ml-1">{{item.fieldvalue}}</span>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-3">
          <div class="row">
            <div class="col-12 text-center">
              <h1 class="m-0 percentage">{{item.percentage}}%</h1>
            </div>
          </div>
        </div>
        <div class="col-9">
          <div class="row mt-3">
            <div class="col-12">
              <div class="progress rounded-pill">
                <div class="progress-bar bg_sec" role="progressbar" [style.width.%]="item.ach" aria-valuenow="20"
                  aria-valuemin="0" aria-valuemax="100"></div>
                <div class="progress-bar bg_pri" role="progressbar" [style.width.%]="item.tgt" aria-valuenow="40"
                  aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-6 text-center">
              <h2 class="m-0 ach">{{item.ach}}<span class="cr" *ngIf="datatype == 'achievement'"> Lac</span></h2>
            </div>
            <div class="col-6 text-center">
              <h2 class="m-0 ach">{{item.tgt}}<span class="cr" *ngIf="datatype == 'achievement'"> Lac</span></h2>
            </div>
          </div>
        </div>
      </div>
      <hr>
    </div>
  </div>
  <div *ngIf="IsOutlet && FieldName != 'outlet'">
    <div *ngFor="let item of getProgramtargetDetailsList; let i = index">
      <div class="row mt-3">
        <div class="col-12">
          <span class="fieldValue">{{item.desc | titlecase}}</span>
          <span class="desc ml-1">{{item.fieldvalue}}</span>
          <span class="float-right" (click)="RowClick(item.fieldname ,item.fieldvalue)">
            <img class="img-fluid mr-1" src="assets/payout.png" />
            <span class="payout_Text">Payout</span>
          </span>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-3">
          <div class="row">
            <div class="col-12 text-center">
              <h1 class="m-0 percentage">{{item.percentage}}%</h1>
            </div>
          </div>
        </div>
        <div class="col-9">
          <div class="row mt-3">
            <div class="col-12">
              <div class="progress rounded-pill">
                <div class="progress-bar bg_sec" role="progressbar" [style.width.%]="item.ach" aria-valuenow="20"
                  aria-valuemin="0" aria-valuemax="100"></div>
                <div class="progress-bar bg_pri" role="progressbar" [style.width.%]="item.tgt" aria-valuenow="40"
                  aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-6 text-center">
              <h2 class="m-0 ach">{{item.ach}}<span class="cr" *ngIf="datatype == 'achievement'"> Lac</span></h2>
            </div>
            <div class="col-6 text-center">
              <h2 class="m-0 ach">{{item.tgt}}<span class="cr" *ngIf="datatype == 'achievement'"> Lac</span></h2>
            </div>
          </div>
        </div>
      </div>
      <hr>
    </div>
  </div>
  <div *ngIf="FieldName == 'outlet' && (selected == 'P7' || selected == 'P5')">
    <div class="row mt-3">
      <div class="col-6">
        <span class="payout">UTR No</span>
      </div>
      <div class="col-3 text-right">
        <span class="payout">Type</span>
      </div>
      <div class="col-3 text-right">
        <span class="payout">Amt ( <img class="img-fluid mr-1" src="assets/rupee.png" />)</span>
      </div>
    </div>
    <div *ngFor="let item of getProgramtargetDetailsList; let i = index">
      <div class="row mt-3">
        <div class="col-6">
          <span class="utr_No">{{item.utrno.trim()=="0"? '' :item.utrno}}</span>
        </div>
        <div class="col-3 text-right">
          <span class="fieldValue">{{item.paymenttype}}</span>
        </div>
        <div class="col-3 text-right">
          <span class="fieldValue">{{item.amount}}</span>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12">
          <span class="date_Value">{{item.utrno=="0"? '' :item.paymentdate}}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <span class="bank_Name">{{item.utrno=="0"? '' : item.bankname | titlecase}}</span>
        </div>
      </div>
      <hr>
    </div>
  </div>
  <div *ngIf="FieldName == 'outlet' && (selected == 'Milan' || selected == 'Udaan')">
    <div class="row mt-3">
      <div class="col-4">
        <span class="payout">Bill No</span>
      </div>
      <div class="col-4 text-right">
        <span class="payout">Upload Date</span>
      </div>
      <div class="col-4 text-right">
        <span class="payout">Amt ( <img class="img-fluid mr-1" src="assets/rupee.png" />)</span>
      </div>
    </div>
    <div *ngFor="let item of getProgramtargetDetailsList; let i = index">
      <div class="row mt-3">
        <div class="col-4">
          <span class="utr_No">{{item.utrno.trim()=="0"? '' :item.utrno}}</span>
        </div>
        <div class="col-4 text-right">
          <span class="fieldValue">{{item.uploaddate}}</span>
        </div>
        <div class="col-4 text-right">
          <span class="fieldValue">{{item.amount}}</span>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12">
          <span class="bank_Name">Bill Date</span>
          <span class="ml-1 date_Value">{{item.utrno.trim()=="0"? '' : item.paymentdate}}</span>
        </div>
      </div>
      <hr>
    </div>
  </div>
  <br>
  <br>
  <br>
</div>
