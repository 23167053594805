import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { InputPara, Resultdata, Seconderytrenddata} from 'src/app/models/kpi-models';
import { FilterComponent } from '../filter/filter.component';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {CommonUtilitiesService} from 'src/app/service/common-utilities.service';
import { DataService } from 'src/app/service/data.service';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
@Component({
  selector: 'app-primary-invoices',
  templateUrl: './primary-invoices.component.html',
  styleUrls: ['./primary-invoices.component.css']
})
export class PrimaryInvoicesComponent implements OnInit {
  Branchname: any;
  AreaName: any;
  UserName: any;

  resultdata: Resultdata[]=[];
  UserType: any;
  FieldName: any;
  FieldValue: any;
  device: any;
  deviceType: any;
  input:InputPara=new InputPara();
  listdata:any;
  FromDate: any ;
  ToDate: any ;
  rdName: any;
  token: any;
  formname: any;
  actiondesc: any;
  userId:any;

  constructor(private activatedRoute: ActivatedRoute,private cs:CommonUtilitiesService,private headerService: HeaderService,private ds: DataService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.headerService.setActiveURl('/Secondarytrend');
    this.headerService.setTitle('Primary Invoices');
    this.token = localStorage.getItem("TokenNo");
    this.UserType = localStorage.getItem("UserType");
      this.listdata=[];
      this.FieldName ="";
      this.device = localStorage.getItem("Device");
      this.deviceType = localStorage.getItem("DeviceType");
      this.rdName="";
      this.activatedRoute.paramMap.forEach(params => {
        this.FieldName = params.get('FieldName');
        this.FieldValue = params.get('FieldValue');
        this.UserName = params.get('UserID');
      //  this.loadData();
      });
      if(this.FieldName =="")
      {
          this.FieldName=localStorage.getItem("FieldName");
          this.FieldValue=localStorage.getItem("FieldValue");
      }

      this.userId = localStorage.getItem('UserID');
    this.formname = "Secondarytrend";
    this.actiondesc = "Secondary Trend";
    this.cs.GetGeoLocation(this.userId, this.UserType, this.formname, this.actiondesc, this.FieldName, this.FieldValue);

  }
  SelectFromDate(type: string, event: MatDatepickerInputEvent<Date>) {
    debugger;
    let x:any = event.value;
    var y = x.getFullYear().toString();
    var m = (x.getMonth() + 1).toString();
    var d = x.getDate().toString();
    (d.length == 1) && (d = '0' + d);
    (m.length == 1) && (m = '0' + m);
    var fDate
    this.FromDate= y +'-'+ m +'-'+  d;
  }
  SelectToDate(type: string, event: MatDatepickerInputEvent<Date>) {

    let x:any = event.value;
    var y = x.getFullYear().toString();
    var m = (x.getMonth() + 1).toString();
    var d = x.getDate().toString();
    (d.length == 1) && (d = '0' + d);
    (m.length == 1) && (m = '0' + m);
   this.ToDate = y +'-'+ m +'-'+  d;

  }
  loadData():void{
    debugger;




   const input ={
    "FromDate" :this.FromDate,
    "ToDate" :this.ToDate ,
    "Filter" :"MeraApp",
    "Salesh":this.FieldValue,
    "Townclass":"",
    "Userid": this.UserName
   }
   debugger;
    this.ds.PrimaryUploadStatus(input,this.token,this.device,this.deviceType,'Primary Invoice').subscribe(res => {
this.rdName=res[0].rdName;
      this.listdata=res ;
  });
  }
  openFilter(){
    if (this.dialog.openDialogs.length == 0) {

      const dialogRef = this.dialog.open(FilterComponent, {
        closeOnNavigation: true,
        data: {
          "FieldName": localStorage.getItem("OriginalFieldName"),
          "FieldValue": localStorage.getItem("OriginalFieldValue"),
          "UserID": this.UserName,
          "MenuUpTo": "5",
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.FieldName=result.FieldName;
          this.FieldValue=result.FieldValue;
          this.loadData();
              }
      });
    }
  }
}
