import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ResNotificationList } from 'src/app/models/kpi-models';
import { EncryptionService } from '../../service/encryption.service';
import { DataService } from '../../service/data.service';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { Enclass, ResultClass } from 'src/app/models/encryption-class';



@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {
  ID: any;
  status: string = '';
  Flag: any;
  ParaName: any;
  UserID: any;
  UserType: any;
  ParaValue: any;
  TokenNo: any;
  ClientID: any;
  notificationlist: any;
  device: any;
  deviceType: any;
  public resnotificationlist: ResNotificationList = new ResNotificationList();
  notificationImageUrl: any;
  notificationFilterList: any;
  praiseList: any;
  formname: any;
  actiondesc: any;
  fieldName: any;
  fieldValue: any;
  secretKey: any;
  public enclass: Enclass = new Enclass();
  public resultClass: ResultClass = new ResultClass();
  ticketResponse: any = []
  constructor(private activatedRoute: ActivatedRoute, private fb: FormBuilder, private es: EncryptionService, private router: Router,
    private headerService: HeaderService, private ds: DataService, private cs: CommonUtilitiesService, private mds: MongoDataService) { }

  ngOnInit(): void {
    this.headerService.setTitle('Notification');
    this.ID = "0";
    this.Flag = "Meraapp";
    this.UserID = localStorage.getItem('UserID');
    this.TokenNo = localStorage.getItem("TokenNo");
    this.ClientID = localStorage.getItem("ClientID");
    this.device = localStorage.getItem('Device');
    this.deviceType = localStorage.getItem('DeviceType');
    this.UserType = localStorage.getItem('UserType');
    this.fieldName = localStorage.getItem("FieldName");
    this.fieldValue = localStorage.getItem("FieldValue");
    this.notificationImageUrl = localStorage.getItem("baseappurl") + "MeraApp2/Notification/";
    this.secretKey = localStorage.getItem("SecreteKey")
    this.formname = "notifications";
    this.actiondesc = "Notifications";
    this.cs.GetGeoLocation(this.UserID, this.UserType, this.formname, this.actiondesc, this.fieldName, this.fieldValue);
    this.loadData();
  }

  loadData(): void {
    this.ds.GetNotificationData(this.UserID, this.ID, this.Flag, 0, this.TokenNo, this.ClientID, this.device, this.deviceType, 'Notification details').subscribe(res => {
      console.log("GetNotificationData", res)
      this.notificationlist = []
      let Ndata = res as ResNotificationList;
      this.notificationlist = Ndata
      this.notificationlist?.map((m: any) => m.dataFrom = "");
      this.HD_TicketNotification()
    });
  }

  HD_TicketNotification() {
    debugger
    let request = {
      "companyCode": "mdlz",
      "userId": this.UserID
    }
    this.es.WordKey = this.secretKey
    this.es.encrypt(this.secretKey);
    this.es.Word = JSON.stringify(request);
    this.enclass.buggerstring = this.es.encrypt(this.secretKey)
    this.mds.HD_TicketNotification(this.enclass, this.ClientID, 'Notification details').subscribe(res => {
      this.resultClass = res;
      this.es.Word = this.resultClass.resultString;
      this.ticketResponse = JSON.parse(this.es.decrypt(this.secretKey));
      console.log("GetNotificationData", this.ticketResponse)
      this.notificationlist.push(...this.ticketResponse.Data.sort((a: any, b: any) => new Date(b?.activeFrom).getTime() - new Date(a?.activeFrom).getTime()));
    }, (err: any) => {
      debugger
    });
  }

  NotificationDetails(item: any, ID: number, flag: string, dataFrom: any) {
    localStorage.setItem("notificationdetails", JSON.stringify(item))
    this.router.navigate(['/home/notificationdetails/', ID, flag, dataFrom]);
  }

  onTabChanged(e: any) {
    if (e.index == 0) {

    } else if (e.index == 1) {
      this.notificationFilterList = this.notificationlist.filter((singleItem: { [x: string]: string; }) => (singleItem['flag'] == "NOT"));
      console.log("notificationFilterList", this.notificationFilterList)
      this.notificationFilterList.push(...this.ticketResponse.Data)
    } else if (e.index == 2) {
      this.praiseList = this.notificationlist.filter((singleItem: { [x: string]: string; }) => (singleItem['flag'] == "PRAISE"));
      console.log("praiseList", this.praiseList)
    }
  }

}
