import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from 'src/app/service/header.service';
import { DataService } from 'src/app/service/data.service';
import { DatePipe } from '@angular/common';
import { getRdList,InputPara } from 'src/app/models/kpi-models';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-fssai-approval',
  templateUrl: './fssai-approval.component.html',
  styleUrls: ['./fssai-approval.component.css']
})
export class FssaiApprovalComponent implements OnInit {
  deviceType: any;
  device: any;
  list:any;
  listOrigin:any;
  private _searchText = '';
  userType: string | null = '';
  userId: any;
  FieldName: any;
  FieldValue: any;
  myBooks: any;
  userList1: any;
  lastkeydown1: number = 0;
  selectedText: any;
  input:InputPara=new InputPara();
  token: any;
  formname:any;
  actiondesc:any;

  constructor(private cs:CommonUtilitiesService,private activatedRoute: ActivatedRoute, private router: Router, private headerService: HeaderService, private datePipe: DatePipe, private ds: DataService,) { }

  ngOnInit(): void {
    this.headerService.setTitle('FSSAI Approval');
    this.headerService.setActiveURl('/fssaiApproval');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.token = localStorage.getItem("TokenNo");
    this.userId = localStorage.getItem("UserID");
    this.userType = localStorage.getItem("UserType");
    this.cs.setFieldNamevalue();
    this.FieldName=localStorage.getItem("FieldName");
    this.FieldValue=localStorage.getItem("FieldValue");
    this.list=[];
    this.listOrigin=[];
    this.formname="fssaiApproval";
    this.actiondesc="Fssai Approval";
    this.cs.GetGeoLocation(this.userId,this.userType,this.formname,this.actiondesc,this.FieldName,this.FieldValue);
    this.onLoad();
  }

  onLoad() {
    const dataToSend = {
      FieldName : this.userType,
      FieldValue  : '',
      Datatype : '0',
      TagName :  'DETAIL',
      UserID :this.userId
    };
    console.log(dataToSend);
    this.ds.getFSSAIComplianceDetail(dataToSend, this.token, this.device, this.deviceType,'FSSAI Approval').subscribe(res => {
    this.listOrigin= this.list = res;
    });
  }

  RowClick(e: any): void {
    debugger;
    localStorage.setItem("FSSAIApproval",JSON.stringify(e))
    this.router.navigate(['/home/fssaiDetailsApproval/']);
  }
  get searchTerm(): any{
    return this._searchText;
  }
  set searchTerm(value: any){
     this._searchText = value;
     this.listOrigin = this.filteredListMethod(value);
  }

  filteredListMethod(value: any): any {
    return this.list.filter((ele: any) =>(
      ele.rdname.toUpperCase().indexOf(value.toUpperCase()) !== -1 || ele.rdCode.toString().toUpperCase().indexOf(value.toUpperCase()) !== -1));
  }
}

