<div>
  <div class="row mt-4">
    <div class="col-12">
      <span class="text_16 text_black">Outlet</span>
    </div>
    <div class="col-12 mt-1" *ngIf="selected != 'VS'">
      <span class="text_14 text_black">{{outletSelectedA.outletname | titlecase}}</span>
      <span class="ml-1 text_14 text_black text_bold">{{outletSelectedA.outletcode}}</span>
    </div>
    <div class="col-12 mt-1" *ngIf="selected == 'VS'">
      <span class="text_14 text_black">{{outletSelectedA.outletname | titlecase}}</span>
      <span class="ml-1 text_14 text_black text_bold">{{outletSelectedA.outletcode}}</span>
    </div>
  </div>
  <hr  *ngIf="selected != 'VS'">
  <div class="row" *ngIf="selected != 'VS'">
    <div class="col-12">
      <span class="text_16 text_black">RD</span>
    </div>
    <div class="col-12 mt-1">
      <span class="text_14 text_black">{{outletSelectedA.rdname | titlecase}}</span>
      <span class="ml-1 text_14 text_black text_bold">{{outletSelectedA.rdcode}}</span>
    </div>
  </div>
  <hr *ngIf="selected == 'VS'">
  <div class="row" *ngIf="selected == 'VS'">
    <div class="col-12">
      <span class="text_16 text_black">RD</span>
    </div>
    <div class="col-12 mt-1">
      <span class="text_14 text_black">{{outletSelectedA.rdName | titlecase}}</span>
      <span class="ml-1 text_14 text_black text_bold">{{outletSelectedA.rdCode}}</span>
    </div>
  </div>
  <hr>
  <div class="row" *ngIf="selected != 'VS'">
    <div class="col-12">
      <span class="text_16 text_black">Mobile No</span>
    </div>
    <div class="col-12 mt-1">
      <span class="text_14 text_black">{{outletSelectedA.cellno}}</span>
    </div>
  </div>
  <hr *ngIf="selected != 'VS'">
  <div class="row">
    <div class="col-12">
      <span class="text_16 text_black">Address 01</span>
    </div>
    <div class="col-12 mt-1">
      <span class="text_14 text_black" *ngIf="selected != 'VS'">{{outletSelectedA.address1 | titlecase}}</span>
      <span class="text_14 text_black" *ngIf="selected == 'VS'">{{address | titlecase}}</span>
    </div>
  </div>
  <hr>
  <div class="row" *ngIf="selected == 'VS'">
    <div class="col-12">
      <span class="text_16 text_black">FIR Date</span>
    </div>
    <div class="col-12 mt-1">
      <span class="text_14 text_black">{{outletSelectedA.firdate | date:'dd/MM/yyyy'}}</span>
    </div>
  </div>
  <hr *ngIf="selected == 'VS'">
  <div class="row" *ngIf="selected == 'VS'">
    <div class="col-12">
      <span class="text_16 text_black">FIR Reason</span>
    </div>
    <div class="col-12 mt-1">
      <span class="text_14 text_black">{{outletSelectedA.reason | titlecase}}</span>
    </div>
  </div>
  <hr *ngIf="selected == 'VS'">
  <div class="row" *ngIf="selected != 'VS'">
    <div class="col-12">
      <span class="text_16 text_black">SO Territory</span>
    </div>
    <div class="col-12 mt-1">
      <span class="text_14 text_black">{{outletSelectedA.soTerritory}}</span>
    </div>
  </div>
  <hr *ngIf="selected != 'VS'">
  <div class="row">
    <div class="col-6">
      <span class="text_18 text_black">Visicooler</span>
    </div>
    <div class="col-6 text-right">
      <span class="text_18 text_black">Visicooler 2</span>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-6 form-check">
      <input type="radio" class="" id="checkbox1" name="radio1"  (change)="selected_Reason($event)">
      <label for="checkbox1" class="ml-3 text_16 text_black form-check-label">{{selected != 'VS' ? outletSelectedA.visicooler : outletSelectedA.visicoolertype}}</label>
    </div>
       <div class="col-6 text-right form-check">
        <input type="radio" class="" id="checkbox2" name="radio1" [disabled]="outletSelectedA.visicooler2=='NONE'  ? true : false" (change)="selected_Reasonvisi2($event)" >
        <label for="checkbox2" class="ml-3 text_16 text_black form-check-label" > {{selected != 'VS' ? outletSelectedA.visicooler2 : "NONE"}}</label>
      <!-- <label class="text_16 text_black" style="opacity: 40%;">{{outletSelectedA.visiDate}}</label> -->
    </div>
   

    <br>
    <br>
    <br>
    <br>
    <br>
  </div>
  <div class="footer" *ngIf="isCheck || isCheckvisi2">
    <div class="row text-center mt-3">
      <div class="col-12" (click)="rowClick()">
        MOVE VISICOOLER
      </div>
    </div>
  </div>
