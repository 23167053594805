<div>
    <div class="row mt-4">
      <div class="col-12">
        <input class="fullWidth" type="text" name="" id="" placeholder="Search PC Name"
          [(ngModel)]="searchTerm">
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <span class="PC_List">PC List</span>
      </div>
    </div>
    <div *ngFor="let item of filteredList; let i = index;">
      <div class="row mt-3">
        <div class="col-8">
          <span class="pc_Name">{{item.pcName | titlecase}}</span>
          <span class="ml-1 pc_Code">{{item.pcuid}}</span>
        </div>
        <div class="col-4 text-right">
          <!-- <div class="round">
            <input type="checkbox" id="checkbox" value={{item.pcuid}} id="Check{{i}}" [(ngModel)]="list[i].checked" (change)="selected = i; selected_pc($event)"/>
            <label for="checkbox"></label>
          </div> -->
          <input type="checkbox" class="form-check-input" value={{item.pcuid}} id="Check{{i}}" [(ngModel)]="list[i].checked" (change)="selected = i; selected_pc($event)" >
        </div>
      </div>
      <hr>
    </div>
    <br>
    <br>
    <br>
    <br>
    <br>
  </div>
  <div class="footer" >
    <div class="row text-center mt-3">
      <div class="col-12" (click)="btnClick()">
        SAVE
      </div>
    </div>
  </div>
  