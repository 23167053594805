import { Component, OnInit } from '@angular/core';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
  panelOpenState: boolean = false;
  token: any;
  formname:any;
  actiondesc:any;
  fieldName: any;
  fieldValue: any;
  userType: string | null = '';
  userId: any;
  constructor(private cs:CommonUtilitiesService) { }

  ngOnInit(): void {
    this.panelOpenState = false;

    this.fieldName = localStorage.getItem("FieldName");
    this.fieldValue = localStorage.getItem("FieldValue");
    this.userType = localStorage.getItem("UserType");
    this.formname="faq";
    this.actiondesc="faq"
    this.cs.GetGeoLocation(this.userId,this.userType,this.formname,this.actiondesc,this.fieldName,this.fieldValue);
  }
}
