<div>
  <div class="row mt-4">
    <div class="col-12">
      <span class="text_16 text_black">Outlet Name</span>
    </div>
    <div class="col-12 mt-1">
      <span class="outletname">{{outLetSelected.outletname | titlecase}}</span>
      <span class="ml-1 outletcode">{{outLetSelected.outletcode}}</span>
    </div>
  </div>
  <hr>
  <div class="row">
    <div class="col-12">
      <span class="text_16 text_black">RD</span>
    </div>
    <div class="col-12 mt-1">
      <span class="outletname">{{outLetSelected.rdname | titlecase}}</span>
      <span class="ml-1 outletcode">{{outLetSelected.rdcode}}</span>
    </div>
  </div>
  <hr>
  <div class="row">
    <div class="col-12">
      <span class="text_16 text_black">Mobile No</span>
    </div>
    <div class="col-12 mt-1">
      <span class="outletname">{{outLetSelected.cellno}}</span>
    </div>
  </div>
  <hr>
  <div class="row">
    <div class="col-12">
      <span class="text_16 text_black">Contact Person</span>
    </div>
    <div class="col-12 mt-1">
      <span class="outletname">{{outLetSelected.contactperson}}</span>
    </div>
  </div>
  <hr>
  <div class="row">
    <div class="col-12">
      <span class="text_16 text_black">Address 01</span>
    </div>
    <div class="col-12 mt-1">
      <span class="outletname">{{outLetSelected.address1| titlecase}} {{outLetSelected.address2|
        titlecase}}</span>
    </div>
  </div>
  <hr>
  <div class="row">
    <div class="col-12">
      <span class="text_16 text_black">Visicooler</span>
    </div>
    <div class="col-12 mt-1">
      <span class="outletname">{{outLetSelected.visicooler}}</span>
    </div>
  </div>
  <hr>

  <div class="row">
    <div class="col-12">
      <span class="text_16 text_black">VC Make</span>
    </div>
    <div class="col-12 mt-1">
      <span class="outletname">{{outLetSelected.visimake | titlecase}}</span>
    </div>
  </div>
  <hr>
  <br>
  <br>
  <br>
  <br>
  <br>
</div>
<div class="footer">
  <div class="row text-center mt-3">
    <div class="col-12" (click)="rowClick()">
      ADD COMPLAINT
    </div>
  </div>
</div>
