import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { DataService } from 'src/app/service/data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-claim-details-two-approval',
  templateUrl: './claim-details-two-approval.component.html',
  styleUrls: ['./claim-details-two-approval.component.css']
})
export class ClaimDetailsTwoApprovalComponent implements OnInit {
  @ViewChild('openModalThankYou', { static: true }) elRefOpenModalThankYou: ElementRef | undefined;
  deviceType: any;
  device: any;
  userType: string | null = '';
  userId: any;
  claimDetailsTwo: any;
  list: any;
  totalAmt: number = 0;
  count: number = 0;
  currYearNo: string | null = '';
  currmonthno: string | null = '';
  isSelected: boolean = false;
  isSelectedObj: any;
  selected = -1;
  dtTemplate: any[]=[];
  amount: number = 0;
  token: any;
  formname:any;
  actiondesc:any;
  fieldName: any;
  fieldValue: any;
  constructor(private activatedRoute: ActivatedRoute, private router: Router,private cs:CommonUtilitiesService,
     private headerService: HeaderService, private mds: MongoDataService, private ds: DataService, private modalService: NgbModal,) { }

  ngOnInit(): void {
    this.headerService.setTitle('Claim Approvals');
    this.headerService.setActiveURl('/claimDetailsTwoApproval');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.token = localStorage.getItem("TokenNo");
    this.userType = localStorage.getItem("UserType");
    this.userId = localStorage.getItem("UserID");
    this.currYearNo = localStorage.getItem("curryearno");
    this.currmonthno = localStorage.getItem("currmonthno");
    this.claimDetailsTwo = JSON.parse(localStorage.getItem("claimDetailsTwo") || '{}');
    console.log("claimDetailsTwo",this.claimDetailsTwo);

    
    this.fieldName = localStorage.getItem("FieldName");
    this.fieldValue = localStorage.getItem("FieldValue");

    this.formname="claimDetailsTwoApproval";
    this.actiondesc="claim Details Two Approval"
    this.cs.GetGeoLocation(this.userId,this.userType,this.formname,this.actiondesc,this.fieldName,this.fieldValue);
    this.onLoad();
  }

  onLoad() {
    if(this.userType?.toUpperCase() == 'SO'){
      this.ds.getSOAuthorisationByID(Number(this.claimDetailsTwo.yearNo), Number(this.claimDetailsTwo.monthNo), this.claimDetailsTwo.rdcode, this.claimDetailsTwo.claimTypeID, this.token, this.device, this.deviceType, 'Claim Approvals').subscribe(res => {
        console.log("getSOAuthorisationByID",res)
        this.list = res;
        this.totalAmtFinding();
      });
    } else if(this.userType?.toUpperCase() == 'ASM'){
      this.ds.getASMAuthorisationByID(Number(this.claimDetailsTwo.yearNo), Number(this.claimDetailsTwo.monthNo), this.claimDetailsTwo.rdcode, this.claimDetailsTwo.claimTypeID, this.token, this.device, this.deviceType, 'Claim Approvals').subscribe(res => {
        console.log("getASMAuthorisationByID",res)
        this.list = res;
        this.totalAmtFinding();
      });
    } else if(this.userType?.toUpperCase() == 'BSM'){
      this.ds.getBSMAuthorisationByID(Number(this.claimDetailsTwo.yearNo), Number(this.claimDetailsTwo.monthNo), this.claimDetailsTwo.rdcode, this.claimDetailsTwo.claimTypeID, this.token, this.device, this.deviceType, 'Claim Approvals').subscribe(res => {
        console.log("getBSMAuthorisationByID",res)
        this.list = res;
        this.totalAmtFinding();
      });
    }
  }

  totalAmtFinding() {
    if(this.list.length > 0 ) {
      this.count = this.list.length;
      this.list.forEach((ele: { claimAmt: number; }) => {
        this.totalAmt += Number(ele.claimAmt);
      });
    }

  }

  rowClick(data: any){
    localStorage.setItem("claimDetailsEditApproval",JSON.stringify(data))
    this.router.navigate(['/home/claimDetailsEditApproval']);
  }

  approve(){
    if(this.userType?.toUpperCase() == 'SO') {
      const dataToSend = {
        DTTemplate: this.dtTemplate,
        userid : this.userId,
        Flag : 'POPUP',
      };
      console.log("dataToSend",dataToSend)
      this.ds.rdCLAIMNew_SOUpdate(dataToSend, this.token, this.device, this.deviceType, 'Claim Approvals').subscribe(res => {
        console.log("rdCLAIMNew_SOUpdate",res)
        this.openModal(this.elRefOpenModalThankYou);
      });
    } else if(this.userType?.toUpperCase() == 'ASM') {
      const dataToSend = {
        DTTemplate: this.dtTemplate,
        userid : this.userId,
        Flag : 'POPUPMULTIPLE',
        RejectedRemark : '',
        ApprovedAmt : 0,
        OpStatus : 'Approved',
      };
      console.log("dataToSend",dataToSend)
      this.ds.rdCLAIMNew_ASMUpdate(dataToSend, this.token, this.device, this.deviceType, 'Claim Approvals').subscribe(res => {
        console.log("rdCLAIMNew_ASMUpdate",res)
        this.openModal(this.elRefOpenModalThankYou);
      });
    } else if(this.userType?.toUpperCase() == 'BSM') {
      const dataToSend = {
        DTTemplate: this.dtTemplate,
        userid : this.userId,
        Flag : 'POPUPMULTIPLE',
        RejectedRemark : '',
        ApprovedAmt : 0,
        OpStatus : 'Approved',
      };
      console.log("dataToSend",dataToSend)
      this.ds.rdCLAIMNew_BSMUpdate(dataToSend, this.token, this.device, this.deviceType, 'Claim Approvals').subscribe(res => {
        console.log("rdCLAIMNew_BSMUpdate",res)
        this.openModal(this.elRefOpenModalThankYou);
      });
    }
  }

  openModal(modal: ElementRef<any> | undefined) {
    this.modalService.open(modal,
      {
        size: 'lg',
        backdrop: 'static',
        windowClass: '',
        keyboard: false,
        centered: true,
      })
  }

  reject(){
    this.router.navigate(['/home/claimApproval']);
  }

  okay(){
    this.modalService.dismissAll();
    this.router.navigate(['/home/claimApproval']);
  }

  modalClose() {
    this.modalService.dismissAll();
    this.router.navigate(['/home/claimApproval']);
  }

  clickOnSelect(e: any, id: any, claimNo: any) {
    if (e.target.checked) {
      this.isSelected = e.target.value;
      this.isSelectedObj = this.list[id];
      this.amount += this.isSelectedObj.claimAmt;
      this.dtTemplate.push({Para1: this.isSelectedObj.yearNo.trim(), Para2: this.isSelectedObj.monthNo.trim(), Para3: this.isSelectedObj.rdCode.trim(), Para4: this.isSelectedObj.claimNo.trim(), Para5: "", Para6: "", Para7: "", Para8: "", Para9: "", Para10: "",});
    } else {
      this.list.forEach((element: any, index: any) => {
        if (element.claimNo.trim() == claimNo.trim()) {
          this.amount -= element.claimAmt;
        }
      });
      this.isSelected = false;
      this.isSelectedObj = null;
      this.dtTemplate.forEach((element: any, index: any) => {
        if (element.Para4 == claimNo.trim()) {
          this.dtTemplate.splice(index, 1);
        }
      });
    }
  }

}
