<div>
      <div class="row mt-4">
      <div class="col-9">
        <i class="fa fa-caret-left" style="color: #757575;" >&nbsp;&nbsp;
        <span class="header_text">{{FieldValue=='0'? 'All India' : FieldValue=='ALLINDIA' ? 'All India': FieldValue.toUpperCase()=='INDIA'? 'All India' :FieldValue |titlecase}}</span></i>
      </div>
      <div class="col-3 text-right">
        <img src="../../../assets/filter.png" class="img-fluid" (click)="openFilter()" /><sup><i
            class="fa fa-circle fa-sm" style="color: #eaab5e;font-size: 5px;"></i></sup>&nbsp;<span
          class="filter_text">Filter</span>
      </div>
    </div>
   
    <br>
    <div class="row mt-2" *ngFor="let item of resList" (click)="RowClick(item.fieldname,item.fieldvalue,'1')">
      <div class="col-12">
        <div class="row">
          <div class="col-12">
            <span class="Head_text">{{ item.pcname}}[{{item.fieldvalue}}]</span>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-3">
            <div class="row mt-2">
              <div class="col-12">
                <span class="Value_text">{{item.mtd}}</span>
              </div>
            </div>
          </div>
          <div class="col-9">
            <div class="row mt-2">
             
                <div class="col-4">
                    <div class="text_per">{{item.vccharging}}</div>
                    
                    <div class="text_val">Charged</div>
                  </div>
                  <div class="col-4">
                    <div class="text_per">{{item.hotzone}}</div>
                    
                    <div class="text_val">Hotzone</div>
                  </div>
               

              </div>
              <div class="row mt-2">
                <div class="col-4">
                  <div class="text_per">{{item.audit1}}</div>
                  
                  <div class="text_val">Audit+1</div>
                </div>
                <div class="col-4">
                    <div class="text_per">{{item.audit2}}</div>
                    
                    <div class="text_val">Audit+2</div>
                  </div>
                  <div class="col-4">
                    <div class="text_per">{{item.analyze}}</div>
                    
                    <div class="text_val">Analyzability</div>
                  </div>

              </div>
         
          
          </div>
        </div>
        <hr class="hr_cust">
      </div>    
    </div>  
    <br>
    <br>
    <br>
    <br>
    <br>
  </div>
  

