<div class="row mt-2 bg_Color">
  <div class="col-12 mt-4">
    <span class="rd_Name_Head">{{claimDetailsTwo.rdname | titlecase}}</span>
    <span class="ml-1 rd_Code_Head">{{claimDetailsTwo.rdcode}}</span>
  </div>
  <div class="col-4 mt-2 mb-4">
    <span class="total_Claim_Text">Total Claim</span>
    <span class="ml-1 total_Claim_Value">{{count}}</span>
  </div>
  <div class="col-8 mt-2 mb-4 text-right">
    <span class="total_Claim_Amount">Total Claim Amount</span>
    <span class="ml-2 total_Claim_Amount_Value"><img class="img-fluid mr-1" src="assets/rupee.png" />{{totalAmt | number: '1.0-2'}}</span>
  </div>
</div>
<div *ngFor="let item of list; let i = index;">
  <div class="myDiv">
    <div class="container-fluid">
      <div class="row">
        <div class="col-6 mt-3">
          <span class="claim_No">Claim No</span>
        </div>
        <div class="col-6 mt-3 text-right">
          <span class="claim_No_Value">{{item.claimNo}}</span>
          <input class="ml-1" type="checkbox" id="Check{{i}}" (change)="selected = i; clickOnSelect($event,i, item.claimNo)"
                [(ngModel)]="list[i].checked">
        </div>
      </div>
      <div (click)="rowClick(item)" >
        <div class="row mt-2">
          <div class="col-6">
            <span class="claim_No">Amount ( <img class="img-fluid mr-1" src="assets/claim_small_rupee.svg" />)</span>
          </div>
          <div class="col-6 text-right">
            <span class="amount_Value">{{item.claimAmt}}</span>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-6">
            <span class="claim_No">Scheme</span>
          </div>
          <div class="col-6 text-right">
            <span class="amount_Value">{{item.claimDetails}}</span>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-6">
            <span class="claim_No">Activity code</span>
          </div>
          <div class="col-6 text-right">
            <span class="amount_Value">{{item.activityCode}}</span>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-6 mb-3">
            <span class="claim_No">SE Approval Status</span>
          </div>
          <div class="col-6 text-right mb-3">
            <img src="assets/fssai_approved.svg" *ngIf="item.seApproval.toUpperCase() == 'YES'"/>
            <img class="btn_img" src="assets/fssai_pending.svg" *ngIf="item.seApproval.toUpperCase() == 'NO'" />
            <!-- <img src="assets/fssai_rejected.svg" *ngIf="item.activityCode == ''"/> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<br>
<br>
<br>
<br>
<br>
<div class="footer" *ngIf="isSelected">
  <div class="row">
    <div class="col-12" (click)="approve()">
      <button class="btn btn_APPROVE">APPROVE</button>
    </div>
  </div>
</div>
<!-- <div class="footer" *ngIf="isSelected && userType?.toUpperCase() != 'SO'">
  <div class="row">
    <div class="col-6 pr-0" (click)="reject()">
      <button class="btn btn_REJECT">REJECT</button>
    </div>
    <div class="col-6 pl-0" (click)="approve()">
      <button class="btn btn_APPROVE">APPROVE</button>
    </div>
  </div>
</div> -->

<ng-template #openModalThankYou let-modal>
  <div class="modal-header">
    <h4 class="modal-title text_color_head text_20" id="modal-basic-title">
      <img class="img-fluid" src="assets/thanks_icon.png" />
      <span class="ml-3">Thank You</span>
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modalClose()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <span class="model_PopUp_Text"><img class="img-fluid mr-1" src="assets/claim_rupee.svg" />{{amount}} Claim Approved
          Successfully</span>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <span class="model_PopUp_Text">Against</span>
      </div>
    </div>
    <div class="row mt-4 mb-2">
      <div class="col-12">
        <span class="pc_Name_PopUp">{{claimDetailsTwo.rdname | titlecase}}</span>
        <span class="ml-2 pc_Code_PopUp">{{claimDetailsTwo.rdcode}}</span>
      </div>
    </div>
  </div>
  <div class="modal-footer" (click)="okay()">
    OKAY
  </div>
</ng-template>
