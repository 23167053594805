import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { DataService } from 'src/app/service/data.service';
import { ToastrService } from 'ngx-toastr';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-survey-approval-details',
  templateUrl: './survey-approval-details.component.html',
  styleUrls: ['./survey-approval-details.component.css']
})
export class SurveyApprovalDetailsComponent implements OnInit {
  deviceType: any;
  device: any;
  userType: string | null = '';
  userId: any;
  list: any;
  msg: any;
  private _searchText = '';
  token: any;
  surveyApprovalDetails: any;
  public rejectedReason: any="";
  FormName: any;
  public surveyDetails = [];
  formname:any;
  actiondesc:any;
  fieldName: any;
  fieldValue: any;
  url:any;
  constructor(private headerService: HeaderService,private cs:CommonUtilitiesService, private router: Router, private ds: DataService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.FormName = "surveyApproval"
    this.headerService.setTitle('Universe Mapping');
    this.headerService.setActiveURl('/surveyApprovalDetails');
    this.url=this.ds.url
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.userId = localStorage.getItem("UserID");
    this.token = localStorage.getItem("TokenNo");
    this.surveyApprovalDetails = localStorage.getItem("surveyApprovalDetails");
    this.surveyApprovalDetails = JSON.parse(this.surveyApprovalDetails);
    console.log("surveyApprovalDetails", this.surveyApprovalDetails)
    console.log("rejectedReason",this.rejectedReason);

    
    this.userType = localStorage.getItem("UserType");
    this.fieldName = localStorage.getItem("FieldName");
    this.fieldValue = localStorage.getItem("FieldValue");
    this.formname = "surveyApprovalDetails"
    this.actiondesc = "Universe Re-Mapping Details";
    this.cs.GetGeoLocation(this.userId, this.userType, this.formname, this.actiondesc, this.fieldName, this.fieldValue);
  }

  saveRecords() {
    debugger
    let request = {
      "userid": this.userId,
      "rdcode": this.surveyApprovalDetails.rdcode,
      "outletcode": this.surveyApprovalDetails.outletcodeuid,
      "status": "approved",
      "reason": ""
    }
    this.ds.MA_SetSurveyApproval(request, this.token, this.device, this.deviceType, this.FormName).subscribe(res => {
      debugger
      console.log("respn", res)
      this.surveyDetails = res;
      console.log("list", this.surveyDetails)
      if (res[0].result.toLowerCase() == "ok") {
        this.router.navigate(['/home/surveyApproval']);
      } else {
        this.toastr.warning(res[0].result, "Warning");
      }
    });
  }

  Reject() {
    console.log("rejectedReason", this.rejectedReason)
    // this.router.navigate(['/home/surveyApproval']);
    debugger
    if(this.rejectedReason!=""){
      let request = {
        "userid": this.userId,
        "rdcode": this.surveyApprovalDetails.rdcode,
        "outletcode": this.surveyApprovalDetails.outletcodeuid,
        "status": "rejected",
        "reason": this.rejectedReason
      }
      this.ds.MA_SetSurveyApproval(request, this.token, this.device, this.deviceType, this.FormName).subscribe(res => {
        debugger
        this.surveyDetails = res;
        console.log("list", this.surveyDetails)
        if (res[0].result.toLowerCase() == "ok") {
          this.router.navigate(['/home/surveyApproval']);
        } else {
          this.toastr.warning(res[0].result, "Warning");
        }
      });
    }else{
      this.toastr.warning("Please write rejected reason", "Warning");
    }
  }
}
