<div class="container-fluid">
    <div class="row" style="margin-top: 2rem!important;">
        <div class="col-4 text-center" (click)="onClick('Courage')">
            <img src="../../../assets/Pr_Courage.svg" class="img-fluid imgshadow">
           <!-- <button class="btn btn1" (click)="onClick('Courage')"></button> -->
        </div>
        <div class="col-4 text-center" (click)="onClick('Optimism')">
            <img src="../../../assets/Pr_Optimism.svg" class="img-fluid imgshadow">
            <!-- <button class="btn btn2" (click)="onClick('Optimism')"></button> -->
        </div>
        <div class="col-4 text-center" (click)="onClick('Inclusive')">
            <img src="../../../assets/Pr_Inclusive.svg" class="img-fluid imgshadow">
            <!-- <button class="btn btn3" (click)="onClick('Inclusive')"></button> -->
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-4 text-center">
           <span class="cust_text" >Courage</span>
        </div>
        <div class="col-4 text-center">
            <span class="cust_text" >Optimism</span>
        </div>
        <div class="col-4 text-center">
            <span class="cust_text" >Inclusive</span>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-4 text-center" (click)="onClick('Awesome')">
            <img src="../../../assets/Pr_Awesome.svg" class="img-fluid imgshadow">
           <!-- <button class="btn btn4" (click)="onClick('Awesome')"></button> -->
        </div>
        <div class="col-4 text-center" (click)="onClick('Thankyou')">
            <img src="../../../assets/Pr_Thankyou.svg" class="img-fluid imgshadow">
            <!-- <button class="btn btn5" (click)="onClick('Thankyou')"></button> -->
        </div>
        <div class="col-4 text-center" (click)="onClick('Achiever')">
            <img src="../../../assets/Pr_Achiever.svg" class="img-fluid imgshadow">
            <!-- <button class="btn btn6" (click)="onClick('Achiever')"></button> -->
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-4 text-center">
           <span class="cust_text" >Awesome</span>
        </div>
        <div class="col-4 text-center">
            <span class="cust_text" >Thank you</span>
        </div>
        <div class="col-4 text-center">
            <span class="cust_text" >Achiever</span>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-4 text-center" (click)="onClick('ProblemSolver')">
            <img src="../../../assets/Pr_ProblemSolver.svg" class="img-fluid imgshadow">
           <!-- <button class="btn btn7" (click)="onClick('ProblemSolver')"></button> -->
        </div>
        <div class="col-4 text-center" (click)="onClick('WorkWithAgility')">
            <img src="../../../assets/Pr_WorkWithAgility.svg" class="img-fluid imgshadow">
            <!-- <button class="btn btn8" (click)="onClick('KindHeart')"></button> -->
        </div>
        <div class="col-4 text-center" (click)="onClick('TeamPlayer')">
            <img src="../../../assets/Pr_TeamPlayer.svg" class="img-fluid imgshadow">
            <!-- <button class="btn btn9" (click)="onClick('TeamPlayer')"></button> -->
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-4 text-center">
           <span class="cust_text" >Problem Solver</span>
        </div>
        <div class="col-4 text-center">
            <span class="cust_text" >Work With Agility</span>
        </div>
        <div class="col-4 text-center">
            <span class="cust_text" >Team Player</span>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-4 text-center" (click)="onClick('Leadership')">
            <img src="../../../assets/Pr_Leadership.svg" class="img-fluid imgshadow">
           <!-- <button class="btn btn10" (click)="onClick('Leadership')"></button> -->
        </div>
        <div class="col-4 text-center" (click)="onClick('Creative')">
            <img src="../../../assets/Pr_Creative.svg" class="img-fluid imgshadow">
            <!-- <button class="btn btn11" (click)="onClick('Creative')"></button> -->
        </div>
        <div class="col-4 text-center" (click)="onClick('Coach')">
            <img src="../../../assets/Pr_Coach.svg" class="img-fluid imgshadow">
            <!-- <button class="btn btn12" (click)="onClick('Coach')"></button> -->
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-4 text-center">
           <span class="cust_text" >Leadership</span>
        </div>
        <div class="col-4 text-center">
            <span class="cust_text" >Creative</span>
        </div>
        <div class="col-4 text-center">
            <span class="cust_text" >Coach</span>
        </div>
    </div>

</div>
