import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { DataService } from 'src/app/service/data.service';
import { InputPara } from '../../models/kpi-models';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-erods-select-reason-approval',
  templateUrl: './erods-select-reason-approval.component.html',
  styleUrls: ['./erods-select-reason-approval.component.css']
})
export class ErodsSelectReasonApprovalComponent implements OnInit {
  deviceType: any;
  device: any;
  userType: string | null = '';
  userId: any;
  list: any;
  selectedReasons: string = '';
  selected = -1;
  input:InputPara=new InputPara();
  FieldName: any;
  FieldValue: any;
  token: any;
  formname:any;
  actiondesc:any;
  constructor(private activatedRoute: ActivatedRoute,  private cs:CommonUtilitiesService,private router: Router, private headerService: HeaderService, private mds: MongoDataService, private ds: DataService,) { }

  ngOnInit(): void {
    this.headerService.setTitle('Select Reason');
    this.headerService.setActiveURl('/erodsSelectReasonApproval');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.token = localStorage.getItem("TokenNo");
    this.userType = localStorage.getItem("UserType");
    this.userId = localStorage.getItem("UserID");
    this.FieldName= localStorage.getItem("OriginalFieldName");
    this.FieldValue= localStorage.getItem("OriginalFieldValue");

    this.formname="erodsSelectReasonApproval";
    this.actiondesc="Erods Select Reason Approval"
    this.cs.GetGeoLocation(this.userId,this.userType,this.formname,this.actiondesc,this.FieldName,this.FieldValue);
    this.onLoad();
  }

  onLoad() {
    this.input.FieldName=this.FieldName;
    this.input.FieldValue=this.FieldValue;
    this.input.TagName='erods';
    this.input.Datatype='0';
    this.input.UserID=this.userId;
    this.mds.GetordermasterForErods(this.input, this.token, this.device, this.deviceType,"Health Status").subscribe(res => {
     this.list = res;
    });
  }

  selected_Reason(e: any) {
    if (e.target.checked) {
      this.selectedReasons = e.target.value;
    } else {
      this.selectedReasons = ''
    }
    console.log("selectedReasons",this.selectedReasons)
  }

  rowClick() {
    this.router.navigate(['/home/erodsVoucherApproval']);
  }
}
