<div>
  <div class="row mt-4">
    <div [class]="billclass" (click)="OnClickRLA('Billed')" >Billed RLA</div>
    <div [class]="totalclass" (click)="OnClickRLA('Total')" >Total RLA</div>
    </div>
  <div class="row mt-2">
    <div class="col-9">
      <i class="fa fa-caret-left" style="color: #757575;" >&nbsp;&nbsp;
      <span class="header_text">{{FieldValue=='0'? 'All India' : FieldValue=='ALLINDIA' ? 'All India': FieldValue.toUpperCase()=='INDIA'? 'All India' :FieldValue |titlecase}}</span></i>
    </div>
    <div class="col-3 text-right">
      <img src="../../../assets/filter.png" class="img-fluid" (click)="openFilter()" /><sup><i
          class="fa fa-circle fa-sm" style="color: #eaab5e;font-size: 5px;"></i></sup>&nbsp;<span
        class="filter_text">Filter</span>
    </div>
  </div>
  <br>
  <div class="row">
    <div class="col-4">
      <!-- <select class="form-control rounded-pill Period_Cust" id="period"  (change)="PeriodChange($event)"   >
        <option value="" disabled>Select Month</option>
        <option *ngFor="let item of periodList" [value]="item">
        {{ item }}
        </option>
    </select> -->
     
    </div>
    <div class="col-8 text-right">
      <i class="fa fa-circle fa-sm" style="color: #eaab5e; font-size: 6px;">&nbsp;&nbsp;<span class="Secondary_text">
          {{myDate | date:'MMMyyyy'}}</span></i>&nbsp;&nbsp;&nbsp;
      <i class="fa fa-circle fa-sm" style="color: #f4d5ae; font-size: 6px;">&nbsp;&nbsp;<span class="Secondary_text"> {{curMonth}}
          {{prevYear}}</span></i>
    </div>
  </div>
  <br>
  <div class="row mt-2" *ngFor="let item of resList" (click)="RowClick(item.saleshierarchy,item.paravalue,item.rdtype)">
    <div class="col-12">
      <div class="row">
        <div class="col-12">
          <span class="Head_text">{{item.pcname + ' ' + item.paravalue}}</span>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-3">
          <div class="row mt-2">
            <div class="col-12">
              <span class="Value_text">{{item.ulbdiff}}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <span class="text_black text_12">Growth</span>
            </div>
          </div>
        </div>
        <div class="col-9">
          <div class="row mt-3">
            <div class="col-12">
              <div class="progress rounded-pill">
                <div class="progress-bar bg_sec" role="progressbar" [style.width.%]="item.cmulb"
                    aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                <div class="progress-bar bg_pri" role="progressbar" [style.width.%]="item.lmulb"
                    aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-6 text-center">
              <span class="val_text">{{item.cmulb}}</span>             
            </div>
            <div class="col-6 text-center">
              <span class="val_text">{{item.lmulb}}</span> 
            </div>
          </div>
        </div>
      </div>
      <hr class="hr_cust">
    </div>    
  </div>  
  <br>
  <br>
  <br>
  <br>
  <br>
</div>
