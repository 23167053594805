import { Component, OnInit } from '@angular/core';
import { FirebasePushNotificationService } from 'src/app/service/firebase-push-notification.service';
import { NotificationService } from 'src/app/service/notification.service';

@Component({
  selector: 'app-notoficationlist',
  templateUrl: './notoficationlist.component.html',
  styleUrls: ['./notoficationlist.component.css']
})
export class NotoficationlistComponent implements OnInit   {
    error: string="";
    messages = [];
    isWaiting = false;
  
    constructor(private fcmService: FirebasePushNotificationService,
      private notificationService: NotificationService) {
        debugger;
      // fcmService.receiveMessage().subscribe(message => this.messages.push());
      this.fcmService.receiveMessage()
    }
    ngOnInit() {

    }
    isRegisteredForPush() :boolean { 
    
       return localStorage.getItem('currentUser') !== null;}
  
    registerForPush() {
    
      this.fcmService.requestPermission()
        .subscribe(
          (token: any)  => {
            debugger;
            localStorage.setItem('currentUser', token);
            this.notificationService.registerForPush('currentUser', token).subscribe();
          },
          error => {
            this.error = error;
          }
        );
    }
    clickme():void {
      alert("clickme");
    }
    removeSubscription() {
      const currentToken = localStorage.getItem('currentUser');
      if (currentToken) {
        this.fcmService.removeToken(currentToken).subscribe(
          ok => localStorage.removeItem('currentUser'),
          error => { this.error = error; localStorage.removeItem('currentUser'); }
        );
        this.notificationService.RemoveSubscription(currentToken, '' ).subscribe()
      }
    }
  
    sendNotification = (title: string, body: string) =>
      this.notificationService.sendNotification({ title, body }).subscribe()
  
    sendNotificationWithDelay(title: string, body: string) {
      const newWindow:any = window.open();
      newWindow.document.write('Wait to see notification');
      setTimeout(() => {
        debugger;
        this.sendNotification(title, body);
      }, 1000);
    }
  }