<div class="container-fluid">
    <div class="row mt-4">
        <div class="col-9">
        </div>
        <div class="col-3 text-right">
            <img src="../../../assets/filter.png" class="img-fluid" (click)="openFilter()" /><sup><i
                class="fa fa-circle fa-sm" style="color: #eaab5e;font-size: 5px;"></i></sup>&nbsp;<span
                class="filter_text">Filter</span>
          </div>
    </div>
    <br>
    <div class="row mt-2">
        <div class="col-6">
            <!-- <select class="form-control rounded-pill Period_Cust" id="Type"  (change)="TypeChange($event)" [(value)]="selectedperiod"  >
                <option value="MTD">MTD</option>
                <option value="FTD">FTD</option>
            </select> -->
            <mat-form-field appearance="outline" style="width: 100px">
              <mat-select id="period" name="period" (selectionChange)="TypeChange($event)"  [(value)]="selectedperiod">
                <mat-option value="MTD">MTD</mat-option>
                <mat-option value="FTD">FTD</mat-option>
              </mat-select>
            </mat-form-field>
        </div>
        <div class="col-6 text-right" >
                    <i class="fa fa-circle fa-sm" style="color: #eaab5e; font-size: 6px;">&nbsp;&nbsp;<span
                            class="Secondary_text">Billed RLA</span></i>&nbsp;&nbsp;&nbsp;
                    <i class="fa fa-circle fa-sm" style="color: #ebe6f1; font-size: 6px;">&nbsp;&nbsp;<span
                            class="Secondary_text">Unbilled RLA</span></i>
        </div>
    </div>
    <br>
    <div  style="overflow: auto;">
        <div class="row mt-2" *ngFor="let item of UnbilledoutletDetails" (click)="RowClick(item.valuetype)">
            <div class="col-12">
                <div class="row">
                    <div class="col-12">
                        <span class="header_text1">{{item.valuetype | titlecase}}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <div class="row mt-2">
                            <div class="col-12">
                                <span class="Value_text">{{item.totrla}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-9" >
                        <div class="row mt-2">
                            <div class="col-12" >
                                <div class="progress rounded-pill">
                                    <div class="progress-bar bg_sec" role="progressbar" [style.width.%]="item.billedrla" aria-valuenow="25"
                                        aria-valuemin="0" aria-valuemax="100"></div>
                                    <div class="progress-bar bg_tgt" role="progressbar" [style.width.%]="item.totrla - item.billedrla" aria-valuenow="25"
                                        aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-6 text-center">
                                <span class="text_14">{{item.billedrla}}</span>
                            </div>
                            <div class="col-6 text-center">
                                <span class="text_14">{{item.totrla - item.billedrla}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr class="hr_cust">
        </div>
    </div>
</div>
<br><br><br><br>