<div>
  <div class="row mt-4">
    <div class="col-12">
      <input class="fullWidth" type="text" name="" id="" placeholder="Search Here" [(ngModel)]="searchTerm">
    </div>
  </div>

  <!-- <div class="row mt-2">
    <div class="col-12 float-end text-end">
      <button class="btn_Header">Pending {{pendingTicket}}</button>
      <button class="btn_Header ms-2">Resolve {{resolvedTicket}}</button>
    </div>
  </div> -->

  <div *ngFor="let item of filteredList; let i = index;" (click)="rowClick(item)">
    <div class="row mt-3">
      <div class="col-12">
        <div class="card" [ngClass]="[item.escalated?.toLowerCase()=='yes' ? 'isL1L2L3background' : '']">
          <!-- <div class="card-body">
            <span class="issueName" *ngIf="!item?.isREShow">{{item.subject}}</span>
            <span class="issueName" *ngIf="item?.isREShow">
              <span style="color: #FF2828; font-size: 16px;">
                RE:
              </span>
              {{item.subject}}
            </span>
            <span class="ms-2 Code">{{item.ticketNo}}</span>

            <span class="mt-1 mb-1 ms-1">
              <img *ngIf="item.ticketOwnerType=='L1'" src="../../../assets/icons/L1Icon.svg" class="mb-1">
              <img *ngIf="item.ticketOwnerType=='L2'" src="../../../assets/icons/L2Icon.svg" class="mb-1">
              <img *ngIf="item.ticketOwnerType=='L3'" src="../../../assets/icons/L3Icon.svg" class="mb-1">
            </span>

              <div class=" mb-1 ms-1 float-end" style="text-align: center;
              width: 16px;
              background-color: #724E8C;
              color: white;">{{item.replyCount}}</div>

            <br>
            <div class="mt-2">
              <span class="mt-1 timing">{{item.statusDateFormatChange}}</span>
              <span class="mt-1 mb-1"><img src="../../../assets/icons/I.svg" class="ms-2 mb-1"></span>
              <span class="ml-2 mt-1 priority">{{item.priority}}</span>
              <span class="float-end mt-1 status">{{item.ticketStatus}}</span>
            </div>
          </div> -->


          <div class="card-body">
            <div class="row">
              <div class="col-12 p-0">
                <span class="issueName" *ngIf="!item?.isREShow">{{item.subject}}</span>
                <span class="issueName" *ngIf="item?.isREShow">
                  <span style="color: #FF2828; font-size: 16px;">
                    RE:
                  </span>
                  {{item.subject}}
                </span>
                <span class="ms-2 Code">{{item.ticketNo}}</span>

                <span class="mt-1 mb-1 ms-2">
                  <img *ngIf="item.ticketOwnerType=='L1'" src="../../../assets/icons/L1Icon.svg" class="mb-1">
                  <img *ngIf="item.ticketOwnerType=='L2'" src="../../../assets/icons/L2Icon.svg" class="mb-1">
                  <img *ngIf="item.ticketOwnerType=='L3'" src="../../../assets/icons/L3Icon.svg" class="mb-1">
                </span>

                <div class=" mb-1 ms-1 float-end" style="text-align: center;
                width: 16px;
                background-color: #724E8C;
                color: white;">{{item.replyCount}}</div>

                <br>
                <div class="mt-2">
                  <span class="mt-1 timing">{{item.ticketTimeInHours}} HRS</span>
                  <span class="mt-1 mb-1"><img src="../../../assets/icons/I.svg" class="ms-2 mb-1"></span>
                  <span class="ml-2 mt-1 priority">{{item.priority}}</span>
                  <span class="float-end mt-1 status">{{item.ticketStatus}}</span>
                </div>
              </div>
            </div>
          </div>



        </div>
      </div>
    </div>
  </div>
  <br>
  <br>
  <br>
  <br>
  <br>  <br>
  <br>
  <br>  <br>
  <br>
  <br>

  <div class="NewTicketFooter">
    <div class="row text-center">
      <div class="col-12">
        <button type="button" class="btn w-100 btn_newTicket" (click)="clickedOnNewTicket()">NEW TICKET</button>
      </div>
    </div>
  </div>
</div>





<!-- <div class="card-body">
  <div class="row">
    <div class="col-12 p-0">
      <span class="issueName" *ngIf="!item?.isREShow">{{item.subject}}</span>
      <span class="issueName" *ngIf="item?.isREShow">
        <span style="color: #FF2828; font-size: 16px;">
          RE:
        </span>
        {{item.subject}}
      </span>
      <span class="ms-2 Code">{{item.ticketNo}}</span>

      <span class="mt-1 mb-1 ms-1">
        <img *ngIf="item.ticketOwnerType=='L1'" src="../../../assets/icons/L1Icon.svg" class="mb-1">
        <img *ngIf="item.ticketOwnerType=='L2'" src="../../../assets/icons/L2Icon.svg" class="mb-1">
        <img *ngIf="item.ticketOwnerType=='L3'" src="../../../assets/icons/L3Icon.svg" class="mb-1">
      </span>

      <div class=" mb-1 ms-1 float-end" style="text-align: center;
      width: 16px;
      background-color: #724E8C;
      color: white;">{{item.replyCount}}</div>
  </div>

  <br>
  <div class="mt-2">
    <span class="mt-1 timing">{{item.statusDateFormatChange}}</span>
    <span class="mt-1 mb-1"><img src="../../../assets/icons/I.svg" class="ms-2 mb-1"></span>
    <span class="ml-2 mt-1 priority">{{item.priority}}</span>
    <span class="float-end mt-1 status">{{item.ticketStatus}}</span>
  </div>
</div> -->