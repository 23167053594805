
<div class="container-fluid">
    <div class="row mt-1" >
        <div class="col-12">
            <!-- <input class="fullWidth" type="text" name="" id="" placeholder="Search RD name or UID"> -->
            <input class="fullWidth" type="text" placeholder=" Search RD Name Or UID" list="dynmicUserIds" (keyup)="getUserIdsFirstWay($event)" id="userIdFirstWay" (change)="SearchText($event)" autocomplete="off">
            <datalist id="dynmicUserIds">
              <option *ngFor="let item of userList1" [value]="item">{{item}}</option>
            </datalist>
          </div>
    </div>
    <div class="row mt-4">
    <div class="col-9"><span class="thheader">RD List</span></div>
    <div class="col-3 text-right"> <span class="thheader2  align-text-bottom">PC Count</span></div>
    </div>
    <div class="row" style="margin-top: 1.25rem;" *ngFor ="let cate of pcFrdata" (click)="RowClick(cate.rdcode)">
        <div class="col-9">
           <span class="rdName">{{cate.rdname|titlecase}}</span> <br><span class="rdCode">{{cate.rdcode }}</span> 
            <br>
             <span class="rdtown" style="margin-top: 1rem;">{{cate.town}}</span>

        </div>
        <div class="col-3 text-right">   <span class="frper">{{cate.totPCCnt}}</span></div>
    </div>
    <hr class="hr_cust">
</div>