import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';
import { HeaderService } from 'src/app/service/header.service';

@Component({
  selector: 'app-praise',
  templateUrl: './praise.component.html',
  styleUrls: ['./praise.component.css']
})
export class PraiseComponent implements OnInit {
  Flag: any;
  formname: any;
  actiondesc: any;
  fieldName: any;
  fieldValue: any;
  UserID: any;
  userType:any;
  constructor(private activatedRoute: ActivatedRoute,private cs:CommonUtilitiesService, private router: Router, private headerService: HeaderService) { }

  ngOnInit(): void {
    this.headerService.setActiveURl('/Praise');
    this.headerService.setTitle('Appreciation');


    this.userType = localStorage.getItem("UserType");
    this.UserID = localStorage.getItem("UserID");
    this.fieldName=localStorage.getItem("FieldName");
    this.fieldValue=localStorage.getItem("FieldValue");
    this.formname = "Praise";
    this.actiondesc = "Praise";
    this.cs.GetGeoLocation(this.UserID, this.userType, this.formname, this.actiondesc, this.fieldName, this.fieldValue);
  }
  onClick(e: any): void{
    localStorage.setItem("PraiseName",e);
    this.router.navigate(['/home/PraiseDetails/']);
  }

}
