<div>
  <div class="row mt-4">
    <div class="col-12">
      <input class="fullWidth" type="text" name="" id="" placeholder="Search PC Name"
        [(ngModel)]="searchTerm">
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-6">
      <span class="select_PC">Select PC</span>
    </div>
    <div class="col-6 text-right">
      <span class="count_Text">{{count}}</span>
      <span class="ml-1 status_Text">PC</span>
    </div>
  </div>
  <div *ngFor="let item of filteredList; let i = index;" (click)="rowClick(item)">
    <div class="row mt-3">
      <div class="col-12">
        <span class="rd_Name">{{item.pcname | titlecase}}</span>
        <span class="ml-1 rd_Code">{{item.pcuid}}</span>
      </div>
    </div>
    <hr>
  </div>
  <br>
  <br>
  <br>
  <br>
  <br>
</div>
