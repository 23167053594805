import { Component, OnInit } from '@angular/core';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-facialrecognition',
  templateUrl: './facialrecognition.component.html',
  styleUrls: ['./facialrecognition.component.css']
})
export class FacialrecognitionComponent implements OnInit {
  token: any;
  formname:any;
  actiondesc:any;
  fieldName: any;
  fieldValue: any;
  userType: string | null = '';
  userId: any;
  list = [1, 2, 3, 4, 5];
  constructor(private cs:CommonUtilitiesService) { }  

  ngOnInit(): void {
    this.userType = localStorage.getItem("UserType");
    this.userId = localStorage.getItem("UserID");
    this.token = localStorage.getItem("TokenNo");
    this.fieldName = localStorage.getItem("FieldName");
    this.fieldValue = localStorage.getItem("FieldValue");
    this.formname="facialrecognition";
    this.actiondesc="Facial Recognition"
    this.cs.GetGeoLocation(this.userId,this.userType,this.formname,this.actiondesc,this.fieldName,this.fieldValue);
  }

  NotificationDetails(ID:string)
  {
    //this.router.navigate(['/home/facialrecdetails/',ID]);
 
  }
}
