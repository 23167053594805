import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';

@Component({
  selector: 'app-urjaa-vp',
  templateUrl: './urjaa-vp.component.html',
  styleUrls: ['./urjaa-vp.component.css']
})
export class UrjaaVPComponent implements OnInit {
  list = [1, 2, 3, 4, 5];
  userId: any;
  userName: any;
  formname:any;
  actiondesc:any;
  fieldName: any;
  fieldValue: any;
  UserType:any;
  constructor(private activatedRoute: ActivatedRoute,private cs:CommonUtilitiesService, private router: Router, private headerService: HeaderService, private mds: MongoDataService,) { }

  ngOnInit(): void {
    this.headerService.setActiveURl('/UrjaaVP');
    this.headerService.setTitle('Village Productivity');
    this.userName = localStorage.getItem("UserID");
    this.userId = localStorage.getItem("UserID");


    this.UserType = localStorage.getItem("UserType");
    this.fieldName = localStorage.getItem("FieldName");
    this.fieldValue = localStorage.getItem("FieldValue");
    this.formname = "UrjaaVP"
    this.actiondesc ="Village Productivity";
    this.cs.GetGeoLocation(this.userId, this.UserType, this.formname, this.actiondesc, this.fieldName, this.fieldValue);
  }

}
