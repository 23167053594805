import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { InputPara, resPCAttendance } from 'src/app/models/kpi-models';
import { EncryptionService } from '../../service/encryption.service';
import { DataService } from '../../service/data.service';
import { Enclass, ResultClass } from '../../models/encryption-class';
import { MatDialog } from '@angular/material/dialog';
import { FilterComponent } from '../filter/filter.component';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';
import { NotifyMeService } from 'src/app/service/notify-me.service';
@Component({
  selector: 'app-tsi-attendance',
  templateUrl: './tsi-attendance.component.html',
  styleUrls: ['./tsi-attendance.component.css']
})
export class TsiAttendanceComponent implements OnInit {
  FieldName: any;
  FieldValue: any;
  DataType: any;
  UserID: any;
  periodList: any[] = [];
  selectedperiod: any;
  UserType: any;
  currentDate = new Date();
  pcData: any;
  heading: string = "";
  CurrentDate: any;
  ParaName: any;
  ParaValue: any;
  tsiAttendance: any;
  isPC: boolean = false;
  isRD: boolean = false;
  device: any;
  deviceType:any;
  input:InputPara=new InputPara();
  token: any;
  lat:any;
  lng:any;

  constructor(private cs: CommonUtilitiesService, private activatedRoute: ActivatedRoute, private fb: FormBuilder, private es: EncryptionService, private router: Router,
    private headerService: HeaderService, private mds: MongoDataService,
     private ds: DataService, private dialog: MatDialog,private notifyMeService: NotifyMeService,) { }

  ngOnInit(): void {
    this.headerService.setTitle('TSI Attendance');
    this.headerService.setActiveURl('/tsiattendance');
    this.UserType = localStorage.getItem("UserType");
    this.UserID = localStorage.getItem("UserID");
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.token = localStorage.getItem("TokenNo");
    this.ParaName = "tsi";
    this.ParaValue = "0";
    this.CurrentDate = new Date();
    this.activatedRoute.paramMap.forEach(params => {
      this.FieldName = params.get('FieldName');
      this.FieldValue =  params.get('FieldValue');
      this.UserID =  params.get('UserID');
      this.ParaName =  params.get('ParaName');
      if(this.ParaName=="0"){
        this.ParaName = "tsi";
      }
      this.selectedperiod = params.get('selectedperiod');
      debugger
      this.GetGeoLocation();
    this.loadData();
  });
  }

  GetGeoLocation() {
    debugger
    if (navigator) {
      navigator.geolocation.getCurrentPosition(pos => {
        this.lat = pos.coords.latitude;
        console.log("  this.lat", this.lat)
        this.lng = pos.coords.longitude;
        this.SetapplicationLogData();
      }, this.handleLocationError, { timeout: 30000 });
    }
    else {
      this.notifyMeService.showError('Location Could not be captured', 'Mera APP');
    }
  }

  handleLocationError(error: { code: any; }) {
    switch (error.code) {
      case 3:
        this.notifyMeService.showError('Timeout in Position capture', 'Mera APP V2');
        break;
      case 2:
        this.notifyMeService.showError('Device is not capable of Position capture', 'Mera APP V2');
        break;
      case 1:
        this.notifyMeService.showError('You denied for Position capture', 'Mera APP V2');
        break;
    }
  }

  SetapplicationLogData() {
    debugger
    let request = {
      "userid": this.UserID,
      "usertype": this.UserType,
      "formname": "tsiattendance",
      "actiondesc": "tsi attendance" + " " + this.FieldName,
      "fieldname": this.FieldName,
      "fieldvalue": this.FieldValue,
      "latitude": this.lat,
      "longitude": this.lng
    }
    this.ds.SetapplicationLogData(request).subscribe(res => {
      console.log("res", res)
    })
  }

  loadData() {
    this.GetTSIAttendaceData();

  }
  GetTSIAttendaceData() {
    this.tsiAttendance = [];
    this.input.FieldName=this.FieldName;
    this.input.FieldValue=this.FieldValue;
    this.input.TagName=this.ParaName;
    this.input.Datatype=this.ParaValue;
    this.input.UserID=this.UserID;

    this.mds.getPCAttendancedata(this.input,this.token,this.device,this.deviceType,'TSI Attendance').subscribe(res => {

      console.log("GetTSIAttendaceData", res);

      this.tsiAttendance = res as resPCAttendance;
      this.periodList = [...new Set(res.map((bill: { period: any; }) => bill.period))].sort();
      if(this.selectedperiod=="0"){
        this.selectedperiod = this.periodList[0];
      }

      if (this.tsiAttendance[0].fieldName == "pc") {
        this.isPC = true;
      } else {
        this.isPC = false;
      }
      if (this.tsiAttendance[0].fieldName == "rd") {
        this.isRD = true;
      } else {
        this.isRD = false;
      }
      if (this.FieldName == "ALLINDIA") { this.heading = "Branch" }
      if (this.FieldName == "branch") { this.heading = "Region" }
      if (this.FieldName == "region") { this.heading = "ASMArea" }
      if (this.FieldName == "asmarea") { this.heading = "SOTerritory" }
      if (this.FieldName == "soterritory") { this.heading = "RD" }
      if (this.FieldName == "rd") { this.heading = "TSI" }
    }
    );
  }
filterItemsOfType(type: any) {
    return type != null ? type.filter((x: { [x: string]: any; }) => (x['period'] == this.selectedperiod)) : null;
  }
  RowClick(FName: any, FVal: any) {
     if(FName!='pc'){
      this.router.navigate(['/home/tsiattendance/',FName,FVal,"tsi",this.UserID,this.selectedperiod]);
    }
  }

  openFilter() {
    if (this.dialog.openDialogs.length == 0) {
      const dialogRef = this.dialog.open(FilterComponent, {
        closeOnNavigation: true,
        data: {
          "FieldName": localStorage.getItem("OriginalFieldName"),
          "FieldValue": localStorage.getItem("OriginalFieldValue"),
          "UserID": this.UserID,
          "MenuUpTo": "5",
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.FieldName = result.FieldName;
          this.FieldValue = result.FieldValue;
          this.loadData();
        }
      });
    }
  }
  PeriodChange(e: any): void {
    this.selectedperiod = e.source._value;
  }
}
