import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { DatePipe } from '@angular/common';
import { ApproveAtoBTransfer } from 'src/app/models/kpi-models';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-a2-b',
  templateUrl: './a2-b.component.html',
  styleUrls: ['./a2-b.component.css']
})
export class A2BComponent implements OnInit {
  deviceType: any;
  device: any;
  isLongPress: boolean = false;
  isSelected: boolean = false;
  list: any;
  selected = -1;
  isSelectedObj: any;
  userType: string | null = '';
  userId: any;
  public approveAtoBTransfer: ApproveAtoBTransfer = new ApproveAtoBTransfer();
  token: any;
  formname: any;
  actiondesc: any;
  FieldName: any;
  FieldValue: any;
  private _searchText = '';
  filteredList: any;
  constructor(private activatedRoute: ActivatedRoute, private router: Router, private headerService: HeaderService, private datePipe: DatePipe, private mds: MongoDataService, private notifyMeService: NotifyMeService, private cs: CommonUtilitiesService) { }

  ngOnInit(): void {
    this.headerService.setTitle('A to B Transfer Approval');
    this.headerService.setActiveURl('/a2b');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.userType = localStorage.getItem("UserType");
    this.userId = localStorage.getItem("UserID");
    this.token = localStorage.getItem("TokenNo");
    this.FieldName = localStorage.getItem("FieldName");
    this.FieldValue = localStorage.getItem("FieldValue");
    var myDate = new Date();
    var previousMonth = new Date(myDate);
    previousMonth.setMonth(myDate.getMonth() - 1);
    let previousMonths = this.datePipe.transform(previousMonth, 'MMM');
    this.formname = "a2b";
    this.actiondesc = "a2b"
    this.cs.GetGeoLocation(this.userId, this.userType, this.formname, this.actiondesc, this.FieldName, this.FieldValue);
    this.onLoad();
  }

  onLoad() {
    debugger
    if (this.userType?.toLowerCase() == "so") {
      let request = {
        "UserID": this.userId
      }
      this.mds.ATS_GetAtoBApprovalDataForSO(request, this.token, this.device, this.deviceType, 'A to B Transfer Approval').subscribe(res => {
        debugger
        this.list = res;
        console.log("list", this.list)
        this.filteredList = this.list;
      });
    } else {
      this.mds.getAtoBApprovalData(this.userId, this.token, this.device, this.deviceType, 'A to B Transfer Approval').subscribe(res => {
        debugger
        this.list = res;
        console.log("list", this.list)
        this.filteredList = this.list;
      });
    }
  }

  onLongPress() {
    this.isLongPress = true;
  }

  clickOnSelect(e: any, id: any) {
    if (e.target.checked) {
      this.isSelected = e.target.value;
      this.isSelectedObj = this.list[id];
    } else {
      this.isSelected = false;
      this.isSelectedObj = null;
    }
  }

  rowClick(data: any) {
    localStorage.setItem("SelectedAtoB", JSON.stringify(data))
    this.router.navigate(['/home/a2bData']);
  }

  get searchTerm(): any {
    return this._searchText;
  }
  set searchTerm(value: any) {
    this._searchText = value;
    this.filteredList = this.filteredListMethod(value);
  }

  filteredListMethod(value: any): any {
    return this.list.filter((ele: any) =>
      ele.fromOutletCode.indexOf(value) !== -1);
  }
}
