<div class="row" style="margin-top: 2rem;">
  <div class="col-12">
    <span class="text_Class">Claim No.</span>
  </div>
  <div class="col-12">
    <span class="value_Class">{{claimDetailsEdit.claimNo}}</span>
  </div>
</div>
<hr>
<div class="row">
  <div class="col-12">
    <span class="text_Class">Claim Type</span>
  </div>
  <div class="col-12">
    <span class="value_Class">{{claimDetailsEdit.claimType}}</span>
  </div>
</div>
<hr>
<div class="row">
  <div class="col-10">
    <span class="text_Class">Amount ( <img class="img-fluid mr-1" src="assets/claim_small_rupee.svg" />)</span>
  </div>
  <div class="col-2 text-right" *ngIf="userType?.toUpperCase() != 'SO'">
    <img class="img-fluid" src="assets/edit_profile.png" (click)="onClickEdit(openModalForClaimAmount)"/>
  </div>
  <div class="col-2 text-right" *ngIf="userType?.toUpperCase() == 'SO'"></div>
  <div class="col-12">
    <span class="value_Class">{{claimDetailsEdit.claimAmt}}</span>
  </div>
</div>
<hr>
<div class="row">
  <div class="col-12">
    <span class="text_Class">Scheme</span>
  </div>
  <div class="col-12">
    <span class="value_Class">{{claimDetailsEdit.claimDetails}}</span>
  </div>
</div>
<hr>
<div class="row">
  <div class="col-12">
    <span class="text_Class">YTD Sales ( <img class="img-fluid mr-1" src="assets/claim_small_rupee.svg" />)</span>
  </div>
  <div class="col-12">
    <span class="value_Class">{{claimDetailsTwo.l12MSecondarySales}}</span>
  </div>
</div>
<hr>
<div class="row">
  <div class="col-12">
    <span class="text_Class">YTD Claim ( <img class="img-fluid mr-1" src="assets/claim_small_rupee.svg" />)</span>
  </div>
  <div class="col-12">
    <span class="value_Class">{{claimDetailsTwo.ytdSecClaim}}</span>
  </div>
</div>
<hr>
<div class="row">
  <div class="col-12">
    <span class="text_Class">MTD Sec Sale ( <img class="img-fluid mr-1" src="assets/claim_small_rupee.svg" />)</span>
  </div>
  <div class="col-12">
    <span class="value_Class">{{claimDetailsTwo.secondarysalesMTD}}</span>
  </div>
</div>
<hr>
<div class="row">
  <div class="col-12">
    <span class="text_Class">% Sec Sales ( <img class="img-fluid mr-1" src="assets/claim_small_rupee.svg" />)</span>
  </div>
  <div class="col-12">
    <span class="value_Class">{{claimDetailsTwo.perofSecSales}}</span>
  </div>
</div>
<hr>
<br>
<br>
<br>
<br>
<br>
<div class="footer" *ngIf="userType?.toUpperCase() == 'SO'">
  <div class="row">
    <div class="col-12" (click)="approve()">
      <button class="btn btn_APPROVE">APPROVE</button>
    </div>
  </div>
</div>
<div class="footer" *ngIf="userType?.toUpperCase() != 'SO'">
  <div class="row">
    <div class="col-6 pr-0" (click)="reject()">
      <button class="btn btn_REJECT">REJECT</button>
    </div>
    <div class="col-6 pl-0" (click)="approve()">
      <button class="btn btn_APPROVE">APPROVE</button>
    </div>
  </div>
</div>

<ng-template #openModalThankYou let-modal>
  <div class="modal-header">
    <h4 class="modal-title text_color_head text_20" id="modal-basic-title">
      <img class="img-fluid" src="assets/thanks_icon.png" />
      <span class="ml-3">Thank You</span>
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modalClose()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <span class="model_PopUp_Text"><img class="img-fluid mr-1" src="assets/claim_rupee.svg" />{{amount}} Claim Approved
          Successfully</span>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <span class="model_PopUp_Text">Against</span>
      </div>
    </div>
    <div class="row mt-4 mb-2">
      <div class="col-12">
        <span class="pc_Name_PopUp">{{claimDetailsTwo.rdname | titlecase}}</span>
        <span class="ml-2 pc_Code_PopUp">{{claimDetailsTwo.rdcode}}</span>
      </div>
    </div>
  </div>
  <div class="modal-footer" (click)="okay()">
    OKAY
  </div>
</ng-template>

<ng-template #openModalForClaimAmount let-modal>
  <div class="modal-header">
    <!-- <h4 class="modal-title text_color_head text_20" id="modal-basic-title">
      <img class="img-fluid" src="assets/login_notification.png" />
      <span class="ml-3">Notification</span>
    </h4> -->
    <button type="button" class="close" aria-label="Close" (click)="modalCloseForEdit()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
  <form [formGroup]="editAmount" autocomplete="off">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="amount" class="text_Head">Enter your Amount</label>
            <input id="amount" class="form-control" type="text" name="amount" formControlName="amount">
          </div>
        </div>
      </div>
      </form>
    </div>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6" (click)="modalCloseForEdit()">
        <button class="btn btn_Cancel">Cancel</button>
      </div>
      <div class="col-6" (click)="save()">
        <button class="btn btn_Save" [disabled]="!editAmount.valid">Save</button>
      </div>
    </div>
  </div>
</ng-template>
