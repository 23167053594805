import { Component } from '@angular/core';
import {  ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-select-multiselect-dropdown',
  templateUrl: './select-multiselect-dropdown.component.html',
  styleUrls: ['./select-multiselect-dropdown.component.css']
})
export class SelectMultiselectDropdownComponent implements OnInit {

  @Input() dataSet: any = []
  dataSetCopy: any = []
  parentSelector: boolean = false;
  @ViewChild('parentSelectorID') accessId: ElementRef | undefined;
  rotateIcon: any;
  selectedValue:any='';
  selectedList:any=[]
  @Input() placeholder:any='Select Watchlist';
  listVisible:boolean=false
  @Output() returnSelectedList=new EventEmitter<any[]>()
  @Input() selectAreaId:any
  @Input() disabled:boolean=false;
  isFirstTimeLoad:boolean=false;
  updatedDataSet:any = [];
  
  constructor() {
  
  }
  
  ngOnInit(): void {
  
  }
  ngOnChanges(){
    debugger
    this.updatedDataSet = this.dataSet;
    console.log("updatedDataSet",this.updatedDataSet)
    if(this.updatedDataSet?.length > 0){
      
      if(!this.isFirstTimeLoad){
        // this.updatedDataSet.map((ds:{isChecked:boolean}) => {
        //   ds.isChecked=true;
        // });
  
        this.isFirstTimeLoad=true
        // this.listSelectedEvent()
        
      }
      this.dataSetCopy=JSON.parse(JSON.stringify(this.updatedDataSet))
      this.setCommaSepratedValue(this.updatedDataSet.filter((f:any) => f.isChecked))
      // if(this.selectAreaId != undefined){
      // this.onChangeSelect(this.selectAreaId);
      // }
    }
  }
  
  onChangeSelect(event: any) {
    debugger
    
    if(this.selectAreaId != undefined && event.isTrusted == undefined){
  
      this.dataSet = this.dataSet.map((d: any) => {
        if (d.code == this.selectAreaId) {  // AreaCode
          d.isChecked = true;
        }
        return d;
      });
  
      this.updatedDataSet = this.updatedDataSet.map((d: any) => {
        if (d.code == this.selectAreaId) {  // AreaCode
          d.isChecked = true;
        }
        return d;
      });
  
      setTimeout(() => {
        this.listSelectedEvent()  
      }, 10);
      
    }else{
      this.selectAreaId=undefined
      const id = event.target.value;
      const isChecked = event.target.checked;
  
      this.dataSet = this.dataSet.map((d: any) => {
        if (d.id == id) {
          d.isChecked = isChecked;
          return d;
        }
  
        if (id == -1) {
          d.isChecked = this.parentSelector;
          return d;
        }
        return d;
      });
  
      this.updatedDataSet = this.updatedDataSet.map((d: any) => {
        if (d.id == id) {
          d.isChecked = isChecked;
          return d;
        }
  
        if (id == -1) {
          d.isChecked = this.parentSelector;
          return d;
        }
        return d;
      });
  
      this.setIndeterminate()
    }
    
  
    
  }
  
  setIndeterminate(){
    let parentCheckBox: any
    parentCheckBox = this.accessId
    let isAllChecked: boolean = false
    let isAllUnchecked: boolean = false
    
    isAllChecked = this.updatedDataSet?.every((item: any) => item.isChecked == true)
    isAllUnchecked = this.updatedDataSet?.every((item: any) => item.isChecked == false)
  
    // if (isAllChecked == false && isAllUnchecked == false) {
    //   parentCheckBox.nativeElement.indeterminate = true
    // } else {
    //   parentCheckBox.nativeElement.indeterminate = false
    // }
    if (isAllChecked) {
      this.parentSelector = true;
    } else {
      this.parentSelector = false;
    }
  }
  
  showPanel() {
    if(!this.disabled){
      this.rotateIcon = true;
      this.listVisible=true;
      setTimeout(() => {
        this.setIndeterminate()  
      }, 10);
    }
  }
  
  hidePanel() {
    this.listVisible = false;
    this.rotateIcon = false;
    (<HTMLInputElement>document.getElementsByClassName("filter-search-input")[0]).value=""
    this.updatedDataSet=JSON.parse(JSON.stringify(this.dataSetCopy))
  }
  
  listSelectedEvent(){
    debugger
    console.log("this.dataSet",this.dataSet)
    this.dataSetCopy=JSON.parse(JSON.stringify(this.dataSet))
  
    this.selectedList=this.dataSetCopy?.filter((f:any)=>f.isChecked==true)
    this.setCommaSepratedValue(this.selectedList)
    
    this.returnSelectedList.emit(this.selectedList);
    this.hidePanel()
  }
  
  setCommaSepratedValue(selectedList: any[]) {
    debugger
    let commaSepratedValue = "";
    if (selectedList.length > 0) {
      for (let f of selectedList) {
        if (commaSepratedValue.length == 0)
        commaSepratedValue = f.userName;
        else
        commaSepratedValue += ', ' + f.userName;
      }
    }
  
    // this.selectedValue= commaSepratedValue;
    (<HTMLInputElement>document.getElementsByClassName("filter-search-input")[0]).value=""

    console.log("selectedValue",this.selectedValue)
  }
  
  selectValue(selectedValue:any){
    debugger
    let selectedValueList = []
    if(selectedValue.replace(",","").replace(" ","")){
      selectedValueList = selectedValue.replace(" ","").split(",");
    }
    this.updatedDataSet = [];  
    if(selectedValueList.length>0){
      selectedValueList.forEach((value:any)=>{
        if(value.length >=1 ){
          this.updatedDataSet = Array.from(
            new Set(this.updatedDataSet.concat(
              this.dataSet.filter((f:any)=>{
                // f.isChecked = false;
                return f.userName.toLowerCase().includes(value.toLowerCase())
              }
              )))
            )
        }
      })
    }
    else{
      // this.dataSet.forEach((f:any)=>{
      //   f.isChecked = false;
      // })
      this.updatedDataSet = this.dataSet;
    }
  
    this.parentSelector = true;
    this.updatedDataSet.forEach((f:any)=>{
      if(f.isChecked == false){
        this.parentSelector = false;
      }
    })
  }
}
