import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js'; //imports
// import { EncryptionClass } from '../models/encryption-class';

@Injectable({
  providedIn: 'root',
})
export class EncryptionService {

  WordKey: string ="0123456789abcdef";
  Word: string ='';

  constructor() { }

  encrypt(WordKey:any): string {
    debugger
    var key = CryptoJS.enc.Utf8.parse(WordKey);
    var iv = CryptoJS.enc.Utf8.parse(WordKey);
    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(this.Word), key,
      {
        keySize: 128/8,
        blockSize: 128,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });

    return encrypted.toString();
  }

  //The get method is use for decrypt the value.
  decrypt(WordKey:any):string{
    var key = CryptoJS.enc.Utf8.parse(WordKey);
    var iv = CryptoJS.enc.Utf8.parse(WordKey);
    var decrypted = CryptoJS.AES.decrypt(this.Word, key, {
      keySize: 128/8,
      blockSize: 128,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  // decryptInfo(info: any="") {
  //   var key = CryptoJS.enc.Utf8.parse(this.WordKey);
  //   var iv = CryptoJS.enc.Utf8.parse(this.WordKey);
  //   var decryptedInfo = CryptoJS.AES.decrypt(info, key, {
  //     keySize: 128 / 8,
  //     blockSize: 128,
  //     iv: iv,
  //     mode: CryptoJS.mode.CBC,
  //     padding: CryptoJS.pad.Pkcs7
  //   });

  //   return decryptedInfo.toString(CryptoJS.enc.Utf8);
  // }

}
