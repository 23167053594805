<div class="row">
  <div class="col-12 mt-4 text-right">
    <img src="../../../assets/download.png" class="img-fluid" (click)="captureScreen()" />
  </div>
  </div>

<div id="content" class="bg_Image">
  <div class="row">
    <div class="col-12 text-center" style="margin-top: 13.5rem;">
      <span class="heading_Text">THIS CERTIFICATE IS PROUDLY PRESENTED TO</span>
    </div>
    <div class="col-12 text-center mt-1">
      <span class="user_Name">Michael Williams</span>
    </div>
    <div class="col-12 text-center">
      <span class="success_Text">For successfully completed the online course of Mondelez Program II</span>
    </div>
    <div class="col-12 text-center">
      <span class="date">DATE: 25 JUNE 2021</span>
    </div>
  </div>
</div>
