import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { DataService } from 'src/app/service/data.service';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-sub-rd-approval-select-old-rd',
  templateUrl: './sub-rd-approval-select-old-rd.component.html',
  styleUrls: ['./sub-rd-approval-select-old-rd.component.css']
})
export class SubRdApprovalSelectOldRdComponent implements OnInit {
  deviceType: any;
  device: any;
  userType: string | null = '';
  userId: any;
  list: any;
  selectedReasons: any;
  selected = -1;
  token: any;

  formname:any;
  actiondesc:any;
  fieldName: any;
  fieldValue: any;

  constructor(private activatedRoute: ActivatedRoute,private cs:CommonUtilitiesService, private router: Router, private headerService: HeaderService, private mds: MongoDataService, private ds: DataService,) { }

  ngOnInit(): void {
    this.headerService.setTitle('Select Old  RD');
    this.headerService.setActiveURl('/subRdApprovalSelectOldRd');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.userType = localStorage.getItem("UserType");
    this.userId = localStorage.getItem("UserID");
    this.token = localStorage.getItem("TokenNo");

    this.fieldName = localStorage.getItem("FieldName");
    this.fieldValue = localStorage.getItem("FieldValue");
    this.formname = "subRdApprovalSelectOldRd"
    this.actiondesc = "Sub Rd Approval Select Old Rd";
    this.cs.GetGeoLocation(this.userId, this.userType, this.formname, this.actiondesc, this.fieldName, this.fieldValue);
    this.onLoad();
  }

  onLoad() {
    this.mds.getReasons(this.token,this.device, this.deviceType , 'Select  Old  RD').subscribe(res => {
      this.list = res.filter((singleItem: { [x: string]: string; }) => singleItem['reasonType'].startsWith("Pullout"));
      console.log("list",this.list)
    });
  }

  selected_Reason(e: any) {
    if (e.target.checked) {
      this.selectedReasons = e.target.value;
    }
  }

  rowClick() {
    this.router.navigate(['/home/subRdApprovalSelectMarket']);
  }
}
