<nz-input-group [nzSuffix]="suffixIconSearch" class="form-control rounded select-width" (click)="showPanel()">
  <input #input type="text" nz-input readonly [placeholder]="placeholder" 
    class="filter-search-input dms-no-border" (input)="selectValue(input.value)"
    style="text-overflow: ellipsis;color:#000!important;cursor:pointer; margin-top: -4px;width: 100%;border: none;"
    [readOnly]="disabled" />
</nz-input-group>
<!-- [value]="selectedValue" -->
<ng-template #suffixIconSearch>
  <span nz-icon nzType="caret-down" [ngClass]="{'rotate': (rotateIcon == true) }">
  </span>
</ng-template>

<div *ngIf="listVisible" class="select-option-width position bg-white rounded p-1 border">

  <div class="setMaxHeight">
    <div class="row mx-auto " *ngFor="let item of updatedDataSet">
      <div class="col-12 mt-2">
        <span class="ms-3">
          <input type="checkbox" class="form-check-input" [value]="item.id" [checked]="item.isChecked"
            (change)="onChangeSelect($event)" />
        </span>

        <span class="ms-3">
          {{item.userName}}
        </span>
      </div>
    </div>
  </div>


  <div class="row mt-2">
    <div class="col-6 pr-0">
      <button type="button" class="btn_Cancel float-end" nzShape="round" (click)="hidePanel()">Close</button>
    </div>
    <div class="col-6 pl-0">
      <button type="button" class="btn_APPROVE float-end" nzShape="round" (click)="listSelectedEvent()">Apply</button>
    </div>


  </div>
</div>