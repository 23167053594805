import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/service/data.service';

@Component({
  selector: 'app-mera-app-home',
  templateUrl: './mera-app-home.component.html',
  styleUrls: ['./mera-app-home.component.css']
})
export class MeraAppHomeComponent implements OnInit {
UserID:any;
  constructor(private router: Router, private ds: DataService,) { }

  ngOnInit() {
    this.UserID=localStorage.getItem("UserID");
    
    if(this.UserID==null || this.UserID=="")
    {  this.router.navigate(['/login']);}
    else {
      this.CheckSessionOfUser();
    }
  }
  CheckSessionOfUser() {
     this.ds.Users_GetSessionID(this.UserID).subscribe((data: any) => {
     
      let d = localStorage.getItem("SessionID");
      let d2 = data[0].sessionId;
      if (localStorage.getItem("SessionID") != data[0].sessionId) {
        localStorage.clear();
        sessionStorage.clear()
        this.router.navigate(['/login']);
      }
    });
  }


}
