<div style="background-color: #f8f6fb;">
<div class="container-fluid">
    <br>
    <div class="row mt-3">
        <div class="col-8">
          <span class="header_text">{{rdName + '(' + FieldValue + ')' |titlecase}}</span>
        </div>
      </div>
      
      <div class="row mt-4">
        <div class="col-8">
          <span class="header_text">Select Dates</span>
        </div>
      </div>
      <br>
      <div class="row">
        <div class="col-6">
            <mat-form-field>
                <input matInput [matDatepicker]="FromDate" placeholder="select date" (dateInput)="SelectFromDate('input', $event)">
                <mat-datepicker-toggle matSuffix [for]="FromDate"></mat-datepicker-toggle>
                <mat-datepicker #FromDate></mat-datepicker>
              </mat-form-field>
       </div>
        <div class="col-6">
            <mat-form-field>
                <input matInput [matDatepicker]="ToDate" placeholder="select date" (dateInput)="SelectToDate('input', $event)">
                <mat-datepicker-toggle matSuffix [for]="ToDate"></mat-datepicker-toggle>
                <mat-datepicker #ToDate></mat-datepicker>
              </mat-form-field>
        </div>
        </div>
        <div class="row p-2 mt-1 mb-3">
            <div class="col-6">&nbsp;</div>
            <div class="col-6" (click)="loadData()">
                <img src="../../../assets/plicon.svg">&nbsp;
                <span style="color: #724e8c;">Generate Data</span>
            </div>
</div>

</div>
</div>
<div class="container-fluid">
    <div class="row mt-4">
        <div class="col-8">
          <span class="header_text">Invoices List</span>
        </div>
      </div>
      <br>
    <div class="row mt-2">
        <div class="col-4 text-center">
            <span class="subhead_text">Bill No</span> 
        </div>
        <div class="col-4 text-center">
            <span class="subhead_text">Date</span> 
        </div>
        <div class="col-4 text-center">
            <span class="subhead_text">Amt(&#8377;)</span> 
        </div>
     
    </div>
    <br>
    <div style="overflow: auto;">
        <div class="row mt-2" *ngFor="let item of listdata ">
            <div class="col-4">
                <span class="val_text" style="color: #724e8c;">{{item.billNo}}</span>
            </div>
            <div class="col-4">
                <span class="val_text">{{item.billDate}}</span>
            </div>
            <div class="col-4">
                <span class="val_text">{{item.amt}}</span>
            </div>
<div class="Divider"></div>
        </div>
    </div>

</div>

<br><br><br><br>