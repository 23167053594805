import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { DatePipe } from '@angular/common';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-outletdetail-complaint',
  templateUrl: './outletdetail-complaint.component.html',
  styleUrls: ['./outletdetail-complaint.component.css']
})
export class OutletdetailComplaintComponent implements OnInit {

  deviceType: any;
  device: any;
  searchText = '';
  isLongPress: boolean = false;
  isSelected: boolean = false;
  outLetSelected: any;
  formname: any;
  actiondesc: any;
  UserType: any;
  userId: any;
  fieldName: any;
  fieldValue: any;

  constructor(private activatedRoute: ActivatedRoute,private cs:CommonUtilitiesService, private router: Router, private headerService: HeaderService, private datePipe: DatePipe, private mds: MongoDataService,) { }

  ngOnInit(): void {
    this.headerService.setTitle('Outlet Detail');
    this.headerService.setActiveURl('/outletDetailcomplaint');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.outLetSelected = JSON.parse(localStorage.getItem("outLetSelected") || '{}');
    this.userId = localStorage.getItem("UserID");
    this.fieldName = localStorage.getItem("FieldName");
    this.fieldValue = localStorage.getItem("FieldValue");
    this.UserType = localStorage.getItem('UserType');
    this.formname = "outletdetailcomplaint";
    this.actiondesc = "Outlet Detail Complaint";
    this.cs.GetGeoLocation(this.userId, this.UserType, this.formname, this.actiondesc, this.fieldName, this.fieldValue);
  }

  onLongPress() {
    this.isLongPress = true;
  }

  clickOnSelect() {
    this.isSelected = true;
  }

  rowClick() {
    this.router.navigate(['/home/addcomplaint']);
  }

}
