import { Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/service/data.service';
import { HeaderService } from 'src/app/service/header.service';
import { QRCodeData } from 'src/app/models/kpi-models';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';


@Component({
  selector: 'app-qrcode-history',
  templateUrl: './qrcode-history.component.html',
  styleUrls: ['./qrcode-history.component.css']
})
export class QRCodeHistoryComponent implements OnInit {
  public GoBtnClicked = "false";
  deviceType: any;
  device: any;
  userType: string | null = '';
  userId: any;
  list: any;
  filteredList: any;
  fieldName: any;
  fieldValue: any;
  token: any;
  public QRCodeHistory: any = [];
  public qrCodeData: QRCodeData = new QRCodeData();
  hasPermission: boolean = false;
  outletSelectedMV: any;
  isVisicooler: any;
  outLetDetails: any;

  scannerEnabled: boolean = true;
  currentDevice: MediaDeviceInfo | undefined;
  guestExist: boolean = false;
  qrResult: any;
  output = "";


  public FirStatus = "VisiFound";
  public slectedIndex = 1;

  lat: number = 0;
  lng: number = 0;
  firList: any;

  public config: Object = {
    isAuto: true,
    text: { font: '25px serif' }, // Hiden { font: '0px' },
    frame: { lineWidth: 8 },
    medias: {
      audio: false,
      video: {
        facingMode: 'environment', // To require the rear camera https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia
        width: { ideal: 1280 },
        height: { ideal: 720 }
      }
    }
  };

  public coolerPhoto: any = "";
  public outletPhoto: any = "";
  searchText = '';
  isLongPress: boolean = false;
  isSelected: boolean = false;
  isValid: boolean = false;
  outletSelectedA: any;
  imgURL: any;
  photourlstr: string = '';
  public progress: number = 0;
  public message: string = '';
  public mobileNo: string = "";
  public photoUploaded: any
  public submitted = false;

  public photos: any = {
    CoolerPhoto: 0,
    OutletPhoto: 0,
  }
  file: any;
  imgResultBeforeCompress: string = '';
  imgResultAfterCompress: string = '';
  localUrl: any;
  localCompressedURl: any;
  sizeOfOriginalImage: number = 0;
  sizeOFCompressedImage: number = 0;
  TokenNo: any;
  ClientID: any;
  CoolerPhoto: any = "";
  OutletPhoto: any = "";
  qrCodeResp: any;
  formname: any;
  actiondesc: any;

  constructor(private headerService: HeaderService, private toastr: ToastrService, private router: Router, private ds: DataService,
    private notifyService: NotifyMeService, private modalService: NgbModal, private cs: CommonUtilitiesService,) { }

  ngOnInit(): void {
    this.headerService.setTitle('QR Code History');
    this.headerService.setActiveURl('/QRCodeHistory');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.token = localStorage.getItem("TokenNo");
    this.userType = localStorage.getItem("UserType");
    this.userId = localStorage.getItem("UserID");
    this.fieldName = localStorage.getItem("FieldName");
    this.fieldValue = localStorage.getItem("FieldValue");

    this.outletSelectedMV = JSON.parse(localStorage.getItem("outletSelectedMV") || '{}');
    console.log("outletSelectedMV", this.outletSelectedMV);
    this.outLetDetails = JSON.parse(localStorage.getItem("outLetDetails") || '{}');
    this.isVisicooler = JSON.parse(localStorage.getItem("isVisicooler") || '{}');
    console.log("isVisicooler", this.isVisicooler);

    this.formname = "QRCodeHistory";
    this.actiondesc = "QrCode History";
    this.cs.GetGeoLocation(this.userId, this.userType, this.formname, this.actiondesc, this.fieldName, this.fieldValue);
    this.startMeeting();
    this.GetGeoLocation();
  }


  startMeeting(): void {
    this.qrCodeData.Latitude = 0;
    this.qrCodeData.Longitude = 0;
    if (navigator) {
      navigator.geolocation.getCurrentPosition(pos => {
        this.qrCodeData.Latitude = pos.coords.longitude;
        this.qrCodeData.Longitude = pos.coords.latitude;
      }, this.handleLocationError, { timeout: 30000 });
    }
  }

  handleLocationError(error: { code: any; }) {
    switch (error.code) {
      case 3:
        this.notifyService.showError('Timeout in Position capture', 'Sales Genie App');
        break;
      case 2:
        this.notifyService.showError('Device is not capable of Position capture', 'Sales Genie App');
        break;
      case 1:
        this.notifyService.showError('You denied for Position capture', 'Sales Genie App');
    }
  }

  startQrcodeScanner() {
    this.scannerEnabled = true;
  }

  onCodeResult(resultString: string): void {
    // this.guestExist = null;
    if (resultString.length > 0) {
      //if (this.checkQRJSON(resultString)) {
      //this.qrResult = JSON.parse(resultString);
      this.qrResult = resultString;
      this.qrCodeData.QRCode = this.output;
      this.scannerEnabled = false;
      //this.checkInGuest(this.qrResult);
      //this.clearMessage();
    } else {
      this.guestExist = false;
      this.clearMessage();
    }
  }

  checkQRJSON(qrString: string): boolean {
    if (
      /^[\],:{​​​​​​​}​​​​​​​\s]*$/.test(
        qrString
          .replace(/\\["\\\/bfnrtu]/g, "@")
          .replace(
            /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
            "]"
          )
          .replace(/(?:^|:|,)(?:\s*\[)+/g, "")
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

  clearMessage() {
    setTimeout(() => {
      this.guestExist = false;
      this.qrCodeData = new QRCodeData();
    }, 3000);
  }

  //Permission for the app to use the device camera
  onHasPermission(has: any): void {
    this.hasPermission = has;
  }

  openModal(modal: ElementRef<any> | undefined) {
    this.modalService.open(modal,
      {
        size: 'lg',
        backdrop: 'static',
        windowClass: '',
        keyboard: false,
        centered: true,
      })
  }
  modalClose() {
    this.modalService.dismissAll();

  }

  GetGeoLocation() {
    if (navigator) {
      navigator.geolocation.getCurrentPosition(pos => {
        this.lat = pos.coords.latitude;
        this.lng = pos.coords.longitude;
        // console.log('Lat Long',this.lat,this.lng)
      }, this.handleLocationError, { timeout: 30000 });
    }
    else {
      this.notifyService.showError('Location Could not be captured', 'Mera APP');
    }
  }

  clearResult(): void {
    this.qrResult = null;
  }

  public frameConfig: Object = {
    lineWidth: 4,
    strokeStyle: 'red'
  };
  public textConfig: Object = {
    font: 'bold 18px serif',
    fillStyle: 'red'
  };


  public onError(e: any): void {
    // debugger
    alert(e);
  }

  public qrCodeResult(result: any): void {
    if (result) {
      this.output = result;
      (<HTMLInputElement>document.getElementsByClassName("btn_Scan")[0]).click();
      return;
    }
  }

  GOBtn(QRCode: any) {
    // debugger
    if(!this.lat || !this.lng){
      this.toastr.warning("Please turn on location.");
      return
    }
    if(!QRCode){
      this.toastr.warning("QR Code data not found.");
      return
    }
    this.QRCodeHistory = [];
    // if (QRCode == "") {
    //   this.toastr.warning("Please enter QR code");
    //   return;
    // }
    this.GoBtnClicked = "true";
    // let dataToSend = QRCode
    let saveRequest = {
      "qrcode": QRCode,
      "userid": this.userId,
      "Latitude": this.lat?.toString(),
      "Longitude": this.lng?.toString()
    }
    this.ds.ATS_ScanQRCodeDataSave(saveRequest, this.token).subscribe();
    let request: any = {
      "qrcode": QRCode,
      "userId": this.userId
    }
    this.ds.ATS_QRCodeHistory(request, this.token).subscribe(res => {
      console.log("res", res);
      this.QRCodeHistory = res;
      if (this.QRCodeHistory.length == 0) {
        this.toastr.warning("QR code not found");
        this.output = "";
        return;
      }
      setTimeout(() => {
        // debugger
        document.getElementsByClassName("QRCodeName")[0]?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
      }, 300)
    });
  }



  numberOnly(event: { which: any; keyCode: any; }): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

}
