import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { statusreport, InputPara } from 'src/app/models/kpi-models';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterComponent } from '../filter/filter.component';
import { MatDialog } from '@angular/material/dialog';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-healthstatus-rpt',
  templateUrl: './healthstatus-rpt.component.html',
  styleUrls: ['./healthstatus-rpt.component.css']
})
export class HealthstatusRptComponent implements OnInit {
  FieldName: any;
  FieldValue: any;
  ParaName: any;
  UserID: any;
  HealthStatus: any;
  periodList: any[] = [];
  selectedperiod: any;
  UserType: any;
  device: any;
  deviceType:any;
  input:InputPara=new InputPara();
  myDate: any;
  isPC: boolean = false;
  token: any;
  formname:any;
  actiondesc:any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private router: Router,
    private headerService: HeaderService,
    private cs:CommonUtilitiesService,
    private mds: MongoDataService
    ) { }

  ngOnInit(): void {
    this.headerService.setActiveURl('/HealthstatusRpt');
    this.headerService.setTitle("Health Status Report");
    this.token = localStorage.getItem("TokenNo");
    this.activatedRoute.paramMap.forEach(params => {
      this.FieldValue = params.get('FieldValue');
      this.FieldName = params.get('FieldName');
      this.UserID = params.get('UserID');
      this.UserType = localStorage.getItem("UserType");
      this.ParaName = params.get('ParaName');
      this.device = localStorage.getItem("Device");
      this.deviceType = localStorage.getItem("DeviceType");
      this.myDate = Date.now();
      this.HealthStatus = [];
      this.formname="HealthstatusRpt";
      this.actiondesc="Health Status Rpt"+" "+this.FieldName;
      this.cs.GetGeoLocation(this.UserID,this.UserType,this.formname,this.actiondesc,this.FieldName,this.FieldValue);
      this.loadData();
    });
  }

  loadData(): void {
    this.input.FieldName=this.FieldName;
    this.input.FieldValue=this.FieldValue;
    this.input.TagName=this.ParaName;
    this.input.Datatype='0';
    this.input.UserID=this.UserID;
    this.mds.getHealthReport(this.input, this.token, this.device, this.deviceType, 'HealthstatusRpt').subscribe(res => {
      this.HealthStatus = res as statusreport;
      if(this.HealthStatus.length > 0) {
        if(this.HealthStatus[0].fieldname == 'pc') {
          this.isPC = true;
        } else {
          this.isPC = false;
        }
      }
    });
  }

  RowClick(fieldName:any, fieldValue: any): void{
    if (fieldName == "pc") {

    } else {
      this.router.navigate(['/home/HealthstatusRpt/',fieldName, fieldValue, this.ParaName, this.UserID]);
    }
  }
  openFilter(){
    if (this.dialog.openDialogs.length == 0) {
      const dialogRef = this.dialog.open(FilterComponent, {
        closeOnNavigation: true,
        data: {
          "FieldName": localStorage.getItem("OriginalFieldName"),
          "FieldValue": localStorage.getItem("OriginalFieldValue"),
          "UserID": this.UserID,
          "MenuUpTo": "5",
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.FieldName=result.FieldName;
          this.FieldValue=result.FieldValue;
          this.loadData();
        }
      });
    }
  }

}
