<div *ngIf="isProfile">
  <div class="row mt-4">
    <div class="col-12 text-center">
      <!-- <i class="p-1 fa fa-user-circle fa-6x" (click)="onClickImage()" style="color: #979797;" aria-hidden="true"></i> -->
      <img class="img-fluid" *ngIf="!isImage" src="assets/profile_Image.svg" (click)="photo.click()"/>
      <img class="img-fluid user_Profile" [src]='image' *ngIf="isImage" (click)="photo.click()"/>
      <img class="cam_icon" *ngIf="isImage" src="assets/camicon.svg" (click)="photo.click()"/>
      <input #photo type="file" accept="image/jpeg" capture="environment" id="cameraInput" name="cameraInput" (change)="onFileSelected($event)" style="display: none" />
    </div>
  </div>
  <div class="row" style="margin-top: 2.5rem;">
    <div class="col-12">
      <span class="text_Head">Name</span>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <span class="text_Value">{{userName}}</span>
    </div>
  </div>
  <hr>
  <div class="row">
    <div class="col-10">
      <span class="text_Head">Phone No</span>
    </div>
    <div class="col-2 text-right">
      <img class="img-fluid" src="assets/edit_profile.png" (click)="onClickEdit(openModalForEditPhoneNo)"/>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <span class="text_Value">{{mobileNo}}</span>
    </div>
  </div>
  <hr>
  <div class="row">
    <div class="col-12">
      <span class="text_Head">Email ID</span>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <span class="text_Value">{{emailID}}</span>
    </div>
  </div>
  <hr>
  <br>
</div>

<div *ngIf="!isProfile">
  <div class="row border bg_color">
    <div class="col-12 text-center">
      <i class="p-5 fa fa-user-circle fa-10x" style="color: #979797;" aria-hidden="true"></i>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-12">
      <span class="text_16">Profile Photo</span>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-4 text-center">
      <i class="fa fa-circle fa-3x" style="color: #c8c8c8"></i>
    </div>
    <div class="col-4 text-center">
      <i class="fa fa-circle fa-3x" style="color: #c8c8c8"></i>
    </div>
    <div class="col-4 text-center">
      <i class="fa fa-circle fa-3x" style="color: #c8c8c8"></i>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-4 text-center">
      <span>Remove Photo</span>
    </div>
    <div class="col-4 text-center">
      <span>Gallary</span>
    </div>
    <div class="col-4 text-center">
      <span>Camera</span>
    </div>
  </div>
</div>

<ng-template #openModalForEditPhoneNo let-modal>
  <div class="modal-header">
    <!-- <h4 class="modal-title text_color_head text_20" id="modal-basic-title">
      <img class="img-fluid" src="assets/login_notification.png" />
      <span class="ml-3">Notification</span>
    </h4> -->
    <button type="button" class="close" aria-label="Close" (click)="modalClose()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
  <form [formGroup]="editPhoneNo" autocomplete="off">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="phoneNo" class="text_Head">Enter your Phone No</label>
            <input id="phoneNo" class="form-control" type="text" name="phoneNo" formControlName="phoneNo">
          </div>
        </div>
      </div>
      </form>
    </div>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6" (click)="modalClose()">
        <button class="btn btn_Cancel">Cancel</button>
      </div>
      <div class="col-6" (click)="save()">
        <button class="btn btn_Save" [disabled]="!editPhoneNo.valid">Save</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #openModalForEditPhoto let-modal>
  <div class="modal-header">
    <!-- <h4 class="modal-title text_color_head text_20" id="modal-basic-title">
      <img class="img-fluid" src="assets/login_notification.png" />
      <span class="ml-3">Notification</span>
    </h4> -->
    <button type="button" class="close" aria-label="Close" (click)="modalClose()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <span class="text_Head">Profile Photo</span>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-4 text-center">
        <i class="fa fa-circle fa-3x" style="color: #c8c8c8"></i>
      </div>
      <div class="col-4 text-center">
        <i class="fa fa-circle fa-3x" style="color: #c8c8c8"></i>
      </div>
      <div class="col-4 text-center">
        <i class="fa fa-circle fa-3x" style="color: #c8c8c8"></i>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-4 text-center">
        <span>Remove Photo</span>
      </div>
      <div class="col-4 text-center">
        <span>Gallary</span>
      </div>
      <div class="col-4 text-center">
        <span>Camera</span>
      </div>
    </div>
  </div>
  <!-- <div class="modal-footer">
    <div class="row">
      <div class="col-6" (click)="modalClose()">
        <button class="btn btn_Cancel">Cancel</button>
      </div>
      <div class="col-6" (click)="save()">
        <button class="btn btn_Save" [disabled]="!editPhoneNo.valid">Save</button>
      </div>
    </div>
  </div> -->
</ng-template>
