<div class="container-fluid">
    <div class="row mt-4">
        <div class="col-9">
            <i class="fa fa-caret-left" style="color: #757575;" >&nbsp;&nbsp;
                <span class="header_text" >{{FieldValue=='0'? 'All India' : FieldValue=='ALLINDIA' ? 'All India': FieldValue |titlecase}}</span></i>
        </div>
        <div class="col-3 text-right">
            <img src="../../../assets/filter.png" class="img-fluid" (click)="openFilter()" /><sup><i
                class="fa fa-circle fa-sm" style="color: #eaab5e;font-size: 5px;"></i></sup>&nbsp;<span
                style="color: #724e8c;font-family: Roboto-Medium;font-weight:400 ;font-size: 14px;">Filter</span>
            </div>
    </div>
    <br>
    <div *ngIf="!IsPCValue" class="row mt-2">
        <div class="col-6">
            <span class="text_12">{{ParaValue}}</span>
        </div>
        <div class="col-6 text-right">
            <span class="text_12">L6M Avg Earnings</span>
        </div> 
    </div>
    <div  *ngIf="IsPCValue" class="row mt-2">
        <div class="col-6">
            <span class="text_12">Monthly Earnings</span>
        </div>
        <div class="col-6 text-right">
            <span class="text_12">Month</span>
        </div> 
    </div>
    <div  class="row mt-2" *ngFor="let item of pcTsiEarnings" (click)="RowClick(item.fieldname,item.paraname)">
        <div *ngIf="!IsPCValue" class="col-8">
            <span *ngIf="!IsRDValue" class="header_text">{{item.paraname}}</span>
            <span *ngIf="IsRDValue" class="header_text">{{item.desc}}</span>&nbsp;&nbsp;
            <span *ngIf="IsRDValue" class="header_code">{{item.paraname}}</span>
        </div>
        <div *ngIf="!IsPCValue" class="col-4 text-right">
            <span class="value_text">{{item.avgEarning}}</span>
        </div>
        <div *ngIf="IsPCValue" class="col-3">
            <span class="header_text">{{item.avgEarning}}</span>                                 
        </div>
        <div *ngIf="IsPCValue" class="col-6 mt-2">
            <div class="progress rounded-pill">
                <div class="progress-bar bg_sec" role="progressbar" [style.width.%]="item.totalincentive/100"
                aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div> 
            </div> 
        </div>
        <div *ngIf="IsPCValue" class="col-3 text-right p-0">
            <span class="value_text">{{item.paraname}}</span>
        </div>
        <hr class="hr_cust">  
    </div>
    <!-- <div class="row">
        <div class="col-12">
        <table mat-table style="width: 100%;" [dataSource]="pcTsiEarnings">
            <ng-container matColumnDef="paraName">
                <th mat-header-cell cdkDrag *matHeaderCellDef> {{ParaValue}} </th>               
                <td mat-cell style="font-weight: 500;" *matCellDef="let element"> {{element.paraname}} </td>
              </ng-container>
              <ng-container  matColumnDef="totalincentive">
                <th mat-header-cell style="text-align: right;" *matHeaderCellDef> L6M Avg Earnings </th>
                <td mat-cell class="value_text" *matCellDef="let element"> {{element.totalincentive}} </td>
              </ng-container> 
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row  *matRowDef="let row; columns: displayedColumns;" (click)="RowClick(row.fieldname,row.paraname)"></tr>           
            </table>
    </div>
</div> -->
</div>
<br><br><br><br>