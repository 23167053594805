import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { DatePipe } from '@angular/common';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';


@Component({
  selector: 'app-a2b-transactions',
  templateUrl: './a2b-transactions.component.html',
  styleUrls: ['./a2b-transactions.component.css']
})
export class A2bTransactionsComponent implements OnInit {

  deviceType: any;
  device: any;
  isLongPress: boolean = false;
  isSelected: boolean = false;
  list: any;
  searchText = '';
  userID: string | null ='';
  userName: string | null ='';
  private _searchText = '';
  filteredList: any;
  token: any;
  userType:any;
  formname:any;
  actiondesc:any;
  FieldName:any;
  FieldValue:any;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private cs:CommonUtilitiesService,
    private headerService: HeaderService, private datePipe: DatePipe, private mds: MongoDataService,) { }

  ngOnInit(): void {
    this.headerService.setTitle('A to B Transfer Transactions');
    this.headerService.setActiveURl('/a2bTransactions');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.userID = localStorage.getItem("UserID");
    this.userName = localStorage.getItem("UserID");
    this.token = localStorage.getItem("TokenNo");
    this.userType = localStorage.getItem("UserType");
    this.FieldName = localStorage.getItem("FieldName");
    this.FieldValue = localStorage.getItem("FieldValue");
    this.formname="a2bTransactions";
    this.actiondesc="a2b Transactions"
    this.cs.GetGeoLocation(this.userID,this.userType,this.formname,this.actiondesc,this.FieldName,this.FieldValue);
    this.loadData();
  }

  loadData() {
    this.mds.getATOBTransferList(this.userID, this.token, this.device, this.deviceType,'A to B Transfer Transactions').subscribe(res => {
      console.log("getATOBTransferList",res);
      this.list = res;
      this.filteredList = this.list;
    });
  }

  onLongPress() {
    this.isLongPress = true;
  }

  clickOnSelect() {
    this.isSelected = true;
  }

  rowClick() {
    this.router.navigate(['/home/fromOutletAtob']);
  }

  get searchTerm(): any{
    return this._searchText;
  }
  set searchTerm(value: any){
     this._searchText = value;
     this.filteredList = this.filteredListMethod(value);
  }

  filteredListMethod(value: any): any {
    return this.list.filter((ele: any) =>
      ele.fromOutletCode.indexOf(value) !== -1);
  }
}
