import { Component, OnInit } from '@angular/core';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-facialrecdetails',
  templateUrl: './facialrecdetails.component.html',
  styleUrls: ['./facialrecdetails.component.css']
})
export class FacialrecdetailsComponent implements OnInit {
  token: any;
  formname:any;
  actiondesc:any;
  fieldName: any;
  fieldValue: any;
  userType: string | null = '';
  userId: any;
  constructor(private cs:CommonUtilitiesService) { }

  ngOnInit(): void {
    this.userType = localStorage.getItem("UserType");
    this.userId = localStorage.getItem("UserID");
    this.token = localStorage.getItem("TokenNo");

    
    this.fieldName = localStorage.getItem("FieldName");
    this.fieldValue = localStorage.getItem("FieldValue");
    this.formname="facialrecdetails";
    this.actiondesc="Facial Rec Details"
    this.cs.GetGeoLocation(this.userId,this.userType,this.formname,this.actiondesc,this.fieldName,this.fieldValue);
  }

}
