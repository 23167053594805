<app-header></app-header>
<div class="container-fluid" style="margin-top: 70px;">
    <div *ngIf="DataType!='SE'" class="row mt-4">
        <div class="col-12">
            <input class="fullWidth" type="text" name="" id="" placeholder="Search Name Or UID"
                [(ngModel)]="searchTerm">
        </div>
    </div>   
    <div *ngIf="DataType=='PC'" class="row mt-4">
        <div class="col-8">
            <span class="header_text">Select RD</span>
        </div>
        <div class="col-4 text-right">
            <span class="header_text">{{list.length}}<span style="opacity: 40%;" class="header_text"> RD</span></span>
            <!-- {{list.length}} -->
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-12">
            <div class="row" *ngFor="let item of listOrigin; let i = index;" (click)="RowClick(item.rdcode,item.rdname)">
                <div class="container-fluid">
                    <div class="row mt-1">
                        <div class="col-12">
                            <span class="Head_text">{{(DataType=='SE'? item.soterritory : DataType=='RD' ? item.rdname :  DataType=='PC'? item.rdname : item.pcname) | titlecase}}<span *ngIf="DataType!='SE'"
                                    class="ml-1 Head_Val_txt">{{DataType=='RD' ? item.rdcode : DataType=='PC'? item.rdcode: item.pcuid }}</span></span>
                                &nbsp;<i class="fa fa-circle fa-sm" style="color:#0fc755dc; font-size: 7px;" *ngIf="item.pccode=='Y'"></i>
                        </div>
                    </div>
                    <div *ngIf="DataType=='RD' || DataType=='PC'" class="row mt-1"> 
                        <div class="col-12">
                            <span class="subhead_text">{{item.soterritory | titlecase}} </span>
                            <!-- {{item.town | titlecase}} -->
                        </div>
                    </div>
                    <div *ngIf="DataType!='PC'" class="row mt-3">
                        <div class="col-12">
                            <!-- <mat-radio-group aria-label="Select an option">
                                <mat-radio-button value="1" [checked]="item.pccode=='Y' && item.status==0 ? true : item.pccode=='Y' && item.status!=0 ? false : defaultchk=='first' ? true:false"  (change)="StatusChange(item,0)">{{firstchk}}</mat-radio-button>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <mat-radio-button [checked]="item.pccode=='Y' && item.status==1 ? true : item.pccode=='Y' && item.status!=1 ? false : defaultchk=='first' ? false:true" (change)="StatusChange(item,1)">{{secondchk}}</mat-radio-button>
                            </mat-radio-group> -->
                            <mat-radio-group aria-label="Select an option">
                                <mat-radio-button value="0" [checked]="item.status==0 ? true : false"  (change)="StatusChange(item,0)">{{firstchk}}</mat-radio-button>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <mat-radio-button value="1" [checked]="item.status==1 ? true : false" (change)="StatusChange(item,1)">{{secondchk}}</mat-radio-button>
                            </mat-radio-group>

                        </div>
                    </div>
                    <hr class="hr_cust">
                </div>
            </div>
        </div>
    </div>
    <br>
    <br>
    <br>
    <br>
    <div  class="footer">
        <div class="row text-center mt-3">
            <div *ngIf="DataType!='PC'" class="col-12" (click)="btnClick()">
                UPDATE
            </div>
        </div>
    </div>
</div>
<!-- <app-footer></app-footer> -->