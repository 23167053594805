<div>
  
    <div class="row mt-4">
      <div class="col-12">        
        <input class="fullWidth" type="text" placeholder=" Search RD Name Or UID" list="dynmicUserIds" (keyup)="getUserIdsFirstWay($event)" id="userIdFirstWay" (change)="SearchText($event)" autocomplete="off">
        <datalist id="dynmicUserIds">
          <option *ngFor="let item of userList1" [value]="item">{{item}}</option>
        </datalist>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-9">{{FieldValue=='ALLINDIA' ? 'All India': FieldValue}}
      </div>
      <div class="col-3 text-left">
          <img src="../../../assets/filter.png" class="img-fluid" (click)="openFilter()" /><sup><i
                  class="fa fa-circle fa-sm" style="color: #eaab5e;font-size: 5px;"></i></sup>&nbsp;<span
              style="color: #724e8c;font-weight:400 ;font-size: 0.8125rem;">Filter</span>
      </div>
  </div>
    <div class="row mt-4">
      <div class="col-8">
        <span class="header_text">RD List</span>
      </div>
      <div class="col-4 text-right">
        <span class="header_text">{{list.length}}<span style="opacity: 40%;"  class="header_text"> RD</span></span>
      </div>
    </div> 
    <div class="row mt-3">
      <div class="col-12">
        <div class="row" *ngFor="let item of list" (click)="RowClick(item.rdcode)">
          <div class="container-fluid">
              <div class="row mt-1">
                <div class="col-12">
                  <span  class="Head_text">{{item.rdname | titlecase}} <span
                    class="ml-1 Head_Val_txt">{{item.rdcode}} </span></span>
                </div>
              </div>
              <div class="row mt-1">
                <div class="col-12">
                  <span class="subhead_text">{{item.town | titlecase}} </span>
                </div>
              </div>
              <hr class="hr_cust">
          </div>
      </div>
      </div>
    </div>   
    
  </div>
  
  <br><br><br><br>