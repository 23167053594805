<div>
  <div class="row mt-4">
    <div class="col-12">
      <span class="text_16 text_black">Outlet</span>
    </div>
    <div class="col-12 mt-1">
      <span class="text_14 text_black">{{outletSelectedMV.outletname | titlecase}}</span>
      <span class="ml-1 text_14 text_black text_bold">{{outletSelectedMV.outletcode}}</span>
    </div>
  </div>
  <hr>
  <div class="row">
    <div class="col-12">
      <span class="text_16 text_black">RD</span>
    </div>
    <div class="col-12 mt-1">
      <span class="text_14 text_black">{{outletSelectedMV.rdName | titlecase}}</span>
      <span class="ml-1 text_14 text_black text_bold">{{outletSelectedMV.rdCode}}</span>
    </div>
  </div>
  <hr>
  <div class="row">
    <div class="col-12">
      <span class="text_16 text_black">Address 01</span>
    </div>
    <div class="col-12 mt-1">
      <span class="text_14 text_black">{{address | titlecase}}</span>
    </div>
  </div>
  <hr>
  <div class="row">
    <div class="col-12">
      <span class="text_18 text_black">Visicooler</span>
    </div>
    <div class="col-12 mt-1">
      <span class="text_14 text_black">{{outletSelectedMV.visicoolertype}}</span>
    </div>
  </div>
  <hr>
  <div class="row">
    <div class="col-12">
      <span class="text_16 text_black">FIR Date</span>
    </div>
    <div class="col-12 mt-1">
      <span class="text_14 text_black">{{outletSelectedMV.firdate | date:'dd/MM/yyyy'}}</span>
    </div>
  </div>
  <hr>
  <div class="row">
    <div class="col-12">
      <span class="text_16 text_black">FIR Reason</span>
    </div>
    <div class="col-12 mt-1">
      <span class="text_14 text_black">{{outletSelectedMV.reason}}</span>
    </div>
  </div>
  <hr>
    <br>
    <br>
    <br>
  <div class="footer">
    <div class="row">
      <div class="col-6 pr-0" (click)="missing()">
        <button class="btn btn_Missing">MISSING</button>
      </div>
      <div class="col-6 pl-0" (click)="found(openModalThankYou)">
        <button class="btn btn_Found">FOUND</button>
      </div>
    </div>
  </div>

  <ng-template #openModalThankYou let-modal>
    <div class="modal-header">
      <h4 class="modal-title text_color_head text_20" id="modal-basic-title">
        <img class="img-fluid" src="assets/thanks_icon.png" />
        <span class="ml-3">Visicooler Found</span>
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="modalClose()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <div class="row mt-4">
          <div class="col-12 customCol">
            <span class="text_16">Is Visicooler in working condition?</span>
          </div>
          <div class="col-3 mt-3 customCol">
            <div class="form-check">
              <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value="Yes"
              [(ngModel)]="isVisicooler" (change)="onItemChange($event)">
              <label class="form-check-label radio_Label" for="flexRadioDefault1" style="vertical-align: text-top;">
                Yes
              </label>
            </div>
          </div>
          <div class="col-3 mt-3">
            <div class="form-check">
              <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value="No"
              [(ngModel)]="isVisicooler" (change)="onItemChange($event)">
              <label class="form-check-label radio_Label" for="flexRadioDefault2" style="vertical-align: text-top;">
                No
              </label>
            </div>
          </div>
        </div>
        <div class="row" style="margin-top: 2.25rem;">
          <div class="col-12 customCol">
            <span class="text_16">Please Continue to Scan QR Code</span>
          </div>
        </div>
    </div>

    <ng-template #openConfirmationBox let-modal id="test">
      <div class="modal-header justify-content-center">
      </div>
      <div class="modal-body">
          <div class="row">
              <div class="col-12 text-center">
                  <span class="model_PopUp_Text text-center">Transaction is not completed.Do you want to continue ? </span>
              </div>
          </div>
      </div>
      <div class="modal-footer text-center justify-content-center" >
          <button type="button" class="btn okBtn" (click)="okay()">Yes</button>
          <button type="button" class="btn cancleBtn" (click)="cancleBtn()">No</button>
      </div>
    </ng-template>

    <div class="modal-footer">
      <div class="row">
        <div class="col-6" (click)="cancle(openConfirmationBox)">
          <button class="btn btn_CANCEL">CANCEL</button>
        </div>
        <div class="col-6" (click)="continue()">
          <button class="btn btn_CONTINUE"  [disabled]="isVisicooler==''" >CONTINUE</button>
        </div>
      </div>
    </div>
  </ng-template>
