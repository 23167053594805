<div>
  <div class="row" style="margin-top: 2rem;">
    <div class="col-12">
      <div class="form-group">
        <select id="ddlcomplaint" data-toggle="collapse" aria-expanded="false" class="form-control-plaintext btn-block"
          (change)="changecomplaint($event)">
          <option value="1">Select complaint</option>
          <option *ngFor="let obj of reason" [value]="obj.reasonName">
            {{obj.reasonName}}
          </option>
        </select>
      </div>
    </div>
  </div>
  <div class="row mt-1">
    <div class="col-12"><span class="dtl"> Details Of Issue</span></div>
  </div>
  <div class="row mt-2">
    <div class="col-12">
      <textarea name="text" #Issue class="form-control-detail" placeholder="Search" required
        (change)="ChangeIssue($event)"></textarea>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-12">
      <input type="text" #ReportingPerson class="form-control-plaintext" placeholder="Enter outlet Person Name"
        maxlength="150" (change)="ChgReportingPerson($event)" />
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-12">
      <input type="text" #ReportingContact class="form-control-plaintext " placeholder="Enter outlet contact no"
        maxlength="10" (change)="ChgReportingContact($event)" (keypress)="numberOnly($event)" maxlength="10"/>
    </div>
  </div>
  <div class="footer">
    <div class="row">
      <div class="col-6 pr-0" (click)="cancel()">
        <button class="btn btn_Cancel">CANCEL</button>
      </div>
      <div class="col-6 pl-0" (click)="ClickSave()">
        <button class="btn btn_SAVE">SAVE</button>
      </div>
    </div>
  </div>
</div>
<ng-template #openModalThankYou let-modal>
  <div class="modal-header">
    <h4 class="modal-title text_color_head text_20" id="modal-basic-title">
      <img class="img-fluid" src="assets/thanks_icon.png" />
      <span class="ml-3">Thank You</span>
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modalClose()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <span class="text_16">{{msg}}</span>
      </div>
    </div>
    <!-- <div class="row">
            <div class="col-12">
                <span class="text_16">successfully against</span>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <span class="text_16">{{outLetSelected.outletName | titlecase}}</span>
                <span class="text_16 ml-2" style="opacity: 40%;">{{outLetSelected.outletCode}}</span>
            </div>
        </div> -->
  </div>

  <div class="modal-footer" (click)="modalClose()">
    CONTINUE
  </div>
</ng-template>
