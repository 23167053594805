import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { DatePipe } from '@angular/common';
import { getRdList,InputPara } from 'src/app/models/kpi-models';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-pccr-rdlist',
  templateUrl: './pccr-rdlist.component.html',
  styleUrls: ['./pccr-rdlist.component.css']
})
export class PccrRdlistComponent implements OnInit {
  deviceType: any;
  device: any;
  list:any;
  listOrigin:any;
  private _searchText = '';
  userType: string | null = '';
  userId: any;
  FieldName: any;
  FieldValue: any;
  myBooks: any;
  userList1: any;
  lastkeydown1: number = 0;
  selectedText: any;
  input:InputPara=new InputPara();
  token: any;
  formname: any;
  actiondesc: any;
  fieldName: any;
  fieldValue: any;
  constructor(private cs:CommonUtilitiesService,private activatedRoute: ActivatedRoute, private router: Router, private headerService: HeaderService, private datePipe: DatePipe, private mds: MongoDataService,) { }

  ngOnInit(): void {
    this.headerService.setTitle('PC Creation Setting');
    this.headerService.setActiveURl('/PccrRdList');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.token = localStorage.getItem("TokenNo");
    this.userId = localStorage.getItem("UserID");
    this.userType = localStorage.getItem("UserType");
    this.cs.setFieldNamevalue();
    this.FieldName=localStorage.getItem("FieldName");
    this.FieldValue=localStorage.getItem("FieldValue");
    this.list=[];
    this.listOrigin=[];

    this.fieldName=localStorage.getItem("FieldName");
    this.fieldValue=localStorage.getItem("FieldValue");
    this.formname = "PccrRdlist";
    this.actiondesc = "Pc Cr Rdlist";
    this.cs.GetGeoLocation(this.userId, this.userType, this.formname, this.actiondesc, this.fieldName, this.fieldValue);

    this.onLoad();
  }

  onLoad() {
    this.input.FieldName=this.FieldName;
    this.input.FieldValue=this.FieldValue;
    this.input.TagName='0';
    this.input.Datatype= '0';
    this.input.UserID=this.userId;
    this.mds.getRdList(this.input, this.token, this.device, this.deviceType,'PC Creation Setting').subscribe(res => {
      this.listOrigin= this.list = res;
      //this.fillAutoCompleteSearchBox();
    });
  }

  RowClick(e: any): void {
    localStorage.setItem("PccreationSDetails",JSON.stringify(e))
    this.router.navigate(['/home/PccreationSetting/']);
  }
  get searchTerm(): any{
    return this._searchText;
  }
  set searchTerm(value: any){
     this._searchText = value;
     this.listOrigin = this.filteredListMethod(value);
  }

  filteredListMethod(value: any): any {
    return this.list.filter((ele: any) =>(
      ele.rdname.toUpperCase().indexOf(value.toUpperCase()) !== -1 || ele.rdcode.toString().toUpperCase().indexOf(value.toUpperCase()) !== -1));
  }
}

