import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { DataService } from 'src/app/service/data.service';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-erods-vouchers-list-approval',
  templateUrl: './erods-vouchers-list-approval.component.html',
  styleUrls: ['./erods-vouchers-list-approval.component.css']
})
export class ErodsVouchersListApprovalComponent implements OnInit {
  deviceType: any;
  device: any;
  userType: string | null = '';
  userId: any;
  list: any;
  fieldName: any;
  fieldValue: any;
  erodsApproval: any;
  token: any;
  formname:any;
  actiondesc:any;
  constructor(private activatedRoute: ActivatedRoute,private cs:CommonUtilitiesService, private router: Router, private headerService: HeaderService, private mds: MongoDataService, private notifyMeService:NotifyMeService, private ds: DataService,) { }

  ngOnInit(): void {
    this.headerService.setTitle('Erods Vouchers');
    this.headerService.setActiveURl('/erodsVouchersListApproval');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.userId = localStorage.getItem("UserID");
    this.token = localStorage.getItem("TokenNo");
    this.erodsApproval = JSON.parse(localStorage.getItem("erodsApproval") || '{}');

    console.log("erodsApproval",this.erodsApproval)
 

    this.mds.getUserDetails(this.userId, this.token, this.device, this.deviceType,'Transactions').subscribe(res => {
      this.userType=res[0].userType.toUpperCase();
    });
    this.fieldName = localStorage.getItem("FieldName");
    this.fieldValue = localStorage.getItem("FieldValue");
    this.formname="erodsVouchersListApproval";
    this.actiondesc="Erods Vouchers List Approval"
    this.cs.GetGeoLocation(this.userId,this.userType,this.formname,this.actiondesc,this.fieldName,this.fieldValue);
    this.onLoad();
  }

  onLoad() {
    this.ds.MA_ERODS_GetVoucherDetails(this.userId, this.erodsApproval.rdCode, this.token, this.device, this.deviceType, 'Erods Vouchers').subscribe(res => {
      console.log("MA_ERODS_GetVoucherDetails",res)
      this.list = res;
    });
  }

  rowClick(data: any){
    if(this.userType == "ASM" || this.userType == "BSM"){
      localStorage.setItem("erodsVoucherApproval",JSON.stringify(data))
      this.router.navigate(['/home/erodsVoucherApproval']);
    }else{
      this.notifyMeService.showWarning("You are not authorized for this transaction", 'Sales Genie');
    }
  }

}
