    <div class="row mt-4 mb-3">
        <div class="col-8">
            <input type="text" class="UID" #UID placeholder="Search UID" (keypress)="numberOnly($event)" />
        </div>
        <div class="col-4">
            <button class="btn btn_GO" (click)="GOBtn(UID)">GO</button>
        </div>
    </div>

    <div class="" *ngIf="filteredList.outletname!=null">
        <div class="row mt-5">
            <div class="col-12">
                <span class="outlet_Name">{{filteredList.outletname | titlecase}}</span>
                <span class="outlet_Code ml-1">{{filteredList.outletcode}}</span>
            </div>
        </div>

        <div class="row mt-2">
            <div class="col-12">
                <span class="rd_Name">{{filteredList.rdname | titlecase}}</span>
                <span class="rd_Code ml-1">{{filteredList.rdcode}}</span>
            </div>
        </div>

        <div class="row mt-2">
            <div class="col-12">
                <span class="rd_Name">Survey Date :</span>
                <span class="rd_Code ml-1">{{filteredList.surveydate}}</span>
            </div>
        </div>

        <div class="row mt-2">
            <div class="col-12">
                <span class="rd_Name">Parallel Dots Status :</span>
                <span class="rd_Code ml-1"> {{filteredList.paralleldotsstatus}}</span>
            </div>
        </div>

        
        <div class="row mt-2 " *ngIf="filteredList.paralleldotsstatus.toLowerCase()=='reject'">
            <div class="col-12" >
                <span class="rd_Name">Reason Of Rejection :</span>
                <span class="rd_Code ml-1 " style="word-wrap: break-word;">
                    {{filteredList.rejectionreason}}
                </span>
            </div>
        </div>

        <div class="row mt-2">
            <div class="col-12">
                <span class="rd_Name"> RD Status :</span>
                <span class="rd_Code ml-1">{{filteredList.rdstatus}}</span>
            </div>
        </div>

        <div class="row mt-2 ">
            <div class="col-12 rd_Name">Outlet Photo</div>
        </div>
        <div class="row mt-1">
            <div class="col-12">
                <img class="img_fluid mt-2 surveyImg"
                    [src]="mds.url + 'MeraNet/GetDownloadFileFromS3Bucket/SurveyImages/' + filteredList.imagename">
            </div>
        </div>
    </div>
    <br>
    <br>
    <br>
    <br>
    <br>
    