<div class="container-fluid">
    <div class="row mt-4">
        <div class="col-9">
            <i class="fa fa-caret-left" style="color: #757575;">&nbsp;&nbsp;
                <span class="header_text">{{FieldValue=='0'? 'All India' : FieldValue=='ALLINDIA' ? 'All India': FieldValue |titlecase}}</span></i>
        </div>
        <div class="col-3 text-right">
            <img src="../../../assets/filter.png" class="img-fluid" (click)="openFilter()" /><sup><i
                    class="fa fa-circle fa-sm" style="color: #eaab5e;font-size: 5px;"></i></sup>&nbsp;<span
                style="color: #724e8c;font-family: Roboto-Medium;font-weight:400 ;font-size: 14px;">Filter</span>
        </div>
    </div>
    <div class="row" style="margin-top: 1rem;">
        <div class="col-6 bgcustom ">
            <span class="header_text1">10 Bills</span>
        </div>
        <div class="col-6 text-right">
            <mat-form-field appearance="outline" style="width: 100px">
                <!-- <mat-label>Period</mat-label> -->
                <mat-select id="period" name="period" (selectionChange)="PeriodChange($event)" [(value)]="selectedperiod">
                  <mat-option value="" disabled>Select Month</mat-option>
                  <mat-option *ngFor="let item of periodList" [value]="item">
                    {{ item }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-12 text-center">
            <div class="col-12">
                <div class="row mt-4">
                    <div class="col-8 text-left" style="margin-left: -15px;"><span class="thheader">{{heading}}</span> </div>
                    <div class="col-4 text-right" style="margin-left: -10px;"><span class="thheader">No Of working
                            days</span>
                    </div>
                </div>
            </div>
            <div class="row" *ngFor="let cate of filterItemsOfType(tsiAttendance)" (click)="RowClick(cate.fieldName,cate.fieldvalue)">
                <div class="col-12 mt-2 mb-2">
                    <div class="row mt-2">
                        <div class="col-8 text-left">
                            <span class="tdcell">{{cate.desc +' '+ cate.fieldvalue|titlecase}} </span>
                        </div>
                        <div class="col-4 text-right">
                            <span class="tdcell"> {{cate.attendance}}%</span>
                        </div>
                    </div>
                </div>
                <hr class="hr_cust">
            </div>
        </div>
    </div>
</div>
<br><br><br><br>