import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { resPCAttendance, resPCFRData,InputPara } from 'src/app/models/kpi-models';
import { EncryptionService } from '../../service/encryption.service';
import { DataService } from '../../service/data.service';
import { Enclass, ResultClass } from '../../models/encryption-class';
import { MatDialog } from '@angular/material/dialog';
import { FilterComponent } from '../filter/filter.component';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';
import { NotifyMeService } from 'src/app/service/notify-me.service';


@Component({
  selector: 'app-pc-attendance',
  templateUrl: './pc-attendance.component.html',
  styleUrls: ['./pc-attendance.component.css']
})
export class PcAttendanceComponent implements OnInit {
  FieldName: any;
  FieldValue: any;
  UserID: any;
  periodList: any[] = [];
  selectedperiod: any;
  UserType: any;
  currentDate = new Date();
  pcAttendance: any;
  heading: string = "";
  CurrentDate: any;
  isPC: boolean = false;
  isRD: boolean = false;
  pcFrdata: any;
  ParaName: any;
  IsIndiaLevel: boolean = false;
  isFC:boolean=false;
  FRheading: any;
  selectedtabindex: number=0;
  device: any;
  deviceType: any;
  input:InputPara=new InputPara();
  token: any;
  lat:any;
  lng:any;

  constructor(private cs: CommonUtilitiesService, private activatedRoute: ActivatedRoute, private fb: FormBuilder, private es: EncryptionService, private router: Router,
    private headerService: HeaderService, private notifyMeService: NotifyMeService,
    private mds: MongoDataService, private ds: DataService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.headerService.setTitle('PC Attendance');
    this.headerService.setActiveURl('/pcattendance');
    this.UserType = localStorage.getItem("UserType");
    this.UserID =  localStorage.getItem("UserID");
    this.CurrentDate = new Date();
    this.device = localStorage.getItem('Device');
    this.deviceType = localStorage.getItem('DeviceType');
    this.token = localStorage.getItem("TokenNo");
      this.activatedRoute.paramMap.forEach(params => {
      this.FieldName = params.get('FieldName');
      this.FieldValue =  params.get('FieldValue');
      this.ParaName =  params.get('ParaName');
      this.UserID =  params.get('UserID');
      this.selectedperiod = params.get('selectedperiod');
      if(this.ParaName!="FC"){
        this.isFC=false;
        this.selectedtabindex=0;
      }
      else {
        this.isFC=true;
        this.selectedtabindex=1;
      }
      this.GetGeoLocation();
      this.loadData();
    });
  }

  GetGeoLocation() {
    debugger
    if (navigator) {
      navigator.geolocation.getCurrentPosition(pos => {
        this.lat = pos.coords.latitude;
        console.log("  this.lat", this.lat)
        this.lng = pos.coords.longitude;
        this.SetapplicationLogData();
      }, this.handleLocationError, { timeout: 30000 });
    }
    else {
      this.notifyMeService.showError('Location Could not be captured', 'Mera APP');
    }
  }

  handleLocationError(error: { code: any; }) {
    switch (error.code) {
      case 3:
        this.notifyMeService.showError('Timeout in Position capture', 'Mera APP V2');
        break;
      case 2:
        this.notifyMeService.showError('Device is not capable of Position capture', 'Mera APP V2');
        break;
      case 1:
        this.notifyMeService.showError('You denied for Position capture', 'Mera APP V2');
        break;
    }
  }

  SetapplicationLogData() {
    debugger
    let request = {
      "userid": this.UserID,
      "usertype": this.UserType,
      "formname": "pcattendance",
      "actiondesc": "pc attendance" + " " + this.FieldName,
      "fieldname": this.FieldName,
      "fieldvalue": this.FieldValue,
      "latitude": this.lat,
      "longitude": this.lng
    }
    this.ds.SetapplicationLogData(request).subscribe(res => {
      console.log("res", res)
    })
  }

  loadData() {
    debugger;
    if(this.isFC){this.GetPCFRData(); }
    else {this.GetPCAttendaceData();  }
  }
  onTabChanged(e: any) {
    if (e.index == "1") { this.selectedtabindex=1; this.isFC=true;  }
    else {  this.selectedtabindex=0;this.isFC=false;  }
    this.loadData();
  }
  PeriodChange(e: any): void {
    this.selectedperiod = e.source._value;
  }
  GetPCFRData() {
    this.input.FieldName=this.FieldName=="ALLINDIA" ? "INDIA" :this.FieldName.toUpperCase()=='ASMAREA'? 'ASM':this.FieldName.toUpperCase() =='RD'? 'rdcode': this.FieldName;
    this.input.FieldValue=this.FieldValue;
    this.input.TagName="1";
    this.input.Datatype= '1';
    this.input.UserID=this.UserID;
    this.ds.getGetFRData(this.input,this.token,this.device,this.deviceType,'PC Attendence').subscribe(res => {
       this.pcFrdata = res as resPCFRData;
       this.FRheading = this.heading= res[0].paraName ;

      if (this.pcFrdata.length > 0) {

        if (this.pcFrdata[0].paraName == "PCUID") {
          this.isPC = true;
        } else {
          this.isPC = false;
        }
        if (this.pcFrdata[0].paraName == "Rdcode") {
          this.isRD = true;
        } else {
          this.isRD = false;
        }
      }
    }
    );
  }

  RowClick(FName: any, FVal: any) {

    if(FName!='pc'){
      this.router.navigate(['/home/pcattendance/',FName,FVal,"PC",this.UserID,this.selectedperiod]);
    }
  }
  RowFRClick(FName: any, FVal: any) {
  if(FName!='PCUID'){
      this.router.navigate(['/home/pcattendance/',FName,FVal,"FC",this.UserID,this.selectedperiod]);
    }
  }
  GetPCAttendaceData() {
    this.pcAttendance = [];
    this.input.FieldName=this.FieldName.toUpperCase()=='INDIA' ? 'ALLINDIA' : this.FieldName.toUpperCase()=='ASM' ? 'ASMAREA' :this.FieldName.toUpperCase()=='RDCODE' ? 'rd':this.FieldName  ;
    this.input.FieldValue=this.FieldValue;
    this.input.TagName="pc";
    this.input.Datatype= 'details';
    this.input.UserID=this.UserID;
    this.mds.getPCAttendancedata(this.input,this.token,this.device,this.deviceType,'PC Attendence').subscribe(res => {
      this.pcAttendance = res as resPCAttendance;
      this.periodList = [...new Set(res.map((bill: { period: any; }) => bill.period))].sort();
     if(this.selectedperiod=="0"){
       this.selectedperiod = this.periodList[0];
     }
     this.FRheading = this.heading= res[0].fieldName ;

      if (this.pcAttendance.length > 0) {
        if (this.pcAttendance[0].fieldName == "pc") {
          this.isPC = true;
        } else {
          this.isPC = false;
        }
        if (this.pcAttendance[0].fieldName == "rd") {
          this.isRD = true;
        } else {
          this.isRD = false;
        }
      }

    }
    );
  }
  filterItemsOfType(type: any) {
    return type != null ? type.filter((x: { [x: string]: any; }) => (x['period'] == this.selectedperiod)) : null;
  }
  ClickFaceRecognition() {
    this.router.navigate(['/home/frrdlist/']);
  }


  openFilter() {
    if (this.dialog.openDialogs.length == 0) {
      const dialogRef = this.dialog.open(FilterComponent, {
        closeOnNavigation: true,
        data: {
          "FieldName": localStorage.getItem("OriginalFieldName"),//this.FieldName,
          "FieldValue": localStorage.getItem("OriginalFieldValue"),// this.FieldValue,
          "UserID": this.UserID,
          "MenuUpTo": "5",
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.FieldName = result.FieldName;
          this.FieldValue = result.FieldValue;
          this.loadData();
        }
      });
    }
  }
}
