<div>
    <div class="row mt-5">
        <div class="col-12">
            <span class="rateYourExpLabel">Rate Your Experience</span>
        </div>
    </div>

    <div class="row mt-3">
        <div class="col-12">
            <span style="opacity: 0.5;">Are you satisfied with the service</span>
        </div>
    </div>

    <div class="row mt-3">
        <div class="col-12">
            <ul class="list-inline rating-list" *ngFor="let star of stars" style="display: inline-block">
                <li (click)="countStar(star)" [ngClass]="{'selected': (star<= selectedValue)}">
                    <i class="fa fa-star" style="font-size: xxx-large;"></i>
                </li>
            </ul>
        </div>
    </div>

    <hr>

    <div class="row mt-4" *ngIf="selectedValue<5">
        <div class="col-12">
            <span style="font-size: 16px;font-weight: 500;">Which of the issues below was the biggest problem during your experience with the ticketing tool service?</span>
        </div>
    </div>

    <div class="row mt-2" *ngIf="selectedValue<5">
        <span *ngFor="let item of feedBackOption;let i=index" class="mt-2">
            <div class="col-12 ms-2">
                <input id="checkBox" 
                class="form-check-input" 
                type="checkbox"
                (click)="checkValue($event, item.id)"
                value="item.option"> 
                <span class="ms-2" style="font-size: 14px;">
                    {{ item.option }}
                </span>
            </div>
        </span>
 
    </div>


    <br><br><br>
    <div class="footer">
        <div class="row text-center">
            <div class="col-6 pr-0">
                <button class="btn btn_Cancel" (click)="clickOnBackBtn()">BACK</button>
            </div>
            <div class="col-6 pl-0">
                <button class="btn btn_APPROVE" (click)="submitFeedback()">SUBMIT</button>
            </div>
        </div>
    </div>
</div>

<ng-template #openSelectMediaModal let-modal>
    <div class="modal-header text-center">
        <h4 class="modal-title text_color_head text_20 text-center" id="modal-basic-title">
            <span class="ml-3 text-center Value_text">Select Media</span>
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="modalClose()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div id="accordion">
            <div class="card">
                <div class="card-header" id="headingOne">
                    <div class="row">
                        <div class="col-2">
                            <img src="../../../assets/icons/gallary_icon.svg">
                        </div>
                        <div class="col-10">
                            <span class="attachment" (click)="gallary.click()">Gallary</span>
                            <input #gallary type="file" accept="image/*" id="gallaryInput" name="gallaryInput"
                                (change)="onFileSelected($event)" style="display: none" />
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-2">
                            <img src="../../../assets/icons/camer_icon.svg">
                        </div>
                        <div class="col-10">
                            <span class="attachment text-center" (click)="camera.click()">Camera</span>
                            <input #camera type="file" accept="image/*" id="cameraInput" name="cameraInput"
                                (change)="onFileSelected($event)" style="display: none" />
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-2">
                            <img src="../../../assets/icons/document_icon.svg">
                        </div>
                        <div class="col-10">
                            <span class="attachment text-center" (click)="excel.click()">Excel</span>
                            <input #excel type="file"
                                accept=".csv,.xlsx,.xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                id="excelInput" name="excelInput" (change)="onFileSelected($event)"
                                style="display: none" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>