import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/service/data.service';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';
@Component({
  selector: 'app-pc-tsi',
  templateUrl: './pc-tsi.component.html',
  styleUrls: ['./pc-tsi.component.css']
})
export class PcTSIComponent implements OnInit {
  list : any;
  deviceType: any;
  device: any;
  FormName: any;
  UserID: any;
  RdCode: any;
  PCCode: any;
  showModal: boolean=false;
  selectedimagepath: any;
  //photopath:any="https://dev.cilsales.net/PCImages/";
  photopath:any="https://prodapi.cilsales.net/PCImages/";
  token: any;
  formname: any;
  actiondesc: any;
  userType: string | null = '';
  fieldName: any;
  fieldValue: any;

  constructor(private headerService: HeaderService,private cs:CommonUtilitiesService,private activatedRoute: ActivatedRoute,  private router: Router,private ds: DataService) { }

  ngOnInit(): void {
    this.headerService.setTitle("PC/TSI");
    this.headerService.setActiveURl('/pc/tsi');
    this.device = localStorage.getItem('Device');
    this.deviceType = localStorage.getItem('DeviceType');
    this.token = localStorage.getItem("TokenNo");
    this.FormName = 'pc-tsi';
    this.UserID = localStorage.getItem('UserID');
    this.RdCode = '0';
    this.PCCode = '0';
    this.list = [];
    this.selectedimagepath="";
    this.photopath =localStorage.getItem("baseapiurl") + "PCImages/";
    this.userType = localStorage.getItem("UserType");
    this.fieldName=localStorage.getItem("FieldName");
    this.fieldValue=localStorage.getItem("FieldValue");
    this.formname = "pc/tsi";
    this.actiondesc = "Pc Tsi";
    this.cs.GetGeoLocation(this.UserID, this.userType, this.formname, this.actiondesc, this.fieldName, this.fieldValue);

    this.loadData();
  }
  OnClick(pccode: any,rdcode:any){
    this.router.navigate(['/home/pctsidetails/',this.UserID,rdcode,pccode]);
  }
  loadData(){
    this.ds.salesmanMasterForApproval_List(this.UserID, this.RdCode, this.PCCode, this.token, this.device,this.deviceType,this.FormName).subscribe(res => {
      this.list = res;
    });
  }

  show(imagepath:any)
  {
    debugger;
    this.selectedimagepath=imagepath;
    this.showModal = true; // Show-Hide Modal Check

  }
  //Bootstrap Modal Close event
  hide()
  {
    this.showModal = false;
  }

}
