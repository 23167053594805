<div *ngIf="MeetingID=='0'">
    <form [formGroup]="MeetingForm" autocomplete="off">
    <div class="row mt-5" >
        <div class="col-12">
          <div class="form-group">
            <select id="ddlTypeOfMeeting" class="form-control-plaintext btn-block"   (change)="changeTypeOfMeeting($event)" name="TypeOfMeeting"
            formControlName="TypeOfMeeting" required>
              <option value="" selected disabled>Select Type Of Meeting</option>
              <option *ngFor="let obj of TypeList" [value]="obj">
                {{obj}}
              </option>
            </select>
          </div>
        </div>
        <div class="col-12 mt-2">
            <div class="form-group">
              <input type="number" placeholder="Add No of PC/TSI Participants?" class="form-control-plaintext btn-block"
              formControlName="Participants" required >
            </div>
        </div>
        <hr class="hr_cust">
        <div class="col-9 text-left">
            <span>Have you shared target with PC/TSI</span>
        </div>
        <div class="col-3 text-right">
            <mat-slide-toggle formControlName="IsSharedTarget"></mat-slide-toggle>
        </div>
      </div>
      <hr class="hr_cust">
      <div class="row mt-4">
        <div class="col-3">
          <img [src]="image" alt="Image" *ngIf="image" class="img-fluid">
          <div class="Rectangle" *ngIf="!image">
            <span class="Image">Image</span>
          </div>
        </div>
        <div class="col-7 mt-3">
          <span class="text_14 text_black">Take a selfie with PC/TSI</span>
        </div>
        <div class="col-2 mt-3">
          <img class="img-fluid" src="assets/camera.png" style="margin-top: -4px;" (click)="MeetingPhoto.click()"/>
          <input #MeetingPhoto id="MeetingPhoto" type="file" accept="image/jpeg" capture="environment" (change)="fileProgress($event)" style="display: none" />
        </div>
      </div>
      <hr class="hr_cust">
      <div class="row mt-2 vw-100">
        <div class="col-12">
          <textarea rows="4" name="text" class="remark_text" placeholder="Enter Meeting Note"
            formControlName="MeetingNote" required></textarea>
        </div>
      </div>
    </form>
    <div class="footer" *ngIf="isValid && MeetingForm.valid">
        <div class="row text-center mt-3">
          <div class="col-12" (click)="rowClick()">
            CHECK OUT
          </div>
        </div>
      </div>
</div>
<div *ngIf="MeetingID!='0'">
    <div class="row mt-4" *ngFor="let item of MeetingDtls" >
        <div class="col-12">
            <span class="Headertext" >Date</span>
        </div>
        <div class="col-12">
            <span class="detailtext" >{{item.startTime| date}}</span>
        </div>
        <hr class="hr_cust">
        <div class="col-12">
            <span class="Headertext" >Duration</span>
        </div>
        <div class="col-12">
            <span class="detailtext" >{{item.startTime| date:'shortTime' }} To {{item.endTime| date:'shortTime' }}</span>
        </div>
        <hr class="hr_cust">
        <div class="col-12">
            <span class="Headertext" >Type of Meeting</span>
        </div>
        <div class="col-12">
            <span class="detailtext" >{{item.meetingType}}</span>
        </div>
        <hr class="hr_cust">
        <div class="col-12">
            <span class="Headertext" >No of PC/TSI Participants</span>
        </div>
        <div class="col-12">
            <span class="detailtext" >{{item.noOfPCPresent}}</span>
        </div>
        <hr class="hr_cust">
        <div class="col-12">
            <span class="Headertext" >Have you shared target with PC/TSI</span>
        </div>
        <div class="col-12">
            <span class="detailtext" >{{item.isTargetProvided}}</span>
        </div>
        <hr class="hr_cust">
        <div class="col-6 mt-3 text-left">
            <span class="Headertext">Selfie with PC/TSI</span>
        </div>
        <div class="col-6 text-right">
          <img class="img-fluid ID_Card" [src]="item.photo" (click) = "show(item.photo)"/>
        </div>
        <hr class="hr_cust">
        <div class="col-12">
            <span class="Headertext" >Meeting Note</span>
        </div>
        <div class="col-12">
            <span class="detailtext" >{{item.meetingNote}}</span>
        </div>
        <hr class="hr_cust">
    </div>
    <br><br><br><br>
    <div [style.display]="showModal ? 'block' : 'none'" class="modal" id="imagemodal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="myModalLabel">Image preview</h4>
          </div>
          <div class="modal-body">
            <img [src]="selectedimagepath" id="imagepreview" style="width: 100%; height: 425px;" >
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal" (click) = "hide()">Close</button>
          </div>
        </div>
      </div>
  <br> <br> <br><br> <br>
</div>
