import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { DataService } from 'src/app/service/data.service';
import { InputPara } from 'src/app/models/kpi-models';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-rla-auto-closure-select-reason-approval',
  templateUrl: './rla-auto-closure-select-reason-approval.component.html',
  styleUrls: ['./rla-auto-closure-select-reason-approval.component.css']
})
export class RlaAutoClosureSelectReasonApprovalComponent implements OnInit {
  deviceType: any;
  device: any;
  userType: string | null = '';
  userId: any;
  list: any;
  selectedReasons: any;
  selected = -1;
  input:InputPara=new InputPara();
  fieldName: any;
  fieldValue: any;
  currYearNo: any;
  rlaAutoClosureOutletDetails: any;
  currmonthno: any;
  token: any;
  formname:any;
  actiondesc:any;

  constructor(private activatedRoute: ActivatedRoute,private cs:CommonUtilitiesService, private router: Router, private headerService: HeaderService, private mds: MongoDataService, private ds: DataService, private notifyMeService:NotifyMeService,) { }

  ngOnInit(): void {
    this.headerService.setTitle('SE Reason');
    this.headerService.setActiveURl('/rlaAutoClosureSelectReasonApproval');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.token = localStorage.getItem("TokenNo");
    this.userType = localStorage.getItem("UserType");
    this.userId = localStorage.getItem("UserID");
    this.fieldName = localStorage.getItem("FieldName");
    this.fieldValue = localStorage.getItem("FieldValue");
    this.currYearNo = localStorage.getItem("curryearno");
    this.currmonthno = localStorage.getItem("currmonthno");
    this.rlaAutoClosureOutletDetails = JSON.parse(localStorage.getItem("rlaAutoClosureOutletDetails") || '{}');

    
    this.formname = "rlaAutoClosureSelectReasonApproval"
    this.actiondesc = "Rla Auto Closure Select Reason Approval";
    // comment for development
    this.cs.GetGeoLocation(this.userId, this.userType, this.formname, this.actiondesc, this.fieldName, this.fieldValue);
    this.onLoad();

  }

  onLoad() {
    this.input.FieldName=this.rlaAutoClosureOutletDetails.fieldName;
    this.input.FieldValue=this.rlaAutoClosureOutletDetails.fieldvalue;
    this.input.TagName='l3mreason';
    this.input.Datatype= this.currmonthno;
    this.input.UserID=this.userId;
    this.mds.getReasonListForSO(this.input, this.token, this.device, this.deviceType , 'SE Reason').subscribe(res => {
     debugger;
      this.list = res;
    });
  }

  selected_Reason(e: any) {
    if (e.target.checked) {
      this.selectedReasons = e.target.value;
    }
  }

  rowClick() {
    const dataToSend = {
      UserID : this.userId,
      PCPerReason : '',
      PCReason : this.selectedReasons,
      YearNo : Number(this.currYearNo),
      MonthNo : Number(this.currmonthno),
      CLSID : this.rlaAutoClosureOutletDetails.pcuid,
    };
    this.ds.autoClosureReportClassForSO(dataToSend, this.token, this.device, this.deviceType, 'SE Reason').subscribe(res => {
      this.notifyMeService.showSuccess('Record APPROVED Sucessfully', 'Sales Genie App');
      this.router.navigate(['/home/rlaAutoClosureApproval']);
    });
  }

}
