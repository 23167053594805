<form [formGroup]="feedbackForm" autocomplete="off">
    <div class="row" style="margin-top: 2rem;">
        <div class="col-12">
            <span class="info_text">Please Submit your Feedback details here. Your Feedback is very important for us. This will help us to improve performance of Sales Genie.</span>
        </div>
        <br><br><br><br>
        <br><br>
      <div class="col-12">
        <textarea rows="5" name="text" class="feedback_Text" placeholder="Enter Your Feedback" formControlName="feedback"
          required></textarea>
      </div>
    </div>
  </form>

  <div class="footer" *ngIf="feedbackForm.valid">
    <div class="row">
      <div class="col-6 pr-0" (click)="Cancel()">
        <button class="btn btn_CANCEL">CANCEL</button>
      </div>
      <div class="col-6 pl-0" (click)="submit()">
        <button class="btn btn_APPROVE">SUBMIT</button>
      </div>
    </div>
  </div>
  <ng-template #openModalThankYou let-modal>
    <div class="modal-header">
      <h4 class="modal-title text_color_head text_20" id="modal-basic-title">
        <img class="img-fluid" src="assets/thanks_icon.png" />
        <span class="ml-3">Thank You</span>
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="modalClose()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-12">
          <span class="model_PopUp_Text">Feedback Saved Successfully</span>
        </div>
      </div>
      <div class="row mt-4 mb-2">
        <!-- <div class="col-12">
          <span class="rd_Name_PopUp">{{stockInoutDetails.rdName}}</span>
          <span class="ml-2 rd_Code_PopUp">{{stockInoutDetails.rdCode}}</span>
        </div> -->
      </div>
    </div>
    <div class="modal-footer" (click)="okay()">
      OKAY
    </div>
  </ng-template>
