<div class="container-fluid">
  <div class="row mt-5 customRow">
    <div class="col-4 text-center">
      <img class="img_fluid_custom bg_Channel" src="assets/oif.png" (click)="clickOnOIF()" />
    </div>
    <div class="col-4 text-center">
      <img class="img_fluid_custom bg_Channel" src="assets/oif_pullout.png" (click)="clickOnPullOutApproval()" />
    </div>
    <div class="col-4 text-center">
      <img class="img_fluid_custom bg_Channel" src="assets/a2b.png" (click)="clickOnA2B()" />
    </div>

  </div>
  <div class="row mt-1 customRow">
    <div class="col-4 text-center" (click)="clickOnOIF()">
      <span [class]="IsPulloutApproval ?  'heading mEnable' : 'heading mDisable'">OIF</span>
    </div>
    <div class="col-4 text-center" (click)="clickOnPullOutApproval()">
      <span [class]="IsPulloutApproval ?  'heading mEnable' : 'heading mDisable'">Pull Out</span>
    </div>
    <div class="col-4 text-center" (click)="clickOnA2B()">
      <span [class]="IsATOBApproval ?  'heading mEnable' : 'heading mDisable'">A2B</span>
    </div>
  </div>

  <div class="row mt-4 customRow">
    <div class="col-4 text-center">
      <img class="img_fluid_custom bg_Channel" src="assets/pc_tsi.png" (click)="clickOnPCTSI()" />
    </div>
    <div class="col-4 text-center">
      <img class="img_fluid_custom bg_Channel" src="assets/rla_Auto_Closure_icon.svg"
        (click)="clickOnRLAAutoClosure()" />
    </div>
    <div class="col-4 text-center">
      <img class="img_fluid_custom bg_Channel" src="assets/stock_In_Out.svg" (click)="clickOnstockInOut()" />
    </div>
  </div>
  <div class="row mt-1 customRow">
    <div class="col-4 text-center" (click)="clickOnPCTSI()">
      <span [class]="ISPCTSIApproval ?  'heading mEnable' : 'heading mDisable'">PC/TSI</span>
    </div>
    <div class="col-4 text-center" (click)="clickOnRLAAutoClosure()">
      <span [class]="ISRLAApproval ?  'heading mEnable' : 'heading mDisable'">RLA Auto Closure</span>
    </div>
    <div class="col-4 text-center" (click)="clickOnstockInOut()">
      <span [class]="IsStkInOutApproval ?  'heading mEnable' : 'heading mDisable'">stock In/Out</span>
    </div>
  </div>

  <div class="row mt-4 customRow">
    <div class="col-4 text-center">
      <img class="img_fluid_custom bg_Channel" src="assets/fac_app_icon.svg" (click)="clickOnFacRec()" />
    </div>
    <div class="col-4 text-center">
      <img class="img_fluid_custom bg_Channel" src="assets/claim_icon.svg" (click)="clickOnEClaim()" />
    </div>
    <div class="col-4 text-center">
      <img class="img_fluid_custom bg_Channel" src="assets/cold_Chain_Audit.svg" (click)="clickOnColdChainAudit()" />
    </div>
  </div>
  <div class="row mt-1 customRow">
    <div class="col-4 text-center" (click)="clickOnFacRec()">
      <span [class]="IsFRApproval ?  'heading mEnable' : 'heading mDisable'">Facial Recognition</span>
    </div>
    <div class="col-4 text-center" (click)="clickOnEClaim()">
      <span [class]="ISClaimApproval ?  'heading mEnable' : 'heading mDisable'">E-claim</span>
    </div>
    <div class="col-4 text-center" (click)="clickOnColdChainAudit()">
      <span [class]="ISColdChainApproval ?  'heading mEnable' : 'heading mDisable'">Cold Chain Audit</span>
    </div>
  </div>

  <div class="row mt-4 customRow">
    <div class="col-4 text-center">
      <img class="img_fluid_custom bg_Channel" src="assets/fssai_icon.svg" (click)="clickOnFSSAI()" />
    </div>
    <div class="col-4 text-center">
      <img class="img_fluid_custom bg_Channel" src="assets/sub_rd_icon.svg" (click)="clickOnSubRD()" />
    </div>
    <div class="col-4 text-center">
      <img class="img_fluid_custom bg_Channel" src="assets/erods_icon.svg" (click)="clickOnErods()" />
    </div>
  </div>
  <div class="row mt-1 customRow">
    <div class="col-4 text-center" (click)="clickOnFSSAI()">
      <span [class]="ISFSSAIApproval ?  'heading mEnable' : 'heading mDisable'">FSSAI</span>
    </div>
    <div class="col-4 text-center" (click)="clickOnSubRD()">
      <span [class]="ISSubRD ?  'heading mEnable' : 'heading mDisable'">Sub RD</span>
    </div>
    <div class="col-4 text-center" (click)="clickOnErods()">
      <span [class]="ISErodsApproval ?  'heading mEnable' : 'heading mDisable'">Erods</span>
    </div>
  </div>

  <div class="row mt-4 customRow">
    <div class="col-4 text-center">
      <img class="img_fluid_custom bg_Channel" src="assets/asset_Approval.svg" (click)="clickOnAssetApproval()" />
    </div>
    <div class="col-4 text-center">
      <img class="img_fluid_custom bg_Channel" src="assets/MTAFH.svg" (click)="clickOnMTAFH()" />
    </div>
    <div class="col-4 text-center">
      <img class="img_fluid_custom bg_Channel" src="assets/rdAudit.svg" (click)="clickOnRDAudit()" />
    </div>
  </div>


  <div class="row mt-1 customRow">
    <div class="col-4 text-center" (click)="clickOnAssetApproval()">
      <span [class]="ISFSSAIApproval ?  'heading mEnable' : 'heading mDisable'">Asset Approval</span>
    </div>
    <div class="col-4 text-center" (click)="clickOnMTAFH()">
      <span [class]="ISMTAFHApproval ?  'heading mEnable' : 'heading mDisable'">MTAFH</span>
    </div>
    <div class="col-4 text-center" (click)="clickOnRDAudit()">
      <span href="http://localhost:4200/ACM-dashboard/acm-home"
        [class]="ISMTAFHApproval ?  'heading mEnable' : 'heading mDisable'">RDAudit</span>
    </div>
  </div>


  <div class="row mt-4 customRow">
    <div class="col-4 text-center">
      <img class="img_fluid_custom bg_Channel" src="../../../assets/icons/outletNameChangeIcon.svg" (click)="clickOnOutletNameChangeApproval()" />
    </div>
  </div>


  <div class="row mt-1 customRow">
    <div class="col-4 text-center" (click)="clickOnOutletNameChangeApproval()">
      <span [class]="ISMTAFHApproval ?  'heading mEnable' : 'heading mDisable'">Outlet Name Change Approval</span>
    </div>
  </div>

  <br><br><br><br>

</div>