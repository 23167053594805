import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Enclass, ResultClass } from 'src/app/models/encryption-class';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';
import { EncryptionService } from 'src/app/service/encryption.service';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';

@Component({
  selector: 'app-ticket-details-feedback',
  templateUrl: './ticket-details-feedback.component.html',
  styleUrls: ['./ticket-details-feedback.component.css']
})
export class TicketDetailsFeedbackComponent implements OnInit {
  stars: number[] = [1, 2, 3, 4, 5];
  selectedValue: any = 0;
  token: any;
  actiondesc: any;
  fieldName: any;
  fieldValue: any;
  deviceType: any;
  device: any;
  userType: string | null = '';
  userId: any;
  formname: any = ""
  FormName: any = ""
  public sendComment = false;
  public comment: any = "";
  public commentDate: any = "";
  selectedFile: any = "";
  @ViewChild('openSelectMediaModal', { static: true }) elRefopenSelectMediaModal: ElementRef | undefined;
  title: any = ""
  secretKey: any = ""
  clientId: any = ""
  public enclass: Enclass = new Enclass();
  public resultClass: ResultClass = new ResultClass();
  feedBackOption: any = []
  checkList: any = []
  ticketDetails:any=[]
  constructor(private headerService: HeaderService, private modalService: NgbModal,
    private cs: CommonUtilitiesService, private es: EncryptionService,
    private router: Router, private toastr: ToastrService, public mds: MongoDataService,) { }

  ngOnInit(): void {
    this.FormName = 'TicketDetailsFeedback'
    this.headerService.setTitle('Feedback');
    this.headerService.setActiveURl('/TicketDetailsFeedback');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.userId = localStorage.getItem("UserID");
    this.token = localStorage.getItem("TokenNo");
    this.userType = localStorage.getItem("UserType");
    this.fieldName = localStorage.getItem("FieldName");
    this.fieldValue = localStorage.getItem("FieldValue");
    this.secretKey = localStorage.getItem("SecreteKey")
    this.clientId = localStorage.getItem("ClientID");
    this.formname = "TicketDetailsFeedback"
    this.actiondesc = "Universe Re-Mapping";
    this.ticketDetails = []
    this.ticketDetails = JSON.parse(localStorage.getItem("ticketDetails") || '{}')
    this.cs.GetGeoLocation(this.userId, this.userType, this.formname, this.actiondesc, this.fieldName, this.fieldValue);
    this.loadData()
  }


  loadData() { 
    let request = {
      "companyCode": "mdlz",
    }

    console.log("request", request)
    this.es.Word = this.es.WordKey;
    this.es.encrypt(this.secretKey);
    this.es.Word = JSON.stringify(request);
    this.enclass.buggerstring = this.es.encrypt(this.secretKey)
    this.mds.HD_GetFeedBackOptions(this.enclass, this.token, this.device, this.deviceType, this.FormName, this.clientId).subscribe(res => {
      console.log("getSurveydetails", res)

      this.resultClass = res;
      this.es.Word = this.resultClass.resultString;
      let response = JSON.parse(this.es.decrypt(this.secretKey));
      console.log("response", response)

      this.feedBackOption = []
      if (response.Data[0].feedBackOption?.length > 0) {
        this.feedBackOption = response.Data[0].feedBackOption
      }

      for (let i = 0; i < this.feedBackOption?.length; i++) {
        this.feedBackOption[i].id = i + 1
        this.feedBackOption[i].isChecked = false
      }

    }, (error: any) => {
      console.log("error", error)
    });
  }


  checkValue(e: any, ind: any) {
    debugger
    console.log("e", e.target.checked)
    for (let i = 0; i < this.feedBackOption.length; i++) {
      if (e.target.checked) {
        this.feedBackOption[ind-1].isChecked = true
      }
      else{
        this.feedBackOption[ind-1].isChecked = false
      }
    }
    console.log("feedBackOption", this.feedBackOption)
  }

  clickOnBackBtn() {
    this.router.navigate(['/home/ticketLogList'])
  }

  submitFeedback(){
    let request = {
      "companyCode": "mdlz",
      "ticketNo":this.ticketDetails.ticketNo,
      "feedbackStar": this.selectedValue,
      "feedbackstatus":this.feedBackOption.filter((f:any)=>f.isChecked).map((m:any)=>m.option),
      "feedbackFlag":"Y"
    }

    console.log("request", request)
    this.es.Word = this.es.WordKey;
    this.es.encrypt(this.secretKey);
    this.es.Word = JSON.stringify(request);
    this.enclass.buggerstring = this.es.encrypt(this.secretKey)
    this.mds.HD_UpdateFeedBackStatus(this.enclass, this.token, this.device, this.deviceType, this.FormName, this.clientId).subscribe(res => {
      console.log("getSurveydetails", res)

      this.resultClass = res;
      this.es.Word = this.resultClass.resultString;
      let response = JSON.parse(this.es.decrypt(this.secretKey));
      console.log("response", response)

      this.toastr.success(response.Data)
      this.router.navigate(['/home/ticketLogList'])


    }, (error: any) => {
      console.log("error", error)
    });
  }
  countStar(star: any) {
    debugger
    this.selectedValue = star;
    console.log('Value of star', star);
  }


  openModal(modal: ElementRef<any> | undefined) {
    this.modalService.open(modal,
      {
        size: 'lg',
        backdrop: 'static',
        windowClass: '',
        keyboard: false,
        centered: true,
      })
  }
  modalClose() {
    this.modalService.dismissAll();
  }

  attachmentOption() {
    this.openModal(this.elRefopenSelectMediaModal);
  }

  commentSend(comment: any, isRequired: any = false) {
    debugger
    if ((comment.trim() == "" && this.selectedFile == "") && isRequired == true) {
      this.toastr.warning("Please enter comment or  attachment")
      return;
    }
    else if ((comment.trim() != "" || this.selectedFile != "")) {
      this.sendComment = true;
    }
    else {
      this.sendComment = false;
    }
    let currentDate = new Date();
    let month = currentDate.getMonth() + 1;
    var date = currentDate.getDate();
    var year = currentDate.getFullYear()
    this.commentDate = date + "-" + month + "-" + year;

    (<HTMLInputElement>document.getElementById("updateInputBox")).value = ""

    setTimeout(() => {
      (<HTMLInputElement>document.getElementById('test'))?.scrollIntoView();
    }, 2)
  }


  onFileSelected(event: any) {

  }

  btnclick() {
    this.title = "hi this is btn click"
  }

  upload(file: any) {

    debugger
  }
}

