<!-- <div > PC/TSI NAME heading   </div> -->
<div class="container-fluid">
    <div class="row mt-4">
        <div class="col-6"><span class="PCTSIheading">PC/TSI Name</span></div>
        <div class="col-6 text-right"><span class="rdcode">RD Code</span></div>
    </div>
    <div class="row mt-3" *ngFor="let item of list" >
        <div class="col-2 p-0">
            <img class="img-fluid user_Profile" [src]="photopath + item.photoPath " (click) = "show(photopath + item.photoPath)"/>
        </div>
        <div class=" col-7" (click)="OnClick(item.pcCode,item.rdcode);">
            <div class="row">
                <div class=" col-12">
                    <span class="TSIName">{{item.pcname}}</span>
                </div>
            </div>
            <div class="row">
                <div class=" col-12">
                    <span class="designation">{{item.qualification}}</span>
                </div>
            </div>
        </div>
        <div class=" col-3 text-right" (click)="OnClick(item.pcCode,item.rdcode);">
            <span>{{item.rdcode}}</span>
        </div>
        <div class="col-12 p-0" (click)="OnClick(item.pcCode,item.rdcode);">
            <hr class="Divider" />
        </div>
    </div>
    <div [style.display]="showModal ? 'block' : 'none'" class="modal" id="imagemodal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="myModalLabel">Image preview</h4>
            </div>
            <div class="modal-body">
              <img [src]="selectedimagepath" id="imagepreview" style="width: 100%; height: 425px;" >
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-default" data-dismiss="modal" (click) = "hide()">Close</button>
            </div>
          </div>
        </div>
    <br> <br> <br><br> <br>

</div>