<div>
  <form [formGroup]="nonMeranetForm" autocomplete="off">
    <div class="row" style="margin-top: 2rem;">
      <div class="col-12">
        <div class="form-group">
          <label for="outlet_Name" class="label_Text">Outlet Details</label>
          <input id="outlet_Name" class="form-control" type="text" name="outletDetails" formControlName="outletDetails"
            required>
        </div>
      </div>
      <div class="col-12">
        <div class="form-group">
          <label for="rd" class="label_Text">RD Details</label>
          <input id="rd" class="form-control" type="text" name="rdDetails" formControlName="rdDetails" required>
        </div>
      </div>
      <div class="col-12">
        <div class="form-group">
          <label for="mobile_No" class="label_Text">Mobile No</label>
          <input id="mobile_No" class="form-control" type="text" name="mobNo" formControlName="mobNo" required
            (keypress)="numberOnly($event)" maxlength="10">
        </div>
      </div>
      <div class="col-12">
        <div class="form-group">
          <label for="contact_Person" class="label_Text">Contact Person</label>
          <input id="contact_Person" class="form-control" type="text" name="contactPerson"
            formControlName="contactPerson" required>
        </div>
      </div>
      <div class="col-12">
        <div class="form-group">
          <label for="address" class="label_Text">Address</label>
          <input id="address" class="form-control" type="text" name="address" formControlName="address" required>
        </div>
      </div>
      <div class="col-12">
        <div class="form-group">
          <label for="Visicooler" class="label_Text">Visicooler</label>
          <select id="Visicooler" class="form-control" (change)="changeVisicooler($event)" name="visicooler"
            formControlName="visicooler" required>
            <option value="" selected disabled>Select Option</option>
            <option *ngFor="let obj of visiCoolerList" [value]="obj.reasonName">
              {{obj.reasonName}}
            </option>
          </select>
        </div>
      </div>
      <div class="col-12">
        <div class="form-group">
          <label for="VC Make" class="label_Text">VC Make</label>
          <input id="address" class="form-control" type="text" name="vcMake" formControlName="vcMake" readonly>
        </div>
      </div>
      <div class="col-12">
        <div class="form-group">
          <label for="State" class="label_Text">State</label>
          <select id="State" class="form-control" name="state" formControlName="state" required>
            <option value="" selected disabled>Select Option</option>
            <option *ngFor="let obj of stateList" [value]="obj.statename">
              {{obj.statename}}
            </option>
          </select>
        </div>
      </div>
      <div class="col-12">
        <div class="form-group">
          <label for="town" class="label_Text">Town</label>
          <input id="town" class="form-control" type="town" name="town" formControlName="town" required>
        </div>
      </div>
    </div>
  </form>
  <br>
  <br>
  <br>
  <br>
  <br>
  <div class="footer" *ngIf="nonMeranetForm.valid">
    <div class="row text-center mt-3">
      <div class="col-12" (click)="rowClick()">
        ADD COMPLAINT
      </div>
    </div>
  </div>
</div>
