import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CategoryPerformanceComponent } from './components/category-performance/category-performance.component';
import { CommonPerformanceComponent } from './components/common-performance/common-performance.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LoginComponent } from './components/login/login.component';
import { MeraAppHomeComponent } from './components/mera-app-home/mera-app-home.component';
import { SecondaryTrendComponent } from './components/secondary-trend/secondary-trend.component';
import { TargetAchComponent } from './components/target-ach/target-ach.component';
import { FilterComponent } from './components/filter/filter.component';
import { ValuePerformanceComponent } from './components/value-performance/value-performance.component'
import { ValuePerformenceDataComponent } from './components/value-performence-data/value-performence-data.component'
import { PcEarningsComponent } from './components/pc-earnings/pc-earnings.component'
import { NotificationsComponent } from './components/notifications/notifications.component';
import { FaqlistComponent } from './components/faqlist/faqlist.component';


import { UnbilledOutletComponent } from './components/unbilled-outlet/unbilled-outlet.component';
import { CommonPerformanceDataComponent } from './components/common-performance-data/common-performance-data.component';
import { ProfileComponent } from './components/profile/profile.component';
import { EffectiveCoverageComponent } from './components/effective-coverage/effective-coverage.component';
import { EffectiveCoverageDataComponent } from './components/effective-coverage-data/effective-coverage-data.component';
import { VpoComponent } from './components/vpo/vpo.component';
import { VpoDataComponent } from './components/vpo-data/vpo-data.component';

import { PcdetailsMainComponent } from './components/pcdetails-main/pcdetails-main.component';
import { PclocationDetailsComponent } from './components/pclocation-details/pclocation-details.component';
import { TodayorderBookingComponent } from './components/todayorder-booking/todayorder-booking.component';
import { NotificationDetailsComponent } from './components/notification-details/notification-details.component';
import { TsidetailsMainComponent } from './components/tsidetails-main/tsidetails-main.component';
import { TsilocationDetailsComponent } from './components/tsilocation-details/tsilocation-details.component';

import { UlbComponent } from './components/ulb/ulb.component';
import { UlbDataComponent } from './components/ulb-data/ulb-data.component';
import { RdPerformanceComponent } from './components/rd-performance/rd-performance.component';
import { ApprovalsComponent } from './components/approvals/approvals.component';
import { OifApprovalComponent } from './components/oif-approval/oif-approval.component';
import { NotoficationlistComponent } from './components/notoficationlist/notoficationlist.component';
import { PullOutApprovalComponent } from './components/pull-out-approval/pull-out-approval.component';
import { A2BComponent } from './components/a2-b/a2-b.component';
import { A2BDataComponent } from './components/a2-bdata/a2-bdata.component';
import { TransactionsComponent } from './components/transactions/transactions.component';
import { PulloutTransactionsComponent } from './components/pullout-transactions/pullout-transactions.component';
import { A2bTransactionsComponent } from './components/a2b-transactions/a2b-transactions.component';

import { SplashScreenComponent } from './splash-screen/splash-screen.component';
import { FromOutletAtobComponent } from './components/from-outlet-atob/from-outlet-atob.component';
import { OutletDetailAtobComponent } from './components/outlet-detail-atob/outlet-detail-atob.component';
import { ToOutletAtobComponent } from './components/to-outlet-atob/to-outlet-atob.component';
import { OutletDetailOneAtobComponent } from './components/outlet-detail-one-atob/outlet-detail-one-atob.component';
import { OutletDetailTwoAtobComponent } from './components/outlet-detail-two-atob/outlet-detail-two-atob.component';
import { AtobTransferPreviewComponent } from './components/atob-transfer-preview/atob-transfer-preview.component';
import { SearchOutletPulloutreqComponent } from './components/search-outlet-pulloutreq/search-outlet-pulloutreq.component';
import { OutletDetailPulloutreqComponent } from './components/outlet-detail-pulloutreq/outlet-detail-pulloutreq.component';
import { PullOutReasonPulloutreqComponent } from './components/pull-out-reason-pulloutreq/pull-out-reason-pulloutreq.component';

import { OutletDetailOIFComponent } from './components/outlet-detail-oif/outlet-detail-oif.component';
import { NewRLAComponent } from './components/new-rla/new-rla.component';
import { NewRLADataComponent } from './components/new-rladata/new-rladata.component';
import { RdListComponent } from './components/rd-list/rd-list.component';
import { PcAttendanceComponent } from './components/pc-attendance/pc-attendance.component';
import { TsiAttendanceComponent } from './components/tsi-attendance/tsi-attendance.component';
import { LtmComponent } from './components/ltm/ltm.component';
import { GateMeetingComponent } from './components/gate-meeting/gate-meeting.component';
import { GateMeetingdataComponent } from './components/gate-meetingdata/gate-meetingdata.component';
import { NpdComponent } from './components/npd/npd.component';
import { NpdDataComponent } from './components/npd-data/npd-data.component';
import { PcfrRdlistComponent } from './components/pcfr-rdlist/pcfr-rdlist.component';
import { FrPClistComponent } from './components/fr-pclist/fr-pclist.component';
import { SeEarningComponent } from './components/se-earning/se-earning.component';
import { SeearningDetailComponent } from './components/seearning-detail/seearning-detail.component';
import { PraiseComponent } from './components/praise/praise.component';
import { PraiseDetailsComponent } from './components/praise-details/praise-details.component';
import { PcTSIComponent } from './components/pc-tsi/pc-tsi.component';
import { MissingVisicoolerComponent } from './components/missing-visicooler/missing-visicooler.component';
import { MissingVisicoolerOutletDetailComponent } from './components/missing-visicooler-outlet-detail/missing-visicooler-outlet-detail.component';
import { MissingReasonComponent } from './components/missing-reason/missing-reason.component';
import { ScanQRCodeComponent } from './components/scan-qrcode/scan-qrcode.component';
import { ComplaintsComponent } from './components/complaints/complaints.component';
import { ComplaintsListComponent } from './components/complaints-list/complaints-list.component';
import { AddComplaintComponent } from './components/add-complaint/add-complaint.component';
import { SearchOutletComplaintComponent } from './components/search-outlet-complaint/search-outlet-complaint.component';
import { OutletdetailComplaintComponent } from './components/outletdetail-complaint/outletdetail-complaint.component';
import { UnbilledOutletdataComponent } from './components/unbilled-outletdata/unbilled-outletdata.component';
import { GreenOutletComponent } from './components/green-outlet/green-outlet.component';
import { UrjaaVPComponent } from './components/urjaa-vp/urjaa-vp.component';
import { FaqComponent } from './components/faq/faq.component';
import { PcTSIDetailsComponent } from './components/pc-tsidetails/pc-tsidetails.component';
import { FacialrecognitionComponent } from './components/facialrecognition/facialrecognition.component';
import { FacialrecdetailsComponent } from './components/facialrecdetails/facialrecdetails.component';
import { NonMeranetOutletdetailComponent } from './components/non-meranet-outletdetail/non-meranet-outletdetail.component';
import { GateMeetinglistComponent } from './components/gate-meetinglist/gate-meetinglist.component';
import { GateMeetingnewComponent } from './components/gate-meetingnew/gate-meetingnew.component';
import { FacialRecognitionApprovalComponent } from './components/facial-recognition-approval/facial-recognition-approval.component';
import { FacialRecDetailsApprovalComponent } from './components/facial-rec-details-approval/facial-rec-details-approval.component';
import { FacialRecRejReasonApprovalComponent } from './components/facial-rec-rej-reason-approval/facial-rec-rej-reason-approval.component';
import { StockInoutApprovalComponent } from './components/stock-inout-approval/stock-inout-approval.component';
import { StockInoutDetailsApprovalComponent } from './components/stock-inout-details-approval/stock-inout-details-approval.component';
import { StockInoutSelectrdApprovalComponent } from './components/stock-inout-selectrd-approval/stock-inout-selectrd-approval.component';
import { RlaAutoClosureApprovalComponent } from './components/rla-auto-closure-approval/rla-auto-closure-approval.component';
import { RlaAutoClosureDetailsApprovalComponent } from './components/rla-auto-closure-details-approval/rla-auto-closure-details-approval.component';
import { RlaAutoClosureSelectReasonApprovalComponent } from './components/rla-auto-closure-select-reason-approval/rla-auto-closure-select-reason-approval.component';
import { ClaimApprovalComponent } from './components/claim-approval/claim-approval.component';
import { ClaimDetailsApprovalComponent } from './components/claim-details-approval/claim-details-approval.component';
import { ClaimDetailsEditApprovalComponent } from './components/claim-details-edit-approval/claim-details-edit-approval.component';
import { FssaiApprovalComponent } from './components/fssai-approval/fssai-approval.component';
import { FssaiDetailsApprovalComponent } from './components/fssai-details-approval/fssai-details-approval.component';
import { ErodsApprovalComponent } from './components/erods-approval/erods-approval.component';
import { ErodsVouchersListApprovalComponent } from './components/erods-vouchers-list-approval/erods-vouchers-list-approval.component';
import { ErodsVoucherApprovalComponent } from './components/erods-voucher-approval/erods-voucher-approval.component';
import { ErodsVoucherDetailsApprovalComponent } from './components/erods-voucher-details-approval/erods-voucher-details-approval.component';
import { ErodsSelectReasonApprovalComponent } from './components/erods-select-reason-approval/erods-select-reason-approval.component';
import { PccrRdlistComponent } from './components/pccr-rdlist/pccr-rdlist.component';
import { PccreationSettingComponent } from './components/pccreation-setting/pccreation-setting.component';
import { PccreationEditComponent } from './components/pccreation-edit/pccreation-edit.component';
import { VillageProductivityComponent } from './components/village-productivity/village-productivity.component';
import { UrjaaVCScanComponent } from './components/urjaa-vcscan/urjaa-vcscan.component';
import { UrjaaEffectiveCoverageDataComponent } from './components/urjaa-effective-coverage-data/urjaa-effective-coverage-data.component';
import { DmsDataComponent } from './components/dms-data/dms-data.component';
import { UrjaaUlbComponent } from './components/urjaa-ulb/urjaa-ulb.component';
import { PcDeviceTaggingComponent } from './components/pc-device-tagging/pc-device-tagging.component';
import { PcDeviceTaggingSelectPcComponent } from './components/pc-device-tagging-select-pc/pc-device-tagging-select-pc.component';
import { PcDeviceTaggingDeviceIdComponent } from './components/pc-device-tagging-device-id/pc-device-tagging-device-id.component';
import { StockInoutVoucherDetailsApprovalComponent } from './components/stock-inout-voucher-details-approval/stock-inout-voucher-details-approval.component';
import { HealthstatusComponent } from './components/healthstatus/healthstatus.component';
import { HealthstatuslistComponent } from './components/healthstatuslist/healthstatuslist.component';
import { ClaimDetailsTwoApprovalComponent } from './components/claim-details-two-approval/claim-details-two-approval.component';
import { HealthstatusRptComponent } from './components/healthstatus-rpt/healthstatus-rpt.component';
import { ColdChainAuditComponent } from './components/cold-chain-audit/cold-chain-audit.component';
import { ColdChainAuditDetailsComponent } from './components/cold-chain-audit-details/cold-chain-audit-details.component';
import { PrimaryInvoicesComponent } from './components/primary-invoices/primary-invoices.component';
import { FeedbackComponent } from './components/feedback/feedback.component'
import { MediaComponent } from './components/media/media.component';
import { KpiProductivityComponent } from './components/kpi-productivity/kpi-productivity.component';
import { KpiProductivityDataComponent } from './components/kpi-productivity-data/kpi-productivity-data.component';
import { KpiProductivityDaywiseComponent } from './components/kpi-productivity-daywise/kpi-productivity-daywise.component';
import { SubRdApprovalComponent } from './components/sub-rd-approval/sub-rd-approval.component';
import { SubRdApprovalDetailsComponent } from './components/sub-rd-approval-details/sub-rd-approval-details.component';
import { SubRdApprovalDetailsEditComponent } from './components/sub-rd-approval-details-edit/sub-rd-approval-details-edit.component';
import { SubRdApprovalSelectMarketComponent } from './components/sub-rd-approval-select-market/sub-rd-approval-select-market.component';
import { SubRdApprovalSelectRdTypeComponent } from './components/sub-rd-approval-select-rd-type/sub-rd-approval-select-rd-type.component';
import { SubRdApprovalSelectOldRdComponent } from './components/sub-rd-approval-select-old-rd/sub-rd-approval-select-old-rd.component';
import { CertificateComponent } from './components/certificate/certificate.component';
import { MScoreComponent } from './components/mscore/mscore.component';
import { MScoreDataComponent } from './components/mscore-data/mscore-data.component';
import { MscorePcDataComponent } from './components/mscore-pc-data/mscore-pc-data.component';
import { AssetApprovalComponent } from './components/asset-approval/asset-approval.component';
import { OtherApplicationsComponent } from './components/other-applications/other-applications.component';
import { VcComponent } from './components/vc/vc.component';
import { PdfComponent } from './components/pdf/pdf.component';
import { MTAFHApprovalComponent } from './components/mtafhapproval/mtafhapproval.component';
import { MTAFHApprovaldetailsComponent } from './components/mtafhapprovaldetails/mtafhapprovaldetails.component';
import { A2bVCComponent } from './components/a2b-vc/a2b-vc.component';
import { A2bpdfComponent } from './components/a2bpdf/a2bpdf.component';
import { A2bTransferComponent } from './components/a2b-transfer/a2b-transfer.component';
import { SurveyApprovalComponent } from './components/survey-approval/survey-approval.component';
import { SurveyApprovalDetailsComponent } from './components/survey-approval-details/survey-approval-details.component';
import { AddEligibleOutletComponent } from './components/add-eligible-outlet/add-eligible-outlet.component';
import { CheckPhotoOfOutletAndAgreementComponent } from './components/check-photo-of-outlet-and-agreement/check-photo-of-outlet-and-agreement.component';
import { OutletDetailsComponent } from './components/outlet-details/outlet-details.component';
import { QRCodeHistoryComponent } from './components/qrcode-history/qrcode-history.component';
import { QrCodeOutletDetailsComponent } from './components/qr-code-outlet-details/qr-code-outlet-details.component';
import { EligibleOutletSerachComponent } from './components/eligible-outlet-serach/eligible-outlet-serach.component';
import { MarketWorkingComponent } from './components/market-working/market-working.component';
import { MarketWorkingRdListDataComponent } from './components/market-working-rd-list-data/market-working-rd-list-data.component';
import { MarketWorkingPcListComponent } from './components/market-working-pc-list/market-working-pc-list.component';
import { MarketWorkingPcListDataComponent } from './components/market-working-pc-list-data/market-working-pc-list-data.component';
import { MarketWorkingBeatListComponent } from './components/market-working-beat-list/market-working-beat-list.component';
import { MarketWorkingBeatListDataComponent } from './components/market-working-beat-list-data/market-working-beat-list-data.component';
import { MarketWorkingOutletListComponent } from './components/market-working-outlet-list/market-working-outlet-list.component';
import { MarketWorkingOutletListDataComponent } from './components/market-working-outlet-list-data/market-working-outlet-list-data.component';
import { MarketWorkingOutletLocationDetailsComponent } from './components/market-working-outlet-location-details/market-working-outlet-location-details.component';
import { OutletNameChangeRequestComponent } from './components/outlet-name-change-request/outlet-name-change-request.component';
import { OutletNameChangeRequestSendOtpComponent } from './components/outlet-name-change-request-send-otp/outlet-name-change-request-send-otp.component';
import { OutletNameChangeRequestVerifyOtpComponent } from './components/outlet-name-change-request-verify-otp/outlet-name-change-request-verify-otp.component';
import { OutletNameChangedVerifiedOtpConfirmationComponent } from './components/outlet-name-changed-verified-otp-confirmation/outlet-name-changed-verified-otp-confirmation.component';
import { OutletNameChangeApprovalListComponent } from './components/outlet-name-change-approval-list/outlet-name-change-approval-list.component';
import { OutletNameChangeApprovalListDetailsComponent } from './components/outlet-name-change-approval-list-details/outlet-name-change-approval-list-details.component';
import { OutletNameChangeComponent } from './components/outlet-name-change/outlet-name-change.component';
import { TicketLogListComponent } from './components/ticket-log-list/ticket-log-list.component';
import { NewTicketLogComponent } from './components/new-ticket-log/new-ticket-log.component';
import { UpdateTicketDetailsComponent } from './components/update-ticket-details/update-ticket-details.component';
import { TicketDetailsFeedbackComponent } from './components/ticket-details-feedback/ticket-details-feedback.component';
import { ScanQrCodeInTransactionComponent } from './components/scan-qr-code-in-transaction/scan-qr-code-in-transaction.component';
import { ScanQrCodeDataInTransactionComponent } from './components/scan-qr-code-data-in-transaction/scan-qr-code-data-in-transaction.component';
import { ScanQrCodeMatchInTransactionComponent } from './components/scan-qr-code-match-in-transaction/scan-qr-code-match-in-transaction.component';
import { TodaysVisitsComponent } from './components/todays-visits/todays-visits.component';
import { TodaysVisitsOutletListDetailsComponent } from './components/todays-visits-outlet-list-details/todays-visits-outlet-list-details.component';



const routes: Routes = [
  { path: "", component: SplashScreenComponent },

  { path: 'login', component: LoginComponent },
  { path: 'Healthstatus', component: HealthstatusComponent },
  { path: 'Healthstatuslist/:FieldName/:FieldValue/:Paraname/:DataType/:UserID', component: HealthstatuslistComponent },
  { path: 'Healthstatuslist/:FieldName', component: HealthstatuslistComponent },
  { path: 'login/vc/:KeyID', component: VcComponent },
  { path: 'login/onc/:KeyID', component: OutletNameChangeComponent },
  { path: 'login/pdf/:KeyID/:type', component: PdfComponent },
  { path: 'login/a2bvc/:KeyID', component: A2bVCComponent },
  { path: 'login/a2bpdf/:KeyID/:type', component: A2bpdfComponent },
  {
    path: 'home', component: MeraAppHomeComponent,
    children: [
      { path: '', component: DashboardComponent },
      { path: 'target', component: TargetAchComponent },
      { path: 'MenuFilter', component: FilterComponent },
      { path: 'MenuFilter/:FieldName/:FieldValue/:UserID/:MenuUpTo', component: FilterComponent },

      { path: 'category', component: CategoryPerformanceComponent },
      { path: 'Secondarytrend', component: SecondaryTrendComponent },
      { path: 'Secondarytrend/:FieldName/:FieldValue/:UserID', component: SecondaryTrendComponent },
      { path: 'ValuePerformance/:selectedperiod', component: ValuePerformanceComponent },
      { path: 'CommomPerformance', component: CommonPerformanceComponent },
      { path: 'CommomPerformanceData/:FieldName/:FieldValue/:DataType/:UserID/:Selected', component: CommonPerformanceDataComponent },
      { path: 'ValuePerformanceData/:FieldName/:FieldValue/:UserID/:DataType/:selectedperiod', component: ValuePerformenceDataComponent },
      { path: 'PcEarnings', component: PcEarningsComponent },
      { path: 'notifications', component: NotificationsComponent },
      { path: 'faqlist', component: FaqlistComponent },
      { path: 'profile', component: ProfileComponent },
      { path: 'PcEarnings/:FieldName/:FieldValue/:ParaName/:UserID', component: PcEarningsComponent },
      { path: 'UnbilledOutlet/:FieldName/:FieldValue/:DataType/:UserID/:selectedperiod', component: UnbilledOutletComponent },
      { path: 'pcdetailsmain/:FieldName/:FieldValue/:DataType/:UserID', component: PcdetailsMainComponent },
      { path: 'pclocation/:FieldName/:FieldValue/:DataType/:UserID', component: PclocationDetailsComponent },
      { path: 'EffectiveCoverage/:FieldName/:FieldValue/:ParaName/:UserID/:selectedperiod', component: EffectiveCoverageComponent },
      { path: 'EffectiveCoverageData/:FieldName/:FieldValue/:ParaName/:UserID/:selectedperiod', component: EffectiveCoverageDataComponent },
      { path: 'Vpo/:FieldName/:FieldValue/:TagName/:ParaName/:UserID/:selectedperiod', component: VpoComponent },
      { path: 'VpoData/:FieldName/:FieldValue/:TagName/:ParaName/:UserID/:selectedperiod', component: VpoDataComponent },
      { path: 'todayorderbooking/:FieldName/:FieldValue/:DataType/:UserID', component: TodayorderBookingComponent },
      { path: 'TodaysVisits/:FieldName/:FieldValue/:DataType/:UserID', component: TodaysVisitsComponent },
      { path: 'TodaysVisitsOutletListDetails/:FieldName/:FieldValue/:DataType/:UserID', component: TodaysVisitsOutletListDetailsComponent },
      { path: 'notificationdetails/:ID/:flag/:dataFrom', component: NotificationDetailsComponent },
      { path: 'tsidetailsmain/:FieldName/:FieldValue/:DataType/:UserID', component: TsidetailsMainComponent },
      { path: 'tsilocation/:FieldName/:FieldValue/:DataType/:UserID', component: TsilocationDetailsComponent },
      { path: 'NewRLAData/:FieldName/:FieldValue/:ParaName/:UserID/:selectedperiod', component: NewRLADataComponent },
      { path: 'NewRLA/:FieldName/:FieldValue/:ParaName/:UserID/:selectedperiod', component: NewRLAComponent },
      { path: 'RdList', component: RdListComponent },
      { path: 'GateMeeting', component: GateMeetingComponent },
      { path: 'GateMeetingdata/:UserID/:ParaName/:FieldName/:FieldValue/:DataType', component: GateMeetingdataComponent },
      { path: 'Praise', component: PraiseComponent },
      { path: 'PraiseDetails', component: PraiseDetailsComponent },
      { path: 'UnbilledOutletdata/:FieldName/:FieldValue/:DataType/:UserID/:selectedperiod', component: UnbilledOutletdataComponent },
      { path: 'GreenOutlet/:FieldName/:FieldValue/:DataType/:UserID/:selectedperiod', component: GreenOutletComponent },

      { path: 'ulb/:FieldName/:FieldValue/:ParaName/:UserID', component: UlbComponent },
      { path: 'ulbData/:FieldName/:FieldValue/:ParaName/:UserID', component: UlbDataComponent },
      { path: 'ulb/:FieldName/:FieldValue/:ParaName/:UserID/:ULBType', component: UlbComponent },
      { path: 'ulbData/:FieldName/:FieldValue/:ParaName/:UserID/:ULBType', component: UlbDataComponent },

      { path: 'MScore/:FieldName/:FieldValue/:ParaName/:UserID/:MScoreType', component: MScoreComponent },
      { path: 'MScoreData/:FieldName/:FieldValue/:ParaName/:UserID/:MScoreType', component: MScoreDataComponent },

      { path: 'MScorePCData/:FieldName/:FieldValue/:ParaName/:UserID/:MScoreType', component: MscorePcDataComponent },

      { path: 'Approvals', component: ApprovalsComponent },
      { path: 'RdPerformance', component: RdPerformanceComponent },
      { path: 'OifApproval', component: OifApprovalComponent },
      { path: 'pullOutApproval', component: PullOutApprovalComponent },
      { path: 'a2b', component: A2BComponent },
      { path: 'a2bData', component: A2BDataComponent },
      { path: 'transactions', component: TransactionsComponent },
      { path: 'ticketLogList', component: TicketLogListComponent },
      { path: 'newTicket', component: NewTicketLogComponent },
      { path: 'UpdateTicketDetails', component: UpdateTicketDetailsComponent },
      { path: 'TicketDetailsFeedback', component: TicketDetailsFeedbackComponent },
      { path: 'addEligibleOutlet', component: AddEligibleOutletComponent },
      { path: 'pulloutTransactions', component: PulloutTransactionsComponent },
      { path: 'a2bTransactions', component: A2bTransactionsComponent },
      { path: 'Notoficationlist', component: NotoficationlistComponent },
      { path: 'fromOutletAtob', component: FromOutletAtobComponent },
      { path: 'outletDetailAtob', component: OutletDetailAtobComponent },
      { path: 'toOutletAtob', component: ToOutletAtobComponent },
      { path: 'outletDetailOneAtob', component: OutletDetailOneAtobComponent },
      { path: 'outletDetailTwoAtob', component: OutletDetailTwoAtobComponent },
      { path: 'atobTransferPreview', component: AtobTransferPreviewComponent },
      { path: 'searchOutletPulloutreq', component: SearchOutletPulloutreqComponent },
      { path: 'outletDetailPulloutreq', component: OutletDetailPulloutreqComponent },
      { path: 'pullOutReasonPulloutreq', component: PullOutReasonPulloutreqComponent },
      // { path: 'ltm', component: LtmComponent },
      { path: 'ltm/:FieldName/:FieldValue/:ParaName/:UserID', component: LtmComponent },

      { path: 'outletdetailoif/:UserID/:RequirementType/:ID/:UserType', component: OutletDetailOIFComponent },
      { path: 'pcattendance', component: PcAttendanceComponent },
      { path: 'pcattendance/:FieldName/:FieldValue/:ParaName/:UserID/:selectedperiod', component: PcAttendanceComponent },

      { path: 'tsiattendance', component: TsiAttendanceComponent },
      { path: 'tsiattendance/:FieldName/:FieldValue/:ParaName/:UserID/:selectedperiod', component: TsiAttendanceComponent },
      { path: 'npd', component: NpdComponent },
      { path: 'npdData/:FieldName/:FieldValue/:ParaName/:UserID', component: NpdDataComponent },

      { path: 'frrdlist', component: PcfrRdlistComponent },
      { path: 'frpclist/:UserID/:paracode/:ParaName/:ParaValue', component: FrPClistComponent },
      { path: 'seearning/:FieldName/:FieldValue/:​tagname/:​datatype/:​UserID', component: SeEarningComponent },
      { path: 'seearningdetail/:FieldName/:FieldValue/:​tagname/:​datatype/:​UserID', component: SeearningDetailComponent },


      // { path: 'seearning/:UserID/:paracode/:ParaName/:ParaValue', component: SeEarningComponent },
      { path: 'pc/tsi', component: PcTSIComponent },
      { path: 'missingVisicooler', component: MissingVisicoolerComponent },
      { path: 'missingVisicoolerOutletDetail', component: MissingVisicoolerOutletDetailComponent },
      { path: 'missingReason', component: MissingReasonComponent },
      { path: 'scanQRCode', component: ScanQRCodeComponent },
      { path: 'complaints', component: ComplaintsComponent },
      { path: 'complaintlist', component: ComplaintsListComponent },
      { path: 'addcomplaint', component: AddComplaintComponent },
      { path: 'searchcomplaint', component: SearchOutletComplaintComponent },
      { path: 'outletdetailcomplaint', component: OutletdetailComplaintComponent },
      { path: 'UrjaaVP', component: UrjaaVPComponent },
      { path: 'faq', component: FaqComponent },
      { path: 'pctsidetails/:​UserID/:RDCode/:PCCode', component: PcTSIDetailsComponent },
      { path: 'facialrecognition', component: FacialrecognitionComponent },
      { path: 'facialrecdetails', component: FacialrecdetailsComponent },
      { path: 'nonMeranetOutletdetail', component: NonMeranetOutletdetailComponent },
      { path: 'GateMeetinglist', component: GateMeetinglistComponent },
      { path: 'GateMeetingnew/:MeetingID', component: GateMeetingnewComponent },
      { path: 'facialRecognitionApproval', component: FacialRecognitionApprovalComponent },
      { path: 'facialRecDetailsApproval', component: FacialRecDetailsApprovalComponent },
      { path: 'facialRecRejReasonApproval', component: FacialRecRejReasonApprovalComponent },
      { path: 'stockInoutApproval', component: StockInoutApprovalComponent },
      { path: 'stockInoutDetailsApproval', component: StockInoutDetailsApprovalComponent },
      { path: 'stockInoutSelectrdApproval', component: StockInoutSelectrdApprovalComponent },
      { path: 'stockInoutVoucherDetailsApproval', component: StockInoutVoucherDetailsApprovalComponent },
      { path: 'rlaAutoClosureApproval', component: RlaAutoClosureApprovalComponent },
      { path: 'rlaAutoClosureDetailsApproval', component: RlaAutoClosureDetailsApprovalComponent },
      { path: 'rlaAutoClosureSelectReasonApproval', component: RlaAutoClosureSelectReasonApprovalComponent },
      { path: 'claimApproval', component: ClaimApprovalComponent },
      { path: 'claimDetailsApproval', component: ClaimDetailsApprovalComponent },
      { path: 'claimDetailsTwoApproval', component: ClaimDetailsTwoApprovalComponent },
      { path: 'claimDetailsEditApproval', component: ClaimDetailsEditApprovalComponent },
      { path: 'fssaiApproval', component: FssaiApprovalComponent },
      { path: 'fssaiDetailsApproval', component: FssaiDetailsApprovalComponent },
      { path: 'erodsApproval', component: ErodsApprovalComponent },
      { path: 'erodsVouchersListApproval', component: ErodsVouchersListApprovalComponent },
      { path: 'erodsVoucherApproval', component: ErodsVoucherApprovalComponent },
      { path: 'erodsVoucherDetailsApproval', component: ErodsVoucherDetailsApprovalComponent },
      { path: 'erodsSelectReasonApproval', component: ErodsSelectReasonApprovalComponent },
      { path: 'PccrRdlist', component: PccrRdlistComponent },
      { path: 'PccreationSetting', component: PccreationSettingComponent },
      { path: 'PccreationEdit', component: PccreationEditComponent },
      { path: 'villageProductivityData/:FieldName/:FieldValue/:ParaName/:UserID/:selectedperiod/:selectedOutletRange/:selectedpopslab/:selectedbillRange', component: VillageProductivityComponent },
      { path: 'urjaaVCScan/:FieldName/:FieldValue/:TagName/:DataType/:UserID/:selectedpopslab', component: UrjaaVCScanComponent },
      { path: 'urjaaEffectiveCoverageData/:FieldName/:FieldValue/:ParaName/:UserID/:selectedperiod', component: UrjaaEffectiveCoverageDataComponent },
      { path: 'dmsData/:FieldName/:FieldValue/:ParaName/:UserID/:selectedperiod/:tagname', component: DmsDataComponent },
      { path: 'urjaaUlb/:FieldName/:FieldValue/:ParaName/:UserID', component: UrjaaUlbComponent },
      { path: 'pcDeviceTagging', component: PcDeviceTaggingComponent },
      { path: 'pcDeviceTaggingSelectPc', component: PcDeviceTaggingSelectPcComponent },
      { path: 'pcDeviceTaggingDeviceId', component: PcDeviceTaggingDeviceIdComponent },
      { path: 'HealthstatusRpt/:FieldName/:FieldValue/:ParaName/:UserID', component: HealthstatusRptComponent },
      { path: 'coldChainAudit', component: ColdChainAuditComponent },
      { path: 'coldChainAuditDetails', component: ColdChainAuditDetailsComponent },
      { path: 'PrimaryInvoices/:FieldName/:FieldValue/:UserID', component: PrimaryInvoicesComponent },
      { path: 'Feedback', component: FeedbackComponent },
      { path: 'Media', component: MediaComponent },
      { path: 'kpiProductivity/:FieldName/:FieldValue/:TagName/:DataType/:UserID/:selectedperiod', component: KpiProductivityComponent },
      { path: 'kpiProductivityData/:FieldName/:FieldValue/:TagName/:DataType/:UserID/:selectedperiod', component: KpiProductivityDataComponent },
      { path: 'kpiProductivityDaywise/:FieldName/:FieldValue', component: KpiProductivityDaywiseComponent },
      { path: 'subRdApproval', component: SubRdApprovalComponent },
      { path: 'surveyApproval', component: SurveyApprovalComponent },
      { path: 'surveyApprovalDetails', component: SurveyApprovalDetailsComponent },
      { path: 'subRdApprovalDetails', component: SubRdApprovalDetailsComponent },
      { path: 'subRdApprovalDetailsEdit', component: SubRdApprovalDetailsEditComponent },
      { path: 'subRdApprovalSelectMarket', component: SubRdApprovalSelectMarketComponent },
      { path: 'subRdApprovalSelectRdType', component: SubRdApprovalSelectRdTypeComponent },
      { path: 'subRdApprovalSelectOldRd', component: SubRdApprovalSelectOldRdComponent },
      { path: 'certificate', component: CertificateComponent },
      { path: 'assetApproval/:paracode/:paraname/:paravalue/:UserID', component: AssetApprovalComponent },
      { path: 'otherApplications', component: OtherApplicationsComponent },
      { path: 'mtafhapproval/:ID/:Flag/:UserID', component: MTAFHApprovalComponent },
      { path: 'mtafhdetails/:ID/:UserID', component: MTAFHApprovaldetailsComponent },
      { path: 'a2bTransfer', component: A2bTransferComponent },
      { path: 'VCDeploymentStatus', component: CheckPhotoOfOutletAndAgreementComponent },
      { path: 'OutletDetails', component: OutletDetailsComponent },
      { path: 'QRCodeHistory', component: QRCodeHistoryComponent },
      { path: 'QrCodeOutletDetails', component: QrCodeOutletDetailsComponent },
      { path: 'EligibleOutletSerach', component: EligibleOutletSerachComponent },
      { path: 'MarketWorking', component: MarketWorkingComponent },
      { path: 'MarketWorkingRdListData', component: MarketWorkingRdListDataComponent },
      { path:'MarketWorkingPcList',component:MarketWorkingPcListComponent},
      { path:'MarketWorkingPcListData',component:MarketWorkingPcListDataComponent},
      { path:'MarketWorkingBeatList',component:MarketWorkingBeatListComponent},
      { path:'MarketWorkingBeatListData',component:MarketWorkingBeatListDataComponent},
      {path:'MarketWorkingOutletList',component:MarketWorkingOutletListComponent},
      {path:'MarketWorkingOutletListData',component:MarketWorkingOutletListDataComponent},
      {path:'MarketWorkingOutletLocation',component:MarketWorkingOutletLocationDetailsComponent},
      {path:'outletNameChangeRequest',component:OutletNameChangeRequestComponent},
      {path:'outletNameChangeRequestSendOtp',component:OutletNameChangeRequestSendOtpComponent},
      {path:'outletNameChangeRequestVerifyOtp',component:OutletNameChangeRequestVerifyOtpComponent},
      {path:'outletNameChangeRequestVerifyOtpConfirmation',component:OutletNameChangedVerifiedOtpConfirmationComponent},
      {path:'OutletNameChangeApprovalList',component:OutletNameChangeApprovalListComponent},
      {path:'OutletNameChangeApprovalListDetails',component:OutletNameChangeApprovalListDetailsComponent},
      {path:'ScanQrCodeInTransaction',component:ScanQrCodeInTransactionComponent},
      {path:'ScanQrCodeDataInTransaction',component:ScanQrCodeDataInTransactionComponent},
      {path:'ScanQrCodeMatchInTransaction',component:ScanQrCodeMatchInTransactionComponent},

      
      
    ]
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
