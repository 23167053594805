import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { InputPara } from 'src/app/models/kpi-models';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
// import * as Highcharts from "highcharts";
// import Gauge from 'highcharts/modules/solid-gauge';
// import More from 'highcharts/highcharts-more';
// More(Highcharts);
// Gauge(Highcharts)

@Component({
  selector: 'app-market-working-rd-list-data',
  templateUrl: './market-working-rd-list-data.component.html',
  styleUrls: ['./market-working-rd-list-data.component.css']
})
export class MarketWorkingRdListDataComponent implements OnInit {
  deviceType: any;
  device: any;
  userType: string | null = '';
  userId: any;
  list: any;
  filteredList: any;
  firMenuCountList: any;
  primarynos: any;
  secondarynos: any;
  token: any;
  rdListData: any;
  panelOpenState: boolean = true;

  iCurrentSpeed = 0;
  job: any = null;
  jumpNumber: any = 0;
  iTickToPrint = 0;
  value: any = 0;

  input: InputPara = new InputPara();
  ParaName: any = "rdincentive";
  FieldName: any = "rd";
  EarningTrend: any;
  GrowthOverLYSM: any;
  GrowthOverLYSMUnit: any;
  rdGrowData: any;
  UlbList: any;
  curYear: any;
  curMonth: any;
  prevYearTwoDigit: any;
  currYearTwoDigit: any;
  prevYear: string = '';
  myDate: any;
  scaleRange: any = [
    {
      from: 0,
      to: 660,
      color: "#DCABFF",
    }
  ];
  pointers: any = [
    {
      value: 0,
      color: "#FFFFFF",
      length: 0.75,
    },
  ];

  title = 'dummy';
  public tooltip: any;
  public rangeSize = 15;
  public ticksColor = 'rgba(252, 252, 255, 0.98)'
  public startAngle = -40;
  public endAngle = 220;
  public maxValue = 0;
  public majorValue = 0;
  public minorValue = 0;
  public showSpeedometer: any = false;
  public PCOutletCount: any = [];
  public getOutletSalesParameter: any = [];
  public getOutletNonSalesParameter: any = [];
  public previousPeriod: any;
  public previousPreviousPeriod: any;
  public currentPeriod: any;
   public day=1; //live
  //  public day=40; //dev
  formname:any;
  actiondesc:any;

  constructor(private router: Router, private headerService: HeaderService,private cs:CommonUtilitiesService,  private mds: MongoDataService, private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.panelOpenState = true;
    this.headerService.setTitle('Market Ka Saathi');
    this.headerService.setActiveURl('/marketWorkingRdListData');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.userType = localStorage.getItem("UserType");
    this.userId = localStorage.getItem("UserID");
    this.token = localStorage.getItem("TokenNo");
    this.showSpeedometer = false;
    let rdListData: any = localStorage.getItem("marketWorkingRdListData");
    this.rdListData = JSON.parse(rdListData);
    console.log("this.rdListData", this.rdListData)
    this.value = this.rdListData.ach;
    setTimeout(() => {
      // this.scaleRange = [
      //   {
      //     from: this.rdListData.tgt > this.rdListData.ach ? this.rdListData.tgt : this.rdListData.ach,
      //     to: 660,
      //     color: "#DCABFF",
      //   },
      //   {
      //     from: 0,
      //     to: this.rdListData.tgt,
      //     color: "#e6c4ff",
      //   },
      //   {
      //     from: 0,
      //     to: this.rdListData.ach,
      //     color: "#EAAB5E",
      //   }
      // ]

      this.scaleRange = [
        {
          from: this.rdListData.tgt > this.rdListData.ach ? this.rdListData.tgt : this.rdListData.ach,
          to: this.rdListData.tgt > this.rdListData.ach ? (Math.ceil(this.rdListData.tgt) + 10) : (Math.ceil(this.rdListData.ach) + 10),
          color: "#DCABFF",
        },
        {
          from: 0,
          to: this.rdListData.tgt,
          color: "#e6c4ff",
        },
        {
          from: 0,
          to: this.rdListData.ach,
          color: "#EAAB5E",
        }
      ]
      console.log("this.scaleRange", this.scaleRange);

      this.maxValue = this.rdListData.tgt > this.rdListData.ach ? (Math.ceil(this.rdListData.tgt) + 10) : (Math.ceil(this.rdListData.ach) + 10);
      this.majorValue = (this.maxValue % 10);
      if (this.majorValue > 0) {
        this.maxValue = this.maxValue - this.majorValue;
      }
      this.majorValue = this.maxValue / 10;
      this.minorValue = this.majorValue / 10;
      this.minorValue = this.majorValue / 10;


      this.pointers = [
        {
          value: this.rdListData.ach,
          color: "#FFFFFF",
          length: 0.75,
        },
      ];
      this.showSpeedometer = false;
    }, 1000)

    // this.myDate = Date.now();
    this.curMonth = this.datePipe.transform(this.myDate, 'MMM');
    this.curYear = JSON.stringify(new Date().getFullYear());
    this.prevYear = JSON.stringify(new Date().getFullYear() - 1);
    // this.prevYearTwoDigit = this.prevYear.substring(2, 4);
    // this.currYearTwoDigit = JSON.stringify(new Date().getFullYear()).substring(2, 4);
    this.input.FieldValue = this.rdListData.rdcode;
    this.formname="MarketWorkingRdListData";
    this.actiondesc="Market Working Rd List Data"+" "+this.rdListData.rdcode;
    this.cs.GetGeoLocation(this.userId,this.userType,this.formname,this.actiondesc,this.FieldName,this.input.FieldValue);
    this.loadCounts();
    this.loadULB();
  }

  loadCounts() {
    let request = {
      "FieldName": "rdcode",
      "FieldValue": this.rdListData.rdcode.toString(),
      "Datatype": "",
      "TagName": "",
      "UserID": this.userId
    }

    this.mds.MarketWorking_GetPCOutletCount(request, this.token, this.device, this.deviceType, 'Market Ka Sathi Get PC Outlet Count Of Selected RD').subscribe(res => {
      this.PCOutletCount = res;
      console.log("PCOutletCount", this.PCOutletCount)
      this.showSpeedometer = true
    });
    debugger
    this.loadData();
  }

  loadData(): void {
    this.input.FieldName = this.FieldName;
    this.input.FieldValue = this.rdListData.rdcode.toString();
    this.input.TagName = this.ParaName;
    this.input.Datatype = this.ParaName;
    this.input.UserID = this.userId;
    this.mds.getEarningTrend(this.input, this.token, this.device, this.deviceType, 'Market Ka Sathi Get Earning Trends Of Selected RD').subscribe(res => {
      this.EarningTrend = [];
      console.log("EarningTrend", res)
      let todaysDate = new Date();
      for (let i = 1; i <= 6; i++) {
        todaysDate.setMonth(todaysDate.getMonth() - 1);
        let previous6MonthData = this.datePipe.transform(todaysDate, 'MMM') + ' ' + todaysDate.getFullYear();
        res.forEach((item: any) => {
          if (item['fieldvalue'] == previous6MonthData) {
            this.EarningTrend.push(item)
            console.log("EarningTrend****", this.EarningTrend)
          }
        });
      }
      console.log("EarningTrend", this.EarningTrend)
    });

    let todaysDate = new Date();
    if (todaysDate.getDate() <= this.day) {
      this.input.Datatype = "lmonth";
    } else {
      this.input.Datatype = "month";
    }
    this.input.FieldName = this.FieldName;
    this.input.FieldValue = this.rdListData.rdcode.toString();
    this.input.TagName = this.ParaName;
    this.input.UserID = this.userId;
    this.mds.MarketWorking_GetGrowthOverLYSM(this.input, this.token, this.device, this.deviceType, 'Market Ka Sathi Get Growth Over LYSM Of Selected RD').subscribe(res => {
      this.GrowthOverLYSM = res;
      this.GrowthOverLYSMUnit = res[0].unit;
      let todaysDate = new Date();
      if (todaysDate.getDate() <= this.day) {
        todaysDate.setDate(todaysDate.getDate() - this.day);
        this.previousPeriod = this.datePipe.transform(todaysDate, 'MMM') + ' ' + JSON.stringify(todaysDate.getFullYear()).substring(2, 4);
        this.previousPreviousPeriod = this.datePipe.transform(todaysDate, 'MMM') + ' ' + JSON.stringify(todaysDate.getFullYear() - 1).substring(2, 4)
        //   let previousPeriod = this.datePipe.transform(todaysDate, 'MMM') + ' ' + todaysDate.getFullYear();
        //   this.GrowthOverLYSM = res.filter((singleItem: { [x: string]: string; }) => (singleItem['period'] == previousPeriod));
      }
      else {
        this.previousPeriod = this.datePipe.transform(todaysDate, 'MMM') + ' ' + JSON.stringify(todaysDate.getFullYear()).substring(2, 4);
        this.previousPreviousPeriod = this.datePipe.transform(todaysDate, 'MMM') + ' ' + JSON.stringify(todaysDate.getFullYear() - 1).substring(2, 4)
        //   let currentPeriod = this.datePipe.transform(todaysDate, 'MMM') + ' ' + todaysDate.getFullYear();
        //   this.GrowthOverLYSM = res.filter((singleItem: { [x: string]: string; }) => (singleItem['period'] == currentPeriod));
      }
      console.log("GrowthOverLYSM", this.GrowthOverLYSM)
    });
    this.mds.GetRDGrow(this.rdListData.rdcode.toString(), this.userId, this.token, this.device, this.deviceType, 'Market Ka Sathi Get Grow Performance Of Selected RD').subscribe(res => {
      this.rdGrowData = res;
      console.log("rdGrowData", this.rdGrowData)
      let todaysDate = new Date();
      if (todaysDate.getDate() <= this.day) {
        todaysDate.setDate(todaysDate.getDate() - this.day);
        let previousPeriod = this.datePipe.transform(todaysDate, 'MMM') + ' ' + todaysDate.getFullYear();
        this.rdGrowData = res.filter((singleItem: { [x: string]: string; }) => (singleItem['period'] == previousPeriod));
      }
      else {
        let currentPeriod = this.datePipe.transform(todaysDate, 'MMM') + ' ' + todaysDate.getFullYear();
        this.rdGrowData = res.filter((singleItem: { [x: string]: string; }) => (singleItem['period'] == currentPeriod));
      }
      this.rdGrowData.forEach((item: any) => {
        item.tgt = item.tgt.toLowerCase().replace("rla", "").trim();
        item.ach = item.ach.toLowerCase().replace("rla", "").trim();
      })
      console.log("rdGrowData", this.rdGrowData)
    });
  }

  loadULB(): void {
    this.input.FieldName = "RD";
    this.input.FieldValue = this.rdListData.rdcode.toString();
    this.input.TagName = "0";
    this.input.Datatype = "0";
    this.input.UserID = this.userId;
    this.mds.getUlbDashboard(this.input, this.token, this.device, this.deviceType, 'Market Ka Sathi Get ULB Of Selected RD').subscribe(res => {
      console.log("res", res)
      this.UlbList = res;
      console.log("UlbList", this.UlbList)
      let todaysDate = new Date();
      if (todaysDate.getDate() <= this.day) {
        todaysDate.setDate(todaysDate.getDate() - this.day);
        this.previousPeriod = this.datePipe.transform(todaysDate, 'MMM') + ' ' + JSON.stringify(todaysDate.getFullYear()).substring(2, 4);
        this.previousPreviousPeriod = this.datePipe.transform(todaysDate, 'MMM') + ' ' + JSON.stringify(todaysDate.getFullYear() - 1).substring(2, 4)
      }
      else {
        this.previousPeriod = this.datePipe.transform(todaysDate, 'MMM') + ' ' + JSON.stringify(todaysDate.getFullYear()).substring(2, 4);
        this.previousPreviousPeriod = this.datePipe.transform(todaysDate, 'MMM') + ' ' + JSON.stringify(todaysDate.getFullYear() - 1).substring(2, 4)
      }
      console.log("UlbList", this.UlbList)
    });
    this.MarketWorking_GetOutletSalesParameter();
  }

  MarketWorking_GetOutletSalesParameter() {
    let request =
    {
      "FieldName": "RD",
      "FieldValue": this.rdListData.rdcode.toString(),
      "Datatype": "",
      "TagName": "",
      "UserID": this.userId
    }
    this.mds.MarketWorking_GetOutletSalesParameter(request, this.token, this.device, this.deviceType, 'Market Ka Sathi Get Sales Parameter Of Selected RD').subscribe(res => {
      console.log("res", res)
      this.getOutletSalesParameter = res;
      console.log("getOutletSalesParameter", this.getOutletSalesParameter)
    //  this.getOutletSalesParameter.forEach((item:any)=>{
    //   if(item.l3m<0 || item.lm<0){
    //     item.l3m=0;
    //     item.lm=0;
    //   }
    //  })
     console.log("getOutletSalesParameter", this.getOutletSalesParameter)

    });

    this.MarketWorking_GetRDNonSalesParameter();
  }

  MarketWorking_GetRDNonSalesParameter() {

    let request =
    {
      "FieldName": "rd",
      "FieldValue": this.rdListData.rdcode.toString(),
      "Datatype": "",
      "TagName": "",
      "UserID": this.userId
    }

    this.mds.MarketWorking_GetRDNonSalesParameter(request, this.token, this.device, this.deviceType, 'Market Ka Sathi Get Non Sales Parameter Of Selected RD').subscribe(res => {
      console.log("res", res)
      this.getOutletNonSalesParameter = res;
      console.log("getOutletNonSalesParameter", this.getOutletNonSalesParameter)
    });
  }
  clickOnViewPC() {
    this.router.navigate(['/home/MarketWorkingPcList'])
  }


  public degToRad(angle: any) {
    return ((angle * Math.PI) / 180);
  }

  public radToDeg(angle: any) {

    return ((angle * 180) / Math.PI);
  }

  public drawLine(options: any, line: any) {
    options.ctx.beginPath();
    options.ctx.globalAlpha = line.alpha;
    options.ctx.lineWidth = line.lineWidth;
    options.ctx.fillStyle = line.fillStyle;
    options.ctx.strokeStyle = line.fillStyle;
    options.ctx.moveTo(line.from.X,
      line.from.Y);

    // Plot the line
    options.ctx.lineTo(
      line.to.X,
      line.to.Y
    );

    options.ctx.stroke();
  }

  public createLine(fromX: any, fromY: any, toX: any, toY: any, fillStyle: any, lineWidth: any, alpha: any) {
    // Create a line object using Javascript object notation
    return {
      from: {
        X: fromX,
        Y: fromY
      },
      to: {
        X: toX,
        Y: toY
      },
      fillStyle: fillStyle,
      lineWidth: lineWidth,
      alpha: alpha
    };
  }

  public drawBackground(options: any) {
    let i = 0;

    options.ctx.globalAlpha = 0.2;
    // options.ctx.fillStyle = "rgb(0,0,0)";

    // Draw semi-transparent circles
    for (i = 170; i < 180; i++) {
      options.ctx.beginPath();

      // options.ctx.arc(options.center.X,
      //   options.center.Y,
      //   i,
      //   0,
      //   Math.PI,
      //   true);

      options.ctx.fill();
    }

  }

  public applyDefaultContextSettings(options: any) {
    options.ctx.lineWidth = 2;
    options.ctx.globalAlpha = 0.5;
    options.ctx.strokeStyle = "rgb(255, 255, 255)";
    options.ctx.fillStyle = 'rgb(255,255,255)';
  }

  public drawTicks(options: any) {
    /* Two tick in the coloured arc!
     * Small ticks every 5
     * Large ticks every 10
     */
    this.drawSmallTickMarks(options);
    this.drawLargeTickMarks(options);
  }
  public drawSmallTickMarks(options: any) {
    let tickvalue = options.levelRadius - 8,
      iTick = 0,
      gaugeOptions = options.gaugeOptions,
      iTickRad = 0,
      onArchX,
      onArchY,
      innerTickX,
      innerTickY,
      fromX,
      fromY,
      line,
      toX,
      toY;

    this.applyDefaultContextSettings(options);

    // Tick every 20 degrees (small ticks)
    for (iTick = 10; iTick < 180; iTick += 20) {

      iTickRad = this.degToRad(iTick);

      onArchX = gaugeOptions.radius - (Math.cos(iTickRad) * tickvalue);
      onArchY = gaugeOptions.radius - (Math.sin(iTickRad) * tickvalue);
      innerTickX = gaugeOptions.radius - (Math.cos(iTickRad) * gaugeOptions.radius);
      innerTickY = gaugeOptions.radius - (Math.sin(iTickRad) * gaugeOptions.radius);

      fromX = (options.center.X - gaugeOptions.radius) + onArchX;
      fromY = (gaugeOptions.center.Y - gaugeOptions.radius) + onArchY;
      toX = (options.center.X - gaugeOptions.radius) + innerTickX;
      toY = (gaugeOptions.center.Y - gaugeOptions.radius) + innerTickY;

      // Create a line expressed in JSON
      line = this.createLine(fromX, fromY, toX, toY, "rgb(127,127,127)", 1, 0.6);

      // Draw the line
      this.drawLine(options, line);

    }
  }

  public drawLargeTickMarks(options: any) {
    let tickvalue = options.levelRadius - 8,
      iTick = 0,
      gaugeOptions = options.gaugeOptions,
      iTickRad = 0,
      innerTickY,
      innerTickX,
      onArchX,
      onArchY,
      fromX,
      fromY,
      toX,
      toY,
      line;

    this.applyDefaultContextSettings(options);

    tickvalue = options.levelRadius - 2;

    // 10 units (major ticks)
    for (iTick = 20; iTick < 180; iTick += 20) {

      iTickRad = this.degToRad(iTick);

      onArchX = gaugeOptions.radius - (Math.cos(iTickRad) * tickvalue);
      onArchY = gaugeOptions.radius - (Math.sin(iTickRad) * tickvalue);
      innerTickX = gaugeOptions.radius - (Math.cos(iTickRad) * gaugeOptions.radius);
      innerTickY = gaugeOptions.radius - (Math.sin(iTickRad) * gaugeOptions.radius);

      fromX = (options.center.X - gaugeOptions.radius) + onArchX;
      fromY = (gaugeOptions.center.Y - gaugeOptions.radius) + onArchY;
      toX = (options.center.X - gaugeOptions.radius) + innerTickX;
      toY = (gaugeOptions.center.Y - gaugeOptions.radius) + innerTickY;

      // Create a line expressed in JSON
      line = this.createLine(fromX, fromY, toX, toY, "rgb(127,127,127)", 1, 0.6);

      // Draw the line
      this.drawLine(options, line);
    }
  }


  public drawTextMarkers(options: any) {
    let innerTickX = 0,
      innerTickY = 0,
      iTick = 0,
      gaugeOptions = options.gaugeOptions;
    this.iTickToPrint = 0;

    this.applyDefaultContextSettings(options);


    // Font styling
    options.ctx.font = '12px Arial Black';
    options.ctx.textBaseline = 'top';
    options.ctx.fillStyle = "white"

    options.ctx.beginPath();

    // Tick every 20 (small ticks)
    for (iTick = 10; iTick < 180; iTick += 20) {

      innerTickX = gaugeOptions.radius - (Math.cos(this.degToRad(iTick)) * gaugeOptions.radius);
      innerTickY = gaugeOptions.radius - (Math.sin(this.degToRad(iTick)) * gaugeOptions.radius);

      // Some cludging to center the values (TODO: Improve)
      if (iTick <= 10) {
        options.ctx.fillText(this.iTickToPrint, (options.center.X - gaugeOptions.radius - 12) + innerTickX,
          (gaugeOptions.center.Y - gaugeOptions.radius - 12) + innerTickY + 5);
      } else if (iTick < 50) {
        options.ctx.fillText(this.iTickToPrint, (options.center.X - gaugeOptions.radius - 12) + innerTickX - 5,
          (gaugeOptions.center.Y - gaugeOptions.radius - 12) + innerTickY + 5);
      } else if (iTick < 90) {
        options.ctx.fillText(this.iTickToPrint, (options.center.X - gaugeOptions.radius - 12) + innerTickX,
          (gaugeOptions.center.Y - gaugeOptions.radius - 12) + innerTickY);
      } else if (iTick === 90) {
        options.ctx.fillText(this.iTickToPrint, (options.center.X - gaugeOptions.radius - 12) + innerTickX + 4,
          (gaugeOptions.center.Y - gaugeOptions.radius - 12) + innerTickY);
      } else if (iTick < 145) {
        options.ctx.fillText(this.iTickToPrint, (options.center.X - gaugeOptions.radius - 12) + innerTickX + 10,
          (gaugeOptions.center.Y - gaugeOptions.radius - 12) + innerTickY);
      } else {
        options.ctx.fillText(this.iTickToPrint, (options.center.X - gaugeOptions.radius - 12) + innerTickX + 15,
          (gaugeOptions.center.Y - gaugeOptions.radius - 12) + innerTickY + 5);
      }

      this.iTickToPrint += this.jumpNumber;
    }

    options.ctx.stroke();
  }

  public drawSpeedometerPart(options: any, alphaValue: any, strokeStyle: any, startPos: any) {
    options.ctx.beginPath();

    options.ctx.globalAlpha = alphaValue;
    options.ctx.lineWidth = 11;
    options.ctx.strokeStyle = strokeStyle;

    options.ctx.arc(options.center.X,
      options.center.Y,
      options.levelRadius,
      Math.PI + (Math.PI / 360 * startPos),
      0 - (Math.PI / 360 * 10),
      false);

    options.ctx.stroke();
  }

  public drawSpeedometerColourArc(options: any) {
    let startOfGreen = 20,
      endOfGreen = 200,
      endOfOrange = 280;

    this.drawSpeedometerPart(options, 1.0, "rgb(234,171,94)", startOfGreen);
    this.drawSpeedometerPart(options, 0.9, "rgb(233,228,238)", endOfGreen);
    this.drawSpeedometerPart(options, 0.9, "rgb(220,171,255) ", endOfOrange);

  }

  public drawNeedleDial(options: any, alphaValue: any, strokeStyle: any, fillStyle: any) {

    let i = 0;

    options.ctx.globalAlpha = alphaValue;
    options.ctx.lineWidth = 3;
    options.ctx.strokeStyle = strokeStyle;
    options.ctx.fillStyle = "white";


    // Draw several transparent circles with alpha
    for (i = 0; i < 20; i++) {

      options.ctx.beginPath();
      options.ctx.arc(options.center.X,
        options.center.Y,
        i,
        0,
        Math.PI,
        true);

      options.ctx.fill();
      options.ctx.stroke();
    }
  }

  public convertSpeedToAngle(options: any) {
    /* Helper public to convert a speed to the
    * equivelant angle.
    */
    let iSpeed = (options.speed / 10),
      iSpeedAsAngle = ((iSpeed * 20) + 10) % 180;

    // Ensure the angle is within range
    if (iSpeedAsAngle > 180) {
      iSpeedAsAngle = iSpeedAsAngle - 180;
    } else if (iSpeedAsAngle < 0) {
      iSpeedAsAngle = iSpeedAsAngle + 180;
    }

    return iSpeedAsAngle;
  }

  public drawNeedle(options: any) {
    let iSpeedAsAngle = this.convertSpeedToAngle(options),
      iSpeedAsAngleRad = this.degToRad(iSpeedAsAngle),
      gaugeOptions = options.gaugeOptions,
      innerTickX = gaugeOptions.radius - (Math.cos(iSpeedAsAngleRad) * 20),
      innerTickY = gaugeOptions.radius - (Math.sin(iSpeedAsAngleRad) * 20),
      fromX = (options.center.X - gaugeOptions.radius) + innerTickX,
      fromY = (gaugeOptions.center.Y - gaugeOptions.radius) + innerTickY,
      endNeedleX = gaugeOptions.radius - (Math.cos(iSpeedAsAngleRad) * gaugeOptions.radius),
      endNeedleY = gaugeOptions.radius - (Math.sin(iSpeedAsAngleRad) * gaugeOptions.radius),
      toX = (options.center.X - gaugeOptions.radius) + endNeedleX,
      toY = (gaugeOptions.center.Y - gaugeOptions.radius) + endNeedleY,
      line = this.createLine(fromX, fromY, toX, toY, "rgb(255,255,255)", 5, 0.6);
    this.drawLine(options, line);

    // Two circle to draw the dial at the base (give its a nice effect?)
    this.drawNeedleDial(options, 0.6, "rgb(127, 127, 127)", "rgb(255,255,255)");
    this.drawNeedleDial(options, 0.2, "rgb(127, 127, 127)", "rgb(127,127,127)");

  }

  public buildOptionsAsJSON(canvas: any, iSpeed: any) {
    /* Setting for the speedometer
    * Alter these to modify its look and feel
    */

    let centerX = 210,
      centerY = 210,
      radius = 150,
      outerRadius = 200;

    // Create a speedometer object using Javascript object notation
    return {
      ctx: canvas.getContext('2d'),
      speed: iSpeed,
      center: {
        X: centerX,
        Y: centerY
      },
      levelRadius: radius - 10,
      gaugeOptions: {
        center: {
          X: centerX,
          Y: centerY
        },
        radius: radius
      },
      radius: outerRadius
    };
  }

  public clearCanvas(options: any) {
    options.ctx.clearRect(0, 0, 800, 600);
    this.applyDefaultContextSettings(options);
  }

  public draw() {
    let canvas = document.getElementById('speedometer'),
      options = null;

    if (canvas !== null) {
      options = this.buildOptionsAsJSON(canvas, this.iCurrentSpeed);

      // Clear canvas
      this.clearCanvas(options);

      // Draw thw background
      this.drawBackground(options);

      // Draw tick marks
      this.drawTicks(options);

      // Draw labels on markers
      this.drawTextMarkers(options);

      // Draw speeometer colour arc
      this.drawSpeedometerColourArc(options);

      // Draw the needle and base
      this.drawNeedle(options);

    } else {
      alert("Canvas not supported by your browser!");
    }

    this.job = setTimeout("draw()", 5);
  }





}
