
<div class="container-fluid">
    <div class="row mt-1" >
        <div class="col-12">
            <!-- <input class="fullWidth" type="text" name="" id="" placeholder="Search RD name or UID"> -->
            <input class="fullWidth" type="text" placeholder=" Search RD Name Or UID" list="dynmicUserIds" (keyup)="getUserIdsFirstWay($event)" id="userIdFirstWay" (change)="SearchText($event)" autocomplete="off">
            <datalist id="dynmicUserIds">
              <option *ngFor="let item of userList1" [value]="item">{{item}}</option>
            </datalist>
          </div>
    </div>
    <div class="row mt-4">
    <div class="col-9"><span class="thheader">PC List</span></div>
    <div class="col-3"> <span class="thheader2  align-text-bottom">Attendance</span></div>
    </div>
    
    <div class="row" style="margin-top: 1.25rem;" *ngFor ="let cate of pcFrdata">
        <div class="col-9">
           <span class="rdName">{{cate.rdcode}}</span> <br><span class="rdCode">{{cate.code}}</span> 
            <!-- <br>
             <span class="rdtown" style="margin-top: 1rem;">Pune</span> -->

        </div>
        <div class="col-3 text-right">   <span class="frper">{{cate.frPer}}%</span></div>
    </div>
    <hr class="hr_cust">
</div>