import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { DataService } from 'src/app/service/data.service';
import { InputPara } from 'src/app/models/kpi-models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-pc-device-tagging-device-id',
  templateUrl: './pc-device-tagging-device-id.component.html',
  styleUrls: ['./pc-device-tagging-device-id.component.css']
})
export class PcDeviceTaggingDeviceIdComponent implements OnInit {
  @ViewChild('openModalThankYou', { static: true }) elRefOpenModalThankYou: ElementRef | undefined;
  deviceType: any;
  device: any;
  userType: string | null = '';
  userId: any;
  fieldName: any;
  fieldValue: any;
  deviceTaggingSelectPcDetails: any;
  deviceId: string = '';
  deviceTaggingDetails: any;
  token: any;
  formname: any;
  actiondesc: any;


  constructor(private activatedRoute: ActivatedRoute, private cs: CommonUtilitiesService, private router: Router, private headerService: HeaderService, private mds: MongoDataService, private notifyMeService: NotifyMeService, private ds: DataService, private modalService: NgbModal, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.headerService.setTitle('PC/TSI Device Tagging');
    this.headerService.setActiveURl('/pcDeviceTaggingDeviceId');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.token = localStorage.getItem("TokenNo");
    this.userType = localStorage.getItem("UserType");
    this.userId = localStorage.getItem("UserID");
    this.fieldName = localStorage.getItem("FieldName");
    this.fieldValue = localStorage.getItem("FieldValue");
    this.deviceTaggingSelectPcDetails = JSON.parse(localStorage.getItem("DeviceTaggingSelectPcDetails") || '{}');
    this.deviceTaggingDetails = JSON.parse(localStorage.getItem("DeviceTaggingDetails") || '{}');

    this.formname = "pcDeviceTaggingDeviceId";
    this.actiondesc = "Pc Device Tagging Device Id";
    this.cs.GetGeoLocation(this.userId, this.userType, this.formname, this.actiondesc, this.fieldName, this.fieldValue);
  }

  cancel() {
    this.router.navigate(['/home/transactions']);
  }

  update() {
    this.ds.pcDeviceTagging(this.deviceTaggingDetails.rdcode, this.deviceTaggingSelectPcDetails.pcuid, this.deviceId, this.token, this.device, this.deviceType, 'pcDeviceTaggingDeviceId').subscribe(res => {
      console.log("pcDeviceTagging", res)
      // this.router.navigate(['/home/transactions']);
      if (res[0].msg.toLowerCase() == "ok") {
        this.openModal(this.elRefOpenModalThankYou);
      } else {
        this.notifyMeService.showWarning(res[0].msg,"");
      }
    });
  }

  openModal(modal: ElementRef<any> | undefined) {
    this.modalService.open(modal,
      {
        size: 'lg',
        backdrop: 'static',
        windowClass: '',
        keyboard: false,
        centered: true,
      })
  }

  okay() {
    this.modalService.dismissAll();
    this.router.navigate(['/home/transactions']);
  }

  modalClose() {
    this.modalService.dismissAll();
    this.router.navigate(['/home/transactions']);
  }

}
