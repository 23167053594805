<div class="row mt-3">
    <div class="col-12">
        <mat-accordion>
            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header class="acctitle">
                    <mat-panel-title>
                        <span>Title-Text</span>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row mt-2 acctitle">
                    <span>This is the first item's accordion body. It is hidden by default, until the collapse plugin
                        adds the appropriate classes that we use to style each element. These classes control the
                        overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of
                        this with custom CSS or overriding our default variables. It's also worth noting that just about
                        any HTML can go within the .accordion-body, though the transition does limit overflow.</span>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header class="acctitle">
                    <mat-panel-title>
                        <span>Title-Text</span>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row mt-2 acctitle">
                    <span>This is the first item's accordion body. It is hidden by default, until the collapse plugin
                        adds the appropriate classes that we use to style each element. These classes control the
                        overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of
                        this with custom CSS or overriding our default variables. It's also worth noting that just about
                        any HTML can go within the .accordion-body, though the transition does limit overflow.</span>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header class="acctitle">
                    <mat-panel-title>
                        <span>Title-Text</span>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row mt-2 acctitle">
                    <span>This is the first item's accordion body. It is hidden by default, until the collapse plugin
                        adds the appropriate classes that we use to style each element. These classes control the
                        overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of
                        this with custom CSS or overriding our default variables. It's also worth noting that just about
                        any HTML can go within the .accordion-body, though the transition does limit overflow.</span>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header class="acctitle">
                    <mat-panel-title>
                        <span>Title-Text</span>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row mt-2 acctitle">
                    <span>This is the first item's accordion body. It is hidden by default, until the collapse plugin
                        adds the appropriate classes that we use to style each element. These classes control the
                        overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of
                        this with custom CSS or overriding our default variables. It's also worth noting that just about
                        any HTML can go within the .accordion-body, though the transition does limit overflow.</span>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>