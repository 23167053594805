import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { InputPara } from 'src/app/models/kpi-models';
import { EncryptionService } from '../../service/encryption.service';
import { DataService } from '../../service/data.service';
import { MatDialog } from '@angular/material/dialog';
import { FilterComponent } from '../filter/filter.component';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';
import { NotifyMeService } from 'src/app/service/notify-me.service';

@Component({
  selector: 'app-se-earning',
  templateUrl: './se-earning.component.html',
  styleUrls: ['./se-earning.component.css']
})
export class SeEarningComponent implements OnInit {
  FieldName: any;
  FieldValue: any;
  paracode: any;
  UserID: any;
  periodList: any[] = [];
  selectedperiod: any;
  UserType: any;
  currentDate = new Date();
  pcAttendance: any;
  heading: string = "";
  CurrentDate: any;
  isPC: boolean = false;
  isRD: boolean = false;
  SeEarningData: any;
  FRheading: string = "";
  ParaName: string = "";
  ParaValue: string = "";
  IsIndiaLevel: boolean = false;
  DataType: string | null = "";
  TagName: string | null = "";
  device: any;
  deviceType:any;
  input:InputPara=new InputPara();
  token: any;
  lng:any;
  lat:any;

  constructor(private cs: CommonUtilitiesService, private activatedRoute: ActivatedRoute, private fb: FormBuilder, private es: EncryptionService, private router: Router,
    private headerService: HeaderService, private mds: MongoDataService, private ds: DataService,
     private dialog: MatDialog,private notifyMeService: NotifyMeService) { }

    ngOnInit(): void {
    this.headerService.setActiveURl('/seearning');
    this.headerService.setTitle('SE Earning');
    this.UserType = localStorage.getItem("UserType");
    this.UserID = localStorage.getItem("UserID");
    this.device = localStorage.getItem('Device');
    this.deviceType = localStorage.getItem('DeviceType');
    this.token = localStorage.getItem("TokenNo");
    this.activatedRoute.paramMap.forEach(params => {
      this.FieldName = params.get('FieldName');
      this.FieldValue = params.get('FieldValue');
      this.DataType = params.get('​datatype');
      this.TagName = params.get('​tagname');
      this.GetSEEarningData();
  });
  this.GetGeoLocation();
  }

  GetGeoLocation() {
    debugger
    if (navigator) {
      navigator.geolocation.getCurrentPosition(pos => {
        this.lat = pos.coords.latitude;
        console.log("  this.lat", this.lat)
        this.lng = pos.coords.longitude;
        this.SetapplicationLogData();
      }, this.handleLocationError, { timeout: 30000 });
    }
    else {
      this.notifyMeService.showError('Location Could not be captured', 'Mera APP');
    }
  }

  handleLocationError(error: { code: any; }) {
    switch (error.code) {
      case 3:
        this.notifyMeService.showError('Timeout in Position capture', 'Mera APP V2');
        break;
      case 2:
        this.notifyMeService.showError('Device is not capable of Position capture', 'Mera APP V2');
        break;
      case 1:
        this.notifyMeService.showError('You denied for Position capture', 'Mera APP V2');
        break;
    }
  }

  SetapplicationLogData() {
    debugger
    let request = {
      "userid": this.UserID,
      "usertype": this.UserType,
      "formname": "seearning",
      "actiondesc": "se earning" + " " + this.FieldName,
      "fieldname": this.FieldName,
      "fieldvalue": this.FieldValue,
      "latitude": this.lat,
      "longitude": this.lng
    }
    this.ds.SetapplicationLogData(request).subscribe(res => {
      console.log("res", res)
    })
  }

  GetSEEarningData() {
    this.TagName = "seincentive";
    this.DataType = "detail";
    this.SeEarningData = [];
    this.input.FieldName=this.FieldName;
    this.input.FieldValue=this.FieldValue;
    this.input.TagName=this.TagName;
    this.input.Datatype= this.DataType;
    this.input.UserID=this.UserID

    this.mds.GetSEEarningData(this.input,this.token,this.device,this.deviceType,'SE Earning').subscribe(res => {
      this.SeEarningData = res;
    });
  }
  RowClick(FName: any, ParaName: any) {
    this.FieldName = FName;
    this.FieldValue = ParaName;
    if (this.FieldName == "soterritory") {
      this.router.navigate(['/home/seearningdetail/', this.FieldName, this.FieldValue, this.TagName, this.DataType, this.UserID]);
    }
    else
    {
      this.router.navigate(['/home/seearning/', this.FieldName, this.FieldValue, this.TagName, this.DataType, this.UserID]);
    }
  }

  openFilter() {
    if (this.dialog.openDialogs.length == 0) {
      const dialogRef = this.dialog.open(FilterComponent, {
        closeOnNavigation: true,
        data: {
          "FieldName": localStorage.getItem("OriginalFieldName"),//this.FieldName,
          "FieldValue": localStorage.getItem("OriginalFieldValue"),// this.FieldValue,
          "UserID": this.UserID,
          "MenuUpTo": "4",
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.FieldName = result.FieldName;
          this.FieldValue = result.FieldValue;

          if (this.FieldName.toUpperCase() == "SOTERRITORY") {
            this.router.navigate(['/home/seearningdetail/', this.FieldName, this.FieldValue, this.TagName, this.DataType, this.UserID]);
          }
          else {
          this.GetSEEarningData();
          }
        }
      });
    }
  }
}
