import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {
  @ViewChild('openModalThankYou', { static: true }) elRefOpenModalThankYou: ElementRef | undefined;
  feedbackForm = this.fb.group({
    feedback: ['', [Validators.required]],
  });
  deviceType: any;
  device: any;
  List:any;
  UserID: any;
  EmailID:any;
  token: any;
  formname:any;
  actiondesc:any;
  fieldName: any;
  fieldValue: any;
  userType:any;
  constructor(private fb:FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private headerService: HeaderService,
    private mds: MongoDataService,
    private modalService: NgbModal,
    private notifyMeService: NotifyMeService,
    private cs:CommonUtilitiesService) { }

  ngOnInit(): void {
    this.headerService.setTitle('Feedback');
    this.headerService.setActiveURl('/Feedback');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.UserID = localStorage.getItem("UserID");
    this.EmailID = localStorage.getItem("EmailID");
    this.token = localStorage.getItem("TokenNo");
    this.userType = localStorage.getItem("UserType");
    this.fieldName = localStorage.getItem("FieldName");
    this.fieldValue = localStorage.getItem("FieldValue");
    this.formname="Feedback";
    this.actiondesc="Feedback"
    this.cs.GetGeoLocation(this.UserID,this.userType,this.formname,this.actiondesc,this.fieldName,this.fieldValue);
  }
  submit(){
    const today = new Date().toISOString().slice(0, 10)
    let feedbackRemark = this.feedbackForm.value.feedback;
    const dataToSend = {
      ObjectId_id  : "0",
      UserID : this.UserID,
      currentdate : today ,
      currenttime : "",
      feedbackdata : feedbackRemark,
      senderemail :this.EmailID
    };
    this.mds.updateFeedback(dataToSend, this.token, this.device, this.deviceType, 'Feedback').subscribe(res => {
      // this.notifyMeService.showSuccess('Feedback Save Sucessfully', 'Sales Genie App');
      this.openModal(this.elRefOpenModalThankYou);
    });
  }
  Cancel(){
    this.router.navigate(['/home/']);
  }

  openModal(modal: ElementRef<any> | undefined) {
    this.modalService.open(modal,
      {
        size: 'lg',
        backdrop: 'static',
        windowClass: '',
        keyboard: false,
        centered: true,
      })
  }

  okay(){
    this.modalService.dismissAll();
    this.router.navigate(['/home/']);
  }

  modalClose() {
    this.modalService.dismissAll();
    this.router.navigate(['/home/']);
  }

}
