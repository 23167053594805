import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { DataService } from 'src/app/service/data.service';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-cold-chain-audit',
  templateUrl: './cold-chain-audit.component.html',
  styleUrls: ['./cold-chain-audit.component.css']
})
export class ColdChainAuditComponent implements OnInit {
  deviceType: any;
  device: any;
  userType: string | null = '';
  userId: any;
  list: any;
  msg: any;
  private _searchText = '';
  filteredList: any;
  token: any;
  formname:any;
  actiondesc:any;
  fieldName: any;
  fieldValue: any;

  constructor(private activatedRoute: ActivatedRoute, private router: Router,private cs:CommonUtilitiesService,
     private headerService: HeaderService, private mds: MongoDataService, private notifyMeService:NotifyMeService, private ds: DataService,) { }

  ngOnInit(): void {
    this.headerService.setTitle('Cold Chain Audit');
    this.headerService.setActiveURl('/coldChainAudit');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.token = localStorage.getItem("TokenNo");
    this.userType = localStorage.getItem("UserType");
    this.userId = localStorage.getItem("UserID"); 
    this.fieldName = localStorage.getItem("FieldName");
    this.fieldValue = localStorage.getItem("FieldValue");

    this.formname="coldChainAudit";
    this.actiondesc="Cold Chain Audit"
    this.cs.GetGeoLocation(this.userId,this.userType,this.formname,this.actiondesc,this.fieldName,this.fieldValue);

    this.onLoad();
  }

  onLoad() {
    this.mds.getUserDetails(this.userId, this.token, this.device, this.deviceType,'Cold Chain Audit').subscribe(res => {
      this.userType=res[0].userType.toUpperCase();
      this.afterLoad()
    });
  }

  afterLoad() {
    this.ds.getColdchainApprovalDetails(this.userId, this.userType, this.token, this.device, this.deviceType, 'Cold Chain Audit').subscribe(res => {
      this.list = res;
      this.filteredList = this.list;
    });
  }

  rowClick(data: any){
    localStorage.setItem("coldChainAuditDetails",JSON.stringify(data))
    this.router.navigate(['/home/coldChainAuditDetails']);
  }

  get searchTerm(): any{
    return this._searchText;
  }
  set searchTerm(value: any){
     this._searchText = value;
     this.filteredList = this.filteredListMethod(value);
  }

  filteredListMethod(value: any): any {
    return this.list.filter((ele: any) =>(
      ele.name.toUpperCase().indexOf(value.toUpperCase()) !== -1 || ele.code.toUpperCase().indexOf(value.toUpperCase()) !== -1));
  }

}
