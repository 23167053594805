<div>
  <div class="row" style="margin-top: 2rem;">
    <div class="col-6">
      <span class="head_Text">Market</span>
    </div>
    <div class="col-3">
      <span class="head_Text">UID</span>
    </div>
    <div class="col-3">
      <span class="head_Text">Code</span>
    </div>
  </div>
  <div class="row mt-4" *ngFor="let item of marketNameList; let i = index;">
    <div class="col-6">
      <div class="form-check">
        <input type="checkbox" class="form-check-input" value={{item.marketName}} id="Check{{i}}"
          [(ngModel)]="marketNameList[i].checked" (change)="selected = i; selected_Reason($event)" [checked]="i">

        <label class="form-check-label" for="Check{{i}}">
          <span class="rd_Name">{{item.marketName }}</span>
        </label>
      </div>
    </div>
    <div class="col-3">
      <span class="uid_value">{{item.marketUID}}</span>
    </div>
    <div class="col-3">
      <span class="uid_value">{{item.marketCode}}</span>
    </div>
  <div class="col-12  hr_line">&nbsp;</div>
  </div>
</div>
<br><br><br><br><br>
<div class="footer">
  <div class="row text-center mt-3">
    <div class="col-12" (click)="rowClick()">
      SUBMIT
    </div>
  </div>
</div>
