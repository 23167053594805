
<form [formGroup]="PCCreationSettingForm" autocomplete="off">
    <div class="row mt-4 " > 
        <!-- style="overflow: auto;" -->
        <div class="col-12 bg_CMA">
            <div class="row">
                <div class="col-12">&nbsp;</div>
              </div>
              <div class="row">
                <div class="col-12">
                    <span class="rdname" >{{PccreationSDetails.rdname}}&nbsp;&nbsp;<span class="rdcode">{{PccreationSDetails.rdcode}}</span></span>
                </div>
              </div>
               <div class="row mt-2">
                <div class="col-12">
                    <span class="rdstate" >{{PccreationSDetails.town}}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-12">&nbsp;</div>
              </div>
        </div>
        <div class="col-12 mt-2">
            <span class="heading" >For New PC</span>
        </div>
        <div class="col-12 mt-2">
            <div class="form-group">
              <input type="number" id="Additional" placeholder="Additional" class="form-control-plaintext btn-block" formControlName="Additional">
            </div>
        </div>
        <div class="col-12 mt-2">
            <div class="form-group">
              <input type="number" id="Replacement" placeholder="Replacement" class="form-control-plaintext btn-block" formControlName="Replacement">
            </div>
        </div>
        <hr class="hr_cust">
        <div class="col-6 ">
            <span class="heading" >For Existing PC</span>
        </div>
        <div class="col-6 text-right">
            <button class="border_rounded btn" (click)="EditPC()">
                Edit PC
              </button>
        </div>
        <hr class="hr_cust">
        <div class="col-12 ">
            <span class="heading" >History</span>
        </div>
        <div class="col-12 mt-3">
            <table class="table tbl_custom" >
              <thead>
                <tr>
                  <td class="td_cust">Date</td>
                  <td class="td_cust">Action</td>
                  <td class="td_cust pr-0 pl-0">No of PC</td>
                  <td class="td_cust pr-0">Utilized</td>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of List;let i = index">
                  <td class="tbl_Val_text">{{data.vdate}}</td>
                  <td class="tbl_Val_text">{{data.action}}</td>
                  <td class="tbl_Val_text text-right">{{data.noofPC}}</td>
                  <td class="tbl_Val_text text-right">{{data.utilized}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        <hr class="hr_cust">
        <br><br><br>
    </div>
    <div class="footer" >
        <div class="row text-center mt-3">
          <div class="col-12" (click)="btnClick()">
            SAVE
          </div>
        </div>
      </div>
    </form>