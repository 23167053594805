<div>
<div class="row mt-2 bg_Color">
  <div class="col-12 mt-2">
<div class="row">
    <div class="col-12 mt-2">
      <span class="pc_Name">{{accountlist.accountName}}</span>
       </div>
    </div>
 
    <div class="row mt-2 bg_Color">
     <div class="col-3 rd_Code">RE</div>
     <div class="col-3 rd_Code">Sub RE</div>
     <div class="col-3 rd_Code">Tag</div>
     <div class="col-3 rd_Code">Proposed Business (pm)</div> 
    </div>
    <div class="row  mt-2 bg_Color">
        <div class="col-3 th_Name">{{accountlist.re}}</div>
       <div class="col-3 th_Name">{{accountlist.subRE}}</div>
        <div class="col-3 th_Name">{{accountlist.tag}}</div>
        <div class="col-3 th_Name"><img class="img-fluid" src="assets/rupee.png" /> {{accountlist.businessValue}}</div> 
       </div>
       <div class="row mt-2 bg_color">
        <div class="col-12 mt-2">
          <span class="rd_Code">Remark</span>        
        </div>
       </div>
       <div class="row mt-2 bg_color">
        <div class="col-12 mt-2 mb-4">
          <span class="th_Name text-wrap">{{accountlist.remark}}</span>        
        </div>
       </div>
      </div>
</div>
       <div class="col-12 mt-3 table-responsive" >
        <table class="table tbl_custom">
          <thead>
            <tr>
              <td class="rd_Code">Heading  (MD to MRP %)</td>
              <td class="rd_Code">Sug margin</td>
              <td class="rd_Code">SE Input</td>
             
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of margindtls;let i = index">
              <td class="th_Name">{{data.heading}}</td>
              <td class="th_Name1">{{data.suggesterdMargin}}%</td>
              <td class="th_Name1">{{data.seInput}}%</td>
             
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row mt-2 bg_Color">
        <div class="col-12 mt-2">
          <div class="row">
            <div class="col-12 mt-2">
            <span class="pc_Name">Approval Status</span>
          </div>
          </div>
                            
<div class="row mt-2">
  <div class="col-6 th_Name1">ASM Approval</div>
  <div class="col-6">   <span class="th_Name1"  [style.color]="accountlist.asmApprovalStatus=='Approved' ? 'Green' :accountlist.asmApprovalStatus=='Pending'?'Black':'Red'">{{accountlist.asmApprovalStatus}}</span>     </div>
                                      
  </div>
  <div class="row mt-2">
  <div class="col-6 th_Name1">First Approval</div>
  <div class="col-6">   <span class="th_Name1"  [style.color]="accountlist.firstApprovalStatus=='Approved' ? 'Green' :accountlist.firstApprovalStatus=='Pending'?'Black':'Red'">{{accountlist.firstApprovalStatus}}</span>     </div>
                          
  </div>
  <div class="row mt-2">
  <div class="col-6 th_Name1">Second Approval</div>
  <div class="col-6">   <span class="th_Name1"  [style.color]="accountlist.secondApprovalStatus=='Approved' ? 'Green' :accountlist.secondApprovalStatus=='Pending'?'Black':'Red'">{{accountlist.secondApprovalStatus}}</span>     </div>
              
  </div>
  
  <div class="row mt-2 mb-2" *ngIf="accountlist.requiredThirdapproval == 'Y'">
  <div class="col-6 th_Name1">Third Approval</div>
  <div class="col-6">   <span class="th_Name1" [style.color]="accountlist.thirdApprovalStatus=='Approved' ? 'Green' :accountlist.thirdApprovalStatus=='Pending'?'Black':'Red'">{{accountlist.thirdApprovalStatus}}</span>     </div>
  
  </div>
               
      </div>
     
      </div>
     
</div>
<br>
<br>
<br>

<br>
      <div class="footer" *ngIf="(UserType=='ASM' && (accountlist.asmApprovalStatus == 'Pending' ||accountlist.asmApprovalStatus == 'Rejected') )|| (UserType=='FIRSTAPPROVAL'&&(accountlist.firstApprovalStatus == 'Pending' ||accountlist.firstApprovalStatus =='Rejected' )) ||(UserType=='SECONDAPPROVAL'&&(accountlist.secondApprovalStatus == 'Pending' ||accountlist.secondApprovalStatus == 'Rejected'))|| (UserType=='THIRDAPPROVAL' &&(accountlist.thirdApprovalStatus == 'Pending'||accountlist.thirdApprovalStatus == 'Rejected'))">
        <div class="row">
          <div class="col-6 pr-0" (click)="reject()">
            <button class="btn btn_REJECT">REJECT</button>
          </div>
          <div class="col-6 pl-0" (click)="approve()">
            <button class="btn btn_APPROVE">APPROVE</button>
          </div>
        </div>
      </div>

      <ng-template #openModalThankYou let-modal>
        <div class="modal-header">
          <h4 class="modal-title text_color_head text_20" id="modal-basic-title">
            <img class="img-fluid" src="assets/thanks_icon.png" />
            <span class="ml-3">Thank You</span>
          </h4>
          <button type="button" class="close" aria-label="Close" (click)="modalClose()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <span class="model_PopUp_Text">MT AFH Account Request Approved Successfully</span>
            </div>
          </div>
          <div class="row mt-4 mb-2">
            <div class="col-12">
              <span class="pc_Name_PopUp">{{accountlist.accountName| titlecase}}</span>
              <span class="ml-2 pc_Code_PopUp">{{accountlist.city}}</span>
            </div>
          </div>
        
        </div>
        <div class="modal-footer" (click)="okay()">
          OKAY
        </div>
      </ng-template>
  