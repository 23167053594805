<div class="row mt-4">
  <div class="col-12 mediaTab">
    <mat-tab-group dynamicHeight mat-align-tabs="center" (selectedTabChange)="onTabChanged($event)">

      <mat-tab label="All" class="tablabel">
        <div class="row mt-4 divcls" *ngFor="let item of notificationlist"
          (click)="NotificationDetails(item, item.id, item.flag,item?.dataFrom)">
          <div class="col-3">
            <img [src]="notificationImageUrl + item.notificationImage" *ngIf="item.notificationImage !=''"
              class="img-fluid img-thumbnail Rounded Corners small"
              onerror="this.onerror=null;this.src='../assets/images/NoBlogImage.jpg'" height="67px" width="67px" />
          </div>
          <div class="col-9">
            <span class="header_text">{{item.notificationHeader}}</span>
            <span class="pull-right">{{item.activeFrom | date : 'dd-MMM'}}</span>
            <br>
            <span class="tmtext">{{item.notificationText}}</span>
            <div class="col-12">
              <span class="Divider">
                <hr />
              </span>
            </div>
          </div>
        </div>
        <br><br><br><br><br>
      </mat-tab>

      <mat-tab label="Notification" class="tablabel">
        <div class="row mt-4 divcls" *ngFor="let item of notificationFilterList"
          (click)="NotificationDetails(item, item.id, item.flag,item?.dataFrom)">
          <div class="col-3">
            <img [src]="notificationImageUrl + item.notificationImage" *ngIf="item.notificationImage !=''"
              class="img-fluid img-thumbnail Rounded Corners small"
              onerror="this.onerror=null;this.src='../assets/images/NoBlogImage.jpg'" height="67px" width="67px" />
          </div>
          <div class="col-9">
            <span class="header_text">{{item.notificationHeader}}</span>
            <span class="pull-right">{{item.activeFrom | date : 'dd-MMM'}}</span>
            <br>
            <span class="tmtext">{{item.notificationText}}</span>
            <div class="col-12">
              <span class="Divider">
                <hr />
              </span>
            </div>
          </div>
        </div>
        <br><br><br><br><br>
      </mat-tab>

      <mat-tab label="Praise" class="tablabel">
        <div class="row mt-4 divcls" *ngFor="let item of praiseList"
          (click)="NotificationDetails(item, item.id, item.flag,item?.dataFrom)">
          <div class="col-3">
            <img [src]="notificationImageUrl + item.notificationImage" *ngIf="item.notificationImage !=''"
              class="img-fluid img-thumbnail Rounded Corners small"
              onerror="this.onerror=null;this.src='../assets/images/NoBlogImage.jpg'" height="67px" width="67px" />
          </div>
          <div class="col-9">
            <span class="header_text">{{item.notificationHeader}}</span>
            <span class="pull-right">{{item.activeFrom | date : 'dd-MMM'}}</span>
            <br>
            <span class="tmtext">{{item.notificationText}}</span>
            <div class="col-12">
              <span class="Divider">
                <hr />
              </span>
            </div>
          </div>
        </div>
        <br><br><br><br><br>
      </mat-tab>

    </mat-tab-group>
  </div>
</div>
