import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { GetGrowKPI, InputPara } from 'src/app/models/kpi-models';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterComponent } from '../filter/filter.component';
import { MatDialog } from '@angular/material/dialog';
import { DataService } from 'src/app/service/data.service';
import { NotifyMeService } from 'src/app/service/notify-me.service';


@Component({
  selector: 'app-urjaa-effective-coverage-data',
  templateUrl: './urjaa-effective-coverage-data.component.html',
  styleUrls: ['./urjaa-effective-coverage-data.component.css']
})
export class UrjaaEffectiveCoverageDataComponent implements OnInit {
  FieldName: any;
  FieldValue: any;
  ParaName: any;
  UserID: any;
  effectiveCoveragedata: any;
  periodList: any[] = [];
  selectedperiod: any;
  IsRDValue: boolean=false;
  rdData: any=null;
  device: any;
  deviceType: any;
input:InputPara=new InputPara();
  token: any ;
  lat:any;
  lng:any;
  UserType:any;

  constructor(private activatedRoute: ActivatedRoute, private dialog: MatDialog,private notifyMeService: NotifyMeService,
    private ds: DataService,
    private fb: FormBuilder,private router: Router, private headerService: HeaderService, private mds: MongoDataService) { }

  ngOnInit(): void {
    this.headerService.setActiveURl('/urjaaEffectiveCoverageData');
    this.headerService.setTitle("Effective Coverage");
    this.token = localStorage.getItem("TokenNo");
    this.UserType = localStorage.getItem("UserType");
    this.activatedRoute.paramMap.forEach(params => {
      this.FieldValue = params.get('FieldValue');
      this.FieldName = params.get('FieldName');
      this.UserID = params.get('UserID');
      this.ParaName = params.get('ParaName');
      this.selectedperiod = params.get('selectedperiod');
      this.effectiveCoveragedata = [];
      this.device = localStorage.getItem("Device");
      this.deviceType = localStorage.getItem("DeviceType");

      // if(this.FieldName=="RD")
      // {
      //   let obj=localStorage.getItem("rdData");
      //   this.rdData = JSON.parse(obj || '{}') as GetGrowKPI;
      //   this.IsRDValue=true;

      // }
      // else{this.IsRDValue=false;}
      this.GetGeoLocation();
      this.loadData();
    });
  }

  
  GetGeoLocation() {
    debugger
    if (navigator) {
      navigator.geolocation.getCurrentPosition(pos => {
        this.lat = pos.coords.latitude;
        console.log("  this.lat", this.lat)
        this.lng = pos.coords.longitude;
        this.SetapplicationLogData();
      }, this.handleLocationError, { timeout: 30000 });
    }
    else {
      this.notifyMeService.showError('Location Could not be captured', 'Mera APP');
    }
  }

  handleLocationError(error: { code: any; }) {
    switch (error.code) {
      case 3:
        this.notifyMeService.showError('Timeout in Position capture', 'Mera APP V2');
        break;
      case 2:
        this.notifyMeService.showError('Device is not capable of Position capture', 'Mera APP V2');
        break;
      case 1:
        this.notifyMeService.showError('You denied for Position capture', 'Mera APP V2');
        break;
    }
  }

  SetapplicationLogData() {
    debugger
    let request = {
      "userid": this.UserID,
      "usertype": this.UserType,
      "formname": "urjaaEffectiveCoverageData",
      "actiondesc": "Urjaa Effective Coverage Data" + " " + this.FieldName,
      "fieldname": this.FieldName,
      "fieldvalue": this.FieldValue,
      "latitude": this.lat,
      "longitude": this.lng
    }
    this.ds.SetapplicationLogData(request).subscribe(res => {
      console.log("res", res)
    })
  }

  loadData(): void {

    this.input.FieldName=this.FieldName;
    this.input.FieldValue=this.FieldValue;
    this.input.TagName='EC';
    this.input.Datatype=this.ParaName;
    this.input.UserID=this.UserID;
    console.log("input",this.input)
    this.mds.GetGrowKPIUrja(this.input, this.token, this.device, this.deviceType,'Urjaa Effective Coverage').subscribe(res => {
      this.effectiveCoveragedata = res as GetGrowKPI;
      this.periodList = [...new Set(res.map((bill: { period: any; }) => bill.period))].sort();
  //    this.selectedperiod = this.periodList[0];
    });
  }
  PeriodChange(e: any): void {
    this.selectedperiod =e.source._value;
  }
  filterItemsOfType(type: any) {
    return type != null ? type.filter((x: { [x: string]: any; }) => (x['period'] == this.selectedperiod)) : null;
  }
  RowClick(fieldName: any, fieldvalue: any): void{
    // this.FieldName=this.FieldName.toUpperCase()=='ALLINDIA'?'BRANCH':this.FieldName.toUpperCase()=='BRANCH'?'REGION': this.FieldName.toUpperCase()=='REGION'?'ASMAREA': this.FieldName.toUpperCase()=='ASMAREA'?'SOTERRITORY' : this.FieldName.toUpperCase()=='SOTERRITORY'?'RD' : 'RD';
    // if(this.FieldName=="RD")
    // {
    //   let rdData = this.effectiveCoveragedata.filter((singleItem: { [x: string]: string; }) => (singleItem['valuetype']==e && singleItem['period']==this.selectedperiod));
    //   localStorage.setItem("rdData", JSON.stringify(rdData));
    // }
    // else {
    //   localStorage.setItem("rdData", '');
    // }
    if (fieldName == "substk") {

    } else {
      this.router.navigate(['/home/urjaaEffectiveCoverageData/',fieldName, fieldvalue,this.ParaName,this.UserID, this.selectedperiod]);
    }
  }
  openFilter(){
    if (this.dialog.openDialogs.length == 0) {
      const dialogRef = this.dialog.open(FilterComponent, {
        closeOnNavigation: true,
        data: {
          "FieldName":localStorage.getItem("OriginalFieldName"),
          "FieldValue": localStorage.getItem("OriginalFieldValue"),
          "UserID": this.UserID,
          "MenuUpTo": "5",
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.FieldName=result.FieldName;
          this.FieldValue=result.FieldValue;
          this.loadData();
              }
      });
    }
  }
}
