import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Route, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';
import { HeaderService } from 'src/app/service/header.service';

@Component({
  selector: 'app-scan-qr-code-match-in-transaction',
  templateUrl: './scan-qr-code-match-in-transaction.component.html',
  styleUrls: ['./scan-qr-code-match-in-transaction.component.css']
})
export class ScanQrCodeMatchInTransactionComponent implements OnInit {
  @ViewChild('openModalSameOutlet', { static: true }) elRefOpenModalSameOutlet: ElementRef | undefined;
  formname:any=""
  actiondesc:any=""
  fieldName: any;
  fieldValue: any;
  userType: string | null = '';
  userId: any;
  qrCodeDetails:any
  constructor(private router:Router,private modalService: NgbModal,private headerService: HeaderService,private cs: CommonUtilitiesService) { }

  ngOnInit(): void {
    this.headerService.setTitle('QR Code Scan');
    this.headerService.setActiveURl('/ScanQrCodeMatchInTransaction');
    this.formname = "QRCodeScan";
    this.actiondesc = "QrCode Scan";
    this.userType = localStorage.getItem("UserType");
    this.userId = localStorage.getItem("UserID");
    this.fieldName = localStorage.getItem("FieldName");
    this.fieldValue = localStorage.getItem("FieldValue");
    this.qrCodeDetails = JSON.parse(localStorage.getItem("qrCodeDetails") || '{}') ;
    this.cs.GetGeoLocation(this.userId, this.userType, this.formname, this.actiondesc, this.fieldName, this.fieldValue);
  }

  clickOnContinue(){
    this.openModal(this.elRefOpenModalSameOutlet);
  }

  clickOnYes() {
    this.modalClose()
    this.router.navigate(['/home/transactions']);

  }

  clickOnNo() {
    this.modalClose()
    this.router.navigate(['/home/ScanQrCodeDataInTransaction'])
  }

  modalClose() {
    this.modalService.dismissAll();

  }
  openModal(modal: ElementRef<any> | undefined) {
    this.modalService.open(modal,
      {
        size: 'lg',
        backdrop: 'static',
        windowClass: '',
        keyboard: false,
        centered: true,
      })
  }

}
