<div class="" style="overflow: hidden;">
    <div class="row mt-4">
        <div class="col-12">
            <span class="issueName" [ngClass]="[isL1L2L3 ? 'isL1L2L3TextColour' : '']">
                {{ticketDetails.subject}}
            </span>
            <span class="ms-2 outletCode" [ngClass]="[isL1L2L3 ? 'isL1L2L3TextColour' : '']">
                {{ticketDetails.ticketNo}}
            </span>
            <span class="mt-1 mb-1 ms-1">
                <img *ngIf="ticketDetails.ticketOwnerType=='L1'" src="../../../assets/icons/L1Icon.svg"
                    class="mb-1 ms-3">
                <img *ngIf="ticketDetails.ticketOwnerType=='L2'" src="../../../assets/icons/L2Icon.svg" class="mb-1">
                <img *ngIf="ticketDetails.ticketOwnerType=='L3'" src="../../../assets/icons/L3Icon.svg" class="mb-1">
            </span>
        </div>
    </div>


    <div class="row mt-2">
        <div class="col-12 status">
            Ticket By: {{ticketDetails.userId}}
        </div>
    </div>

    <div class="row mt-2">
        <div class="col-12">
            <span class="" style="font-size: 14px;color: #00000099">
                {{ticketDetails.statusDateFormatChange}}
            </span>
            <span class="ms-3" style="color: #00000099;font-size: 14px;">
                {{ ticketDetails.statusTimeFormat | date : 'shortTime'}}
            </span>
            <span class="float-end status">
                {{ticketDetails.ticketStatus}}
            </span>
        </div>
    </div>
    <hr>

    <div class="row mt-3">
        <div class="col-6 bodyHeading">
            Module
        </div>
        <div class="col-6 text-end bodyHeaderValue">
            {{ticketDetails.module}}
        </div>
    </div>

    <div class="row mt-3">
        <div class="col-6 bodyHeading">
            Category
        </div>
        <div class="col-6 text-end bodyHeaderValue">
            {{ticketDetails.category}}
        </div>
    </div>

    <!-- <div class="row mt-3">
        <div class="col-6 bodyHeading">
            Urgency
        </div>
        <div class="col-6 text-end bodyHeaderValue">
            <button class="p-0" (click)="openTooltip()" #tooltip="matTooltip"
                matTooltip="Based on your urgency type you have to select the urgency." matTooltipPosition="above"
                style="border: none;
                background: white;">
                <img src="../../../assets/icons/information_btn.svg" class="mb-1">
            </button>
            {{ticketDetails.urgency}}
        </div>
    </div> -->

    <!-- <div class="row mt-3">
        <div class="col-6 bodyHeading">
            Impact
        </div>
        <div class="col-6 text-end bodyHeaderValue">
            <button class="p-0" (click)="openImpactTooltip()" #impacttooltip="matTooltip"
                matTooltip="Based on how many people are impacted, you have to select the impacted."
                matTooltipPosition="above" style="border: none;
            background: white;">
                <img src="../../../assets/icons/information_btn.svg" class="mb-1">
            </button>
            {{ticketDetails.impact}}
        </div>
    </div> -->

    <div class="row mt-3">
        <div class="col-6 bodyHeading">
            Priority
        </div>
        <div class="col-6 text-end bodyHeaderValue" style="color: #599E4E;">
            {{ticketDetails.priority}}
        </div>
    </div>

    <div class="row mt-3">
        <div class="col-7 bodyHeading mt-3" >
            Watchlist
        </div>
        <div class="col-5 text-end bodyHeaderValue p-0" style="margin-left: -1em;">

            <mat-form-field>
                <mat-select [value]="selectedWishlistItem[0]?.userName">
                    <mat-option *ngFor="let item of selectedWishlistItem" [value]="item.userName" disabled>
                        {{item.userName}}</mat-option>
                </mat-select>
            </mat-form-field>

            <img src="../../../assets/icons/addBtn.svg" class="me-2 ms-3" (click)="addWatchlist()"
                *ngIf="(ticketDetails.ticketStatus?.toLowerCase()!='cancel') && (ticketDetails.ticketStatus?.toLowerCase()!='closed' && ticketDetails.userId?.toLowerCase()==UserID?.toLowerCase())">
        </div>
    </div>

    <hr>

    <div class="row mt-3">
        <div class="col-12 descriptionHeading" style="overflow: auto !important">
            <span>
                Description
            </span>
            <br><br>
            <span class="descriptionValue" [innerHTML]="ticketDetails.description">
                {{ticketDetails.description}}
            </span>
        </div>
    </div>


    <div class="row mt-4 me-2">
        <div class="col-12">
            <div class="card" id="test" style="border: none;">
                <div class="card-body p-0">
                    <div class="row ">
                        <div class="col-4" *ngFor="let data of ticketDetails.fileAttachmentName">
                            <div type="file" class="row mt-2 ms-0 fileName"
                                *ngIf="ticketDetails.fileAttachmentName?.length>0">
                                <span class="icon" (click)="clickOnExistingCommentImages(data)" style="max-height: 88px;
                                overflow: hidden;">
                                    <img src="../../../assets/icons/document_icon.svg" class="mb-1"
                                        *ngIf="data?.toLowerCase()?.lastIndexOf('zip')!=-1 || data?.toLowerCase()?.lastIndexOf('doc')!=-1 || data?.toLowerCase()?.lastIndexOf('docx')!=-1 || data?.toLowerCase()?.lastIndexOf('txt')!=-1 || data?.toLowerCase()?.lastIndexOf('xlsx')!=-1 || data?.toLowerCase()?.lastIndexOf('7z')!=-1 || data?.toLowerCase()?.lastIndexOf('pdf')!=-1 || data?.toLowerCase()?.lastIndexOf('pptx')!=-1 || data?.toLowerCase()?.lastIndexOf('msg')!=-1">

                                    <img src="../../../assets/icons/gallary.svg" class="mb-1"
                                        *ngIf="data?.toLowerCase()?.lastIndexOf('jpeg')!=-1 || data?.toLowerCase()?.lastIndexOf('png')!=-1|| data?.toLowerCase()?.lastIndexOf('jpg')!=-1">
                                    {{data}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>




    <hr>

    <div class="row mt-3" *ngIf="existingComments.length>0 || sendCommentList.length>0">
        <div class="col-12">
            <span class="commentLebel">Comment's</span>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col-12">

            <div class="card mb-4" id="test" *ngFor="let item of existingComments"
                [ngClass]="[ticketDetails?.userId?.toLowerCase()!=item?.transactionBy?.toLowerCase() ? 'commentBox' : 'sendCommentBox']">
                <div class="row mb-2" *ngIf="ticketDetails?.userId?.toLowerCase()!=item?.transactionBy?.toLowerCase()">
                    <div class="p-1 ms-3" style="background-color: #4F2170;color:white;font-size: 12px;
                    border-radius: 8px;width: 87px;">
                        Support Team
                    </div>
                </div>
                <div class="card-body" style="padding-top: 0px !important;">
                    <!-- <div class="row mb-2" *ngIf="UserID?.toLowerCase()!=item?.transactionBy?.toLowerCase()">
                        <div class="p-1" style="background-color: #4F2170;color:white;
                        border-radius: 8px;width: 99px;">
                            Support Team
                        </div>
                    </div> -->
                    <div class="row mt-1">
                        <div class="col-7">
                            <span style="color: #4F2170;font-weight: 500;font-size: 16px;">{{item.transactionBy}}</span>
                        </div>
                        <div class="col-5 p-0">
                            <span class="float-end" style="opacity: 0.36;">{{item.commentDate}}</span>
                        </div>
                    </div>

                    <div class="row mt-1">
                        <div class="col-12" style="color: #8B8B8B;font-size: 16px;">
                            {{item.description}} </div>
                    </div>

                    <div class="row">
                        <div class="col-4" *ngFor="let data of item.fileAttachmentName">
                            <div class="form-input" *ngIf="item.fileAttachmentName?.length>0">
                                <div type="file" class="row mt-2 ms-0 fileName">
                                    <span class="icon" (click)="clickOnExistingCommentImages(data)" style="max-height: 88px;
                                overflow: hidden;">
                                        <!-- <img src="../../../assets/icons/gallary.svg" class="mb-1"> -->

                                        <img src="../../../assets/icons/document_icon.svg" class="mb-1"
                                            *ngIf="data?.toLowerCase().lastIndexOf('zip')!=-1 || data?.toLowerCase().lastIndexOf('doc')!=-1 || data?.toLowerCase().lastIndexOf('docx')!=-1 || data?.toLowerCase().lastIndexOf('txt')!=-1 || data?.toLowerCase().lastIndexOf('xlsx')!=-1 || data?.toLowerCase().lastIndexOf('7z')!=-1 || data?.toLowerCase().lastIndexOf('pdf')!=-1 || data?.toLowerCase().lastIndexOf('pptx')!=-1 || data?.toLowerCase().lastIndexOf('msg')!=-1">

                                        <img src="../../../assets/icons/gallary.svg" class="mb-1"
                                            *ngIf="data?.toLowerCase().lastIndexOf('jpeg')!=-1 || data?.toLowerCase().lastIndexOf('png')!=-1 || data?.toLowerCase().lastIndexOf('jpg')!=-1">
                                        {{data}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-2" *ngIf="sendComment">
        <div class="col-12">
            <div class="card sendCommentBox mb-4" id="test" *ngFor="let item of sendCommentList ">
                <div class="card-body">

                    <div class="row">
                        <div class="col-5">
                            <span style="color: #4F2170;font-weight: 500;">You</span>
                        </div>
                        <div class="col-7 p-0">
                            <span class="float-end" style="opacity: 0.36;">{{item.commentDate}}</span>
                        </div>
                    </div>

                    <!-- <div class="row mt-1">
                        <div class="col-7">
                            <span style="color: #4F2170;">Checked & Remark</span>
                        </div>
                        <div class="col-5 p-0">
                            <span class="float-end" style="opacity: 0.36;">{{item.commentDate}}</span>
                        </div>
                    </div> -->
                    <div class="row mt-1">
                        <div class="col-12" style="color: #8B8B8B;">
                            {{item.comment}}
                        </div>
                    </div>

                    <div class="row mt-2">
                        <div class="col-4" *ngFor="let data of item.attachments">
                            <div type="file" class="row mt-2 ms-0 fileName">
                                <span class="icon" (click)="clickOnExistingCommentImages(data.fileName)" style="max-height: 88px;
                                overflow: hidden;">
                                    <img src="../../../assets/icons/document_icon.svg" class="mb-1"
                                        *ngIf="data.name?.toLowerCase().lastIndexOf('zip')!=-1 || data.name?.toLowerCase().lastIndexOf('doc')!=-1 || data.name?.toLowerCase().lastIndexOf('docx')!=-1 || data.name?.toLowerCase().lastIndexOf('txt')!=-1 || data.name?.toLowerCase().lastIndexOf('xlsx')!=-1 || data.name?.toLowerCase().lastIndexOf('7z')!=-1 || data.name?.toLowerCase().lastIndexOf('pdf')!=-1 || data.name?.toLowerCase().lastIndexOf('pptx')!=-1 || data.name?.toLowerCase().lastIndexOf('msg')!=-1">

                                    <img src="../../../assets/icons/gallary.svg" class="mb-1"
                                        *ngIf="data.name?.toLowerCase().lastIndexOf('jpeg')!=-1 || data.name?.toLowerCase().lastIndexOf('png')!=-1|| data.name?.toLowerCase().lastIndexOf('jpg')!=-1">
                                    {{data.fileName}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <br><br><br><br><br> <br><br><br><br><br><br><br><br><br><br> <br><br><br><br><br>


    <!-- <div class="row">
        <div class="col-12"> -->
    <div class="rods_Footer"
        *ngIf="(ticketDetails.ticketStatus?.toLowerCase()!='cancel') && ticketDetails.ticketStatus?.toLowerCase()!='closed' && ticketDetails.userId?.toLowerCase()==UserID?.toLowerCase()">
        <hr class="me-4">
        <div style="max-height: 203px;overflow-y: auto;">
            <div class="row me-4" *ngFor="let item of uploadedMultipleFile; let i = index">
                <!-- <hr class="me-4"> -->
                <div class="col-10">
                    <img src="../../../assets/icons/gallary.svg" class="mb-1">
                    {{item.fileName}}
                </div>
                <div class="col-2">
                    <button type="button" class="close" aria-label="Close" (click)="removeAttachment(i)">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>
        </div>

        <!-- height: 4rem; -->
        <div class="row  my-2" style="background: #724e8c 0% 0% no-repeat padding-box;  height: auto;">
            <div class="input-group col-8 p-1">
                <textarea #commentBox type="text" id="updateInputBox" class="form-control ms-2 updateInputBox mt-2 mb-2"
                    placeholder="Add Your Update" rows="2" style="overflow:hidden" [(ngModel)]="comment"
                    style="border-radius: 25px;align-content: center;"> </textarea>
                <input #file type="file" (change)="upload($event)"
                    accept=".zip,.doc,.docx,.txt,.xlsx,.7z,.pdf,.jpg,.pptx,.msg,.png" style="display: none;" multiple>
            </div>
            <div class="input-group col-4">
                <!-- <button type="button" class="bg-transparent attachmentBtn" style="margin-left: -16px; z-index: 100;"
                    (click)="file.click()">
                    <img src="../../../assets/icons/attachment_icon.svg" title="Attachment" class="me-2 sendIcon"
                        style="background-color: white;">
                </button> -->

                <button type="button" class="bg-transparent attachmentBtn p-0" (click)="file.click()">
                    <img src="../../../assets/icons/attachment_icon.svg" title="Attachment" class="me-2"
                        style="height: 2.2rem;">
                </button>

                <button class="btn p-0" style="border: none;">
                    <img src="../../../assets/icons/send.svg" id="sendIcon" class=""
                        (click)="commentSend(commentBox.value,true)">
                </button>
            </div>

            <!-- <div class="input-group col-2">
                <button class="btn p-0 ms-3" style="border: none;">
                    <img src="../../../assets/icons/send_icon.svg" id="sendIcon" class="sendIcon"
                        (click)="commentSend(commentBox.value,true)">
                </button>
            </div> -->
        </div>
    </div>
</div>
<!-- </div>
    </div> -->


<div class="footer">
    <div class="row text-center">
        <!--  -->
        <div class="col-12 pr-0"
            *ngIf="(ticketDetails.ticketStatus?.toLowerCase()=='inprogress') || (ticketDetails.ticketStatus?.toLowerCase()=='cancel')|| (ticketDetails.ticketStatus?.toLowerCase()=='onhold') || (ticketDetails.ticketStatus?.toLowerCase()=='new') || (ticketDetails.ticketStatus?.toLowerCase()=='open') || (ticketDetails.ticketStatus?.toLowerCase()=='reopen') || (ticketDetails.ticketStatus?.toLowerCase()=='closed' && ticketDetails.userId?.toLowerCase()==UserID?.toLowerCase() &&  (ticketDetails?.feedbackstatus.length>0 || ticketDetails?.feedbackStar!=0 || ticketDetails?.feedbackStar!=''))">
            <button class="btn btn_Cancel" (click)="clickOnBackBtn()">BACK</button>
        </div>

        <div class="row p-0" *ngIf="ticketDetails.ticketStatus?.toLowerCase()=='resolved'">
            <div class="col-6 pr-0">
                <button class="btn btn_Cancel" (click)="clickOnBackBtn()">BACK</button>
            </div>
            <div class="col-6 p-0" (click)="reOpenClicked()">
                <button class="btn btn_APPROVE">RE-OPEN</button>
            </div>
        </div>

        <div class="row p-0"
            *ngIf="ticketDetails.ticketStatus?.toLowerCase()=='closed' && ticketDetails.userId?.toLowerCase()==UserID?.toLowerCase() && (ticketDetails?.feedbackstatus.length==0 || ticketDetails?.feedbackStar==0 || ticketDetails?.feedbackStar=='')">
            <div class="col-6 pr-0">
                <button class="btn btn_Cancel" (click)="clickOnBackBtn()">BACK</button>
            </div>
            <div class="col-6 p-0" (click)="clickedOnFeedbackBtn()">
                <button class="btn btn_APPROVE">FEEDBACK</button>
            </div>
        </div>

        <!-- 
        <div class="row p-0"
            *ngIf="ticketDetails.ticketStatus?.toLowerCase()=='closed' && ticketDetails.userId?.toLowerCase()==UserID?.toLowerCase() && ticketDetails?.feedbackstatus.length>0">
            <div class="col-12 pr-0">
                <button class="btn btn_Cancel" (click)="clickOnBackBtn()">BACK</button>
            </div>
        </div> -->

    </div>
</div>


<ng-template #openWatchListModal let-modal>
    <div class="modal-header text-center">
        <h4 class="modal-title text_color_head text_20 text-center m-0" id="modal-basic-title">
            <img src="../../../assets/icons/addWatchlist_icon.svg" alt="" class="mb-2">
            <span class="ml-3 text-center Value_text ms-3" style="color: #724E8C;
                font-size: 20px;
                font-weight: 500;">Add Watchlist</span>
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="modalClose()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" style="height: 34vh;">
        <div id="accordion">
            <div class="row" *ngIf="isWatchlisShow">
                <div class="col-12">
                    <app-select-multiselect-dropdown [dataSet]="watchListItems" [selectAreaId]="selectedAreaId"
                        [placeholder]="'Search WatchList'" (returnSelectedList)="receivedSelectedList($event)"
                        class="dmappelect-multicolumn-multiselect-table">
                    </app-select-multiselect-dropdown>
                </div>
            </div>

        </div>
    </div>
</ng-template>

<ng-template #openReOpenModal let-modal>
    <div class="modal-header text-center">
        <h4 class="modal-title text_color_head text_20 text-center" id="modal-basic-title">
            <img src="../../../assets/icons/alert.svg">
            <span class="ml-3 ms-2 text-center Value_text"
                style="color: #724E8C;font-size: 20px;font-weight: 500;letter-spacing: 0.19px;">Alert</span>
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="modalClose()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div id="accordion">
            <div class="card">
                <div class="card-header" id="headingOne">
                    <div class="row">
                        <div class="col-12">
                            <span style="font-weight: 500;
                                letter-spacing: 0.21px;">Are you sure want to reopen the ticket?</span>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-12">
                            <span style="color: #724E8C;letter-spacing: 0.16px;font-size: 12px;">Ticket NO :
                                {{ticketDetails.ticketNo}}</span>
                        </div>
                    </div>

                    <div class="row mt-2">
                        <div class="col-12">
                            <span style="font-weight: 500;
                            letter-spacing: 0.21px;">Reason
                                <textarea class="form-control" id="exampleFormControlTextarea1"
                                    placeholder="Enter Reason" [(ngModel)]="reopenReason" rows="4">
                                </textarea>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer p-0">
        <div class="row w-100 p-0">
            <div class="col-6 p-0">
                <button class="btn btn_Cancel" (click)="clickedOnNo()">NO</button>
            </div>
            <div class="col-6 p-0" (click)="clickedOnYes()">
                <button class="btn btn_APPROVE">YES</button>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #openExistingCommentImagesModal let-modal>
    <div class="modal-header text-center">
        <button type="button" class="close" aria-label="Close" (click)="modalClose()">
            <span aria-hidden="true">&times;</span>

        </button>
    </div>
    <div class="modal-body">
        <div id="accordion">
            <div class="card">
                <div class="card-header" id="headingOne">
                    <div class="row">
                        <div class="col-12">
                            <span style="font-weight: 500;
                                letter-spacing: 0.21px;">
                                <img [src]="url+'MeraNet/GetDownloadFileFromS3Bucket/HelpDesk/' + existingCommentImageName"
                                    alt="Image" class="img-fluid float-end">
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>




<!-- 
<ng-template #openSelectMediaModal let-modal>
    <div class="modal-header text-center">
        <h4 class="modal-title text_color_head text_20 text-center" id="modal-basic-title">
            <span class="ml-3 text-center Value_text">Select Media</span>
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="modalClose()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div id="accordion">
            <div class="card">
                <div class="card-header" id="headingOne">
                    <div class="row">
                        <div class="col-2">
                            <img src="../../../assets/icons/gallary.svg">
                        </div>
                        <div class="col-10">
                            <span class="attachment" (click)="gallary.click()">Gallary</span>
                            <input #gallary type="file" accept="image/*" id="gallaryInput" name="gallaryInput"
                                (change)="onFileSelected($event)" style="display: none" />
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-2">
                            <img src="../../../assets/icons/camer_icon.svg">
                        </div>
                        <div class="col-10">
                            <span class="attachment text-center" (click)="camera.click()">Camera</span>
                            <input #camera type="file" accept="image/*" id="cameraInput" name="cameraInput"
                                (change)="onFileSelected($event)" style="display: none" />
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-2">
                            <img src="../../../assets/icons/document_icon.svg">
                        </div>
                        <div class="col-10">
                            <span class="attachment text-center" (click)="excel.click()">Excel</span>
                            <input #excel type="file"
                                accept=".csv,.xlsx,.xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                id="excelInput" name="excelInput" (change)="onFileSelected($event)"
                                style="display: none" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template> -->