<div>
    <div class="row">
        <div class="row mt-4">
            <div class="col-3">
              <img [src]="coolerPhoto" alt="Image" *ngIf="coolerPhoto" class="img-fluid photo">
              <div class="Rectangle" *ngIf="!coolerPhoto">
                <span class="Image">Image</span>
              </div>
            </div>
            <div class="col-7 mt-3">
              <span class="text_14 text_black">Cooler Photo</span>
            </div>
            <div class="col-2 mt-3">
              <img class="img-fluid" src="assets/camera.png" style="margin-top: -4px;" (click)="CoolerPhoto.click()" />
              <input #CoolerPhoto id="CoolerPhoto" type="file" accept="image/*" (change)="fileProgress($event,'CoolerPhoto')"
               style="display: none" />
            </div>
            <hr>
          </div>
      
          <div class="row mt-4">
            <div class="col-3">
              <img [src]="outletPhoto" alt="Image" *ngIf="outletPhoto" class="img-fluid photo">
              <div class="Rectangle" *ngIf="!outletPhoto">
                <span class="Image">Image</span>
              </div>
            </div>
            <div class="col-7 mt-3">
              <span class="text_14 text_black">Outlet Exterior Photo</span>
            </div>
            <div class="col-2 mt-3">
              <img class="img-fluid" src="assets/camera.png" style="margin-top: -4px;" (click)="OutletPhoto.click()" />
              <input #OutletPhoto id="OutletPhoto" type="file" accept="image/*" (change)="fileProgress($event,'OutletPhoto')"
                 style="display: none" />
            </div>
            <hr>
          </div>
    </div>
</div>

<div class="footer row">
    <div class="col-12 p-0">
        <button type="button" class="btn btn_save float-right w-100"
        (click)="saveBtn()">Save</button>
    </div>
</div>
