<div class="row mt-2 bg_Color">
  <div class="col-12 mt-4">
    <span class="pc_Name">{{facialRecDetails.pcName}}</span>
    <span class="ml-1 pc_Code">{{facialRecDetails.pcuid}}</span>
  </div>
  <div class="col-12 mt-2 mb-4">
    <span class="rd_Name">{{facialRecDetails.rdName}}</span>
    <span class="ml-1 rd_Code">{{facialRecDetails.rdCode}}</span>
  </div>
</div>
<div class="row mt-3" *ngIf="facialRecDetails.finalStatus != 'PENDING'">
  <div class="col-12">
    <span class="Selfie_facing_text">Status:</span>
    <span class="ml-2 final_Status">{{facialRecDetails.finalStatus}}</span>
  </div>
</div>
<div class="row mt-3">
  <div class="col-12">
    <span class="Selfie_facing_text">Selfie facing the camera</span>
  </div>
  <div class="col-12 mt-3">
    <img [src]="photopath + facialRecDetails.pcImage_m1" alt="Image" class="img_fluid" (click) = "show('https://dev.cilsales.net/PCRegistration/' + facialRecDetails.pcImage_m1)">
  </div>
</div>
<div class="row mt-3">
  <div class="col-12">
    <span class="Selfie_facing_text">Selfie with head turned right</span>
  </div>
  <div class="col-12 mt-3">
    <img [src]="photopath + facialRecDetails.pcImage_m2" alt="Image" class="img_fluid"  (click) = "show('https://dev.cilsales.net/PCRegistration/' + facialRecDetails.pcImage_m1)">
  </div>
</div>
<div class="row mt-3">
  <div class="col-12">
    <span class="Selfie_facing_text">Selfie with head turned left</span>
  </div>
  <div class="col-12 mt-3">
    <img [src]="photopath + facialRecDetails.pcImage_m3" alt="Image" class="img_fluid"  (click) = "show('https://dev.cilsales.net/PCRegistration/' + facialRecDetails.pcImage_m1)">
  </div>
</div>
  <br>
  <br>
  <br>
  <br>
  <br>
  <div class="footer" *ngIf="facialRecDetails.finalStatus == 'PENDING'">
    <div class="row">
      <div class="col-6 pr-0" (click)="reject()">
        <button class="btn btn_REJECT">REJECT</button>
      </div>
      <div class="col-6 pl-0" (click)="approve()">
        <button class="btn btn_APPROVE">APPROVE</button>
      </div>
    </div>
  </div>
<ng-template #openModalThankYou let-modal>
  <div class="modal-header">
    <h4 class="modal-title text_color_head text_20" id="modal-basic-title">
      <img class="img-fluid" src="assets/thanks_icon.png" />
      <span class="ml-3">Thank You</span>
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modalClose()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <span class="model_PopUp_Text">Facial Recognition Approved</span>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <span class="model_PopUp_Text">successfully for</span>
      </div>
    </div>
    <div class="row mt-4 mb-2">
      <div class="col-12">
        <span class="pc_Name_PopUp">{{facialRecDetails.pcName}}</span>
        <span class="ml-2 pc_Code_PopUp">{{facialRecDetails.pcuid}}</span>
      </div>
    </div>
  </div>
  <div class="modal-footer" (click)="okay()">
    OKAY
  </div>
</ng-template>
<div [style.display]="showModal ? 'block' : 'none'" class="modal" id="imagemodal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel">Image preview</h4>
      </div>
      <div class="modal-body">
        <img [src]="selectedimagepath" id="imagepreview" style="width: 100%; height: 425px;" >
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal" (click) = "hide()">Close</button>
      </div>
    </div>
  </div>
<br> <br> <br><br> <br>
