<div>
  <div class="row mt-4">
    <div class="col-12">
      <input class="fullWidth" type="text" name="" id="" placeholder="Search RD Name"
        [(ngModel)]="searchTerm">
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-6">
      <span class="RD_List">RD List</span>
    </div>
    <div class="col-6 text-right">
      <span class="status_Text" *ngIf="userType == 'so'">No. of Outlets</span>
      <span class="status_Text" *ngIf="userType == 'asm'">L3M Unbilled Base</span>
    </div>
  </div>
<div *ngIf="userType == 'so'">
  <div *ngFor="let item of filteredList; let i = index;" (click)="rowClick(item)">
    <div class="row mt-3">
      <div class="col-9">
        <span class="rd_Name">{{item.desc | titlecase}}</span>
        <span class="ml-1 rd_Code">{{item.fieldvalue}}</span>
      </div>
      <div class="col-3 text-right">
        <span class="status_Value">{{item.totaloutlets}}</span>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-12">
        <span class="state_Name">{{item.beatname | titlecase}}</span>
      </div>
    </div>
    <hr>
  </div>
  </div>
<div *ngIf="userType == 'asm'">
  <div *ngFor="let item of filteredList; let i = index;" (click)="rowClick(item)">
    <div class="row mt-3">
      <div class="col-9">
        <span class="rd_Name">{{item.rdname | titlecase}}</span>
        <span class="ml-1 rd_Code">{{item.rdcode}}</span>
      </div>
      <div class="col-3 text-right">
        <span class="status_Value">{{item.l3MUnbilledBase}}</span>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-12">
        <span class="state_Name">{{item.soterritory | titlecase}}</span>
      </div>
    </div>
    <hr>
  </div>
  </div>
  <br>
  <br>
  <br>
  <br>
  <br>
</div>
