<ng-template #openEnterVerificationCodeModal let-modal>
    <div class="modal-header text-center">
        <button type="button" class="close" aria-label="Close" (click)="modalClose()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body ">
        <div id="accordion">
            <div class="card">
                <div class="card-header" id="headingOne">
                    <h5 class="mb-0 collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
                        aria-controls="collapseOne">
                        <button class="btn collapsed" data-toggle="collapse" data-target="#collapseOne"
                            aria-expanded="true" aria-controls="collapseOne">
                            <span class="Value_headertext text-center"><img class="img-fluid mr-1" />VIEW NAME
                                CHANGE AMENDMENT LETTER NOTE</span>
                        </button>
                        <i class="fa fa-sort-desc float-right" aria-hidden="true"></i>
                    </h5>
                </div>

                <div id="collapseOne" class="collapse show cardBody" aria-labelledby="headingOne"
                    data-parent="#accordion">
                    <div class="card-body">
                        <span class="data_Privacy_Text" innerHtml="{{VerifiedOtpText}}"></span>
                    </div>
                </div>
            </div>
        </div>
        <form [formGroup]="loginForm" autocomplete="off">
            <div class="row">
                <div class="col-12 text-center mt-3">
                    <label>
                        <input type="checkbox" name="options" autocomplete="off" class="Chkbtn" #checkbox
                            (change)="selected_Reason($event)" />
                        &nbsp;<span>I agree the terms & conditions</span>
                    </label>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-left">
                    <label>
                        <span class="txtspan">Enter Verification Code</span>
                    </label>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <input id="otp" class="form-control" #OTP type="text" placeholder="Enter verification Code"
                            name="OTP" formControlName="otp" value="">
                    </div>
                </div>

            </div>
            <div class="row">
                <div class="col-12"><button class="btn btn-block btn_login" type="submit"
                        (click)="verifyOTP(OTP.value,checkbox.checked)">
                        SUBMIT VERIFICATION CODE</button></div>
            </div>
        </form>
        <div class="row">
            <div class="col-12 text-center">
                <!-- <h6 [style]="IsError? 'color: red' : 'color:green'">{{errormsg}}</h6> -->
            </div>
        </div>
    </div>
</ng-template>



<ng-template #openShowDataAndPrivacyGModal let-modal>
    <div class="modal-header text-center">
        <button type="button" class="close" aria-label="Close" (click)="modalClose()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body my-3">
        <div id="accordion">
            <div class="card">
                <div class="card-header" id="headingOne">
                    <h5 class="mb-0 collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
                        aria-controls="collapseOne">
                        <button class="btn collapsed" data-toggle="collapse" data-target="#collapseOne"
                            aria-expanded="true" aria-controls="collapseOne">
                            <span class="Value_headertext text-center"><img class="img-fluid mr-1" />VIEW NAME
                                CHANGE AMENDMENT LETTER NOTE</span>
                        </button>
                        <i class="fa fa-sort-desc float-right" aria-hidden="true"></i>
                    </h5>
                </div>

                <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                    <div class="card-body">
                        <span class="data_Privacy_Text" innerHtml="{{VerifiedOtpText}}"></span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 text-center mt-3">
                        <!-- <span class="Value_headertext text-center submissionDate">Signed on dated : {{dpnsubmiton }}</span>  -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>