import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { DataService } from 'src/app/service/data.service';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-erods-approval',
  templateUrl: './erods-approval.component.html',
  styleUrls: ['./erods-approval.component.css']
})
export class ErodsApprovalComponent implements OnInit {
  deviceType: any;
  device: any;
  userType: string | null = '';
  userId: any;
  list: any;
  msg: any;
  private _searchText = '';
  filteredList: any;
  token: any;
  formname:any;
  actiondesc:any;
  fieldName: any;
  fieldValue: any;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private headerService: HeaderService, 
    private cs:CommonUtilitiesService,private mds: MongoDataService, private notifyMeService:NotifyMeService, private ds: DataService,) { }

  ngOnInit(): void {
    this.headerService.setTitle('Erods Approvals');
    this.headerService.setActiveURl('/erodsApproval');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.token = localStorage.getItem("TokenNo");
    this.userType = localStorage.getItem("UserType");
    this.userId = localStorage.getItem("UserID");
  
    this.fieldName = localStorage.getItem("FieldName");
    this.fieldValue = localStorage.getItem("FieldValue");

    this.formname="erodsApproval";
    this.actiondesc="Erods Approval"
    this.cs.GetGeoLocation(this.userId,this.userType,this.formname,this.actiondesc,this.fieldName,this.fieldValue);
    this.onLoad();
  }

  onLoad() {
    this.ds.MA_ERODS_GetClaimSummary(this.userId, this.token, this.device, this.deviceType, 'Erods Approvals').subscribe(res => {
      console.log("MA_ERODS_GetClaimSummary",res)
      this.list = res;
      this.filteredList = this.list;
    });
  }

  rowClick(data: any){
    localStorage.setItem("erodsApproval",JSON.stringify(data))
    this.router.navigate(['/home/erodsVouchersListApproval']);
  }

  get searchTerm(): any{
    return this._searchText;
  }
  set searchTerm(value: any){
     this._searchText = value;
     this.filteredList = this.filteredListMethod(value);
  }

  filteredListMethod(value: any): any {
    return this.list.filter((ele: any) =>(
      ele.rdName.toUpperCase().indexOf(value.toUpperCase()) !== -1 || ele.rdCode.trim().toUpperCase().indexOf(value.toUpperCase()) !== -1));
  }

}
