<div>
  <div class="row mt-4">
      <div class="col-12">
        <span class="header_text">{{FieldValue=='0'? 'All India' : FieldValue=='ALLINDIA' ? 'All India': FieldValue |titlecase}}</span>
      </div>
  </div>
  <br>
  <div class="row mt-2" *ngIf="!isOutlet">
    <div class="col-12">
      <div class="row">
          <div class="col-4">
            <span class="SE_text" *ngIf="paraName == '' && userType?.toUpperCase() == 'SO'">Distributor</span>
            <span class="SE_text" *ngIf="paraName != '' && userType?.toUpperCase() == 'SO'">PC/Substockist</span>
            <span class="SE_text" *ngIf="paraName == '' && userType?.toUpperCase() == 'ASM'">SE</span>
            <span class="SE_text" *ngIf="paraName != '' && userType?.toUpperCase() == 'ASM'">Distributor</span>
            <span class="SE_text" *ngIf="paraName == '' && userType?.toUpperCase() == 'BSM'">ASM Area</span>
            <span class="SE_text" *ngIf="paraName == 'ASMArea' && userType?.toUpperCase() == 'BSM'">SE</span>
            <span class="SE_text" *ngIf="paraName == 'SOTerritory' && userType?.toUpperCase() == 'BSM'">Distributor</span>
          </div>
          <div class="col-2">
              <span class="SE_text">Available</span>
          </div>
          <div class="col-2">
              <span class="SE_text">Missing</span>
          </div>
          <div class="col-2">
              <span class="SE_text">Shifted</span>
          </div>
          <div class="col-2 pl-0 pr-0" *ngIf="userType?.toUpperCase() == 'SO'">
            <span class="SE_text">Not Visited</span>
          </div>
          <div class="col-2" *ngIf="userType?.toUpperCase() != 'SO'"></div>
      </div>
      <hr class="hr_cust">
      <div class="row" *ngFor="let item of assetApprovalData; let i = index;">
          <div class="col-4" (click)="RowClick(item)">
            <span [class]="item.nvCount > 0 ?  'ASM_Value_Red' : 'ASM_Value'">{{item.code | titlecase}}</span>
          </div>
          <div class="col-2">
            <span class="Avalaible_Value">{{item.avCount}}</span>
          </div>
          <div class="col-2">
            <span class="Avalaible_Value">{{item.msCount}}</span>
          </div>
          <div class="col-2">
            <span class="Avalaible_Value">{{item.sfCount}}</span>
          </div>
          <div class="col-2 pl-0" *ngIf="userType?.toUpperCase() == 'SO'">
            <span class="Avalaible_Value">{{item.nvCount}}</span>
          </div>
          <div class="col-2" style="margin-top: 2px;" *ngIf="userType?.toUpperCase() != 'SO'">
            <input type="checkbox" id="Check{{i}}" (change)="clickOnSelect($event,i, item)"
                [(ngModel)]="assetApprovalData[i].checked">
          </div>
          <hr class="hr_cust">
      </div>
    </div>
  </div>
  <div class="row mt-2" *ngIf="isOutlet">
    <div class="col-12">
      <div class="row">
        <div class="col-8">
          <span class="SE_text">Outlet Name/Code</span>
        </div>
        <div class="col-4 text-right">
          <span class="Select_All mr-2">Select All</span>
          <input type="checkbox" id="Check" (change)="toggleCheckboxAll($event)">
        </div>
      </div>
      <hr class="hr_cust">
      <div class="row" *ngFor="let item of assetApprovalData; let i = index;">
        <div class="col-10">
          <span [class]="item.verificationstatus == 'Not Visited' ?  'ASM_Value_Red' : 'outletName'">{{item.outletName | titlecase}}</span>
          <span [class]="item.verificationstatus == 'Not Visited' ?  'ASM_Value_Red' : 'outletCode'" class="ml-1">{{item.outletcode}}</span>
        </div>
        <div class="col-2 text-right" style="margin-top: 4px;">
          <input type="checkbox" onclick="return false;" id="Check{{i}}" [checked]="item.selected">
        </div>
        <div class="col-6">
          <span class="SE_text">Visicooler:</span>
          <span class="visicooler ml-1">{{item.visicooler}}</span>
        </div>
        <div class="col-6 text-right">
          <span class="SE_text">Visicooler Type:</span>
          <span class="visicooler ml-1">{{item.visicoolerType}}</span>
        </div>
        <div class="col-12">
          <span class="SE_text">Status:</span>
          <span (click)="onClickEdit(openModalEdit,item.verificationstatus, item.outletcode)">
            <span class="visicooler ml-1">{{item.verificationstatus}}</span>
            <span *ngIf="item.verificationstatus != 'Available'"><img class="img-fluid ml-1" src="assets/editAsset.svg"/></span>
          </span>
        </div>
        <hr class="hr_cust">
      </div>
    </div>
  </div>
  <br>
  <br>
  <br>
  <br>
</div>
<div class="footer" *ngIf="userType?.toUpperCase() == 'SO' && isSelected">
  <div class="row">
    <div class="col-12" (click)="approve()">
      <button class="btn btn_APPROVE">APPROVE</button>
    </div>
  </div>
</div>
<div class="footer" *ngIf="userType?.toUpperCase() != 'SO' && isSelected">
  <div class="row">
    <div class="col-6 pr-0" (click)="reject()">
      <button class="btn btn_REJECT">REJECT</button>
    </div>
    <div class="col-6 pl-0" (click)="approve()">
      <button class="btn btn_APPROVE">APPROVE</button>
    </div>
  </div>
</div>

<ng-template #openModalEdit let-modal>
  <div class="modal-header">
    <h4 class="modal-title text_color_head text_20" id="modal-basic-title">
      <img class="img-fluid" src="assets/edit_Visicooler_Status.svg" />
      <span class="ml-3">Edit Visicooler Status</span>
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modalClose()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <span class="change_Status_Text">Change Status</span>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <form [formGroup]="editVisicoolerStatusForm" autocomplete="off">
          <select class="form-control" id="status" name="status" formControlName="status">
            <option value="Available">Available</option>
            <option value="Missing">Missing</option>
            <option value="Visi Shifted">Visi Shifted</option>
            <!-- <option value="Not Visited">Not Visited</option> -->
          </select>
        </form>
      </div>
    </div>
    <!-- <div class="row mt-3">
      <div class="col-6 pr-0" (click)="modalClose()">
        <button class="btn btn_Cancel">Cancel</button>
      </div>
      <div class="col-6 pl-0" (click)="save()">
        <button class="btn btn_Save">Save</button>
      </div>
    </div> -->
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6" (click)="modalClose()">
        <button class="btn btn_Cancel">Cancel</button>
      </div>
      <div class="col-6" (click)="save()">
        <button class="btn btn_Save">Save</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #openModalMsg let-modal>
  <div class="modal-header">
    <h4 class="modal-title text_color_head text_20" id="modal-basic-title">
      <span class="ml-3">Message</span>
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modalClose()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      <div class="row">
        <div class="col-12">
          <span class="change_Status_Text">{{msg}}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer my_Modal_Footer" (click)="modalClose()">
    OK
  </div>
</ng-template>

<ng-template #openModalThankYou let-modal>
  <div class="modal-header">
    <h4 class="modal-title text_color_head text_20" id="modal-basic-title">
      <img class="img-fluid" src="assets/thanks_icon.png" />
      <span class="ml-3">Thank You</span>
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="okay()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <span class="change_Status_Text">Record Approved Successfully</span>
      </div>
    </div>
  </div>
  <div class="modal-footer my_Modal_Footer" (click)="okay()">
    OK
  </div>
</ng-template>
