<div>

    <div class="row mt-4 mb-3">
        <div class="col-8">
            <input type="text" class="UID" #UID placeholder="Add Outlet UID" (keypress)="numberOnly($event)" />
        </div>
        <div class="col-4">
            <button class="btn btn_GO" (click)="GOBtn(UID)">GO</button>
        </div>
    </div>

    <div *ngIf="GoBtnClicked=='true'" class="mt-5  parentEle">
        <div class="row" *ngFor="let item of eligibleOutlet">
            <div class="row existingOutletSearch mt-2">
                <div class="col-7 word_break align-items-center d-flex">
                    <span class="text_14 text_black">{{item.outletname}}</span>
                </div>
                <div class="col-5 word_break justify-content-end align-items-center d-flex">
                    <span class="text_14 text_black float-end text-end">{{item.outletCode}}</span>
                </div>
            </div>
            

            <div class="row mt-1">
                <div class="col-6 word_break align-items-center d-flex">
                    <span>Requirement Type</span>
                </div>
                <div class="col-6 word_break justify-content-end align-items-center d-flex">
                    <span class="float-end text-end">{{item.requirementType}}</span>
                </div>
                <hr class="w-99">
            </div>

            <div class="row mt-1">
                <div class="col-6 word_break align-items-center d-flex">
                    <span>OIF Upload Date</span>
                </div>
                <div class="col-6 word_break justify-content-end align-items-center d-flex">
                    <span class="float-end text-end">{{item.oifuPloadDate}}</span>
                </div>
                <hr class="w-99">
            </div>

            <div class="row mt-1">
                <div class="col-6 word_break align-items-center d-flex">
                    <span>SO Approval Date</span>
                </div>
                <div class="col-6 word_break justify-content-end align-items-center d-flex">
                    <span class="float-end text-end">{{item.soApprovalDate}}</span>
                </div>
                <hr class="w-99">
            </div>

            <div class="row mt-1">
                <div class="col-6 word_break align-items-center d-flex">
                    <span>ASM Approval Date</span>
                </div>
                <div class="col-6 word_break justify-content-end align-items-center d-flex">
                    <span class="float-end text-end">{{item.asmApprovalDate}}</span>
                </div>
                <hr class="w-99">
            </div>

            <div class="row mt-1">
                <div class="col-6 word_break align-items-center d-flex">
                    <span>RCCM Approval Date</span>
                </div>
                <div class="col-6 word_break justify-content-end align-items-center d-flex">
                    <span class="float-end text-end">{{item.rccmApprovalDate}}</span>
                </div>
                <hr class="w-99">
            </div>

            <div class="row mt-1">
                <div class="col-6 word_break align-items-center d-flex">
                    <span>OIF Creation Date</span>
                </div>
                <div class="col-6 word_break justify-content-end align-items-center d-flex">
                    <span class="float-end text-end">{{item.oifCreationDate}}</span>
                </div>
                <hr class="w-99">
            </div>

            <div class="row mt-1">
                <div class="col-6 word_break align-items-center d-flex">
                    <span>OIF No</span>
                </div>
                <div class="col-6 word_break justify-content-end align-items-center d-flex">
                    <span class="float-end text-end">{{item.oifno}}</span>
                </div>
                <hr class="w-99">
            </div>
        </div>
    </div>
    <br>
    <br>
    <br>
    <br>
    <br>
</div>