<div>
  <div class="row mt-5">
    <div class="col-9">
      <!-- <i class="fa fa-caret-left" style="color: #757575;">&nbsp; -->
      <span class="header_text">{{FieldValue=='0'? 'All India' : FieldValue=='ALLINDIA' ? 'All India': FieldValue |titlecase}}</span>
      <!-- </i> -->
    </div>
    <div class="col-3 text-right">
      <img src="../../../assets/filter.png" class="img-fluid" (click)="openFilter()" />
      <sup><i class="fa fa-circle fa-sm" style="color: #eaab5e;font-size: 5px;"></i></sup>&nbsp;
      <span class="Filter">Filter</span>
      <!-- style="color: #724e8c;font-family: Roboto-Medium;font-weight:400 ;font-size: 14px;" -->
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-12 bgcustom ">
      <mat-tab-group [disablePagination]="true" (selectedTabChange)="onTabChanged($event);" dynamicHeight [(selectedIndex)]="selectedtabindex">
        <mat-tab label="15 Bills" class="mtlabel">
          <div>
            <div class="row mt-3">
              <div class="col-12 text-right">
                <mat-form-field appearance="outline" style="width: 100px">
                  <!-- <mat-label>Period</mat-label> -->
                  <mat-select id="period" name="period" (selectionChange)="PeriodChange($event)" [(value)]="selectedperiod">
                    <mat-option value="" disabled>Select Month</mat-option>
                    <mat-option *ngFor="let item of periodList" [value]="item">
                      {{ item }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <!-- </div> -->
                <!-- </div> -->
                <div class="container-fluid">
                  <div class="row mt-2">
                    <div class="col-6">
                      <span class="thheader pull-left">{{heading |titlecase}}</span>
                    </div>
                    <div class="col-6">
                      <span class="thheader pull-right">No Of working days</span>
                    </div>
                  </div>

                  <div class="row" *ngFor="let cate of filterItemsOfType(pcAttendance)" (click)="RowClick(cate.fieldName,cate.fieldvalue)">
                    <div class="col-6 mt-3 mb-3 text-left">
                      <span class="tdcell">{{cate.desc| titlecase}} <br>{{cate.fieldvalue | titlecase}}</span>
                    </div>
                    <div class="col-6 mt-3 mb-3 text-right">
                      <span class="percnt">{{cate.attendance}}%</span>
                    </div>
                    <br>
                    <hr class="hr_cust">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br>
        </mat-tab>
        <mat-tab label="Facial Recognition" class="tablabel">
          <div>
            <div class="row mt-3">
              <!-- <div class="col-8"></div> -->
              <div class="col-12">
                <span class="monthname">{{CurrentDate|date:'dd MMM yyyy'}}</span>
              </div>
            </div>

            <div class="row" style="margin-top: 1rem;">
              <div class="col-6 text-left"><span class="thheader">{{FRheading | titlecase}}</span></div>
              <div class="col-6 text-right"><span class="thheader">Attendance</span></div>
            </div>

            <div class="row" style="margin-top: 1rem;" *ngFor="let cate of pcFrdata"
              (click)="RowFRClick(cate.paraName,cate.code)">
              <div class="col-6 text-left">
                <span class="tdcell">{{cate.code}}</span>
                <br>
                <span class="rdname_text" *ngIf="isRD || isPC"> {{cate.rdcode}}</span>
              </div>

              <div class="col-6 text-right">
                <span class="percnt"> {{cate.frPer}}%</span>
              </div>

              <hr class="hr_cust" style="margin-top: 1rem;">
            </div>

            <div class="row mt-2 fixed-bottom">
              <div class="col-12">
                <!--*ngIf="IsIndiaLevel" -->
                <button class="btnrectangle btn-block" (click)="ClickFaceRecognition()">
                  <span class="btnText">Todays Face Recognition</span>
                </button>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
<br><br><br><br>