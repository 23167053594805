import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { DataService } from 'src/app/service/data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { InputPara } from 'src/app/models/kpi-models';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-stock-inout-selectrd-approval',
  templateUrl: './stock-inout-selectrd-approval.component.html',
  styleUrls: ['./stock-inout-selectrd-approval.component.css']
})
export class StockInoutSelectrdApprovalComponent implements OnInit {
  @ViewChild('openModalThankYou', { static: true }) elRefOpenModalThankYou: ElementRef | undefined;
  deviceType: any;
  device: any;
  userType: string | null = '';
  userId: any;
  list: any;
  selectedReasons: any;
  selected = -1;
  fieldName: any;
  fieldValue: any;
  stockInoutDetails: any;
  toList: any;
  input:InputPara=new InputPara();
  token: any;
  formname:any;
  actiondesc:any;

  constructor(private activatedRoute: ActivatedRoute, private cs:CommonUtilitiesService,private router: Router, private headerService: HeaderService, private mds: MongoDataService, private ds: DataService, private modalService: NgbModal, private datePipe: DatePipe,) { }

  ngOnInit(): void {
    this.headerService.setTitle('Select RD');
    this.headerService.setActiveURl('/stockInoutSelectrdApproval');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.token = localStorage.getItem("TokenNo");
    this.userType = localStorage.getItem("UserType");
    this.userId = localStorage.getItem("UserID");
    this.fieldName=localStorage.getItem("FieldName");
    this.fieldValue=localStorage.getItem("FieldValue");
    this.stockInoutDetails = JSON.parse(localStorage.getItem("stockInoutDetails") || '{}');

    this.formname = "stockInoutSelectrdApproval"
    this.actiondesc = "Stock In Out Selected Approval";
    this.cs.GetGeoLocation(this.userId, this.userType, this.formname, this.actiondesc, this.fieldName, this.fieldValue);
    this.onLoad();
  }
  onLoad() {
    this.input.FieldName=this.fieldName;
this.input.FieldValue=this.fieldValue;
this.input.TagName='0';
this.input.Datatype='0';
this.input.UserID=this.userId;
    this.mds.getRdList(this.input, this.token, this.device, this.deviceType, 'Select RD').subscribe(res => {
      this.list = res;
    });
  }
  selected_Reason(e: any) {
    if (e.target.checked) {
      this.selectedReasons = e.target.value;
      this.list.forEach((element: any) => {
        if(element.rdcode == this.selectedReasons){
          this.toList = element;
        }
      });
    }
  }

  rowClick() {
    const dataToSend = {
      RDCode: this.stockInoutDetails.rdCode,
      TRN: this.stockInoutDetails.trn,
      TRNNO: this.stockInoutDetails.trnNo,
      TRNType: this.stockInoutDetails.trnType,
      TRNDATE: this.stockInoutDetails.trnDate,
      Status: 'A',
      RDCode1: this.selectedReasons,
      UserType: this.userType,
      UserID: this.userId,
    };
    console.log("dataToSend",dataToSend)
    this.ds.setSTKInOutApprovalData(dataToSend, this.token, this.device, this.deviceType, 'Select RD').subscribe(res => {
      console.log("setSTKInOutApprovalData",res)
      this.openModal(this.elRefOpenModalThankYou);
    });
  }

  openModal(modal: ElementRef<any> | undefined) {
    this.modalService.open(modal,
      {
        size: 'lg',
        backdrop: 'static',
        windowClass: '',
        keyboard: false,
        centered: true,
      })
  }

  okay(){
    this.modalService.dismissAll();
    this.router.navigate(['/home/stockInoutApproval']);
  }

  modalClose() {
    this.modalService.dismissAll();
    this.router.navigate(['/home/stockInoutApproval']);
  }

}
