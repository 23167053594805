import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterComponent } from '../filter/filter.component';
import { MatDialog } from '@angular/material/dialog';
import { InputPara } from 'src/app/models/kpi-models';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { DataService } from 'src/app/service/data.service';
import { EncryptionService } from 'src/app/service/encryption.service';
import { Enclass, ResultClass } from 'src/app/models/encryption-class';

@Component({
  selector: 'app-todays-visits-outlet-list-details',
  templateUrl: './todays-visits-outlet-list-details.component.html',
  styleUrls: ['./todays-visits-outlet-list-details.component.css']
})
export class TodaysVisitsOutletListDetailsComponent implements OnInit {

  FieldName: any;
  FieldValue: any;
  UserID: any;
  DataType: any;
  VData: any;
  UserType: any;
  TokenNo: any;
  deviceType: any;
  device: any;
  lat: any;
  lng: any;
  input: InputPara = new InputPara();
  secretKey:any=""
  clientId:any=""
  public enclass: Enclass = new Enclass();
  public resultClass: ResultClass = new ResultClass();
  constructor(private activatedRoute: ActivatedRoute, private fb: FormBuilder, private router: Router,
    private headerService: HeaderService, private mds: MongoDataService,
    private dialog: MatDialog, private notifyMeService: NotifyMeService, private ds: DataService,private es: EncryptionService) { }

  ngOnInit(): void {
    debugger
    this.headerService.setActiveURl('/TodaysVisitsOutletListDetails');
    this.headerService.setTitle("Today's Visits");
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.UserType = localStorage.getItem("UserType");
    this.VData = [];
    this.DataType = "1";
    this.TokenNo = localStorage.getItem("TokenNo");
    this.secretKey = localStorage.getItem("SecreteKey")
    this.clientId = localStorage.getItem("ClientID");
    this.activatedRoute.paramMap.forEach(params => {
      this.FieldName = params.get('FieldName');
      this.FieldValue = params.get('FieldValue');
      this.UserID = params.get('UserID');
      this.GetGeoLocation();
      this.loadData();
    });
  }

  GetGeoLocation() {
    debugger
    if (navigator) {
      navigator.geolocation.getCurrentPosition(pos => {
        this.lat = pos.coords.latitude;
        console.log("  this.lat", this.lat)
        this.lng = pos.coords.longitude;
        this.SetapplicationLogData();
      }, this.handleLocationError, { timeout: 30000 });
    }
    else {
      this.notifyMeService.showError('Location Could not be captured', 'Mera APP');
    }
  }

  handleLocationError(error: { code: any; }) {
    switch (error.code) {
      case 3:
        this.notifyMeService.showError('Timeout in Position capture', 'Mera APP V2');
        break;
      case 2:
        this.notifyMeService.showError('Device is not capable of Position capture', 'Mera APP V2');
        break;
      case 1:
        this.notifyMeService.showError('You denied for Position capture', 'Mera APP V2');
        break;
    }
  }

  SetapplicationLogData() {
    debugger
    let request = {
      "userid": this.UserID,
      "usertype": this.UserType,
      "formname": "todayorderbooking",
      "actiondesc": "today order booking" + " " + this.FieldName,
      "fieldname": this.FieldName,
      "fieldvalue": this.FieldValue,
      "latitude": this.lat,
      "longitude": this.lng
    }
    this.ds.SetapplicationLogData(request).subscribe(res => {
      console.log("res", res)
    })
  }

  loadData(): void {
    debugger;
    let request = {
      "FieldName":this.FieldName,
      "FieldValue":this.FieldValue,
      "userId": this.UserID,
    }
    this.es.Word = this.es.WordKey;
    this.es.encrypt(this.secretKey);
    this.es.Word = JSON.stringify(request);
    this.enclass.buggerstring = this.es.encrypt(this.secretKey)
    this.mds.GetTodaysVisited(this.enclass,this.clientId, "todays visits" ).subscribe(res => {
      console.log("getSurveydetails", res)

      this.resultClass = res;
      this.es.Word = this.resultClass.resultString;
      let response = JSON.parse(this.es.decrypt(this.secretKey));
      this.VData =response
      console.log("response", response)

    }, (error: any) => {
      console.log("error", error)
    });
  }
  RowClick(paraname: any, paravalue: any): void {
    debugger
    paraname == "ab"
    if (paraname != "pc") {
      this.router.navigate(['/home/TodaysVisitsOutletListDetails/', paraname, paravalue, this.DataType, this.UserID]);
    }
  }
  openFilter() {
    if (this.dialog.openDialogs.length == 0) {
      const dialogRef = this.dialog.open(FilterComponent, {
        closeOnNavigation: true,
        data: {
          "FieldName": localStorage.getItem("OriginalFieldName"),
          "FieldValue": localStorage.getItem("OriginalFieldValue"),
          "UserID": this.UserID,
          "MenuUpTo": "5",
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.FieldName = result.FieldName;
          this.FieldValue = result.FieldValue;
          this.loadData();
        }
      });
    }
  }

}
