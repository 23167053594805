<div class="row mt-4 p-0">
  <div class="col-12 p-0">
    <mat-accordion>
    <div id="content1" #content1  *ngFor="let item of List">
        <mat-expansion-panel (opened)="panelOpenState = false" [expanded]="false" (closed)="panelOpenState = true">
          <mat-expansion-panel-header>
            <mat-panel-title>
             {{item.question}}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p class="details">{{item.answer}}</p>
        </mat-expansion-panel>
    </div>
  </mat-accordion>
</div>
</div>