import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PCMarketData,MarketData,InputPara } from 'src/app/models/kpi-models';
import { EncryptionService } from '../../service/encryption.service';
import { DataService } from '../../service/data.service';
import { Enclass, ResultClass } from '../../models/encryption-class';
import { MatDialog } from '@angular/material/dialog';
import { FilterComponent } from '../filter/filter.component';
import { NotifyMeService } from 'src/app/service/notify-me.service';

@Component({
  selector: 'app-pcdetails-main',
  templateUrl: './pcdetails-main.component.html',
  styleUrls: ['./pcdetails-main.component.css']
})
export class PcdetailsMainComponent implements OnInit {
  FieldName: any;
  FieldValue: any;
  DataType: any;
  UserID: any;
  periodList: any[] = [];
  selectedperiod: any;
  UserType: any;
  currentDate = new Date();
  pcData:any;
  heading :string="";
  input:InputPara=new InputPara();
  public pcMarketData: PCMarketData = new PCMarketData();
  public marketData: MarketData = new MarketData();
  public enclass: Enclass = new Enclass();
  public resultclass: ResultClass=new ResultClass();
  TokenNo: any ;
  ClientID: any;
  SecreteKey: any;
  IsRDValue: boolean=false;
  rdData: any=null;
  device: any;
  deviceType: any;
  token: any;
lat:any;
lng:any;
  constructor(private activatedRoute: ActivatedRoute,private fb: FormBuilder,  private es: EncryptionService,private router: Router,
     private headerService: HeaderService, private mds: MongoDataService,private ds:DataService, 
     private dialog: MatDialog,private notifyMeService: NotifyMeService) { }

  ngOnInit(): void {
    this.headerService.setActiveURl('/pcdetailsmain');
    this.headerService.setTitle('Active PC');
    this.UserType = localStorage.getItem("UserType");
    this.token = localStorage.getItem("TokenNo");
    this.DataType = "0"
    this.pcData = [];
    this.TokenNo= localStorage.getItem("TokenNo");
    this.ClientID= localStorage.getItem("ClientID");
   this.SecreteKey=   localStorage.getItem("SecreteKey");
   this.UserID = localStorage.getItem('UserID');
   this.device = localStorage.getItem('Device');
   this.deviceType = localStorage.getItem('DeviceType');
     this.currentDate=new Date();
      this.activatedRoute.paramMap.forEach(params => {

      this.FieldName = params.get('FieldName');
      this.FieldValue = params.get('FieldValue');
      this.DataType = params.get('DataType');
      this.heading=this.FieldName;
       if(this.FieldName=="RD")
      {
         this.heading=this.FieldName;

        let obj=localStorage.getItem("rdData");
         this.rdData = JSON.parse(obj || '{}') as PCMarketData;
         this.IsRDValue=true;

       }
       else{this.IsRDValue=false;  this.heading=this.FieldName;}
       debugger
       this.GetGeoLocation();
       this.loadData();
     });
  }

  GetGeoLocation() {
    debugger
    if (navigator) {
      navigator.geolocation.getCurrentPosition(pos => {
        this.lat = pos.coords.latitude;
        console.log("  this.lat", this.lat)
        this.lng = pos.coords.longitude;
        this.SetapplicationLogData();
      }, this.handleLocationError, { timeout: 30000 });
    }
    else {
      this.notifyMeService.showError('Location Could not be captured', 'Mera APP');
    }
  }

  handleLocationError(error: { code: any; }) {
    switch (error.code) {
      case 3:
        this.notifyMeService.showError('Timeout in Position capture', 'Mera APP V2');
        break;
      case 2:
        this.notifyMeService.showError('Device is not capable of Position capture', 'Mera APP V2');
        break;
      case 1:
        this.notifyMeService.showError('You denied for Position capture', 'Mera APP V2');
        break;
    }
  }

  SetapplicationLogData() {
    debugger
    let request = {
      "userid": this.UserID,
      "usertype": this.UserType,
      "formname": "pcdetailsmain",
      "actiondesc": "pc details main" + " " + this.FieldName,
      "fieldname": this.FieldName,
      "fieldvalue": this.FieldValue,
      "latitude": this.lat,
      "longitude": this.lng
    }
    this.ds.SetapplicationLogData(request).subscribe(res => {
      console.log("res", res)
    })
  }

  loadData(): void {
    this.input.FieldName=this.FieldName;
    this.input.FieldValue=this.FieldValue;
    this.input.TagName='0';
    this.input.Datatype= 'details';
    this.input.UserID=this.UserID;

    this.mds.GetMarketData(this.input,this.token,this.device,this.deviceType,'Active PC').subscribe(res => {
      this.pcData= res;

              });
            }


  RowClick(FieldName: any,fieldValue:any): void {
    if(FieldName=="rd" || FieldName=="RD")
    {

       this.IsRDValue=true;
       this.router.navigate(['/home/pclocation/',FieldName,fieldValue,this.DataType,this.UserID]);
     }
     else
     {
       this.router.navigate(['/home/pcdetailsmain/',FieldName,fieldValue,"details",this.UserID]);
     }
  }

  openFilter(){
    if (this.dialog.openDialogs.length == 0) {
      const dialogRef = this.dialog.open(FilterComponent, {
        closeOnNavigation: true,
        data: {
          "FieldName": localStorage.getItem("OriginalFieldName"),
          "FieldValue": localStorage.getItem("OriginalFieldValue"),
          "UserID": this.UserID,
          "MenuUpTo": "4",
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.FieldName=result.FieldName;
          this.FieldValue=result.FieldValue;
          this.loadData();
              }
      });
    }
  }
}




