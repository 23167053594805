<form [formGroup]="rejectReasonForm" autocomplete="off">
  <div class="row" style="margin-top: 2rem;">
    <div class="col-12">
      <textarea rows="5" name="text" class="reason_Text" placeholder="Enter Reason" formControlName="reason"
        required></textarea>
    </div>
  </div>
</form>

<div class="footer" *ngIf="rejectReasonForm.valid">
  <div class="row text-center mt-3">
    <div class="col-12" (click)="submit()">
      SUBMIT
    </div>
  </div>
</div>
