import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/service/data.service';
import { HeaderService } from 'src/app/service/header.service';
import { NotifyMeService } from 'src/app/service/notify-me.service';

@Component({
  selector: 'app-add-eligible-outlet',
  templateUrl: './add-eligible-outlet.component.html',
  styleUrls: ['./add-eligible-outlet.component.css']
})
export class AddEligibleOutletComponent implements OnInit {

  UserID: any;
  RequirementType: any;
  UserType: any;
  isChecked: boolean = false;
  ID: any;
  OIFApprovalList: any;
  TokenNo: any;
  ClientID: any;
  isLongPress: boolean = false;
  isSelected: boolean = false;
  userType: string | null = '';
  list: any;
  selected = -1;
  deviceType: any;
  device: any;
  token: any;
  FormName: any;
  public selectedOutletType = "";
  public selectedVisicooler = "";
  public outletCode = "";
  public visicoolerList:any = [];
  lat:any;
  lng:any;
  FieldName:any;
  FieldValue:any;
  constructor(private headerService: HeaderService, private ds: DataService,  private notifyMeService: NotifyMeService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.headerService.setActiveURl('/addEligibleOutlet');
    this.headerService.setTitle("Add Eligible Outlet");
    this.token = localStorage.getItem("TokenNo");
    this.FormName = 'Add Eligible Outlet';
    this.UserType = localStorage.getItem("UserType");
    this.UserID = localStorage.getItem("UserID");
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.FieldName = localStorage.getItem("FieldName");
    this.FieldValue = localStorage.getItem("FieldValue");
    // this.RequirementType = "Expansion";
    // this.ID = 0;
    this.TokenNo = localStorage.getItem("TokenNo");
    this.ClientID = localStorage.getItem("ClientID");
    this.GetGeoLocation();
    // this.onChangeOutletType();
  }

  
  GetGeoLocation() {
    debugger
    if (navigator) {
      navigator.geolocation.getCurrentPosition(pos => {
        this.lat = pos.coords.latitude;
        console.log("  this.lat", this.lat)
        this.lng = pos.coords.longitude;
        this.SetapplicationLogData();
      }, this.handleLocationError, { timeout: 30000 });
    }
    else {
      this.notifyMeService.showError('Location Could not be captured', 'Mera APP');
    }
  }

  handleLocationError(error: { code: any; }) {
    switch (error.code) {
      case 3:
        this.notifyMeService.showError('Timeout in Position capture', 'Mera APP V2');
        break;
      case 2:
        this.notifyMeService.showError('Device is not capable of Position capture', 'Mera APP V2');
        break;
      case 1:
        this.notifyMeService.showError('You denied for Position capture', 'Mera APP V2');
        break;
    }
  }

  SetapplicationLogData() {
    debugger
    let request = {
      "userid": this.UserID,
      "usertype": this.UserType,
      "formname": "addEligibleOutlet",
      "actiondesc": "Add Eligible Outlet",
      "fieldname": this.FieldName,
      "fieldvalue": this.FieldValue,
      "latitude": this.lat,
      "longitude": this.lng
    }
    this.ds.SetapplicationLogData(request).subscribe(res => {
      console.log("res", res)
    })
  }

  showMessage() {
    debugger
    if(this.selectedOutletType=="" || this.selectedOutletType.toLowerCase()=="selected"){
      this.toastr.warning("Please select outlet type");
      return;
    }
    else if(this.selectedVisicooler=="" || this.selectedVisicooler.toLowerCase()=="selected"){
      this.toastr.warning("Please select visicooler ");
      return;
    }
    else if(this.outletCode==""){
      this.toastr.warning("Please enter outlet code");
      return;
    }
    
    let obj = {
      "OutletCode": this.outletCode,
      "ReqType": this.selectedOutletType,
      "VisiCooler": this.selectedVisicooler,
      "UserID": this.UserID
    }
    debugger
    this.ds.ATS_AddEligibleOutlet(obj,this.token).subscribe(res => {
      if(res[0].msg.toLowerCase()=="outlet addition done successfully. please ask asm to approve the outlet."){
        this.toastr.success(res[0].msg);
      }else{
        this.toastr.warning(res[0].msg,'Sales Genie App');
      }
    },
      error => {
        console.log("error:Invalid API")
      },
    )
  }

  reset() {
    this.selectedOutletType = "";
    this.selectedVisicooler = "";
    this.outletCode = "";
  }

  numberOnly(event: { which: any; keyCode: any; }): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  onChangeOutletType() {
    debugger 
    console.log("outlettype", this.selectedOutletType)
    if(this.selectedOutletType!=""){
      this.random();
    }

  }

  random(){
    this.ds.GetVisicoolerList(this.selectedOutletType).subscribe(res => {
      this.visicoolerList = res;
      console.log("this.visicoolerList",this.visicoolerList)
    },
      error => {
        console.log("error:Invalid API")
      },
    )
  }
}
