import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { InputPara } from 'src/app/models/kpi-models';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterComponent } from '../filter/filter.component';
import { MatDialog } from '@angular/material/dialog';
import { DataService } from 'src/app/service/data.service';
import { NotifyMeService } from 'src/app/service/notify-me.service';

@Component({
  selector: 'app-village-productivity',
  templateUrl: './village-productivity.component.html',
  styleUrls: ['./village-productivity.component.css']
})
export class VillageProductivityComponent implements OnInit {
  FieldName: any;
  FieldValue: any;
  ParaName: any;
  UserID: any;
  vpdata: any;
  periodList: any[] = [];
  selectedperiod: any;
  selectedOutletRange: any;
  selectedpopslab: any;
  IsRDValue: boolean=false;
  rdData: any=null;
  device: any;
  deviceType: any;
  input:InputPara=new InputPara();
  popslabListForVP: any[] = [];
  outletrangeListForVP: any[] = [];
  isRd: boolean = false;
  RLAType: any;
  billslabListForVP: any[]=[];
  selectedbillRange: any;
  token: any;
  lat:any;
  lng:any;
  UserType:any;

  constructor(private activatedRoute: ActivatedRoute, private dialog: MatDialog,private notifyMeService: NotifyMeService,
    private ds: DataService,private fb: FormBuilder,private router: Router, private headerService: HeaderService, private mds: MongoDataService) { }

  ngOnInit(): void {
    this.headerService.setActiveURl('/villageProductivityData');
    this.headerService.setTitle("Village Productivity");
    this.token = localStorage.getItem("TokenNo");
    this.UserType = localStorage.getItem("UserType");
    this.activatedRoute.paramMap.forEach(params => {
      this.FieldValue = params.get('FieldValue');
      this.FieldName = params.get('FieldName');
      this.UserID = params.get('UserID');
      this.ParaName = params.get('ParaName');
      this.selectedOutletRange = params.get('selectedOutletRange');
      this.selectedpopslab = params.get('selectedpopslab');
      this.selectedbillRange = params.get('selectedbillRange');
      this.vpdata = [];
      this.device = localStorage.getItem("Device");
      this.deviceType = localStorage.getItem("DeviceType");
      this.selectedperiod = params.get('selectedperiod');
      // if(this.FieldName=="rd")
      // {
      //   let obj=localStorage.getItem("rdData");
      //   this.rdData = JSON.parse(obj || '{}');
      //   this.IsRDValue=true;

      // }
      // else{this.IsRDValue=false;}
      this.GetGeoLocation();
      this.loadData();
    });
  }

  GetGeoLocation() {
    debugger
    if (navigator) {
      navigator.geolocation.getCurrentPosition(pos => {
        this.lat = pos.coords.latitude;
        console.log("  this.lat", this.lat)
        this.lng = pos.coords.longitude;
        this.SetapplicationLogData();
      }, this.handleLocationError, { timeout: 30000 });
    }
    else {
      this.notifyMeService.showError('Location Could not be captured', 'Mera APP');
    }
  }

  handleLocationError(error: { code: any; }) {
    switch (error.code) {
      case 3:
        this.notifyMeService.showError('Timeout in Position capture', 'Mera APP V2');
        break;
      case 2:
        this.notifyMeService.showError('Device is not capable of Position capture', 'Mera APP V2');
        break;
      case 1:
        this.notifyMeService.showError('You denied for Position capture', 'Mera APP V2');
        break;
    }
  }

  SetapplicationLogData() {
    debugger
    let request = {
      "userid": this.UserID,
      "usertype": this.UserType,
      "formname": "villageProductivityData",
      "actiondesc": "village Productivity Data" + " " + this.FieldName,
      "fieldname": this.FieldName,
      "fieldvalue": this.FieldValue,
      "latitude": this.lat,
      "longitude": this.lng
    }
    this.ds.SetapplicationLogData(request).subscribe(res => {
      console.log("res", res)
    })
  }

  billslabChange(e: any): void {
    this.selectedbillRange = e.source._value;
  }
  loadData(): void {
    this.input.FieldName=this.FieldName;
    this.input.FieldValue=this.FieldValue;
    this.input.TagName = "ALLINDIA";
    this.input.Datatype=this.ParaName;
    this.input.UserID=this.UserID;
    debugger;
    this.mds.getVillagedata(this.input, this.token, this.device, this.deviceType,'Village Productivity').subscribe(res => {
      debugger;
      this.vpdata = res;
      this.periodList = [...new Set(res.map((bill: { period: any; }) => bill.period))].sort();
      this.popslabListForVP = [...new Set(res.map((bill: { popslab: any; }) => bill.popslab))].sort();
      this.selectedpopslab = this.popslabListForVP[0];
      this.outletrangeListForVP = [...new Set(res.map((bill: { outletrange: any; }) => bill.outletrange))].sort();
    //  this.selectedOutletRange = this.outletrangeListForVP[0];
      this.billslabListForVP = [...new Set(res.map((bill: { billslab: any; }) => bill.billslab))].sort();
    //  this.selectedbillRange = this.billslabListForVP[0];
    });
  }
  ChangeRLA(e:any): void {
    this.RLAType = e.source._value;

  }
  PeriodChange(e: any): void {
    this.selectedperiod = e.source._value;
  }
  outletrangeChange(e: any): void {
    this.selectedOutletRange = e.source._value;
  }
  popslabChange(e: any): void {
    this.selectedpopslab = e.source._value;
  }

  filterItemsOfTypeUrjaaVP(type: any) {
    return type != null ? type.filter((x: { [x: string]: any; }) => (x['period'] == this.selectedperiod && x['popslab'] == this.selectedpopslab && x['outletrange'] == this.selectedOutletRange && x['billslab'] == this.selectedbillRange)) : null;
  }

  RowClick(fieldName: any, fieldvalue: any, e: any): void {
    // this.FieldName=this.FieldName.toUpperCase()=='ALLINDIA'?'BRANCH':this.FieldName.toUpperCase()=='BRANCH'?'REGION': this.FieldName.toUpperCase()=='REGION'?'ASMAREA': this.FieldName.toUpperCase()=='ASMAREA'?'SOTERRITORY' : this.FieldName.toUpperCase()=='SOTERRITORY'?'RD' : 'RD';
    // if(this.FieldName=="RD")
    // {
    //   let rdData = e;
    //   localStorage.setItem("rdData", JSON.stringify(rdData));
    // }
    // else {
    //   localStorage.setItem("rdData", '');
    // }

    if (fieldName == "substk") {

    } else {
      this.router.navigate(['/home/villageProductivityData/', fieldName, fieldvalue, this.ParaName, this.UserID,this.selectedperiod, this.selectedOutletRange, this.selectedpopslab, this.selectedbillRange]);
    }
  }

  openFilter(){
    if (this.dialog.openDialogs.length == 0) {
      const dialogRef = this.dialog.open(FilterComponent, {
        closeOnNavigation: true,
        data: {
          "FieldName":localStorage.getItem("OriginalFieldName"),
          "FieldValue": localStorage.getItem("OriginalFieldValue"),
          "UserID": this.UserID,
          "MenuUpTo": "4",
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.FieldName=result.FieldName;
          this.FieldValue=result.FieldValue;
          this.loadData();
        }
      });
    }
  }

}
