<div class="row mt-2 bg_Color">
  <div class="col-12 mt-4">
    <span class="rd_Name">{{stockInoutDetails.rdName | titlecase}}</span>
    <span class="ml-1 rd_Code">{{stockInoutDetails.rdCode}}</span>
  </div>
  <div class="col-6 mt-2 mb-4">
    <span class="state_Name">{{stockInoutDetails.state}}</span>
  </div>
  <div class="col-6 mt-2 mb-4 text-right">
    <span class="status_Value">{{stockInoutDetails.status}}</span>
  </div>
</div>
<div class="row mt-3">
  <div class="col-7">
    <span class="sku_Text">SKU</span>
  </div>
  <div class="col-2 text-right p-0">
    <span class="sku_Text">Mrp ( <img class="img-fluid mr-1" src="assets/rupee.png" />)</span>
  </div>
  <div class="col-3 text-right">
    <span class="sku_Text">Amt ( <img class="img-fluid mr-1" src="assets/rupee.png" />)</span>
  </div>
</div>
<div class="row mt-4"></div>
<div *ngFor="let item of list; let i = index;">
  <div class="row">
    <div class="col-7 pr-0">
      <div class="row">
        <div class="col-12 pr-0">
          <span class="sku_Name">{{item.skuName | titlecase}}</span>
          <span class="ml-1 sku_Code">{{item.skucode}}</span>
        </div>
        <div class="col-12">
          <span class="batch_Text">Batch</span>
          <span class="ml-1 batch_No">{{item.batch}}</span>
        </div>
      </div>
    </div>
    <div class="col-2 text-right">
      <span class="mrp_Value">{{item.mrp}}</span>
    </div>
    <div class="col-3 text-right">
      <span class="mrp_Value">{{item.amt}}</span>
    </div>
  </div>
  <hr>
</div>
<br>
<br>
<br>
<br>
<br>
<div class="footer">
  <div class="row mt-3">
    <div class="col-6">
      <span class="ml-3 total_Amount_Text">TOTAL AMOUNT</span>
    </div>
    <div class="col-6 text-right">
      <img class="img-fluid mr-1" src="assets/rupee.png" />
      <span class="ml-1 mr-3 total_Amount_Text">{{totalAmt | number: '1.0-2'}}</span>
    </div>
  </div>
</div>
