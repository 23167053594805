import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { InputPara } from 'src/app/models/kpi-models';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterComponent } from '../filter/filter.component';
import { MatDialog } from '@angular/material/dialog';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { DataService } from 'src/app/service/data.service';

@Component({
  selector: 'app-kpi-productivity-data',
  templateUrl: './kpi-productivity-data.component.html',
  styleUrls: ['./kpi-productivity-data.component.css']
})
export class KpiProductivityDataComponent implements OnInit {
  FieldName: any;
  FieldValue: any;
  TagName: any;
  DataType: any;
  UserID: any;
  productivitydata: any;
  periodList: any[] = [];
  selectedperiod: any;
  selectedOutletRange: any;
  selectedpopslab: any;
  IsRDValue: boolean=false;
  rdData: any=null;
  device: any;
  deviceType: any;
  input:InputPara=new InputPara();
  popslabListForVP: any[] = [];
  outletrangeListForVP: any[] = [];
  isRd: boolean = false;
  RLAType: any;
  billslabListForVP: any[]=[];
  selectedbillRange: any;
  token: any;
  lat:any;
  lng:any;
  UserType:any;
  constructor(private activatedRoute: ActivatedRoute, 
    private dialog: MatDialog,private fb: FormBuilder,private router: Router,
     private headerService: HeaderService, private mds: MongoDataService,private notifyMeService: NotifyMeService, private ds: DataService) { }

  ngOnInit(): void {
    this.headerService.setActiveURl('/kpiProductivity');
    this.headerService.setTitle("Productivity");
    this.token = localStorage.getItem("TokenNo");
    this.UserType = localStorage.getItem("UserType");
    this.activatedRoute.paramMap.forEach(params => {
      this.FieldValue = params.get('FieldValue');
      this.FieldName = params.get('FieldName');
      this.UserID = params.get('UserID');
      this.TagName = params.get('TagName');
      this.DataType = params.get('DataType');
      this.selectedperiod = params.get('selectedperiod');
      this.productivitydata = [];
      this.device = localStorage.getItem("Device");
      this.deviceType = localStorage.getItem("DeviceType");
      debugger
      this.GetGeoLocation();
      this.loadData();
    });
  }

  GetGeoLocation() {
    debugger
    if (navigator) {
      navigator.geolocation.getCurrentPosition(pos => {
        this.lat = pos.coords.latitude;
        console.log("  this.lat", this.lat)
        this.lng = pos.coords.longitude;
        this.SetapplicationLogData();
      }, this.handleLocationError, { timeout: 30000 });
    }
    else {
      this.notifyMeService.showError('Location Could not be captured', 'Mera APP');
    }
  }

  handleLocationError(error: { code: any; }) {
    switch (error.code) {
      case 3:
        this.notifyMeService.showError('Timeout in Position capture', 'Mera APP V2');
        break;
      case 2:
        this.notifyMeService.showError('Device is not capable of Position capture', 'Mera APP V2');
        break;
      case 1:
        this.notifyMeService.showError('You denied for Position capture', 'Mera APP V2');
        break;
    }
  }

  SetapplicationLogData() {
    debugger
    let request = {
      "userid": this.UserID,
      "usertype": this.UserType,
      "formname": "kpiProductivityData",
      "actiondesc": "kpi Productivity Data" + " " + this.FieldName,
      "fieldname": this.FieldName,
      "fieldvalue": this.FieldValue,
      "latitude": this.lat,
      "longitude": this.lng
    }
    this.ds.SetapplicationLogData(request).subscribe(res => {
      console.log("res", res)
    })
  }

  loadData(): void {
    this.input.FieldName=this.FieldName;
    this.input.FieldValue=this.FieldValue;
    this.input.TagName = this.TagName;
    this.input.Datatype=this.DataType;
    this.input.UserID=this.UserID;
    this.mds.getProductivity(this.input, this.token, this.device, this.deviceType, 'Productivity').subscribe(res => {
      this.productivitydata = res;
      this.periodList = [...new Set(res.map((bill: { period: any; }) => bill.period))].sort();
    });
  }

  PeriodChange(e: any): void {
    this.selectedperiod = e.source._value;
  }

  filterItemsOfTypeProductivity(type: any) {
    return type != null ? type.filter((x: { [x: string]: any; }) => (x['period'] == this.selectedperiod)) : null;
  }

  RowClick(fieldName: any, fieldvalue: any): void {
    if (fieldName == "pc") {

    } else {
      this.router.navigate(['/home/kpiProductivity/', fieldName, fieldvalue, this.TagName, 'details', this.UserID, this.selectedperiod]);
    }
  }

  openFilter(){
    if (this.dialog.openDialogs.length == 0) {
      const dialogRef = this.dialog.open(FilterComponent, {
        closeOnNavigation: true,
        data: {
          "FieldName":localStorage.getItem("OriginalFieldName"),
          "FieldValue": localStorage.getItem("OriginalFieldValue"),
          "UserID": this.UserID,
          "MenuUpTo": "5",
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.FieldName=result.FieldName;
          this.FieldValue=result.FieldValue;
          this.loadData();
        }
      });
    }
  }

  dayWise(fieldname:any, fieldvalue:any ) {
    this.router.navigate(['/home/kpiProductivityDaywise/',fieldname, fieldvalue ]);
  }

}
