<div class="container-fluid">
    <br>
    <div class="row mt-3" *ngFor="let item of list"  >
        <div class="col-3 p-0">
            <img class="img-fluid user_Profile" [src]="item.photo"  (click) = "show(item.photo)"/>
        </div>
        <div class="col-9" (click)="rowClick(item.meetingID)">
            <div class="row">
                <div class="col-5 p-0 text-left">
                    <span class="mdate" >{{item.startTime| date}}</span>
                </div>
                <div class="col-7 p-0 text-right">
                    <span class="mtime" >{{item.startTime| date:'shortTime' }} To {{item.endTime| date:'shortTime' }}</span>
                 </div>
            </div>
            <div class="row">
                <div class="col-12 p-0">
                    <span class="mname" >{{item.meetingType}}</span>
                </div>
            </div>
            <div class="row">
                <div class="col-12 p-0">
                    <span class="mnote" >{{item.meetingNote}}</span>
                </div>
            </div>
        </div>
        <hr class="hr_cust">
    </div>
    <br><br><br>
    <div  class="footer">
        <div class="row">
          <div class="col-12 pl-0" (click)="CheckIN()">
            <button class="btn btn_Ok">CHECK IN</button>
          </div>
        </div>
      </div>
      <div [style.display]="showModal ? 'block' : 'none'" class="modal" id="imagemodal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="myModalLabel">Image preview</h4>
            </div>
            <div class="modal-body">
              <img [src]="selectedimagepath" id="imagepreview" style="width: 100%; height: 425px;" >
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-default" data-dismiss="modal" (click) = "hide()">Close</button>
            </div>
          </div>
        </div>
    <br> <br> <br><br> <br>
</div>
