import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HeaderService } from 'src/app/service/header.service';
import { GetVPOORVISIScan,InputPara,Resultdata } from 'src/app/models/kpi-models';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterComponent } from '../filter/filter.component';
import { DataService } from 'src/app/service/data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-asset-approval',
  templateUrl: './asset-approval.component.html',
  styleUrls: ['./asset-approval.component.css']
})
export class AssetApprovalComponent implements OnInit {
  @ViewChild('openModalEdit', { static: true }) elRefOpenModalEdit: ElementRef | undefined;
  @ViewChild('openModalMsg', { static: true }) elRefopenModalMsg: ElementRef | undefined;
  @ViewChild('openModalThankYou', { static: true }) elRefOpenModalThankYou: ElementRef | undefined;

  paraCode: any;
  paraName: any;
  paraValue: any;
  UserID: any;
  assetApprovalData: any;
  periodList: any[] = [];
  device: any;
  deviceType: any;
  input:InputPara=new InputPara();
  token: any ;
  FieldValue: any;
  userType: any;
  isOutlet: boolean = false;
  level: any;
  isSelected: boolean = false;
  isSelectedObj: any;
  dtTemplate: any[]=[];
  editVisicoolerStatusForm = this.fb.group({
    status: ['', [Validators.required]],
  });
  outletcode: any;
  nvCount: number = 0;
  msg: string = '';
  prevParaname: any;
  prevParavalue: any;
  prevParacode: any;
  formname:any;
  actiondesc:any;
  fieldName:any;
  constructor(private activatedRoute: ActivatedRoute, private dialog: MatDialog,private fb: FormBuilder,private router: Router, private headerService: HeaderService, 
    private ds: DataService, private modalService: NgbModal,private cs:CommonUtilitiesService,) { }

  ngOnInit(): void {
    this.headerService.setActiveURl('/assetApproval');
    this.headerService.setTitle("Asset Approval");
    this.token = localStorage.getItem("TokenNo");
    this.FieldValue = localStorage.getItem("FieldValue");
    this.userType = localStorage.getItem("UserType");
    this.msg = "Please change the status of Not Visisted Outlets";

    this.activatedRoute.paramMap.forEach(params => {
      this.paraCode = params.get('paracode');
      if(this.paraCode == '0') {
        this.paraCode = '';
        this.FieldValue = localStorage.getItem("FieldValue");
      }
      this.paraName = params.get('paraname');
      if(this.paraName == '0') {
        this.paraName = '';
      }
      this.paraValue = params.get('paravalue');
      if(this.paraValue == '0') {
        this.paraValue = '';
      }
      this.UserID = params.get('UserID');
      this.assetApprovalData = [];
      this.device = localStorage.getItem("Device");
      this.deviceType = localStorage.getItem("DeviceType");
      if(this.paraName == 'RDCode') {
        this.FieldValue = localStorage.getItem("assetApprovalFieldValue");
      }
      this.dtTemplate = [];
      this.isSelected = false;
      this.nvCount = 0;
      this.fieldName = localStorage.getItem("FieldName");
      this.formname="assetApproval";
      this.actiondesc="Asset Approval"+" "+this.paraCode;
      this.cs.GetGeoLocation(this.UserID,this.userType,this.formname,this.actiondesc,this.fieldName,this.FieldValue);
      this.loadData();
      // this.levelData();
    });
  }

  loadData(): void {
    const dataToSend = {
      UserID: this.UserID,
      paracode: this.paraCode,
      paraname: this.paraName,
      paravalue: this.paraValue,
    };
    console.log("dataToSend",dataToSend)
    this.ds.ATSAsset_GetDataForApproval(dataToSend, this.token, this.device, this.deviceType,'Asset Approval').subscribe(res => {
      this.assetApprovalData = res;
      console.log("assetApprovalData",this.assetApprovalData)
      if(this.assetApprovalData.length > 0 && this.assetApprovalData[0].outletcode) {
        this.isOutlet = true;
        this.level = 'outlet';
        this.assetApprovalData.forEach((element: any) => {
          element["selected"] = false;
        });
      } else {
        this.isOutlet = false;
        this.level = this.assetApprovalData[0].paraName;
      }
      console.log("isOutlet",this.isOutlet)

      // if (this.userType?.toUpperCase() == "SO" && this.paraName == '') {
      //   this.FieldValue = this.assetApprovalData[0].code;
      // }
    });
  }

  RowClick(item: any): void {
    if (item.paraName == "RDCode" && (this.userType?.toUpperCase() == "ASM" || this.userType?.toUpperCase() == "BSM")) {

    } else {
      this.FieldValue = item.code;
      if(this.paraName == '') {
        localStorage.setItem("assetApprovalFieldValue",this.FieldValue);
      }

      if(item.paraName != "PCUID" ) {
        if(item.paraName != "RDCode" ) {
          this.router.navigate(['/home/assetApproval/', item.paraCode, item.paraName, item.code, this.UserID]);
        } else {
          this.router.navigate(['/home/assetApproval/', item.paraCode, item.paraName, item.rdCode, this.UserID]);
        }
      } else {
        this.router.navigate(['/home/assetApproval/', item.paraCode, item.paraName, item.pcuid, this.UserID]);
      }
    }

  }

  clickOnSelect(e: any, id: any, item: any) {
    if (e.target.checked) {
      this.isSelected = e.target.value;
      this.isSelectedObj = this.assetApprovalData[id];
      console.log("isSelectedObj",this.isSelectedObj);
      if(this.isOutlet) {
        // this.dtTemplate.push({Paraname: this.isSelectedObj.outletcode, Paravalue: this.isSelectedObj.verificationstatus});
        this.dtTemplate.push({Para1: this.isSelectedObj.outletcode, Para2: this.isSelectedObj.verificationstatus, Para3: "Approved", Para4: this.isSelectedObj.visicoolerType, Para5: "", Para6: "", Para7: "", Para8: "", Para9: "", Para10: "",});
      } else {
        if(this.isSelectedObj.paraName != "PCUID") {
          if(this.isSelectedObj.paraName != "RDCode" ) {
            // this.dtTemplate.push({Paraname: this.isSelectedObj.code, Paravalue: ""});
            this.dtTemplate.push({Para1: this.isSelectedObj.code, Para2: "", Para3: "Approved", Para4: "", Para5: "", Para6: "", Para7: "", Para8: "", Para9: "", Para10: "",});
          } else {
            // this.dtTemplate.push({Paraname: this.isSelectedObj.rdCode, Paravalue: ""});
            this.dtTemplate.push({Para1: this.isSelectedObj.rdCode, Para2: "", Para3: "Approved", Para4: "", Para5: "", Para6: "", Para7: "", Para8: "", Para9: "", Para10: "",});
          }
        } else {
          // this.dtTemplate.push({Paraname: this.isSelectedObj.pcuid, Paravalue: ""});
          this.dtTemplate.push({Para1: this.isSelectedObj.pcuid, Para2: "", Para3: "Approved", Para4: "", Para5: "", Para6: "", Para7: "", Para8: "", Para9: "", Para10: "",});
        }
      }
      console.log("dtTemplate",this.dtTemplate);
    } else {
      this.isSelected = false;
      this.isSelectedObj = null;
      this.dtTemplate.forEach((element: any, index: any) => {
        if(this.isOutlet) {
          if (element.Para1 == item.outletcode) {
            this.dtTemplate.splice(index, 1);
          }
        } else {
          if(this.assetApprovalData[0].paraName != "PCUID") {
            if(this.assetApprovalData[0].paraName != "RDCode" ) {
              if (element.Para1 == item.code) {
                this.dtTemplate.splice(index, 1);
              }
            } else {
              if (element.Para1 == item.rdCode) {
                this.dtTemplate.splice(index, 1);
              }
            }
          } else {
            if (element.Para1 == item.pcuid) {
              this.dtTemplate.splice(index, 1);
            }
          }
        }
      });
      console.log("dtTemplate",this.dtTemplate);
      if(this.dtTemplate.length > 0) {
        this.isSelected = true;
      }
    }
  }


  approve(){
    this.nvCount = 0;
    if(this.isOutlet) {
      this.dtTemplate.forEach((element: any) => {
        if(element.Para2 == "Not Visited")
        this.nvCount = 1;
      });
    } else {
      this.assetApprovalData.forEach((element: any) => {
        this.dtTemplate.forEach((ele: any) => {
          if(this.assetApprovalData[0].paraName != "PCUID") {
            if(this.assetApprovalData[0].paraName != "RDCode" ) {
              if (ele.Para1 == element.code) {
                this.nvCount += element.nvCount;
              }
            } else {
              if (ele.Para1 == element.rdCode) {
                this.nvCount += element.nvCount;
              }
            }
          } else {
            if (ele.Para1 == element.pcuid) {
              this.nvCount += element.nvCount;
            }
          }
        });
      });
    }
    console.log("nvCount",this.nvCount)

    if(this.nvCount > 0) {
      this.openModal(this.elRefopenModalMsg);
    } else {
      const dataToSend = {
        DTTemplate: this.dtTemplate,
        UserID : this.UserID,
        Level: this.level,
      };
      console.log("dataToSend",dataToSend);
      this.ds.ATSAsset_SetApproval(dataToSend, this.token, this.device, this.deviceType,'Reject Reason Asset Approval').subscribe(res => {
        console.log("ATSAsset_SetApproval",res);
        localStorage.removeItem("dtTemplate");
        this.openModal(this.elRefOpenModalThankYou);
      });
    }
  }

  reject() {
    this.nvCount = 0;
    this.assetApprovalData.forEach((element: { nvCount: number; }) => {
      this.nvCount += element.nvCount;
    });
    console.log("nvCount",this.nvCount)
    if(this.nvCount > 0) {
      this.openModal(this.elRefopenModalMsg);
    } else {
      localStorage.setItem("dtTemplate",JSON.stringify(this.dtTemplate));
      this.router.navigate(['/home/facialRecRejReasonApproval'], { queryParams: { selected: 'Asset', level: this.level } });
    }
  }

  onClickEdit(model: any, verificationstatus: any, outletcode: any){
    if(verificationstatus != "Available") {
      this.outletcode = outletcode;
      this.editVisicoolerStatusForm.patchValue({
        status: verificationstatus,
      });
      this.openModal(model);
    }
  }

  modalClose() {
    this.modalService.dismissAll();
  }

  save() {
    this.assetApprovalData.forEach((element: any) => {
      if(element.outletcode == this.outletcode) {
        element.verificationstatus = this.editVisicoolerStatusForm.value.status;
      }
    });
    console.log("assetApprovalData after edit",this.assetApprovalData)
    this.dtTemplate.forEach((element: any) => {
      if(element.Para1 == this.outletcode) {
        element.Para2 = this.editVisicoolerStatusForm.value.status;
      }
    });
    console.log("dtTemplate after edit",this.dtTemplate)
    this.modalService.dismissAll();
  }

  openModal(modal: ElementRef<any> | undefined) {
    this.modalService.open(modal,
      {
        size: 'lg',
        backdrop: 'static',
        windowClass: '',
        keyboard: false,
        centered: true,
      })
  }

  okay(){
    this.modalService.dismissAll();
    this.router.navigate(['/home/assetApproval/', 0, 0, 0, this.UserID]);
  }

  toggleCheckboxAll(event: any) {
    if (event.target.checked) {
      this.isSelected = event.target.checked;
      this.assetApprovalData.forEach((element: any) => {
        this.dtTemplate.push({Para1: element.outletcode, Para2: element.verificationstatus, Para3: "Approved", Para4: element.visicoolerType, Para5: "", Para6: "", Para7: "", Para8: "", Para9: "", Para10: "",});
      });
    } else {
      this.isSelected = false;
      this.dtTemplate = [];
    }
    const checked = event.target.checked;
    this.assetApprovalData.forEach((item: { selected: any; }) => item.selected = checked);
    console.log("In toggleCheckboxAll assetApprovalData",this.assetApprovalData)
    console.log("In toggleCheckboxAll dtTemplate",this.dtTemplate)
  }

  levelData() {
    if(this.paraName == "RDCode" && this.userType?.toUpperCase() == "SO") {
      this.prevParaname = this.paraName;
      this.prevParavalue = this.paraValue;
      this.prevParacode = this.paraCode;
      console.log("url", '/home/assetApproval/', this.prevParacode, this.prevParaname, this.prevParavalue, this.UserID)
    } else if(this.userType?.toUpperCase() == "ASM") {
      this.prevParaname = 0;
      this.prevParavalue = 0;
      this.prevParacode = 0;
      console.log("url", '/home/assetApproval/', this.prevParacode, this.prevParaname, this.prevParavalue, this.UserID)
    } else if(this.paraName == "" && this.userType?.toUpperCase() == "BSM") {
      this.prevParaname = 0;
      this.prevParavalue = 0;
      this.prevParacode = 0;
      console.log("url", '/home/assetApproval/', this.prevParacode, this.prevParaname, this.prevParavalue, this.UserID)
    } else if(this.paraName == "ASMArea" && this.userType?.toUpperCase() == "BSM") {
      this.prevParaname = this.paraName;
      this.prevParavalue = this.paraValue;
      this.prevParacode = this.paraCode;
      console.log("url", '/home/assetApproval/', this.prevParacode, this.prevParaname, this.prevParavalue, this.UserID)
    } else {
      this.prevParaname = this.paraName;
      this.prevParavalue = this.paraValue;
      this.prevParacode = this.paraCode;
    }
  }
}
