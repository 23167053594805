import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { DataService } from 'src/app/service/data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-stock-inout-details-approval',
  templateUrl: './stock-inout-details-approval.component.html',
  styleUrls: ['./stock-inout-details-approval.component.css']
})
export class StockInoutDetailsApprovalComponent implements OnInit {
  @ViewChild('openModalThankYou', { static: true }) elRefOpenModalThankYou: ElementRef | undefined;
  deviceType: any;
  device: any;
  userType: string | null = '';
  userId: any;
  stockInoutDetails: any;
  isReject: boolean = false;
  token: any;
  formname:any;
  actiondesc:any;
  fieldName: any;
  fieldValue: any;

  constructor(private activatedRoute: ActivatedRoute, private cs:CommonUtilitiesService,private router: Router, private headerService: HeaderService, private mds: MongoDataService, private ds: DataService, private modalService: NgbModal, private datePipe: DatePipe,) { }

  ngOnInit(): void {
    this.headerService.setTitle('In/Out Details');
    this.headerService.setActiveURl('/stockInoutDetailsApproval');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.token = localStorage.getItem("TokenNo");
    this.userType = localStorage.getItem("UserType");
    this.userId = localStorage.getItem("UserID");
    this.stockInoutDetails = JSON.parse(localStorage.getItem("stockInoutDetails") || '{}');
    console.log("stockInoutDetails",this.stockInoutDetails)

    
    this.fieldName = localStorage.getItem("FieldName");
    this.fieldValue = localStorage.getItem("FieldValue");
    this.formname = "stockInoutDetailsApproval"
    this.actiondesc = "Stock In Out Details Approval";
    this.cs.GetGeoLocation(this.userId, this.userType, this.formname, this.actiondesc, this.fieldName, this.fieldValue);
  }

  approve(){
    const dataToSend = {
      RDCode: this.stockInoutDetails.rdCode,
      TRN: this.stockInoutDetails.trn,
      TRNNO: this.stockInoutDetails.trnNo,
      TRNType: this.stockInoutDetails.trnType,
      TRNDATE: this.stockInoutDetails.trnDate,
      Status: 'A',
      RDCode1: '',
      UserType: this.userType,
      UserID: this.userId,
    };
    console.log("dataToSend",dataToSend)
    this.ds.setSTKInOutApprovalData(dataToSend, this.token, this.device, this.deviceType, 'In/Out Details').subscribe(res => {
      console.log("setSTKInOutApprovalData",res)
      this.openModal(this.elRefOpenModalThankYou);
    });
  }

  openModal(modal: ElementRef<any> | undefined) {
    this.modalService.open(modal,
      {
        size: 'lg',
        backdrop: 'static',
        windowClass: '',
        keyboard: false,
        centered: true,
      })
  }

  reject(){
    this.isReject=true;
    const dataToSend = {
      RDCode: this.stockInoutDetails.rdCode,
      TRN: this.stockInoutDetails.trn,
      TRNNO: this.stockInoutDetails.trnNo,
      TRNType: this.stockInoutDetails.trnType,
      TRNDATE: this.stockInoutDetails.trnDate,
      Status: 'R',
      RDCode1: '',
      UserType: this.userType,
      UserID: this.userId,
    };
    console.log("dataToSend",dataToSend)
    this.ds.setSTKInOutApprovalData(dataToSend, this.token, this.device, this.deviceType, 'Enter Reason').subscribe(res => {
      console.log("setSTKInOutApprovalData",res)
      this.openModal(this.elRefOpenModalThankYou);
    });
  }

  selectRD(){
    this.router.navigate(['/home/stockInoutSelectrdApproval']);
  }

  okay(){
    this.modalService.dismissAll();
    this.router.navigate(['/home/stockInoutApproval']);
  }

  modalClose() {
    this.modalService.dismissAll();
    this.router.navigate(['/home/stockInoutApproval']);
  }

  clickOnVoucherDetails() {
    this.router.navigate(['/home/stockInoutVoucherDetailsApproval']);
  }
}
