<div>
    <!-- <div class="row mt-4">
      <div [class]="billclass" (click)="OnClickRLA('Audited')" >Audited RLA</div>
      <div [class]="totalclass" (click)="OnClickRLA('Visi')" >Visi RLA</div>
      </div> -->
    <div class="row mt-4">
        <div class="row mt-1 col-12">
            <div class="col-9">        
              <input class="search col-12 bi bi-search" type="text"  placeholder=" Search Outlet"  (keyup)="getUserIdsFirstWay($event)" id="userIdFirstWay" (change)="SearchText($event)" autocomplete="off">
              <!-- <datalist id="dynmicUserIds">
                <option *ngFor="let item of orignal_resList" [value]="item.paravalue">{{item.paravalue}}</option>
              </datalist> -->
            </div>
     
      <!-- <div class="col-3 text-right">
        <img src="../../../assets/filter.png" class="img-fluid" (click)="openFilter()" /><sup><i
            class="fa fa-circle fa-sm" style="color: #eaab5e;font-size: 5px;"></i></sup>&nbsp;<span
          class="filter_text">Filter</span>
      </div> -->
    </div>
    <br>
   
    <div class="row mt-2 " style="width: 100%;" *ngFor="let item of resList" >
      <div class="col-12 ">
        <div class="row  pt-2" style="background-color: #EBEBEB;min-height: 35px;">
          <div class="col-8">
            <span class="header_text ml-2 bold">{{ item.outletname}}</span>
          </div>
          <div class="col-4">
            <span class="header_text bold  float-right text-right">{{item.outletcode}}</span>
          </div>
        </div>
        <div class="row mt-1 border-bottom" style="min-height: 35px;">
            <div class="col-8">
              <span class="Head_text ml-2 opacity-75">Month Till Date Score</span>
            </div>
            <div class="col-4">
              <span class="Head_text float-right text-right">{{item.mtdscore}}</span>
            </div>
          </div>
          <div class="row mt-1 border-bottom" style="min-height: 35px;">
            <div class="col-8">
              <span class="Head_text ml-2 opacity-75">Last Visit Score</span>
            </div>
            <div class="col-4">
              <span class="Head_text float-right text-right">{{item.pcscore}}</span>
            </div>
          </div>
          <div class="row mt-1 border-bottom" style="min-height: 35px;">
            <div class="col-8">
              <span class="Head_text ml-2 opacity-75">Visicooler Location</span>
            </div>
            <div class="col-4">
              <span [class]="item.visicoolerlocation">{{item.visicoolerlocation}}</span>
            </div>
            <div class="col-12 mt-2">
                <span class="Head_text ml-2 remark text-right">{{item.location}}</span>
                
            </div>
          </div>
          <div class="row mt-1 border-bottom" style="min-height: 35px;">
            <div class="col-8">
              <span class="Head_text ml-2 opacity-75">Visicooler Close-up</span>
            </div>
            <div class="col-4">
              <span [class]="item.closeup">{{item.closeup}}</span>
            </div>
            
          </div>
          <div class="row mt-2 " style="min-height: 35px;">
            <div class="col-12 text-center">
              <span class="col-12" style="color:black;font-weight: bold; font-size: 12px;">Analyzable Last Visit Score</span>
            </div>
            <div class="col-12 mt-2 text-center">
              <!-- <span class="Head_text mr-2 ">{{item.purity}}</span>Purity -->
                <span class="Head_text mr-2 ">{{item.plano}}</span>Charging
                |
                <span class="Head_text mr-2 ">{{item.hotzone}}</span>Location
            </div>
          </div>
       
        </div>
       
      </div>    
    </div>  
    <br>
    <br>
    <br>
  
  </div>
  

