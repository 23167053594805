import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { OifApproval, VisicoolerMapping, inputOifapproval } from 'src/app/models/kpi-models';
import { DataService } from '../../service/data.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { FilterComponent } from '../filter/filter.component';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';
import { NullTemplateVisitor } from '@angular/compiler';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';import { NotifyMeService } from 'src/app/service/notify-me.service';
 '@angular/router';


@Component({
  selector: 'app-outlet-detail-oif',
  templateUrl: './outlet-detail-oif.component.html',
  styleUrls: ['./outlet-detail-oif.component.css']
})
export class OutletDetailOIFComponent implements OnInit {
  @ViewChild('openModalThankYou', { static: true }) elRefOpenModalThankYou: ElementRef | undefined;
  UserID: any;
  RequirementType: any;
  UserType: any;
  ID: any;
  OutletDtl: any;
  TokenNo: any;
  ClientID: any;
  isLongPress: boolean = false;
  isSelected: boolean = false;
  userType: string | null = '';
  list: any;
  selected = -1;
  isSelectedObj: any;
  msg: string | null = '';

  public oifapproval: OifApproval = new OifApproval();
  public iapproval: inputOifapproval = new inputOifapproval();

  public visimapping: VisicoolerMapping = new VisicoolerMapping();
  name: string | null = '';
  animal: string | null = '';
  //outletList: OIFelizibleOutlets;
  //public visicoolerMapping: VisicoolerMapping = new VisicoolerMapping()
  //reason: Reason;

  NewVISI: string | null = '';
  NewVISIID: number = 0;
  ReasonID: number = 0;
  ApprovalType: string | null = '';
  RCCMStatus: string | null = '';
  SubStkUID: string | null = '';
  IsSaved: boolean = false;
  Title: string | null = '';
  showModal: boolean = false;
  visiList: any;
  reasonlist: any;
  ReasonRequired: boolean = false;
  deviceType: any;
  device: any;
  OutletCode: any;


  token: any;
  suggVisicooler: string | null = '';
  FormName: any;
  lat:any;
  lng:any;
  FieldName:any;
  FieldValue:any;

  constructor(private headerService: HeaderService, private activatedRoute: ActivatedRoute,
    private notifyMeService: NotifyMeService, private fb: FormBuilder, private router: Router, private mds: MongoDataService, private ds: DataService, private modalService: NgbModal,) { }

  ngOnInit(): void {
    this.headerService.setActiveURl('/outletdetailoif');
    this.headerService.setTitle("Outlet Details");
    this.TokenNo = localStorage.getItem("TokenNo");
    this.ClientID = localStorage.getItem("ClientID");
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.token = localStorage.getItem("TokenNo");
    this.ApprovalType = localStorage.getItem('ApprovalType');
    this.FieldName = localStorage.getItem("FieldName");
    this.FieldValue = localStorage.getItem("FieldValue");
    this.FormName = 'Outlet Details';
    this.activatedRoute.paramMap.forEach(params => {
      this.UserID = params.get('UserID');
      this.RequirementType = params.get('RequirementType');
      this.ID = params.get('ID');
      this.UserType = params.get('UserType');
      this.GetGeoLocation();
      this.mds.getReasons(this.token, this.device, this.deviceType, this.FormName).subscribe(res => {

        this.visiList = res.filter((singleItem: { [x: string]: string; }) => singleItem['reasonType'].startsWith("Visicooler"));
        this.reasonlist = res.filter((singleItem: { [x: string]: string; }) => singleItem['reasonType'].startsWith("Exception"));
      });
      this.GetApprovalList(this.UserID, this.RequirementType, this.ID, this.UserType);

    });
  }


  GetGeoLocation() {
    debugger
    if (navigator) {
      navigator.geolocation.getCurrentPosition(pos => {
        this.lat = pos.coords.latitude;
        console.log("  this.lat", this.lat)
        this.lng = pos.coords.longitude;
        this.SetapplicationLogData();
      }, this.handleLocationError, { timeout: 30000 });
    }
    else {
      this.notifyMeService.showError('Location Could not be captured', 'Mera APP');
    }
  }

  handleLocationError(error: { code: any; }) {
    switch (error.code) {
      case 3:
        this.notifyMeService.showError('Timeout in Position capture', 'Mera APP V2');
        break;
      case 2:
        this.notifyMeService.showError('Device is not capable of Position capture', 'Mera APP V2');
        break;
      case 1:
        this.notifyMeService.showError('You denied for Position capture', 'Mera APP V2');
        break;
    }
  }

  SetapplicationLogData() {
    debugger
    let request = {
      "userid": this.UserID,
      "usertype": this.userType,
      "formname": "outletdetailoif",
      "actiondesc": "outlet detail oif",
      "fieldname": this.FieldName,
      "fieldvalue": this.ID,
      "latitude": this.lat,
      "longitude": this.lng
    }
    this.ds.SetapplicationLogData(request).subscribe(res => {
      console.log("res", res)
    })
  }


  GetApprovalList(UserID: string, RequirementType: string, ID: number, UserType: string) {
    this.OutletDtl = [];

    this.ds.GetEligibleOutletList(UserID, RequirementType, ID, UserType, this.TokenNo, this.ClientID, this.device, this.deviceType, this.FormName).subscribe(res => {

      console.log("GetOIFOutletDtl", res);
      this.OutletDtl = res as OifApproval;
      localStorage.setItem('oldVisi', this.OutletDtl[0].suggVisicooler);
      this.ApprovalType = this.OutletDtl[0].soApprovalStatus == 'PENDING' ? 'SO' : this.OutletDtl[0].asmApprovalStatus == 'PENDING' ? 'ASM' : 'RCCM';
      this.OutletCode = this.OutletDtl[0].outletCode;
      localStorage.setItem('ApprovalType', this.ApprovalType);
      if (this.RequirementType.toUpperCase() == 'REPLACEMENT') {
        this.NewVISIID = this.OutletDtl[0].newVisiCooler;
        for (let j in this.visiList) {
          if (this.visiList[j].reasonID == this.NewVISIID) {
            this.NewVISI = this.visiList[j].reasonName;
          }
        }
      }
      if (this.OutletDtl[0].newVisiCooler > 0) {
        let newVISIID = this.OutletDtl[0].newVisiCooler;
        this.NewVISIID = this.OutletDtl[0].newVisiCooler;
      }

      for (let j in this.visiList) {
        if (this.visiList[j].reasonName == this.OutletDtl[0].suggVisicooler) {
          this.NewVISIID = this.visiList[j].reasonID;
        }
      }


      console.log("GetOIFApprovalList", this.OutletDtl)

      this.suggVisicooler = this.OutletDtl[0].suggVisicooler;

    });

  }
  showVisiDialog() {
    this.showModal = true;
  }
  hide() {
    this.visimapping.Visicooler = this.OutletDtl[0].suggVisicooler;
    this.visimapping.FinalVisi = this.NewVISIID;
    this.checkIfReasonRequired();
    this.showModal = false;
  }
  OnChangeVISI(id: number, valueName: string) {
    this.NewVISIID = id;
    this.NewVISI = valueName;
  }
  checkIfReasonRequired(): void {
    this.ds.CheckVisicoolerMapping(this.visimapping, this.TokenNo, this.ClientID, this.device, this.deviceType, this.FormName).subscribe(data => {
      this.ReasonRequired = data[0].msg == 'Y' ? true : false;
    },
      error => { }
    );
  }

  changeReason(e: any) {
    this.ReasonID = e.target.value;
  }

  onNoClick() { this.hide(); }
  onYesClick() { this.hide(); }

  SaveRecords() {


    let oldVISI = localStorage.getItem('oldVisi');
    this.iapproval.ID = this.ID;
    this.iapproval.UserID = this.UserID;
    this.iapproval.Status = 'Approved';
    this.iapproval.ReasonID = this.ReasonID == null ? 0 : this.ReasonID;
    this.iapproval.VisiFinal = this.NewVISIID;
    this.iapproval.UserType = this.ApprovalType;
    this.iapproval.RCCMStatus = this.RCCMStatus == null ? '' : this.RCCMStatus;
    this.iapproval.SubStkUID = this.SubStkUID;
    this.iapproval.visicooler = oldVISI;
    this.iapproval.RequirementType = this.RequirementType;


    this.ds.SaveOIFRequest(this.iapproval, this.TokenNo, this.ClientID, this.device, this.deviceType, this.FormName).subscribe(data => {
      console.log(data);
      this.IsSaved = true;

      this.modalService.open(this.elRefOpenModalThankYou,
        {
          size: 'lg',
          backdrop: 'static',
          windowClass: '',
          keyboard: false,
          centered: true,
        });
    });

  }




  openModal(modal: ElementRef<any> | undefined) {
    // console.log('OpenModal called');
    this.modalService.open(modal,
      {
        size: 'lg',
        backdrop: 'static',
        windowClass: '',
        keyboard: false,
        centered: true,
      })
  }

  modalClose() {
    this.modalService.dismissAll();
    this.router.navigate(['/home/OifApproval']);
  }
}
