import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { DataService } from 'src/app/service/data.service';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-sub-rd-approval-details-edit',
  templateUrl: './sub-rd-approval-details-edit.component.html',
  styleUrls: ['./sub-rd-approval-details-edit.component.css']
})
export class SubRdApprovalDetailsEditComponent implements OnInit {
  deviceType: any;
  device: any;
  userType: string | null = '';
  userId: any;
  list: any = [1,2,3,4,5];
  fieldName: any;
  fieldValue: any;
  subRdApprovalDetails: any;
  photopath: any;
  formname:any;
  actiondesc:any;
  constructor(private activatedRoute: ActivatedRoute, private cs:CommonUtilitiesService,private router: Router, private headerService: HeaderService, private mds: MongoDataService, private notifyMeService:NotifyMeService, private ds: DataService,) { }

  ngOnInit(): void {
    this.headerService.setTitle('Sub RD Details');
    this.headerService.setActiveURl('/subRdApprovalDetailsEdit');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.userType = localStorage.getItem("UserType");
    this.userId = localStorage.getItem("UserID");
    this.photopath=localStorage.getItem("baseappurl") +"NewSubPhoto/";
    this.subRdApprovalDetails = JSON.parse(localStorage.getItem("subRdApprovalDetails") || '{}');
  
    console.log("subRdApprovalDetails",this.subRdApprovalDetails)

    
    this.fieldName = localStorage.getItem("FieldName");
    this.fieldValue = localStorage.getItem("FieldValue");
    this.formname = "subRdApprovalDetailsEdit"
    this.actiondesc = "Sub Rd Approval Details Edit";
    this.cs.GetGeoLocation(this.userId, this.userType, this.formname, this.actiondesc, this.fieldName, this.fieldValue);
  }

  back() {
    this.router.navigate(['/home/subRdApprovalDetails']);
  }
}
