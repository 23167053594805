import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/service/data.service';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-pc-tsidetails',
  templateUrl: './pc-tsidetails.component.html',
  styleUrls: ['./pc-tsidetails.component.css']
})
export class PcTSIDetailsComponent implements OnInit {
  @ViewChild('openModalThankYou', { static: true }) elRefOpenModalThankYou: ElementRef | undefined;
  list : any;
  deviceType: any;
  device: any;
  FormName: any;
  UserID: any;
  RdCode: any;
  PCCode: any;
  Status: any;
  UserType:any;
  IsUserValid: boolean=false;
  selectedimagepath: any;
  showModal: boolean=false;
  isReject: boolean = false;
photopathpc:any="https://prodapi.cilsales.net/PCImages/";
photopathcard:any="https://www.cilsales.net/PCICard/";
  token: any;
  fieldName: any;
  fieldValue: any;
  formname: any;
  actiondesc: any;

  constructor(private headerService: HeaderService,private cs:CommonUtilitiesService,private activatedRoute: ActivatedRoute,  private router: Router,private ds: DataService, private notifyMeService:NotifyMeService, private modalService: NgbModal,) { }

  ngOnInit(): void {
    this.headerService.setTitle("PC/TSI Details");
    this.headerService.setActiveURl('/pctsidetails');
    this.device = localStorage.getItem('Device');
    this.deviceType = localStorage.getItem('DeviceType');
    this.UserType = localStorage.getItem('UserType');
    this.token = localStorage.getItem("TokenNo");
    this.FormName = 'pc-tsidetails';
    this.UserID =localStorage.getItem('UserID');
    this.photopathpc =localStorage.getItem("baseapiurl") + "PCImages/";
    this.photopathcard =localStorage.getItem("baseappurl") + "PCICard/";
    this.activatedRoute.paramMap.forEach(params => {
      this.RdCode = params.get('RDCode');

      this.PCCode = params.get('PCCode');

      this.fieldName=localStorage.getItem("FieldName");
      this.fieldValue=localStorage.getItem("FieldValue");
      this.formname = "pctsidetails";
      this.actiondesc = "pctsidetails"+" "+this.UserID+" "+this.RdCode;
      this.cs.GetGeoLocation(this.UserID, this.UserType, this.formname, this.actiondesc, this.fieldName, this.fieldValue);
      this.loadData();
    });
  }
  loadData(){
    this.ds.salesmanMasterForApproval_List(this.UserID, this.RdCode, this.PCCode, this.token, this.device,this.deviceType,this.FormName).subscribe(res => {
      this.list = res;
    });
  }
  cancel(){
    if(this.UserType.toUpperCase() == "ASM" || this.UserType.toUpperCase() == "BSM"){
      this.isReject=true;
      this.Status = "REJECT"
      this.saveRecord();
      // this.router.navigate(['/home/pc/tsi']);
    }else{
      this.notifyMeService.showWarning("You are not authorized for this transaction", 'Sales Genie App');
    }

  }
  saveRecord() {
    this.ds.salesmanApprove( this.RdCode, this.PCCode,this.UserID,this.Status, this.token, this.device,this.deviceType,this.FormName).subscribe(res => {
      this.list = res;
      this.openModal(this.elRefOpenModalThankYou);
    });
  }

  Ok(){
    if(this.UserType.toUpperCase() == "ASM" || this.UserType.toUpperCase() == "BSM"){
      this.Status = "Y"
      this.saveRecord();
      // this.router.navigate(['/home/pc/tsi']);
    }else{
      this.notifyMeService.showWarning("You are not authorized for this transaction", 'Sales Genie App');
    }

  }

  show(imagepath:any)
  {
    this.selectedimagepath=imagepath;
    this.showModal = true; // Show-Hide Modal Check

  }
  //Bootstrap Modal Close event
  hide()
  {
    this.showModal = false;
  }

  openModal(modal: ElementRef<any> | undefined) {
    this.modalService.open(modal,
      {
        size: 'lg',
        backdrop: 'static',
        windowClass: '',
        keyboard: false,
        centered: true,
      })
  }

  okay(){
    this.modalService.dismissAll();
    this.router.navigate(['/home/pc/tsi']);
  }

  modalClose() {
    this.modalService.dismissAll();
    this.router.navigate(['/home/pc/tsi']);
  }
}
