import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxImageCompressService } from 'ngx-image-compress';
import { ToastrService } from 'ngx-toastr';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { NotifyMeService } from 'src/app/service/notify-me.service';

@Component({
  selector: 'app-qr-code-outlet-details',
  templateUrl: './qr-code-outlet-details.component.html',
  styleUrls: ['./qr-code-outlet-details.component.css']
})
export class QrCodeOutletDetailsComponent implements OnInit {
  deviceType: any;
  device: any;
  userType: string | null = '';
  userId: any;
  token: any;
  public coolerPhoto:any;
  public outletPhoto:any;
  searchText = '';
  isLongPress: boolean = false;
  isSelected: boolean = false;
  isValid: boolean = false;
  outletSelectedA: any;
  imgURL: any;
  photourlstr: string = '';
  public progress: number = 0;
  public message: string = '';
  public mobileNo: string = "";
  public photoUploaded:any
  public submitted = false;

  public photos: any = {
    CoolerPhoto: 0,
    OutletPhoto: 0,
  }
  file: any;
  imgResultBeforeCompress: string = '';
  imgResultAfterCompress: string = '';
  localUrl: any;
  localCompressedURl: any;
  sizeOfOriginalImage: number = 0;
  sizeOFCompressedImage: number = 0;
  TokenNo: any;
  ClientID: any;
  outLetDetails:any;
  CoolerPhoto:any;
  OutletPhoto:any;
  qrCodeData:any
  httpOptions: HttpHeaders =
  new HttpHeaders({
    'Access-Control-Allow-Origin': '*',
    'enctype': 'multipart/form-data',
    'charset': 'UTF-8',
    'FolderName': 'SGVisicoolerPhoto',
  });
  formname:any;
  actiondesc:any;
  FieldName:any;
  FieldValue:any;
  constructor( private headerService: HeaderService,private cs:CommonUtilitiesService,
    private toastr: ToastrService, private mds: MongoDataService,private notifyMeService: NotifyMeService,
   private imageCompress: NgxImageCompressService ,private http: HttpClient,private router: Router) { }

  ngOnInit(): void {
    this.headerService.setTitle('Outlet Detail');
    this.headerService.setActiveURl('/outletDetail');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.userType = localStorage.getItem("UserType");
    this.userId = localStorage.getItem("UserID");
    this.token = localStorage.getItem("TokenNo");
    this.outLetDetails = JSON.parse(localStorage.getItem("outLetDetails") || '{}');
    this.qrCodeData=JSON.parse(localStorage.getItem("QRCodeData") || '{}');

    this.FieldName=localStorage.getItem("FieldName");
    this.FieldValue=localStorage.getItem("FieldValue");
    this.formname = "outletDetail";
    this.actiondesc = "QrCode Outlet Details";
    this.cs.GetGeoLocation(this.userId, this.userType, this.formname, this.actiondesc, this.FieldName, this.FieldValue);
  }

  fileProgress(fileInput: any, photoUrl: string) {
    debugger
    this.photos[photoUrl] += 1;
    
    this.imgURL = <File>fileInput.target.files[0];
 
      if (fileInput.target.files.length === 0) {
        return;
      }
      

    let fileToUpload = <File>fileInput.target.files[0];
    this.photoUploaded=fileToUpload
    
    if ( fileToUpload.name.toLowerCase().lastIndexOf('.jpg') == -1 && fileToUpload.name.toLowerCase().lastIndexOf('.jpeg') == -1 ) {
      console.log("Other file format...");
      this.notifyMeService.showWarning("Please upload jpg or jpeg format only", 'Sales Genie');
      return;
    }

    this.imgURL = fileToUpload;
    const formData = new FormData();
    this.loaddatatoMemberVariable(fileToUpload.name, photoUrl);

    var fileName: any;
    this.file = fileInput.target.files[0];
    fileName = this.file['name'];
    if (fileInput.target.files && fileInput.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.localUrl = event.target.result;
        var orientation = -1;
        this.sizeOfOriginalImage = this.imageCompress.byteCount(this.localUrl) / (1024 * 1024);

        this.imageCompress.compressFile(this.localUrl, orientation, 50, 50).then(result => {
          this.imgResultAfterCompress = result;
          this.localCompressedURl = result;
          this.sizeOFCompressedImage = this.imageCompress.byteCount(result) / (1024 * 1024)
          // create file from byte
          const imageName = fileName;
          // call method that creates a blob from dataUri
          const imageBlob = this.dataURItoBlob(this.imgResultAfterCompress.split(',')[1]);
          //imageFile created below is the new compressed file which can be send to API in form data
          const imageFile = new File([imageBlob], imageName, { type: 'image/jpeg' });

          formData.append('file', imageFile, this.photourlstr);

          this.http.post(this.mds.url + 'MeraNet/UploadFileToS3Bucket', formData, { reportProgress: true, observe: 'events', headers: this.httpOptions, responseType: 'blob' as 'json' }).subscribe(res => {
            console.log("ATOBUploadFile", res)
            this.preview(photoUrl);
          },
            error => { console.log("error", error) });
        });
      }
      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

  dataURItoBlob(dataURI: string) {
    debugger
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }

  loaddatatoMemberVariable(filename: string, photoUrl: string) {
    debugger
    switch (photoUrl) {
      case 'CoolerPhoto':
        this.photourlstr = this.outLetDetails[0]?.outletCode.toString() + "_1" + filename.substring(filename.indexOf('.'));
        this.CoolerPhoto = this.photourlstr;
        break;
      case 'OutletPhoto':
        this.photourlstr = this.outLetDetails[0]?.outletCode.toString() + "_2" + filename.substring(filename.indexOf('.'));
        this.OutletPhoto = this.photourlstr;
        break;
    }

    if (this.CoolerPhoto != '' && this.OutletPhoto != '' ) {
      this.isValid = true;
    }
  }

  preview(photoUrl: string) {
    // Show preview
    debugger
    var mimeType = this.imgURL.type;
    if (mimeType?.match(/image\/*/) == null) {
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(this.imgURL);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
      switch (photoUrl) {
        case 'CoolerPhoto':
          this.coolerPhoto = this.imgURL;
          break;
        case 'OutletPhoto':
          this.outletPhoto = this.imgURL;
          break;
       
      }
    }
  }

  cancleBtn(){
    this.router.navigate(['/home/scanQRCode']);
  }

  saveBtn(){
    debugger
    if (this.CoolerPhoto != '' && this.OutletPhoto != '' ){
      this.mds.saveQRCodeScanningData(this.qrCodeData, this.token, this.device, this.deviceType, 'Scan the QR Code').subscribe(data => {
        console.log("data", data);
        if (data[0].msg.toLowerCase() == "ok") {
          // this.toastr.success("QR code scan sucessfully");
          this.router.navigate(['/home/QrCodeOutletDetails']);
        } else {
          this.toastr.warning(data[0].msg);
          this.router.navigate(['/home/QrCodeOutletDetails']);
          return;
        }
      });
      this.router.navigate(['/home/missingVisicooler'])
    }else{
      this.toastr.warning("Please uplaod Photo");
      return;
    }
  }

}
