<div>
  <div class="row mt-4">
    <div class="col-6 text-center mt-3" *ngFor="let item of appList; let i = index;">
      <span (click)="appClick(item)">
        <img class="img_fluid"
          [src]="url+ 'MeraNet/GetDownloadFileFromS3Bucket/apiimages/' + item.otherApplicationImage" /><br><br>
        <span class="app_Name">{{item.otherApplicationHeader}}</span>
      </span>
    </div>
  </div>
  <br>
  <br>
  <br>
  <br>
  <br>
</div>
