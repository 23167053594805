<div class="customContainer">
    <div class="row mt-4">
        <div class="col-12 text-center">
            <button type="button" class="btn btn_ViewDPN_Agreement" (click)="viewVerificationPage()">VIEW NAME CHANGE
                AMENDMENT LETTER</button>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-12 text-center">
            <span class="text_FailOtp text_displayContent">
                Didn't receive an Verification Code?
                <span class="text_ResendOtp" (click)="resendOtp()">Resend Verification Code</span>
            </span>
        </div>
    </div>
    <hr>

    <div class="row mt-5">
        <div class="col-12 text-center">
            <span style="font-weight: 600;font-size: 21px;color: red;">
                Note:
            </span>
            <br><br>
            <span class="text_FailOtp text_displayContent" style="
            font-size: 17px;color: red;
        ">    
                Please submit the verification code by clicking on
                <b>"View Name Change Amendment Letter"</b>
                or ask the
                <b>retailer</b>
                to submit the verification code to complete the process.
            </span>
        </div>
    </div>
</div>


<div class="footer ">
    <div class="row">
        <div class="col-12 p-0">
            <button type="button" class="btn btn_cancle_Agreement float-right w-100"
                (click)="backBtnClicked()">Cancel</button>
        </div>
    </div>


    <!-- <div class="col-6 p-0">
            <button type="button" class="btn btn_save_Agreement float-right w-100"
                (click)="saveBtnClicked()" [disabled]="!isSubmitted">Save</button>
        </div> -->
</div>

<ng-template #openDPNModal let-modal>
    <div class="modal-header text-center">
        <button type="button" class="close" aria-label="Close" (click)="modalClose()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body ">
        <div id="accordion">
            <div class="card">
                <div class="card-header" id="headingOne">
                    <h5 class="mb-0 collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
                        aria-controls="collapseOne">
                        <button class="btn collapsed" data-toggle="collapse" data-target="#collapseOne"
                            aria-expanded="true" aria-controls="collapseOne">
                            <span class="Value_headertext text-center"><img class="img-fluid mr-1" />VIEW NAME
                                CHANGE AMENDMENT LETTER NOTE</span>
                        </button>
                        <i class="fa fa-sort-desc float-right" aria-hidden="true"></i>
                    </h5>
                </div>

                <div id="collapseOne" class="collapse show cardBody" aria-labelledby="headingOne"
                    data-parent="#accordion">
                    <div class="card-body">
                        <span class="data_Privacy_Text" innerHtml="{{VerifiedOtpText}}"></span>
                    </div>
                </div>
            </div>
        </div>
        <form [formGroup]="loginForm" autocomplete="off">
            <div class="row">
                <div class="col-12 text-center mt-3">
                    <label>
                        <input type="checkbox" name="options" autocomplete="off" class="Chkbtn" #checkbox
                            (change)="selected_Reason($event)" />
                        &nbsp;<span>I agree the terms & conditions</span>
                    </label>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-left">
                    <label>
                        <span class="txtspan">Enter Verification Code</span>
                    </label>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <input id="otp" class="form-control" #OTP type="text" placeholder="Enter verification Code"
                            name="OTP" formControlName="otp" value="">
                    </div>
                </div>

            </div>
            <div class="row">
                <div class="col-12"><button class="btn btn-block btn_login" type="submit"
                        (click)="verifyOTP(OTP.value,checkbox.checked)">
                        SUBMIT VERIFICATION CODE</button></div>
            </div>
        </form>
        <div class="row">
            <div class="col-12 text-center">
                <!-- <h6 [style]="IsError? 'color: red' : 'color:green'">{{errormsg}}</h6> -->
            </div>
        </div>
    </div>
</ng-template>



<ng-template #openDPNARGModal let-modal>
    <div class="modal-header text-center">
        <button type="button" class="close" aria-label="Close" (click)="modalClose()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body my-3">
        <div id="accordion">
            <div class="card">
                <div class="card-header" id="headingOne">
                    <h5 class="mb-0 collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
                        aria-controls="collapseOne">
                        <button class="btn collapsed" data-toggle="collapse" data-target="#collapseOne"
                            aria-expanded="true" aria-controls="collapseOne">
                            <span class="Value_headertext text-center"><img class="img-fluid mr-1" />VIEW NAME
                                CHANGE AMENDMENT LETTER NOTE</span>
                        </button>
                        <i class="fa fa-sort-desc float-right" aria-hidden="true"></i>
                    </h5>
                </div>

                <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                    <div class="card-body">
                        <span class="data_Privacy_Text" innerHtml="{{VerifiedOtpText}}"></span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 text-center mt-3">
                        <!-- <span class="Value_headertext text-center submissionDate">Signed on dated : {{dpnsubmiton }}</span>  -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>