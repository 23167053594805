<div>
  <!-- <div class="row mt-3">
    <div class="col-6">
      <span class="text_16">{{areaName}}</span>
    </div>
    <div class="col-3">
      <button class="btn-sucess" (click)="openFilter()">Filter</button>
    </div>
  </div> -->
  <div class="row mt-4">
    <div class="col-9">
      <span class="text_16">{{FieldName=='ALLINDIA' ? "All India" :FieldName }} Performance</span>
    </div>
    <div class="col-3 text-right">
      <img src="../../../assets/filter.png" class="img-fluid" (click)="openFilter()" />
      <sup><i class="fa fa-circle fa-sm" style="color: #eaab5e;font-size: 5px;"></i></sup>&nbsp;
      <span style="color: #724e8c;font-family: Roboto;font-weight:500 ;font-size: 0.875rem;">Filter</span>
    </div>
  </div>

  <div class="row mt-4">
    <!-- <div class="col-4">
      <button class="btn btn_custom rounded-pill" (click)="onMonthClick()" [class.myClass]="isMonthClicked">Month</button>
    </div>
    <div class="col-4">
      <button class="btn btn_custom rounded-pill" (click)="onYearClick()" [class.myClass]="isYearClicked">Year</button>
    </div> -->
    <div class="col-7 mt-1">
      <img src="../../../assets/info_btn.png" class="img-fluid" />
      <span class="text-left value_with_tax align-middle"> Value with tax</span>
    </div>
    <div class="col-5 text-right">
      <!-- <select class="form_control_custom rounded-pill" id="period" name="period" (change)="changeMonth($event)">
        <option value="" disabled>Select Option</option>
        <option value="Month">Month</option>
        <option value="Year">Year</option>
      </select> -->
      <mat-form-field appearance="outline" style="width: 100px">
        <!-- <mat-label>Period</mat-label> -->
        <mat-select id="period" name="period" (selectionChange)="changeMonth($event)" [(value)]="selectedCategory">
          <mat-option value="" disabled>Select Option</mat-option>
          <mat-option value="Month">Month</mat-option>
          <mat-option value="Year">Year</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="table-responsive" style="margin-top: 1.875rem;">
    <table class="table table-hover table-borderless table-striped">
      <thead class="table_head">
        <tr>
          <th scope="col" *ngIf="selected == 'RE'">RE</th>
          <th scope="col" *ngIf="selected == 'P7' || selected == 'Category' || selected == 'P5'">Category</th>
          <th scope="col" *ngIf="selected == 'Milan'">Tier</th>
          <th scope="col" *ngIf="selected == 'Non P7 VC' || selected == 'BR'">Type</th>
          <th scope="col" *ngIf="selected == 'Udaan'">Tier</th>
          <th scope="col" *ngIf="selected == 'Top SKU'">Sub Brand</th>
          <th scope="col" *ngIf="selected == 'Town Class'">Town Class</th>
          <!-- <th scope="col" *ngIf="selected == 'NPD'">Links</th> -->
          <!-- <th scope="col" *ngIf="selected == 'NPD'">Sales (Cr)</th> -->
          <!-- <th scope="col" *ngIf="selected == 'NPD'">Distribution</th> -->
          <th scope="col" *ngIf="!isYearClicked">{{myDate | date:'MMM yy'}}<br>({{unit}})</th>
          <th scope="col" *ngIf="!isYearClicked">{{curMonth}} {{prevYearTwoDigit}}<br>({{unit}})</th>
          <th scope="col" *ngIf="isYearClicked">{{myDate | date:'yyyy'}}({{unit}})</th>
          <th scope="col" *ngIf="isYearClicked">{{prevYear}}({{unit}})</th>
          <th scope="col">Growth</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of data; let i = index">
          <td class="table_col1_data" [class.total_Head]="item.paraname == 'Total'">{{item.paraname}}</td>
          <td class="table_data" [class.total]="item.paraname == 'Total'">{{item.salescy}}</td>
          <td class="table_data" [class.total]="item.paraname == 'Total'">{{item.salespy}}</td>
          <td class="table_data" [class.total]="item.paraname == 'Total'">{{item.growth}}%</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="row mt-5" *ngIf="selected == 'P7' || selected == 'Milan' || selected == 'Udaan' || selected == 'P5'">
    <div class="col-9 text-left">
      <span class="text_16">Achievement</span>
      <span class="ml-2"><img src="../../../assets/info_btn.png" class="img-fluid" /></span>
      <span class="value_with_tax"> pre-tax</span>
    </div>
    <div class="col-3 text-right">
      <span class="text_10"> {{curMonth +' '+ curYear}} MTD</span>
    </div>
  </div>
  <div class="row mt-2" *ngIf="selected == 'P7' || selected == 'Milan' || selected == 'Udaan' || selected == 'P5'"
    (click)="clickOnAchievement(selected)">
    <div class="col-3">
      <div class="row">
        <div class="col-12 text-center">
          <h1 class="m-0 text_Per">{{achPer}}%</h1>
        </div>
      </div>
    </div>
    <div class="col-9">
      <div class="row mt-2">
        <div class="col-12">
          <div class="progress rounded-pill">
            <div class="progress-bar bg_sec" role="progressbar" [style.width.%]="achievement" aria-valuenow="20"
              aria-valuemin="0" aria-valuemax="100"></div>
            <div class="progress-bar bg_pri" role="progressbar" [style.width.%]="target" aria-valuenow="40"
              aria-valuemin="0" aria-valuemax="100"></div>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-6 text-center">
          <h2 class="m-0 text_16_black">{{achievement}}<span class="text_10"> Lac</span></h2>
        </div>
        <div class="col-6 text-center">
          <h2 class="m-0 text_16_black">{{target}}<span class="text_10"> Lac</span></h2>
        </div>
      </div>
      <div class="row" style="margin-top: -10px;">
        <div class="col-6 text-center">
          <img class="img-fluid" src="assets/sec_dot.png" />
          <span class="secondary_text"> Achievement</span>
        </div>
        <div class="col-6 text-center">
          <img class="img-fluid" src="assets/pri_dot.png" />
          <span class="primary_text"> Target</span>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-5" *ngIf="selected == 'P7' || selected == 'Milan' || selected == 'Udaan' || selected == 'P5'">
    <div class="col-12">
      <span class="text_16">WOA</span>
    </div>
  </div>
  <div class="row mt-2" *ngIf="selected == 'P7' || selected == 'Milan' || selected == 'Udaan' || selected == 'P5'"
    (click)="clickOnWOA(selected)">
    <div class="col-3">
      <div class="row">
        <div class="col-12 text-center">
          <h1 class="m-0 text_Per">{{woaPer}}%</h1>
        </div>
      </div>
    </div>
    <div class="col-9">
      <div class="row mt-2">
        <div class="col-12">
          <div class="progress rounded-pill">
            <div class="progress-bar bg_sec" role="progressbar" [style.width.%]="woaachievement" aria-valuenow="20"
              aria-valuemin="0" aria-valuemax="100"></div>
            <div class="progress-bar bg_pri" role="progressbar" [style.width.%]="woatarget" aria-valuenow="40"
              aria-valuemin="0" aria-valuemax="100"></div>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-6 text-center">
          <h2 class="m-0 text_16_black">{{woaachievement}}</h2>
        </div>
        <div class="col-6 text-center">
          <h2 class="m-0 text_16_black">{{woatarget}}</h2>
        </div>
      </div>
      <div class="row" style="margin-top: -10px;">
        <div class="col-6 text-center">
          <img class="img-fluid" src="assets/sec_dot.png" />
          <span class="secondary_text" *ngIf="selected == 'P7' || selected == 'P5'"> Tgt Ach Outlets</span>
          <span class="secondary_text" *ngIf="selected == 'Milan'"> Tgt Ach Wholesaler</span>
          <span class="secondary_text" *ngIf="selected == 'Udaan'"> Tgt Ach Sub</span>
        </div>
        <div class="col-6 text-center">
          <img class="img-fluid" src="assets/pri_dot.png" />
          <span class="primary_text" *ngIf="selected == 'P7' ||selected == 'P5'"> Total Outlets</span>
          <span class="primary_text" *ngIf="selected == 'Milan'"> Total Wholesalers</span>
          <span class="secondary_text" *ngIf="selected == 'Udaan'"> Total Sub</span>
        </div>
      </div>
    </div>
  </div>
  <br>
  <br>
  <br>
  <br>
  <br>

</div>