import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { FormBuilder, Validators } from '@angular/forms';
import { DataService } from 'src/app/service/data.service';
import { DatePipe } from '@angular/common';
import { NotifyMeService } from 'src/app/service/notify-me.service';
import { ApproveAtoBTransfer } from 'src/app/models/kpi-models';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';
//import { debug } from 'console';

@Component({
  selector: 'app-facial-rec-rej-reason-approval',
  templateUrl: './facial-rec-rej-reason-approval.component.html',
  styleUrls: ['./facial-rec-rej-reason-approval.component.css'],
})
export class FacialRecRejReasonApprovalComponent implements OnInit {
  deviceType: any;
  device: any;
  userType: string | null = '';
  userId: any;
  userTypelist: any;
  FormName: any;
  public approveAtoBTransfer: ApproveAtoBTransfer = new ApproveAtoBTransfer();
  selectedAtoB: any;
  rejectReasonForm = this.fb.group({
    reason: ['', [Validators.required]],
  });
  facialRecDetails: any;
  from: any;
  stockInoutDetails: any;
  selected: any;
  currYearNo: string | null = '';
  dtTemplate: any[] = [];
  token: any;
  level: any;
  formname:any;
  actiondesc:any;
  fieldName: any;
  fieldValue: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private headerService: HeaderService,
    private mds: MongoDataService,
    private fb: FormBuilder,
    private ds: DataService,
    private datePipe: DatePipe,
    private notifyMeService: NotifyMeService,
    private cs:CommonUtilitiesService
  ) { }

  ngOnInit(): void {
    this.headerService.setTitle('Enter Reason');
    this.headerService.setActiveURl('/facialRecRejReasonApproval');
    this.device = localStorage.getItem('Device');
    this.deviceType = localStorage.getItem('DeviceType');
    this.token = localStorage.getItem("TokenNo");
    this.userType = localStorage.getItem('UserType');
    this.userId = localStorage.getItem('UserID');
    this.selectedAtoB = JSON.parse(localStorage.getItem("SelectedAtoB") || '{}');
    this.selected = this.activatedRoute.snapshot.queryParams["selected"];
    console.log("this.selected", this.selected)
    this.level = this.activatedRoute.snapshot.queryParams["level"];
    if (this.selected == "Facial Rec") {
      this.facialRecDetails = JSON.parse(localStorage.getItem('facialRecDetails') || '{}');
    } else if (this.selected == "FSSAI") {
      this.facialRecDetails = JSON.parse(localStorage.getItem("FSSAIApproval") || '{}');
    } else if (this.selected == "RLAAutoClosure") {
      this.facialRecDetails = JSON.parse(localStorage.getItem("rlaAutoClosureDetails") || '{}');
      this.currYearNo = localStorage.getItem("curryearno");
    } else if (this.selected == "claim") {
      this.facialRecDetails = JSON.parse(localStorage.getItem("claimDetailsEditApproval") || '{}');
      console.log("this.facialRecDetails", this.facialRecDetails);
    }
    else if (this.selected == "MTAFH") {
      debugger;
      this.facialRecDetails = JSON.parse(localStorage.getItem("MTAFHDetails") || '{}');
      console.log("this.facialRecDetails", this.facialRecDetails);
    }
    else if (this.selected == "AtoB") {
      this.facialRecDetails = JSON.parse(localStorage.getItem("approveAtoBTransfer") || '{}');
    }

    this.fieldName = localStorage.getItem("FieldName");
    this.fieldValue = localStorage.getItem("FieldValue");
    this.formname="facialRecRejReasonApproval";
    this.actiondesc="Facial Rec Rej Reason Approval"
    this.cs.GetGeoLocation(this.userId,this.userType,this.formname,this.actiondesc,this.fieldName,this.fieldValue);
  }

  submit() {
    debugger
    if (this.selected == "Facial Rec") {
      let status = 'REJECTED';
      let RejectedRemark = this.rejectReasonForm.value.reason;
      this.ds.FRSetPCApprovalData(this.userId, status, this.facialRecDetails.pcuid, RejectedRemark, this.token, this.device, this.deviceType, 'facialRecRejReasonApproval').subscribe((res) => {
        console.log('FRSetPCApprovalData', res);
        this.router.navigate(['/home/facialRecognitionApproval']);
      });
    } else if (this.selected == "FSSAI") {
      let RejectedRemark = this.rejectReasonForm.value.reason;

      const dataToSend = {
        UserID: this.userId,
        UserType: this.userType,
        RDCode: this.facialRecDetails.rdCode,
        LicID: this.facialRecDetails.lid,
        LicenseNo: this.facialRecDetails.licenseNo,
        Remark: RejectedRemark,
        Status: 'Rejected'
      };
      console.log(dataToSend);
      this.ds.fSSAIApproval(dataToSend, this.token, this.device, this.deviceType, 'FSSAI Approval').subscribe(res => {
        console.log(res);
        this.notifyMeService.showSuccess('Records Save Sucessfully', 'Sales Genie App');
        localStorage.removeItem("FSSAIApproval");
        this.router.navigate(['/home/fssaiApproval']);
      });
    } else if (this.selected == "RLAAutoClosure") {
      let RejectedRemark = this.rejectReasonForm.value.reason;

      const dataToSend = {
        UserID: this.userId,
        UserType: this.userType,
        Status: 'REJECTED',
        RejectRemark: RejectedRemark,
        Rdcode: this.facialRecDetails.rdcode,
        Yearno: Number(this.currYearNo),
        // Yearno : 2020,
      };
      this.ds.autoClosureReportApprovalForASM(dataToSend, this.token, this.device, this.deviceType, 'Reject Reason Approval').subscribe(res => {
        this.notifyMeService.showSuccess('Record REJECTED Sucessfully', 'Sales Genie App');
        this.router.navigate(['/home/rlaAutoClosureApproval']);
      });
    } else if (this.selected == "claim") {
      let RejectedRemark = this.rejectReasonForm.value.reason;

      this.dtTemplate.push({ Para1: this.facialRecDetails.yearNo.trim(), Para2: this.facialRecDetails.monthNo.trim(), Para3: this.facialRecDetails.rdCode.trim(), Para4: this.facialRecDetails.claimNo.trim(), Para5: "", Para6: "", Para7: "", Para8: "", Para9: "", Para10: "", });
      const dataToSend = {
        DTTemplate: this.dtTemplate,
        userid: this.userId,
        Flag: '',
        RejectedRemark: RejectedRemark,
        ApprovedAmt: 0,
        OpStatus: 'Rejected',
      };
      console.log("dataToSend", dataToSend)
      if (this.userType?.toUpperCase() == 'ASM') {
        this.ds.rdCLAIMNew_ASMUpdate(dataToSend, this.token, this.device, this.deviceType, 'Claim Approvals').subscribe(res => {
          console.log("rdCLAIMNew_ASMUpdate", res)
          this.notifyMeService.showSuccess('Record REJECTED Sucessfully', 'Sales Genie App');
          this.router.navigate(['/home/claimApproval']);
        });
      } else if (this.userType?.toUpperCase() == 'BSM') {
        this.ds.rdCLAIMNew_BSMUpdate(dataToSend, this.token, this.device, this.deviceType, 'Claim Approvals').subscribe(res => {
          console.log("rdCLAIMNew_BSMUpdate", res)
          this.notifyMeService.showSuccess('Record REJECTED Sucessfully', 'Sales Genie App');
          this.router.navigate(['/home/claimApproval']);
        });
      }
    } else if (this.selected == "Asset") {
      let RejectedRemark = this.rejectReasonForm.value.reason;
      this.facialRecDetails.forEach((element: any) => {
        element.Para3 = "Rejected";
        element.Para4 = RejectedRemark;
      });
      const dataToSend = {
        DTTemplate: this.facialRecDetails,
        UserID: this.userId,
        Level: this.level,
      };
      console.log("dataToSend", dataToSend);
      this.ds.ATSAsset_SetApproval(dataToSend, this.token, this.device, this.deviceType, 'Reject Reason Asset Approval').subscribe(res => {
        console.log("ATSAsset_SetApproval", res);
        this.notifyMeService.showSuccess('Records Rejected Sucessfully', 'Sales Genie App');
        localStorage.removeItem("dtTemplate");
        this.router.navigate(['/home/Approvals']);
      });
    }
    else if (this.selected == "MTAFH") {

      let RejectedRemark = this.rejectReasonForm.value.reason;
      let status = "Rejected";
      this.dtTemplate.push({ Para1: "", Para2: "", Para3: "", Para4: "", Para5: "", Para6: "", Para7: "", Para8: "", Para9: "", Para10: "", });


      this.userType = localStorage.getItem("UserTypeDetails");
      const dataToSend = {
        ID: this.facialRecDetails.id,
        UserID: this.userId,
        Status: status,
        UserType: this.userType,
        RejectRemark: RejectedRemark,
        DTTemplate: this.dtTemplate,

      };

      console.log("dataToSend1", dataToSend);
      this.ds.MTAFH_ApproveAccount(dataToSend, this.token, this.device, this.deviceType, 'Reject Reason MT AFH').subscribe(res => {

        console.log("MTAFH_ApproveAccount", res);
        this.notifyMeService.showSuccess('Records Rejected Sucessfully', 'Sales Genie App');
        localStorage.removeItem("dtTemplate");
        localStorage.removeItem("UserTypeDetails");
        this.router.navigate(['/home/mtafhapproval', 0, this.userType, this.userId]);
      });
    }
    else if(this.selected=="AtoB"){
      this.approveAtoBTransfer.ID = this.selectedAtoB.id;
      this.approveAtoBTransfer.Status = 'Rejected';
      this.approveAtoBTransfer.RejectRemark = this.rejectReasonForm.value.reason;
      this.approveAtoBTransfer.UserID = this.userId;
      this.mds.approveAtoBtransfer(this.approveAtoBTransfer, this.token, this.device, this.deviceType, 'A to B Transfer Approval').subscribe((res: any) => {
        console.log("approveAtoBTransfer",res);
          this.notifyMeService.showSuccess('Records Rejected Sucessfully', 'Sales Genie App');
             this.router.navigate(['/home/a2b']);
      });
    }

  }

  rejectRecord() {
    this.approveAtoBTransfer.ID = this.selectedAtoB.id;
    this.approveAtoBTransfer.Status = 'Rejected';
    this.approveAtoBTransfer.RejectRemark = this.rejectReasonForm.value.reason;
    this.approveAtoBTransfer.UserID = this.userId;
    this.mds.approveAtoBtransfer(this.approveAtoBTransfer, this.token, this.device, this.deviceType, 'A to B Transfer Approval').subscribe((res: any) => {
      console.log("approveAtoBTransfer", res);
      this.notifyMeService.showSuccess('Records Rejected Sucessfully', 'Sales Genie App');
      this.router.navigate(['/home/a2b']);
    });
  }


}
