<div class="">
    <div class="row mt-5">
        <div class="col-6">
            <span class="outlet_Code">QR Code</span>
        </div>
        <div class="col-6 text-end">
            <span class="outlet_Name ml-1">{{qrCodeDetails[0].QRCode}}</span>
        </div>
    </div>
    <hr>

    <div class="row mt-2">
        <div class="col-6">
            <span class="outlet_Code">Outlet UID</span>
        </div>
        <div class="col-6 text-end">
            <span class="outlet_Name ml-1">{{qrCodeDetails[0].OutletCode}}</span>
        </div>
    </div>
    <hr>

    <div class="row mt-2">
        <div class="col-6">
            <span class="outlet_Code">Outlet Name</span>
        </div>
        <div class="col-6 text-end">
            <span class="outlet_Name ml-1">{{qrCodeDetails[0].OutletName}}</span>
        </div>
    </div>
    <hr>

    <div class="row mt-2">
        <div class="col-6">
            <span class="outlet_Code">RD Code</span>
        </div>
        <div class="col-6 text-end">
            <span class="outlet_Name ml-1">{{qrCodeDetails[0].RDCode}}</span>
        </div>
    </div>
    <hr>
    <div class="row mt-2 ">
        <div class="col-6">
            <span class="outlet_Code">Rd Name</span>
        </div>
        <div class="col-6 text-end">
            <span class="outlet_Name ml-1 " style="word-wrap: break-word;">
                {{qrCodeDetails[0].RDName}}
            </span>
        </div>
    </div>
    <hr>

    <div class="row mt-2">
        <div class="col-6">
            <span class="outlet_Code">SO territory</span>
        </div>
        <div class="col-6 text-end">
            <span class="outlet_Name ml-1">{{qrCodeDetails[0].SOTerritory}}</span>
        </div>
    </div>
    <hr>

    <div class="row mt-2">
        <div class="col-6">
            <span class="outlet_Code">ASM area</span>
        </div>
        <div class="col-6 text-end">
            <span class="outlet_Name ml-1">{{qrCodeDetails[0].ASMArea}}</span>
        </div>
    </div>
    <hr>
    <br><br><br><br>
    <div class="footer">
        <div class="row">
            <div class="col-12 pl-0">
                <button class="btn btn_APPROVE" (click)="clickOnContinue()">CONTINUE</button>
            </div>
        </div>
    </div>

    <ng-template #openModalSameOutlet let-modal>
        <div class="modal-header p-1">
          <button type="button" class="close" aria-label="Close" (click)="modalClose()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row mx-auto">
            <div class="col-12">
              <span class="model_PopUp_Text">You Are At Same Outlet ?</span>
            </div>
          </div> 
        </div>
        <div class="modal-footer p-1">
          <div class="row">
            <div class="col-6">
              <button type="button" class="btn btn_SUBMIT" (click)="clickOnYes()">Yes</button>
            </div>
            <div class="col-6">
              <button type="button" class="btn btn_SUBMIT" (click)="clickOnNo()">No</button>
            </div>
          </div>
        </div>
      </ng-template>
</div>


