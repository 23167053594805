<div class="popup-content-box" style="background-color:aquamarine;">
    <div class="modal-content">
      <div class="title">{{data.title}}</div>
      <div style="color:black">
        {{data.message}}
  
        <div class="row col-12"> &nbsp;</div>
        <div class="row">
          <div class="col-6 text-center">
            <button class="btn btn-outline-dark" [mat-dialog-close]="true">Yes</button>
          </div>
          <div class="col-6 text-center">
            <button class="btn btn-outline-dark" (click)="onNoClick()">No</button>
          </div>
        </div>
        <div class="row col-12"> &nbsp;</div>
      </div>
        </div>
      </div>
  
  
  
  