<div>
  <div class="row mt-4">
    <div class="col-12">
      <input class="fullWidth" type="text" name="" id="" placeholder="Search RD Name"
        [(ngModel)]="searchTerm">
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-6">
      <span class="RD_List">RD List</span>
    </div>
    <div class="col-6 text-right">
      <span class="status_Text">Claim Amt ( <img class="img-fluid mr-1" src="assets/rupee.png"/>)</span>
    </div>
  </div>
  <div *ngFor="let item of filteredList; let i = index;" (click)="rowClick(item)">
    <div class="row mt-3">
      <div class="col-9">
        <span class="rd_Name">{{item.rdname | titlecase}}</span>
        <span class="ml-1 rd_Code">{{item.rdcode}}</span>
      </div>
      <div class="col-3 text-right">
        <span class="state_Name">{{item.claimsamount}}</span>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-12">
        <span class="rd_Name">{{item.claimType | titlecase}}</span>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-12">
        <span class="status_Value">{{item.soTerritory | titlecase}}</span>
      </div>
    </div>
    <hr>
  </div>
  <br>
  <br>
  <br>
  <br>
  <br>
</div>
