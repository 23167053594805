import { Component, OnInit } from '@angular/core';
import { DataService } from '../../service/data.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from 'src/app/service/header.service';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';
@Component({
  selector: 'app-non-meranet-outletdetail',
  templateUrl: './non-meranet-outletdetail.component.html',
  styleUrls: ['./non-meranet-outletdetail.component.css'],
})
export class NonMeranetOutletdetailComponent implements OnInit {
  deviceType: any;
  device: any;
  userType: any;
  userId: any;
  fieldName: any;
  fieldValue: any;
  visiCoolerList: any;
  nonMeranetForm = this.fb.group({
    outletDetails: ['', [Validators.required]],
    rdDetails: ['', [Validators.required]],
    mobNo: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
    contactPerson: ['', [Validators.required]],
    address: ['', [Validators.required]],
    visicooler: ['', [Validators.required]],
    vcMake: [''],
    state: ['', [Validators.required]],
    town: ['', [Validators.required]],
  });
  branchName: any;
  stateList: any;
  token: any;
  formname:any;
  actiondesc:any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private headerService: HeaderService,
    private mds: MongoDataService,
    private ds: DataService,
    private fb: FormBuilder,
    private cs:CommonUtilitiesService,
  ) {}

  ngOnInit(): void {
    this.headerService.setTitle('Outlet Detail');
    this.headerService.setActiveURl('/outletDetailcomplaint');
    this.device = localStorage.getItem('Device');
    this.deviceType = localStorage.getItem('DeviceType');
    this.userId = localStorage.getItem('UserID');
    this.token = localStorage.getItem("TokenNo");
    this.userType = localStorage.getItem('UserType');
    this.fieldName = localStorage.getItem('OriginalFieldName');
    this.fieldValue = localStorage.getItem('OriginalFieldValue');
    this.formname="nonMeranetOutletdetail";
    this.actiondesc="Non Meranet Outlet Detail";
    this.cs.GetGeoLocation(this.userId,this.userType,this.formname,this.actiondesc,this.fieldName,this.fieldValue);  

    if (this.userType.toUpperCase() == 'GM') {
      this.branchName = 0;
    } else {
      this.branchName = localStorage.getItem('BranchName')?.trim();
    }
    this.mds.getReasons(this.token,this.device, this.deviceType,'Outlet Details').subscribe((res) => {
      this.visiCoolerList = res.filter((singleItem: { [x: string]: string }) =>
        singleItem['reasonType'].startsWith('Visicooler')
      );
    });
    let tagName = 'statemaster';
    this.mds.getStatemaster(tagName, this.branchName, this.userId, this.token, this.device, this.deviceType,'Outlet Details').subscribe((res) => {
        this.stateList = res;
    });
  }

  changeVisicooler(reasonName: any) {
    if (reasonName.target.value == 'None') {
      this.nonMeranetForm.patchValue({
        vcMake: '',
      });
    } else {
      const dataToSend = {
        VisicoolerName: reasonName.target.value,
      };
      this.ds.getVisiMake(dataToSend, this.token, this.device, this.deviceType,'Outlet Details').subscribe((res) => {
        this.nonMeranetForm.patchValue({
          vcMake: res[0].manufacturerName,
        });
      });
    }
  }

  rowClick() {
    const nonMeranetForm = this.nonMeranetForm.value;
    localStorage.setItem("nonMeranetForm",JSON.stringify(nonMeranetForm));
    this.router.navigate(['/home/addcomplaint'], { queryParams: { selected: "fromNonMeranet" } });
  }

  numberOnly(event: { which: any; keyCode: any; }): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
