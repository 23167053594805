import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { HeaderService } from 'src/app/service/header.service';
import { DatePipe } from '@angular/common';
import { FilterComponent } from '../filter/filter.component';
import { MatDialog } from '@angular/material/dialog';
import { InputPara } from 'src/app/models/kpi-models';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';
@Component({
  selector: 'app-mscore-pc-data',
  templateUrl: './mscore-pc-data.component.html',
  styleUrls: ['./mscore-pc-data.component.css']
})
export class MscorePcDataComponent implements OnInit {

  deviceType: any;
  device: any;
  FieldName: any;
  FieldValue: any;
  ParaName: any;
  UserID: any;
  public resList: any[] = [];
  myDate: any;
  curYear: any;
  curMonth: any;
  prevYear: string = '';
  prevYearTwoDigit: string = '';
  rdType: any = 0;
  selected: string = '';
  token: any ;
  MScoreType:any;
  billclass:any;
  totalclass:any;
  formname:any;
  actiondesc:any;
  userType:any;
  constructor(private activatedRoute: ActivatedRoute,private cs:CommonUtilitiesService, private dialog: MatDialog, private router: Router, private headerService: HeaderService, private mds: MongoDataService, private datePipe: DatePipe,) { }

  ngOnInit(): void {
    this.selected = this.activatedRoute.snapshot.queryParams["selected"];
    this.headerService.setActiveURl('/MScore');
    this.headerService.setTitle('M Score');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.token = localStorage.getItem("TokenNo");
    this.userType = localStorage.getItem("UserType");
    this.myDate = Date.now();

    // this.curMonth = this.datePipe.transform(this.myDate, 'MMM');
    // this.prevYear = JSON.stringify(new Date().getFullYear() - 1);
    // this.prevYearTwoDigit = this.prevYear.substring(2, 4);

    this.activatedRoute.paramMap.forEach(params => {
      this.FieldValue = params.get('FieldValue');
      this.FieldName = params.get('FieldName');
      this.UserID = params.get('UserID');
      this.rdType = params.get('ParaName');
      try{this.MScoreType = params.get('MScoreType');}catch{this.MScoreType ="Audited"}


      this.formname="MScorePCData";
      this.actiondesc="MScore PC Data"+" "+this.FieldName;
      this.cs.GetGeoLocation(this.UserID,this.userType,this.formname,this.actiondesc,this.FieldName,this.FieldValue);
      this.loadData();
    });
  }

  input:InputPara=new InputPara();
  loadData(): void {
    this.input.FieldName=this.FieldName;
    this.input.FieldValue=this.FieldValue;
    this.input.TagName="1";
    this.input.Datatype=this.rdType;
    this.input.UserID=this.UserID;
this.input.TYPE=""
    if(this.MScoreType=='Audited')
    {
      this.billclass='active_rla col-6';
      this.totalclass='unactive_rla col-6';
     this.input.TYPE="auditedrla";
    
  
  }
  else{
    this.billclass='unactive_rla col-6';
    this.totalclass='active_rla col-6';
   this.input.TYPE="visirla";
  }
 
    this.mds.GetMScoreDataPCDashboard(this.input, this.token, this.device, this.deviceType,'Unique Lines Billed').subscribe(res => {
      console.log("GetMScoreDataPCDashboard", res);
     
      this.resList = res;
    });
  
   

  }
    OnClickRLA(type:any)
  {
    this.MScoreType=type;
   
    this.loadData();
  }
  RowClick(paraname: any,paravalue: any, selected: any): void {
 
   
    paraname='PC';
    this.router.navigate(['/home/MScore/', paraname, paravalue, selected, this.UserID,this.MScoreType]);
   
  }
  openFilter(){
    if (this.dialog.openDialogs.length == 0) {
      const dialogRef = this.dialog.open(FilterComponent, {
        closeOnNavigation: true,
        data: {
          "FieldName": localStorage.getItem("OriginalFieldName"),
          "FieldValue": localStorage.getItem("OriginalFieldValue"),
          "UserID": this.UserID,
          "MenuUpTo": "5",
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if(result.FieldName == "RD"){
            this.FieldName="RD";
          }else{
            this.FieldName=result.FieldName;
          }
          this.FieldValue=result.FieldValue;
          // this.FieldName="1";
          // this.FieldValue=result.FieldName;
          // this.ParaName=result.FieldValue;
          this.loadData();
         }
      });
    }
  }
}

