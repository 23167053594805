import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { DataService } from 'src/app/service/data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-claim-details-edit-approval',
  templateUrl: './claim-details-edit-approval.component.html',
  styleUrls: ['./claim-details-edit-approval.component.css']
})
export class ClaimDetailsEditApprovalComponent implements OnInit {
  @ViewChild('openModalThankYou', { static: true }) elRefOpenModalThankYou: ElementRef | undefined;
  deviceType: any;
  device: any;
  userType: string | null = '';
  userId: any;
  claimDetailsEdit: any;
  claimDetailsTwo: any;
  dtTemplate: any[]=[];
  amount: number = 0;
  editAmount = this.fb.group({
    amount: [0, [Validators.required]],
  });
  token: any;
  formname:any;
  actiondesc:any;
  fieldName: any;
  fieldValue: any;

  constructor(private activatedRoute: ActivatedRoute, private cs:CommonUtilitiesService,
    private router: Router, private headerService: HeaderService, private mds: MongoDataService, private ds: DataService, private modalService: NgbModal, private fb: FormBuilder,) { }

  ngOnInit(): void {
    this.headerService.setTitle('Claim Approvals');
    this.headerService.setActiveURl('/claimDetailsApproval');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.token = localStorage.getItem("TokenNo");
    this.userType = localStorage.getItem("UserType");
    this.userId = localStorage.getItem("UserID");
    this.claimDetailsEdit = JSON.parse(localStorage.getItem("claimDetailsEditApproval") || '{}');
    console.log("claimDetailsEdit",this.claimDetailsEdit)
    this.claimDetailsTwo = JSON.parse(localStorage.getItem("claimDetailsTwo") || '{}');
    console.log("claimDetailsTwo",this.claimDetailsTwo);
    this.amount = this.claimDetailsEdit.claimAmt;

    this.fieldName = localStorage.getItem("FieldName");
    this.fieldValue = localStorage.getItem("FieldValue");

    this.formname="claimDetailsEditApproval";
    this.actiondesc="claim Details Edit Approval"
    this.cs.GetGeoLocation(this.userId,this.userType,this.formname,this.actiondesc,this.fieldName,this.fieldValue);
  }

  approve(){
    if(this.userType?.toUpperCase() == 'SO') {
      this.dtTemplate.push({Para1: this.claimDetailsEdit.yearNo.trim(), Para2: this.claimDetailsEdit.monthNo.trim(), Para3: this.claimDetailsEdit.rdCode.trim(), Para4: this.claimDetailsEdit.claimNo.trim(), Para5: "", Para6: "", Para7: "", Para8: "", Para9: "", Para10: "",});
      const dataToSend = {
        DTTemplate: this.dtTemplate,
        userid : this.userId,
        Flag : 'POPUP',
      };
      console.log("dataToSend",dataToSend)
      this.ds.rdCLAIMNew_SOUpdate(dataToSend, this.token, this.device, this.deviceType, 'Claim Approvals').subscribe(res => {
        console.log("rdCLAIMNew_SOUpdate",res)
        this.openModal(this.elRefOpenModalThankYou);
      });
    } else if(this.userType?.toUpperCase() == 'ASM') {
      this.dtTemplate.push({Para1: this.claimDetailsEdit.yearNo.trim(), Para2: this.claimDetailsEdit.monthNo.trim(), Para3: this.claimDetailsEdit.rdCode.trim(), Para4: this.claimDetailsEdit.claimNo.trim(), Para5: "", Para6: "", Para7: "", Para8: "", Para9: "", Para10: "",});
      const dataToSend = {
        DTTemplate: this.dtTemplate,
        userid : this.userId,
        Flag : 'POPUP',
        RejectedRemark : '',
        ApprovedAmt : this.amount,
        OpStatus : 'Approved',
      };
      console.log("dataToSend",dataToSend)
      this.ds.rdCLAIMNew_ASMUpdate(dataToSend, this.token, this.device, this.deviceType, 'Claim Approvals').subscribe(res => {
        console.log("rdCLAIMNew_ASMUpdate",res)
        this.openModal(this.elRefOpenModalThankYou);
      });
    } else if(this.userType?.toUpperCase() == 'BSM') {
      this.dtTemplate.push({Para1: this.claimDetailsEdit.yearNo.trim(), Para2: this.claimDetailsEdit.monthNo.trim(), Para3: this.claimDetailsEdit.rdCode.trim(), Para4: this.claimDetailsEdit.claimNo.trim(), Para5: "", Para6: "", Para7: "", Para8: "", Para9: "", Para10: "",});
      const dataToSend = {
        DTTemplate: this.dtTemplate,
        userid : this.userId,
        Flag : 'POPUP',
        RejectedRemark : '',
        ApprovedAmt : this.amount,
        OpStatus : 'Approved',
      };
      console.log("dataToSend",dataToSend)
      this.ds.rdCLAIMNew_BSMUpdate(dataToSend, this.token, this.device, this.deviceType, 'Claim Approvals').subscribe(res => {
        console.log("rdCLAIMNew_BSMUpdate",res)
        this.openModal(this.elRefOpenModalThankYou);
      });
    }
  }

  openModal(modal: ElementRef<any> | undefined) {
    this.modalService.open(modal,
      {
        size: 'lg',
        backdrop: 'static',
        windowClass: '',
        keyboard: false,
        centered: true,
      })
  }

  reject(){
    this.router.navigate(['/home/facialRecRejReasonApproval'], { queryParams: { selected: 'claim' } });
  }

  okay(){
    this.modalService.dismissAll();
    this.router.navigate(['/home/claimApproval']);
  }

  modalClose() {
    this.modalService.dismissAll();
    this.router.navigate(['/home/claimApproval']);
  }

  modalCloseForEdit() {
    this.modalService.dismissAll();
  }

  onClickEdit(model: any){
    this.editAmount.patchValue({
      amount: this.claimDetailsEdit.claimAmt,
    });
    this.modalService.open(model,
      {
        size: 'lg',
        backdrop: 'static',
        windowClass: '',
        keyboard: false,
        centered: true,
      });
  }

  save() {
    this.modalService.dismissAll();
    this.amount = this.editAmount.value.amount;
    this.claimDetailsEdit.claimAmt = this.amount;
    console.log("amount",this.amount)
  }

}
