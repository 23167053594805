import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from 'src/app/service/header.service';
import { DataService } from 'src/app/service/data.service';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-other-applications',
  templateUrl: './other-applications.component.html',
  styleUrls: ['./other-applications.component.css']
})
export class OtherApplicationsComponent implements OnInit {
  token: any ;
  deviceType: any;
  device: any;
  userId: any;
  appList: any;
  formname:any;
  actiondesc:any;
  UserType:any;
  fieldName:any;
  fieldValue:any;
url:any;
  constructor(private activatedRoute: ActivatedRoute, private router: Router, private cs:CommonUtilitiesService,private headerService: HeaderService, private ds: DataService, ) { }


  ngOnInit(): void {
    this.headerService.setActiveURl('/otherApplications');
    this.headerService.setTitle("Other Applications");
    this.url=this.ds.url
    this.token = localStorage.getItem("TokenNo");
    this.userId = localStorage.getItem("UserID");
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.UserType= localStorage.getItem('UserType');
    this.fieldName = localStorage.getItem("FieldName");
    this.fieldValue = localStorage.getItem("FieldValue");
    this.formname="otherApplications";
    this.actiondesc="Other Applications";
    this.cs.GetGeoLocation(this.userId,this.UserType,this.formname,this.actiondesc,this.fieldName,this.fieldValue);  
    this.loadData();
  }

  appClick(item: any) {
    
  }

  loadData(): void {
    const dataToSend = {
      UserID: this.userId,
      ID: "0",
      Flag: "SG",
    };
    console.log("dataToSend",dataToSend)
    this.ds.MA_GetOtherApplicationData(dataToSend, this.token, this.device, this.deviceType,'Other Applications').subscribe(res => {
      this.appList = res;
      console.log("appList",this.appList)
    });
  }

}
