<!-- <div class="popup-content-box" style="background-color:aquamarine;"> -->
  <div class="modal-content">
  
      <div class="modal-header">
<mat-toolbar class="filterheader">
  <div class="row vw-100">
    <div class="col-1">
      <img src="../../../assets/AppIcons/icon_arrow_left_dark.png" class="leftarrow" (click)="onNoClick()" >
    </div>
    <div class="col-7 text-left" (click)="onNoClick()">
      <span class="filtertext">Filter</span>
    </div>
    <div class="col-3 text-right">
      <span class="resettext" (click)="ResetMenus()">Reset</span>
    </div>
  </div>
</mat-toolbar>
</div>
<div class="modal-body">
<div class="leftdiv">
  <label [class]="HighlitedMenu.toUpperCase()=='BRANCH' ? 'leftActivemenutext' : visitedmenu1==true ? 'leftVlinkmenutext' : 'leftmenutext'" (click)="Menu1Click()" [attr.disabled]="visitedmenu1==true ? true:null">{{menu1}}</label><br>
  <div *ngIf="MenuUpTo>1"><label  [class]="HighlitedMenu.toUpperCase()=='REGION' ? 'leftActivemenutext' :visitedmenu2==true ? 'leftVlinkmenutext' : 'leftmenutext'" (click)="Menu2Click()" [attr.disabled]="visitedmenu2==true ? true:null">{{menu2}}</label></div>
  <div *ngIf="MenuUpTo>2"><label  [class]="HighlitedMenu.toUpperCase()=='ASMAREA' || HighlitedMenu.toUpperCase()=='ASM AREA' ? 'leftActivemenutext' :visitedmenu3==true ? 'leftVlinkmenutext' : 'leftmenutext'" (click)="Menu3Click()" [attr.disabled]="visitedmenu3==true ? true:null">{{menu3}}</label></div>
  <div *ngIf="MenuUpTo>3"><label  [class]="HighlitedMenu.toUpperCase()=='SOTERRITORY' ? 'leftActivemenutext' :visitedmenu4==true ? 'leftVlinkmenutext' : 'leftmenutext'" (click)="Menu4Click()" [attr.disabled]="visitedmenu4==true ? true:null">{{menu4}}</label></div>
  <div *ngIf="MenuUpTo>4"><label  [class]="HighlitedMenu.toUpperCase()=='RD' ? 'leftActivemenutext' : this.visitedmenu5==true ? 'leftVlinkmenutext' : 'leftmenutext'" (click)="Menu5Click()" [attr.disabled]="visitedmenu5==true ? true:null">{{menu5}}</label></div>

</div>
<div class="rightdiv">
 <div *ngFor="let obj of this.rightList">
  <div class="form-check">
    <label class="form-check-label">
      <input type="radio" name="groupA" class="form-check-input" [value]="obj" (change)="onCheckboxChange($event)">{{obj}}
    </label>
  </div>
 <br>
 </div>
</div>
    </div>
<div class=" buttonclass">
<div class="row">
  <div class="col-12">
    <div class="btn-group btn-block" role="group" aria-label="Basic example">
      <button type="button" class="btn btnreset" (click)="onNoClick()">CANCEL</button>
      <button type="button" class="btn btnappy" (click)="onYesClick()">APPLY</button>
    </div>
  </div>
</div>
  <!-- <div class="buttonclass"> -->
   
  <!-- </div> -->
 </div>
 </div>
<!-- </div> -->