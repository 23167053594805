import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HeaderService } from 'src/app/service/header.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { DataService } from 'src/app/service/data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';

@Component({
  selector: 'app-claim-details-approval',
  templateUrl: './claim-details-approval.component.html',
  styleUrls: ['./claim-details-approval.component.css']
})
export class ClaimDetailsApprovalComponent implements OnInit {
  @ViewChild('openModalThankYou', { static: true }) elRefOpenModalThankYou: ElementRef | undefined;
  deviceType: any;
  device: any;
  userType: string | null = '';
  userId: any;
  claimDetails: any;
  list: any;
  totalAmt: number = 0;
  count: number = 0;
  isSelected: boolean = false;
  isSelectedObj: any;
  selected = -1;
  dtTemplate: any[]=[];
  amount: number = 0;
  token: any;
  formname:any;
  actiondesc:any;
  fieldName: any;
  fieldValue: any;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private headerService: HeaderService,
    private cs:CommonUtilitiesService,
    private mds: MongoDataService, private ds: DataService, private modalService: NgbModal,) { }

  ngOnInit(): void {
    this.headerService.setTitle('Claim Approvals');
    this.headerService.setActiveURl('/claimDetailsApproval');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.token = localStorage.getItem("TokenNo");
    this.userType = localStorage.getItem("UserType");
    this.userId = localStorage.getItem("UserID");
    this.claimDetails = JSON.parse(localStorage.getItem("claimDetails") || '{}');
    console.log("claimDetails",this.claimDetails);
 
    this.fieldName = localStorage.getItem("FieldName");
    this.fieldValue = localStorage.getItem("FieldValue");

    this.formname="claimDetailsApproval";
    this.actiondesc="claim Details Approval"
    this.cs.GetGeoLocation(this.userId,this.userType,this.formname,this.actiondesc,this.fieldName,this.fieldValue);
    this.onLoad();
  }

  onLoad() {
    if(this.userType?.toUpperCase() == 'SO'){
      this.ds.soAuthorisation(this.userId, this.token, this.device, this.deviceType, 'Claim Approvals').subscribe(res => {
        this.list = res.filter((singleItem: { [x: string]: string; }) => singleItem['rdcode'] == this.claimDetails.rdcode);
        console.log("soAuthorisation list",this.list)
        this.totalAmtFinding();
      });
    } else if(this.userType?.toUpperCase() == 'ASM'){
      this.ds.asmAuthorisation(this.userId, this.token, this.device, this.deviceType, 'Claim Approvals').subscribe(res => {
        this.list = res.filter((singleItem: { [x: string]: string; }) => singleItem['rdcode'] == this.claimDetails.rdcode);
        console.log("asmAuthorisation list",this.list)
        this.totalAmtFinding();
      });
    } else if(this.userType?.toUpperCase() == 'BSM'){
      this.ds.bsmAuthorisation(this.userId, this.token, this.device, this.deviceType, 'Claim Approvals').subscribe(res => {
        this.list = res.filter((singleItem: { [x: string]: string; }) => singleItem['rdcode'] == this.claimDetails.rdcode);
        console.log("bsmAuthorisation list",this.list)
        this.totalAmtFinding();
      });
    }
  }

  totalAmtFinding() {
    if(this.list.length > 0 ) {
      this.count = this.list.length;
      this.list.forEach((ele: { claimsamount: number; }) => {
        this.totalAmt += Number(ele.claimsamount);
      });
    }

  }

  rowClick(data: any){
      localStorage.setItem("claimDetailsTwo",JSON.stringify(data))
      this.router.navigate(['/home/claimDetailsTwoApproval']);
  }

  approve(){
    if(this.userType?.toUpperCase() == 'SO') {
      const dataToSend = {
        DTTemplate: this.dtTemplate,
        userid : this.userId,
        Flag : '',
      };
      console.log("dataToSend",dataToSend)
      this.ds.rdCLAIMNew_SOUpdate(dataToSend, this.token, this.device, this.deviceType, 'Claim Approvals').subscribe(res => {
        console.log("rdCLAIMNew_SOUpdate",res)
        this.openModal(this.elRefOpenModalThankYou);
      });
    } else if(this.userType?.toUpperCase() == 'ASM') {
      const dataToSend = {
        DTTemplate: this.dtTemplate,
        userid : this.userId,
        Flag : '',
        RejectedRemark : '',
        ApprovedAmt : 0,
        OpStatus : 'Approved',
      };
      console.log("dataToSend",dataToSend)
      this.ds.rdCLAIMNew_ASMUpdate(dataToSend, this.token, this.device, this.deviceType, 'Claim Approvals').subscribe(res => {
        console.log("rdCLAIMNew_ASMUpdate",res)
        this.openModal(this.elRefOpenModalThankYou);
      });
    } else if(this.userType?.toUpperCase() == 'BSM') {
      const dataToSend = {
        DTTemplate: this.dtTemplate,
        userid : this.userId,
        Flag : '',
        RejectedRemark : '',
        ApprovedAmt : 0,
        OpStatus : 'Approved',
      };
      console.log("dataToSend",dataToSend)
      this.ds.rdCLAIMNew_BSMUpdate(dataToSend, this.token, this.device, this.deviceType, 'Claim Approvals').subscribe(res => {
        console.log("rdCLAIMNew_BSMUpdate",res)
        this.openModal(this.elRefOpenModalThankYou);
      });
    }
  }

  openModal(modal: ElementRef<any> | undefined) {
    this.modalService.open(modal,
      {
        size: 'lg',
        backdrop: 'static',
        windowClass: '',
        keyboard: false,
        centered: true,
      })
  }

  reject(){
    this.router.navigate(['/home/claimApproval']);
  }

  okay(){
    this.modalService.dismissAll();
    this.router.navigate(['/home/claimApproval']);
  }

  modalClose() {
    this.modalService.dismissAll();
    this.router.navigate(['/home/claimApproval']);
  }

  clickOnSelect(e: any, id: any, claimTypeID: any) {
    if (e.target.checked) {
      this.isSelected = e.target.value;
      this.isSelectedObj = this.list[id];
      console.log("isSelectedObj",this.isSelectedObj);
      this.amount += this.isSelectedObj.claimsamount;
      this.dtTemplate.push({Para1: this.isSelectedObj.yearNo.toString(), Para2: this.isSelectedObj.monthNo.toString(), Para3: this.isSelectedObj.rdcode, Para4: this.isSelectedObj.claimTypeID, Para5: "", Para6: "", Para7: "", Para8: "", Para9: "", Para10: "",});
      console.log("dtTemplate",this.dtTemplate);
    } else {
      this.list.forEach((element: any, index: any) => {
        if (element.claimTypeID == claimTypeID) {
          this.amount -= element.claimsamount;
        }
      });
      this.isSelected = false;
      this.isSelectedObj = null;
      this.dtTemplate.forEach((element: any, index: any) => {
        if (element.Para4 == claimTypeID) {
          this.dtTemplate.splice(index, 1);
        }
      });
      console.log("dtTemplate",this.dtTemplate);
    }
  }

}
