<div class="row mt-4">
  <div class="col-9">
    <i class="fa fa-caret-left" style="color: #757575;">&nbsp;&nbsp;
      <span class="header_text">{{FieldValue=='0'? 'All India' : FieldValue=='ALLINDIA' ? 'All India': FieldValue
        |titlecase}}</span></i>
  </div>
  <div class="col-3 text-right" (click)="openFilter()">
    <img src="../../../assets/filter.png" class="img-fluid" /><sup><i class="fa fa-circle fa-sm"
        style="color: #eaab5e;font-size: 5px;"></i></sup>&nbsp;<span
      style="color: #724e8c;font-family: Roboto-Medium;font-weight:400 ;font-size: 14px;">Filter</span>
  </div>
</div>
<br>
<div class="row mt-2">
  <div class="col-5">
    <mat-form-field appearance="outline" style="width: 90px">
      <mat-select id="period" name="period" (selectionChange)="PeriodChange($event)" [(value)]="selectedperiod">
        <mat-option value="" disabled>Select Month</mat-option>
        <mat-option *ngFor="let item of periodList" [value]="item">
          {{ item }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-7 text-right" style="margin-top: 0.75rem;">
    <img class="img-fluid" src="assets/sec_dot.png" />&nbsp;&nbsp;&nbsp;
    <span class="Secondary_text"> PJP</span>&nbsp;&nbsp;&nbsp;
    <img class="img-fluid" src="assets/pri_dot.png" />&nbsp;&nbsp;&nbsp;
    <span class="Secondary_text"> Month</span>
  </div>
</div>
<br>
<div>
  <div class="row mt-2" *ngFor="let item of filterItemsOfTypeProductivity(productivitydata)">
    <div class="col-12">
      <div class="row">
        <div class="col-8">
          <span class="header_text">{{item.desc + ' ' + item.fieldvalue | titlecase}}</span>
        </div>
        <div class="col-4 text-right" (click)="dayWise(item.fieldname, item.fieldvalue)">
          <img class="img-fluid" src="assets/day_wise_icon.svg" />
          <span class="ml-1 day_Wise">Day Wise</span>
        </div>
      </div>
      <div class="row mt-3" (click)="RowClick(item.fieldname, item.fieldvalue)">
        <div class="col-12">
          <div class="row">
            <div class="col-12">
              <div class="progress rounded-pill">
                <div class="progress-bar bg_sec" role="progressbar" [style.width.%]="item.pjpper"
                  aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                <div class="progress-bar bg_pri" role="progressbar" [style.width.%]="item.prodper"
                  aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-6 text-center">
              <span class="text_14">{{item.pjpper}}</span>
            </div>
            <div class="col-6 text-center">
              <span class="text_14">{{item.prodper}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr class="hr_cust">
  </div>
</div>
<br>
<br>
<br>
<br>
