import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxImageCompressService } from 'ngx-image-compress';
import { ToastrService } from 'ngx-toastr';
import { Enclass, ResultClass } from 'src/app/models/encryption-class';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';
import { EncryptionService } from 'src/app/service/encryption.service';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';
import { NotifyMeService } from 'src/app/service/notify-me.service';

@Component({
  selector: 'app-outlet-name-change-request-send-otp',
  templateUrl: './outlet-name-change-request-send-otp.component.html',
  styleUrls: ['./outlet-name-change-request-send-otp.component.css']
})
export class OutletNameChangeRequestSendOtpComponent implements OnInit {
  newOutletName: any = "";
  selectedSupportingDocument1: any = ""
  selectedSupportingDocument2: any = ""

  @ViewChild('attachSupportingDocument1') attachSupportingDocument1!: any;
  @ViewChild('attachSupportingDocument2') attachSupportingDocument2!: any;

  mobileNo: any = ""

  deviceType: any;
  device: any;
  userType: string | null = '';
  userId: any;
  token: any;
  FormName: any;
  formname: any;
  actiondesc: any;
  fieldName: any;
  fieldValue: any;

  name = 'Angular 5';
  fileName: string | undefined;
  file: any;
  localUrl: any;
  localCompressedURl: any;
  sizeOfOriginalImage: number = 0;
  sizeOFCompressedImage: number = 0;
  imgResultBeforeCompress: string = '';
  imgResultAfterCompress: string = '';
  isErrorMessageInToast: boolean = false
  photourlstr: string = '';
  OutletDetails: any;
  fileURL: any;
  httpOptions: HttpHeaders =
    new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      // 'Content-Type': 'multipart/form-data',
      'enctype': 'multipart/form-data',
      'charset': 'UTF-8',
      'FolderName': 'OutletNameChange',
    })

  @ViewChild('openSendOtpConfirmationBox', { static: true }) elRefopenModalSendOtpConfirmation: ElementRef | undefined;
  secretKey: any;
  clientId: any;
  public enclass: Enclass = new Enclass();
  public resultClass: ResultClass = new ResultClass();
  charachetrValidation:boolean=false
  constructor(private modalService: NgbModal, private es: EncryptionService, private notifyMeService: NotifyMeService, private toaster: ToastrService,
    private headerService: HeaderService, private cs: CommonUtilitiesService, private router: Router, private http: HttpClient,
    private imageCompress: NgxImageCompressService, private mds: MongoDataService) { }

  ngOnInit(): void {
    this.FormName = 'OutletNameChange'
    this.headerService.setTitle('Outlet Name Change');
    this.headerService.setActiveURl('/outletNameChangeRequestSendOtp');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.userId = localStorage.getItem("UserID");
    this.token = localStorage.getItem("TokenNo");
    this.userType = localStorage.getItem("UserType");
    this.fieldName = localStorage.getItem("FieldName");
    this.fieldValue = localStorage.getItem("FieldValue");
    this.secretKey = localStorage.getItem("SecreteKey")
    this.clientId = localStorage.getItem("ClientID");
    this.OutletDetails = JSON.parse(sessionStorage.getItem("OutletDetails") || '{}')
    console.log("this.OutletDetails", this.OutletDetails)
    this.mobileNo = this.OutletDetails[0]?.MobileNo?.split("T")[1]
    this.formname = "outletNameChange"
    this.actiondesc = "Outlet Name Change Request";
    this.cs.GetGeoLocation(this.userId, this.userType, this.formname, this.actiondesc, this.fieldName, this.fieldValue)
  }

  numberOnly(event: { which: any; keyCode: any; }): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  sendOtpAndLink() {
    debugger
    if (this.isErrorMessageInToast || this.charachetrValidation|| this.newOutletName == "" || this.selectedSupportingDocument1 == "" || (this.attachSupportingDocument1.nativeElement ? this.attachSupportingDocument1.nativeElement.value == "" : this.attachSupportingDocument1 == null)
      || this.mobileNo == "" || this.mobileNo == undefined) {
      return;
    }

    if (this.attachSupportingDocument1.nativeElement) {
      if (this.attachSupportingDocument1.nativeElement.value?.toLowerCase().lastIndexOf('.jpeg') == -1 && this.attachSupportingDocument1.nativeElement.value?.toLowerCase().lastIndexOf('.jpg') == -1 && this.attachSupportingDocument1.nativeElement.value?.toLowerCase().lastIndexOf('.png') == -1) {
        this.notifyMeService.showError('Image Should Be In JEPG or In PNG Format', 'Mera APP');
        return
      }
    }

    if (this.attachSupportingDocument1) {
      if (this.attachSupportingDocument1.lastIndexOf('.jpeg') == -1 && this.attachSupportingDocument1.lastIndexOf('.jpg') == -1 && this.attachSupportingDocument1.lastIndexOf('.png') == -1) {
        this.notifyMeService.showError('Image Should Be In JEPG or In PNG Format', 'Mera APP');
        return
      }
    }


    if (this.mobileNo.length < 10) {
      this.toaster.warning("Mobile No Should Be 10 Digit")
      return
    }

    this.openModal(this.elRefopenModalSendOtpConfirmation);
  }

  openModal(modal: ElementRef<any> | undefined) {
    this.modalService.open(modal,
      {
        size: 'lg',
        backdrop: 'static',
        windowClass: '',
        keyboard: false,
        centered: true,
      })
  }
  modalClose() {
    this.modalService.dismissAll();
  }

  validateMobileNo(event: any) {
    debugger
    console.log("event", event)
    this.mobileNo = event.target.value
  }
  sendOtp() {
    debugger
    // if (this.isErrorMessageInToast) {
    //   this.toaster.warning("Please Enter Valid Outlet Name")
    //   return
    // }

    // if(this.newOutletName==""){
    //   this.toaster.warning("Please Enter Outlet Name")
    //   return
    // }

    // if(this.selectedSupportingDocument1==""){
    //   this.toaster.warning("Please Select Supporting Document1")
    //   return
    // }

    // if(this.selectedSupportingDocument2==""){
    //   this.toaster.warning("Please Select Supporting Document2")
    //   return
    // }

    // if(this.attachSupportingDocument1==null){
    //   this.toaster.warning("Please Attach Supporting Document1")
    //   return
    // }
    // if(this.attachSupportingDocument2==null){
    //   this.toaster.warning("Please Attach Supporting Document2")
    //   return
    // }
    // if(this.mobileNo==""){
    //   this.toaster.warning("Please Enter Mobile No")
    //   return
    // }

    let request = {
      "OutletCode": this.OutletDetails[0]?.Outletcode,
      "OldOutletName": this.OutletDetails[0]?.Outletname,
      "NewOutletName": this.newOutletName,
      "DocumentType1": this.selectedSupportingDocument1,
      "DocumentName1": this.attachSupportingDocument1,
      "MobileNo": this.mobileNo,
      "VisiCooler": this.OutletDetails[0]?.Visicooler,
      "PP": this.OutletDetails[0]?.PP,
      "UserID": this.userId,
      "RDCode": this.OutletDetails[0]?.RdCode,
      "IsResend": "N"
    }

    console.log("request", request)

    this.es.WordKey = this.secretKey
    this.es.encrypt(this.secretKey);
    this.es.Word = JSON.stringify(request);
    this.enclass.buggerstring = this.es.encrypt(this.secretKey)
    this.mds.MA_SetOutletNameChangeDetails(this.enclass, this.token, this.device, this.deviceType, this.FormName, this.clientId).subscribe(res => {
      console.log("getSurveydetails", res)
      this.resultClass = res;
      this.es.Word = this.resultClass.resultString;
      let response = JSON.parse(this.es.decrypt(this.secretKey));
      console.log("response**************", response)

      if (response[0].Result?.toLowerCase() == "ok") {
        sessionStorage.setItem("outletNameChangeMobileNo", this.mobileNo)
        sessionStorage.setItem("outletNameChangeDetails", JSON.stringify(response))
        this.router.navigate(['home/outletNameChangeRequestVerifyOtp'])
        this.modalService.dismissAll();
      } else {
        this.toaster.warning(response[0].Result)
        this.modalService.dismissAll();
        return;
      }
    }, (error) => {
      console.log("error", error)
    });

  }



  trigger() {
    let element = document.getElementById('upload_file') as HTMLInputElement;
    element.click();
  }

  onChange(file: any) {
    this.file = file.files[0];
    this.fileName = file.files[0].name;
  }

  removeFile() {
    // this.file = "";
    this.fileName = '';
  }


  alphaNumberOnly(e: any) {  // Accept only alpha numerics, not special characters 
    debugger


    if (this.newOutletName?.length > 40) {
      this.charachetrValidation=true;
    }
    else{
      this.charachetrValidation=false 
    }

    let allowSpecialChar = ".,-_& "
    allowSpecialChar = allowSpecialChar.replace("-", "\-")
    let regex = new RegExp("^[A-Za-z0-9" + allowSpecialChar + "]+$")
    // var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    var str = e.target.value
    if (regex.test(str)) {
      this.isErrorMessageInToast = false
      return true;
    } else {
      e.preventDefault();
      this.isErrorMessageInToast = true
      return false;
    }


  }

  onPaste(e: any) {
    e.preventDefault();
    return false;
  }

  fileProgress(fileInput: any, fileType: any) {
    debugger

    if (fileType == "attachSupportingDocument1") {
      this.attachSupportingDocument1 = fileInput.target.files[0].name
    } else {
      this.attachSupportingDocument2 = fileInput.target.files[0].name
    }
    this.fileURL = <File>fileInput.target.files[0];
    if (fileInput.length === 0) {
      return;
    }
    let fileToUpload = <File>fileInput.target.files[0];

    if (fileToUpload.name.toLowerCase().lastIndexOf('.jpeg') == -1 && fileToUpload.name.toLowerCase().lastIndexOf('.jpg') == -1 && fileToUpload.name.toLowerCase().lastIndexOf('.png') == -1) {
      this.notifyMeService.showError('Image Should Be In JEPG or In PNG Format', 'Mera APP');
      return
    }

    this.fileURL = fileToUpload;
    const formData = new FormData();
    debugger
    // formData.append('file', fileToUpload, this.fileURL.name);
    // this.http.post(this.mds.url + 'MeraNet/UploadFileToS3Bucket', formData, { reportProgress: true, observe: 'events', headers: this.httpOptions, responseType: 'blob' as 'json' }).subscribe(res => {
    //   console.log("ATOBUploadFile", res)
    // });

    var fileName: any;
    this.file = fileInput.target.files[0];
    fileName = this.file['name'];
    if (fileInput.target.files && fileInput.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.localUrl = event.target.result;
        var orientation = -1;
        this.sizeOfOriginalImage = this.imageCompress.byteCount(this.localUrl) / (2500 * 2500);

        this.imageCompress.compressFile(this.localUrl, orientation, 75, 75).then(result => {
          this.imgResultAfterCompress = result;
          console.log("this.imgResultAfterCompress", this.imgResultAfterCompress)
          this.localCompressedURl = result;
          this.sizeOFCompressedImage = this.imageCompress.byteCount(result) / (2500 * 2500)
          console.log("this.sizeOFCompressedImage", this.sizeOFCompressedImage)
          // create file from byte
          const imageName = fileName;
          // call method that creates a blob from dataUri
          const imageBlob = this.dataURItoBlob(this.imgResultAfterCompress.split(',')[1]);
          //imageFile created below is the new compressed file which can be send to API in form data
          const imageFile = new File([imageBlob], imageName, { type: 'image/jpeg,image/png' });

          // formData.append('file', fileToUpload, this.fileURL.name);
          formData.append('file', imageFile, this.fileURL.name);
          this.http.post(this.mds.url + 'MeraNet/UploadFileToS3Bucket', formData, { reportProgress: true, observe: 'events', headers: this.httpOptions, responseType: 'blob' as 'json' }).subscribe(res => {
            console.log("ATOBUploadFile", res)
            // this.preview(photoUrl);
          },
            error => { console.log("error", error) });
        });
      }
      reader.readAsDataURL(fileInput.target.files[0]);
    }

  }

  dataURItoBlob(dataURI: string) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg,image/png' });
    return blob;
  }

}
