<div>
  <div class="row mt-4">
    <div class="col-12">
      <input class="fullWidth" type="text" name="" id="" placeholder="Search RD Name"
        [(ngModel)]="searchTerm">
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-6">
      <span class="select_RD">Select RD</span>
    </div>
    <div class="col-6 text-right">
      <span class="count_Text">{{count}}</span>
      <span class="ml-1 status_Text">RD</span>
    </div>
  </div>
  <div *ngFor="let item of filteredList; let i = index;" (click)="rowClick(item)">
    <div class="row mt-3">
      <div class="col-12">
        <span class="rd_Name">{{item.rdname | titlecase}}</span>
        <span class="ml-1 rd_Code">{{item.rdcode}}</span>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12">
        <span class="state_Name">{{item.town}}</span>
      </div>
    </div>
    <hr>
  </div>
  <br>
  <br>
  <br>
  <br>
  <br>
</div>
