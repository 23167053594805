<div>
    <div class="row mt-4">
        <div class="col-8">
          <!-- <span class="header_text">{{FieldValue=='0'? 'All India' : FieldValue}}</span> -->
        </div>
        <div class="col-4 text-right">
          <!-- <img src="../../../assets/filter.png" class="img-fluid" (click)="openFilter()" /><sup><i
              class="fa fa-circle fa-sm" style="color: #eaab5e;font-size: 5px;"></i></sup>&nbsp;<span
            class="filter_text">Filter</span> -->
        </div>
      </div>
      <br>
      <div>
          <div class="row mt-2" *ngFor="let item of gateMeeting"
              (click)="RowClick(item.rdType)">
              <div class="col-12">
                  <div class="row">
                      <div class="col-12">
                          <span class="Head_text">{{item.rdType}}</span>
                      </div>
                  </div>
                  <div class="row mt-2">
                      <div class="col-3">
                          <div class="row mt-2">
                              <div class="col-12">
                                  <span class="Value_text">{{item.sePer | number: '1.0-0'}}%</span>
                              </div>
                          </div>
                      </div>

                      <div class="col-9" >
                        <div class="row">
                            <div class="col-12 text-right">
                                <span class="Attendedper_text">{{item.pcPer}}% <span class="Attended_text">{{item.rdType=='RURAL'? 'TSI':'PC'}} Attended</span></span>
                            </div>
                        </div>
                          <div class="row">
                              <div class="col-12">
                                  <div class="progress rounded-pill">
                                      <div class="progress-bar bg_sec" role="progressbar" [style.width.%]="item.gateMeetingCount"
                                          aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                      <div class="progress-bar bg_pri" role="progressbar" [style.width.%]="item.seCount"
                                          aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                  </div>
                              </div>
                          </div>
                          <div class="row mt-2">
                              <div class="col-6 text-center">
                                <span class="val_text">{{item.gateMeetingCount}}</span>
                              </div>
                              <div class="col-6 text-center">
                                <span class="val_text">{{item.seCount}}</span>
                              </div>
                          </div>
                          <div class="row">
                              <div class="col-6 text-center">
                                <img class="img-fluid" src="assets/sec_dot.png" />&nbsp;
                                <span class="Secondary_text">{{item.rdType=='RURAL'? 'Day One Meeting':'Gate Meeting'}}</span>
                              </div>
                              <div class="col-6 text-center">
                                <img class="img-fluid" src="assets/pri_dot.png" />&nbsp;
                                <span class="Secondary_text">Total SE</span>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>

              <hr class="hr_cust">
          </div>
      </div>
      <br><br><br><br><br><br>
</div>
