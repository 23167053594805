import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { InputPara } from 'src/app/models/kpi-models';
import { CommonUtilitiesService } from 'src/app/service/common-utilities.service';
import { HeaderService } from 'src/app/service/header.service';
import { MongoDataService } from 'src/app/service/mongo-data.service';

@Component({
  selector: 'app-market-working-outlet-list-data',
  templateUrl: './market-working-outlet-list-data.component.html',
  styleUrls: ['./market-working-outlet-list-data.component.css']
})
export class MarketWorkingOutletListDataComponent implements OnInit {
  deviceType: any;
  device: any;
  userType: string | null = '';
  userId: any;
  list: any;
  filteredList: any;
  firMenuCountList: any;
  primarynos: any;
  secondarynos: any;
  token: any;
  outletListData: any;
  panelOpenState: boolean = true;
  FieldName: any;
  FieldValue: any;
  public rangeSize = 15;
  public ticksColor = 'rgba(252, 252, 255, 0.98)';
  public startAngle = -40;
  public endAngle = 220;
  public isMarketWorkingViewClicked = "false";
  public url="https://salespulseapi.cilpwa.net/api/"

  value: any = 0;
  // public scaleRange: any = [
  //   {
  //     from: 0,
  //     to: 20,
  //     color: "#EAAB5E ",
  //   },
  //   {
  //     from: 20,
  //     to: 120,
  //     color: "#e6c4ff"
  //   },
  //   {
  //     from: 120,
  //     to: 180,
  //     color: "#DCABFF"
  //   }
  // ]

  // public pointers = [
  //   {
  //     value: 20,
  //     color: "#FFFFFF",
  //     length: 0.75,
  //   },
  // ];

  scaleRange: any = [
    {
      from: 0,
      to: 660,
      color: "#DCABFF",
    }
  ];
  pointers: any = [
    {
      value: 0,
      color: "#FFFFFF",
      length: 0.75,
    },
  ];
  public maxValue = 0;
  public majorValue = 0;
  public minorValue = 0;
  public showSpeedometer: any = false;


  public KPIData: any = [
    {
      value: "Program WOA",
      Jan: "50",
      Feb: "25",
      March: "50",
    }, {
      value: "VPO WOA",
      Jan: "50",
      Feb: "25",
      March: "50",
    },
    {
      value: "M-Score LM",
      Jan: "50",
      Feb: "25",
      March: "50",
    },
    {
      value: "GO WOA",
      Jan: "50",
      Feb: "25",
      March: "50",
    }
  ];

  public categoryPerformance: any = [
    {
      category: "All Drink",
      MTD: "100",
      L3M: "90",
      LM: "80"
    },
    {
      category: "Base Choclate",
      MTD: "100",
      L3M: "90",
      LM: "80"
    },
    {
      category: "Biscuits",
      MTD: "100",
      L3M: "90",
      LM: "80"
    },
    {
      category: "Candy + Mint",
      MTD: "100",
      L3M: "90",
      LM: "80"
    },
  ];

  public getProgramtargetDetailsList: any = [];

  public getOutletSalesParameter: any = [];
  public getOutletKPIData: any = [];
  public outletCode: any;
  public input: InputPara = new InputPara();
  datatype: any;
  public LYSM:any=0;
  formname:any;
  actiondesc:any;

  constructor(private router: Router, private cs:CommonUtilitiesService,private headerService: HeaderService, public mds: MongoDataService,) { }

  ngOnInit(): void {
    this.panelOpenState = true;
    this.headerService.setTitle('Market Ka Saathi');
    this.headerService.setActiveURl('/marketWorkingOutletListData');
    this.device = localStorage.getItem("Device");
    this.deviceType = localStorage.getItem("DeviceType");
    this.userType = localStorage.getItem("UserType");
    this.userId = localStorage.getItem("UserID");
    this.token = localStorage.getItem("TokenNo");
    this.FieldName = localStorage.getItem("FieldName");
    this.FieldValue = localStorage.getItem("FieldValue");
    this.datatype = localStorage.getItem("DataType");
    this.showSpeedometer = false;
    let outletListData: any = localStorage.getItem("marketWorkingOutletListData");
    this.outletListData = JSON.parse(outletListData);
    this.outletCode = this.outletListData.outletcode;
    this.LYSM=Math.round(this.outletListData.lysmach)
    // this.outletCode = this.outletCode + "_SURVEY.JPEG";
    // console.log(" this.outletCode", this.outletCode)
    console.log("this.outletListData", this.outletListData)
    setTimeout(() => {
      this.scaleRange = [
        {
          from: this.outletListData.tgt > this.outletListData.ach ? this.outletListData.tgt : this.outletListData.ach,
          to: this.outletListData.tgt > this.outletListData.ach ? (Math.ceil(this.outletListData.tgt) + 10) : (Math.ceil(this.outletListData.ach) + 10),
          color: "#DCABFF",
        },
        {
          from: 0,
          to: this.outletListData.tgt,
          color: "#e6c4ff",
        },
        {
          from: 0,
          to: this.outletListData.ach,
          color: "#EAAB5E",
        }
      ]
      console.log("this.scaleRange", this.scaleRange);
      this.maxValue = this.outletListData.tgt > this.outletListData.ach ? (Math.ceil(this.outletListData.tgt) + 10) : (Math.ceil(this.outletListData.ach) + 10);
      this.majorValue = (this.maxValue % 6);
      if (this.majorValue > 0) {
        this.maxValue = this.maxValue - this.majorValue;
      }
      this.majorValue = this.maxValue / 6;
      this.minorValue = this.majorValue / 6;
      this.minorValue = this.majorValue / 6;
      this.pointers = [
        {
          value: this.outletListData.ach,
          color: "#FFFFFF",
          length: 0.75,
        },
      ];
      this.showSpeedometer = true;
    }, 1000)
    debugger
    this.formname="MarketWorkingOutletListData";
    this.actiondesc="Market Working Outlet List Data"+" "+this.outletListData.outletcode;
    this.cs.GetGeoLocation(this.userId,this.userType,this.formname,this.actiondesc,'outlet',this.outletListData.outletcode);
    this.MarketWorking_GetOutletKPI();
    this.MarketWorking_GetOutletSalesParameter();
    debugger
    this.loadData();
  }


  MarketWorking_GetOutletKPI() {
    let request =
    {
      "FieldName": "outlet",
      "FieldValue": this.outletListData.outletcode.toString(),
      "Datatype": "",
      "TagName": "",
      "UserID": this.userId
    }

    this.mds.MarketWorking_GetOutletKPI(request, this.token, this.device, this.deviceType, 'Market Ka Sathi Get Outlet KPI Of Selected Outlet').subscribe(res => {
      this.getOutletKPIData = res;
      this.getOutletKPIData.forEach((item: any) => {

        item.color1 = 'black';
        item.color2 = 'black';
        item.color3 = 'black';

        if (item.kpiname.toLowerCase() == "program woa") {
          item.color1 = item.lm1 >= 100 ? 'green' : 'red';
          item.color2 = item.lm2 >= 100 ? 'green' : 'red';
          item.color3 = item.lm3 >= 100 ? 'green' : 'red';

          item.lm1 = item.lm1 >= 100 ? 'Y' : 'N';
          item.lm2 = item.lm2 >= 100 ? 'Y' : 'N';
          item.lm3 = item.lm3 >= 100 ? 'Y' : 'N';
        }

        else if (item.kpiname.toLowerCase() == "vpo woa") {
          item.color1 = item.lm1 >= 100 ? 'green' : 'red';
          item.color2 = item.lm2 >= 100 ? 'green' : 'red';
          item.color3 = item.lm3 >= 100 ? 'green' : 'red';

          item.lm1 = item.lm1 >= 100 ? 'Y' : 'N';
          item.lm2 = item.lm2 >= 100 ? 'Y' : 'N';
          item.lm3 = item.lm3 >= 100 ? 'Y' : 'N';
        }

        if (item.kpiname.toLowerCase() == "vc scan") {
          item.color1 = item.lm1 == 0 ? 'red' : item.lm1 == 1 ? 'green' : 'black';
          item.color2 = item.lm2 == 0 ? 'red' : item.lm2 == 1 ? 'green' : 'black';
          item.color3 = item.lm3 == 0 ? 'red' : item.lm3 == 1 ? 'green' : 'black';

          item.lm1 = item.lm1 == 0 ? 'N' : item.lm1 == 1 ? 'Y' : item.lm1;
          item.lm2 = item.lm2 == 0 ? 'N' : item.lm2 == 1 ? 'Y' : item.lm2;
          item.lm3 = item.lm3 == 0 ? 'N' : item.lm3 == 1 ? 'Y' : item.lm3;
        }

        
        if (item.kpiname.toLowerCase() == "go") {
          item.color1 = item.lm1 == 0 ? 'red' : item.lm1 == 1 ? 'green' : 'black';
          item.color2 = item.lm2 == 0 ? 'red' : item.lm2 == 1 ? 'green' : 'black';
          item.color3 = item.lm3 == 0 ? 'red' : item.lm3 == 1 ? 'green' : 'black';

          item.lm1 = item.lm1 == 0 ? 'N' : item.lm1 == 1 ? 'Y' : item.lm1;
          item.lm2 = item.lm2 == 0 ? 'N' : item.lm2 == 1 ? 'Y' : item.lm2;
          item.lm3 = item.lm3 == 0 ? 'N' : item.lm3 == 1 ? 'Y' : item.lm3;
        }

      })
      console.log("getOutletKPIData", this.getOutletKPIData)
      // this.showSpeedometer = true;
    });
  }

  MarketWorking_GetOutletSalesParameter() {
    let request =
    {
      "FieldName": "outlet",
      "FieldValue": this.outletListData.outletcode.toString(),
      "Datatype": "",
      "TagName": "",
      "UserID": this.userId
    }

    this.mds.MarketWorking_GetOutletSalesParameter(request, this.token, this.device, this.deviceType, 'Market Ka Sathi Get Sales Parameter Of Selected Outlet').subscribe(res => {
      console.log("res", res)
      this.getOutletSalesParameter = res;
      ;
    });
  }

  loadData() {
    debugger
        this.input.FieldName = "outlet";
        this.input.FieldValue = this.outletListData.outletcode.toString();
        this.input.TagName = "p7";
        this.input.Datatype = "";
        this.input.UserID = this.userId;
        this.mds.getP7payout(this.input, this.token, this.device, this.deviceType, "Market Ka Sathi Get P7 Payout Of Selected Outlet").subscribe(res => {
          debugger;
          this.getProgramtargetDetailsList=[]
          this.getProgramtargetDetailsList = res;
          console.log("getProgramtargetDetailsList", this.getProgramtargetDetailsList)
        },(error:any)=>{
          debugger
        });
  }

  viewMap() {
    this.isMarketWorkingViewClicked = "true";
    localStorage.setItem("isMarketWorkingViewClicked", this.isMarketWorkingViewClicked);
    this.router.navigate(['/home/MarketWorkingOutletLocation/'])
  }

}
