<div>
    <div class="row mt-4">
      <div class="col-12">
        <input class="fullWidth" type="text" name="" id="" placeholder="Search RD Name Or UID"
          [(ngModel)]="searchTerm">
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-8">
        <span class="header_text">RD List</span>
      </div>
      <div class="col-4 text-right">
        <span class="header_text">{{list.length}}<span style="opacity: 40%;"  class="header_text"> RD</span></span>
      </div>
    </div> 
    <div class="row mt-3">
      <div class="col-12">
        <div class="row" *ngFor="let item of listOrigin; let i = index;" (click)="RowClick(item)">
          <div class="container-fluid">
              <div class="row mt-1">
                <div class="col-12">
                  <span  class="Head_text">{{item.rdname | titlecase}} <span
                    class="ml-1 Head_Val_txt">{{item.rdcode}} </span></span>
                </div>
              </div>
              <div class="row mt-1">
                <div class="col-12">
                  <span class="subhead_text">{{item.town | titlecase}} </span>
                </div>
              </div>
              <hr class="hr_cust">
          </div>
      </div>
      </div>
    </div>   
    
  </div>
  
  <br><br><br><br>