<div>
  <div class="row mt-4">
    <div class="col-12">
      <input class="fullWidth" type="text" name="" id="" placeholder="Search Outlet by Outlet Code" [(ngModel)]="searchTerm">
    </div>
  </div>
  <div *ngFor="let item of filteredList">
    <div class="myDiv" [class.myClass]="isSelected">
      <div class="container-fluid">
        <div long-press (onLongPress)="onLongPress()">
          <div class="row">
            <div class="col-12 mt-2">
              <span class="text_14 text_black">{{item.outletName | titlecase}}</span><span class="ml-1 text_14 text_black"
                style="opacity: 40%;">{{item.outletCode}}</span>
            </div>
            <!-- <div class="col-2 text-right mt-2" *ngIf="isLongPress">
          <img class="img" src="assets/check.png" *ngIf="!isSelected" (click)="clickOnSelect()"/>
          <img class="img" src="assets/check_1.png" *ngIf="isSelected"/>
          </div> -->
          </div>
          <div class="row mt-1">
            <div class="col-12">
              <span style="opacity: 40%;" class="text_12 text_black">{{item.rdName | titlecase}}<span
                  class="ml-1 text_12 text_black"></span>{{item.rdCode}} </span>
            </div>
          </div>
          <hr>
          <div class="row">
            <div class="col-6 text-left">
              <span style="opacity: 40%;" class="text_12 text_black">{{item.visicoolerName}}</span>
            </div>
            <div class="col-6 text-right">
              <button class="btn btn_custom">
                <img class="btn_img" src="assets/done.png" /> Done
              </button>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-12 mb-2">
              <span class="text_14 text_black">{{item.pulloutReason | titlecase}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-12 text-right">
        <span class="text_12 text_black">{{item.requestDate| date:'dd/MM/yyyy'}}</span>
      </div>
    </div>
  </div>
  <br>
  <br>
  <br>
  <br>
  <br>
</div>
<div class="footer" *ngIf="userType?.toUpperCase() =='ASM' || userType?.toUpperCase() =='SO'">
  <div class="row text-center mt-3">
    <div class="col-12" (click)="rowClick()">
      NEW PULL OUT REQUEST
    </div>
  </div>
</div>
