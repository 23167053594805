<div class="row mt-4 divbk">
    <div class="col-12">
        <div>
            <span class="pcnamex">Shrikant Nayak K</span>
            <span class="pccodex">15990</span>
        </div>
        <div>
            <span class="cmpnamex">Leroys Enterprises</span>
            <span class="cmpcodex">7081572</span>
        </div>
    </div>
</div>

<div class="col-12 mt-4">
    <span class="head1">
        Selfie facing the camera
    </span>
    <div class="col-12">
        <img class="img-fluid photo" src="..\assets\images\NoBlogImage.jpg" />
    </div>
</div>

<div class="col-12">
    <span class="head1">
        Selfie with head turned right
    </span>
    <div class="col-12">
        <img class="img-fluid photo" src="..\assets\images\NoBlogImage.jpg" />
    </div>
</div>

<div class="col-12">
    <span class="head1">
        Selfie with head turned left
    </span>
    <div class="col-12">
        <img class="img-fluid photo" src="..\assets\images\NoBlogImage.jpg" />
    </div>
</div>

<div class="row">
    <div class="col-6 p-0">
        <button class="btnReject btn-block">Reject</button>
    </div>
    <div class="col-6 p-0">
        <button class="btnReject btn-block">Accept</button>
    </div>
</div>
<br /><br /><br /><br /><br /><br /><br /><br />